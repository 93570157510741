import React, { useState } from 'react';
import { CopyOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { DefaultButton, SubmitButton } from '../../../styles';
import { Layout } from '../../../store/ducks/layout/types';
interface Props {
  value: string;
  layout?: Layout;
}
const CodebillContent: React.FC<Props> = ({ value, layout }) => {
  const [codeCopied, setCodeCopied] = useState<boolean>(false);
  return (
    <>
      <h3 style={{ textAlign: 'center' }}>{value}</h3>
      {codeCopied ? (
        <DefaultButton
          layout={layout}
          onClick={() => {
            setCodeCopied(true);
            navigator.clipboard.writeText(value);
          }}
          icon={<CheckCircleOutlined />}
          block
        >
          Copiado
        </DefaultButton>
      ) : (
        <SubmitButton
          layout={layout}
          onClick={() => {
            setCodeCopied(true);
            navigator.clipboard.writeText(value);
          }}
          icon={<CopyOutlined />}
          block
        >
          Copiar código
        </SubmitButton>
      )}
    </>
  );
};

export default CodebillContent;
