import { List } from 'antd';
import React, { Dispatch } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Action } from 'redux';
import { LoadingOutlined } from '@ant-design/icons';
import { ApplicationState } from '../../../store';
import ProductCardItem from '../ProductCardItem';
import { loadRequestShowCase } from '../../../store/ducks/showCase/actions';
const ProductCardList: React.FC = () => {
  const layout = useSelector((state: ApplicationState) => state.layout.data);
  const data = useSelector((state: ApplicationState) => state.productsShowCase.data);
  const pagination = useSelector((state: ApplicationState) => state.productsShowCase.pagination);
  const loading = useSelector((state: ApplicationState) => state.productsShowCase.loading);
  const searchValue = useSelector((state: ApplicationState) => state.productsShowCase.searchValue);
  const dispatch = useDispatch<Dispatch<Action>>();

  return (
    <List
      style={{ margin: '10px' }}
      grid={{ gutter: 16, xs: 1, sm: 2, md: 3, lg: 3, xl: 4, xxl: 4 }}
      loading={{
        style: { color: layout?.color.default },
        spinning: loading,
        size: 'large',
        delay: 500,
        indicator: <LoadingOutlined style={{ fontSize: 24 }} spin />,
      }}
      dataSource={data}
      pagination={{
        size: 'small',
        ...pagination,
        onChange: (page, pageSize) => {
          dispatch(
            loadRequestShowCase(
              { current: page, pageSize: pageSize || 0, total: pagination?.total || 0 },
              searchValue
            )
          );
        },
      }}
      renderItem={(item: any) => {
        return (
          <List.Item key={item.CODPROD}>
            <ProductCardItem product={item} loading={loading} />
          </List.Item>
        );
      }}
    />
  );
};

export default ProductCardList;
