import React from 'react';
import { Radar } from '@ant-design/plots';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../store';
import { Serie } from '../../../store/ducks/dashboard/types';
import DataSet from '@antv/data-set';
import { maskCamelCase } from '../../../utils/masks';
import EmptyChart from '../EmptyChart';
interface Props {
  title: string;
  data: any[];
  xField: string;
  series: Serie[];
}
const RadarChart: React.FC<Props> = ({ title, data, xField, series }) => {
  const layout = useSelector((state: ApplicationState) => state.layout.data);
  const { DataView } = DataSet;
  const dv = new DataView().source(data);
  dv.transform({
    type: 'fold',
    fields: series.map((item) => item.yField?.fieldName || ''),
    key: 'user',
    value: 'score',
  });
  const config = {
    data: dv.rows,

    xField: xField,
    yField: 'score',
    seriesField: 'user',
    color: series.length === 1 ? layout?.color.default : undefined,
    meta: {
      score: {
        alias: maskCamelCase(xField),
        min: 0,
        nice: true,
        formatter: (v: string) => Number(v),
      },
    },
    xAxis: {
      tickLine: null,
    },
    yAxis: {
      label: false,
      grid: {
        alternateColor: 'rgba(0, 0, 0, 0.04)',
      },
    },
    point: {
      size: 2,
    },
    area: {},
  };
  return data.length === 0 || series.length === 0 ? (
    <EmptyChart type={'radar'} />
  ) : (
    <div style={{ padding: '20px' }}>
      <h2 style={{ color: layout?.color.dark }}>{title}</h2>
      <Radar {...config} />
    </div>
  );
};

export default RadarChart;
