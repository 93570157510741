import { action } from 'typesafe-actions';
import { ResponseError } from '../../../services/api';
import { deliveryOption, DeliveryPoint, NewDeliveryPointTypes } from './types';

export const deliveriesRequest = (userId: number, contactId: number) =>
  action(NewDeliveryPointTypes.DELIVERIES_REQUEST, { userId, contactId });
export const deliveriesSuccess = (data: deliveryOption[]) =>
  action(NewDeliveryPointTypes.DELIVERIES_SUCCESS, { data });
export const deliveriesFailure = (error: ResponseError) =>
  action(NewDeliveryPointTypes.DELIVERIES_FAILURE, { error });

export const pointRequest = (userId: string, contactId: string, id: string) =>
  action(NewDeliveryPointTypes.POINT_REQUEST, { userId, contactId, id });
export const pointSuccess = (data?: DeliveryPoint) => action(NewDeliveryPointTypes.POINT_SUCCESS, { data });
export const pointFailure = (error: ResponseError) =>
  action(NewDeliveryPointTypes.POINT_FAILURE, { error });

export const sendPointRequest = (body: any, userId: number, contactId: number, id?: string) =>
  action(NewDeliveryPointTypes.SEND_REQUEST, { body, userId, contactId, id });
export const sendPointSuccess = (data: string) =>
  action(NewDeliveryPointTypes.SEND_SUCCESS, { data });
export const sendPointFailure = (error: ResponseError) =>
  action(NewDeliveryPointTypes.SEND_FAILURE, { error });

export const setError = (error?: ResponseError) =>
  action(NewDeliveryPointTypes.SET_ERROR, { error });

export const clearSend = () => action(NewDeliveryPointTypes.CLEAR_SEND);
