import styled from 'styled-components';
import { Image } from 'antd';

export const CarouselStyled = styled.div`
  margin-left: 20px;
  margin-right: 20px;
  .ant-carousel {
    .slick-next {
      padding-top: 150px;
      padding-bottom: 150px;
      margin-top: -150px;
      &::before {
        content: '';
      }
    }
    .slick-prev {
      padding-top: 150px;
      padding-bottom: 150px;
      margin-top: -150px;
      &::before {
        content: '';
      }
    }
  }
`;

export const ImageContainer = styled.div`
  width: 260px;
  height: 260px;
  padding: 20px;
  display: flex !important;
  align-items: center;
  justify-content: center;
`;

export const ImageContent = styled(Image)`
  max-width: 100%;
  max-height: 100%;
`;
