import { Reducer } from 'redux';
import { CampaignProductsState, CampaignProductsTypes } from './types';

const INITIAL_STATE: CampaignProductsState = {
  loading: false,
  data: [],
};

const reducer: Reducer<CampaignProductsState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CampaignProductsTypes.CAMPAIGN_PRODUCTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CampaignProductsTypes.CAMPAIGN_PRODUCTS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        ...action.payload,
      };
    case CampaignProductsTypes.CAMPAIGN_PRODUCTS_FAILURE:
      return {
        ...state,
        data: [],
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export default reducer;
