import { Spin } from 'antd';
import React, { useEffect, useState } from 'react';

import { IBannerImage } from '..';
import api from '../../../services/api';
import { Container, Title, TitleContent, Image, LoadingImage } from './styles';

interface Props {
  banner: IBannerImage
}

const BannerImage: React.FC<Props> = ({ banner }) => {
  const [image, setImage] = useState<string | undefined>(banner.imageType === 'U' ? banner.imageUrl : undefined);

  useEffect(() => {
    if (banner.imageType === 'I')
      api.get(`/product-showcase/${banner.productShowcaseId}/banners/${banner.bannerId}/file`)
        .then(({ data }) => setImage(`data:image/png;base64,${data.value}`))
  }, [banner])


  return (
    <Container
      hasDots
    >
      <TitleContent hasTitle={!!banner.title}>
        {banner.title && (
          <Title >{banner.title}</Title>
        )}
      </TitleContent>

      {
        image ? (<Image hasLink={!!banner.link}
          onClick={() => (banner.link ? window.open(banner.link, '_blank')?.focus() : '')}
          src={image}
          alt="Imagem do Banner"
          title={banner.link} />) : (<LoadingImage>
            <Spin />
          </LoadingImage>
        )
      }
    </Container>
  )
}

export default BannerImage;
