import { ResponseError } from '../../../services/api';

export enum LayoutTypes {
  'LOAD_REQUEST' = '@layout/LOAD_REQUEST',
  'LOAD_SUCCESS' = '@layout/LOAD_SUCCESS',
  'LOAD_FAILURE' = '@layout/LOAD_FAILURE',
  'SCROLL' = '@layout/SCROLL',
  'RESET_SCROLL' = '@layout/RESET_SCROLL',
}
export interface Colors {
  default: string;
  light: string;
  lighter: string;
  dark: string;
}

export interface LayoutImage {
  image: string;
  type: 'BASE64' | 'URL';
}

export interface Whatsapp {
  message: string;
  show: boolean;
  telephone: string;
}
export interface Menu {
  id: number;
  showForm?: boolean;
  showList?: boolean;
  description: string;
  icon:
    | 'PicLeftOutlined'
    | 'PlusCircleOutlined'
    | 'FormOutlined'
    | 'WalletOutlined'
    | 'FileTextOutlined'
    | 'SolutionOutlined';
}

export interface Layout {
  id: number;
  color: Colors;
  favicon?: LayoutImage;
  logo?: LayoutImage;
  whatsApp?: Whatsapp;
  wallpapper?: LayoutImage;
  idCompany?: number;
  description: string;
  additionalMenus?: Menu[];
  existsAccessRequest?: boolean;
  existsProductShowcase?: boolean;
  showHelp?: boolean;
}

export interface LayoutState {
  readonly data?: Layout;
  readonly loading: boolean;
  readonly error?: ResponseError;
  readonly scroll: boolean;
}
