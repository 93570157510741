import { List, Tooltip, Image, Spin, Collapse, Row, Col, Divider } from 'antd';
import React, { Dispatch } from 'react';
import { Grid, ShoppingCartItem } from '../../store/ducks/shoppingCart/types';
import { maskCamelCase, maskCurrency } from '../../utils/masks';
import { LoadingOutlined, DeleteFilled } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../store';
import { Action } from 'typesafe-actions';
import { addItemRequest, deleteItemRequest } from '../../store/ducks/shoppingCart/actions';
import {
  Footer,
  FooterCol,
  FooterTitle,
  Header,
  ImageContainer,
  ListItem,
  ValueText,
} from './styles';
import { baseURL } from '../../services/api';
import defaultImg from '../../assets/img/default.jpg';
import Swal from 'sweetalert2';
import { useWindowSize } from '../../customHooks';
import PromoTag from '../PromoTag';
import { colors } from '../../utils/constants';
const { Panel } = Collapse;
const GridItem: React.FC<ShoppingCartItem> = (item) => {
  const layout = useSelector((state: ApplicationState) => state.layout.data);
  const { currentAction, currentActionItemId, config } = useSelector((state: ApplicationState) => state.shoppingCart);
  const dispatch = useDispatch<Dispatch<Action>>();
  const size = useWindowSize();
  const getActions = () => {
    return (size.width || 0) > 550
      ? [
        <ValueText>
          {item.grid && maskCurrency(item.grid.reduce((total, e) => total + e.total, 0))}
        </ValueText>,
        <Tooltip placement="left" title="Remover item">
          {
            currentActionItemId === item.codProd && currentAction === 'REMOVE' ? <LoadingOutlined /> :
              <DeleteFilled onClick={deleteAction} style={{ fontSize: '16px', color: 'red' }} />
          }
        </Tooltip>,
      ]
      : [];
  };
  const deleteAction = () => {
    Swal.fire({
      title: 'Atenção!',
      text: `Deseja deletar o item do carrinho?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: layout?.color.default,
      confirmButtonText: 'Continuar',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) dispatch(deleteItemRequest(item));
    });
  };

  const deleteItem = (element: Grid) => {
    if (item.grid?.length === 0) {
      dispatch(
        deleteItemRequest({
          codProd: item.codProd,
          quantity: 0,
          grid: item.grid,
          unity: item.unity,
          description: item.description,
          value: item.value,
        })
      );
    } else {
      const newQuantities = item.grid?.filter(({ index }) => index !== element.index);
      if (newQuantities?.length === 0) {
        dispatch(
          deleteItemRequest({
            codProd: item.codProd,
            unity: item.unity,
            quantity: newQuantities?.reduce((total, { quantity }) => total + quantity, 0) || 0,
            grid: newQuantities,
            description: item.description,
            value: item.value,
          })
        );
      } else {
        dispatch(
          addItemRequest({
            unity: item.unity,
            codProd: item.codProd,
            quantity: newQuantities?.reduce((total, { quantity }) => total + quantity, 0) || 0,
            grid: newQuantities,
            description: item.description,
            value: item.value,
            controlType: item.controlType
          })
        );
      }
    }
  };
  return (
    <Header>
      <Collapse ghost expandIcon={() => <></>}>
        <Panel
          key={item.codProd || 0}
          header={
            <ListItem>
              <List.Item
                extra={
                  (size.width || 0) <= 550 ? (
                    <Row gutter={[10, 10]} align="middle" justify="space-between">
                      <Col>
                        <ValueText>
                          {item.grid &&
                            maskCurrency(item.grid.reduce((total, e) => total + e.total, 0))}
                        </ValueText>
                      </Col>
                      <Col>
                        <Tooltip placement="left" title="Remover item">
                          {
                            currentActionItemId === item.codProd && currentAction === 'REMOVE' ? <LoadingOutlined /> :
                              <DeleteFilled onClick={deleteAction} style={{ fontSize: '16px', color: 'red' }} />
                          }
                        </Tooltip>
                      </Col>
                    </Row>
                  ) : undefined
                }
                actions={getActions()}
              >
                <List.Item.Meta
                  title={<h3>{item.description}</h3>}
                  avatar={
                    config?.details.viewModes.some(
                      (mode: string) => mode === 'CARD' || mode === 'LIST'
                    ) && (
                      <ImageContainer>
                        <Image
                          preview={false}
                          fallback={defaultImg}
                          loading="lazy"
                          placeholder={
                            <Spin
                              indicator={
                                <LoadingOutlined style={{ color: layout?.color.default }} />
                              }
                            />
                          }
                          style={{ maxHeight: '100%', maxWidth: '100%' }}
                          src={`${baseURL}/produtos/${item?.codProd}/imagem?w=50&h=50`}
                        />
                      </ImageContainer>
                    )
                  }
                  description={
                    <Row gutter={[5, 5]}>
                      {item.isPromo && (
                        <Col span={24}>
                          <PromoTag />
                        </Col>
                      )}
                      <Col span={24}>
                        <div>
                          {maskCurrency(item.value || 0)} {item.value ? '/' : ''}
                          {maskCamelCase(item.unity?.value || '')}
                        </div>
                        <div>
                          {item.message && (
                            <small style={{ color: colors.danger }}>{item.message}</small>
                          )}
                        </div>
                      </Col>
                    </Row>
                  }
                />
              </List.Item>
            </ListItem>
          }
        >
          {item.grid &&
            item.grid.map((element, _index) => {
              return (
                <>
                  <Row>
                    <Col span={16}>
                      <>
                        {`${element.columnLabel &&
                          element.columnLabel.slice(0, element.columnLabel.length - 1)
                          }: `}
                        <strong>{element.column}</strong>
                      </>
                      <br />
                      <>
                        {`${element.rowLabel && element.rowLabel.slice(0, element.rowLabel.length - 1)
                          }: `}
                        <strong>{element.row}</strong>
                      </>
                      <br />
                      <>
                        Preço: <strong>{maskCurrency(element?.value || 0)}</strong>
                      </>
                      <br />
                      <>
                        Quantidade: <strong>{element.quantity}</strong>
                      </>
                    </Col>
                    <Col
                      span={6}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        color: 'green',
                      }}
                    >
                      <strong>{maskCurrency(element.total)}</strong>
                    </Col>
                    <Col
                      span={2}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                      }}
                    >
                      <Tooltip placement="left" title="Remover item">
                        <h3
                          style={{ margin: 0 }}
                        >
                          {
                            currentActionItemId === item.codProd && currentAction === 'REMOVE' ? <LoadingOutlined /> :
                              <DeleteFilled
                                onClick={() => {
                                  deleteItem(element);
                                }}
                                style={{ color: 'red', cursor: 'pointer', padding: '0 8px' }}
                              />
                          }
                        </h3>
                      </Tooltip>
                    </Col>
                  </Row>
                  <Divider style={{ margin: '12px 0' }} />
                </>
              );
            })}
          <Footer>
            <FooterCol>
              <FooterTitle>QTD TOTAL</FooterTitle>
              <strong>{item.grid && item.grid.reduce((total, e) => total + e.quantity, 0)}</strong>
            </FooterCol>
            <FooterCol>
              <small>VALOR TOTAL</small>
              <strong>
                {item.grid && maskCurrency(item.grid.reduce((total, e) => total + e.total, 0))}
              </strong>
            </FooterCol>
          </Footer>
        </Panel>
      </Collapse>
      <Divider style={{ margin: 0 }} />
    </Header>
  );
};

export default GridItem;
