import React, { Dispatch, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Action } from 'typesafe-actions';
import { useHistory } from 'react-router-dom';
import { CheckCircleTwoTone, EllipsisOutlined, EditOutlined } from '@ant-design/icons';
import { ApplicationState } from '../../../store';
import { controlRequest } from '../../../store/ducks/accessControl/actions';
import CustomTable from '../../../components/CustomTable';
import { colors } from '../../../utils/constants';
import { hasPermission } from '../../../utils/permissions';
import { ROLE } from '../../../utils/constants';
import { Button, Dropdown, Menu } from 'antd';
import ServerError from '../../../components/ServerError';

interface Props {
  search: any;
}
const AccessControlTable: React.FC<Props> = ({ search }) => {
  const data = useSelector((state: ApplicationState) => state.accessControl.data);
  const loading = useSelector((state: ApplicationState) => state.accessControl.loading);
  const pagination = useSelector((state: ApplicationState) => state.accessControl.pagination);
  const error = useSelector((state: ApplicationState) => state.accessControl.error);
  const roles = useSelector((state: ApplicationState) => state.user.data?.roles);
  const history = useHistory();
  const dispatch = useDispatch<Dispatch<Action>>();

  const [columns, setColumns] = useState<any[]>([]);

  useEffect(() => {
    setColumns([
      {
        title: 'Cód. Usuário',
        dataIndex: 'id',
        key: 'id',
      },
      {
        title: 'Nome',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Email/Usuário',
        dataIndex: 'email',
        key: 'email',
      },
      {
        title: 'Ativo',
        dataIndex: 'active',
        key: 'active',
        render: (_text: any, record: any) => {
          return (
            <>
              {record.active ? (
                <CheckCircleTwoTone style={{ fontSize: '20px' }} twoToneColor={colors.success} />
              ) : (
                <CheckCircleTwoTone style={{ fontSize: '20px' }} twoToneColor={colors.grey} />
              )}
            </>
          );
        },
      },
      {
        title: 'Ações',
        key: 'action',
        render: (record: any) => {
          return (
            <Dropdown
              trigger={['click']}
              overlay={
                <Menu>
                  <Menu.Item>
                    <Button
                      data-cy="btn-edit"
                      icon={<EditOutlined />}
                      type="text"
                      disabled={!hasPermission([ROLE.ACCESS_CONTROL], roles)}
                      onClick={() => {
                        history.push(`controle-acessos/usuario/${record.id}`);
                      }}
                    >
                      Editar
                    </Button>
                  </Menu.Item>
                </Menu>
              }
            >
              <Button
                data-cy="btn-more"
                type="link"
                style={{ padding: 0 }}
                onClick={(e) => e.preventDefault()}
              >
                <EllipsisOutlined style={{ fontSize: '24px' }} rotate={90} />
              </Button>
            </Dropdown>
          );
        },
      },
    ]);
  }, [history, roles]);

  return (
    <>
      {error ? (
        <ServerError {...error} />
      ) : (
        <CustomTable
          style={{ padding: '20px' }}
          onChange={(pg: any) => {
            dispatch(controlRequest(pg, search));
          }}
          pagination={pagination}
          loading={loading}
          columns={columns}
          data={data}
        />
      )}
    </>
  );
};

export default AccessControlTable;
