import { Col, Divider, Row, Tabs, Tag } from 'antd';
import React, { Dispatch, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../store';
import { UnorderedListOutlined, AppstoreOutlined } from '@ant-design/icons';
import { PageContent } from './styles';
import ProductList from './ProductList';
import ProductCardList from './ProductCardList';
import CheckCollapse from '../../components/CheckCollapse';
import { colors } from '../../utils/constants';
import { Action } from 'typesafe-actions';
import { loadRequestShowCase, setFilter, setSearch } from '../../store/ducks/showCase/actions';
import { Category } from '../../store/ducks/showCase/types';
import { Size, useWindowSize } from '../../customHooks';
const { TabPane } = Tabs;

const ShowCase: React.FC<any> = () => {
  const layout = useSelector((state: ApplicationState) => state.layout.data);
  const categories = useSelector((state: ApplicationState) => state.productsShowCase.categories);
  const searchValue = useSelector((state: ApplicationState) => state.productsShowCase.searchValue);
  const filters = useSelector((state: ApplicationState) => state.productsShowCase.filters);

  const size: Size = useWindowSize();

  const [tags, setTags] = useState<JSX.Element[]>([]);

  const dispatch = useDispatch<Dispatch<Action>>();

  useEffect(() => {
    const newTags: JSX.Element[] = [];
    categories.forEach((category: Category) => {
      return category.list.length > 0 ? (
        category.list
          .filter((item) => filters.includes(item.key))
          .forEach((item) =>
            newTags.push(
              <Tag
                closable
                onClose={(e) => {
                  e.preventDefault();
                  dispatch(
                    setFilter(filters.filter((filter: string | number) => filter !== item.key))
                  );
                }}
              >
                {item.value}
              </Tag>
            )
          )
      ) : filters.includes(category.key) ? (
        newTags.push(
          <Tag
            closable
            onClose={(e) => {
              e.preventDefault();
              dispatch(
                setFilter(filters.filter((filter: string | number) => filter !== category.key))
              );
            }}
          >
            {category.value}
          </Tag>
        )
      ) : (
        <></>
      );
    });
    setTags(newTags);
  }, [categories, dispatch, filters]);

  useEffect(() => {
    dispatch(loadRequestShowCase(undefined, searchValue));
  }, [dispatch, searchValue, filters]);

  return (
    <PageContent>
      <Row gutter={[10, 10]}>
        {categories.length > 0 && (size?.width || 0) > 1024 && (
          <Col span={4} style={{ borderRight: `1px solid ${colors.grey}` }}>
            <h2 style={{ color: layout?.color.dark }}>Categorias</h2>
            {categories.map((category: Category) => {
              return (
                <>
                  <CheckCollapse category={category} />
                  <Divider style={{ margin: 0 }} />
                </>
              );
            })}
          </Col>
        )}

        <Col span={categories.length > 0 && (size?.width || 0) > 1024 ? 20 : 24}>
          {(searchValue || filters.length > 0) && (
            <Row gutter={[5, 5]} align="middle">
              <Col>
                <h3 style={{ margin: 0 }}>Resultados para: </h3>
              </Col>
              {searchValue && (
                <Col>
                  <Tag
                    closable
                    onClose={(e) => {
                      e.preventDefault();
                      dispatch(setSearch(''));
                    }}
                  >
                    {`"${searchValue}"`}
                  </Tag>
                </Col>
              )}
              {tags.map((item) => item)}
            </Row>
          )}
          <Tabs defaultActiveKey={'1'} size="small">
          <TabPane
              tab={
                <div data-cy="tab-cardView">
                  <AppstoreOutlined style={{ marginLeft: '16px' }} />
                </div>
              }
              key="1"
            >
              <ProductCardList />
            </TabPane>

            <TabPane
              tab={
                <div data-cy="tab-listView">
                  <UnorderedListOutlined style={{ marginLeft: '16px' }} />
                </div>
              }
              key="2"
            >
              <ProductList />
            </TabPane>
          </Tabs>
        </Col>
      </Row>
    </PageContent>
  );
};

export default ShowCase;
