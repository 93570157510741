import { action } from 'typesafe-actions';
import { ScreenEdit, ScreenEditTypes, SendObject, Success } from './types';
import { ResponseError } from '../../../services/api';

export const EditRequest = (id?: string, screenId?: string) =>
  action(ScreenEditTypes.LOAD_REQUEST, { id, screenId });
export const loadSuccess = (data: ScreenEdit[]) => action(ScreenEditTypes.LOAD_SUCCESS, { data });
export const loadFailure = (error: ResponseError) =>
  action(ScreenEditTypes.LOAD_FAILURE, { error });

export const sendEditRequest = (id?: string, screenId?: string, values?: SendObject[]) =>
  action(ScreenEditTypes.SEND_REQUEST, { id, values, screenId });
export const sendSuccess = (success: Success) => action(ScreenEditTypes.SEND_SUCCESS, { success });
export const sendFailure = (sendError: ResponseError) =>
  action(ScreenEditTypes.SEND_FAILURE, { sendError });

export const cleanEditState = () => action(ScreenEditTypes.CLEAN_STATE);
