export const PASSWORD_REGEX = new RegExp(
  '^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})'
);

export const PASSWORD_VALIDATOR: any[] = [
  {
    min: 6,
    message:
      'A senha deve ter entre 6 e 10 caracteres e deve conter ao menos um número e uma letra!',
  },
  {
    max: 10,
    message:
      'A senha deve ter entre 6 e 10 caracteres e deve conter ao menos um número e uma letra!',
  },
  () => ({
    validator(value: string) {
      if (!value || value.length < 6 || value.length > 10 || PASSWORD_REGEX.test(value)) {
        return Promise.resolve();
      }
      return Promise.reject(
        'A senha deve ter entre 6 e 10 caracteres e deve conter ao menos um número e uma letra!'
      );
    },
  }),
];

export const testCPF = (cpf: string) => {
  if (!cpf) return false;
  cpf = cpf?.replace(/[^\d]+/g, '');
  if (
    cpf.length !== 11 ||
    cpf === '00000000000' ||
    cpf === '11111111111' ||
    cpf === '22222222222' ||
    cpf === '33333333333' ||
    cpf === '44444444444' ||
    cpf === '55555555555' ||
    cpf === '66666666666' ||
    cpf === '77777777777' ||
    cpf === '88888888888' ||
    cpf === '99999999999'
  )
    return false;
  let add = 0;
  for (let i = 0; i < 9; i++) add += parseInt(cpf.charAt(i)) * (10 - i);
  let rev = 11 - (add % 11);
  if (rev === 10 || rev === 11) rev = 0;
  if (rev !== parseInt(cpf.charAt(9))) return false;
  add = 0;
  for (let i = 0; i < 10; i++) add += parseInt(cpf.charAt(i)) * (11 - i);
  rev = 11 - (add % 11);
  if (rev === 10 || rev === 11) rev = 0;
  if (rev !== parseInt(cpf.charAt(10))) return false;
  return true;
};

export const testCNPJ = (cnpj: string) => {
  if (!cnpj) return false;
  cnpj = cnpj.replace(/[^\d]+/g, '');

  if (cnpj.length !== 14) return false;

  if (
    cnpj === '00000000000000' ||
    cnpj === '11111111111111' ||
    cnpj === '22222222222222' ||
    cnpj === '33333333333333' ||
    cnpj === '44444444444444' ||
    cnpj === '55555555555555' ||
    cnpj === '66666666666666' ||
    cnpj === '77777777777777' ||
    cnpj === '88888888888888' ||
    cnpj === '99999999999999'
  )
    return false;

  let size = cnpj.length - 2;
  let numbers = cnpj.substring(0, size);
  let digits = cnpj.substring(size);
  let sum = 0;
  let position = size - 7;
  for (let i = size; i >= 1; i--) {
    sum += parseInt(numbers.charAt(size - i)) * position--;
    if (position < 2) position = 9;
  }
  let result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
  if (result !== parseInt(digits.charAt(0))) return false;

  size = size + 1;
  numbers = cnpj.substring(0, size);
  sum = 0;
  position = size - 7;
  for (let i = size; i >= 1; i--) {
    sum += parseInt(numbers.charAt(size - i)) * position--;
    if (position < 2) position = 9;
  }
  result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
  if (result !== parseInt(digits.charAt(1))) return false;

  return true;
};

export const CONFIRM_PASSWORD_VALIDATOR = (field: any) => {
  return [
    ({ getFieldValue }: any) => ({
      validator(value: any) {
        if (!value || getFieldValue(field) === getFieldValue(value.field.split('.'))) {
          return Promise.resolve();
        }
        return Promise.reject('As senhas não conferem!');
      },
    }),
  ];
};
