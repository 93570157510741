import { call, put, select } from 'redux-saga/effects';
import jwt from 'jsonwebtoken';
import {
  loadSuccess,
  loadFailure,
  loadRequest,
  loadPhotoSuccess,
  loadPhotoFailure,
  loadPartnersSuccess,
  loadPartnersFailure,
  updatePhotoRequest,
  updatePhotoSuccess,
  updatePhotoFailure,
  deletePhotoFailure,
  deletePhotoSuccess,
  updateRequest,
  passwordRequest,
  updateFailure,
  passwordFailure,
} from './actions';
import { requestHeaders } from '../../../utils/apiHelper';
import { ApplicationState } from '../..';
import api from '../../../services/api';
import { refreshRequest, loginSuccess } from '../auth/actions';
import Swal from 'sweetalert2';
import { AxiosResponse } from 'axios';
import { Partner, User } from './types';

export function* loadUser({ payload }: ReturnType<typeof loadRequest>) {
  try {
    const response: { data: User } = yield call(jwt.decode, payload.token || '');
    yield put(loadSuccess(response.data));
  } catch (err: any) {
    yield put(loadFailure());
  }
}

export function* updateUser({ payload }: ReturnType<typeof updateRequest>) {
  try {
    const state: ApplicationState = yield select();
    const headers = requestHeaders(state.auth.token);
    const response: AxiosResponse<{ ['access_token']: string; ['refresh_token']: string }> =
      yield call(
        api.put,
        'mydata/update',
        {
          name: payload.name,
          contact: {
            email: payload?.email,
            phone: payload?.phone,
          },
          budget: {
            contributionMargin: payload?.contributionMargin,
          },
        },
        {
          headers: headers,
        }
      );
    yield put(loginSuccess(response.data['access_token'], response.data['refresh_token']));
    Swal.fire({
      title: 'Sucesso!',
      confirmButtonColor: state.layout.data?.color.default,
      html: 'Dados alterados com sucesso!',
      icon: 'success',
    });
  } catch (err: any) {
    if (err.response.status === 401) {
      yield put(refreshRequest());
    } else {
      const state: ApplicationState = yield select();
      Swal.fire({
        title: 'Erro!',
        confirmButtonColor: state.layout.data?.color.default,
        html: err.response.data.message,
        icon: 'error',
      });
      yield put(updateFailure());
    }
  }
}

export function* updatePassword({ payload }: ReturnType<typeof passwordRequest>) {
  try {
    const state: ApplicationState = yield select();
    const headers = requestHeaders(state.auth.token);
    const response: AxiosResponse<{ message: string }> = yield call(
      api.put,
      '/mydata/update/password',
      payload,
      {
        headers: headers,
      }
    );
    Swal.fire({
      title: 'Sucesso!',
      confirmButtonColor: state.layout.data?.color.default,
      html: response.data.message,
      icon: 'success',
    });
  } catch (err: any) {
    const state: ApplicationState = yield select();
    if (err.response.status === 401) {
      yield put(refreshRequest());
    } else {
      Swal.fire({
        title: 'Erro!',
        confirmButtonColor: state.layout.data?.color.default,
        html: err.response.data.message,
        icon: 'error',
      });
      yield put(passwordFailure());
    }
  }
}

export function* loadUserPhoto() {
  try {
    const state: ApplicationState = yield select();
    const headers = requestHeaders(state.auth.token);
    const response: AxiosResponse<any> = yield call(api.get, '/mydata/image', {
      headers: headers,
      responseType: 'arraybuffer',
    });
    const image = btoa(
      new Uint8Array(response.data).reduce((data, byte) => data + String.fromCharCode(byte), '')
    );
    yield put(loadPhotoSuccess(`data:image/png;base64,${image}`));
  } catch (err: any) {
    yield put(err.response.status !== 401 ? loadPhotoFailure() : refreshRequest());
  }
}

export function* loadPartners() {
  try {
    const state: ApplicationState = yield select();
    const headers = requestHeaders(state.auth.token);
    const response: AxiosResponse<Partner[]> = yield call(api.get, '/partnerchange/partners', {
      headers: headers,
    });
    yield put(loadPartnersSuccess(response.data));
  } catch (err: any) {
    yield put(err.response.status !== 401 ? loadPartnersFailure() : refreshRequest());
  }
}

export function* updatePhoto({ payload }: ReturnType<typeof updatePhotoRequest>) {
  try {
    const state: ApplicationState = yield select();
    const bodyFormData = new FormData();
    bodyFormData.append('image', payload.photo.file);
    const headers = requestHeaders(state.auth.token, 'multipart/form-data');
    yield call(api.put, '/mydata/image', bodyFormData, { headers: headers });
    yield put(updatePhotoSuccess(`data:image/png;base64,${payload.photo.image}`));
  } catch (err: any) {
    yield put(err.response.status !== 401 ? updatePhotoFailure() : refreshRequest());
  }
}

export function* deleteUserPhoto() {
  try {
    const state: ApplicationState = yield select();
    const headers = requestHeaders(state.auth.token);
    yield call(api.delete, '/mydata/image', {
      headers: headers,
    });
    yield put(deletePhotoSuccess());
  } catch (err: any) {
    yield put(err.response.status !== 401 ? deletePhotoFailure() : refreshRequest());
  }
}
