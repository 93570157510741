import { action } from 'typesafe-actions';
import { ResponseError } from '../../../services/api';
import { Campaign } from '../campaigns/types';
import { HomeCampaignsTypes } from './types';

export const requestHomeCampaigns = () => action(HomeCampaignsTypes.HOME_CAMPAIGNS_REQUEST);
export const successHomeCampaigns = (data: Campaign[]) =>
  action(HomeCampaignsTypes.HOME_CAMPAIGNS_SUCCESS, { data });
export const failureHomeCampaigns = (error: ResponseError) =>
  action(HomeCampaignsTypes.HOME_CAMPAIGNS_FAILURE, { error });
