import React from 'react';
import { Form, Modal } from 'antd';
import { useSelector } from 'react-redux';

import { Container } from '../../../styles';
import DynamicFields from '../../../components/DynamicFields';
import { ActionParamsFormProps } from './props';
import { SubmitButton } from '../../../styles';
import { ApplicationState } from '../../../store';

const ActionParamsForm: React.FC<ActionParamsFormProps> = ({ dataFields, onFinish, visible, setVisible }) => {
  const layout = useSelector((state: ApplicationState) => state.layout.data);
  const [form] = Form.useForm();

  return (
    <Modal
      centered
      destroyOnClose
      title="Parâmetros da ação"
      cancelText="Fechar"
      visible={visible}
      onCancel={() => setVisible(false)}
      footer={
        <Form layout="vertical" onFinish={onFinish} form={form}>
          <Form.Item style={{ textAlign: 'right' }}>
            <SubmitButton
              data-cy="btn-generateReport"
              layout={layout}
              htmlType="submit"
            >
              Gerar
            </SubmitButton>
          </Form.Item>
        </Form>
      }
    >
      <Container layout={layout}>
        <Form layout="vertical" onFinish={onFinish} form={form}>
          {dataFields.map((field) => (
            <DynamicFields
              key={field.key}
              type={field.type}
              label={field.description}
              name={field.key}
              list={field.options}
              required={field.required}
            />
          )
          )}
        </Form>
      </Container>
    </Modal>
  )
}

export default ActionParamsForm;
