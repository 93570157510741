import React, { Dispatch } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Col, Row, Select } from 'antd';
import { baseURL } from '../../services/api';

import {
  Actions,
  ActiveFavoriteIcon,
  CardContainer,
  CartFavorite,
  CartTitle,
  Description,
  AdditionalInfos,
  FavoriteIcon,
  Stock,
  Value,
} from './styles';
import { ApplicationState } from '../../store';
import { maskCamelCase, maskCurrency } from '../../utils/masks';
import QuantityInput from '../QuantityInput';
import { Action } from 'typesafe-actions';
import { favoriteRequest, setUnity } from '../../store/ducks/products/actions';
import MetreageCalculator from '../NewOrderModals/MetreageCalculator';
import ModalGrid from '../NewOrderModals/ModalGrid';
import { Unity } from '../../store/ducks/shoppingCart/types';
import { useHistory } from 'react-router-dom';
import MattressModal from '../NewOrderModals/MattressModal';
import ImageCarousel from './ProductCarousel';
import PromoTag from '../PromoTag';
import { getFixedFieldDescription } from '../../utils/newTableHelper';

export interface Props {
  product: any;
  margin?: string;
  loading?: boolean;
}

const { Option } = Select;

const ProductCard: React.FC<Props> = ({ product, margin }) => {
  const config = useSelector((state: ApplicationState) => state.products.config);
  const shoppingCart = useSelector((state: ApplicationState) => state.shoppingCart.cart);
  const layout = useSelector((state: ApplicationState) => state.layout.data);
  const dispatch = useDispatch<Dispatch<Action>>();

  const getUnity = (): Unity => {
    const unity = shoppingCart.find((element) => element.codProd?.toString() === product?.CODPROD?.toString())?.unity;

    if (unity) product?.UNIDADE?.find((e: Unity) => unity?.key === e.key);

    return product?.UNIDADE?.find((e: Unity) => product?.SELECTED_UNITY === e.key);
  };

  const images: string[] =
    product.DETAILS.alternativeImages.length > 0
      ? [
        `${baseURL}/produtos/${product?.pkValue}/imagem?w=260&h=260`,
        ...product.DETAILS.alternativeImages.map(
          (item: any) =>
            `${baseURL}/produtos/${product?.pkValue}/imagens/alternativas/${item.key}?w=260&h=260`
        ),
      ]
      : [`${baseURL}/produtos/${product?.pkValue}/imagem?w=260&h=260`];

  const history = useHistory();

  const handleClick = (id: number) => {
    history.push(`/home/novo-pedido/produto/${id}/detalhes`);
  };

  const getStock = () =>
    Math.floor(
      product?.ESTOQUE?.find(
        ({ key }: any) =>
          key ===
          (shoppingCart.find((element) => element.codProd === product?.pkValue)?.unity?.key ||
            product?.SELECTED_UNITY)
      )?.value || 0
    );

  const getValue = () => {
    if (shoppingCart.length > 0) {
      const dataInCart = shoppingCart.find(cartItem => String(cartItem.codProd) === product.pkValue);

      if (dataInCart)
        return dataInCart.value
    }

    return product?.VALOR?.find(
      ({ key }: any) =>
        key ===
        (shoppingCart.find((element) => element.codProd === product?.pkValue)?.unity?.key ||
          product?.SELECTED_UNITY)
    )?.value || 0;
  }

  return (
    <>
      <Card
        data-cy="productCard"
        style={{
          margin: margin,
          borderRadius: '5px',
          borderTop: product?.PROMOCAO?.isPromo ? `5px solid ${layout?.color.default}` : 0,
          minWidth: '100%',
        }}
        hoverable
        onClick={() => handleClick(product?.pkValue)}
      >
        <CardContainer>
          <ImageCarousel images={images} />
          <div>
            <CartTitle>
              <div onClick={(e) => e.stopPropagation()}>
                <CartFavorite
                  onClick={() => dispatch(favoriteRequest(product?.pkValue, !product?.FAVORITO))}
                >
                  {product?.FAVORITO ? (
                    <ActiveFavoriteIcon data-cy="iconFiled" />
                  ) : (
                    <FavoriteIcon data-cy="iconOutlined" />
                  )}
                </CartFavorite>
              </div>
              <Stock>
                {product?.CODPROD_SHOW && `${product?.CODPROD} - `}
                {product?.ESTOQUE_SHOW && (
                  <>
                    Disponível:
                    <strong style={{ marginLeft: '5px', marginRight: '5px' }}>{getStock()}</strong>
                  </>
                )}
              </Stock>
            </CartTitle>
            <Description title={product?.DESCRPROD}>{product?.DESCRPROD}</Description>
            {(product?.REFERENCIA ||
              product?.FORNEC ||
              product?.MARCA) && (
                <AdditionalInfos>
                  {product?.MARCA && <div>{getFixedFieldDescription('MARCA', 'Marca', config?.fields)}: <strong>{product?.MARCA}</strong></div>}
                  {product?.REFERENCIA && <div>{getFixedFieldDescription('REFERENCIA', 'Referência', config?.fields)}: <strong>{product?.REFERENCIA}</strong></div>}
                  {product?.FORNEC && <div>{getFixedFieldDescription('FORNEC', 'Fornecedor', config?.fields)}: <strong>{product?.FORNEC}</strong></div>}
                </AdditionalInfos>
              )}
            <Row align="middle" gutter={[10, 10]} style={{ marginBottom: '10px' }}>
              {product?.PROMOCAO?.isPromo && (
                <Col>
                  <PromoTag />
                </Col>
              )}
              <Col>
                <Value>
                  {product?.DETAILS.stockControlType === 'GRADE' && getValue() === 0
                    ? 'Indisponível'
                    : maskCurrency(getValue())}
                </Value>
              </Col>
            </Row>
            <Actions>
              <div onClick={(e) => e.stopPropagation()}>
                <Select
                  data-cy="input-selectUnity"
                  defaultValue={product?.SELECTED_UNITY}
                  value={
                    shoppingCart.find((element) => element.codProd === product?.pkValue)?.unity
                      ?.key || product?.SELECTED_UNITY
                  }
                  onChange={(value) => dispatch(setUnity(product?.pkValue, value))}
                  disabled={!!shoppingCart.find((element) => element.codProd === product?.pkValue)}
                >
                  {product?.UNIDADE?.map((element: any) => {
                    return (
                      <Option key={element.key} value={element.key}>
                        {maskCamelCase(element.value)}
                      </Option>
                    );
                  })}
                </Select>
              </div>
              <div onClick={(e) => e.stopPropagation()}>
                {!product?.USAMETRAGEM &&
                  product?.DETAILS.stockControlType !== 'GRADE' &&
                  product.DETAILS.stockControlType !== 'AFC' && (
                    <QuantityInput
                      description={product?.DESCRPROD}
                      codProd={product?.pkValue}
                      quantity={0}
                      stock={getStock()}
                      unity={getUnity()}
                      isPromo={product?.PROMOCAO?.isPromo}
                      value={getValue()}
                      hasStock={product?.ESTOQUE_SHOW}
                      message={getUnity().message}
                      multiplier={getUnity().multiple}
                      isDecimal={!!getUnity().decimalPlaces}
                      decimalQtd={getUnity().decimalPlaces}
                      controlType={product?.DETAILS?.stockControlType}
                    />
                  )}
                {product?.USAMETRAGEM && <MetreageCalculator product={product} />}
                {product?.DETAILS.stockControlType === 'GRADE' && (
                  <ModalGrid product={product} disabled={getValue() === 0} />
                )}
                {product?.DETAILS.stockControlType === 'AFC' && <MattressModal product={product} />}
              </div>
            </Actions>
          </div>
        </CardContainer>
      </Card>
    </>
  );
};

export default ProductCard;
