import React, { useEffect, useState, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Dropdown, Menu, message } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { EllipsisOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { Action } from 'typesafe-actions';
import moment from 'moment';
import Swal from 'sweetalert2';

import api from '../../../services/api';
import { ApplicationState } from '../../../store';
import CustomTable from '../../../components/CustomTable';
import ServerError from '../../../components/ServerError';
import ReportsDropdown from '../../../components/ReportsDropdown';
import { Config, configToColumnsType, Data, dataToTableData, filterParamsHandle, KeyValue, sorterParamsHandle } from '../../../utils/newTableHelper';
import { requestHeaders } from '../../../utils/apiHelper';
import ActionParametersValues from '../ActionParamsForm';
import { Pagination } from '../../../types/pagination';
import ShowActionReport from '../ShowActionReport';
import { refreshRequest } from '../../../store/ducks/auth/actions';
import { downloadFile } from '../../../utils/fileHelper';

interface Props {
  search?: string;
  screenId?: number;
  appearance?: 'home' | 'child';
  params?: {
    name: string,
    value: any
  }[];
}

export interface ResponseAction {
  message: string;
  report?: string;
  returnType: "MESSAGE" | 'REPORT' | 'REFRESH_LISTING' | 'DOWNLOAD';
  title: string;
  fileExtension?: string,
  file?: string,
}

const ScreenTable: React.FC<Props> = ({ search, screenId, appearance, params }) => {
  const { id: idParam }: any = useParams();
  const id = screenId || idParam;

  const dispatch = useDispatch<Dispatch<Action>>();
  const layout = useSelector((state: ApplicationState) => state.layout.data);
  const token = useSelector((state: ApplicationState) => state.auth.token);

  const [config, setConfig] = useState<Config>();
  const [loadingConfig, setloadingConfig] = useState(true);
  const [data, setData] = useState<Data[]>([]);
  const [loadingData, setLoadingData] = useState(true);
  const [pagination, setPagination] = useState<Pagination>();
  const [filters, setFilters] = useState<any[]>([])
  const [sorter, setSorter] = useState<any>()
  const [hasError, setHasError] = useState(false);


  const editRole = useSelector(
    (state: ApplicationState) =>
      state.additionalScreens.screens.find((item) => item.id === parseInt(id))?.edit
  );
  const detailRole = useSelector(
    (state: ApplicationState) =>
      state.additionalScreens.screens.find((item) => item.id === parseInt(id))?.showDetail
  );
  const [columns, setColumns] = useState<ColumnsType<any>>([]);

  const history = useHistory();

  const actions = config?.fields.find(field => field.id === -2);
  const [dataFieldsAction, setDataFieldsAction] = useState<any[]>([]);
  const [actionsParamsFormVisible, setActionsParamsFormVisible] = useState(false);
  const [actionReportVisible, setActionReportVisible] = useState(false);
  const [action, setActionId] = useState<KeyValue>()
  const [pkValue, setPkValue] = useState<any>()
  const [reportResponse, setReportResponse] = useState<ResponseAction>();

  const getConfig = useCallback(
    async (id: number) => {
      try {
        const headers = requestHeaders(token);
        const { data } = await api.get(`/screen/${id}/config`, {
          headers: headers,
        });

        setConfig(data);
      } catch (error: any) {
        if (error?.response?.status === 401) {
          dispatch(refreshRequest())
        } else {
          setHasError(true);
        }
      } finally {
        setloadingConfig(false);
      }
    },
    [token, dispatch],
  );


  const getData = useCallback(
    async (page = 0, size = 12, filters?: any, sort?: any, search?: string) => {
      try {
        setLoadingData(true);

        const headers = requestHeaders(token);
        const { data } = await api.post(`/screen/${id}/all`, {
          page,
          size,
          filters: filterParamsHandle(filters),
          sort: sorterParamsHandle(sort),
          search,
          params
        }, {
          headers: headers,
        });

        setData(data.content || []);
        setPagination({
          total: data.numberOfElements,
          current: data.number + 1,
          pageSize: data.size,
        });
        setFilters(filters);
        setSorter(sort);
      } catch (error: any) {
        if (error?.response?.status === 401) {
          dispatch(refreshRequest())
        } else {
          setHasError(true);
        }
      } finally {
        setLoadingData(false);
      }
    },
    [id, token, params, dispatch],
  );

  const callAction = useCallback(
    async (actionId: number, pkValue: any, parameters: any[] = []) => {
      try {
        const headers = requestHeaders(token);
        const { data } = await api.post<ResponseAction>(`screen/${id}/registry/${pkValue}/action/${actionId}`, parameters, { headers: headers });

        if (data.returnType === 'MESSAGE')
          Swal.fire({
            title: data.title,
            confirmButtonColor: layout?.color.default,
            html: data.message,
            icon: 'success',
          });
        else if (data.returnType === 'REFRESH_LISTING') {
          getData();
        }
        else if (data.returnType === 'DOWNLOAD') {
          if (data.file && data.fileExtension) {
            downloadFile(data.file, `arquivo`, data.fileExtension);
          } else {
            Swal.fire({
              title: 'Nenhum arquivo disponível para download',
              confirmButtonColor: layout?.color.default,
              icon: 'warning',
            });
          }
        }
        else {
          setReportResponse(data);
          setActionReportVisible(true);
        }
      } catch {
        Swal.fire({
          title: 'Erro!',
          confirmButtonColor: layout?.color.default,
          html: 'Erro ao executar ação',
          icon: 'error',
        });
      }
    },
    [id, layout, token, getData],
  );

  const handleClickAction = useCallback(async (action: KeyValue, pkValue: any) => {
    try {
      const hide = message.loading('Executando ação: ' + action.value, 0);

      setActionId(action);
      setPkValue(pkValue);

      const headers = requestHeaders(token);
      const { data } = await api.get(`screen/${id}/actions/${action.key}/parameters `, { headers: headers });

      if (data.length === 0) {
        await callAction(action.key, pkValue);
      }
      else {
        setDataFieldsAction(data);
        setActionsParamsFormVisible(true);
      }

      hide();
    } catch (err: any) {
      Swal.fire({
        title: 'Erro!',
        confirmButtonColor: layout?.color.default,
        html: 'Erro ao executar ação',
        icon: 'error',
      });
    }
  },
    [layout, id, token, callAction],
  )

  const onSubmitActionParamsForm = async (values = {}) => {
    if (!action || !pkValue) return;

    const parameters: any[] = [];
    Object.entries(values).forEach(([key, value]) => {
      parameters.push({
        key,
        value: value instanceof moment ? moment(value).format('DD/MM/YYYY HH:mm') : value
      });
    });

    const hide = message.loading('Executando ação: ' + action.value, 0);

    await callAction(action.key, pkValue, parameters);
    setDataFieldsAction([]);
    setActionsParamsFormVisible(false);

    hide();
  }

  const handleCloseActionReport = () => {
    setReportResponse(undefined);
    setActionReportVisible(false);
  }

  useEffect(() => {
    getConfig(parseInt(id));
  }, [getConfig, id]);

  useEffect(() => {
    getData();
  }, [getData, id]);

  useEffect(() => {
    if (search !== undefined)
      getData(0, pagination?.pageSize, filters, sorter, search);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search])


  useEffect(() => {
    setColumns(
      configToColumnsType(
        config?.fields.filter((item) => item.show) || [],
        [
          {
            id: -1,
            render: (value: any, record: any) => (
              <ReportsDropdown
                value={value}
                pdfUrl={`screen/${id}/registry/${record.pkValue}/report`}
              />
            ),
          },
        ],
        editRole || detailRole || (actions && actions.options.length > 0)
          ? [
            {
              title: 'Ações',
              key: 'action',
              render: (record: any) => {
                const rowActions: KeyValue[] = record.ACOES || [];

                return (
                  <Dropdown

                    trigger={['click']}
                    overlay={
                      <Menu>
                        {editRole && (
                          <Menu.Item>
                            <Button
                              data-cy="btn-Edit"
                              icon={<EditOutlined />}
                              type="text"
                              onClick={() =>
                                history.push(`/home/screen/${id}/edit/${record.pkValue}`)
                              }
                            >
                              Editar
                            </Button>
                          </Menu.Item>
                        )}
                        {detailRole && (
                          <Menu.Item>
                            <Button
                              data-cy="btn-Detail"
                              icon={<EyeOutlined />}
                              type="text"
                              onClick={() =>
                                history.push(`/home/screen/${id}/detail/${record.pkValue}`)
                              }
                            >
                              Ver
                            </Button>
                          </Menu.Item>
                        )}

                        {
                          rowActions.map(action => (
                            <Menu.Item key={action.key}>
                              <Button
                                type="text"
                                onClick={() => handleClickAction(action, record.pkValue)}
                              >
                                {action.value}
                              </Button>
                            </Menu.Item>
                          ))
                        }
                      </Menu>
                    }
                  >
                    <Button
                      data-cy="btn-more"
                      type="link"
                      style={{ padding: 0 }}
                      onClick={(e) => e.preventDefault()}
                    >
                      <EllipsisOutlined style={{ fontSize: '24px' }} rotate={90} />
                    </Button>
                  </Dropdown>
                );
              },
            },
          ]
          : undefined,
        [0, -1]
      )
    );
  }, [config?.fields, detailRole, editRole, history, id, actions, handleClickAction]);

  useEffect(() => {
    setloadingConfig(true);
    setLoadingData(true);
    setColumns([]);
    setData([])
  }, [id]);


  return (
    <>
      {hasError ? (
        <ServerError />
      ) : (
        <CustomTable
          style={{ padding: appearance === 'home' ? '20px' : 0 }}
          onChange={(pagination: any, filters: any, sorter: any) => {
            getData((pagination.current || 1) - 1, pagination.pageSize, filters, sorter, search);
          }}
          loading={loadingConfig || loadingData}
          columns={columns}
          pagination={pagination}
          data={dataToTableData(data, config)}
        />
      )}

      {actionsParamsFormVisible && <ActionParametersValues
        onFinish={values => onSubmitActionParamsForm(values)}
        dataFields={dataFieldsAction}
        visible={actionsParamsFormVisible}
        setVisible={setActionsParamsFormVisible}
      />}

      <ShowActionReport resportResponse={reportResponse} visible={actionReportVisible} onCancel={handleCloseActionReport} />
    </>
  );
};

export default ScreenTable;
