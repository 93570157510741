import { ResponseError } from "../../../services/api";
import { Config, Data } from "../../../utils/newTableHelper";

export enum FinancialTypes {
  'LOAD_REQUEST' = '@financial/LOAD_REQUEST',
  'LOAD_SUCCESS' = '@financial/LOAD_SUCCESS',
  'LOAD_FAILURE' = '@financial/LOAD_FAILURE',
}

export interface Pagination {
  current: number;
  total: number;
  pageSize: number;
}

export interface FinancialState {
  readonly data: Data[];
  readonly config?: Config;
  readonly pagination?: Pagination;
  readonly loading: boolean;
  readonly error?: ResponseError;
}
