import { Modal, Input, List, Radio } from 'antd';
import React, { Dispatch, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Action } from 'typesafe-actions';
import { Description } from './styles';
import { ApplicationState } from '../../store';
import { Address } from '../../store/ducks/shoppingCart/types';
import { selectAddress } from '../../store/ducks/shoppingCart/actions';
import { maskCamelCase, maskCurrency } from '../../utils/masks';
import { DefaultButton, SubmitButton } from '../../styles';

const { Search } = Input;

export interface Props {
  visible: boolean;
  setVisible: any;
}

const AddressModal: React.FC<Props> = ({ visible, setVisible }) => {
  const addresses = useSelector((state: ApplicationState) => state.shoppingCart.addresses);
  const address = useSelector((state: ApplicationState) => state.shoppingCart.selectedAddress);
  const loading = useSelector((state: ApplicationState) => state.shoppingCart.addressLoading);
  const layout = useSelector((state: ApplicationState) => state.layout.data);
  const [searchValue, setSearchValue] = useState<string>('');
  const [addressList, setAddressList] = useState<Address[]>(addresses);
  const [selectedAddress, setSelectedAddress] = useState<Address | undefined>(address);

  const dispatch = useDispatch<Dispatch<Action>>();

  const onSearch = useCallback(() => {
    const filteredAddresses = addresses.filter((item) => {
      const descriptionDeliveryAddress = item.descriptionDeliveryAddress?.toUpperCase();
      const contactName = item.contactName?.toUpperCase();
      return (
        descriptionDeliveryAddress?.includes(searchValue?.toUpperCase()) ||
        contactName?.includes(searchValue?.toUpperCase())
      );
    });

    setAddressList(filteredAddresses);
  }, [addresses, searchValue]);

  useEffect(() => {
    onSearch();
  }, [onSearch]);

  useEffect(() => {
    setSelectedAddress(address);
  }, [address]);

  const onChange = (value: number) => {
    setSelectedAddress(addresses.find(({ deliveryAddressId }) => deliveryAddressId === value));
  };

  const handleOk = () => {
    dispatch(selectAddress(selectedAddress));
    setVisible(false);
  };

  const handleCancel = () => {
    setSearchValue('');
    setSelectedAddress(address);
    setVisible(false);
  };
  return (
    <Modal
      data-cy="modal-selectAddress"
      destroyOnClose
      width={800}
      title="Selecione um endereço"
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={[
        <DefaultButton data-cy="btn-cancel" layout={layout} key="back" onClick={handleCancel}>
          Cancelar
        </DefaultButton>,
        <SubmitButton
          data-cy="btn-select"
          layout={layout}
          key="submit"
          loading={loading}
          onClick={handleOk}
        >
          Selecionar
        </SubmitButton>,
      ]}
    >
      <Search
        data-cy="input-search"
        value={searchValue}
        onChange={(text) => setSearchValue(text.target.value)}
        onSearch={onSearch}
      />
      <List
        size="small"
        dataSource={addressList}
        renderItem={(item: Address) => (
          <List.Item>
            <List.Item.Meta
              title={
                <Radio
                  value={item.deliveryAddressId}
                  onChange={(e) => onChange(e.target.value)}
                  checked={selectedAddress?.deliveryAddressId === item.deliveryAddressId}
                >
                  <strong style={{ whiteSpace: 'normal' }}>
                    {maskCamelCase(item.descriptionDeliveryAddress)}
                  </strong>
                </Radio>
              }
              description={
                <Description>
                  <small>Contato: {item.contactName?.toUpperCase()}</small>
                  {(item.creditLimit || item.creditLimit >= 0) && (
                    <small>Limite de Crédito: {maskCurrency(item.creditLimit)}</small>
                  )}
                </Description>
              }
            />
          </List.Item>
        )}
      />
    </Modal>
  );
};

export default AddressModal;
