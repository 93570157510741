import { ResponseError } from '../../../services/api';
import { Config } from '../../../utils/newTableHelper';

export enum ProductTypes {
  'LOAD_REQUEST' = '@product/LOAD_REQUEST',
  'LOAD_SUCCESS' = '@product/LOAD_SUCCESS',
  'LOAD_FAILURE' = '@product/LOAD_FAILURE',

  'SET_UNITY' = '@product/SET_UNITY',
  'UNITY_SUCCESS' = '@product/UNITY_SUCCESS',
}
export type View = 'TABELA' | 'CARD' | 'LISTA';
export interface ProductState {
  readonly data: any[];
  readonly config?: Config;
  readonly relatedProducts: any[];
  readonly images: string[];
  readonly thumbnails: string[];
  readonly loading: boolean;
  readonly error?: ResponseError;
}
