import { all, takeLatest } from 'redux-saga/effects';
import { AuthTypes } from './auth/types';
import {
  forgotPassword,
  login,
  updateToken,
  changePassword,
  refreshToken,
  newPassword,
} from './auth/sagas';
import { LayoutTypes } from './layout/types';
import { BannerTypes } from './banners/types';
import { loadLayout, resetLayoutScroll } from './layout/sagas';
import { UserTypes } from './user/types';
import { loadBanners } from './banners/sagas';
import {
  deleteUserPhoto,
  loadPartners,
  loadUser,
  loadUserPhoto,
  updatePassword,
  updatePhoto,
  updateUser,
} from './user/sagas';
import { HomeOrdersTypes } from './homeOrders/types';
import { loadHomeOrders } from './homeOrders/sagas';
import { HomeFinancialsTypes } from './homeFinancials/types';
import { loadHomeFinancials } from './homeFinancials/sagas';
import { OrderFieldsTypes } from './orderFields/types';
import { loadOrderFields } from './orderFields/sagas';
import { ProductsTypes } from './products/types';
import { changeUnity, loadAdvancedFields, loadCategories, loadProducts, setFavorite } from './products/sagas';
import { ShoppingCartTypes } from './shoppingCart/types';
import { MyOrdersTypes } from './myOrders/types';
import { loadMyOrders } from './myOrders/sagas';
import { FinancialTypes } from './financial/types';
import { loadFinancial } from './financial/sagas';
import { ServiceOrdersTypes } from './serviceOrders/types';
import { loadServiceOrders } from './serviceOrders/sagas';
import { ServiceOrdersDetailTypes } from './serviceOrdersDetail/types';
import { loadServiceOrdersDetail, requestDetail } from './serviceOrdersDetail/sagas';
import {
  addItem,
  addItems,
  clearCart,
  deleteItem,
  editOrder,
  initCart,
  loadAddresses,
  loadConfigs,
  loadPayments,
  loadTotal,
  reDoOrder,
  saveOrder,
} from './shoppingCart/sagas';
import { AccessControlTypes } from './accessControl/types';
import { loadControl } from './accessControl/sagas';
import { FaqTypes } from './faq/types';
import { loadCall, loadCategory, loadFaq, sendCall } from './faq/sagas';
import { loadOrdersDetail, requestOrderDetail, requestSteps } from './myOrdersDetail/sagas';
import { MyOrdersDetailTypes } from './myOrdersDetail/types';
import { DataSheetTypes } from './dataSheet/types';
import { loadDataSheet, loadDatasheetCategories } from './dataSheet/sagas';
import { BudgetFieldsTypes } from './budgetFields/types';
import { loadBudgetFields } from './budgetFields/sagas';
import { OrderReleaseTypes } from './orderRelease/types';
import { loadApprove, loadDeny, loadReleases } from './orderRelease/sagas';
import { CampaignsTypes } from './campaigns/types';
import { loadCampaigns, loadCampaign } from './campaigns/sagas';
import { ReportsTypes } from './reports/types';
import { loadReports } from './reports/sagas';
import { NewUserTypes } from './newUser/types';
import { loadDataUser, loadSendUser } from './newUser/sagas';
import { NewContactTypes } from './newContact/types';
import { loadContact, loadRoles, loadSendContact } from './newContact/sagas';
import { DeliveryPointTypes } from './deliveryPoint/types';
import { loadPoints } from './deliveryPoint/sagas';
import { NewDeliveryPointTypes } from './newDeliveryPoint/types';
import { loadDeliveries, loadPoint, loadSendPoint } from './newDeliveryPoint/sagas';
import { ProductTypes } from './productDetail/types';
import { changeUnityDetail, loadProduct } from './productDetail/sagas';
import { HomeCampaignsTypes } from './homeCampaingns/types';
import { loadHomeCampaigns } from './homeCampaingns/sagas';
import { HomeServiceOrdersTypes } from './homeServiceOrders/types';
import { loadHomeServiceOrders } from './homeServiceOrders/sagas';
import { BudgetsTypes } from './budgets/types';
import { loadBudgets } from './budgets/sagas';
import { BudgetsDetailTypes } from './budgetDetail/types';
import { loadBudgetItens, requestBudgetDetail } from './budgetDetail/sagas';
import { HomeProductsTypes } from './homeProducts/types';
import { loadHomeProduct } from './homeProducts/sagas';
import { HomeCardsTypes } from './homeCards/types';
import { loadHomeCards } from './homeCards/sagas';
import { NewServiceOrderTypes } from './newServiceOrder/types';
import { loadForm, loadList, loadModels, sendServiceOrder } from './newServiceOrder/sagas';
import { ContactsTypes } from './contacts/types';
import { loadContacts } from './contacts/sagas';
import { ScreenFormTypes } from './screenForm/types';
import { loadScreenForm, sendScreenForm } from './screenForm/sagas';
import { loadScreenEdit, sendScreenEdit } from './screenEdit/sagas';
import { ScreenConstructorTypes } from './screenConstructor/types';
import { loadScreen } from './screenConstructor/sagas';
import { DashboardTypes } from './dashboard/types';
import { loadDashboardConfigs, loadDashboardData } from './dashboard/sagas';
import { loadDashboardMenu } from './dashboardMenus/sagas';
import { DashboardMenuTypes } from './dashboardMenus/types';
import { CampaignProductsTypes } from './campaignProducts/types';
import { loadCampaignProducts } from './campaignProducts/sagas';
import { SignUpFieldsTypes } from './signUpFields/types';
import { loadSignUpFields, sendSignUpForm } from './signUpFields/sagas';
import { ScreenEditTypes } from './screenEdit/types';
import { ProductsShowCaseTypes } from './showCase/types';
import { AdditionalScreenTypes } from './additionalScreens/types';
import {
  loadCategoriesShowCase,
  loadProductsShowCase,
  showCasechangeUnity,
} from './showCase/sagas';
import { ProductDetailShowCaseTypes } from './showCaseDetail/types';
import { loadProductDetailShowCase, showCaseDetailChangeUnity } from './showCaseDetail/sagas';
import { ScreenDetailTypes } from './screenDetail/types';
import { loadScreenDetail } from './screenDetail/sagas';
import { loadAdditionalScreens } from './additionalScreens/sagas';

export default function* rootSaga(): any {
  return yield all([
    takeLatest(AuthTypes.LOGIN_REQUEST, login),
    takeLatest(AuthTypes.UPDATE_REQUEST, updateToken),
    takeLatest(AuthTypes.REFRESH_REQUEST, refreshToken),
    takeLatest(AuthTypes.PASSWORD_REQUEST, forgotPassword),
    takeLatest(AuthTypes.CHANGE_PASSWORD_REQUEST, changePassword),
    takeLatest(AuthTypes.NEW_PASSWORD_REQUEST, newPassword),
    takeLatest(LayoutTypes.LOAD_REQUEST, loadLayout),
    takeLatest(LayoutTypes.SCROLL, resetLayoutScroll),
    takeLatest(BannerTypes.LOAD_REQUEST, loadBanners),
    takeLatest(UserTypes.LOAD_REQUEST, loadUser),
    takeLatest(HomeOrdersTypes.LOAD_REQUEST, loadHomeOrders),
    takeLatest(HomeFinancialsTypes.LOAD_REQUEST, loadHomeFinancials),
    takeLatest(OrderFieldsTypes.LOAD_REQUEST, loadOrderFields),
    takeLatest(BudgetFieldsTypes.LOAD_REQUEST, loadBudgetFields),
    takeLatest(UserTypes.UPDATE_REQUEST, updateUser),
    takeLatest(UserTypes.PASSWORD_REQUEST, updatePassword),
    takeLatest(UserTypes.LOAD_REQUEST_PHOTO, loadUserPhoto),
    takeLatest(UserTypes.UPDATE_REQUEST_PHOTO, updatePhoto),
    takeLatest(UserTypes.DELETE_REQUEST_PHOTO, deleteUserPhoto),
    takeLatest(UserTypes.LOAD_REQUEST_PARTNERS, loadPartners),
    takeLatest(ProductsTypes.LOAD_REQUEST, loadProducts),
    takeLatest(ProductsTypes.FAVORITE_REQUEST, setFavorite),
    takeLatest(ProductsTypes.LOAD_REQUEST_CATEGORY, loadCategories),
    takeLatest(ProductsTypes.SET_UNITY, changeUnity),
    takeLatest(ProductsTypes.ADVANCED_FIELDS_REQUEST, loadAdvancedFields),
    takeLatest(ProductTypes.SET_UNITY, changeUnityDetail),
    takeLatest(ProductsShowCaseTypes.SET_UNITY, showCasechangeUnity),
    takeLatest(ProductDetailShowCaseTypes.SET_UNITY, showCaseDetailChangeUnity),
    takeLatest(ShoppingCartTypes.GET_CURRENT_CART_REQUEST, initCart),
    takeLatest(ShoppingCartTypes.ADD_ITEM_REQUEST, addItem),
    takeLatest(ShoppingCartTypes.ADD_ITEMS_REQUEST, addItems),
    takeLatest(ShoppingCartTypes.DELETE_ITEM_REQUEST, deleteItem),
    takeLatest(ShoppingCartTypes.PAYMENT_REQUEST, loadPayments),
    takeLatest(ShoppingCartTypes.ADDRESS_REQUEST, loadAddresses),
    takeLatest(ShoppingCartTypes.TOTAL_REQUEST, loadTotal),
    takeLatest(ShoppingCartTypes.SAVE_REQUEST, saveOrder),
    takeLatest(ShoppingCartTypes.REDO_REQUEST, reDoOrder),
    takeLatest(ShoppingCartTypes.EDIT_REQUEST, editOrder),
    takeLatest(ShoppingCartTypes.CLEAR_CART, clearCart),
    takeLatest(ShoppingCartTypes.CONFIGS_REQUEST, loadConfigs),
    takeLatest(MyOrdersTypes.LOAD_REQUEST, loadMyOrders),
    takeLatest(MyOrdersDetailTypes.LOAD_REQUEST, loadOrdersDetail),
    takeLatest(MyOrdersDetailTypes.DATA_REQUEST, requestOrderDetail),
    takeLatest(MyOrdersDetailTypes.STEPS_REQUEST, requestSteps),
    takeLatest(FinancialTypes.LOAD_REQUEST, loadFinancial),
    takeLatest(ServiceOrdersTypes.LOAD_REQUEST, loadServiceOrders),
    takeLatest(ServiceOrdersDetailTypes.LOAD_REQUEST, loadServiceOrdersDetail),
    takeLatest(ServiceOrdersDetailTypes.DATA_REQUEST, requestDetail),
    takeLatest(AccessControlTypes.LOAD_REQUEST, loadControl),
    takeLatest(ContactsTypes.LOAD_REQUEST, loadContacts),
    takeLatest(FaqTypes.CATEGORY_REQUEST, loadCategory),
    takeLatest(FaqTypes.FAQ_REQUEST, loadFaq),
    takeLatest(FaqTypes.CALL_REQUEST, loadCall),
    takeLatest(FaqTypes.SEND_CALL_REQUEST, sendCall),
    takeLatest(DataSheetTypes.LOAD_REQUEST, loadDataSheet),
    takeLatest(DataSheetTypes.LOAD_REQUEST_CATEGORY, loadDatasheetCategories),
    takeLatest(OrderReleaseTypes.RELEASES_REQUEST, loadReleases),
    takeLatest(OrderReleaseTypes.APPROVE_REQUEST, loadApprove),
    takeLatest(OrderReleaseTypes.DENY_REQUEST, loadDeny),
    takeLatest(CampaignsTypes.CAMPAIGNS_REQUEST, loadCampaigns),
    takeLatest(CampaignsTypes.CAMPAIGN_REQUEST, loadCampaign),
    takeLatest(CampaignProductsTypes.CAMPAIGN_PRODUCTS_REQUEST, loadCampaignProducts),
    takeLatest(ReportsTypes.REPORTS_REQUEST, loadReports),
    takeLatest(NewUserTypes.USER_REQUEST, loadDataUser),
    takeLatest(NewUserTypes.SEND_REQUEST, loadSendUser),
    takeLatest(NewContactTypes.CONTACT_REQUEST, loadContact),
    takeLatest(NewContactTypes.ROLES_REQUEST, loadRoles),
    takeLatest(NewContactTypes.SEND_REQUEST, loadSendContact),
    takeLatest(DeliveryPointTypes.POINTS_REQUEST, loadPoints),
    takeLatest(NewDeliveryPointTypes.DELIVERIES_REQUEST, loadDeliveries),
    takeLatest(NewDeliveryPointTypes.POINT_REQUEST, loadPoint),
    takeLatest(NewDeliveryPointTypes.SEND_REQUEST, loadSendPoint),
    takeLatest(ProductTypes.LOAD_REQUEST, loadProduct),
    takeLatest(ProductsShowCaseTypes.LOAD_REQUEST, loadProductsShowCase),
    takeLatest(ProductDetailShowCaseTypes.LOAD_REQUEST, loadProductDetailShowCase),
    takeLatest(ProductsShowCaseTypes.LOAD_REQUEST_CATEGORY, loadCategoriesShowCase),
    takeLatest(HomeProductsTypes.LOAD_REQUEST, loadHomeProduct),
    takeLatest(HomeCampaignsTypes.HOME_CAMPAIGNS_REQUEST, loadHomeCampaigns),
    takeLatest(HomeServiceOrdersTypes.LOAD_REQUEST, loadHomeServiceOrders),
    takeLatest(BudgetsTypes.LOAD_REQUEST, loadBudgets),
    takeLatest(BudgetsDetailTypes.LOAD_REQUEST, loadBudgetItens),
    takeLatest(BudgetsDetailTypes.DATA_REQUEST, requestBudgetDetail),
    takeLatest(HomeCardsTypes.LOAD_REQUEST, loadHomeCards),
    takeLatest(NewServiceOrderTypes.LOAD_REQUEST, loadModels),
    takeLatest(NewServiceOrderTypes.FORM_REQUEST, loadForm),
    takeLatest(NewServiceOrderTypes.LIST_REQUEST, loadList),
    takeLatest(NewServiceOrderTypes.SEND_REQUEST, sendServiceOrder),
    takeLatest(ScreenFormTypes.LOAD_REQUEST, loadScreenForm),
    takeLatest(ScreenFormTypes.SEND_REQUEST, sendScreenForm),
    takeLatest(ScreenEditTypes.LOAD_REQUEST, loadScreenEdit),
    takeLatest(ScreenDetailTypes.LOAD_REQUEST, loadScreenDetail),
    takeLatest(ScreenEditTypes.SEND_REQUEST, sendScreenEdit),
    takeLatest(ScreenConstructorTypes.LOAD_REQUEST, loadScreen),
    takeLatest(DashboardTypes.LOAD_CONFIG_REQUEST, loadDashboardConfigs),
    takeLatest(DashboardTypes.LOAD_REQUEST, loadDashboardData),
    takeLatest(DashboardMenuTypes.LOAD_REQUEST, loadDashboardMenu),
    takeLatest(SignUpFieldsTypes.LOAD_REQUEST, loadSignUpFields),
    takeLatest(SignUpFieldsTypes.SEND_REQUEST, sendSignUpForm),
    takeLatest(AdditionalScreenTypes.LOAD_REQUEST, loadAdditionalScreens),
  ]);
}
