import React, { Dispatch } from 'react';
import { Collapse, Divider, List } from 'antd';
import { Action } from 'typesafe-actions';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingOutlined } from '@ant-design/icons';

import { ApplicationState } from '../../../store';
import { requestFaq } from '../../../store/ducks/faq/actions';
import { QuestionsSolution } from '../styles';
import ServerError from '../../../components/ServerError';
import { Fields } from '../../../store/ducks/faq/types';

const { Panel } = Collapse;

interface Props {
  search: any;
  category: any;
}

const FAQCommonQuestions: React.FC<Props> = ({ search, category }) => {
  const dataFaq = useSelector((state: ApplicationState) => state.faq.dataFaq);
  const error = useSelector((state: ApplicationState) => state.faq.errorFaq);
  const loading = useSelector((state: ApplicationState) => state.faq.loadingFaq);
  const layout = useSelector((state: ApplicationState) => state.layout.data);
  const pagination = useSelector((state: ApplicationState) => state.faq.paginationFaq);
  const dispatch = useDispatch<Dispatch<Action>>();
  return (
    <>
      {error ? (
        <ServerError {...error} />
      ) : (
        <List
          loading={{
            style: { color: layout?.color.default },
            spinning: loading,
            size: 'large',
            delay: 500,
            indicator: <LoadingOutlined style={{ fontSize: 24 }} spin />,
          }}
          itemLayout="vertical"
          size="small"
          pagination={{
            size: 'small',
            ...pagination,

            onChange: (page, pageSize) => {
              const fieldsArr: Fields[] = [];
              if (search) fieldsArr.push({ name: 'titulo', value: search });
              if (category) fieldsArr.push({ name: 'codFaqPai', value: category });
              dispatch(
                requestFaq(
                  { current: page, pageSize: pageSize || 0, total: pagination?.total || 0 },
                  fieldsArr ? fieldsArr : undefined
                )
              );
            },
          }}
          dataSource={dataFaq}
          renderItem={(item) => (
            <Collapse ghost>
              <Panel header={<strong>{item.title}</strong>} key={item.faqId}>
                <QuestionsSolution dangerouslySetInnerHTML={{ __html: item.solution || '' }} />
              </Panel>
              <Divider />
            </Collapse>
          )}
        />
      )}
    </>
  );
};

export default FAQCommonQuestions;
