import { Button, Col, Form, Input, Row, Switch } from 'antd';
import React, { Dispatch, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Action } from 'typesafe-actions';
import { useWindowSize } from '../../../customHooks';
import { CheckOutlined, SaveOutlined } from '@ant-design/icons';
import { ApplicationState } from '../../../store';
import { clearSend, sendContactRequest, setError } from '../../../store/ducks/newContact/actions';
import { ContainerForm } from '../styles';

const ContactForm: React.FC = () => {
  const data = useSelector((state: ApplicationState) => state.newContact.dataContact);
  const roles = useSelector((state: ApplicationState) => state.newContact.roles);
  const layout = useSelector((state: ApplicationState) => state.layout.data);
  const saved = useSelector((state: ApplicationState) => state.newContact.saved);
  const dataSend = useSelector((state: ApplicationState) => state.newContact.dataSend);
  const loadingSend = useSelector((state: ApplicationState) => state.newContact.loadingSend);
  const error = useSelector((state: ApplicationState) => state.newContact.errorSend);
  const [form] = Form.useForm();
  const size = useWindowSize();
  const history = useHistory();
  const { userId, id }: any = useParams();
  const dispatch = useDispatch<Dispatch<Action>>();

  useEffect(() => {
    if (!data || !id || !userId) {
      return;
    }
    form.setFieldsValue(data);
  }, [data, form, id, userId]);

  useEffect(() => {
    if (!error) {
      return;
    }
    Swal.fire({
      title: 'Erro!',
      confirmButtonColor: layout?.color.default,
      text: error.message,
      icon: 'error',
    });
    Swal.fire({
      confirmButtonColor: layout?.color.default,
      title: 'Erro!',
      text: error.message,
      icon: 'error',
    }).then(() => {
      dispatch(setError(undefined));
    });
  }, [error, dispatch, layout?.color.default]);

  useEffect(() => {
    if (!saved) {
      return;
    }
    const textFire = `Contato (${dataSend?.contactName}) ${!id ? 'salvo' : 'editado'} com sucesso!`;
    Swal.fire({
      title: 'Sucesso!',
      confirmButtonColor: layout?.color.default,
      text: `${textFire}`,
      icon: 'success',
      confirmButtonText: 'Ok',
    }).then(() => {
      history.goBack();
      dispatch(clearSend());
    });
  }, [saved, dispatch, id, userId, dataSend, layout?.color.default, history]);

  const onFinish = (values: any) => {
    const body = !id ? { ...values } : { ...values, userId: userId };
    dispatch(sendContactRequest(body, userId, id));
  };

  return (
    <>
      <ContainerForm>
        <Form form={form} onFinish={onFinish} layout="vertical">
          <h2>Dados contato</h2>
          <Row gutter={[20, 20]}>
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <Form.Item
                label="Nome"
                name="contactName"
                rules={[{ required: true, message: `Por favor informe um nome!` }]}
              >
                <Input data-cy="input-name" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12} lg={12} xl={12}>
              <Form.Item label="Ativo" name="active" valuePropName="checked" initialValue={true}>
                <Switch data-cy="input-active" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </ContainerForm>

      <ContainerForm>
        <h2>Permissões</h2>
        <Form form={form} onFinish={onFinish}>
          <Row gutter={[20, 20]}>
            {roles.map((permission) => {
              return (
                <Col xs={24} sm={12} md={12} lg={6} xl={6}>
                  <Form.Item
                    label={permission.label}
                    name={`${permission.name}`}
                    valuePropName="checked"
                    initialValue={!!permission?.value}
                  >
                    <Switch data-cy={`input-${permission.name}`} />
                  </Form.Item>
                </Col>
              );
            })}
          </Row>
        </Form>
      </ContainerForm>
      <Form form={form} onFinish={onFinish}>
        <Row justify="end">
          <Button
            data-cy="btn-saveContact"
            icon={!data || !id || !userId ? <CheckOutlined /> : <SaveOutlined />}
            block={(size.width ?? 0) <= 1024}
            type="primary"
            htmlType="submit"
            loading={loadingSend}
          >
            {!data || !id || !userId ? 'Criar contato' : 'Salvar'}
          </Button>
        </Row>
      </Form>
    </>
  );
};

export default ContactForm;
