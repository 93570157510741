import { action } from 'typesafe-actions';
import { ResponseError } from '../../../services/api';
import { ScreenForm, ScreenFormTypes, SendObject, Success } from './types';

export const formRequest = (id?: number) => action(ScreenFormTypes.LOAD_REQUEST, { id });
export const loadSuccess = (data: ScreenForm[]) => action(ScreenFormTypes.LOAD_SUCCESS, { data });
export const loadFailure = (error: ResponseError) =>
  action(ScreenFormTypes.LOAD_FAILURE, { error });

export const sendFormRequest = (id: number, values?: SendObject[]) =>
  action(ScreenFormTypes.SEND_REQUEST, { id, values });
export const sendSuccess = (success: Success) => action(ScreenFormTypes.SEND_SUCCESS, { success });
export const sendFailure = (sendError: ResponseError) =>
  action(ScreenFormTypes.SEND_FAILURE, { sendError });

export const cleanFormState = () => action(ScreenFormTypes.CLEAN_STATE);
