import React, { Dispatch, FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, Redirect, useLocation, useHistory, Link } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Input, Popover, Row, Spin } from 'antd';
import { UserOutlined, MenuOutlined, UserAddOutlined } from '@ant-design/icons';
import logo from '../../../assets/img/logo.png';
import { RouteType } from '../../../routes/routeType';
import { EXTERNAL_ROUTES } from '../../../routes/routes';

import {
  ColoredMenu,
  Container,
  Footer,
  FormLogin,
  Header,
  HomeLogin,
  Logo,
  LogoContent,
  LogoFooter,
  LogoShowCaseContent,
  PackageVersion,
  ShowCaseContainer,
  Wallpaper,
  WallpaperContainer,
} from './styles';
import { ApplicationState } from '../../../store';
import { getImage } from '../../../utils/image';
import packageJson from '../../../../package.json';
import Scrollbars from 'react-custom-scrollbars';
import { maskCamelCase } from '../../../utils/masks';
import { Action } from 'typesafe-actions';
import { Category } from '../../../store/ducks/showCase/types';
import {
  categoriesRequestShowCase,
  setFilter,
  setSearch,
} from '../../../store/ducks/showCase/actions';
import PopoverMenu from './PopoverMenu';
import { Size, useWindowSize } from '../../../customHooks';
import DrawerMenu from './DrawerMenu';

const { Search } = Input;

const ExternalLayout: FC<any> = () => {
  const layout = useSelector((state: ApplicationState) => state.layout.data);
  const token = useSelector((state: ApplicationState) => state.auth.token);
  const loading = useSelector((state: ApplicationState) => state.layout.loading);
  const categories = useSelector((state: ApplicationState) => state.productsShowCase.categories);
  const [visiblePopover, setVisiblePopover] = useState<boolean>(false);
  const [visibleDrawer, setVisibleDrawer] = useState<boolean>(false);

  const size: Size = useWindowSize();

  const dispatch = useDispatch<Dispatch<Action>>();
  const history = useHistory();
  const location = useLocation();
  const [searchValue, setSearchValue] = useState<string>('');

  useEffect(() => {
    if (!location.pathname.includes('showcase')) return;
    dispatch(categoriesRequestShowCase());
  }, [dispatch, location.pathname]);

  const onSearch = () => {
    dispatch(setSearch(searchValue));
    history.push('/showcase/products');
    setSearchValue('');
  };

  const renderRoutes = (prop: RouteType, index: number) => {
    return (
      <Route path={`${prop.layout}${prop.path}`} component={prop.component} key={index} exact />
    );
  };
  const handleMenuItemClick = (category: Category) => {
    dispatch(
      setFilter(category.list.length > 0 ? category.list.map((item) => item.key) : [category.key])
    );
    history.push('/showcase/products');
  };

  const renderCategories = () => {
    return (
      <Col>
        {categories
          .filter((item) => item.isHighlight)
          .map((item, index) => {
            return (
              <>
                <Button
                  type="text"
                  size="large"
                  style={{ color: 'white' }}
                  onClick={() => handleMenuItemClick(item)}
                >
                  {maskCamelCase(item.value)}
                </Button>
                {index !== categories.filter((item) => item.isHighlight).length - 1 && (
                  <Divider type="vertical" style={{ backgroundColor: 'white', margin: 0 }} />
                )}
              </>
            );
          })}
      </Col>
    );
  };

  const hasImage = (): boolean => !!layout?.wallpapper;

  return (
    <>
      <Spin
        style={{ color: layout?.color.default }}
        spinning={loading}
        size="large"
        delay={500}
        indicator={<LoadingOutlined />}
      >
        {location.pathname.includes('showcase') ? (
          <ShowCaseContainer>
            <Scrollbars style={{ paddingLeft: '5px' }}>
              <Header>
                <Row align="middle" justify="space-around" gutter={[10, 10]}>
                  <Col>
                    <LogoShowCaseContent>
                      <Link to={
                        layout?.existsProductShowcase ? '/showcase' : '/'
                      }>
                        <Logo
                          alt=""
                          src={
                            layout?.logo?.type === 'URL'
                              ? layout?.logo.image
                              : getImage(logo, layout?.logo?.image)
                          }
                        />
                      </Link>
                    </LogoShowCaseContent>
                  </Col>
                  <Col xs={24} sm={24} md={14} xl={14} xxl={14}>
                    <Search
                      data-cy="input-search"
                      placeholder="Buscar produtos"
                      value={searchValue}
                      style={{ width: '100%' }}
                      onChange={(text) => setSearchValue(text.target.value)}
                      onSearch={onSearch}
                    />
                  </Col>
                  <Col style={{ paddingBottom: (size?.width || 0) <= 1024 ? '10px' : '' }}>
                    <Button
                      type="primary"
                      icon={<UserOutlined />}
                      onClick={() => history.push('/login')}
                    >
                      Fazer login
                    </Button>
                    {layout?.existsAccessRequest && (
                      <Button
                        type="default"
                        icon={<UserAddOutlined />}
                        style={{ marginLeft: '10px' }}
                        onClick={() => history.push('/signup')}
                      >
                        Registrar
                      </Button>
                    )}
                  </Col>
                </Row>
              </Header>
              {categories.length > 0 && (
                <ColoredMenu layout={layout}>
                  {(size?.width || 0) > 1024 ? (
                    <Row align="middle" justify="space-between">
                      <Col>
                        <Popover
                          content={<PopoverMenu closePopover={() => setVisiblePopover(false)} />}
                          title="Departamentos"
                          trigger="click"
                          visible={visiblePopover}
                          onVisibleChange={() => setVisiblePopover(!visiblePopover)}
                        >
                          <Button
                            size="large"
                            type="text"
                            style={{ color: 'white', marginLeft: '10px' }}
                            icon={<MenuOutlined />}
                          >
                            Todos os departamentos
                          </Button>
                        </Popover>
                      </Col>
                      {renderCategories()}
                    </Row>
                  ) : (
                    <Button
                      size="large"
                      type="text"
                      style={{ color: 'white', marginLeft: '10px' }}
                      icon={<MenuOutlined />}
                      onClick={() => setVisibleDrawer(!visibleDrawer)}
                    >
                      Todos os departamentos
                    </Button>
                  )}
                </ColoredMenu>
              )}
              <Switch>
                {EXTERNAL_ROUTES.map(renderRoutes)}
                <Redirect to="/showcase" />
                <Redirect from="/" to="/home" />
              </Switch>
            </Scrollbars>
          </ShowCaseContainer>
        ) : (
          <Container hasImage={hasImage()}>
            {
              <WallpaperContainer hasImage={hasImage()}>
                <Wallpaper
                  backgroudImage={
                    layout?.wallpapper?.type === 'URL'
                      ? layout?.wallpapper.image
                      : getImage('', layout?.wallpapper?.image)
                  }
                />
              </WallpaperContainer>
            }
            <HomeLogin hasImage={hasImage()} isLogin={!location.pathname.includes('signup')}>
              {!location.pathname.includes('signup') && (
                <LogoContent
                  onClick={() => (layout?.existsProductShowcase ? history.push('/showcase') : {})}
                  style={{ cursor: layout?.existsProductShowcase ? 'pointer' : 'default' }}
                >
                  <Logo
                    alt=""
                    src={
                      layout?.logo?.type === 'URL'
                        ? layout?.logo.image
                        : getImage(logo, layout?.logo?.image)
                    }
                  />
                </LogoContent>
              )}
              <Row justify="space-between" align="middle">
                <FormLogin hasImage={hasImage()} isLogin={!location.pathname.includes('signup')}>
                  <Scrollbars>
                    {location.pathname.includes('signup') && (
                      <LogoContent
                        onClick={() =>
                          layout?.existsProductShowcase ? history.push('/showcase') : {}
                        }
                        style={{ cursor: layout?.existsProductShowcase ? 'pointer' : 'default' }}
                      >
                        <Logo
                          alt=""
                          src={
                            layout?.logo?.type === 'URL'
                              ? layout?.logo.image
                              : getImage(logo, layout?.logo?.image)
                          }
                        />
                      </LogoContent>
                    )}
                    <Switch>
                      {EXTERNAL_ROUTES.map(renderRoutes)}
                      <Redirect from="/login" to="/login" />
                      <Redirect from="/" to="/home" />
                    </Switch>
                  </Scrollbars>
                </FormLogin>
                <Footer>
                  <LogoFooter>
                    <a href="https://zydon.com.br/b2b" target="_blank" rel="noreferrer">
                      <Logo alt="" src={logo} />
                    </a>
                  </LogoFooter>
                  <PackageVersion>{`Versão ${packageJson.version}`}</PackageVersion>
                </Footer>
              </Row>
            </HomeLogin>
          </Container>
        )}
      </Spin>
      {(size?.width || 0) <= 1024 && (
        <DrawerMenu onClose={() => setVisibleDrawer(false)} visible={visibleDrawer} />
      )}
      {token && <Redirect to={{ pathname: '/home' }} />}
    </>
  );
};

export default ExternalLayout;
