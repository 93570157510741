import styled from 'styled-components';

export const CarouselStyled = styled.div`
  margin-left: 20px;
  margin-right: 20px;
  .ant-carousel {
    .slick-next {
      &::before {
        content: '';
      }
    }
    .slick-prev {
      &::before {
        content: '';
      }
    }
  }
`;