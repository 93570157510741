import { AxiosResponse } from 'axios';
import { call, put, select } from 'redux-saga/effects';
import { ApplicationState } from '../..';
import api, { errorHandler } from '../../../services/api';
import { requestHeaders } from '../../../utils/apiHelper';
import { Config, Data, filterParamsHandle, sorterParamsHandle } from '../../../utils/newTableHelper';
import { refreshRequest } from '../auth/actions';
import { loadFailure, loadServiceOrdersRequest, loadSuccess } from './actions';

export function* loadServiceOrders({ payload }: ReturnType<typeof loadServiceOrdersRequest>) {
  try {
    const state: ApplicationState = yield select();
    const headers = requestHeaders(state.auth.token);
    const config: AxiosResponse<Config> = yield call(api.get, '/service-order/config', {
      headers: headers,
    });
    const response: AxiosResponse<{
      content: Data[];
      numberOfElements: number;
      size: number;
      number: number;
    }> = yield call(
      api.post,
      `/service-order/all`,
      {
        page: (payload.pagination?.current || 1) - 1,
        size: payload.pagination?.pageSize || 12,
        filters: filterParamsHandle(payload.filter),
        sort: sorterParamsHandle(payload.sorter),
        search: payload.searchValue,
      },
      {
        headers: headers,
      }
    );
    yield put(
      loadSuccess(
        {
          total: response.data.numberOfElements,
          current: response.data.number + 1,
          pageSize: response.data.size,
        },
        response.data.content,
        config.data
      )
    );
  } catch (err: any) {
    yield put(err.response.status !== 401 ? loadFailure(errorHandler(err)) : refreshRequest());
  }
}
