import { Button, Result } from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { MESSAGES } from '../../utils/constants';

interface ExceptionProps {
  status?: number;
  message?: string;
  back?: boolean;
}

const ServerError: React.FC<ExceptionProps> = ({ status, message, back }) => {
  const history = useHistory();
  const getStatusCode = (code?: number): 403 | 404 | 500 => {
    switch (code) {
      case 403:
      case 404:
      case 500:
        return code;

      default:
        return 500;
    }
  };

  const getDefaultMessage = (code?: number): string => {
    switch (code) {
      case 403:
        return MESSAGES.NOT_AUTHRIZED;
      case 404:
        return MESSAGES.NOT_FOUND;
      case 500:
        return MESSAGES.ERROR;

      default:
        return MESSAGES.ERROR;
    }
  };
  return (
    <Result
      status={getStatusCode(status)}
      title={status || 500}
      subTitle={message || getDefaultMessage(status)}
      extra={
        <>
          {back && (
            <Button data-cy="btn-back" type="primary" onClick={() => history.goBack()}>
              Voltar
            </Button>
          )}
        </>
      }
    />
  );
};

export default ServerError;
