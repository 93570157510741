import React from 'react';
import { Line, G2 } from '@ant-design/plots';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../store';
import { maskCamelCase } from '../../../utils/masks';
import { Serie } from '../../../store/ducks/dashboard/types';
import DataSet from '@antv/data-set';
import EmptyChart from '../EmptyChart';
import { colors } from '../../../utils/constants';

interface Props {
  series: Serie[];
  title: string;
  data: any[];
  xField: string;
  yField: string;
  slider?: {
    start: number;
    end: number;
  };
  onClick(values: any): void;
}
const LineChart: React.FC<Props> = ({ title, data, xField, yField, slider, series, onClick }) => {
  const layout = useSelector((state: ApplicationState) => state.layout.data);
  const { DataView } = DataSet;
  const getColor = () => {
    const colors = [
      '#5B8FF9',
      '#61DDAA',
      '#65789B',
      '#F6BD16',
      '#7262fd',
      '#78D3F8',
      '#9661BC',
      '#F6903D',
      '#008685',
    ];
    return series.length > 1 ? colors : layout?.color.default;
  };
  const dv = new DataView().source(data);
  const arr: string[] = [];
  series.forEach((item) => {
    arr.push(item.yField?.fieldName || '');
  });
  dv.transform({
    type: 'fold',
    fields: arr,
    key: 'category',
    value: 'value',
  });
  const config: any = {
    data: dv.rows,
    color: getColor(),
    xField: xField,
    yField: 'value',
    seriesField: 'category',
    xAxis: {
      tickCount: 5,
    },
    label: {
      style: {
        fill: colors.writeGrey,
        fontWeight: 600,
        stroke: '#fff',
        lineWidth: 2,
      },
    },
    slider: slider,
    tooltip: {
      formatter: (datum: any) => ({
        name: maskCamelCase(datum.category),
        value: datum.value,
      }),
    },
    meta: {
      [xField]: {
        alias: maskCamelCase(xField),
      },
      [yField]: {
        alias: maskCamelCase(yField),
      },
    },
    point: series[0]?.circleIntersection
      ? {
        shape: 'breath-point',
      }
      : undefined,
    onReady: (plot: any) => {
      plot.on('element:click', ({ data: { data } }: any) => {
        onClick(data);
      });
    }
  };
  G2.registerShape('point', 'breath-point', {
    draw(cfg, container) {
      const point: any = {
        x: cfg.x,
        y: cfg.y,
      };
      const group = container.addGroup();

      const decorator1 = group.addShape('circle', {
        attrs: {
          x: point.x,
          y: point.y,
          r: 5,
          fill: cfg.color,
          opacity: 0.5,
        },
      });
      const decorator2 = group.addShape('circle', {
        attrs: {
          x: point.x,
          y: point.y,
          r: 5,
          fill: cfg.color,
          opacity: 0.5,
        },
      });
      const decorator3 = group.addShape('circle', {
        attrs: {
          x: point.x,
          y: point.y,
          r: 5,
          fill: cfg.color,
          opacity: 0.5,
        },
      });
      decorator1.animate(
        {
          r: 10,
          opacity: 0,
        },
        {
          duration: 1800,
          easing: 'easeLinear',
          repeat: true,
        }
      );
      decorator2.animate(
        {
          r: 10,
          opacity: 0,
        },
        {
          duration: 1800,
          easing: 'easeLinear',
          repeat: true,
          delay: 600,
        }
      );
      decorator3.animate(
        {
          r: 10,
          opacity: 0,
        },
        {
          duration: 1800,
          easing: 'easeLinear',
          repeat: true,
          delay: 1200,
        }
      );
      group.addShape('circle', {
        attrs: {
          x: point.x,
          y: point.y,
          r: 3,
          fill: cfg.color,
          opacity: 0.7,
        },
      });
      group.addShape('circle', {
        attrs: {
          x: point.x,
          y: point.y,
          r: 0.5,
          fill: cfg.color,
        },
      });

      return group;
    },
  });

  return data.length === 0 || series.length === 0 ? (
    <EmptyChart type={'line'} />
  ) : (
    <div style={{ padding: '20px' }}>
      <h2 style={{ color: layout?.color.dark }}>{title}</h2>
      <Line {...config} />
    </div>
  );
};

export default LineChart;
