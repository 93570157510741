import React from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../store';
import { colors } from '../../utils/constants';
import { Container, Title } from './styles';

// import { Container } from './styles';

const PromoTag: React.FC = () => {
  const layout = useSelector((state: ApplicationState) => state.layout.data);
  return (
    <Container color={layout?.color.default || colors.writeGrey}>
      <Title color={layout?.color.default || colors.writeGrey}>PROMO</Title>
    </Container>
  );
};

export default PromoTag;
