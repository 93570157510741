import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import ScreenConstructor from '../../components/ScreenConstructor';

const WindowConstructor: React.FC = () => {
  const { id }: any = useParams();
  const history = useHistory();

  const handleClickeNewRegister = () => {
    history.push(`/home/screen/${id}/form`);

  }
  return <ScreenConstructor screenId={id} onClickNewRegister={handleClickeNewRegister} />;
};

export default WindowConstructor;
