import { call, put, select } from 'redux-saga/effects';
import { ApplicationState } from '../..';
import api, { errorHandler } from '../../../services/api';
import { requestHeaders } from '../../../utils/apiHelper';
import { refreshRequest } from '../auth/actions';
import { additionalScreensSuccess, additionalScreensFailure } from './actions';

export function* loadAdditionalScreens(): any {
  try {
    const state: ApplicationState = yield select();
    const headers = requestHeaders(state.auth.token);

    const response = yield call(
      api.get,
      '/layout/menus',
      { headers: headers }
    );

    yield put(
      additionalScreensSuccess(response.data || [])
    );
  } catch (err: any) {
    yield put(err.response.status !== 401 ? additionalScreensFailure(errorHandler(err)) : refreshRequest());
  }
}
