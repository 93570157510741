import {
  changePasswordRoute,
  loginRoute,
  newPasswordRoute,
  forgotPasswordRoute,
  showCaseRoute,
  showCaseDetailRoute,
  signUpRoute,
  showCaseHome,
} from './externalRoutes';
import {
  AccessControlRoute,
  CampaignsRoute,
  CampaignDetailsRoute,
  DatasheetRoute,
  DeliveryPointRoute,
  EditContactRoute,
  EditDeliveryPointRoute,
  FAQRoute,
  FinancialRoute,
  homeRoute,
  MyDataRoute,
  NewContactRoute,
  NewDeliveryPointRoute,
  NewOrderRoute,
  NewServiceOrderRoute,
  NewUserRoute,
  EditUserRoute,
  OrderReleaseRoute,
  ReportRoute,
  ServiceOrdersRoute,
  OSDetailsRoute,
  ProductDetailRoute,
  BudgetsDetailsRoute,
  MyOrdersDetailsRoute,
  MyOrdersRoute,
  ConstructorRoute,
  ScreenFormRoute,
  MyOrdersReleaseDetailsRoute,
  NotFoundRoute,
  ShoppingCartRoute,
  DashboardViewRoute,
  ConstructorEditRoute,
  ConstructorDetailRoute,
} from './privateRoutes';
import { RouteType } from './routeType';

export const PRIVATE_ROUTES: RouteType[] = [
  homeRoute,
  MyOrdersRoute,
  OrderReleaseRoute,
  CampaignsRoute,
  OrderReleaseRoute,
  FinancialRoute,
  ServiceOrdersRoute,
  NewServiceOrderRoute,
  FAQRoute,
  AccessControlRoute,
  NewUserRoute,
  EditUserRoute,
  NewContactRoute,
  EditContactRoute,
  DeliveryPointRoute,
  NewDeliveryPointRoute,
  EditDeliveryPointRoute,
  MyDataRoute,
  NewOrderRoute,
  CampaignDetailsRoute,
  ReportRoute,
  DatasheetRoute,
  MyOrdersDetailsRoute,
  OSDetailsRoute,
  ProductDetailRoute,
  BudgetsDetailsRoute,
  ConstructorRoute,
  ConstructorEditRoute,
  ConstructorDetailRoute,
  ScreenFormRoute,
  MyOrdersReleaseDetailsRoute,
  NotFoundRoute,
  ShoppingCartRoute,
  DashboardViewRoute,
];

export const MENU_ROUTES: RouteType[] = [
  homeRoute,
  MyOrdersRoute,
  OrderReleaseRoute,
  DatasheetRoute,
  CampaignsRoute,
  FinancialRoute,
  ServiceOrdersRoute,
  FAQRoute,
  AccessControlRoute,
  ReportRoute,
];

export const EXTERNAL_ROUTES: RouteType[] = [
  loginRoute,
  forgotPasswordRoute,
  changePasswordRoute,
  newPasswordRoute,
  showCaseHome,
  showCaseRoute,
  showCaseDetailRoute,
  signUpRoute
];
