import styled from 'styled-components';
import { colors } from '../../utils/constants';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const ServiceOrdersHeader = styled.div`
  margin-bottom: 20px;
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: white;
  border-radius: 5px;
  box-shadow: 1px 2px 2px 2px ${colors.grey};
  @media only screen and (max-width: 1024px) {
    flex-direction: column;
    justify-content: space-between;
  }

`;
