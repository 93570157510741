import styled from 'styled-components';
import { colors } from '../../utils/constants';

export const HeaderPoints = styled.div`
  margin-bottom: 20px;
  display: flex;
  padding: 20px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  border-radius: 5px;
  box-shadow: 1px 2px 2px 2px ${colors.grey};
  @media only screen and (max-width: 600px) {
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }
`;

export const InfoPoints = styled.div`
  display: flex;
  flex-direction: row;
  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }
`;

export const PaternBox = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 12px;
`;

export const ContactBox = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TitleBox = styled.p`
  margin-bottom: 7px;
`;
export const SubtitleBox = styled.h4`
  margin-bottom: 10px;
`;
