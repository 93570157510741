import React from 'react';
import { useParams } from 'react-router-dom';

import ScreenForm from '../../components/ScreenForm';

const WindowConstructor: React.FC = () => {
  const { id }: { id: string } = useParams();

  return <ScreenForm screenId={id}/>
};

export default WindowConstructor;
