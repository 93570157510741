import { Reducer } from 'redux';
import { FaqState, FaqTypes } from './types';

const INITIAL_STATE: FaqState = {
  dataCategory: [],
  dataFaq: [],
  dataCall: [],
  paginationFaq: undefined,
  paginationCall: undefined,
  loadingCall: false,
  loadingSend: false,
  loadingFaq: false,
  loading: false,
};

const reducer: Reducer<FaqState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FaqTypes.CATEGORY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FaqTypes.CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        errorCategory: undefined,
        dataCategory: action.payload.data,
      };
    case FaqTypes.CATEGORY_FAILURE:
      return {
        ...state,
        loading: false,
        dataCategory: [],
        errorCategory: action.payload.error,
      };
    case FaqTypes.FAQ_REQUEST:
      return {
        ...state,
        loadingFaq: true,
      };
    case FaqTypes.FAQ_SUCCESS:
      return {
        ...state,
        loadingFaq: false,
        errorFaq: undefined,
        dataFaq: action.payload.data,
        paginationFaq: action.payload.pagination,
      };
    case FaqTypes.FAQ_FAILURE:
      return {
        ...state,
        dataFaq: [],
        loadingFaq: false,
        errorFaq: action.payload.error,
      };
    case FaqTypes.CALL_REQUEST:
      return {
        ...state,
        loadingCall: true,
      };
    case FaqTypes.CALL_SUCCESS:
      return {
        ...state,
        loadingCall: false,
        errorCall: undefined,
        dataCall: action.payload.data,
        paginationCall: action.payload.pagination,
      };
    case FaqTypes.CALL_FAILURE:
      return {
        ...state,
        dataCall: [],
        loadingCall: false,
        errorCall: action.payload.error,
      };
    case FaqTypes.SEND_CALL_REQUEST:
      return {
        ...state,
        loadingSend: true,
      };
    case FaqTypes.SEND_CALL_SUCCESS:
      return {
        ...state,
        loadingSend: false,
        errorSend: undefined,
      };
    case FaqTypes.SEND_CALL_FAILURE:
      return {
        ...state,
        loadingSend: false,
        errorSend: action.payload.error,
      };
    default:
      return state;
  }
};

export default reducer;
