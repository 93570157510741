import { Button, Col, Row } from 'antd';
import styled from 'styled-components';
import { Layout } from '../../store/ducks/layout/types';
import { colors } from '../../utils/constants';

export const SubmitButton = styled(Button)<{ layout?: Layout }>`
  background-color: ${({ layout }) => layout?.color.default};
  color: white;
  border: 1px solid ${({ layout }) => layout?.color.default};
  :hover {
    background-color: ${({ layout }) => layout?.color.default};
    color: white;
    border: 1px solid ${({ layout }) => layout?.color.default};
  }
  :active {
    background-color: ${({ layout }) => layout?.color.default};
    color: white;
    border: 1px solid ${({ layout }) => layout?.color.default};
  }
  :focus {
    background-color: ${({ layout }) => layout?.color.default};
    color: white;
    border: 1px solid ${({ layout }) => layout?.color.default};
  }
`;

export const ClearButton = styled(Button)`
  background-color: transparent;
  color: red;
  border: 0 solid transparent;
  :hover {
    background-color: transparent;
    color: red !important;
    border: 0 solid transparent;
  }
  :active {
    background-color: transparent;
    color: red !important;
    border: 0 solid transparent;
  }
  :focus {
    background-color: transparent;
    color: red !important;
    border: 0 solid transparent;
  }
`;

export const Footer = styled(Row)`
  display: flex;
  justify-content: space-evenly;
  margin-top: 5px;
  @media only screen and (max-width: 1024px) {
    padding-top: 5px;
    border-top: 1px solid ${colors.grey};
  }
`;

export const FooterTitle = styled.small`
  color: ${colors.writeGrey};
`;

export const FooterCol = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 1024px) {
    width: 50%;
  }
`;

export const DefaultButton = styled(Button)<{ layout?: Layout }>`
  margin: 5px 0 5px;
  background-color: white;
  :hover {
    background-color: white;
    color: ${({ layout }) => layout?.color.default};
    border: 1px solid ${({ layout }) => layout?.color.default};
  }
  :active {
    background-color: white;
    color: ${({ layout }) => layout?.color.default};
    border: 1px solid ${({ layout }) => layout?.color.default};
  }
  :focus {
    background-color: white;
    color: ${({ layout }) => layout?.color.default};
    border: 1px solid ${({ layout }) => layout?.color.default};
  }
`;
