import { Drawer, Menu } from 'antd';
import styled from 'styled-components';
import { Layout } from '../../store/ducks/layout/types';
const { SubMenu } = Menu;

export const Container = styled.div<{ collapsed?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: white;
  min-height: 100vh;
  min-width: 80px;
  width: ${({ collapsed }) => (collapsed ? '80px' : '256px')};
  margin-right: 2px;
  z-index: 999;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.18), 0px 1px 6px rgba(0, 0, 0, 0.24);
`;

export const BoxVersion = styled.div<{ opened?: boolean }>`
  position: fixed;
  width: 215px;
  z-index: 999;
  left: 0;
  bottom: 0;
  display: ${({ opened }) => (opened ? 'block' : 'none')};
  background-color: white;
  @media only screen and (max-width: 550px) {
    width: 255px;
  }
`;

export const LogoContent = styled.div<{ collapsed?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
  padding: 10px;
  height: 118px;
  @media only screen and (max-width: 1024px) {
    padding: 20px;
  }
`;

export const Logo = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

export const CustomDrawer = styled(Drawer)`
  .ant-drawer-body {
    padding: 0;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;

export const MenuItem = styled(Menu.Item)<{ layout?: Layout }>`
  &.anticon {
    color: ${({ layout }) => layout?.color.default};
  }
  &.ant-menu-item:hover {
    color: ${({ layout }) => layout?.color.default};
  }
  &.ant-menu-item-selected:hover {
    background-color: ${({ layout }) => layout?.color.lighter};
    color: ${({ layout }) => layout?.color.default};
  }
  &.ant-menu-item:active {
    background-color: ${({ layout }) => layout?.color.lighter} !important;
    color: ${({ layout }) => layout?.color.default};
  }

  &.ant-menu-item::after {
    border-right: none !important;
  }

  &.ant-menu-item-selected {
    border-right: 3px solid ${({ layout }) => layout?.color.default};
    background-color: ${({ layout }) => layout?.color.lighter} !important;
    color: ${({ layout }) => layout?.color.default};
  }
`;

export const SubMenuCustom = styled(SubMenu)<{ layout?: Layout }>`
  &.ant-menu-submenu-title:hover,
  .ant-menu-submenu-open {
    .ant-menu-submenu-arrow::before,
    .ant-menu-submenu-arrow::after {
      background: ${({ layout }) => layout?.color.default} !important;
    }
  }

  &.ant-menu-item-selected,
  .ant-menu-submenu-selected,
  .ant-menu-item:hover,
  .ant-menu-submenu:hover,
  .ant-menu-submenu-selected,
  .ant-menu-submenu-title:hover {
    color: ${({ layout }) => layout?.color.default};
  }
  &.ant-menu-sub.ant-menu-inline > .ant-menu-item-selected,
  .ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
    background-color: ${({ layout }) => layout?.color.lighter};
  }

  &.anticon {
    color: ${({ layout }) => layout?.color.default};
  }
  &.ant-menu-submenu:hover {
    color: ${({ layout }) => layout?.color.default};
  }
  &.ant-menu-submenu-selected:hover {
    background-color: ${({ layout }) => layout?.color.lighter};
    color: ${({ layout }) => layout?.color.default};
  }
  &.ant-menu-submenu:active {
    background-color: ${({ layout }) => layout?.color.lighter} !important;
    color: ${({ layout }) => layout?.color.default};
  }

  &.ant-menu-submenu::after {
    border-right: none !important;
  }

  &.ant-menu-submenu-selected {
    background-color: ${({ layout }) => layout?.color.lighter} !important;
    color: ${({ layout }) => layout?.color.default};
  }
`;
