import React from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../store';
import { Column } from '@ant-design/plots';
import { maskCamelCase  } from '../../../utils/masks';
import { HorizontalAxis, Serie, SeriesGroup } from '../../../store/ducks/dashboard/types';
import DataSet from '@antv/data-set';
import EmptyChart from '../EmptyChart';

interface Props {
  title: string;
  data: any[];
  xField: string;
  series: Serie[];
  seriesGroup?: SeriesGroup[];
  yField: string;
  slider?: {
    start: number;
    end: number;
  };
  horizontalAxis?: HorizontalAxis;
  onClick(values: any): void;
}
const ColumnChart: React.FC<Props> = ({
  title,
  data,
  xField,
  yField,
  slider,
  seriesGroup,
  series,
  onClick
}) => {
  const layout = useSelector((state: ApplicationState) => state.layout.data);
  const { DataView } = DataSet;
  const getColor = () => {
    const colors = [
      '#5B8FF9',
      '#61DDAA',
      '#65789B',
      '#F6BD16',
      '#7262fd',
      '#78D3F8',
      '#9661BC',
      '#F6903D',
      '#008685',
    ];

    return colors;
  };

  const dv = new DataView().source(data);


  if (seriesGroup && seriesGroup?.length > 0) {
    const arr: string[] = [];
    const groups: any = {};
    seriesGroup.forEach((item, index) => {
      item.series.forEach((serie) => arr.push(serie.yField?.fieldName || ''));
      item.series.forEach((serie) =>
        Object.assign(groups, { [serie.yField?.fieldName || '']: `grupo${index}` })
      );
    });
    dv.transform({
      type: 'fold',
      fields: arr,
      key: 'stack',
      value: 'value',
    });
    dv.transform({
      type: 'map',
      callback(row) {
        return {
          ...row,
          group: groups[row.stack],
        };
      },
    });
  } else {
    dv.transform({
      type: 'fold',
      fields: series.map((serie) => serie.yField?.fieldName || ''),
      key: series?.length > 1 ? xField : undefined,
      value: 'valor',
    });
  }

  dv.rows.forEach(row => row['valor'] = parseFloat(row['valor']));
  const config: any = {
    data: dv.rows,
    xField: !(seriesGroup && seriesGroup?.length > 0)
      ? xField
      : seriesGroup[0].series[0].xField?.fieldName || '',
    yField: 'valor',
    isStack: !!(seriesGroup && seriesGroup?.length > 0),
    isGroup: !!(seriesGroup && seriesGroup?.length > 0),
    seriesField: seriesGroup && seriesGroup?.length > 0 ? 'stack' : undefined,
    groupField: seriesGroup && seriesGroup?.length > 0 ? 'group' : undefined,
    color: getColor(),
    min: 0,
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: true,
        tickCount: 5,
      },
    },
    slider: slider,
    tooltip:
      seriesGroup && seriesGroup?.length > 0
        ? {
          formatter: (datum: any) => ({
            name: maskCamelCase(datum.stack),
            value: datum.valor,
          }),
        }
        : undefined,
    label: {
      position: 'middle',
      layout: [
        {
          type: 'interval-adjust-position',
        },
        {
          type: 'interval-hide-overlap',
        },
        {
          type: 'adjust-color',
        },
      ],
    },
    meta: {
      [xField]: {
        alias: maskCamelCase(xField),
      },
      [yField]: {
        alias: maskCamelCase(yField),
      },
    },
    onReady: (plot: any) => {
      plot.on('element:click', ({ data: { data } }: any) => {
        onClick(data);
      });
    }
  };

  return data.length === 0 ? (
    <EmptyChart type={'column'} />
  ) : (
    <div style={{ padding: '20px' }}>
      <h2 style={{ color: layout?.color.dark }}>{title}</h2>
      <Column {...config} />
    </div>
  );
};

export default ColumnChart;
