import { Button, Col, Row } from 'antd';
import React, { Dispatch, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import AddressModal from '../../../components/AddressModal';
import CustomCollapse from '../../../components/CustomCollapse';
import { ApplicationState } from '../../../store';
import { maskCamelCase, maskCurrency, maskDate } from '../../../utils/masks';
import { AddressContent } from '../styles';
import { EnvironmentOutlined } from '@ant-design/icons';
import { Action } from 'typesafe-actions';
import { addressesRequest } from '../../../store/ducks/shoppingCart/actions';

const Address: React.FC = () => {
  const dispatch = useDispatch<Dispatch<Action>>();
  const editId = useSelector((state: ApplicationState) => state.shoppingCart.editId);
  const token = useSelector((state: ApplicationState) => state.auth.token);
  const payment = useSelector(
    (state: ApplicationState) =>
      state.shoppingCart.fields.find((item) => item.key === 'payment')?.value,
    shallowEqual
  );
  const selectedAddress = useSelector(
    (state: ApplicationState) => state.shoppingCart.selectedAddress
  );
  const layout = useSelector((state: ApplicationState) => state.layout.data);

  const result = useSelector((state: ApplicationState) => state.shoppingCart.result);

  const [addressesVisible, setAddressesVisible] = useState<boolean>(false);

  useEffect(() => {
    dispatch(addressesRequest());
  }, [dispatch, token]);

  return (
    <CustomCollapse
      icon={<EnvironmentOutlined style={{ color: layout?.color.default }} />}
      title="Endereço de entrega"
      defaultOpened
      hasNotification
      dot={!selectedAddress}
    >
      <Row>
        {selectedAddress && (
          <AddressContent>
            <Col span={24}>
              <small>{selectedAddress.descriptionDeliveryAddress}</small>
            </Col>
            <Col span={24}>
              <small>Contato: {maskCamelCase(selectedAddress.contactName)}</small>
            </Col>
            {selectedAddress.creditLimit && selectedAddress.creditLimit >= 0 && (
              <Col span={24}>
                <small>Limite de crédito: {maskCurrency(selectedAddress.creditLimit)}</small>
              </Col>
            )}
            {result?.deliveryForecast && result.amount && payment && (
              <Col span={24}>
                <small>Previsão de entrega: {maskDate(result?.deliveryForecast)}</small>
              </Col>
            )}
          </AddressContent>
        )}
        <Col span={24}>
          <Button
            data-cy="btn-selectAddress"
            onClick={() => setAddressesVisible(true)}
            disabled={!!editId}
            type="default"
            block
          >
            {!selectedAddress ? 'Selecione o endereço' : 'Trocar endereço'}
          </Button>
        </Col>
        <AddressModal visible={addressesVisible} setVisible={setAddressesVisible} />
      </Row>
    </CustomCollapse>
  );
};

export default Address;
