import React from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../store';
import { maskCamelCase, maskCpfCnpj } from '../../../utils/masks';
import { Content, Label } from '../styles';

const General: React.FC = () => {
  const user = useSelector((state: ApplicationState) => state.user.data);
  return (
    <>
      <h4>Informações gerais</h4>
      <Content>
        <Label>CNPJ/CPF: </Label>
        {maskCpfCnpj(user?.partner.cnpj || '')}
      </Content>
      <Content>
        <Label>Razão social: </Label>
        {maskCamelCase(user?.partner.corporateName || '')}
      </Content>
      {user?.partner.phone && (
        <Content>
          <Label>Telefone: </Label>
          {user?.partner.phone}
        </Content>
      )}
      <Content>
        <Label>Endereço: </Label>
        {user?.partner.address}
      </Content>
    </>
  );
};

export default General;
