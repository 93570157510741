import { action } from 'typesafe-actions';
import { ResponseError } from '../../../services/api';
import { Config, Data } from '../../../utils/newTableHelper';
import { Pagination, FinancialTypes } from './types';

export const loadFinancialRequest = (
  pagination?: Pagination,
  filter?: any,
  sorter?: any,
  searchValue?: string
) => action(FinancialTypes.LOAD_REQUEST, { pagination, filter, sorter, searchValue });
export const loadSuccess = (
  pagination: Pagination,
  data: Data[],
  config: Config,
) => action(FinancialTypes.LOAD_SUCCESS, { pagination, data, config });
export const loadFailure = (error: ResponseError) => action(FinancialTypes.LOAD_FAILURE, { error });
