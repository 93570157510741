import { action } from 'typesafe-actions';
import { ResponseError } from '../../../services/api';
import { Config } from '../../../utils/newTableHelper';
import { ProductTypes } from './types';

export const loadRequest = (id: string) => action(ProductTypes.LOAD_REQUEST, { id });
export const loadSuccess = (
  data: any[],
  relatedProducts: any[],
  config: Config,
  images: string[],
  thumbnails: string[]
) => action(ProductTypes.LOAD_SUCCESS, { data, relatedProducts, config, images, thumbnails });
export const loadFailure = (error: ResponseError) => action(ProductTypes.LOAD_FAILURE, { error });

export const setUnityDetail = (codProd: string, unity: string) =>
  action(ProductTypes.SET_UNITY, { codProd, unity });

export const unitySuccess = (data: any[]) => action(ProductTypes.UNITY_SUCCESS, { data });
