import { Button, message, Modal, Result } from 'antd';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../store';
import { SubmitButton } from '../../styles';

interface Props {
  title: string;
  content?: string;
  disabled?: boolean;
  url: string;
  icon?: any;
}

const XmlDisplay: React.FC<Props> = ({ title, content, disabled, url, icon }) => {
  const layout = useSelector((state: ApplicationState) => state.layout.data);
  const [visible, setVisible] = useState<boolean>(false);
  return (
    <>
      <Button
        data-cy="btn=xmlView"
        icon={icon}
        type="text"
        disabled={disabled}
        onClick={() => setVisible(true)}
      >
        {content}
      </Button>
      <Modal
        data-cy={`modal-${title}`}
        className="pdf-display"
        title={title}
        visible={visible}
        onCancel={() => setVisible(false)}
        cancelText="Fechar"
        footer={null}
        destroyOnClose
      >
        <Result
          status="success"
          title="XML da nota disponível!"
          subTitle="Clique no botão download para baixar o arquivo"
          extra={[
            <SubmitButton
              data-cy="btn-download"
              onClick={() => {
                setVisible(false);
                message.success({
                  content: `Documento XML baixado com sucesso!`,
                  style: { marginTop: '85vh' },
                });
              }}
              type="primary"
              href={url}
              layout={layout}
            >
              Download
            </SubmitButton>,
          ]}
        />
      </Modal>
    </>
  );
};

export default XmlDisplay;
