import React from 'react'
import { Tabs } from 'antd';

import SubScreen from './SubScreen';
import { Container } from './styles';
import { SubScreensProps } from './props';
import { KeyValue } from '../../utils/newTableHelper';

const { TabPane } = Tabs;

interface ISubScreen {
  id: number,
  name: string,
  linkFields: KeyValue[]
}


const SubScreens: React.FC<SubScreensProps> = ({ config, father }) => {
  const buildParams = (linkFields: KeyValue[]) => {
    const params = linkFields.map(field => {
      const data = father.find(item => item.name === field.value);

      return {
        id: field.key,
        name: data?.name,
        value: data?.value
      };
    });

    return params;
  }

  if (!config || !config.details || config.details.length === 0 || father.length === 0) return null;

  return (
    <Container>
      <Tabs type="card">
        {
          config.details.map((screen: ISubScreen) => (
            <TabPane tab={screen.name} key={screen.id}>
              <SubScreen screenId={screen.id} params={buildParams(screen.linkFields)} />
            </TabPane>
          ))
        }
      </Tabs>
    </Container>
  )
}

export default SubScreens
