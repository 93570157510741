import { ResponseError } from '../../../services/api';

export enum CampaignsTypes {
  'CAMPAIGNS_REQUEST' = '@campaigns/CAMPAIGNS_REQUEST',
  'CAMPAIGNS_SUCCESS' = '@campaigns/CAMPAIGNS_SUCCESS',
  'CAMPAIGNS_FAILURE' = '@campaigns/CAMPAIGNS_FAILURE',

  'CAMPAIGN_REQUEST' = '@campaigns/CAMPAIGN_REQUEST',
  'CAMPAIGN_SUCCESS' = '@campaigns/CAMPAIGN_SUCCESS',
  'CAMPAIGN_FAILURE' = '@campaigns/CAMPAIGN_FAILURE',
}

export interface Pagination {
  current: number;
  total: number;
  pageSize: number;
}

export interface Campaign {
  active: boolean;
  campaignId: number;
  description: string;
  image?: string;
  link?: string;
  title: string;
  type: 'BASE64' | 'URL';
}

export interface CampaignsState {
  readonly dataCampaigns: Campaign[];
  readonly dataCampaign?: Campaign;
  readonly paginationCampaigns?: Pagination;
  readonly loadingCampaigns: boolean;
  readonly loadingCampaign: boolean;
  readonly errorCampaigns?: ResponseError;
  readonly errorCampaign?: ResponseError;
}
