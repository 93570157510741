import styled from 'styled-components';
import { Layout } from '../../store/ducks/layout/types';
import { colors } from '../../utils/constants';

export const Container = styled.div`
  width: 100%;
  background: white;
  border-radius: 5px;
  box-shadow: 1px 2px 2px 2px ${colors.grey};
`;

export const ResumeContainer = styled.div<{ layout?: Layout }>`
  padding: 20px;
  background: white;
  position: fixed;
  border-radius: 5px;
  box-shadow: 1px 2px 2px 2px ${colors.grey};
  border-top: 5px solid ${({ layout }) => layout?.color.default};
  width: 24vw;

  @media only screen and (max-width: 768px) {
    position: sticky;
    width: 100%;
  }
`;

export const AddressContent = styled.div`
  padding-left: 10px;
  margin-bottom: 20px;
  border-left: 2px solid ${colors.borderGrey};
`;
