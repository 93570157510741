import { Button, Card } from 'antd';
import React, { useState } from 'react';
import { RightOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../store';
import ServerError from '../../../components/ServerError';
import { Redirect } from 'react-router-dom';
import ProductsCarousel from '../../../components/ProductCarousel';
import { ProductsTitle } from '../styles';

const HighlightProducts: React.FC = () => {
  const products = useSelector((state: ApplicationState) => state.homeProducts.data);
  const loading = useSelector((state: ApplicationState) => state.homeProducts.loading);
  const error = useSelector((state: ApplicationState) => state.homeProducts.error);
  const layout = useSelector((state: ApplicationState) => state.layout.data);
  const [redirect, setRedirect] = useState<string>('');

  return (
    <>
      {error ? (
        <ServerError {...error} />
      ) : (
        <Card
          actions={[
            <Button
              data-cy="btn-redirectProducts"
              onClick={() => setRedirect('/home/novo-pedido')}
              type="link"
              block
              size="small"
            >
              Ver todos os produtos <RightOutlined type="right" />
            </Button>,
          ]}
          bodyStyle={{ padding: '0 10px' }}
          title={
            <ProductsTitle layout={layout} style={{ margin: 0 }}>
              Produtos em destaque
            </ProductsTitle>
          }
        >
          <ProductsCarousel products={products} loading={loading} />
        </Card>
      )}
      {redirect && <Redirect to={redirect} />}
    </>
  );
};

export default HighlightProducts;
