import { Input, List } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../store';
import { ShoppingCartItem } from '../../../store/ducks/shoppingCart/types';

import { ShoppingCartOutlined } from '@ant-design/icons';
import CustomCollapse from '../../../components/CustomCollapse';
import GridItem from '../../../components/GridItem';
import MetreageItem from '../../../components/MetreageItem';
import MattressItem from '../../../components/MattressItem';
import CartItem from '../../../components/CartItem';
import { useWindowSize } from '../../../customHooks';
const { Search } = Input;

const CartList: React.FC = () => {
  const size = useWindowSize();
  const { cart: shoppingCart, currentAction } = useSelector((state: ApplicationState) => state.shoppingCart);
  const [searchValue, setSearchValue] = useState<string>('');
  const [cartList, setCartList] = useState<ShoppingCartItem[]>(shoppingCart);
  const layout = useSelector((state: ApplicationState) => state.layout.data);

  const onSearch = useCallback(() => {
    const filteredCart = shoppingCart.filter((item: ShoppingCartItem) => {
      const cartItemDescription = item.description?.toUpperCase() || '';
      return cartItemDescription.includes(searchValue?.toUpperCase());
    });

    setCartList(filteredCart);
  }, [shoppingCart, searchValue]);

  useEffect(() => {
    onSearch();
  }, [onSearch]);

  const renderItem = (item: ShoppingCartItem) => {
    if (item.grid) return <GridItem {...item} />;
    if (item.metreages) return <MetreageItem {...item} />;
    if (item.mattresses) return <MattressItem {...item} />;
    return <CartItem {...item} />;
  };

  return (
    <CustomCollapse
      icon={<ShoppingCartOutlined style={{ color: layout?.color.default }} />}
      title="Meu carrinho"
      defaultOpened
    >
      <Search
        data-cy="input-search"
        value={searchValue}
        onChange={(text) => setSearchValue(text.target.value)}
        onSearch={onSearch}
      />
      <List
        itemLayout={(size.width || 0) > 550 ? 'horizontal' : 'vertical'}
        dataSource={cartList}
        renderItem={(item) => renderItem(item)}
        loading={currentAction === 'INIT'}
      />
    </CustomCollapse>
  );
};

export default CartList;
