import React, { Dispatch, useEffect, useState } from 'react';
import { Tooltip, TreeSelect } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Action } from 'typesafe-actions';
import { TreeNode } from 'antd/lib/tree-select';
import { ApplicationState } from '../../../store';
import { Size, useWindowSize } from '../../../customHooks';
import { categoriesRequest, loadRequest, setFilter } from '../../../store/ducks/products/actions';

const { SHOW_CHILD } = TreeSelect;
const CategorySelect: React.FC = () => {
  const categories = useSelector((state: ApplicationState) => state.products.categories);
  const filter = useSelector((state: ApplicationState) => state.products.filter);
  const pagination = useSelector((state: ApplicationState) => state.products.pagination);
  const loading = useSelector((state: ApplicationState) => state.products.loadingCategories);
  const shoppingCartConfig = useSelector((state: ApplicationState) => state.shoppingCart.config);
  const { data: requiredFields, values: requiredValues } = useSelector((state: ApplicationState) => state.orderFields);
  const applyFilterBeforeListing = shoppingCartConfig?.details?.applyFilterBeforeListing;
  const dispatch = useDispatch<Dispatch<Action>>();
  const [value, setValue] = useState<any>();
  const size: Size = useWindowSize();

  useEffect(() => {
    const categoriesFilter = filter?.filter((item) => item?.name === 'categoryId');
    if (categoriesFilter.length === 0) return;
    const values = categoriesFilter
      .map((item) => item.value)
      .toString()
      .split(',');
    setValue(values);
  }, [filter]);

  const renderNode = (node: any) => {
    return (
      <TreeNode key={node.key} value={node.key} title={node.value}>
        {node.list && node.list.map((child: any) => renderNode(child))}
      </TreeNode>
    );
  };
  const onChange = (e: any) => {
    setValue(e);

    if (applyFilterBeforeListing && !e?.length) {
      dispatch(setFilter([]));
      return;
    }

    dispatch(
      e.length !== 0
        ? loadRequest(
          [
            ...filter?.filter((item) => item?.name !== 'categoryId'),
            { name: 'categoryId', value: e.map((item: string) => parseInt(item)) },
          ],
          undefined,
          { ...pagination, current: 1 }
        )
        : loadRequest([...filter?.filter((item) => item?.name !== 'categoryId')])
    );
  };
  useEffect(() => {
    dispatch(categoriesRequest());
  }, [dispatch]);

  const tProps = {
    showSearch: false,
    categories,
    maxTagCount: 2,
    value: value,
    loading,
    allowClear: true,
    onChange: onChange,
    treeCheckable: true,
    showCheckedStrategy: SHOW_CHILD,
    placeholder: 'Selecione uma categoria',
    style: {
      marginBottom: (size.width ?? 0) > 1160 ? '0px' : '10px',
      marginRight: '20px',
      minWidth: (size.width ?? 0) > 1160 ? '400px' : '100%',
    },
  };
  return (
    <Tooltip placement="topRight" title={requiredValues.length !== requiredFields.length && requiredFields.length > 0 ? "Primeiro preencha os filtros obrigatórios" : ''}>
      <TreeSelect {...tProps} disabled={requiredValues.length !== requiredFields.length && requiredFields.length > 0}>{categories.map(renderNode)}</TreeSelect>
    </Tooltip>
  );
};

export default CategorySelect;
