import { action } from 'typesafe-actions';
import { ResponseError } from '../../../services/api';
import { Pagination, DeliveryPointTypes, DeliveryPoint } from './types';

export const pointsRequest = (userId: string, contactId: string, pagination?: Pagination) =>
  action(DeliveryPointTypes.POINTS_REQUEST, { userId, contactId, pagination });
export const pointsSuccess = (pagination: Pagination, data: DeliveryPoint[]) =>
  action(DeliveryPointTypes.POINTS_SUCCESS, { pagination, data });
export const pointsFailure = (error: ResponseError) =>
  action(DeliveryPointTypes.POINTS_FAILURE, { error });
