import { Config } from '../../../utils/newTableHelper';
import { ResponseError } from '../../../services/api';
import { KeyValue } from '../dashboard/types';

export enum ProductsTypes {
  'LOAD_REQUEST' = '@products/LOAD_REQUEST',
  'LOAD_SUCCESS' = '@products/LOAD_SUCCESS',
  'LOAD_FAILURE' = '@products/LOAD_FAILURE',

  'CONFIG_SUCCESS' = '@products/CONFIG_SUCCESS',

  'LOAD_REQUEST_CATEGORY' = '@productsCategory/LOAD_REQUEST',
  'LOAD_SUCCESS_CATEGORY' = '@productsCategory/LOAD_SUCCESS',
  'LOAD_FAILURE_CATEGORY' = '@productsCategory/LOAD_FAILURE',

  'FAVORITE_REQUEST' = '@products/FAVORITE_REQUEST',
  'FAVORITE_SUCCESS' = '@products/FAVORITE_SUCCESS',
  'FAVORITE_FAILURE' = '@products/FAVORITE_FAILURE',

  'SET_UNITY' = '@products/SET_UNITY',
  'SET_FILTER' = '@products/SET_FILTER',
  'SET_MODE' = '@products/SET_MODE',
  'RESET_SORTER' = '@products/RESET_SORTER',
  'RESET_ADVANCED_FILTER' = '@products/RESET_ADVANCED_FILTER',
  'CLEAR_SEARCH' = '@products/CLEAR_SEARCH',
  'UNITY_SUCCESS' = '@products/UNITY_SUCCESS',

  'ADVANCED_FIELDS_REQUEST' = '@products/ADVANCED_FIELDS_REQUEST',
  'ADVANCED_FIELDS_SUCCESS' = '@products/ADVANCED_FIELDS_SUCCESS',
  'ADVANCED_FIELDS_FAILURE' = '@products/ADVANCED_FIELDS_FAILURE',
}

export interface Filter {
  name: string;
  value: any;
}

export interface Category {
  key: number | string;
  value: string;
  list: Category[];
}

export interface Sorter {
  name: string;
  direction: 'ASC' | 'DESC';
}
export interface Pagination {
  current: number;
  total: number;
  pageSize: number;
}

export interface ProductsState {
  readonly data: any[];
  readonly config?: Config;
  readonly pagination: Pagination;
  readonly loading: boolean;
  readonly loadingConfig: boolean;
  readonly loadingCategories: boolean;
  readonly errorProducts?: ResponseError;
  readonly errorCategory?: ResponseError;
  readonly categories: Category[];
  readonly hasStock?: boolean;
  readonly sorter?: Sorter;
  readonly filter: Filter[];
  readonly search: string;
  readonly mode?: string;
  readonly loadingAdvancedFields?: boolean
  readonly advancedFields?: any[];
  readonly advancedFilters?: KeyValue[];
}
