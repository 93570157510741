import styled from 'styled-components';
import { colors } from '../../utils/constants';

export const ContentDetail = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const ContainerData = styled.div`
  padding: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: white;
  margin-top: 20px;
  border-radius: 5px;
  box-shadow: 1px 2px 2px 2px ${colors.grey};
`;

export const ContentData = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
`;

export const ItemData = styled.div`
  width: 100%;
  padding-right: 6px;
  display: flex;
  flex-direction: row;
`;

export const TitleData = styled.h4`
  font-weight: 500;
  font-size: 14px;
  margin-right: 4px;
`;

export const ValueData = styled.span`
  font-size: 14px;
`;
