import { Button, Form, Input, InputNumber } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { Dispatch, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MaskedInput from 'react-text-mask';
import { Action } from 'typesafe-actions';
import { SaveOutlined } from '@ant-design/icons';
import UploadUserPhoto from '../../../components/UploadUserPhoto';
import { Size, useWindowSize } from '../../../customHooks';
import { ApplicationState } from '../../../store';
import { updateRequest } from '../../../store/ducks/user/actions';
import { phoneMask, unmask } from '../../../utils/masks';
import { Container, FormItem } from '../styles';

const BasicConfigs: React.FC = () => {
  const user = useSelector((state: ApplicationState) => state.user);
  const dispatch = useDispatch<Dispatch<Action>>();
  const size: Size = useWindowSize();
  const [form] = useForm();

  useEffect(() => {
    form.resetFields();
  }, [form, user.data]);

  const onFinish = (values: any) => {
    if (!values) return;
    if (values.contact.phone) values.contact.phone = unmask(values.contact.phone);
    dispatch(
      updateRequest(
        values.name,
        values?.contact?.phone,
        values?.contact?.email,
        values?.budget?.contributionMargin
      )
    );
  };

  return (
    <>
      <h4>Configurações básicas</h4>
      <Container>
        {(size?.width ?? 0) <= 1024 && <UploadUserPhoto />}
        <Form
          form={form}
          style={{ width: (size?.width ?? 0) > 1024 ? '50%' : '100%' }}
          layout="vertical"
          name="basic"
          initialValues={user.data}
          onFinish={onFinish}
        >
          <FormItem
            rules={[{ required: true, message: 'Por favor digite o nome' }]}
            name="name"
            label="Nome"
          >
            <Input data-cy="input-name" placeholder="Nome" />
          </FormItem>

          <FormItem name={['contact', 'email']} label="E-mail do contato">
            <Input data-cy="input-email" type="email" placeholder="E-mail de contato" />
          </FormItem>

          <FormItem name={['contact', 'phone']} label="Telefone">
            <MaskedInput data-cy="input-phone" className="ant-input" mask={phoneMask} showMask />
          </FormItem>

          {user.data?.budget.show && (
            <FormItem name={['budget', 'contributionMargin']} label="Margem de contribuição">
              <InputNumber data-cy="input-budget" style={{ width: '100%' }} />
            </FormItem>
          )}

          <FormItem>
            <Button
              data-cy="btn-saveMydata"
              icon={<SaveOutlined />}
              block={(size.width ?? 0) <= 1024}
              type="primary"
              htmlType="submit"
              loading={user.loading}
            >
              Salvar meus dados
            </Button>
          </FormItem>
        </Form>
        {(size?.width ?? 0) > 1024 && <UploadUserPhoto />}
      </Container>
    </>
  );
};

export default BasicConfigs;
