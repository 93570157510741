import { Dispatch, FC, useEffect, useState } from 'react';
import { Button, Form, Input } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Action } from 'typesafe-actions';
import { UserOutlined } from '@ant-design/icons';
import { Redirect } from 'react-router-dom';
import Swal from 'sweetalert2';

import { ApplicationState } from '../../store';
import { cleanState, passwordRequest } from '../../store/ducks/auth/actions';
import { Container, FormItem, FormLastItem, Submit } from './styles';
import { Auth } from '../../store/ducks/auth/types';
import { Layout } from '../../store/ducks/layout/types';
import { colors } from '../../utils/constants';

interface Props {
  layout?: Layout;
}

export interface Rules {
  required: boolean;
  message: string;
}

const ForgotPassword: FC<Props> = () => {
  const loading = useSelector((state: ApplicationState) => state.auth.passwordLoading);
  const error = useSelector((state: ApplicationState) => state.auth.passwordError);
  const message = useSelector((state: ApplicationState) => state.auth.forgotPasswordMessage);
  const errorMessage = useSelector((state: ApplicationState) => state.auth.errorMessage);
  const layout = useSelector((state: ApplicationState) => state.layout.data);
  const [login, setLogin] = useState<boolean>(false);
  const dispatch = useDispatch<Dispatch<Action>>();
  const dispatchClean = useDispatch<Dispatch<Action>>();
  const onFinish = (authValues: Auth) => {
    dispatch(passwordRequest(authValues));
  };
  const userNameRules: Rules = {
    required: true,
    message: 'Por favor informe um e-mail!',
  };

  useEffect(() => {
    if (loading || (!error && !message)) return;
    Swal.fire({
      title: error ? 'Erro!' : 'Sucesso!',
      confirmButtonColor: layout?.color.default,
      html: error ? errorMessage : message,
      icon: error ? 'error' : 'success',
    });
    dispatchClean(cleanState());
  }, [error, errorMessage, loading, message, dispatchClean, layout?.color.default]);

  return (
    <Container>
      <Form name="basic" initialValues={{ remember: true }} onFinish={onFinish}>
        <FormItem name="userName" rules={[userNameRules]}>
          <Input
            data-cy="input-email"
            placeholder="Digite seu email"
            type="email"
            prefix={<UserOutlined style={{ color: colors.writeGrey }} />}
          />
        </FormItem>

        <FormItem>
          <Submit data-cy="btn-sendEmail" layout={layout} htmlType="submit" block loading={loading}>
            Enviar email
          </Submit>
        </FormItem>

        <FormLastItem>
          <Button
            data-cy="btn-backToLogin"
            type="link"
            block
            onClick={() => {
              setLogin(true);
            }}
          >
            Voltar para o login
          </Button>
        </FormLastItem>
      </Form>

      {login && <Redirect to="/login" />}
    </Container>
  );
};

export default ForgotPassword;
