import { Reducer } from 'redux';
import { HomeOrdersState, HomeOrdersTypes } from './types';

const INITIAL_STATE: HomeOrdersState = {
  data: [],
  loading: false,
};

const reducer: Reducer<HomeOrdersState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case HomeOrdersTypes.LOAD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case HomeOrdersTypes.LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        ...action.payload
      };
    case HomeOrdersTypes.LOAD_FAILURE:
      return {
        ...state,
        data: [],
        loading: false,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
