import styled from 'styled-components';
import { colors } from '../../utils/constants';

export const CommonQuestions = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 5px;
  box-shadow: 1px 2px 2px 2px ${colors.grey};
`;

export const SendQuestions = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 5px;
  box-shadow: 1px 2px 2px 2px ${colors.grey};
  padding: 20px;
`;

export const HistoricQuestions = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 1px 2px 2px 2px ${colors.grey};
`;

export const CommonQuestionsHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 1px 2px 2px 2px ${colors.grey};
  margin-bottom: 20px;
  @media only screen and (max-width: 1024px) {
    flex-direction: column;
  }
`;

export const TitleList = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media only screen and (max-width: 1024px) {
    flex-direction: column;
  }
`;

export const SituationCalled = styled.span`
  font-size: 14px;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.65);
`;

export const QuestionsSolution = styled.div`
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,
    'Open Sans', 'Helvetica Neue', sans-serif;
  white-space: pre-wrap;
  color: ${colors.darkGrey};
`;
