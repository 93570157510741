import { ResponseError } from '../../../services/api';
import { Config } from '../../../utils/newTableHelper';

export enum CampaignProductsTypes {
  'CAMPAIGN_PRODUCTS_REQUEST' = '@campaigns/CAMPAIGN_PRODUCTS_REQUEST',
  'CAMPAIGN_PRODUCTS_SUCCESS' = '@campaigns/CAMPAIGN_PRODUCTS_SUCCESS',
  'CAMPAIGN_PRODUCTS_FAILURE' = '@campaigns/CAMPAIGN_PRODUCTS_FAILURE',
}


export interface CampaignProductsState {
  readonly data: any[];
  readonly config?: Config;
  readonly loading: boolean;
  readonly error?: ResponseError;
}
