import { ResponseError } from "../../../services/api";
import { Config, Data, DataValues } from "../../../utils/newTableHelper";

export enum MyOrdersDetailTypes {
  'LOAD_REQUEST' = '@myOrdersDetail/LOAD_REQUEST',
  'LOAD_SUCCESS' = '@myOrdersDetail/LOAD_SUCCESS',
  'LOAD_FAILURE' = '@myOrdersDetail/LOAD_FAILURE',

  'DATA_REQUEST' = '@myOrdersDetail/DATA_REQUEST',
  'DATA_SUCCESS' = '@myOrdersDetail/DATA_SUCCESS',
  'DATA_FAILURE' = '@myOrdersDetail/DATA_FAILURE',

  'STEPS_REQUEST' = '@myOrdersDetail/STEPS_REQUEST',
  'STEPS_SUCCESS' = '@myOrdersDetail/STEPS_SUCCESS',
  'STEPS_FAILURE' = '@myOrdersDetail/STEPS_FAILURE',
}

export interface Pagination {
  current: number;
  total: number;
  pageSize: number;
}

export interface Step {
  order: number;
  title: string;
  isCurrent?: boolean;
  fixedStatus?: string;
}

export interface MyOrdersDetailState {
  readonly data: Data[];
  readonly config?: Config;
  readonly pagination?: Pagination;
  readonly loading: boolean;
  readonly loadingTable: boolean;
  readonly dataDetail: DataValues[];
  readonly configDetail?: Config;
  readonly steps: Step[];
  readonly errorData?: ResponseError;
  readonly errorSteps?: ResponseError;
  readonly errorDetails?: ResponseError;
}
