import React, { Dispatch, useEffect } from 'react';
import { Form, Select, Tooltip } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import CustomCollapse from '../../../components/CustomCollapse';
import { ApplicationState } from '../../../store';
import { maskCamelCase, maskCurrency } from '../../../utils/masks';
import { Action } from 'typesafe-actions';
import { CreditCardOutlined } from '@ant-design/icons';
import { paymentsRequest } from '../../../store/ducks/shoppingCart/actions';
import CreditCard from '../CreditCard';


const { Option } = Select;
interface Props {
  form: FormInstance<any>;
  onValuesChange: (_value: any, values: any) => void;
}

const PaymentSelect: React.FC<Props> = ({ form, onValuesChange }) => {
  const dispatch = useDispatch<Dispatch<Action>>();
  const editId = useSelector((state: ApplicationState) => state.shoppingCart.editId);
  const token = useSelector((state: ApplicationState) => state.auth.token);
  const subTotal = useSelector((state: ApplicationState) => state.shoppingCart.subTotal);
  const layout = useSelector((state: ApplicationState) => state.layout.data);

  const payments = useSelector((state: ApplicationState) => state.shoppingCart.payments);
  const payment = useSelector(
    (state: ApplicationState) =>
      state.shoppingCart.fields.find((item) => item.key === 'payment')?.value,
    shallowEqual
  );
  const paymentLoading = useSelector(
    (state: ApplicationState) => state.shoppingCart.paymentLoading
  );

  const selectedPayment = payments.find(item => item.key === payment);

  useEffect(() => {
    dispatch(paymentsRequest());
  }, [dispatch, editId, token]);

  useEffect(() => {
    form.setFieldsValue({ payment: payment });
  }, [form, payment]);

  useEffect(() => {
    if (payment) return;
    form.setFieldsValue({
      payment:
        payments.length === 1
          ? payments[0].key
          : payments.find((item: any) => item.defaultValue)?.key,
    });
  }, [form, payment, payments]);


  return (
    <CustomCollapse
      icon={<CreditCardOutlined style={{ color: layout?.color.default }} />}
      title="Forma de pagamento"
      defaultOpened
      hasNotification
      dot={!payment}
    >
      <Form layout="vertical" onValuesChange={onValuesChange} form={form}>
        <Form.Item
          rules={[{ required: true, message: `Forma de pagamento é obrigatório.` }]}
          key={'payment'}
          label={''}
          name={'payment'}
          initialValue={
            payments.length === 1
              ? payments[0].key
              : payments.find((item: any) => item.defaultValue)?.key
          }
          style={{ marginBottom: '10px' }}
        >
          <Select
            data-cy="input-selectPayment"
            loading={paymentLoading}
            placeholder="Selecione a forma de pagamento"
            disabled={!!editId}
            allowClear
            style={{ width: '100%' }}
            value={payment}
            defaultValue={
              payments.length === 1
                ? payments[0].key
                : payments.find((item: any) => item.defaultValue)?.key
            }
          >
            {payments.map((item) => {
              return (
                <>
                  {item.maxOrderValue ? (
                    <Option
                      disabled={!!(item.maxOrderValue && subTotal > item.maxOrderValue)}
                      value={item.key}
                    >
                      <Tooltip
                        placement="right"
                        title={`Valor máximo ${maskCurrency(item.maxOrderValue)}`}
                      >
                        {maskCamelCase(item.value)}
                      </Tooltip>
                    </Option>
                  ) : (
                    <Option value={item.key}>{maskCamelCase(item.value)}</Option>
                  )}
                </>
              );
            })}
          </Select>
        </Form.Item>


      </Form>

      {selectedPayment && selectedPayment.paymentMethod?.type === 'PAGARME' && <CreditCard form={form} selectedPayment={selectedPayment} />}
    </CustomCollapse>
  );
};

export default PaymentSelect;
