import { AxiosResponse } from 'axios';
import { call, put, select } from 'redux-saga/effects';
import { ApplicationState } from '../..';
import api from '../../../services/api';
import { requestHeaders } from '../../../utils/apiHelper';
import { setValues } from '../orderFields/actions';
import { clearCart } from '../shoppingCart/actions';

import {
  loginSuccess,
  loginFailure,
  loginRequest,
  passwordRequest,
  passwordSuccess,
  passwordFailure,
  cleanState,
  updateSuccess,
  updateFailure,
  updateRequest,
  changePasswordRequest,
  changePasswordSuccess,
  changePasswordFailure,
  refreshSuccess,
  refreshhFailure,
  newPasswordRequest,
  newPasswordSuccess,
  newPasswordFailure,
} from './actions';

export function* login({ payload }: ReturnType<typeof loginRequest>) {
  try {
    const params = `grant_type=password&username=${payload.auth.userName}&password=${payload.auth.password}`;
    const headers = {
      authorization: 'Basic Y29kZXBlYzpjb2Rl',
      'content-type': 'application/x-www-form-urlencoded',
    };
    const response: AxiosResponse<{ ['access_token']: string; ['refresh_token']: string }> =
      yield call(api.post, 'oauth/token', params, { headers: headers });
    yield put(loginSuccess(response.data['access_token'], response.data['refresh_token']));
  } catch (err: any) {
    yield put(
      loginFailure(
        err.response.status === 423
          ? err.response.data['error_description']
          : 'Usuário ou senha inválido!',
        err.response.status,
        payload.auth.userName
      )
    );
  }
}

export function* forgotPassword({ payload }: ReturnType<typeof passwordRequest>) {
  try {
    const response: AxiosResponse<{ message: string }> = yield call(
      api.post,
      `/authentication/recoverpassword`,
      {
        email: payload.auth.userName,
      }
    );

    yield put(passwordSuccess(response.data.message));
  } catch (err: any) {
    yield put(passwordFailure(err.response.data.message));
  }
}

export function* changePassword({ payload }: ReturnType<typeof changePasswordRequest>) {
  try {
    const response: AxiosResponse<{ message: string }> = yield call(
      api.post,
      '/authentication/changepassword',
      payload
    );
    yield put(changePasswordSuccess(response.data.message));
  } catch (err: any) {
    yield put(changePasswordFailure(err.response.data.message));
  }
}

export function* newPassword({ payload }: ReturnType<typeof newPasswordRequest>) {
  try {
    const response: AxiosResponse<{ message: string }> = yield call(
      api.post,
      '/authentication/recoverpassword/newpassword',
      payload
    );
    yield put(newPasswordSuccess(response.data.message));
  } catch (err: any) {
    yield put(newPasswordFailure(err.response.data.message));
  }
}

export function* updateToken({ payload }: ReturnType<typeof updateRequest>) {
  try {
    const state: ApplicationState = yield select();
    const headers = requestHeaders(state.auth.token);
    const response: AxiosResponse<{ ['access_token']: string; ['refresh_token']: string }> =
      yield call(api.post, '/partnerchange/partners', payload.partnerAuth, {
        headers: headers,
      });
    yield put(setValues([]));
    yield put(clearCart());
    yield put(updateSuccess(response.data['access_token'], response.data['refresh_token']));
  } catch (err: any) {
    yield put(
      err.response.status !== 401 ? updateFailure(err.response.data.message) : cleanState()
    );
  }
}

export function* refreshToken() {
  try {
    const state: ApplicationState = yield select();
    if (state.auth.refreshToken === '' || state.auth.token === '') throw new Error('error');
    const params = `grant_type=refresh_token&refresh_token=${state.auth.refreshToken}`;
    const headers = {
      authorization: 'Basic Y29kZXBlYzpjb2Rl',
      'content-type': 'application/x-www-form-urlencoded',
    };
    const response: AxiosResponse<{ ['access_token']: string; ['refresh_token']: string }> =
      yield call(api.post, '/oauth/token', params, {
        headers: headers,
      });
    yield put(refreshSuccess(response.data['access_token'], response.data['refresh_token']));
  } catch (err: any) {
    yield put(refreshhFailure());
  }
}
