import { ResponseError } from '../../../services/api';

export enum SignUpFieldsTypes {
  'LOAD_REQUEST' = '@signUpFields/LOAD_REQUEST',
  'LOAD_SUCCESS' = '@signUpFields/LOAD_SUCCESS',
  'LOAD_FAILURE' = '@signUpFields/LOAD_FAILURE',

  'SEND_REQUEST' = '@signUpFields/SEND_REQUEST',
  'SEND_SUCCESS' = '@signUpFields/SEND_SUCCESS',
  'SEND_FAILURE' = '@signUpFields/SEND_FAILURE',

  'CLEAN_STATE' = '@signUpFields/CLEAN_STATE',
}

export interface SignUpForm {
  title?: string;
  fields: FormFields[];
}

export interface Success {
  id: number;
  message: string;
  allowLogin: boolean;
}

export interface SendObject {
  id?: number;
  name: string;
  value: any;
}

export interface FormFields {
  configId: number;
  order: number;
  idField: number;
  fieldName: string;
  description: string;
  presentationType: string;
  defaultValue?: any;
  enable?: boolean;
  mask?: string;
  required: boolean;
  visible: boolean;
}

export interface SignUpFieldsState {
  readonly data: SignUpForm[];
  readonly sendLoading: boolean;
  readonly loading: boolean;
  readonly sendError?: ResponseError;
  readonly error?: ResponseError;
  readonly success?: Success;
}
