import React, { Dispatch, useEffect } from 'react';
import { Action } from 'typesafe-actions';
import { useDispatch, useSelector } from 'react-redux';

import { ApplicationState } from '../../store';
import { requestCampaigns } from '../../store/ducks/campaigns/actions';
import { Container } from '../../styles';
import CampaignsList from './CampaignsList';

const Campaigns: React.FC = () => {
  const token = useSelector((state: ApplicationState) => state.auth.token);

  const dispatch = useDispatch<Dispatch<Action>>();
  useEffect(() => {
    dispatch(requestCampaigns());
  }, [dispatch, token]);

  return (
    <Container>
      <CampaignsList />
    </Container>
  );
};

export default Campaigns;
