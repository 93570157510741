import { Carousel, Spin } from 'antd';
import React, { useState } from 'react';
import { LoadingOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { CarouselStyled, ImageContainer, ImageContent } from './styles';
import defaultImg from '../../../../assets/img/default.jpg';
import { ApplicationState } from '../../../../store';

interface Props {
  images: string[];
}
const ImageCarousel: React.FC<Props> = ({ images }) => {
  const layout = useSelector((state: ApplicationState) => state.layout.data);
  const [nextArrowHover, SetNextArrowHover] = useState<boolean>(false);
  const [prevArrowHover, SetPrevArrowHover] = useState<boolean>(false);

  const renderImages = (url: string, index: number) => {
    return (
      <ImageContainer key={index}>
        <ImageContent
          preview={false}
          src={url}
          alt="image"
          loading="lazy"
          fallback={defaultImg}
          placeholder={
            <Spin indicator={<LoadingOutlined style={{ color: layout?.color.default }} />} />
          }
        />
      </ImageContainer>
    );
  };
  const SampleNextArrow = ({ className, style, onClick }: any) => {
    return (
      <div onClick={(e) => e.stopPropagation()}>
        <div
          onMouseEnter={() => SetNextArrowHover(true)}
          onMouseLeave={() => SetNextArrowHover(false)}
          className={className}
          style={{
            ...style,
            color: layout?.color.default,
            fontSize: !nextArrowHover ? '20px' : '24px',
            lineHeight: '1.5715',
          }}
          onClick={onClick}
        >
          <RightOutlined />
        </div>
      </div>
    );
  };

  const SamplePrevArrow = ({ className, style, onClick }: any) => {
    return (
      <div onClick={(e) => e.stopPropagation()}>
        <div
          className={className}
          onMouseEnter={() => SetPrevArrowHover(true)}
          onMouseLeave={() => SetPrevArrowHover(false)}
          style={{
            ...style,
            color: layout?.color.default,
            fontSize: !prevArrowHover ? '20px' : '24px',
            lineHeight: '1.5715',
          }}
          onClick={onClick}
        >
          <LeftOutlined />
        </div>
      </div>
    );
  };
  return (
    <CarouselStyled>
      <Carousel
        nextArrow={<SampleNextArrow />}
        prevArrow={<SamplePrevArrow />}
        slidesToShow={1}
        infinite
        arrows
        dots={false}
        autoplay={false}
        slidesToScroll={1}
      >
        {images.map(renderImages)}
      </Carousel>
    </CarouselStyled>
  );
};

export default ImageCarousel;
