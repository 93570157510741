import { Dispatch, FC, useCallback, useEffect, useState } from 'react';
import { Button, InputNumber, Modal, Row, Col, Tooltip, Select } from 'antd';
import { DeleteFilled } from '@ant-design/icons';
import { maskCurrency } from '../../../utils/masks';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../../store';
import { Container } from '../../../styles';
import {
  ClearButton,
  DefaultButton,
  Footer,
  FooterCol,
  FooterTitle,
  SubmitButton,
} from '../styles';
import { addItemRequest, deleteItemRequest } from '../../../store/ducks/shoppingCart/actions';
import { Action } from 'typesafe-actions';
import Swal from 'sweetalert2';
import Scrollbars from 'react-custom-scrollbars';
import CustomTable from '../../CustomTable';
import { Dimension, Format } from '../../../store/ducks/shoppingCart/types';

const { Option } = Select;
interface Props {
  product: any;
}

const MattressModal: FC<Props> = ({ product }) => {
  const dispatch = useDispatch<Dispatch<Action>>();
  const layout = useSelector((state: ApplicationState) => state.layout.data);
  const { cart: shoppingCart } = useSelector((state: ApplicationState) => state.shoppingCart);
  const [visible, setVisible] = useState<boolean>(false);
  const [quantity, setQuantity] = useState<number>();
  const [size, setSize] = useState<number>();
  const [height, setHeight] = useState<number>();
  const [width, setWidth] = useState<number>();
  const [format, setFormat] = useState<Format>('retangular');
  const [mattresses, setMattresses] = useState<Dimension[]>([]);
  const [totalQuantity, setTotalQuantity] = useState<number>(0);
  const [totalVolume, setTotalVolume] = useState<number>(0);

  const handleModalVisibility = () => {
    setVisible(!visible);
  };

  const mattressExists = () => {
    return !!mattresses?.find(
      (item) =>
        item.size === size &&
        item.format === format &&
        item.height === height &&
        item.width === width &&
        quantity &&
        item.quantity
    );
  };

  const volumeCalc = (format: Format) => {
    return format === 'redondo'
      ? (comprimento: number, altura: number) => 3.14 * (comprimento * comprimento) * altura
      : (comprimento: number, altura: number, largura: number) => comprimento * largura * altura;
  };

  const calculate = useCallback(() => {
    setTotalQuantity(0);
    setTotalVolume(0);
    mattresses.forEach((item) => {
      setTotalQuantity((oldValue) => oldValue + (item.quantity || 0));
      setTotalVolume(
        (oldValue) =>
          oldValue +
          volumeCalc(item.format)(item.size || 0, item.height || 0, item.width || 0) *
          (item.quantity || 0)
      );
    });
  }, [mattresses]);

  const cleanFields = () => {
    setHeight(undefined);
    setQuantity(undefined);
    setSize(undefined);
    setWidth(undefined);
  };

  const deleteMattress = (record: Dimension) => {
    const newMattresses = mattresses?.filter((item) => item !== record);
    setMattresses(newMattresses);
    if (shoppingCart.find((item) => item.codProd === product.CODPROD)?.mattresses) {
      if (newMattresses?.length === 0) {
        dispatch(
          deleteItemRequest({
            codProd: product?.CODPROD,
            quantity: 0,
            mattresses: newMattresses,
            description: product?.DESCRPROD,
            value: getValue(),
            unity: product?.UNIDADE[0],
            isPromo: product?.PROMOCAO?.isPromo,
          })
        );
      } else {
        dispatch(
          addItemRequest({
            unity: product?.UNIDADE[0],
            codProd: product?.CODPROD,
            quantity: newMattresses?.reduce((total, item) => total + (item.quantity || 0), 0) ?? 0,
            mattresses: newMattresses,
            description: product?.DESCRPROD,
            value: getValue(),
            isPromo: product?.PROMOCAO?.isPromo,
            controlType: product?.DETAILS?.stockControlType
          })
        );
      }
    }
  };

  const addMattress = () => {
    if (mattressExists()) {
      Swal.fire({
        title: 'Item já adicionada, deseja continuar?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: layout?.color.default,
        confirmButtonText: 'Continuar',
        cancelButtonText: 'Cancelar',
      }).then((result) => {
        if (result.isConfirmed) {
          const arr = mattresses?.map((item) => {
            return item.size === size &&
              item.format === format &&
              item.height === height &&
              item.width === width &&
              quantity !== undefined &&
              item.quantity !== undefined
              ? { ...item, quantity: quantity + item.quantity }
              : item;
          });
          setMattresses(arr);
        }
      });
    } else {
      setMattresses((oldArray) => [
        ...(oldArray ?? []),
        {
          format: format,
          quantity: quantity,
          height: height,
          width: width,
          size: size,
          volume: volumeCalc(format)(size || 0, height || 0, width || 0) * (quantity || 0),
        },
      ]);
    }
    cleanFields();
  };

  const changeFormat = (value: Format) => {
    cleanFields();
    setFormat(value);
  };

  const SendToShoppingCart = () => {
    handleModalVisibility();
    dispatch(
      addItemRequest({
        codProd: product?.CODPROD,
        unity: product?.UNIDADE[0],
        quantity: totalQuantity,
        mattresses: mattresses,
        description: product?.DESCRPROD,
        value: getValue(),
        isPromo: product?.PROMOCAO?.isPromo,
        controlType: product?.DETAILS?.stockControlType
      })
    );
  };

  const onChangeQuantity = (value: any) => setQuantity(value < 0 ? 0 : value);
  const onChangeHeight = (value: any) => setHeight(value < 0 ? 0 : value);
  const onChangeWidth = (value: any) => setWidth(value < 0 ? 0 : value);
  const onChangeSize = (value: any) => setSize(value < 0 ? 0 : value);

  const isRetangular = () => format === 'retangular';
  const getValue = useCallback((): number => {
    return (
      product.VALOR.find(({ key }: { key: number }) => key === product?.SELECTED_UNITY).value ?? 0
    );
  }, [product]);

  useEffect(() => {
    calculate();
  }, [calculate]);

  useEffect(() => {
    setMattresses(shoppingCart.find((item) => item.codProd === product.CODPROD)?.mattresses ?? []);
  }, [visible, shoppingCart, product.CODPROD]);

  const columns = [
    {
      title: 'Formato',
      dataIndex: 'format',
      key: 'format',
    },
    {
      title: 'Qtd',
      dataIndex: 'quantity',
      key: 'quantity',
    },

    {
      title: 'Comprimento',
      dataIndex: 'size',
      key: 'size',
      hasTitle: true,
      render: (item: number) => (
        <>
          {item} {item === 1 ? 'metro' : 'metros'}
        </>
      ),
    },

    {
      title: 'Altura',
      dataIndex: 'height',
      key: 'height',
      hasTitle: true,
      render: (item: number) => (
        <>
          {item} {item === 1 ? 'metro' : 'metros'}
        </>
      ),
    },
    {
      title: 'Largura',
      dataIndex: 'width',
      key: 'width',
      hasTitle: true,
      render: (item: number) => {
        return item ? (
          <>
            {item} {item === 1 ? 'metro' : 'metros'}
          </>
        ) : (
          <></>
        );
      },
    },
    {
      title: 'Ação',
      key: 'action',
      render: (record: any) => (
        <Tooltip placement="right" title="Remover item">
          <ClearButton
            data-cy="btn-clear"
            type="text"
            shape="circle"
            danger
            icon={<DeleteFilled />}
            onClick={() => deleteMattress(record)}
          />
        </Tooltip>
      ),
    },
  ];

  const colProps = {
    xs: 24,
    sm: 24,
    md: isRetangular() ? 6 : 8,
    lg: isRetangular() ? 6 : 8,
    xl: isRetangular() ? 6 : 8,
  };
  return (
    <>
      <Button
        data-cy="btn-calculate"
        type="link"
        onClick={handleModalVisibility}
        style={{ padding: 0 }}
      >
        ADICIONAR
      </Button>
      <Modal
        data-cy="modalMetreage"
        destroyOnClose
        centered
        visible={visible}
        title={`Produto: ${product?.DESCRPROD}`}
        onCancel={handleModalVisibility}
        footer={[
          <DefaultButton
            data-cy="btn-cancel"
            layout={layout}
            key="cancel"
            onClick={handleModalVisibility}
          >
            Cancelar
          </DefaultButton>,
          <SubmitButton
            data-cy="btn-addToCart"
            layout={layout}
            key="submit"
            type="primary"
            disabled={totalQuantity <= 0}
            onClick={SendToShoppingCart}
          >
            Adicionar ao carrinho
          </SubmitButton>,
        ]}
      >
        <Container layout={layout}>
          <Row gutter={[8, 8]}>
            <Col span={24}>
              <Select
                style={{ width: '100%' }}
                data-cy={`input-format`}
                placeholder={'Selecione um formato'}
                value={format}
                onChange={(value) => changeFormat(value)}
              >
                <Option key={'retangular'} value={'retangular'}>
                  {'Retangular'}
                </Option>
                <Option key={'redondo'} value={'redondo'}>
                  {'Redondo'}
                </Option>
              </Select>
            </Col>
            <Col {...colProps}>
              <InputNumber
                data-cy="input-quantity"
                min={0}
                style={{ width: '100%' }}
                type="number"
                placeholder="Quantidade"
                value={quantity}
                onChange={onChangeQuantity}
              />
            </Col>
            {isRetangular() && (
              <Col {...colProps}>
                <InputNumber
                  data-cy="input-width"
                  min={0}
                  style={{ width: '100%' }}
                  type="number"
                  placeholder="Largura"
                  value={width}
                  onChange={onChangeWidth}
                />
              </Col>
            )}
            <Col {...colProps}>
              <InputNumber
                data-cy="input-lenght"
                min={0}
                style={{ width: '100%' }}
                type="number"
                placeholder="Comprimento"
                value={size}
                onChange={onChangeSize}
              />
            </Col>

            <Col {...colProps}>
              <InputNumber
                data-cy="input-height"
                min={0}
                style={{ width: '100%' }}
                type="number"
                placeholder="Altura"
                value={height}
                onChange={onChangeHeight}
              />
            </Col>
            <Col span={24}>
              <Button
                data-cy="btn-add"
                style={{ width: '100%' }}
                disabled={
                  !quantity || !size || !height || !format || (!width && format === 'retangular')
                }
                type="default"
                onClick={addMattress}
                block
              >
                Adicionar
              </Button>
            </Col>
          </Row>
          <div style={{ height: '200px' }}>
            <Scrollbars>
              <CustomTable
                loading={false}
                data={mattresses}
                columns={columns}
                onChange={() => { }}
              />
            </Scrollbars>
          </div>
          <Footer>
            <FooterCol data-cy="totalQtd">
              <FooterTitle>QTD TOTAL</FooterTitle>
              <strong>{totalQuantity}</strong>
            </FooterCol>
            <FooterCol data-cy="totalLenght">
              <FooterTitle>VOLUME TOTAL</FooterTitle>
              <strong>
                {totalVolume.toFixed(2)} {totalVolume === 1 ? 'metro' : 'metros'}
              </strong>
            </FooterCol>
            <FooterCol data-cy="unityValue">
              <FooterTitle>VALOR M³</FooterTitle>
              <strong>{maskCurrency(getValue())}</strong>
            </FooterCol>
            <FooterCol data-cy="totalValue">
              <FooterTitle>VALOR TOTAL</FooterTitle>
              <strong>{maskCurrency(totalVolume * getValue())}</strong>
            </FooterCol>
          </Footer>
        </Container>
      </Modal>
    </>
  );
};

export default MattressModal;
