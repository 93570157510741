import { ResponseError } from '../../../services/api';

export enum ContactsTypes {
  'LOAD_REQUEST' = '@contacts/LOAD_REQUEST',
  'LOAD_SUCCESS' = '@contacts/LOAD_SUCCESS',
  'LOAD_FAILURE' = '@contacts/LOAD_FAILURE',
}
export interface Pagination {
  current: number;
  total: number;
  pageSize: number;
}

export interface Contact {
  accessControl: boolean;
  active: boolean;
  bill: boolean;
  campaigns: boolean;
  contactName: string;
  editOrders: boolean;
  faq: boolean;
  financial: boolean;
  userId: number;
  idContact: number;
  idPartner: number;
  myData: boolean;
  newOrder: boolean;
  orderRelease: boolean;
  orders: boolean;
  partnerName: string;
  reOrder: boolean;
  reports: boolean;
  serviceOrder: boolean;
  technicalFile: boolean;
}
export interface ContactsState {
  readonly data: Contact[];
  readonly pagination?: Pagination;
  readonly loading: boolean;
  readonly error?: ResponseError;
}
