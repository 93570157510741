import { action } from 'typesafe-actions';
import { ResponseError } from '../../../services/api';
import { Config, Data, DataValues } from '../../../utils/newTableHelper';
import { Pagination, ServiceOrdersDetailTypes } from './types';

export const loadServiceOrdersDetailRequest = (
  pagination?: Pagination,
  filter?: any,
  sorter?: any,
  numOs?: number
) => action(ServiceOrdersDetailTypes.LOAD_REQUEST, { pagination, filter, sorter, numOs });
export const loadSuccess = (pagination: Pagination, data: Data[], config: Config) =>
  action(ServiceOrdersDetailTypes.LOAD_SUCCESS, { pagination, data, config });
export const loadFailure = (error: ResponseError) =>
  action(ServiceOrdersDetailTypes.LOAD_FAILURE, { error });

export const loadDetail = (numOs?: number) =>
  action(ServiceOrdersDetailTypes.DATA_REQUEST, { numOs });
export const successDetail = (data: DataValues[], config: Config) =>
  action(ServiceOrdersDetailTypes.DATA_SUCCESS, { data, config });
export const failureDetail = (error: ResponseError) =>
  action(ServiceOrdersDetailTypes.DATA_FAILURE, { error });
