import { Button, Col, Form, Row, Spin } from 'antd';
import React, { Dispatch, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Action } from 'typesafe-actions';
import { CheckOutlined, LoadingOutlined } from '@ant-design/icons';
import moment from 'moment';
import Swal from 'sweetalert2';
import { useForm } from 'antd/lib/form/Form';
import DynamicFields, { NewTypes } from '../../components/DynamicFields';
import ServerError from '../../components/ServerError';
import { ApplicationState } from '../../store';
import { cleanEditState, EditRequest, sendEditRequest } from '../../store/ducks/screenEdit/actions';
import { colors } from '../../utils/constants';
import { Fields, SendObject } from '../../store/ducks/screenEdit/types';
import { baseURL } from '../../services/api';
import { unmask } from '../../utils/masks';

const ScreenEdit: React.FC = () => {
  const { screenId, id }: { screenId: string; id: string } = useParams();
  const layout = useSelector((state: ApplicationState) => state.layout.data);
  const groups = useSelector((state: ApplicationState) => state.screenEdit.data);
  const success = useSelector((state: ApplicationState) => state.screenEdit.success);
  const sendError = useSelector((state: ApplicationState) => state.screenEdit.sendError);
  const error = useSelector((state: ApplicationState) => state.screenEdit.error);
  const loading = useSelector((state: ApplicationState) => state.screenEdit.loading);
  const sendLoading = useSelector((state: ApplicationState) => state.screenEdit.sendLoading);
  const menus = useSelector((state: ApplicationState) => state.additionalScreens.screens);
  const dispatch = useDispatch<Dispatch<Action>>();
  const history = useHistory();
  const [form] = useForm();

  useEffect(() => {
    dispatch(EditRequest(id, screenId));
  }, [dispatch, id, screenId]);

  useEffect(() => {
    form.resetFields();
  }, [form, groups]);

  const getUrl = (type: NewTypes, id: string, idField: number) => {
    switch (type) {
      case 'OPTIONS':
        return `/screen/${id}/form/fields/${idField}/options`;
      case 'BLOB':
      case 'BLOB_IMAGE':
        return `${baseURL}/screen/${id}/form/fields/${idField}/upload`;

      default:
        return undefined;
    }
  };

  const sorter = (fields: Fields[]) => {
    return fields.sort((a, b) => {
      if (a.position < b.position) {
        return -1;
      }
      if (a.position > b.position) {
        return 1;
      }
      return 0;
    });
  };

  const onFinish = (formValues: any) => {
    const values: SendObject[] = [];
    const fields: Fields[] = [];
    groups.forEach((group) => fields.push(...group.fields));

    Object.keys(formValues).forEach((item) => {
      const field = fields.find((field) => field.fieldName === item);
      let value = formValues[item];
      if (value?.file) {
        value = value.file.response.attachment;
      }
      if (value instanceof moment) {
        value = moment(value).format('DD/MM/YYYY HH:mm:ss');
      }
      if (field?.mask !== 'currency' && field?.mask) {
        value = unmask(value);
      }
      if (!value) return;
      values.push({
        id: field?.idField,
        name: item,
        value: value,
      });
    });
    dispatch(sendEditRequest(id, screenId, values));
  };

  useEffect(() => {
    if (!success) return;

    Swal.fire({
      title: 'Sucesso!',
      confirmButtonColor: layout?.color.default,
      text: `${success.message}`,
      icon: 'success',
      confirmButtonText: 'Ok',
    }).then(() => {
      history.goBack();
      dispatch(cleanEditState());
    });
  }, [dispatch, history, layout?.color.default, success]);

  useEffect(() => {
    if (!sendError) return;

    Swal.fire({
      title: 'Erro!',
      confirmButtonColor: layout?.color.default,
      text: `${sendError.message}`,
      icon: 'error',
      confirmButtonText: 'Ok',
    }).then(() => {
      dispatch(cleanEditState());
    });
  }, [dispatch, history, layout?.color.default, sendError]);

  useEffect(() => {
    if (!menus?.some((item) => item.id.toString() === screenId))
      history.push('/')
  }, [menus, screenId, history]);

  return (
    <Spin
      style={{ color: layout?.color.default }}
      spinning={loading}
      size="large"
      delay={500}
      indicator={<LoadingOutlined />}
    >
      {error ? (
        <ServerError {...error} />
      ) : (
        <Form onFinish={onFinish} form={form} preserve={false} layout="vertical">
          <Col
            style={{
              backgroundColor: 'white',
              padding: '20px',
              marginBottom: '20px',
              borderRadius: '5px',
              boxShadow: `1px 2px 2px 2px ${colors.grey}`,
            }}
            span={24}
          >
            <Row justify="end">
              <Col xs={24} sm={24} md={3} lg={3} xl={3}>
                <Form.Item style={{ margin: 0 }}>
                  <Button
                    loading={sendLoading}
                    block
                    icon={<CheckOutlined />}
                    htmlType="submit"
                    type="primary"
                  >
                    Salvar
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Col>
          {groups.map((group) => {
            return (
              <Col
                style={{
                  backgroundColor: 'white',
                  padding: '20px',
                  marginBottom: '20px',
                  borderRadius: '5px',
                  boxShadow: `1px 2px 2px 2px ${colors.grey}`,
                }}
                span={24}
              >
                <h2 style={{ color: layout?.color.dark }}>{group.title}</h2>
                <Row gutter={[20, 20]} justify="start">
                  {sorter(group.fields).filter(field => field.visible).map((field) => {
                    return (
                      <Col
                        xs={24}
                        sm={24}
                        md={field.columnSize * 6}
                        lg={field.columnSize * 6}
                        xl={field.columnSize * 6}
                      >
                        <DynamicFields
                          type={field.mask || field.fieldType}
                          label={field.description}
                          required={field.required}
                          disabled={!field.enable || !field.allowEdit}
                          name={field.fieldName}
                          key={field.idField.toString()}
                          value={field.value}
                          urlImage={
                            field.fieldType === 'BLOB_IMAGE'
                              ? `/screen/${screenId}/registry/${id}/field/${field.idField}/file`
                              : undefined
                          }
                          urlUnique={getUrl(field.fieldType, screenId, field.idField)}
                        />
                      </Col>
                    );
                  })}
                </Row>
              </Col>
            );
          })}
        </Form>
      )}
    </Spin>
  );
};

export default ScreenEdit;
