import { Button, List } from 'antd';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';

import PdfDisplay from '../../../components/PdfDisplay';
import api from '../../../services/api';
import { ApplicationState } from '../../../store';
import { requestHeaders } from '../../../utils/apiHelper';
import ReportForm from '../ReportForm';
interface Props {
  item: any;
}

const ReportItem: React.FC<Props> = ({ item }) => {
  const layout = useSelector((state: ApplicationState) => state.layout.data);
  const token = useSelector((state: ApplicationState) => state.auth.token);
  const [visibleForm, setVisibleForm] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [clicked, setClicked] = useState<boolean>(false);
  const [dataFields, setDataFields] = useState<any[]>([]);

  const onclick = (report: any) => {
    setLoading(true);
    const headers = requestHeaders(token);
    api
      .get(`relatorios/${report.key}/campos`, { headers: headers })
      .then((res) => {
        setLoading(false);
        setDataFields(res.data);
        res.data.length === 0 ? setClicked(true) : setVisibleForm(true);
      })
      .catch((err) => {
        setLoading(false);
        Swal.fire({
          title: 'Erro!',
          confirmButtonColor: layout?.color.default,
          html: err.response.data.titulo,
          icon: 'error',
        });
      });
  };

  return (
    <>
      <List.Item
        style={{ marginBottom: '10px' }}
        key={item.key}
        actions={[
          <div onClick={(e) => e.stopPropagation()}>
            <Button
              data-cy="btn-downloadReport"
              loading={loading}
              onClick={() => onclick(item)}
              type="default"
            >
              Download
            </Button>
          </div>,
        ]}
      >
        <List.Item.Meta title={item.value} />
      </List.Item>
      {dataFields.length === 0 ? (
        <PdfDisplay
          title="Relatório"
          visible={clicked}
          setVisible={setClicked}
          url={`relatorios/${item.key}/gerar-relatorio`}
          post
          body={{ campos: dataFields, codCfg: 1, nuRef: item.key, origem: 'LISTA_RELATORIOS' }}
        />
      ) : (
        <ReportForm
          visible={visibleForm}
          setVisible={setVisibleForm}
          dataFields={dataFields}
          keyField={item.key}
          namePdf={item.value}
        />
      )}
    </>
  );
};

export default ReportItem;
