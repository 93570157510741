import { Button } from 'antd';
import { ColumnsType } from 'antd/es/table';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import CustomTable from '../../../components/CustomTable';
import ServerError from '../../../components/ServerError';
import { ApplicationState } from '../../../store';
import {
  configToColumnsType,
  filterParamsHandle,
  sorterParamsHandle,
} from '../../../utils/tableHelper';

interface Props {
  setFilters: any;
  setSorter: any;
  setPagination: any;
}

const DatasheetTable: React.FC<Props> = ({ setFilters, setSorter, setPagination }) => {
  const config = useSelector((state: ApplicationState) => state.dataSheet.config, shallowEqual);
  const data = useSelector((state: ApplicationState) => state.dataSheet.data, shallowEqual);
  const error = useSelector((state: ApplicationState) => state.dataSheet.error);
  const loading = useSelector((state: ApplicationState) => state.dataSheet.loading);
  const pagination = useSelector((state: ApplicationState) => state.dataSheet.pagination);
  const [columns, setColumns] = useState<ColumnsType<any>>([]);

  useEffect(() => {
    const renderLinkColumn = (_text: any, record: any) => {
      const DataSheetClick = (e: any) => {
        e.preventDefault();
        const win = window.open(record.LINKFICHATEC, '_blank');
        win && win.focus();
      };
      return (
        <Button
          data-cy="btn-datasheetLink"
          disabled={!record.LINKFICHATEC}
          style={{ padding: 0 }}
          type="link"
          onClick={DataSheetClick}
        >
          {record.LINKFICHATEC ? 'Abrir ficha técnica' : 'Esse item não possui ficha técnica'}
        </Button>
      );
    };
    setColumns(
      configToColumnsType(
        config.filter((item: any) => item.exibir),
        [{ nuCampo: -1, render: renderLinkColumn }],
        undefined,
        [0, -1]
      )
    );
  }, [config]);

  return (
    <>
      {error ? (
        <ServerError {...error} />
      ) : (
        <CustomTable
          style={{ padding: '20px' }}
          onChange={(pg: any, filters: any, sorter: any) => {
            setFilters(filterParamsHandle(filters));
            setSorter(sorterParamsHandle(sorter));
            setPagination(pg);
          }}
          pagination={pagination}
          loading={loading}
          columns={columns}
          data={data}
        />
      )}
    </>
  );
};

export default DatasheetTable;
