import { action } from 'typesafe-actions';
import { ResponseError } from '../../../services/api';
import { Config, Data } from '../../../utils/newTableHelper';
import { Pagination, BudgetsTypes } from './types';

export const loadBudgetsRequest = (
  pagination?: Pagination,
  filter?: any,
  sorter?: any,
  searchValue?: string
) => action(BudgetsTypes.LOAD_REQUEST, { pagination, filter, sorter, searchValue });
export const loadSuccess = (pagination: Pagination, data: Data[], config: Config) =>
  action(BudgetsTypes.LOAD_SUCCESS, {
    pagination,
    data,
    config,
  });
export const loadFailure = (error: ResponseError) => action(BudgetsTypes.LOAD_FAILURE, { error });
