import styled from 'styled-components';
import { colors } from '../../utils/constants';
import { Layout } from '../../store/ducks/layout/types';

export const PageContent = styled.div`
  margin: 20px auto;
  max-width: 1366px;
  padding: 20px;
  border-radius: 5px;
  background-color: white;
  box-shadow: 1px 2px 2px 2px ${colors.grey};
  @media only screen and (max-width: 1366px) {
    padding: 10px;
    margin: 10px;
  }
`;

export const BannersContainer = styled.div`
  max-height: 280px;
  min-height: 280px;
`

export const ProductsContainer = styled.div<{ layout?: Layout, noBorder: boolean }>`
  border-top: ${({ layout, noBorder }) => noBorder || !layout ? 'none' : `10px solid ${layout.color.default}`} ;
  padding-top: 20px;
`;
