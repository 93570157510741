import { action } from 'typesafe-actions';
import { ResponseError } from '../../../services/api';
import { Contact, NewContactTypes, Role } from './types';

export const contactRequest = (contactId: number | string, userId: number | string) =>
  action(NewContactTypes.CONTACT_REQUEST, { contactId, userId });
export const contactSuccess = (data: Contact) => action(NewContactTypes.CONTACT_SUCCESS, { data });
export const contactFailure = (error: ResponseError) =>
  action(NewContactTypes.CONTACT_FAILURE, { error });

export const rolesRequest = () => action(NewContactTypes.ROLES_REQUEST);
export const rolesSuccess = (roles: Role[]) => action(NewContactTypes.ROLES_SUCCESS, { roles });
export const rolesFailure = (error: ResponseError) =>
  action(NewContactTypes.ROLES_FAILURE, { error });

export const sendContactRequest = (body: any, userId: string, id: string) =>
  action(NewContactTypes.SEND_REQUEST, { body, userId, id });
export const sendContactSuccess = (data: Contact) => action(NewContactTypes.SEND_SUCCESS, { data });
export const sendContactFailure = (error: ResponseError) =>
  action(NewContactTypes.SEND_FAILURE, { error });

export const clearSend = () => action(NewContactTypes.CLEAR_SEND);

export const setError = (error?: ResponseError) => action(NewContactTypes.SET_ERROR, { error });
