import { AxiosResponse } from 'axios';
import { call, put, select } from 'redux-saga/effects';
import { ApplicationState } from '../..';
import api, { errorHandler } from '../../../services/api';
import { requestHeaders } from '../../../utils/apiHelper';
import { refreshRequest } from '../auth/actions';
import { contactsFailure, contactsRequest, contactsSuccess } from './actions';
import { Contact } from './types';

export function* loadContacts({ payload }: ReturnType<typeof contactsRequest>) {
  try {
    const state: ApplicationState = yield select();
    const headers = requestHeaders(state.auth.token);
    const response: AxiosResponse<{
      content: Contact[];
      numberOfElements: number;
      size: number;
      number: number;
    }> = yield call(
      api.post,
      `/access-control/${payload.userId}/contacts/all`,
      {
        page: (payload.pagination?.current || 1) - 1,
        size: payload.pagination?.pageSize || 12,
      },
      { headers: headers }
    );
    yield put(
      contactsSuccess(
        {
          total: response.data.numberOfElements,
          pageSize: response.data.size,
          current: response.data.number + 1,
        },
        response.data.content.map((item: Contact) => {
          return {
            ...item,
            userId: payload.userId,
          };
        })
      )
    );
  } catch (err: any) {
    yield put(err.response.status !== 401 ? contactsFailure(errorHandler(err)) : refreshRequest());
  }
}
