import { Modal, Skeleton } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { useWindowSize } from '../../customHooks';
import api from '../../services/api';
import { ApplicationState } from '../../store';
import { requestHeaders } from '../../utils/apiHelper';
import { downloadFile } from '../../utils/fileHelper';
import { PdfContent } from './styles';

interface Props {
  title: string;
  url: string;
  visible: boolean;
  setVisible: any;
  post?: boolean;
  body?: any;
}

const PdfDisplay: React.FC<Props> = ({ title, url, visible, setVisible, post, body }) => {
  const token = useSelector((state: ApplicationState) => state.auth.token);
  const layout = useSelector((state: ApplicationState) => state.layout.data);
  const [pdf, setPdf] = useState<string>('');
  const size = useWindowSize();

  useEffect(() => {
    if (visible) return;
    setPdf('');
  }, [visible]);

  useEffect(() => {
    if (!pdf) {
      return;
    }
    if ((size.width ?? 0) <= 1024) {
      downloadFile(pdf, title, 'pdf', '75vh');
      setVisible(false);
    }
  }, [pdf, setVisible, size, title]);

  useEffect(() => {
    if (!visible || pdf) {
      return;
    }
    const success = (res: any) => {
      if (res.status === 204) {
        Swal.fire({
          title: 'Aviso!',
          confirmButtonColor: layout?.color.default,
          html: 'Nenhuma página a apresentar.',
          icon: 'warning',
        }).then(() => setVisible(false));
      } else {
        setPdf(res.data);
      }
    };
    const headers = requestHeaders(token);
    if (post) {
      api
        .post(url, body, { headers: headers })
        .then((res) => {
          success(res);
        })
        .catch((err) => {
          Swal.fire({
            title: 'Erro!',
            confirmButtonColor: layout?.color.default,
            html: err.response.data.titulo,
            icon: 'error',
          }).then(() => setVisible(false));
        });
    } else {
      api
        .get(url, { headers: headers })
        .then((res) => {
          success(res);
        })
        .catch((err) => {
          Swal.fire({ title: 'Erro!', html: err.response.data.titulo, icon: 'error' });
        });
    }
  }, [token, url, visible, pdf, post, body, layout?.color.default, setVisible, size.width, title]);
  return (
    <>
      {(size.width ?? 0) > 1024 ? (
        <Modal
          data-cy={`modal-${title}`}
          width={1000}
          title={title}
          visible={visible}
          onCancel={() => setVisible(false)}
          cancelText="Fechar"
          footer={null}
          destroyOnClose
          centered
        >
          {pdf ? (
            <PdfContent title="pdf" src={`data:application/pdf;base64,${pdf}`} />
          ) : (
            <>
              <Skeleton active />
              <Skeleton active />
              <Skeleton active />
              <Skeleton active />
            </>
          )}
        </Modal>
      ) : (
        <></>
      )}
    </>
  );
};

export default PdfDisplay;
