import Color from 'color';
import { call, delay, put } from 'redux-saga/effects';
import api, { errorHandler } from '../../../services/api';
import { refreshRequest } from '../auth/actions';
import favicon from '../../../assets/img/favicon.png';

import { loadSuccess, loadFailure, resetScroll } from './actions';
import { Colors } from './types';
import { AxiosResponse } from 'axios';

const setColors = (colorName?: string): Colors => {
  const color = Color(`#${colorName ? colorName : '0BD079'}`);
  return {
    default: color.string(),
    light: color.fade(0.2).string(),
    lighter: color.fade(0.9).string(),
    dark: color.darken(0.5).string(),
  };
};

const getImageByType = (type: 'BASE64' | 'URL', image: string): string => {
  switch (type) {
    case 'BASE64':
      return `data:image/png;base64,${image}`;
    case 'URL':
      return image;
    default:
      return favicon;
  }
};
export function* loadLayout() {
  try {
    const response: AxiosResponse<any> = yield call(api.get, 'layout');
    const color: Colors = yield call(setColors, response.data.color);
    document.title = response.data.description || '';
    let link: any = document.querySelector("link[rel*='icon']") || document.createElement('link');
    link.type = 'image/x-icon';
    link.rel = 'shortcut icon';
    link.href = response.data.favicon
      ? getImageByType(response.data.favicon.type, response.data.favicon.image)
      : favicon;

    document.head.appendChild(link);
    yield put(
      loadSuccess({
        ...response.data,
        color: color,
      })
    );
  } catch (err: any) {
    yield put(err.response.status !== 401 ? loadFailure(errorHandler(err)) : refreshRequest());
  }
}

export function* resetLayoutScroll() {
  yield delay(100);
  yield put(resetScroll());
}
