import { action } from 'typesafe-actions';
import { Config } from '../../../utils/newTableHelper';
import {
  Address,
  CalculateResult,
  KeyValue,
  Payment,
  ShoppingCartItem,
  ShoppingCartTypes,
  Success,
} from './types';

export const getCurrentCartRequest = (synced = false) =>
  action(ShoppingCartTypes.GET_CURRENT_CART_REQUEST, { synced });

export const getCurrentCartSuccess = (items: ShoppingCartItem[], subTotal: number, totalQuantity: number, cartId: number, versionCart: number) =>
  action(ShoppingCartTypes.GET_CURRENT_CART_SUCCESS, { items, subTotal, totalQuantity, cartId, versionCart });

export const getCurrentCartFailure = () =>
  action(ShoppingCartTypes.GET_CURRENT_CART_FAILURE);

export const cartInitialized = () => action(ShoppingCartTypes.CART_INITIALIZED);

export const addItemRequest = (item: ShoppingCartItem, isEdit?: boolean) =>
  action(ShoppingCartTypes.ADD_ITEM_REQUEST, { item, isEdit });

export const addItemsRequest = (items: ShoppingCartItem[]) => action(ShoppingCartTypes.ADD_ITEMS_REQUEST, { items });

export const addItemSuccess = (data: ShoppingCartItem[], subTotal: number, totalQuantity: number, cartId?: number, versionCart?: number) =>
  action(ShoppingCartTypes.ADD_ITEM_SUCCESS, { data, subTotal, totalQuantity, cartId, versionCart });

export const addItemFailure = (errorMessage: string) =>
  action(ShoppingCartTypes.ADD_ITEM_FAILURE, { errorMessage });

export const deleteItemRequest = (item: ShoppingCartItem) =>
  action(ShoppingCartTypes.DELETE_ITEM_REQUEST, { item });

export const deleteItemSuccess = (
  data: ShoppingCartItem[],
  subTotal: number,
  totalQuantity: number,
  cartId: number,
  versionCart: number
) => action(ShoppingCartTypes.DELETE_ITEM_SUCCESS, {
  data, subTotal, totalQuantity, cartId, versionCart
});

export const deleteItemFailure = () => action(ShoppingCartTypes.DELETE_ITEM_FAILURE);

export const paymentsRequest = () => action(ShoppingCartTypes.PAYMENT_REQUEST);

export const paymentSuccess = (data: Payment[]) =>
  action(ShoppingCartTypes.PAYMENT_SUCCESS, { data });

export const paymentFailure = () => action(ShoppingCartTypes.PAYMENT_FAILURE);

export const addressesRequest = () => action(ShoppingCartTypes.ADDRESS_REQUEST);

export const addressSuccess = (data: Address[], selectedAddress?: Address) =>
  action(ShoppingCartTypes.ADDRESS_SUCCESS, { data, selectedAddress });

export const selectAddress = (selectedAddress?: Address) =>
  action(ShoppingCartTypes.ADDRESS_SELECT, { selectedAddress });

export const addressFailure = () => action(ShoppingCartTypes.ADDRESS_FAILURE);

export const requestConfigs = () => action(ShoppingCartTypes.CONFIGS_REQUEST);

export const configsSuccess = (config: Config) =>
  action(ShoppingCartTypes.CONFIGS_SUCCESS, { config });

export const configsFailure = () => action(ShoppingCartTypes.CONFIGS_FAILURE);

export const totalRequest = () => action(ShoppingCartTypes.TOTAL_REQUEST);

export const totalSuccess = (result: CalculateResult) =>
  action(ShoppingCartTypes.TOTAL_SUCCESS, { result });

export const totalFailure = (errorMessage: string) =>
  action(ShoppingCartTypes.TOTAL_FAILURE, { errorMessage });

export const saveOrderAction = (tokenCard?: string) => action(ShoppingCartTypes.SAVE_REQUEST, { tokenCard });

export const saveSuccess = (success: Success) =>
  action(ShoppingCartTypes.SAVE_SUCCESS, { success });

export const saveFailure = (error: string) => action(ShoppingCartTypes.SAVE_FAILURE, { error });

export const reDoOrderRequest = (nuNota: number) =>
  action(ShoppingCartTypes.REDO_REQUEST, { nuNota });

export const reDoOrderSuccess = () => action(ShoppingCartTypes.REDO_SUCCESS);

export const reDoOrderFailure = (error: string) =>
  action(ShoppingCartTypes.REDO_FAILURE, { error });

export const editOrderRequest = (nuNota: number) =>
  action(ShoppingCartTypes.EDIT_REQUEST, { nuNota });

export const editOrderSuccess = (nuNota: number) =>
  action(ShoppingCartTypes.EDIT_SUCCESS, { nuNota });

export const editOrderFailure = (error: string) =>
  action(ShoppingCartTypes.EDIT_FAILURE, { error });

export const clearCart = () => action(ShoppingCartTypes.CLEAR_CART);
export const clearCartFinish = () => action(ShoppingCartTypes.CLEAR_CART_FINISH);

export const openCart = () => action(ShoppingCartTypes.OPEN_CART);
export const closeCart = () => action(ShoppingCartTypes.CLOSE_CART);

export const setBudgetId = (budgetId: number) => action(ShoppingCartTypes.SET_BUDGET, { budgetId });

export const setBudgetVisible = (isBudget: boolean) =>
  action(ShoppingCartTypes.SET_BUDGET_VISIBLE, { isBudget });

export const setFields = (fields: KeyValue[]) => action(ShoppingCartTypes.SET_FIELDS, { fields });

export const setError = (error: boolean) => action(ShoppingCartTypes.SET_ERROR, { error });
