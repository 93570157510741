import { List } from 'antd';
import React, { Dispatch } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Action } from 'redux';
import { LoadingOutlined } from '@ant-design/icons';
import ProductCard from '../../../components/ProductCard';
import { ApplicationState } from '../../../store';
import { loadRequest } from '../../../store/ducks/products/actions';
import { scroll } from '../../../store/ducks/layout/actions';

import { Container } from "./styles";

const ProductCardList: React.FC = () => {
  const layout = useSelector((state: ApplicationState) => state.layout.data);
  const data = useSelector((state: ApplicationState) => state.products.data);
  const pagination = useSelector((state: ApplicationState) => state.products.pagination);
  const loading = useSelector((state: ApplicationState) => state.products.loading);
  const dispatch = useDispatch<Dispatch<Action>>();
  const advancedFilters = useSelector((state: ApplicationState) => state.products.advancedFilters);
  const filter = useSelector((state: ApplicationState) => state.products.filter);

  return (
    <Container>
      <List
        style={{ margin: '10px' }}
        grid={{ gutter: 10, xs: 1, sm: 2, md: 3, lg: 3, xl: 4, xxl: 4 }}
        loading={{
          style: { color: layout?.color.default },
          spinning: loading,
          size: 'large',
          delay: 500,
          indicator: <LoadingOutlined style={{ fontSize: 24 }} spin />,
        }}
        dataSource={data}
        pagination={{
          size: 'small',
          ...pagination,
          onChange: (page, pageSize) => {
            dispatch(loadRequest(filter, undefined, {
              current: page,
              pageSize: pageSize || 0,
              total: pagination?.total || 0,
            }, undefined, advancedFilters));
            dispatch(scroll());
          },
        }}
        renderItem={(item: any) => {
          return (
            <List.Item>
              <ProductCard product={item} loading={loading} />
            </List.Item>
          );
        }}
      />
    </Container>
  );
};

export default ProductCardList;
