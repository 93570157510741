import { ColumnsType, ColumnType } from 'antd/lib/table';
import TableFilterDate from '../components/CustomTable/TableFilterDate';
import TableFilterDefault from '../components/CustomTable/TableFilterDefault';
import TableFilterList from '../components/CustomTable/TableFilterList';
import TableFilterRangeCurrency from '../components/CustomTable/TableFilterRangeCurrency';
import mask from './masks';

export const configToColumnsType = (
  config: any,
  customRender?: any[],
  customColumns?: ColumnsType<any>,
  customSort?: number[],
  noFilters?: boolean,
  noSorter?: boolean
): ColumnsType<any> => {
  let ordered: any[] = [];
  if (customSort) {
    const dynamicFields = config.filter((field: any) => field.ordem);
    dynamicFields.sort((a: any, b: any) => {
      return a.ordem > b.ordem ? 1 : b.ordem > a.ordem ? -1 : 0;
    });

    customSort.forEach((nuCampo) => {
      if (nuCampo === 0) {
        ordered.push(...dynamicFields);
        return;
      }
      const index = config.findIndex((field: any) => field.nuCampo === nuCampo);
      if (index === -1) return;
      ordered.push(config[index]);
    });
  } else {
    ordered = config.sort((a: any, b: any) => {
      if (a.ordem < b.ordem) {
        return -1;
      }
      if (a.ordem > b.ordem) {
        return 1;
      }
      return 0;
    });
  }
  const columns: ColumnsType<any> = ordered.map((item: any, index: number) => {
    const custom = customRender?.find(({ nuCampo }: any) => nuCampo === item.nuCampo);
    return {
      ellipsis: true,
      className: 'table-column',
      fixed: item.isPK ? 'left' : false,
      key: index,
      dataIndex: item.nomeCampo,
      title: item.descricaoCampo,
      filters: item.filtro && !noFilters ? item.opcoes : undefined,
      sorter: !noSorter ? item.ordenacao : undefined,
      filterDropdown:
        item.filtro && !noFilters
          ? getFilterDropdown(
              item.nomeCampo,
              item.mascara || getColumnType(item.tipoCampo, item.opcoes?.length > 0)
            )
          : undefined,
      render: custom ? custom.render : undefined,
    };
  });
  if (customColumns) {
    customColumns.forEach((column: ColumnType<any>) => {
      columns.push(column);
    });
  }
  return columns;
};

const getColumnType = (type: string, hasOption?: boolean) => {
  if (hasOption) return 'options';
  switch (type) {
    case 'currency':
      return 'currency';

    case 'phone':
      return 'phone';

    case 'cpfCnpj':
      return 'cpfCnpj';

    case 'I':
    case 'F':
      return 'number';

    case 'D':
      return 'date';

    case 'H':
      return 'dateTime';

    case 'S':
    case 'C':
    case 'T':
    default:
      return 'string';
  }
};

function getFilterDropdown(dataIndex: string, type: any): any {
  switch (type) {
    case 'date':
    case 'dateTime':
      return TableFilterDate(dataIndex);

    case 'currency':
      return TableFilterRangeCurrency(dataIndex);

    case 'options':
      return TableFilterList(dataIndex);

    default:
      return TableFilterDefault(dataIndex, type);
  }
}

export const filterParamsHandle = (filters: any): any[] => {
  if (!filters) return [];
  const campos: any[] = [];
  Object.keys(filters).forEach((index: any) => {
    if (!filters[index]) return;
    if (filters[index].slice(1)[0] === null) return;
    campos.push({
      nomeCampo: filters[index][0],
      valor: filters[index].slice(1),
    });
  });
  return campos;
};

export const sorterParamsHandle = (sorter: any) => {
  if (!sorter?.order) return;
  return {
    nomeCampo: sorter.field,
    ordem: sorter.order === 'ascend' ? 'ASC' : 'DESC',
  };
};

export const dataToDisplay = (data: any, config: any) => {
  const dataDisplay: object[] = [];
  data.forEach((dataItem: any) => {
    if (!dataItem) return undefined;
    const row = dataItem.map((item: any) => {
      const dataConfig = config.find((c: any) => c?.nuCampo === item?.nuCampo);
      return {
        key: dataConfig?.nomeCampo,
        value: mask(
          dataConfig?.mascara || getColumnType(dataConfig?.tipoCampo, false),
          item?.valor
        ),
        title: dataConfig?.descricaoCampo,
        display: dataConfig?.exibir && dataConfig?.nuCampo > 0,
        show: dataConfig?.exibir,
      };
    });
    row.forEach((element: any) => {
      dataDisplay.push(element);
    });
  });
  return dataDisplay;
};

export const dataToTableData = (data: any, config: any) => {
  return data.map((dataItem: any, index: number) => {
    if (!dataItem) return undefined;
    const row = dataItem.map((item: any) => {
      const dataConfig = config.find((c: any) => c?.nuCampo === item?.nuCampo);
      return {
        key: dataConfig?.nomeCampo,
        value: mask(
          dataConfig?.mascara || getColumnType(dataConfig?.tipoCampo, false),
          item?.valor
        ),
        show: dataConfig?.exibir,
      };
    });
    const tableData: any = {};
    row.forEach((element: any) => {
      Object.assign(tableData, {
        key: index,
        [element.key]: element.value ? element.value : '',
        [`${element.key}_SHOW`]: element.show,
      });
    });
    return tableData;
  });
};
