import React, { Dispatch, useEffect } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { Button, Col, Drawer, Row, Switch } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Action } from 'typesafe-actions';
import Swal from 'sweetalert2';
import { ApplicationState } from '../../store';
import {
  ShoppingCartIcon,
  TitleContainer,
  ButtonWithOutBorder,
  CartHeader,
  CartFooter,
} from './styles';
import {
  openCart,
  closeCart,
  clearCart,
  setBudgetVisible,
  setFields,
  requestConfigs,
  getCurrentCartRequest,
} from '../../store/ducks/shoppingCart/actions';
import { ShoppingCartOutlined, DeleteFilled } from '@ant-design/icons';
import { Container } from '../../styles';
import { Size, useWindowSize } from '../../customHooks';
import { useCallback } from 'react';
import EmptyCart from '../EmptyCart';
import ItensList from './ItensList';
import { maskCurrency } from '../../utils/masks';
import { useHistory } from 'react-router-dom';

export interface KeyValue {
  key: string;
  value: number | string;
}

const ShoppingCartDrawer: React.FC = () => {
  const history = useHistory();
  const layout = useSelector((state: ApplicationState) => state.layout.data);
  const token = useSelector((state: ApplicationState) => state.auth.token);
  const {
    totalQuantity: quantity,
    cartVisibility: visible,
    config,
    subTotal: subtotal,
    editId,
    budgetVisible: isBudget,
    cleaningCart,
    currentAction,
    synced
  } = useSelector((state: ApplicationState) => state.shoppingCart);
  const dispatch = useDispatch<Dispatch<Action>>();
  const size: Size = useWindowSize();

  const onClose = useCallback(() => {
    dispatch(closeCart());
  }, [dispatch]);

  useEffect(() => {
    dispatch(requestConfigs());
  }, [dispatch, token]);

  useEffect(() => {
    if (config && config.details?.saveCart)
      dispatch(getCurrentCartRequest())
  }, [config, dispatch])


  const showDrawer = () => {
    dispatch(openCart());
  };

  const resumeRedirect = () => {
    onClose();
    history.push('/home/resumo-da-compra');
  };

  const handleClick = () => {
    onClose();
    history.push('/home/novo-pedido');
  };

  const clearCartHandle = () => {
    Swal.fire({
      title: 'Atenção',
      text: 'Tem certeza que deseja limpar o carrinho?',
      icon: 'warning',
      confirmButtonColor: layout?.color.default,
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
    }).then((result) => {
      if (result.isConfirmed) {
        if (!editId) {
          dispatch(clearCart());
          setFields([]);
          dispatch(setBudgetVisible(false));
        } else {
          dispatch(clearCart());
        }
      }
    });
  };

  useEffect(() => {
    if (synced)
      Swal.fire({
        title: 'Atualização do carrinho',
        confirmButtonColor: layout?.color.default,
        html: 'O seu carrinho foi atualizado para uma versão mais recente encontrada',
        icon: 'success',
      });
  }, [synced, layout])


  return (
    <>
      <ButtonWithOutBorder
        data-cy="btn-shoppingCart"
        onClick={showDrawer}
        shape="circle"
        type="text"
        icon={<ShoppingCartIcon layout={layout} />}
        loading={currentAction === 'INIT'}
      />
      <Drawer
        bodyStyle={{ padding: 0 }}
        title={
          <TitleContainer>
            <ShoppingCartIcon layout={layout} />
            {editId ? `Editando pedido ${editId}` : 'Carrinho de compras'}
          </TitleContainer>
        }
        placement="right"
        width={(size?.width ?? 0) >= 550 ? 550 : '100%'}
        onClose={onClose}
        visible={visible}
      >
        {quantity <= 0 && !editId ? (
          <Container layout={layout}>
            <EmptyCart handleButton={handleClick} />
          </Container>
        ) : (
          <Scrollbars>
            <CartHeader layout={layout}>
              <Row
                justify={config?.details?.showBudget && !editId ? 'space-between' : 'end'}
                align="middle"
              >
                {config?.details?.showBudget && !editId && (
                  <Col>
                    <Switch
                      checkedChildren="Orçamento"
                      unCheckedChildren="Orçamento"
                      checked={isBudget}
                      onChange={() => dispatch(setBudgetVisible(!isBudget))}
                    />
                  </Col>
                )}
                <Col>
                  <Button type="link" icon={<DeleteFilled />} onClick={clearCartHandle} danger loading={cleaningCart}>
                    {cleaningCart ? 'Limpando carrinho' : 'Limpar carrinho'}
                  </Button>
                </Col>
              </Row>
            </CartHeader>
            <Container layout={layout}>
              <div style={{ marginBottom: '80px', marginTop: '52px' }}>
                <ItensList />
              </div>
              <CartFooter opened={visible}>
                <Row justify="space-between" align="middle">
                  <Col>
                    <h3 style={{ color: '#5b8c00', margin: 0 }}>
                      Subtotal: <strong>{maskCurrency(subtotal)}</strong>
                    </h3>
                  </Col>
                  <Col>
                    <Button
                      type="primary"
                      icon={<ShoppingCartOutlined />}
                      onClick={resumeRedirect}
                      size="large"
                    >
                      {!isBudget
                        ? !editId
                          ? 'Fechar pedido'
                          : 'Finalizar edição'
                        : 'Fechar orçamento'}
                    </Button>
                  </Col>
                </Row>
              </CartFooter>
            </Container>
          </Scrollbars>
        )}
      </Drawer>
    </>
  );
};

export default ShoppingCartDrawer;
