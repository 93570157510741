import { ResponseError } from '../../../services/api';
import { Config } from '../../../utils/newTableHelper';

export enum ProductsShowCaseTypes {
  'LOAD_REQUEST' = '@showCaseProducts/LOAD_REQUEST',
  'LOAD_SUCCESS' = '@showCaseProducts/LOAD_SUCCESS',
  'LOAD_FAILURE' = '@showCaseProducts/LOAD_FAILURE',

  'LOAD_REQUEST_CATEGORY' = '@showCaseProductsCategory/LOAD_REQUEST',
  'LOAD_SUCCESS_CATEGORY' = '@showCaseProductsCategory/LOAD_SUCCESS',
  'LOAD_FAILURE_CATEGORY' = '@showCaseProductsCategory/LOAD_FAILURE',

  'SET_UNITY' = '@showCaseProducts/SET_UNITY',
  'UNITY_SUCCESS' = '@showCaseProducts/UNITY_SUCCESS',

  'SET_FILTERS' = '@showCaseProducts/SET_FILTERS',

  'SET_SEARCH' = '@showCaseProducts/SET_SEARCH',
}

export interface Category {
  key: number | string;
  value: string;
  list: Category[];
  isHighlight?: boolean;
}

export interface Pagination {
  current: number;
  total: number;
  pageSize: number;
}

export interface ProductsShowCaseState {
  readonly data: any[];
  readonly config?: Config;
  readonly pagination: Pagination;
  readonly categories: Category[];
  readonly searchValue?: string;
  readonly loading: boolean;
  readonly filters: (string | number)[];
  readonly loadingCategories: boolean;
  readonly errorProducts?: ResponseError;
  readonly errorCategory?: ResponseError;
}
