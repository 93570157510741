import { Col, Row, Spin } from 'antd';
import React, { Dispatch, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Action } from 'typesafe-actions';
import { ApplicationState } from '../../store';
import { dashboardConfigRequest, dashboardRequest } from '../../store/ducks/dashboard/actions';
import {
  ChartConfig,
  GridConfig,
  LevelContainer,
  RefreshDetails,
  Serie,
  SimpleValueConfig,
} from '../../store/ducks/dashboard/types';
import { dashboardData } from '../../utils/DahboardHelper';
import Parameters from './Parameters';
import { LoadingOutlined } from '@ant-design/icons';

import { Container } from './DashContainer/styles';
import Charts from './Charts';
import ServerError from '../../components/ServerError';
import DashContainer from './DashContainer';
import { Content } from './styles';
import Scrollbars from 'react-custom-scrollbars';
import Grid from './Grid';
const Dasboard: React.FC = () => {
  const dispatch = useDispatch<Dispatch<Action>>();
  const config = useSelector((state: ApplicationState) => state.dashboard.config);
  const data = useSelector((state: ApplicationState) => state.dashboard.data);
  const elementsToBeUpdate = useSelector(
    (state: ApplicationState) => state.dashboard.elementsToBeUpdate
  );
  const loading = useSelector((state: ApplicationState) => state.dashboard.loading);
  const layout = useSelector((state: ApplicationState) => state.layout.data);
  const error = useSelector((state: ApplicationState) => state.dashboard.error);
  const [currentLevel, setCurrentLevel] = useState<string | undefined>(undefined);
  const fields = useSelector(
    (state: ApplicationState) => state.dashboard.config?.gadget.promptParameters
  );
  const [display, setDisplay] = useState<any>([]);
  const { id }: { id: string } = useParams();

  useEffect(() => {
    setCurrentLevel(undefined);
  }, [id]);

  useEffect(() => {
    dispatch(dashboardConfigRequest(parseInt(id)));
  }, [dispatch, id]);

  useEffect(() => {
    setCurrentLevel(config?.gadget.firstLevelId);
  }, [config?.gadget.firstLevelId]);

  useEffect(() => {
    if (!currentLevel) return;
    if (!config) return;
    if (fields && fields.some((item) => item.required)) return;

    dispatch(dashboardRequest(undefined, currentLevel, config.codDsb));
  }, [config, currentLevel, dispatch, fields]);

  const getSlider = (dataLength?: number, quantity?: number, initValue?: string) => {
    if (!initValue || initValue === 'disabled' || !quantity || !dataLength) return;
    if ((!initValue || initValue === 'disabled') && !quantity && dataLength >= 6) {
      return {
        start: 0,
        end: 6 / dataLength,
      };
    }
    return {
      start: initValue === 'first' ? 0 : (dataLength - quantity + 1) / dataLength,
      end: initValue === 'last' ? 1 : quantity / dataLength,
    };
  };

  const callRefreshDetails = useCallback(
    (chartId: string, values: any, refreshDetails?: RefreshDetails) => {
      const parameters =
        refreshDetails?.params.map((param) => {
          const field = param.field.replace(/[^a-zA-Z]+/g, '');

          return {
            key: param.id,
            value: values[field],
          };
        }) || [];

      if (config && currentLevel && refreshDetails) {
        dispatch(
          dashboardRequest(
            {
              refreshDetails: {
                master: chartId,
                uiList: refreshDetails.uiList,
                parameters,
              },
            },
            currentLevel,
            config.codDsb,
            refreshDetails.uiList
          )
        );
      }
    },
    [config, currentLevel, dispatch]
  );

  const handleClick = useCallback(
    (values: any, chartId: string, series: Serie[] = []) => {
      const refreshDetailsSerie = series.find((serie) => serie.refreshDetails);
      const onClickSerie = series.find((serie) => serie.onClick);

      if (!config) return;

      if (refreshDetailsSerie) {
        const refreshDetails = refreshDetailsSerie.refreshDetails;

        if (currentLevel && refreshDetails) {
          callRefreshDetails(chartId, values, refreshDetails);
        }
      } else if (onClickSerie) {
        /* dispatch(dashboardRequest([], onClickSerie.onClick?.navigateTo || '', config.codDsb)); */
      }
    },
    [config, currentLevel, callRefreshDetails]
  );

  const getChart = useCallback(
    (chart?: ChartConfig, title?: string, width?: number) => {
      if (!chart) return;
      return {
        id: chart.id,
        position: chart.displayOrder,
        component: (
          <Charts
            metaData={chart.metadata}
            value={chart.value}
            maxValue={chart.maxValue}
            minValue={chart.minValue}
            seriesGroup={chart.seriesGroup}
            alertColors={chart.alertColors}
            type={chart?.type}
            title={title}
            data={dashboardData(chart?.dataProvider, chart?.metadata)}
            angleField={chart?.metadata[1]?.name}
            colorField={chart?.metadata[0]?.name}
            xField={chart?.series ? chart?.series[0]?.xField?.fieldName : ''}
            yField={chart?.series ? chart?.series[0]?.yField?.fieldName : ''}
            horizontalAxis={chart?.horizontalAxis}
            width={width}
            field={chart?.series ? chart?.series[0]?.field : ''}
            nameField={chart?.series ? chart?.series[0]?.nameField : ''}
            valueField={chart.valueField}
            fieldType={chart?.series ? chart?.series[0]?.valueType : ''}
            slider={getSlider(
              chart?.dataProvider?.length,
              chart?.columns,
              chart?.horizontalAxis?.category?.initViewValue ||
                chart?.verticalAxis?.category?.initViewValue
            )}
            series={chart?.series}
            onClick={(values) => handleClick(values, chart.id, chart?.series)}
          />
        ),
      };
    },
    [handleClick]
  );

  const renderHtml = useCallback(
    (simpleValue?: SimpleValueConfig, width?: number) => {
      if (!simpleValue) return;
      return {
        id: simpleValue.id,
        position: simpleValue.displayOrder,
        component: (
          <DashContainer width={width || 100}>
            <Content>
              <Scrollbars>
                {simpleValue.label && (
                  <h2 style={{ color: layout?.color.dark }}>{simpleValue.label}</h2>
                )}
                <div dangerouslySetInnerHTML={{ __html: simpleValue.valueField }} />
              </Scrollbars>
            </Content>
          </DashContainer>
        ),
      };
    },
    [layout?.color.dark]
  );

  const renderGrid = useCallback(
    (grid?: GridConfig, width?: number) => {
      if (!grid) return;
      return {
        id: grid.id,
        position: grid.displayOrder,
        component: (
          <DashContainer width={width || 100}>
            <Content>
              <Grid
                grid={grid}
                onClick={
                  grid.refreshDetails
                    ? (values) => callRefreshDetails(grid.id, values, grid.refreshDetails)
                    : undefined
                }
              />
            </Content>
          </DashContainer>
        ),
      };
    },
    [callRefreshDetails]
  );

  const resolvePercent = (source: LevelContainer, sources: LevelContainer[] = []) => {
    const percent = source.relativeSize;
    let percentTotal = 0;

    sources.forEach((s) => {
      percentTotal += s.relativeSize;
    });

    return (percent * 100) / percentTotal;
  };

  const renderChart = useCallback(
    (container?: LevelContainer, isUpdate?: boolean) => {
      if (container?.chart) {
        if (isUpdate) {
          setDisplay((oldArray: any) => {
            const current = oldArray.find((elem: any) => elem.id === container.chart?.id);

            return [
              ...oldArray.filter((elem: any) => elem.id !== container.chart?.id),
              getChart(
                container.chart
                  ? { ...container.chart, displayOrder: current.position }
                  : undefined,
                '',
                container.orientation === 'V' ? 100 : container.relativeSize
              ),
            ];
          });

          return;
        }

        setDisplay((oldArray: any) => {
          return [
            ...oldArray,
            getChart(
              container.chart,
              '',
              container.orientation === 'V' ? 100 : container.relativeSize
            ),
          ];
        });
      }

      if (container?.grid) {
        if (isUpdate) {
          setDisplay((oldArray: any) => {
            const current = oldArray.find((elem: any) => elem.id === container.grid?.id);

            return [
              ...oldArray.filter((elem: any) => elem.id !== container.grid?.id),
              renderGrid(
                container.grid ? { ...container.grid, displayOrder: current.position } : undefined,
                container.orientation === 'V'
                  ? 100
                  : resolvePercent(container, container.containers)
              ),
            ];
          });

          return;
        }

        setDisplay((oldArray: any) => {
          return [
            ...oldArray,
            renderGrid(
              container.grid,
              container.orientation === 'V' ? 100 : resolvePercent(container, container.containers)
            ),
          ];
        });
      }

      if (container?.containers) {
        container.containers.forEach((item) => {
          if (item.chart) {
            if (isUpdate) {
              setDisplay((oldArray: any) => {
                const current = oldArray.find((elem: any) => elem.id === item.chart?.id);

                return [
                  ...oldArray.filter((elem: any) => elem.id !== item.chart?.id),
                  getChart(
                    item.chart ? { ...item.chart, displayOrder: current.position } : undefined,
                    '',
                    container.orientation === 'V' ? 100 : resolvePercent(item, container.containers)
                  ),
                ];
              });

              return;
            }

            setDisplay((oldArray: any) => {
              return [
                ...oldArray,
                getChart(
                  item.chart,
                  '',
                  container.orientation === 'V' ? 100 : resolvePercent(item, container.containers)
                ),
              ];
            });
          }

          if (item.simpleValue) {
            if (isUpdate) {
              setDisplay((oldArray: any) => {
                const current = oldArray.find((elem: any) => elem.id === item.simpleValue?.id);

                return [
                  ...oldArray.filter((elem: any) => elem.id !== item.simpleValue?.id),
                  renderHtml(
                    item.simpleValue
                      ? { ...item.simpleValue, displayOrder: current.position }
                      : undefined,
                    container.orientation === 'V' ? 100 : resolvePercent(item, container.containers)
                  ),
                ];
              });

              return;
            }

            setDisplay((oldArray: any) => {
              return [
                ...oldArray,
                renderHtml(
                  item.simpleValue,
                  container.orientation === 'V' ? 100 : resolvePercent(item, container.containers)
                ),
              ];
            });
          }

          if (item.grid) {
            if (isUpdate) {
              setDisplay((oldArray: any) => {
                const current = oldArray.find((elem: any) => elem.id === item.grid?.id);

                return [
                  ...oldArray.filter((elem: any) => elem.id !== item.grid?.id),
                  renderGrid(
                    item.grid ? { ...item.grid, displayOrder: current.position } : undefined,
                    container.orientation === 'V' ? 100 : resolvePercent(item, container.containers)
                  ),
                ];
              });

              return;
            }

            setDisplay((oldArray: any) => {
              return [
                ...oldArray,
                renderGrid(
                  item.grid,
                  container.orientation === 'V' ? 100 : resolvePercent(item, container.containers)
                ),
              ];
            });
          }
          if (item.containers) renderChart(item, isUpdate);
        });
      }
      return;
    },
    [getChart, renderGrid, renderHtml]
  );

  useEffect(() => {
    if (!loading) {
      // setDisplay((state: any) => elementsToBeUpdate ? state.filter((item: any) => !elementsToBeUpdate.includes(item.id)) : []);
      setDisplay((state: any) => (elementsToBeUpdate ? state : []));
      renderChart(data?.container, elementsToBeUpdate && elementsToBeUpdate.length > 0);
    }
  }, [data, renderChart, elementsToBeUpdate, loading]);

  return (
    <Spin
      style={{ color: layout?.color.default }}
      spinning={loading}
      size="large"
      delay={500}
      indicator={<LoadingOutlined />}
    >
      <Row gutter={[20, 20]}>
        {error ? (
          <Col span={24}>
            <ServerError {...error} />
          </Col>
        ) : (
          <>
            {fields && fields.length > 0 && config && config.codDsb.toString() === id && (
              <Col span={24}>
                <Container>
                  <Parameters config={config} />
                </Container>
              </Col>
            )}
            {display && (
              <>
                {display
                  .sort((a: any, b: any) => {
                    if (a.position < b.position) {
                      return -1;
                    }
                    if (a.position > b.position) {
                      return 1;
                    }
                    return 0;
                  })
                  .map((item: any) => item.component)}
              </>
            )}
          </>
        )}
      </Row>
    </Spin>
  );
};

export default Dasboard;
