import { Button } from 'antd';
import styled from 'styled-components';
import { colors } from '../../utils/constants';

export const ContainerForm = styled.div`
  padding: 20px;
  margin-bottom: 20px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 1px 2px 2px 2px ${colors.grey};
  .ant-form-item-label > label {
    width: 170px;
  }
`;

export const CustomButton = styled(Button)`
  margin-right: 5px;
`;
