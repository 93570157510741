import styled from 'styled-components';
import { colors } from '../../../utils/constants';
export const Container = styled.div`
  display: flex;
  align-items: space-between;
  justify-content: space-between;
  width: 100%;
`;

export const Title = styled.h3`
  color: ${colors.darkGrey};
  margin: 0;
  padding-right: 5px;
`;

export const Value = styled.h3`
  margin: 0;
`;
export const Content = styled.div`
  display: flex;
  flex-direction: column;

`;