import { AxiosResponse } from 'axios';
import { call, put } from 'redux-saga/effects';
import api, { errorHandler } from '../../../services/api';
import { refreshRequest } from '../auth/actions';
import {
  loadFieldsFailure,
  loadFieldsSuccess,
  sendFieldsFailure,
  sendFieldsRequest,
  sendFieldsSuccess,
} from './actions';
import { SignUpForm, Success } from './types';

export function* loadSignUpFields() {
  try {
    const response: AxiosResponse<SignUpForm[]> = yield call(api.get, '/access-request/fields');
    yield put(loadFieldsSuccess(response.data));
  } catch (err: any) {
    yield put(
      err.response.status !== 401 ? loadFieldsFailure(errorHandler(err)) : refreshRequest()
    );
  }
}

export function* sendSignUpForm({ payload }: ReturnType<typeof sendFieldsRequest>) {
  try {
    const response: AxiosResponse<Success> = yield call(api.post, `/access-request`, {
      password: payload.values?.find((item) => item.name === 'password')?.value,
      values: payload.values?.filter(
        (item) => item.name !== 'password' && item.name !== 'confirmationPassword'
      ),
    });
    yield put(sendFieldsSuccess(response.data));
  } catch (err: any) {
    yield put(
      err.response.status !== 401 ? sendFieldsFailure(errorHandler(err)) : refreshRequest()
    );
  }
}
