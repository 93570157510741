import styled from 'styled-components';

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 25px;
  @media only screen and (max-width: 1024px) {
    padding-left: 0;
  }
`;
