import { Modal, Skeleton } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../store';
import { PdfContent } from './styles';

interface Props {
  title?: string;
  visible: boolean;
  setVisible: any;
}

const BudgetDisplay: React.FC<Props> = ({ title, visible, setVisible }) => {
  const pdf = useSelector((state: ApplicationState) => state.shoppingCart.pdf);

  return (
    <Modal
      data-cy={`modal-${title}`}
      className="pdf-display"
      style={{ minWidth: '70%' }}
      title={title}
      visible={visible}
      onCancel={() => setVisible(false)}
      cancelText="Fechar"
      footer={null}
    >
      {pdf ? (
        <PdfContent title="pdf" src={`data:application/pdf;base64,${pdf}`} />
      ) : (
        <>
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
        </>
      )}
    </Modal>
  );
};

export default BudgetDisplay;
