import { Reducer } from 'redux';
import { ReportsState, ReportsTypes } from './types';

const INITIAL_STATE: ReportsState = {
  dataReports: [],
  loadingReports: false,
};

const reducer: Reducer<ReportsState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ReportsTypes.REPORTS_REQUEST:
      return {
        ...state,
        loadingReports: true,
      };
    case ReportsTypes.REPORTS_SUCCESS:
      return {
        ...state,
        error: undefined,
        loadingReports: false,
        dataReports: action.payload.data,
      };
    case ReportsTypes.REPORTS_FAILURE:
      return {
        ...state,
        dataReports: [],
        loadingReports: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export default reducer;
