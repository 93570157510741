import React, { useState } from 'react';
import { Form, Modal } from 'antd';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';

import DynamicFields from '../../../components/DynamicFields';
import api from '../../../services/api';
import { ApplicationState } from '../../../store';
import { requestHeaders } from '../../../utils/apiHelper';
import { Container, SubmitButton } from '../../../styles';
import { PdfContent } from '../styles';
import { useWindowSize } from '../../../customHooks';
import { downloadFile } from '../../../utils/fileHelper';
import moment from 'moment';

interface Props {
  namePdf: string;
  keyField: number;
  visible: boolean;
  setVisible: any;
  dataFields: any[];
}

const ReportForm: React.FC<Props> = ({ visible, setVisible, dataFields, keyField, namePdf }) => {
  const layout = useSelector((state: ApplicationState) => state.layout.data);
  const token = useSelector((state: ApplicationState) => state.auth.token);
  const [loading, setLoading] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [pdf, setPdf] = useState<any>(undefined);
  const size = useWindowSize();

  const onFinish = (value: any) => {
    const arr: any[] = [];
    Object.keys(value).forEach((item) => {
      const fieldValue = dataFields.find((e) => e.nomeCampo === item);
      if (fieldValue) {
        arr.push({
          ...fieldValue,
          valor:
            value[item] instanceof moment ? moment(value[item]).format('DD/MM/YYYY') : value[item],
        });
      }
    });
    const headers = requestHeaders(token);
    setLoading(true);
    api
      .post(
        `relatorios/${keyField}/gerar-relatorio`,
        {
          campos: arr.filter((item) => item.valor),
          codCfg: 1,
          nuRef: keyField,
          origem: 'LISTA_RELATORIOS',
        },
        { headers: headers }
      )
      .then((res) => {
        setLoading(false);
        if (res.data) {
          if ((size.width ?? 0) < 1024) {
            downloadFile(res.data, namePdf, 'pdf', '75vh');
            onCancel();
          } else {
            setPdf(res.data);
          }
        }
        if (res.status === 204) {
          onCancel();
          Swal.fire({
            title: 'Aviso!',
            confirmButtonColor: layout?.color.default,
            html: 'Nenhuma página a apresentar.',
            icon: 'warning',
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        Swal.fire({
          title: 'Erro!',
          confirmButtonColor: layout?.color.default,
          html: err.response.data.titulo,
          icon: 'error',
        });
      });
  };

  const onCancel = () => {
    setPdf(undefined);
    setVisible(false);
    form.resetFields();
  };

  return (
    <Modal
      data-cy="modal-reports"
      centered
      destroyOnClose
      title={pdf ? namePdf : 'Download de relatório'}
      width={pdf ? 1000 : 400}
      visible={visible}
      onCancel={() => onCancel()}
      cancelText="Fechar"
      footer={
        !pdf || loading
          ? [
            <Form layout="vertical" onFinish={onFinish} form={form}>
              <Form.Item style={{ textAlign: 'right' }}>
                <SubmitButton
                  data-cy="btn-generateReport"
                  layout={layout}
                  htmlType="submit"
                  loading={loading}
                >
                  Gerar
                </SubmitButton>
              </Form.Item>
            </Form>,
          ]
          : false
      }
    >
      <Container layout={layout}>
        {pdf && !loading && (size.width ?? 0) > 1024 ? (
          <PdfContent title="pdf" src={`data:application/pdf;base64,${pdf}`} />
        ) : (
          !pdf && (
            <Form layout="vertical" onFinish={onFinish} form={form}>
              {dataFields.map((field) => {
                return (
                  <DynamicFields
                    type={field.tipoApres}
                    label={field.descricao}
                    foreign={field.entidadeEstrang}
                    name={field.nomeCampo}
                    url={`relatorios/${keyField}`}
                  />
                );
              })}
            </Form>
          )
        )}
      </Container>
    </Modal>
  );
};

export default ReportForm;
