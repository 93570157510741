import React, { useEffect } from 'react';

import { FileTextOutlined } from '@ant-design/icons';
import TextArea from 'antd/lib/input/TextArea';
import { shallowEqual, useSelector } from 'react-redux';
import { ApplicationState } from '../../../store';
import { Form } from 'antd';
import { FormInstance } from 'antd/lib/form';
import CustomCollapse from '../../../components/CustomCollapse';
interface Props {
  form: FormInstance<any>;
  onValuesChange: (_value: any, values: any) => void;
}
const Observation: React.FC<Props> = ({ form, onValuesChange }) => {
  const config = useSelector((state: ApplicationState) => state.shoppingCart.config);
  const observationInput = useSelector(
    (state: ApplicationState) =>
      state.shoppingCart.fields.find((item) => item.key === 'observation')?.value,
    shallowEqual
  );
  const layout = useSelector((state: ApplicationState) => state.layout.data);

  useEffect(() => {
    form.setFieldsValue({ observation: observationInput });
  }, [form, observationInput]);

  return (
    <CustomCollapse
      icon={<FileTextOutlined style={{ color: layout?.color.default }} />}
      title="Observação"
      hasNotification
      defaultOpened={!!observationInput || config?.details.note.required}
      dot={!observationInput && config?.details.note.required}
    >
      <Form layout="vertical" onValuesChange={onValuesChange} form={form}>
        <Form.Item
          rules={[
            { required: config?.details.note.required, message: `Observação é obrigatório.` },
          ]}
          key={'observation'}
          label={''}
          initialValue={observationInput}
          name={'observation'}
          style={{ marginBottom: '10px' }}
        >
          <TextArea
            data-cy="input-observation"
            autoSize={{ minRows: 2, maxRows: 4 }}
            placeholder={config?.details.note.placeholder ?? 'Digite uma observação'}
          />
        </Form.Item>
      </Form>
    </CustomCollapse>
  );
};

export default Observation;
