import styled from 'styled-components';
import { Avatar, Button } from 'antd';
import { Layout } from '../../store/ducks/layout/types';
import { colors } from '../../utils/constants';
import { ShoppingOutlined, MenuOutlined, QuestionCircleOutlined } from '@ant-design/icons';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 58px;
  background-color: white;
  z-index: 999;
  box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.24);
`;

export const Content = styled.div`
  display: flex;
  min-width: 100px;
  align-items: center;
  justify-content: space-around;
`;

export const UserContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: space-around;
  justify-content: center;
  padding: 0px 10px 0px;
`;
export const UserContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 0px 10px 0px;
  cursor: pointer;
`;

export const NewOrderButton = styled(Button) <{ layout?: Layout }>`
  margin-right: 10px;
`;

export const TextAvatar = styled(Avatar) <{ layout?: Layout }>`
  color: ${({ layout }) => layout?.color.dark};
  background-color: ${({ layout }) => layout?.color.lighter};
`;

export const PartnerName = styled.small`
  color: ${colors.writeGrey};
`;

export const ShoppingIcon = styled(ShoppingOutlined)`
  font-size: 20px;
`;

export const MenuIcon = styled(MenuOutlined) <{ layout?: Layout }>`
  font-size: 20px;
`;
export const ButtonWithOutBorder = styled(Button) <{ layout?: Layout }>`
  border: 0px solid transparent !important;
  :hover {
    border: 0px solid transparent !important;
  }
  :active {
    border: 0px solid transparent !important;
  }
  :focus {
    border: 0px solid transparent !important;
  }
`;

export const QuestionIcon = styled(QuestionCircleOutlined) <{ layout?: Layout }>`
  font-size: 20px !important;
`;

export const VideoContainer = styled.div`
  position: relative;
  padding-bottom: 56.25%;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`
