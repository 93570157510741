import { Reducer } from 'redux';
import { UserState, UserTypes } from './types';

const INITIAL_STATE: UserState = {
  data: undefined,
  error: false,
  loading: false,
  photo: undefined,
  loadingPhoto: false,
  partners: [],
};

const reducer: Reducer<UserState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UserTypes.LOAD_REQUEST:
    case UserTypes.UPDATE_REQUEST:
    case UserTypes.LOAD_REQUEST_PARTNERS:
    case UserTypes.PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UserTypes.LOAD_SUCCESS:
    case UserTypes.UPDATE_SUCCESS:
    case UserTypes.PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        data: action.payload.user,
      };
    case UserTypes.LOAD_FAILURE:
    case UserTypes.UPDATE_FAILURE:
    case UserTypes.PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case UserTypes.LOAD_REQUEST_PHOTO:
    case UserTypes.UPDATE_REQUEST_PHOTO:
    case UserTypes.DELETE_REQUEST_PHOTO:
      return {
        ...state,
        loadingPhoto: true,
      };
    case UserTypes.LOAD_SUCCESS_PHOTO:
    case UserTypes.UPDATE_SUCCESS_PHOTO:
      return {
        ...state,
        loadingPhoto: false,
        error: false,
        photo: action.payload.image,
      };
    case UserTypes.LOAD_FAILURE_PHOTO:
    case UserTypes.UPDATE_FAILURE_PHOTO:
      return {
        ...state,
        loadingPhoto: false,
        error: true,
        photo: undefined,
      };

    case UserTypes.DELETE_SUCCESS_PHOTO:
      return {
        ...state,
        loadingPhoto: false,
        error: false,
        photo: undefined,
      };
    case UserTypes.DELETE_FAILURE_PHOTO:
      return {
        ...state,
        loadingPhoto: false,
        error: true,
      };

    case UserTypes.LOAD_SUCCESS_PARTNERS:
      return {
        ...state,
        loading: false,
        error: false,
        partners: action.payload.partners,
      };
    case UserTypes.LOAD_FAILURE_PARTNERS:
      return {
        ...state,
        loading: false,
        error: true,
        partners: [],
      };
    default:
      return state;
  }
};

export default reducer;
