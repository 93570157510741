import React, { Dispatch, useEffect } from 'react';
import { Button } from 'antd';
import { Action } from 'typesafe-actions';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import { ApplicationState } from '../../store';
import { Container } from '../../styles';
import UserForm from './UserForm';
import { requestUser } from '../../store/ducks/newUser/actions';
import { Content, HeaderContacts } from './styles';
import ContactsTable from './ContactsTable';
import { contactsRequest } from '../../store/ducks/contacts/actions';
const EditUser: React.FC = () => {
  const layout = useSelector((state: ApplicationState) => state.layout.data);
  const token = useSelector((state: ApplicationState) => state.auth.token);
  const history = useHistory();
  const dispatch = useDispatch<Dispatch<Action>>();
  const { id }: any = useParams();

  useEffect(() => {
    dispatch(requestUser(id));
    dispatch(contactsRequest(id));
  }, [dispatch, token, id]);
  return (
    <Container layout={layout}>
      <UserForm />
      <Content>
        <HeaderContacts>
          <h2>Contatos</h2>
          <Button
            data-cy="btn-newContact"
            icon={<PlusOutlined />}
            type="primary"
            onClick={() => {
              history.push(`/home/controle-acessos/usuario/${id}/contato`);
            }}
          >
            Novo contato
          </Button>
        </HeaderContacts>
        <ContactsTable userId={id} />
      </Content>
    </Container>
  );
};

export default EditUser;
