import { Spin } from 'antd';
import React, { Dispatch, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Action } from 'typesafe-actions';
import { LoadingOutlined, FilePdfOutlined, ShoppingCartOutlined } from '@ant-design/icons';
import CustomTable from '../../components/CustomTable';
import { ApplicationState } from '../../store';
import { loadDetail, loadRequest } from '../../store/ducks/budgetDetail/actions';
import {
  Buttons,
  Container,
  ContainerData,
  Content,
  ContentData,
  ContentDetail,
  FooterButton,
  ItemData,
  TitleData,
  ValueData,
} from './styles';
import { reDoOrderRequest, setBudgetId } from '../../store/ducks/shoppingCart/actions';
import Swal from 'sweetalert2';
import ServerError from '../../components/ServerError';
import PdfDisplay from '../../components/PdfDisplay';
import { configToColumnsType, dataToDisplay, dataToTableData } from '../../utils/newTableHelper';

const OrderDetails: React.FC = () => {
  const token = useSelector((state: ApplicationState) => state.auth.token);
  const layout = useSelector((state: ApplicationState) => state.layout.data);
  const config = useSelector((state: ApplicationState) => state.budgetDetail.config);
  const error = useSelector((state: ApplicationState) => state.budgetDetail.errorData);
  const data = useSelector((state: ApplicationState) => state.budgetDetail.data);
  const configDetail = useSelector((state: ApplicationState) => state.budgetDetail.configDetail);
  const dataDetail = useSelector((state: ApplicationState) => state.budgetDetail.dataDetail);
  const loading = useSelector((state: ApplicationState) => state.budgetDetail.loading);
  const loadingTable = useSelector((state: ApplicationState) => state.budgetDetail.loadingTable);
  const pagination = useSelector((state: ApplicationState) => state.budgetDetail.pagination);
  const cartQuantity = useSelector((state: ApplicationState) => state.shoppingCart.totalQuantity);

  const [visible, setVisible] = useState<boolean>(false);
  const [dataList, setDataList] = useState<any[]>([]);

  const dispatch = useDispatch<Dispatch<Action>>();
  const { id }: any = useParams();

  const budgetToOrder = useCallback(
    (nuNota: number) => {
      if (cartQuantity > 0) {
        Swal.fire({
          title: 'Aviso!',
          confirmButtonColor: layout?.color.default,
          html: 'Você já tem itens adicionados no seu carrinho. Deseja limpar o carrinho?',
          icon: 'warning',
          confirmButtonText: 'Sim',
          cancelButtonText: 'Não',
          showCancelButton: true,
        }).then((result) => {
          if (result.isConfirmed) {
            dispatch(reDoOrderRequest(nuNota));
            dispatch(setBudgetId(nuNota));
          }
        });
      } else {
        dispatch(reDoOrderRequest(nuNota));
        dispatch(setBudgetId(nuNota));
      }
    },
    [cartQuantity, dispatch, layout?.color.default]
  );

  useEffect(() => {
    dispatch(loadRequest(undefined, undefined, undefined, id));
    dispatch(loadDetail(id));
  }, [dispatch, token, id]);

  useEffect(() => {
    setDataList(dataToDisplay(dataDetail, configDetail) || []);
  }, [dataDetail, configDetail]);

  return (
    <>
      {error ? (
        <ServerError status={error.status} message={error.message} back />
      ) : (
        <Container>
          <Buttons>
            <FooterButton
              data-cy="btn-budgetToOrder"
              onClick={() => budgetToOrder(id)}
              icon={<ShoppingCartOutlined />}
              type="primary"
            >
              Fazer pedido
            </FooterButton>
            {configDetail?.details.generateReport && (
              <FooterButton
                data-cy="btn-generateReport"
                onClick={() => setVisible(true)}
                icon={<FilePdfOutlined />}
                type="primary"
              >
                Gerar relatório
              </FooterButton>
            )}
          </Buttons>
          <ContentDetail>
            <CustomTable
              style={{ padding: '20px', marginBottom: '20px' }}
              onChange={(pg: any, filters: any, sorter: any) => {
                dispatch(loadRequest(pg, filters, sorter, id));
              }}
              pagination={pagination}
              loading={loadingTable}
              columns={configToColumnsType(config?.fields.filter((item) => item.show) || [])}
              data={dataToTableData(data, config)}
            />
            <Spin
              style={{ color: layout?.color.default }}
              spinning={loading}
              delay={500}
              indicator={<LoadingOutlined />}
            >
              <Content>
                <ContainerData>
                  <h3>Informações do orçamento</h3>
                  <ContentData>
                    {dataList.map((item: any) => {
                      if (!item.display) {
                        return undefined;
                      }
                      return (
                        <ItemData>
                          <TitleData>{`${item.title}:`}</TitleData>
                          <ValueData>{item.value}</ValueData>
                        </ItemData>
                      );
                    })}
                  </ContentData>
                </ContainerData>
              </Content>
            </Spin>
          </ContentDetail>
        </Container>
      )}
      <PdfDisplay
        title="Relatório"
        visible={visible}
        setVisible={setVisible}
        url={`budget/${id}/report`}
      />
    </>
  );
};

export default OrderDetails;
