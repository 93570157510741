import { action } from 'typesafe-actions';
import { Config } from '../../../utils/newTableHelper';
import { ResponseError } from '../../../services/api';
import { Category, Filter, Pagination, ProductsTypes, Sorter } from './types';
import { KeyValue } from '../dashboard/types';

export const loadRequest = (
  filter?: any,
  sorter?: any,
  pagination?: Pagination,
  search?: string,
  advancedFilters?: KeyValue[]
) => action(ProductsTypes.LOAD_REQUEST, { filter, sorter, pagination, search, advancedFilters });
export const loadSuccess = (
  data: any[],
  config: Config,
  pagination: Pagination,
  filter?: Filter[],
  sorter?: Sorter,
  search?: string,
  advancedFilters?: KeyValue[]
) =>
  action(ProductsTypes.LOAD_SUCCESS, {
    data,
    config,
    pagination,
    filter,
    sorter,
    search,
    advancedFilters,
  });
export const configSuccess = (config: Config) => action(ProductsTypes.CONFIG_SUCCESS, { config });
export const loadFailure = (error: ResponseError) => action(ProductsTypes.LOAD_FAILURE, { error });

export const favoriteRequest = (codProd: number, isFavorite: boolean) =>
  action(ProductsTypes.FAVORITE_REQUEST, { codProd, isFavorite });
export const favoriteSuccess = (data: any[]) => action(ProductsTypes.FAVORITE_SUCCESS, { data });
export const favoriteFailure = (error: ResponseError) =>
  action(ProductsTypes.FAVORITE_FAILURE, { error });

export const categoriesRequest = () => action(ProductsTypes.LOAD_REQUEST_CATEGORY);
export const CategoriesSuccess = (data: Category[]) =>
  action(ProductsTypes.LOAD_SUCCESS_CATEGORY, { data });
export const categoriesFailure = (error: ResponseError) =>
  action(ProductsTypes.LOAD_FAILURE_CATEGORY, { error });

export const setUnity = (codProd: string, unity: string) =>
  action(ProductsTypes.SET_UNITY, { codProd, unity });

export const resetSorter = () => action(ProductsTypes.RESET_SORTER);

export const resetAdvancedFilter = () => action(ProductsTypes.RESET_ADVANCED_FILTER);

export const setMode = (mode: string) => action(ProductsTypes.SET_MODE, { mode });

export const setFilter = (filter: Filter[]) => action(ProductsTypes.SET_FILTER, { filter });

export const clearSearch = () => action(ProductsTypes.CLEAR_SEARCH);

export const unitySuccess = (data: any[]) => action(ProductsTypes.UNITY_SUCCESS, { data });

export const advancedFieldsRequest = () => action(ProductsTypes.ADVANCED_FIELDS_REQUEST);

export const advancedFieldsSuccess = (data: any[]) => action(ProductsTypes.ADVANCED_FIELDS_SUCCESS, { data });

export const advancedFieldsFailure = (error: ResponseError) =>
  action(ProductsTypes.ADVANCED_FIELDS_FAILURE, { error });
