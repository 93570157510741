import { action } from 'typesafe-actions';
import { ResponseError } from '../../../services/api';
import { Pagination, CampaignsTypes, Campaign } from './types';

export const requestCampaigns = (pagination?: Pagination) =>
  action(CampaignsTypes.CAMPAIGNS_REQUEST, { pagination });
export const successCampaigns = (pagination: Pagination, data: Campaign[]) =>
  action(CampaignsTypes.CAMPAIGNS_SUCCESS, { pagination, data });
export const failureCampaigns = (error: ResponseError) =>
  action(CampaignsTypes.CAMPAIGNS_FAILURE, { error });

export const requestCampaign = (codCampaign: string) =>
  action(CampaignsTypes.CAMPAIGN_REQUEST, { codCampaign });
export const successCampaign = (data: Campaign) =>
  action(CampaignsTypes.CAMPAIGN_SUCCESS, { data });
export const failureCampaign = (error: ResponseError) =>
  action(CampaignsTypes.CAMPAIGN_FAILURE, { error });
