import { ResponseError } from '../../../services/api';

export enum DashboardTypes {
  'LOAD_CONFIG_REQUEST' = '@dashboard/LOAD_CONFIG_REQUEST',
  'LOAD_CONFIG_SUCCESS' = '@dashboard/LOAD_CONFIG_SUCCESS',
  'LOAD_CONFIG_FAILURE' = '@dashboard/LOAD_CONFIG_FAILURE',

  'LOAD_REQUEST' = '@dashboard/LOAD_REQUEST',
  'LOAD_SUCCESS' = '@dashboard/LOAD_SUCCESS',
  'LOAD_FAILURE' = '@dashboard/LOAD_FAILURE',
}

export interface KeyValue {
  key: string;
  value: string;
}

export interface Parameter {
  id: string;
  description: string;
  metadata: string;
  required: boolean;
  keepLast: boolean;
  keepDate: boolean;
  limitChar: number;
  precision: number;
  listType?: string;
  items: KeyValue[];
  entity?: string;
  field?: string;
}

export interface HorizontalAxis {
  category?: {
    field?: string;
    rotation?: number;
    dropLabel?: boolean;
    initViewValue?: string;
    title?: string;
  };
  linear?: {
    resultRotation?: number;
    vResizing?: boolean;
    title?: string;
  };
}

export interface VerticalAxis {
  linear?: {
    resultRotation?: number;
    vResizing?: boolean;
    title?: string;
  };
  category?: {
    field?: string;
    rotation?: number;
    dropLabel?: boolean;
    initViewValue?: string;
    title?: string;
  };
}

export interface Metadata {
  name: string;
  label: string;
  type: string;
  visible: boolean;
  useFooter: boolean;
  horizontalAxis?: HorizontalAxis;
  verticalAxis?: VerticalAxis;
}

export interface Field {
  fieldName: string;
  accumulated?: boolean;
}

export interface RefreshDetails {
  uiList: string[],
  params: {
    id: "A_TIME",
    field: "$TIME"
  }[]
}

export interface Serie {
  type: string;
  labelSize?: string | number;
  field?: string;
  nameField?: string;
  xField?: Field;
  yField?: Field;
  display?: string;
  valueType?: string;
  circleIntersection?: boolean;
  yMinField?: Field;
  yMaxField?: Field;
  onClick?: {
    navigateTo: string;
    params: [
      {
        id: string,
        field: string
      }
    ]
  }
  refreshDetails?: RefreshDetails;
}

export interface legend {
  position?: string;
  direction?: string;
}

export interface SeriesGroup {
  type: string;
  series: Serie[];
}

export interface ChartConfig {
  id: string;
  type: string;
  metadata: Metadata[];
  displayOrder: number;
  series?: Serie[];
  seriesGroup?: SeriesGroup[];
  legend?: legend;
  dataProvider?: any[];
  horizontalAxis?: HorizontalAxis;
  verticalAxis?: VerticalAxis;
  columns?: number;
  alertColors?: { value: number; color: string }[];
  value?: number;
  minValue?: number;
  maxValue?: number;
  valueField?: string;
}

export interface GridConfig {
  id: string;
  displayOrder: number;
  metadata: Metadata[];
  dataProvider?: any[];
  refreshDetails?: RefreshDetails;
}

export interface SimpleValueConfig {
  id: string;
  displayOrder: number;
  label: string;
  valueField: string;
}

export interface LevelContainer {
  orientation: string;
  relativeSize: number;
  chart?: ChartConfig;
  containers?: LevelContainer[];
  grid?: GridConfig;
  simpleValue?: SimpleValueConfig;
}

export interface Level {
  id: string;
  description: string;
}

export interface Gadget {
  nuGdg: number;
  promptParameters?: Parameter[];
  firstLevelId: string;
  levels: Level[];
}

export interface DashboardConfig {
  codDsb: number;
  title: string;
  gadget: Gadget;
}

export interface ChartMetadata {
  name: string;
  label: string;
  type: string;
  visible: boolean;
  useFooter: boolean;
}

export interface DashboardData {
  container: LevelContainer;
}

export interface DashboardState {
  readonly config?: DashboardConfig;
  readonly data?: DashboardData;
  readonly loading: boolean;
  readonly error?: ResponseError;
  readonly elementsToBeUpdate?: string[];
}
