import React, { Dispatch, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Action } from 'typesafe-actions';
import { ShoppingCartItem } from '../../store/ducks/shoppingCart/types';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { addItemRequest, deleteItemRequest } from '../../store/ducks/shoppingCart/actions';
import { InputContent, InputField, ProductMultiplier } from './styles';
import { ApplicationState } from '../../store';
import { Button } from 'antd';
import Swal from 'sweetalert2';

export interface Props {
  width?: number;
  hasStock?: boolean;
  hasValidation?: boolean;
}

const QuantityInput: React.FC<ShoppingCartItem & Props> = ({
  codProd,
  stock,
  value,
  unity,
  description,
  width,
  hasStock,
  multiplier,
  isDecimal,
  decimalQtd,
  message,
  isPromo,
  hasValidation,
  controlType
}) => {
  const layout = useSelector((state: ApplicationState) => state.layout.data);
  const error = useSelector((state: ApplicationState) => state.shoppingCart.error);
  const { insertProductOutOfStock, validateStock } = useSelector((state: ApplicationState) => state.products.config?.details || {});
  const quantity = useSelector(
    (state: ApplicationState) =>
      state.shoppingCart.cart.find((item) => item.codProd === codProd)?.quantity || 0,
    shallowEqual
  );
  const [input, setInput] = useState<string | number>(quantity.toFixed(decimalQtd || 0));
  const dispatch = useDispatch<Dispatch<Action>>();
  const getStock = (): any => {
    return multiplier && stock ? Math.floor(stock / multiplier) : stock;
  };
  const { currentAction, currentActionItemId } = useSelector((state: ApplicationState) => state.shoppingCart);
  const [timer, setTimer] = useState<NodeJS.Timeout | null>();

  useEffect(() => {
    setInput(quantity.toString());
  }, [decimalQtd, quantity, error]);

  const setQuantity = (quantityInput: number) => {
    if (
      getStock() !== undefined &&
      quantityInput > getStock() &&
      quantity >= getStock() &&
      !!hasStock
    ) {
      if (!insertProductOutOfStock) return;
    }
    if (!quantityInput) {
      if (quantity === 0) return;
      if (hasValidation) {
        Swal.fire({
          title: 'Atenção!',
          text: `Deseja deletar o item do carrinho?`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: layout?.color.default,
          confirmButtonText: 'Sim',
          cancelButtonText: 'Não',
        }).then((result) => {
          if (result.isConfirmed) {
            dispatch(
              deleteItemRequest({
                hasStock: hasStock,
                description: description,
                codProd: codProd,
                stock: stock,
                value: value,
                unity: unity,
                quantity: value,
                multiplier: multiplier,
                isDecimal: isDecimal,
                decimalQtd: decimalQtd,
                message: message,
                isPromo: isPromo,
                controlType
              })
            );
          } else {
            setInput(quantity.toString());
          }
        });
      } else {
        dispatch(
          deleteItemRequest({
            hasStock: hasStock,
            description: description,
            codProd: codProd,
            stock: stock,
            value: value,
            unity: unity,
            quantity: value,
            multiplier: multiplier,
            isDecimal: isDecimal,
            decimalQtd: decimalQtd,
            message: message,
            isPromo: isPromo,
            controlType
          })
        );
      }
    } else {
      let newQuantity;

      if (insertProductOutOfStock)
        newQuantity = quantityInput;
      else
        newQuantity = (getStock() !== undefined && quantityInput >= getStock() && !!hasStock) || (getStock() !== undefined && quantityInput >= getStock() && validateStock)
          ? getStock()
          : quantityInput;

      setInput(newQuantity);

      dispatch(
        addItemRequest({
          hasStock: hasStock,
          description: description,
          codProd: codProd,
          stock: stock,
          value: value,
          unity: unity,
          quantity: newQuantity,
          multiplier: multiplier,
          isDecimal: isDecimal,
          decimalQtd: decimalQtd,
          message: message,
          isPromo: isPromo,
          controlType
        })
      );
    }
  };

  const onMinusClick = () => {
    if (quantity <= 0) {
      return;
    }
    const newQuantity = (quantity - 1).toFixed(decimalQtd || 0);
    setQuantity(parseFloat(newQuantity) < 0 ? 0 : parseFloat(newQuantity));
    // setInput(parseFloat(newQuantity) < 0 ? 0 : newQuantity);
  };

  const stockDisabled = () => {
    if (insertProductOutOfStock) return false;

    return getStock() !== undefined && quantity >= getStock() && (!!hasStock);
  }

  const onPlusClick = () => {
    if (stockDisabled()) return;

    const newQuantity = (quantity + 1).toFixed(decimalQtd || 0);
    setQuantity(parseFloat(newQuantity));
    // setInput(newQuantity);
  };

  const parser = (inputText: string): string | number => {
    if (!inputText) {
      setQuantity(0);
      return 0;
    }
    return !isDecimal ? parseFloat(inputText).toFixed(0) : inputText;
  };

  const onChange = ({ target }: any) => {
    const inputQuantity = parseFloat(target.value.replace(',', '.').replace(/[A-Za-z]/g, ''));

    let newQuantity;

    if (insertProductOutOfStock) {
      newQuantity = parser(target.value.replace(',', '.').replace(/[A-Za-z]/g, ''));
    } else {
      newQuantity = (getStock() !== undefined && inputQuantity >= getStock() && !!hasStock) || (getStock() !== undefined && inputQuantity >= getStock() && validateStock)
        ? getStock().toFixed(decimalQtd || 0)
        : parser(target.value.replace(',', '.').replace(/[A-Za-z]/g, ''));
    }

    setInput(newQuantity);

    if (isNaN(inputQuantity)) return;

    changeDelay(Number(newQuantity));
  };

  const changeDelay = (change: number) => {
    if (timer) {
      clearTimeout(timer);
      setTimer(null);
    }
    setTimer(
      setTimeout(() => {
        setQuantity(parseFloat(change.toFixed(decimalQtd || 0)));
      }, 1000)
    );
  }

  return (
    <InputContent data-cy="input-quantity">
      <InputField
        onBlur={({ target }) => setInput(parseFloat(target.value).toFixed(decimalQtd || 0))}
        overFlow={getStock() !== undefined && quantity > getStock() && !!hasStock && !insertProductOutOfStock}
        width={width}
        min={0}
        layout={layout}
        disabled={!!currentAction}
        pattern="[0-9]+([,\.][0-9]+)?"
        /* formNoValidate */
        prefix={
          <Button
            data-cy="btn-minus"
            size="small"
            style={{ padding: 0 }}
            onClick={onMinusClick}
            type="link"
            disabled={quantity === 0 || !!currentAction}
            icon={<MinusOutlined />}
            loading={currentActionItemId === codProd && currentAction !== 'REMOVE'}
          />
        }
        suffix={
          <Button
            data-cy="btn-plus"
            type="link"
            size="small"
            style={{ padding: 0 }}
            disabled={stockDisabled() || !!currentAction}
            onClick={onPlusClick}
            icon={<PlusOutlined />}
            loading={currentActionItemId === codProd && currentAction !== 'REMOVE'}
          />
        }
        max={getStock()}
        value={input.toString().replace('.', ',')}
        onChange={onChange}
      />
      {multiplier ? <ProductMultiplier>{`x ${multiplier}`}</ProductMultiplier> : <></>}
    </InputContent>
  );
};

export default QuantityInput;
