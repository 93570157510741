import { action } from 'typesafe-actions';
import { ResponseError } from '../../../services/api';
import { ScreenDetail, ScreenDetailTypes } from './types';
import { Config } from '../../../utils/newTableHelper';

export const DetailRequest = (id?: string, screenId?: string) =>
  action(ScreenDetailTypes.LOAD_REQUEST, { id, screenId });
export const loadSuccess = (data: ScreenDetail[], config: Config) =>
  action(ScreenDetailTypes.LOAD_SUCCESS, { data, config });
export const loadFailure = (error: ResponseError) =>
  action(ScreenDetailTypes.LOAD_FAILURE, { error });
