import React, { Dispatch, useEffect } from 'react';
import { Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Action } from 'typesafe-actions';
import { PlusOutlined } from '@ant-design/icons';
import { ApplicationState } from '../../store';
import { pointsRequest } from '../../store/ducks/deliveryPoint/actions';
import { contactRequest } from '../../store/ducks/newContact/actions';
import { Container } from '../../styles';
import PointsTable from './PointsTable';
import { ContactBox, HeaderPoints, InfoPoints, PaternBox, SubtitleBox, TitleBox } from './styles';
import { Size, useWindowSize } from '../../customHooks';

const DeliveryPoint: React.FC = () => {
  const layout = useSelector((state: ApplicationState) => state.layout.data);
  const token = useSelector((state: ApplicationState) => state.auth.token);
  const dataContact = useSelector((state: ApplicationState) => state.newContact.dataContact);
  const history = useHistory();
  const size: Size = useWindowSize();
  const dispatch = useDispatch<Dispatch<Action>>();
  const { userId, contactId }: any = useParams();

  useEffect(() => {
    if (!userId || !contactId) {
      return;
    }
    dispatch(contactRequest(contactId, userId));
    dispatch(pointsRequest(userId, contactId));
  }, [contactId, dispatch, token, userId]);

  return (
    <Container layout={layout}>
      <HeaderPoints>
        {dataContact && (
          <InfoPoints>
            <PaternBox>
              <TitleBox>Parceiro:</TitleBox>
              <SubtitleBox>{dataContact.partnerName}</SubtitleBox>
            </PaternBox>
            <ContactBox>
              <TitleBox>Contato:</TitleBox>
              <SubtitleBox>{dataContact.contactName}</SubtitleBox>
            </ContactBox>
          </InfoPoints>
        )}
        <Button
          data-cy="btn-linkDeliveryPoint"
          icon={<PlusOutlined />}
          block={(size?.width ?? 0) <= 600}
          type="primary"
          onClick={() => {
            history.push(
              `/home/controle-acessos/usuario/${userId}/contato/${contactId}/ponto-entrega/novo`
            );
          }}
        >
          Vincular ponto de entrega
        </Button>
      </HeaderPoints>
      <PointsTable />
    </Container>
  );
};

export default DeliveryPoint;
