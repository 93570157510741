import styled from 'styled-components';
import { Layout } from '../../store/ducks/layout/types';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: space-between;
`;
export const ProductsTitle = styled.h2<{ layout?: Layout }>`
  color: ${({ layout }) => layout?.color.default} !important;
  font-weight: 400px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
`;
export const CarouselStyled = styled.div<{ lenght: number }>`
  margin: ${({ lenght }) => (lenght > 4 ? '0 20px' : '0 -8px')};
  .ant-carousel {
    .slick-next {
      &::before {
        content: '';
      }
    }
    .slick-prev {
      &::before {
        content: '';
      }
    }
  }
  @media only screen and (max-width: 1200px) {
    margin: ${({ lenght }) => (lenght > 3 ? '0 20px' : '0 -8px')};
  }
  @media only screen and (max-width: 900px) {
    margin: ${({ lenght }) => (lenght > 2 ? '0 20px' : '0 -8px')};
  }
  @media only screen and (max-width: 570px) {
    margin: ${({ lenght }) => (lenght > 1 ? '0 20px' : '0 -8px')};
  }
`;

export const CampaignTitle = styled.a<{ layout?: Layout }>`
  color: ${({ layout }) => layout?.color.default} !important;
  font-weight: 400px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
`;
export const CampaignDescription = styled.pre`
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,
    'Open Sans', 'Helvetica Neue', sans-serif;
  white-space: pre-wrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  margin: 0;
  height: 44px;
`;
