import styled from 'styled-components';
import { colors } from '../../utils/constants';

export const ProductDescription = styled.span`
  font-size: 1.17em;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
`;

export const TotalQauntity = styled.small`
  display:block;
  text-align: left;
  color: ${colors.darkGrey};
`;

export const ValueText = styled.h3`
  font-weight: bold;
  color: green;
  margin: 0;
`;

export const ImageContainer = styled.div`
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MessageAlert = styled.small`
  margin-top: 5px;
  color: ${colors.danger};
`;

export const ListItem = styled.div`
  .ant-list-item-extra {
    margin: 0;
  }

  .ant-list-item{
    align-items: flex-start;

    @media (max-width: 550px) {
      align-items: initial
    }
  }
`;
