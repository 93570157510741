import { AxiosResponse } from 'axios';
import { call, put, select } from 'redux-saga/effects';
import { ApplicationState } from '../..';
import api, { errorHandler } from '../../../services/api';
import { requestHeaders } from '../../../utils/apiHelper';
import { refreshRequest } from '../auth/actions';
import { pointsRequest, pointsSuccess, pointsFailure } from './actions';
import { DeliveryPoint } from './types';

export function* loadPoints({ payload }: ReturnType<typeof pointsRequest>) {
  try {
    const state: ApplicationState = yield select();
    const headers = requestHeaders(state.auth.token);
    const response: AxiosResponse<{
      content: DeliveryPoint[];
      numberOfElements: number;
      size: number;
      number: number;
    }> = yield call(
      api.post,
      `/access-control/${payload.userId}/contact/${payload.contactId}/deliverypoints/all`,
      {
        page: (payload.pagination?.current || 1) - 1,
        size: payload.pagination?.pageSize || 12,
      },
      { headers: headers }
    );
    yield put(
      pointsSuccess(
        {
          total: response.data.numberOfElements,
          pageSize: response.data.size,
          current: response.data.number + 1,
        },
        response.data.content
      )
    );
  } catch (err: any) {
    yield put(err.response.status !== 401 ? pointsFailure(errorHandler(err)) : refreshRequest());
  }
}
