import { Reducer } from 'redux';
import { ScreenConstructorState, ScreenConstructorTypes } from './types';

const INITIAL_STATE: ScreenConstructorState = {
  config: undefined,
  pagination: undefined,
  data: [],
  loading: false,
};

const reducer: Reducer<ScreenConstructorState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ScreenConstructorTypes.LOAD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ScreenConstructorTypes.LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        ...action.payload,
      };
    case ScreenConstructorTypes.LOAD_FAILURE:
      return {
        ...state,
        data: [],
        loading: false,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
