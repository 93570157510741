import { AxiosResponse } from 'axios';
import { call, put, select } from 'redux-saga/effects';
import { ApplicationState } from '../..';
import { requestHeaders } from '../../../utils/apiHelper';
import api, { errorHandler } from '../../../services/api';
import { refreshRequest } from '../auth/actions';
import {
  EditRequest,
  loadFailure,
  loadSuccess,
  sendFailure,
  sendEditRequest,
  sendSuccess,
} from './actions';
import { ScreenEdit, Success } from './types';

export function* loadScreenEdit({ payload }: ReturnType<typeof EditRequest>) {
  try {
    const state: ApplicationState = yield select();
    const headers = requestHeaders(state.auth.token);
    const response: AxiosResponse<ScreenEdit[]> = yield call(
      api.get,
      `/screen/${payload.screenId}/detail/${payload.id}/fields`,
      {
        headers: headers,
      }
    );
    yield put(loadSuccess(response.data));
  } catch (err: any) {
    yield put(err.response.status !== 401 ? loadFailure(errorHandler(err)) : refreshRequest());
  }
}

export function* sendScreenEdit({ payload }: ReturnType<typeof sendEditRequest>) {
  try {
    const state: ApplicationState = yield select();
    const headers = requestHeaders(state.auth.token);
    const response: AxiosResponse<Success> = yield call(
      api.put,
      `/screen/${payload.screenId}/edit`,
      { values: payload.values, registryPk: payload.id, id: parseInt(payload.screenId || '') },
      {
        headers: headers,
      }
    );
    yield put(sendSuccess(response.data));
  } catch (err: any) {
    yield put(err.response.status !== 401 ? sendFailure(errorHandler(err)) : refreshRequest());
  }
}
