import { AxiosResponse } from 'axios';
import { call, put, select } from 'redux-saga/effects';
import { ApplicationState } from '../..';
import api, { errorHandler } from '../../../services/api';
import { requestHeaders } from '../../../utils/apiHelper';
import { Config, Data } from '../../../utils/newTableHelper';
import { refreshRequest } from '../auth/actions';
import { loadFailure, loadSuccess } from './actions';

export function* loadHomeFinancials() {
  try {
    const state: ApplicationState = yield select();
    const headers = requestHeaders(state.auth.token);
    const config: AxiosResponse<Config> = yield call(api.get, 'financial/config', {
      headers: headers,
    });
    const response: AxiosResponse<{ content: Data[] }> = yield call(
      api.post,
      'financial/home',
      { page: 0, size: 5 },
      {
        headers: headers,
      }
    );
    yield put(loadSuccess(response.data.content, config.data));
  } catch (err: any) {
    yield put(err.response.status !== 401 ? loadFailure(errorHandler(err)) : refreshRequest());
  }
}
