import styled from "styled-components";

export const CreditCardData = styled.div`
  background-color: #FEFFF4;
  border-radius: 5px;
  color: #5B8C00;
  border: 1px solid #e1ecbb;
  padding: 10px;
  margin: 20px 0 10px;
`;

export const CreditCardDataTitle = styled.p`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 16px;
  margin-bottom: 20px;
  color: #5B8C00;

  svg {
    font-size: 20px;
  }
`
