import { AxiosResponse } from 'axios';
import { call, put, select } from 'redux-saga/effects';
import { ApplicationState } from '../..';
import api, { errorHandler } from '../../../services/api';
import { requestHeaders } from '../../../utils/apiHelper';
import { refreshRequest } from '../auth/actions';
import { loadFailure, loadSuccess } from './actions';
import { DashboardMenu } from './types';

export function* loadDashboardMenu() {
  try {
    const state: ApplicationState = yield select();
    const headers = requestHeaders(state.auth.token);
    const response: AxiosResponse<{ content: DashboardMenu[] }> = yield call(
      api.get,
      'dashboard/menu',
      { headers: headers }
    );
    yield put(loadSuccess(response.data.content));
  } catch (err: any) {
    yield put(err.response.status !== 401 ? loadFailure(errorHandler(err)) : refreshRequest());
  }
}
