import { Reducer } from 'redux';
import { NewServiceOrderState, NewServiceOrderTypes } from './types';

const INITIAL_STATE: NewServiceOrderState = {
  data: [],
  stepForm: [],
  loading: false,
  sendLoading: false,
  stepData: [],
  params: [],
  requiredParams: [],
  pagination: {
    current: 1,
    pageSize: 12,
    total: 12,
  },
};

const reducer: Reducer<NewServiceOrderState> = (state = INITIAL_STATE, { payload, type }) => {
  switch (type) {
    case NewServiceOrderTypes.LOAD_REQUEST:
    case NewServiceOrderTypes.FORM_REQUEST:
    case NewServiceOrderTypes.LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case NewServiceOrderTypes.LOAD_SUCCESS:
    case NewServiceOrderTypes.FORM_SUCCESS:
    case NewServiceOrderTypes.LIST_SUCCESS:
      return {
        ...state,
        ...payload,
        loading: false,
        error: undefined,
      };
    case NewServiceOrderTypes.LOAD_FAILURE:
    case NewServiceOrderTypes.FORM_FAILURE:
    case NewServiceOrderTypes.LIST_FAILURE:
      return {
        ...state,
        ...payload,
        loading: false,
      };
    case NewServiceOrderTypes.SET_PARAMS:
    case NewServiceOrderTypes.SET_REQUIRED_PARAMS:
    case NewServiceOrderTypes.SET_ATTACHMENT:
      return {
        ...state,
        ...payload,
      };

    case NewServiceOrderTypes.SEND_REQUEST:
      return {
        ...state,
        sendLoading: true,
      };
    case NewServiceOrderTypes.SEND_SUCCESS:
      return {
        ...state,
        ...payload,
        sendLoading: false,
        sendError: undefined,
      };
    case NewServiceOrderTypes.SEND_FAILURE:
      return {
        ...state,
        ...payload,
        sendLoading: false,
      };
    default:
      return state;
  }
};

export default reducer;
