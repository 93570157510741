import React, { useEffect, useState } from 'react';
import { ColumnsType } from 'antd/lib/table';
import TableFilterDefault from '../../../components/CustomTable/TableFilterDefault';
import { GridConfig } from '../../../store/ducks/dashboard/types';
import { maskCamelCase } from '../../../utils/masks';
import { dashboardData } from '../../../utils/DahboardHelper';
import VirtualTable from '../VirtualTable';
import { TableStyled } from '../styles';

interface Props {
  grid: GridConfig;
  onClick?(values: any): void;
}

const Grid: React.FC<Props> = ({ grid, onClick }) => {
  const [data, setData] = useState<any[]>([]);

  useEffect(() => {
    setData(dashboardData(grid.dataProvider, grid.metadata) || []);
  }, [grid.dataProvider, grid.metadata]);

  const columns: ColumnsType<any> = grid.metadata.filter(item => item.visible).map((item) => {
    return {
      ellipsis: true,
      className: 'table-column',
      title: maskCamelCase(item.label),
      dataIndex: item.name,
      key: item.name,
      filterDropdown: TableFilterDefault(
        item.name,
        item.type === 'I' || item.type === 'F' ? 'number' : 'string'
      ),
      filterSearch: true,
      onFilter: (value, record) =>
        item.type === 'I' || item.type === 'F'
          ? record[item.name] === value
          : record[item.name].startsWith(value),
      sorter: (a, b) => {
        if (a[item.name] > b[item.name]) {
          return 1;
        }
        if (a[item.name] < b[item.name]) {
          return -1;
        }
        return 0;
      },
      sortDirections: ['descend', 'ascend'],
    };
  });

  return (
    <TableStyled>
      <VirtualTable columns={columns} data={data} scroll={{ y: 360 }} onClick={onClick} />
    </TableStyled>
  );
};

export default Grid;
