import { action } from 'typesafe-actions';
import { ResponseError } from '../../../services/api';
import { Config, Data } from '../../../utils/newTableHelper';
import { Filter } from '../products/types';
import {
  Attachment,
  FormFields,
  NewServiceOrderTypes,
  Pagination,
  Params,
  ServiceOrderTemplate,
  ServiceOrderTemplateStep,
  Sorter,
} from './types';

export const loadModelsRequest = () => action(NewServiceOrderTypes.LOAD_REQUEST);
export const loadModelsSuccess = (data: ServiceOrderTemplate[]) =>
  action(NewServiceOrderTypes.LOAD_SUCCESS, { data });
export const loadModelsFailure = (error: ResponseError) =>
  action(NewServiceOrderTypes.LOAD_FAILURE, { error });

export const sendRequest = (templateId?: number) =>
  action(NewServiceOrderTypes.SEND_REQUEST, { templateId });
export const sendSuccess = (newId?: number) => action(NewServiceOrderTypes.SEND_SUCCESS, { newId });
export const sendFailure = (sendError?: ResponseError) =>
  action(NewServiceOrderTypes.SEND_FAILURE, { sendError });

export const loadFormRequest = (id: number) => action(NewServiceOrderTypes.FORM_REQUEST, { id });
export const loadFormSuccess = (stepForm: FormFields[]) =>
  action(NewServiceOrderTypes.FORM_SUCCESS, { stepForm });
export const loadFormFailure = (error: ResponseError) =>
  action(NewServiceOrderTypes.FORM_FAILURE, { error });

export const setParams = (params: Params[]) => action(NewServiceOrderTypes.SET_PARAMS, { params });

export const setRequiredParams = (requiredParams: Params[]) =>
  action(NewServiceOrderTypes.SET_REQUIRED_PARAMS, { requiredParams });

export const setAttachments = (attachments: Attachment[]) =>
  action(NewServiceOrderTypes.SET_ATTACHMENT, { attachments });

export const loadListRequest = (
  step?: ServiceOrderTemplateStep,
  id?: number,
  searchValue?: string,
  filters?: Filter[],
  sorter?: Sorter,
  pagination?: Pagination
) =>
  action(NewServiceOrderTypes.LIST_REQUEST, { step, id, searchValue, filters, sorter, pagination });
export const loadListSuccess = (stepConfig: Config, stepData: Data[], pagination?: Pagination) =>
  action(NewServiceOrderTypes.LIST_SUCCESS, { stepConfig, stepData, pagination });
export const loadListFailure = (error: ResponseError) =>
  action(NewServiceOrderTypes.LIST_FAILURE, { error });
