import styled from 'styled-components';

export const Content = styled.div`
  padding: 20px;
  height: 450.49px;
`;

export const TableStyled = styled.div`
.ant-empty {
  height: 270px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
`;
