import React, { Dispatch, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Action } from 'typesafe-actions';
import { useHistory } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';

import { ApplicationState } from '../../store';
import { controlRequest } from './../../store/ducks/accessControl/actions';
import AccessControlTable from './AccessControlTable';
import { Container, AccessControlHeader } from './styles';
import { hasPermission } from '../../utils/permissions';
import { ROLE } from '../../utils/constants';
import { Button } from 'antd';
import SearchInput from './SearchInput';
import { Size, useWindowSize } from '../../customHooks';

const AccessControl: React.FC = () => {
  const token = useSelector((state: ApplicationState) => state.auth.token);
  const [search, setSearch] = useState<string>();
  const roles = useSelector((state: ApplicationState) => state.user.data?.roles);
  const history = useHistory();
  const size: Size = useWindowSize();

  const dispatch = useDispatch<Dispatch<Action>>();
  useEffect(() => {
    dispatch(controlRequest());
  }, [dispatch, token]);

  return (
    <Container>
      <AccessControlHeader>
        <Button
          data-cy="btn-newUser"
          icon={<PlusOutlined />}
          block={(size.width ?? 0) <= 1024}
          disabled={!hasPermission([ROLE.ACCESS_CONTROL], roles)}
          type="primary"
          onClick={() => {
            history.push('controle-acessos/usuario');
          }}
        >
          Novo usuário
        </Button>
        <SearchInput data-cy="input-search" setSearch={setSearch} />
      </AccessControlHeader>
      <AccessControlTable search={search} />
    </Container>
  );
};

export default AccessControl;
