import React, { Dispatch, useEffect } from 'react';
import { Action } from 'typesafe-actions';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { ApplicationState } from '../../store';
import { requestCampaign } from '../../store/ducks/campaigns/actions';
import { Container } from '../../styles';
import CampaignInfo from './CampaignInfo';
import { requestCampaignProducts } from '../../store/ducks/campaignProducts/actions';

const CampaignDetails: React.FC = () => {
  const token = useSelector((state: ApplicationState) => state.auth.token);
  const dispatch = useDispatch<Dispatch<Action>>();
  const { id }: any = useParams();
  useEffect(() => {
    dispatch(requestCampaign(id));
    dispatch(requestCampaignProducts(id));
  }, [dispatch, token, id]);

  return (
    <Container>
      <CampaignInfo />
    </Container>
  );
};

export default CampaignDetails;
