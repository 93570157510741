import styled from "styled-components";

export const TableContainer = styled.div`
  table {
    width: auto;
  }

  .ant-table-thead > tr > th {
    min-width: 240px;
    padding: 16px !important;
  }

  .virtual-table .ant-table-container:before,
  .virtual-table .ant-table-container:after {
    display: none;
  }

  .virtual-table-cell {
    box-sizing: border-box;
    padding: 16px;
    border-bottom: 1px solid #e8e8e8;
    background: #FFF;
    cursor: default;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .CellHovered {
    background-color: #fafafa;
  }
`;

export const Cell = styled.div``
