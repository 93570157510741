import { Result, Spin } from 'antd';
import React from 'react';
import { LoadingOutlined, ClockCircleOutlined } from '@ant-design/icons';
import { CustomButton, EmptyContainer, ShoppingCartIcon, ShoppingIcon } from './styles';
import { ApplicationState } from '../../store';
import { useSelector } from 'react-redux';
import { colors } from '../../utils/constants';

interface Props {
  handleButton: any;
}
const EmptyCart: React.FC<Props> = ({ handleButton }) => {
  const layout = useSelector((state: ApplicationState) => state.layout.data);
  const config = useSelector((state: ApplicationState) => state.shoppingCart.config);
  const loading = useSelector((state: ApplicationState) => state.shoppingCart.loading);

  return (
    <EmptyContainer>
      <Spin
        style={{ color: layout?.color.default }}
        spinning={loading}
        size="large"
        delay={500}
        indicator={<LoadingOutlined />}
      >
        <Result
          icon={
            config?.details.time.validTime ? (
              <ShoppingCartIcon style={{ color: colors.grey }} />
            ) : (
              <ClockCircleOutlined style={{ color: colors.grey }} />
            )
          }
          title="Seu carrinho está vazio!"
          subTitle={
            config?.details.time.validTime
              ? false
              : `O pedido poderá ser enviado entre ${config?.details.time.start} e ${config?.details.time.end}`
          }
          extra={
            <CustomButton
              data-cy="btn-purchase"
              onClick={() => handleButton()}
              icon={<ShoppingIcon />}
              type="primary"
              size="large"
            >
              Comprar
            </CustomButton>
          }
        />
      </Spin>
    </EmptyContainer>
  );
};

export default EmptyCart;
