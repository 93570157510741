import React, { Dispatch, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Action } from 'typesafe-actions';
import api from '../../services/api';

import { ApplicationState } from '../../store';
import { loadRequestShowCase } from '../../store/ducks/showCase/actions';
import ProductCardList from '../ShowCase/ProductCardList';
import Banners from './Banners';

import { PageContent, BannersContainer, ProductsContainer } from './styles';

export interface IBannerImage {
  bannerId: number;
  excTime: number;
  imageType: 'U' | 'I';
  imageUrl: string;
  link: string;
  order: number;
  productShowcaseId: number;
  title: string;
}

const ShowCaseHome: React.FC<any> = () => {
  const layout = useSelector((state: ApplicationState) => state.layout.data);
  const dispatch = useDispatch<Dispatch<Action>>();
  const [banners, setBanners] = useState<IBannerImage[]>([]);

  useEffect(() => {
    api.get('/product-showcase/banners').then(({ data = [] }) => setBanners(data));
    dispatch(loadRequestShowCase());
  }, [dispatch])

  return (
    <PageContent>
      {banners.length > 0 && (
        <BannersContainer>
          <Banners banners={banners} />
        </BannersContainer>)
      }

      <ProductsContainer layout={layout} noBorder={banners.length === 0}>
        <ProductCardList />
      </ProductsContainer>
    </PageContent>
  )
};

export default ShowCaseHome;
