import React, { useState } from 'react';

import ScreenConstructor from '../../ScreenConstructor';
import ScreenForm from '../../ScreenForm';

interface Params {
  name: string,
  value: any
}

interface SubScreenProps {
  screenId: number;
  params?: Params[]
}

const SubScreen: React.FC<SubScreenProps> = ({ screenId, params }) => {
  const [mode, setMode] = useState<'FORM' | 'LIST'>('LIST');

  const buildInitialValues = (values?: Params[]) => {
    const initialValues = values?.reduce(function (acc, value) {
      return {
        ...acc,
        [value.name]: value.value
      };
    }, {});

    return initialValues;
  }

  const hiddenFields = (values?: Params[]) => values?.map(param => param.name);

  return (
    mode === 'LIST' ? (
      <ScreenConstructor
        screenId={screenId}
        appearance="child"
        onClickNewRegister={() => setMode('FORM')}
        params={params}
      />
    ) : (
      <ScreenForm screenId={String(screenId)} initialValues={buildInitialValues(params)} hiddenFields={hiddenFields(params)} appearance="child" onCancel={() => setMode('LIST')} />
    )
  )
}

export default SubScreen
