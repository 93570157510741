import { AxiosResponse } from 'axios';
import { call, put, select } from 'redux-saga/effects';
import { ApplicationState } from '../..';
import api, { baseURL, errorHandler } from '../../../services/api';
import { Config, Data, dataToTableData } from '../../../utils/newTableHelper';
import { refreshRequest } from '../auth/actions';
import {
  loadFailureShowCaseDetail,
  loadRequestShowCaseDetail,
  loadSuccessShowCaseDetail,
  setUnityDetail,
  unitySuccessDetail,
} from './actions';

export function* loadProductDetailShowCase({
  payload,
}: ReturnType<typeof loadRequestShowCaseDetail>) {
  try {
    const config: AxiosResponse<Config> = yield call(api.get, '/new-order/products/template');
    const response: AxiosResponse<Data> = yield call(
      api.get,
      `/product-showcase/products/detail/${payload.id}`
    );
    const tableData: any[] = yield call(dataToTableData, [response.data], config.data);
    const filteredData = tableData.filter((item: any) => item?.UNIDADE.length > 0);
    const data = filteredData.map((item: any) => {
      return {
        ...item,
        SELECTED_UNITY: item?.UNIDADE[0],
      };
    });
    const images =
      data[0].DETAILS.alternativeImages.length > 0
        ? [
            `${baseURL}/produtos/${payload.id}/imagem?w=320&h=320`,
            ...data[0].DETAILS.alternativeImages.map(
              (item: any) =>
                `${baseURL}/produtos/${payload.id}/imagens/alternativas/${item.key}?w=320&h=320`
            ),
          ]
        : [`${baseURL}/produtos/${payload.id}/imagem?w=320&h=320`];

    const thumbnails =
      data[0].DETAILS.alternativeImages?.length > 0
        ? [
            `${baseURL}/produtos/${payload.id}/imagem?w=50&h=50`,
            ...data[0].DETAILS.alternativeImages.map(
              (item: any) =>
                `${baseURL}/produtos/${payload.id}/imagens/alternativas/${item.key}?w=50&h=50`
            ),
          ]
        : [`${baseURL}/produtos/${payload.id}/imagem?w=50&h=50`];
    yield put(loadSuccessShowCaseDetail(data, config as any, images, thumbnails));
  } catch (err: any) {
    yield put(
      err?.response?.status !== 401
        ? loadFailureShowCaseDetail(errorHandler(err))
        : refreshRequest()
    );
  }
}

export function* showCaseDetailChangeUnity({ payload }: ReturnType<typeof setUnityDetail>) {
  const state: ApplicationState = yield select();
  const newData = state.productsShowCase.data;
  const index = newData.findIndex((item) => item?.CODPROD === payload.codProd);
  newData[index].SELECTED_UNITY = payload.unity;
  yield put(unitySuccessDetail([...newData]));
}
