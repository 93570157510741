import { Reducer } from 'redux';
import { BudgetFieldsState, BudgetFieldsTypes } from './types';

const INITIAL_STATE: BudgetFieldsState = {
  data: [],
  values: [],
  loading: false,
};

const reducer: Reducer<BudgetFieldsState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case BudgetFieldsTypes.LOAD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case BudgetFieldsTypes.LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        data: action.payload.data,
      };
    case BudgetFieldsTypes.LOAD_FAILURE:
      return {
        ...state,
        data: [],
        loading: false,
        error: action.payload.error,
      };
    case BudgetFieldsTypes.SET_VALUES:
      return {
        ...state,
        values: action.payload.values,
      };
    default:
      return state;
  }
};

export default reducer;
