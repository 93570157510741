import { AxiosResponse } from 'axios';
import { call, put, select } from 'redux-saga/effects';
import { ApplicationState } from '../..';
import api, { errorHandler } from '../../../services/api';
import { requestHeaders } from '../../../utils/apiHelper';
import {
  Config,
  Data,
  filterParamsHandle,
  sorterParamsHandle,
} from '../../../utils/newTableHelper';
import { refreshRequest } from '../auth/actions';
import {
  failureDetail,
  loadDetail,
  loadFailure,
  loadServiceOrdersDetailRequest,
  loadSuccess,
  successDetail,
} from './actions';

export function* loadServiceOrdersDetail({
  payload,
}: ReturnType<typeof loadServiceOrdersDetailRequest>) {
  try {
    const state: ApplicationState = yield select();
    const headers = requestHeaders(state.auth.token);
    const config: AxiosResponse<Config> = yield call(api.get, `service-order/detail/item/config`, {
      headers: headers,
    });
    const response: AxiosResponse<{
      content: Data[];
      numberOfElements: number;
      size: number;
      number: number;
    }> = yield call(
      api.post,
      `service-order/detail/${payload.numOs}/item`,
      {
        page: (payload.pagination?.current || 1) - 1,
        size: payload.pagination?.pageSize || 12,
        filters: filterParamsHandle(payload.filter),
        sort: sorterParamsHandle(payload.sorter),
      },
      { headers: headers }
    );
    yield put(
      loadSuccess(
        {
          total: response.data.numberOfElements,
          current: response.data.number + 1,
          pageSize: response.data.size,
        },
        response.data.content,
        config.data
      )
    );
  } catch (err: any) {
    yield put(err.response.status !== 401 ? loadFailure(errorHandler(err)) : refreshRequest());
  }
}

export function* requestDetail({ payload }: ReturnType<typeof loadDetail>) {
  try {
    const state: ApplicationState = yield select();
    const headers = requestHeaders(state.auth.token);
    const config: AxiosResponse<Config> = yield call(api.get, `service-order/detail/config`, {
      headers: headers,
    });
    const response: AxiosResponse<Data> = yield call(
      api.get,
      `service-order/detail/${payload.numOs}`,
      {
        headers: headers,
      }
    );
    yield put(successDetail(response.data.fields, config.data));
  } catch (err: any) {
    yield put(err.response.status !== 401 ? failureDetail(errorHandler(err)) : refreshRequest());
  }
}
