import { Reducer } from 'redux';
import { OrderReleaseState, OrderReleaseTypes } from './types';

const INITIAL_STATE: OrderReleaseState = {
  data: [],
  pagination: undefined,
  loading: false,
  statusLoading: false,
};

const reducer: Reducer<OrderReleaseState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case OrderReleaseTypes.RELEASES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case OrderReleaseTypes.RELEASES_SUCCESS:
      return {
        ...state,
        error: undefined,
        loading: false,
        ...action.payload,
      };
    case OrderReleaseTypes.RELEASES_FAILURE:
      return {
        ...state,
        data: [],
        loading: false,
        ...action.payload,
      };
    case OrderReleaseTypes.APPROVE_REQUEST:
    case OrderReleaseTypes.DENY_REQUEST:
      return {
        ...state,
        statusLoading: true,
      };
    case OrderReleaseTypes.APPROVE_SUCCESS:
    case OrderReleaseTypes.DENY_SUCCESS:
      return {
        ...state,
        statusError: undefined,
        statusLoading: false,
        ...action.payload,
      };
    case OrderReleaseTypes.APPROVE_FAILURE:
    case OrderReleaseTypes.DENY_FAILURE:
      return {
        ...state,
        statusLoading: false,
        ...action.payload,
      };
    case OrderReleaseTypes.CLEAN_STATUS:
      return {
        ...state,
        status: undefined,
        statusError: undefined,
      };

    default:
      return state;
  }
};

export default reducer;
