import { Reducer } from 'redux';
import { MyOrdersState, MyOrdersTypes } from './types';

const INITIAL_STATE: MyOrdersState = {
  data: [],
  loading: false,
  tab: '1',
};

const reducer: Reducer<MyOrdersState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case MyOrdersTypes.SET_TAB:
      return {
        ...state,
        tab: action.payload.key,
      };

    case MyOrdersTypes.LOAD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case MyOrdersTypes.LOAD_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: undefined,
      };
    case MyOrdersTypes.LOAD_FAILURE:
      return {
        ...state,
        data: [],
        loading: false,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
