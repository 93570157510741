import { Button } from 'antd';
import styled from 'styled-components';
import { Layout } from './store/ducks/layout/types';
import { colors } from './utils/constants';
export const LoadingPage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
`;

export const SubmitButton = styled(Button)<{ layout?: Layout }>`
  background-color: ${({ layout }) => layout?.color.default};
  color: white;
  border: 1px solid ${({ layout }) => layout?.color.default};
  :hover {
    background-color: ${({ layout }) => layout?.color.default};
    color: white;
    border: 1px solid ${({ layout }) => layout?.color.default};
  }
  :active {
    background-color: ${({ layout }) => layout?.color.default};
    color: white;
    border: 1px solid ${({ layout }) => layout?.color.default};
  }
  :focus {
    background-color: ${({ layout }) => layout?.color.default};
    color: white;
    border: 1px solid ${({ layout }) => layout?.color.default};
  }
`;

export const DefaultButton = styled(Button)<{ layout?: Layout }>`
  background-color: white;
  :hover {
    background-color: white;
    color: ${({ layout }) => layout?.color.default};
    border: 1px solid ${({ layout }) => layout?.color.default};
  }
  :active {
    background-color: white;
    color: ${({ layout }) => layout?.color.default};
    border: 1px solid ${({ layout }) => layout?.color.default};
  }
  :focus {
    background-color: white;
    color: ${({ layout }) => layout?.color.default};
    border: 1px solid ${({ layout }) => layout?.color.default};
  }
`;

export const Container = styled.div<{ layout?: Layout }>`
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding: 10 !important;
  }
  .ant-checkbox-checked .ant-checkbox-inner  {
    background-color: ${({ layout }) => layout?.color.default};
    border-color: ${({ layout }) => layout?.color.default};
  }
  .ant-checkbox-indeterminate .ant-checkbox-inner::after  {
    background-color: ${({ layout }) => layout?.color.default};
  }

  .ant-typography {
    display: block;
    height: 80px;
    margin-left: 15px;
    margin-top: 15px;
  }
  .ant-menu-submenu-title:hover,
  .ant-menu-submenu-open {
    .ant-menu-submenu-arrow::before,
    .ant-menu-submenu-arrow::after {
      background: ${({ layout }) => layout?.color.default} !important;
    }
  }

  .ant-steps-navigation .ant-steps-item::before {
    background-color: ${({ layout }) => layout?.color.default};
  }

  .ant-steps-navigation .ant-steps-item {
    border-bottom: 1px solid ${colors.grayBackgroud};
  }

  .green-text {
    color: #00a789;
  }
  .ant-tabs-ink-bar {
    position: absolute;
    background: ${({ layout }) => layout?.color.default};
    pointer-events: none;
  }
  .ant-upload.ant-upload-select-picture-card {
    width: 120px;
    height: 120px;
    :hover {
      color: ${({ layout }) => layout?.color.default};
      border: 1px dashed ${({ layout }) => layout?.color.default};
    }
    :active {
      color: ${({ layout }) => layout?.color.default};
      border: 1px dashed ${({ layout }) => layout?.color.default};
    }
    :focus {
      color: ${({ layout }) => layout?.color.default};
      border: 1px dashed ${({ layout }) => layout?.color.default};
    }
  }

  .ant-btn-link,
  .ant-table-filter-trigger.active,
  .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
    color: ${({ layout }) => layout?.color.default};
  }

  .ant-collapse .ant-collapse-item-disabled > .ant-collapse-header,
  .ant-collapse .ant-collapse-item-disabled > .ant-collapse-header > .arrow {
    cursor: default;
  }

  .ant-btn-primary {
    background-color: ${({ layout }) => layout?.color.default};
    border-color: ${({ layout }) => layout?.color.default};
  }

  .ant-btn-link[disabled],
  .ant-btn-link[disabled]:hover,
  .ant-btn-link[disabled]:focus,
  .ant-btn-link[disabled]:active {
    color: rgba(0, 0, 0, 0.25);
    text-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .ant-btn-primary[disabled],
  .ant-btn-primary[disabled]:hover,
  .ant-btn-primary[disabled]:focus,
  .ant-btn-primary[disabled]:active,
  .ant-btn-default[disabled],
  .ant-btn-default[disabled]:hover,
  .ant-btn-default[disabled]:focus,
  .ant-btn-default[disabled]:active {
    color: rgba(0, 0, 0, 0.25);
    background: #f5f5f5;
    border-color: #d9d9d9;
    text-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .ant-btn-primary:hover,
  .ant-btn-primary:active,
  .ant-btn-primary:focus {
    background-color: ${({ layout }) => layout?.color.light};
    border-color: ${({ layout }) => layout?.color.light};
    color: white;
  }

  .ant-btn-default:hover,
  .ant-btn-default:active,
  .ant-btn-default:focus {
    border-color: ${({ layout }) => layout?.color.default};
    color: ${({ layout }) => layout?.color.default};
  }
  .ant-upload.ant-upload-drag:hover,
  .ant-upload.ant-upload-drag:active,
  .ant-upload.ant-upload-drag:focus {
    border-color: ${({ layout }) => layout?.color.default};
  }

  .ant-btn-link:hover,
  .ant-btn-link:active,
  .ant-btn-link:focus,
  .ant-table-column-sorter-up.active,
  .ant-table-column-sorter-down.active {
    color: ${({ layout }) => layout?.color.light};
  }

  .ant-switch-checked {
    background-color: ${({ layout }) => layout?.color.default};
  }
  .dark-button,
  .dark-button:hover,
  .dark-button:active,
  .dark-button:focus {
    background-color: ${({ layout }) => layout?.color.dark};
    color: white;
    border: 1px solid ${({ layout }) => layout?.color.dark};
  }
  .ant-tabs-tab:hover,
  .ant-tabs-tab:active,
  .ant-tabs-tab:focus,
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
  .ant-tabs-tab-btn:hover,
  .ant-tabs-tab-btn:focus,
  .ant-tabs-tab-btn:active,
  .ant-tabs-tab-active:hover,
  .ant-tabs-tab-active:focus,
  .ant-tabs-tab-active:active,
  .ant-tabs-tab.ant-tabs-tab-active {
    color: ${({ layout }) => layout?.color.default} !important;
  }
  .ant-tabs-tab {
    @media only screen and (max-width: 1024px) {
      padding: 8px 16px 8px 14px !important;
    }
  }
  .ant-pagination-item-active {
    border-color: ${({ layout }) => layout?.color.default};
  }

  .ant-pagination-item-active:focus,
  .ant-pagination-item-active:hover,
  .ant-pagination-item-active:active,
  .ant-pagination-item:focus,
  .ant-pagination-item:hover,
  .ant-pagination-prev:focus .ant-pagination-item-link,
  .ant-pagination-next:focus .ant-pagination-item-link,
  .ant-pagination-prev:hover .ant-pagination-item-link,
  .ant-pagination-next:hover .ant-pagination-item-link {
    border-color: ${({ layout }) => layout?.color.light};
    color: ${({ layout }) => layout?.color.light};
  }

  .ant-pagination-item-active a,
  .ant-pagination-item:focus a,
  .ant-pagination-item:hover a,
  .ant-pagination-prev:focus a,
  .ant-pagination-prev:hover a,
  .ant-pagination-next:focus a,
  .ant-pagination-next:hover a {
    color: ${({ layout }) => layout?.color.default} !important;
  }

  .ant-select:not(.ant-select-disabled):hover .ant-select-selector,
  .ant-select:not(.ant-select-disabled):focus .ant-select-selector {
    border-color: ${({ layout }) => layout?.color.default};
  }

  .ant-select-selector:focus,
  .ant-pagination-options:focus,
  .ant-select:focus,
  .ant-input-group:focus,
  .ant-picker:focus {
    outline: none;
  }

  input[type='color'],
  input[type='date'],
  input[type='datetime'],
  input[type='datetime-local'],
  input[type='email'],
  input[type='month'],
  input[type='number'],
  input[type='password'],
  input[type='search'],
  input[type='tel'],
  input[type='text'],
  input[type='time'],
  input[type='url'],
  input[type='week'],
  select:focus,
  textarea {
    font-size: 14px;
  }

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: ${({ layout }) => layout?.color.lighter};
  }

  .ant-input-affix-wrapper:focus,
  .ant-input-affix-wrapper-focused,
  .ant-input:focus,
  .ant-input-focused,
  .ant-input-number:focus,
  .ant-input-number-focused {
    border-color: ${({ layout }) => layout?.color.default};
    box-shadow: 0 0 0 2px ${({ layout }) => layout?.color.lighter};
  }

  .ant-input-affix-wrapper:hover,
  .ant-input:hover,
  .ant-input-number:hover {
    border-color: ${({ layout }) => layout?.color.default};
  }

  .ant-steps-item-finish .ant-steps-item-icon {
    border-color: ${({ layout }) => layout?.color.default};
  }

  .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
    background-color: ${({ layout }) => layout?.color.default};
  }

  .ant-steps-item-process .ant-steps-item-icon {
    background-color: ${({ layout }) => layout?.color.default};
    border-color: ${({ layout }) => layout?.color.default};
  }

  .ant-spin-dot-item {
    background-color: ${({ layout }) => layout?.color.default};
  }

  .ant-picker:hover,
  .ant-picker-focused {
    border-color: ${({ layout }) => layout?.color.default};
  }

  .ant-picker-focused {
    box-shadow: 0 0 0 2px ${({ layout }) => layout?.color.lighter};
  }
`;
