import { action } from 'typesafe-actions';
import { ResponseError } from '../../../services/api';
import { Pagination, ContactsTypes, Contact } from './types';

export const contactsRequest = (userId: number, pagination?: Pagination, search?: string) =>
  action(ContactsTypes.LOAD_REQUEST, { userId, pagination, search });
export const contactsSuccess = (pagination: Pagination, data: Contact[]) =>
  action(ContactsTypes.LOAD_SUCCESS, { pagination, data });
export const contactsFailure = (error: ResponseError) =>
  action(ContactsTypes.LOAD_FAILURE, { error });
