import { action } from 'typesafe-actions';
import { ResponseError } from '../../../services/api';
import { Config, Data } from '../../../utils/newTableHelper';
import { Pagination, MyOrdersTypes } from './types';

export const loadOrdersRequest = (
  pagination?: Pagination,
  filter?: any,
  sorter?: any,
  searchValue?: string
) => action(MyOrdersTypes.LOAD_REQUEST, { pagination, filter, sorter, searchValue });
export const loadSuccess = (pagination: Pagination, data: Data[], config: Config) =>
  action(MyOrdersTypes.LOAD_SUCCESS, {
    pagination,
    data,
    config,
  });
export const loadFailure = (error: ResponseError) => action(MyOrdersTypes.LOAD_FAILURE, { error });

export const setTab = (key: string) => action(MyOrdersTypes.SET_TAB, { key });
