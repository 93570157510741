import styled from 'styled-components';
import { Layout } from '../../../store/ducks/layout/types';
import { colors } from '../../../utils/constants';

export const Container = styled.div<{ hasImage?: boolean }>`
  display: flex;
  height: 100vh;
  width: 100vw;
  align-items: center;
  justify-content: center;
  background-color: ${colors.grayBackgroud};
`;

export const HomeLogin = styled.div<{ hasImage?: boolean; isLogin?: boolean }>`
  padding: 20px;
  height: ${({ hasImage, isLogin }) => (hasImage ? '100vh' : isLogin ? '75vh' : '90vh')};
  width: 550px;
  margin: ${({ hasImage }) => (!hasImage ? '20px' : 0)};
  background-color: white;
  box-shadow: 5px 5px 5px 5px ${colors.grey};
  border-radius: 5px;
  z-index: 999;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  @media only screen and (max-width: 1024px) {
    width: 100%;
    height: 100%;
    margin: 0;
  }
`;

export const FormLogin = styled.div<{ hasImage?: boolean; isLogin?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: ${({ hasImage, isLogin }) =>
    hasImage ? (isLogin ? '50vh' : '80vh') : isLogin ? '36vh' : '70vh'};

  @media only screen and (max-width: 1024px) {
    height: ${({ isLogin }) => (isLogin ? '36vh' : '80vh')};
  }
`;

export const LogoContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 100px;
  margin: 0 auto;
  margin-bottom: 10px;
`;

export const Logo = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

export const Wallpaper = styled.div<{ backgroudImage: string }>`
  background-image: ${({ backgroudImage }) => `url(${backgroudImage})`};
  background-color: #cccccc;
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  @media only screen and (max-width: 1024px) {
    display: none;
  }
`;

export const WallpaperContainer = styled.div<{ hasImage?: boolean }>`
  height: 100vh;
  width: 100%;
  display: ${({ hasImage }) => (!hasImage ? 'none' : 'block')};
  @media only screen and (max-width: 1024px) {
    display: none;
  }
`;

export const PackageVersion = styled.small`
  color: ${colors.writeGrey};
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  height: 80px;
`;

export const LogoFooter = styled.div`
  display: flex;
  align-items: center;
  width: 60px;
  justify-content: center;
  margin-bottom: 5px;
`;

export const ShowCaseContainer = styled.div`
  width: 100%;
  height: 100vh;
  background-color: ${colors.grayBackgroud};
`;

export const Header = styled.div`
  background-color: white;
`;

export const LogoShowCaseContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 100px;
`;


export const ColoredMenu = styled.div<{ layout?: Layout }>`
  background-image: linear-gradient(
    to right,
    ${({ layout }) => layout?.color.default},
    ${({ layout }) => layout?.color.dark}
  );
`;

