import { Input } from 'antd';
import styled from 'styled-components';
import { Layout } from '../../store/ducks/layout/types';
import { colors } from '../../utils/constants';

export const InputContent = styled.div`
  display: flex;
  align-items: center;
`;

export const InputField = styled(Input) <{ width?: number; overFlow?: boolean; layout?: Layout }>`
  max-width: ${({ width }) => (width ? `${width}px` : '100%')};
  min-width: 50px;
  text-align: center;
  padding-left: 4px;
  padding-right: 4px;
  height: 32px;
  border-color: ${({ layout, overFlow }) =>
    overFlow ? colors.danger : layout?.color.default} !important;
  :hover {
    border-color: ${({ layout, overFlow }) => (overFlow ? colors.danger : layout?.color.default)};
    box-shadow: 0 0 0 1px
      ${({ layout, overFlow }) => (overFlow ? colors.danger : layout?.color.default)};
  }
  :active {
    border-color: ${({ layout, overFlow }) => (overFlow ? colors.danger : layout?.color.default)};
    box-shadow: 0 0 0 1px
      ${({ layout, overFlow }) => (overFlow ? colors.danger : layout?.color.default)};
  }
  :focus {
    border-color: ${({ layout, overFlow }) => (overFlow ? colors.danger : layout?.color.default)};
    box-shadow: 0 0 0 1px
      ${({ layout, overFlow }) => (overFlow ? colors.danger : layout?.color.default)};
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  input {
    text-align: center;
  }
`;

export const ProductMultiplier = styled.small`
  margin-left: 6px;
  color: ${colors.darkGrey};
  white-space: nowrap;
`;
