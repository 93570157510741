import { ResponseError } from '../../../services/api';

export enum DeliveryPointTypes {
  'POINTS_REQUEST' = '@deliveryPoint/POINTS_REQUEST',
  'POINTS_SUCCESS' = '@deliveryPoint/POINTS_SUCCESS',
  'POINTS_FAILURE' = '@deliveryPoint/POINTS_FAILURE',
}

export interface Pagination {
  current: number;
  total: number;
  pageSize: number;
}

export interface DeliveryPoint {
  contactId: number;
  creditLimit: number;
  deadline: number;
  delivPointContactId: number;
  delivPointId: number;
  name: string;
  partnerId: number;
  useLimit: boolean;
  userId: number;
}

export interface DeliveryPointState {
  readonly data: DeliveryPoint[];
  readonly pagination?: Pagination;
  readonly loading: boolean;
  readonly error?: ResponseError;
}
