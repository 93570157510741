import React, { useEffect, useMemo, useRef, useState } from 'react';
import { VariableSizeGrid as Grid } from 'react-window';

import { Cell as CellElem } from './styles';

const Cell: React.FC<any> = ({ columnIndex, data, rowIndex, style, rawData, mergedColumns, onClick }) => {
  const { hoveredRowIndex, setHoveredRowIndex } = data;

  let className = hoveredRowIndex === rowIndex ? "CellHovered" : "Cell";

  return (
    <CellElem
      className={`virtual-table-cell ${className}`}
      style={{ ...style, cursor: onClick ? 'pointer' : 'auto' }}
      onMouseEnter={() => setHoveredRowIndex(rowIndex)}
      onClick={() => onClick && onClick(rawData[rowIndex])}
    >
      {(rawData[rowIndex] as any)[(mergedColumns as any)[columnIndex].dataIndex]}
    </CellElem>
  );
};

const Body: React.FC<any> = ({ rawData, scrollbarSize, tRef, onScroll, tableWidth, mergedColumns, scroll, onClick }) => {
  const gridRef = useRef<any>();

  const resetVirtualGrid = () => {
    gridRef?.current?.resetAfterIndices({
      columnIndex: 0,
      shouldForceUpdate: true,
    });
  };

  useEffect(() => resetVirtualGrid, [tableWidth]);

  const [connectObject] = useState<any>(() => {
    const obj = {};
    Object.defineProperty(obj, 'scrollLeft', {
      get: () => {
        if (gridRef.current) {
          return gridRef.current?.state?.scrollLeft;
        }
        return null;
      },
      set: (scrollLeft: number) => {
        if (gridRef.current) {
          gridRef.current.scrollTo({ scrollLeft });
        }
      },
    });

    return obj;
  });

  tRef.current = connectObject;

  const totalHeight = rawData.length * 54;
  const [hoveredRowIndex, setHoveredRowIndex] = useState(null);
  const itemData = useMemo(
    () => ({
      hoveredRowIndex,
      setHoveredRowIndex
    }),
    [hoveredRowIndex]
  );

  return (
    <Grid
      ref={gridRef}
      className="virtual-grid"
      itemData={itemData}
      columnCount={mergedColumns.length}
      columnWidth={(index: number) => {
        const { width } = mergedColumns[index];
        return totalHeight > scroll!.y! && index === mergedColumns.length - 1
          ? ((width) as number) - scrollbarSize - 1
          : ((width) as number);
      }}
      height={scroll!.y as number}
      rowCount={rawData.length}
      rowHeight={() => 54}
      width={tableWidth}
      onScroll={({ scrollLeft }: { scrollLeft: number }) => {
        onScroll({ scrollLeft });
      }}
    >
      {({
        columnIndex,
        rowIndex,
        style,
        data
      }: {
        columnIndex: number;
        rowIndex: number;
        style: React.CSSProperties;
        data: any
      }) => (
        <Cell
          columnIndex={columnIndex}
          data={data}
          rowIndex={rowIndex}
          style={style}
          rawData={rawData}
          mergedColumns={mergedColumns}
          onClick={onClick}
        />
      )}
    </Grid>
  );
}

export default Body;
