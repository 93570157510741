import { Col, Form, Row } from 'antd';
import React, { Dispatch, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Action } from 'typesafe-actions';
import { useHistory } from 'react-router-dom';
import { ApplicationState } from '../../store';
import { Container, ResumeContainer } from './styles';
import Address from './Address';
import PaymentSelect from './PaymentSelect';
import CartList from './CartList';
import BudgetFields from './BudgetFields';
import Observation from './Observation/inde';
import Resume from './Resume';
import { requestConfigs, setFields } from '../../store/ducks/shoppingCart/actions';
import EmptyCart from '../../components/EmptyCart';
import BudgetDisplay from '../../components/BudgetDisplay';

export interface KeyValue {
  key: string;
  value: number | string;
}

const ShoppingCart: React.FC = () => {
  const dispatch = useDispatch<Dispatch<Action>>();
  const [form] = Form.useForm();
  const history = useHistory();
  const layout = useSelector((state: ApplicationState) => state.layout.data);
  const token = useSelector((state: ApplicationState) => state.auth.token);
  const config = useSelector((state: ApplicationState) => state.shoppingCart.config);
  const isBudget = useSelector((state: ApplicationState) => state.shoppingCart.budgetVisible);
  const quantity = useSelector((state: ApplicationState) => state.shoppingCart.totalQuantity);
  const editId = useSelector((state: ApplicationState) => state.shoppingCart.editId);
  const [pdfVisible, setPdfVisible] = useState<boolean>(false);

  useEffect(() => {
    if (config) return;
    dispatch(requestConfigs());
  }, [dispatch, token, config]);

  const onValuesChange = (_value: any, values: any) => {
    const arr: KeyValue[] = [];

    Object.keys(values).forEach((item) => {
      if (!values[item]) {
        return;
      }

      arr.push({
        key: item,
        value:
          values[item] instanceof moment
            ? moment(values[item]).format('DD/MM/YYYY HH:mm:ss')
            : values[item],
      });
    });

    dispatch(setFields(arr));
  };

  return (
    <>
      {quantity <= 0 && !editId ? (
        <EmptyCart handleButton={() => history.push('/home/novo-pedido')} />
      ) : (
        <Row gutter={[20, 20]}>
          <Col
            xs={{ span: 24, order: 2 }}
            sm={{ span: 24, order: 2 }}
            md={{ span: 16, order: 1 }}
            lg={{ span: 16, order: 1 }}
            xl={{ span: 16, order: 1 }}
          >
            <Row gutter={[0, 20]}>
              <Col span={24}>
                <Container>
                  <Address />
                </Container>
              </Col>
              <Col span={24}>
                <Container>
                  <PaymentSelect form={form} onValuesChange={onValuesChange} />
                </Container>
              </Col>
              {isBudget && (
                <Col span={24}>
                  <Container>
                    <BudgetFields form={form} onValuesChange={onValuesChange} />
                  </Container>
                </Col>
              )}
              <Col span={24}>
                <Container>
                  <Observation form={form} onValuesChange={onValuesChange} />
                </Container>
              </Col>
              <Col span={24}>
                <Container>
                  <CartList />
                </Container>
              </Col>
            </Row>
          </Col>
          <Col
            xs={{ span: 24, order: 1 }}
            sm={{ span: 24, order: 1 }}
            md={{ span: 8, order: 2 }}
            lg={{ span: 8, order: 2 }}
            xl={{ span: 8, order: 2 }}
          >
            <ResumeContainer layout={layout}>
              <Resume form={form} onValuesChange={onValuesChange} setPdfVisible={setPdfVisible} />
            </ResumeContainer>
          </Col>
        </Row>
      )}
      <BudgetDisplay title="Orçamento" visible={pdfVisible} setVisible={setPdfVisible} />
    </>
  );
};

export default ShoppingCart;
