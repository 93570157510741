import React, { Dispatch } from 'react';
import { Button, List, Avatar } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Action } from 'typesafe-actions';
import { useHistory } from 'react-router-dom';
import { LoadingOutlined, RightOutlined } from '@ant-design/icons';

import { ApplicationState } from '../../../store';
import { requestCampaigns } from '../../../store/ducks/campaigns/actions';
import { CampaignDescription, CampaignTitle } from '../styles';
import ServerError from '../../../components/ServerError';
import { colors } from '../../../utils/constants';

const CampaignsList: React.FC = () => {
  const data = useSelector((state: ApplicationState) => state.campaigns.dataCampaigns);
  const error = useSelector((state: ApplicationState) => state.campaigns.errorCampaigns);
  const pagination = useSelector((state: ApplicationState) => state.campaigns.paginationCampaigns);
  const loading = useSelector((state: ApplicationState) => state.campaigns.loadingCampaigns);
  const layout = useSelector((state: ApplicationState) => state.layout.data);
  const history = useHistory();
  const dispatch = useDispatch<Dispatch<Action>>();

  return (
    <>
      {error ? (
        <ServerError {...error} />
      ) : (
        <List
          style={{
            backgroundColor: 'white',
            padding: '20px',
            borderRadius: '5px',
            boxShadow: `1px 2px 2px 2px ${colors.grey}`,
          }}
          itemLayout="horizontal"
          size="small"
          loading={{
            style: { color: layout?.color.default },
            spinning: loading,
            size: 'large',
            delay: 500,
            indicator: <LoadingOutlined style={{ fontSize: 24 }} spin />,
          }}
          pagination={{
            ...pagination,
            size: 'small',
            onChange: (page, pageSize) => {
              dispatch(
                requestCampaigns({
                  current: page,
                  pageSize: pageSize || 0,
                  total: pagination?.total || 0,
                })
              );
            },
          }}
          dataSource={data}
          renderItem={(item) => (
            <List.Item
              key={item.campaignId}
              actions={[
                <Button
                  data-cy="btn-openCampaign"
                  type="text"
                  icon={<RightOutlined />}
                  onClick={() => history.push(`campanhas/${item.campaignId}`)}
                />,
              ]}
            >
              <List.Item.Meta
                avatar={
                  <Avatar
                    shape="square"
                    size={120}
                    src={item.type === 'BASE64' ? `data:image/png;base64,${item.image}` : item.link}
                  />
                }
                title={
                  <CampaignTitle
                    onClick={() => history.push(`campanhas/${item.campaignId}`)}
                    layout={layout}
                  >
                    {item.title}
                  </CampaignTitle>
                }
                description={<CampaignDescription>{item.description}</CampaignDescription>}
              />
            </List.Item>
          )}
        />
      )}
    </>
  );
};

export default CampaignsList;
