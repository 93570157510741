import { Spin, Tooltip } from 'antd';
import React, { Dispatch, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { matchPath, Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { Action } from 'typesafe-actions';
import {
  LoadingOutlined,
  LeftOutlined,
  PicLeftOutlined,
  PlusCircleOutlined,
  FormOutlined,
  WalletOutlined,
  FileTextOutlined,
  SolutionOutlined,
} from '@ant-design/icons';
import ReactGA from 'react-ga4';
import { PRIVATE_ROUTES } from '../../../routes/routes';
import { RouteType } from '../../../routes/routeType';
import { ApplicationState } from '../../../store';
import { loadRequest } from '../../../store/ducks/user/actions';
import { MESSAGES } from '../../../utils/constants';
import { hasPermission } from '../../../utils/permissions';
import ServerError from '../../ServerError';
import Navbar from '../../Navbar';
import Sidebar from '../../Sidebar';
import {
  Container,
  PageContainer,
  PageContent,
  Title,
  Header,
  WhatsAppButton,
  WhatsAppIcon,
} from './styles';
import { clearCart } from '../../../store/ducks/shoppingCart/actions';
import { clearOrderValues } from '../../../store/ducks/orderFields/actions';
import Scrollbars from 'react-custom-scrollbars';
import { Size, useWindowSize } from '../../../customHooks';
import { loadDashboardMenuRequest } from '../../../store/ducks/dashboardMenus/actions';
import { additionalScreensRequest } from '../../../store/ducks/additionalScreens/actions';

const BasicLayout: React.FC<any> = (props) => {
  const token = useSelector((state: ApplicationState) => state.auth.token);
  const roles = useSelector((state: ApplicationState) => state.user.data?.roles);
  const { data: layout, scroll } = useSelector((state: ApplicationState) => state.layout);
  const additionalMenus = useSelector(
    (state: ApplicationState) => state.additionalScreens.screens
  );
  const dashboardMenu = useSelector((state: ApplicationState) => state.dashboardMenu.data);
  const loading = useSelector((state: ApplicationState) => state.layout.loading);
  const [pageview, setPageview] = useState<string>('');
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const dispatch = useDispatch<Dispatch<Action>>();
  const history = useHistory();
  const location = useLocation();
  const size: Size = useWindowSize();

  const ref: any = useRef();

  const pathname = props.match.path;

  useEffect(() => {
    if (!process.env.REACT_APP_GOOGLE_ID) return;
    if (pathname === '*') {
      setPageview('/not-fount');
    } else setPageview(pathname);
  }, [pathname]);

  useEffect(() => {
    if (!process.env.REACT_APP_GOOGLE_ID) return;
    ReactGA.send(pageview);
  }, [pageview]);

  useEffect(() => {
    if (size.width && size.width < 1024) {
      setCollapsed(true);
    }
  }, [size.width]);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  useEffect(() => {
    if (token) {
      return;
    }
    dispatch(clearCart());
    dispatch(clearOrderValues());
  }, [dispatch, token]);

  useEffect(() => {
    dispatch(loadRequest(token));
  }, [dispatch, token]);

  useEffect(() => {
    if (!ref) return;
    ref?.current.scrollTop(0);
  }, [location, ref, scroll]);

  useEffect(() => {
    dispatch(loadDashboardMenuRequest());
    dispatch(additionalScreensRequest());
  }, [dispatch, token]);

  const removeSlash = (initialString: string) => {
    if (initialString[initialString.length - 1] === '/')
      initialString = initialString.substring(0, initialString.length - 1);
    return initialString;
  };

  const getMenuIcon = (iconName?: string) => {
    switch (iconName) {
      case 'PicLeftOutlined':
        return <PicLeftOutlined style={{ color: 'white', marginRight: '10px' }} />;
      case 'PlusCircleOutlined':
        return <PlusCircleOutlined style={{ color: 'white', marginRight: '10px' }} />;
      case 'FormOutlined':
        return <FormOutlined style={{ color: 'white', marginRight: '10px' }} />;
      case 'WalletOutlined':
        return <WalletOutlined style={{ color: 'white', marginRight: '10px' }} />;
      case 'FileTextOutlined':
        return <FileTextOutlined style={{ color: 'white', marginRight: '10px' }} />;
      default:
        return <SolutionOutlined style={{ color: 'white', marginRight: '10px' }} />;
    }
  };

  const getWindowId = () => {
    const currentPath = removeSlash(location.pathname);
    const pathArray = currentPath.split('/');
    return pathArray[pathArray.length - 2];
  };

  const match = (route: string) => {
    return matchPath(location.pathname, {
      path: route,
      exact: true,
      strict: false,
    });
  };

  const getRoute = () => {
    return PRIVATE_ROUTES.find((route) => match(`${route.layout}${route.path}`));
  };

  const path = getRoute();

  const renderRoutes = (prop: RouteType, index: number) => {
    return (
      <Route
        path={`${prop.layout}${prop.path}`}
        component={
          !hasPermission(prop.roles, roles)
            ? () => <ServerError status={403} message={MESSAGES.NOT_AUTHRIZED} />
            : prop.component
        }
        key={index}
        exact
      />
    );
  };

  const getTitle = (name?: string) => {
    if (name === 'Dashboard')
      return dashboardMenu.find((item) => item.codDsb.toString() === getWindowId())?.title;
    if (!name)
      return additionalMenus?.find(({ id }) => id.toString() === getWindowId())?.description;
    return name;
  };

  const getUrlWhatsApp = () => {
    if (layout?.whatsApp?.message) {
      return `https://api.whatsapp.com/send?phone=55${layout?.whatsApp.telephone}&text=${layout?.whatsApp.message}`;
    }
    return `https://api.whatsapp.com/send?phone=55${layout?.whatsApp?.telephone}`;
  };
  return (
    <>
      <Spin
        style={{ color: layout?.color.default }}
        spinning={loading}
        size="large"
        delay={500}
        indicator={<LoadingOutlined />}
      >
        <Container>
          <Sidebar toggleCollapsed={toggleCollapsed} collapsed={collapsed} />
          <PageContainer>
            <Navbar toggleCollapsed={toggleCollapsed} />
            <Scrollbars style={{ paddingLeft: '5px' }} ref={ref}>
              <Header
                data-cy="header"
                layout={layout}
                title={
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {path?.icon ? (
                      <path.icon style={{ color: 'white', marginRight: '10px' }} />
                    ) : (
                      <>
                        {path?.name ? (
                          <LeftOutlined
                            style={{ color: 'white', marginRight: '10px' }}
                            onClick={() => path?.additionalRouteData ? history.push(path.additionalRouteData.to, { from: path.additionalRouteData.from }) : history.goBack()}
                          />
                        ) : (
                          getMenuIcon(
                            additionalMenus?.find(({ id }) => id.toString() === getWindowId())?.icon
                          )
                        )}
                      </>
                    )}
                    <Title data-cy="pageTitle">{getTitle(path?.name)}</Title>
                  </div>
                }
              />
              <PageContent>
                <Switch>
                  {PRIVATE_ROUTES.map(renderRoutes)}
                  <Redirect to="/home/404" />
                </Switch>
              </PageContent>
            </Scrollbars>
          </PageContainer>
          {!token && (
            <Redirect to={{ pathname: layout?.existsProductShowcase ? '/showcase' : '/login' }} />
          )}
        </Container>
      </Spin>
      {layout?.whatsApp?.show && (
        <Tooltip placement="right" title="Entre em contato">
          <WhatsAppButton
            icon={<WhatsAppIcon />}
            type="primary"
            size="large"
            shape="circle"
            onClick={() => window.open(getUrlWhatsApp(), '_blank')}
          />
        </Tooltip>
      )}
    </>
  );
};

export default BasicLayout;
