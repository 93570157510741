export enum AuthTypes {
  'LOGIN_REQUEST' = '@auth/LOGIN_REQUEST',
  'LOGIN_SUCCESS' = '@auth/LOGIN_SUCCESS',
  'LOGIN_FAILURE' = '@auth/LOGIN_FAILURE',

  'UPDATE_REQUEST' = '@auth/UPDATE_REQUEST',
  'UPDATE_SUCCESS' = '@auth/UPDATE_SUCCESS',
  'UPDATE_FAILURE' = '@auth/UPDATE_FAILURE',

  'REFRESH_REQUEST' = '@auth/REFRESH_REQUEST',
  'REFRESH_SUCCESS' = '@auth/REFRESH_SUCCESS',
  'REFRESH_FAILURE' = '@auth/REFRESH_FAILURE',

  'PASSWORD_REQUEST' = '@auth/PASSWORD_REQUEST',
  'PASSWORD_SUCCESS' = '@auth/PASSWORD_SUCCESS',
  'PASSWORD_FAILURE' = '@auth/PASSWORD_FAILURE',

  'CHANGE_PASSWORD_REQUEST' = '@auth/CHANGE_PASSWORD_REQUEST',
  'CHANGE_PASSWORD_SUCCESS' = '@auth/CHANGE_PASSWORD_SUCCESS',
  'CHANGE_PASSWORD_FAILURE' = '@auth/CHANGE_PASSWORD_FAILURE',

  'NEW_PASSWORD_REQUEST' = '@auth/NEW_PASSWORD_REQUEST',
  'NEW_PASSWORD_SUCCESS' = '@auth/NEW_PASSWORD_SUCCESS',
  'NEW_PASSWORD_FAILURE' = '@auth/NEW_PASSWORD_FAILURE',

  'CLEAN_STATE' = '@auth/CLEAN_STATE',
}

export interface PartnerAuth {
  idPartner?: number;
  idContact?: number;
}

export interface Auth {
  userName: string;
  password: string;
}

export interface AuthState {
  readonly token: string;
  readonly refreshToken: string;
  readonly forgotPasswordMessage: '';
  readonly loginLoading: boolean;
  readonly loginError: boolean;
  readonly passwordLoading: boolean;
  readonly refreshLoading: boolean;
  readonly passwordError: boolean;
  readonly errorMessage: string;
  readonly statusFailure?: number;
  readonly userName?: string;
  readonly changePasswordMessage: '';
  readonly changePasswordLoading: boolean;
  readonly changePasswordError: boolean;
  readonly successMessage: '';
  readonly newPasswordLoading: boolean;
  readonly newPasswordError: boolean;
}
