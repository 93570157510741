import { ResponseError } from '../../../services/api';
import { Config, Data, Type } from '../../../utils/newTableHelper';

export enum NewServiceOrderTypes {
  'LOAD_REQUEST' = '@newServiceOrder/LOAD_REQUEST',
  'LOAD_SUCCESS' = '@newServiceOrder/LOAD_SUCCESS',
  'LOAD_FAILURE' = '@newServiceOrder/LOAD_FAILURE',

  'FORM_REQUEST' = '@newServiceOrder/FORM_REQUEST',
  'FORM_SUCCESS' = '@newServiceOrder/FORM_SUCCESS',
  'FORM_FAILURE' = '@newServiceOrder/FORM_FAILURE',

  'LIST_REQUEST' = '@newServiceOrder/LIST_REQUEST',
  'LIST_SUCCESS' = '@newServiceOrder/LIST_SUCCESS',
  'LIST_FAILURE' = '@newServiceOrder/LIST_FAILURE',

  'SEND_REQUEST' = '@newServiceOrder/SEND_REQUEST',
  'SEND_SUCCESS' = '@newServiceOrder/SEND_SUCCESS',
  'SEND_FAILURE' = '@newServiceOrder/SEND_FAILURE',

  'SET_PARAMS' = '@newServiceOrder/SET_PARAMS',

  'SET_REQUIRED_PARAMS' = '@newServiceOrder/SET_REQUIRED_PARAMS',

  'SET_ATTACHMENT' = '@newServiceOrder/SET_ATTACHMENT',
}

export type Step = 'SELECT_TEMPLATE' | 'FORM' | 'SELECT_PRODUCT' | 'SELECT_SERIES';
export interface Filter {
  name: string;
  value: any;
}
export interface Pagination {
  current: number;
  total: number;
  pageSize: number;
}

export interface Sorter {
  name: string;
  direction: 'ASC' | 'DESC';
}
export interface StepValues {
  step: Step;
  id: number;
  name: string;
  value: any;
}

export interface Attachment {
  attachment: string;
  description: string;
}
export interface SendServiceOrder {
  idTemplace: number;
  values: StepValues[];
  attachment: Attachment;
}
export interface ServiceOrderTemplateStep {
  id: Step;
  label: string;
  requiredFields: string[];
  type: 'FORM' | 'LIST';
}

export interface ServiceOrderTemplate {
  description: string;
  enableAttach: boolean;
  fileTypes: string[];
  id: number;
  maximumFileSize: number;
  steps: ServiceOrderTemplateStep[];
}

export interface FormFields {
  id: number;
  idField: number;
  fieldName: string;
  description: string;
  placeholder?: string;
  position: number;
  enable: boolean;
  tableName: string;
  foreignEntity?: string | null;
  fieldType: Type;
  defaultValue?: any;
  visible: boolean;
  required: boolean;
}
export interface Params {
  step?: Step;
  id?: number;
  name: string;
  value: any;
}

export interface NewServiceOrderState {
  readonly data: ServiceOrderTemplate[];
  readonly loading: boolean;
  readonly stepForm: FormFields[];
  readonly stepConfig?: Config;
  readonly stepData: Data[];
  readonly error?: ResponseError;
  readonly pagination: Pagination;
  readonly params: Params[];
  readonly requiredParams: Params[];
  readonly sendLoading: boolean;
  readonly sendError?: ResponseError;
  readonly newId?: number;
  readonly attachments?: Attachment[];
}
