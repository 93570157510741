import { action } from 'typesafe-actions';
import { ResponseError } from '../../../services/api';
import { Pagination, FaqTypes, Faq, Fields, Call, SendCall } from './types';

export const requestCategory = () => action(FaqTypes.CATEGORY_REQUEST);
export const successCategory = (data: any[]) => action(FaqTypes.CATEGORY_SUCCESS, { data });
export const failureCategory = (error: ResponseError) =>
  action(FaqTypes.CATEGORY_FAILURE, { error });

export const requestFaq = (pagination?: Pagination, fields?: Fields[]) =>
  action(FaqTypes.FAQ_REQUEST, { pagination, fields });
export const successFaq = (pagination: Pagination, data: Faq[]) =>
  action(FaqTypes.FAQ_SUCCESS, { pagination, data });
export const failureFaq = (error: ResponseError) => action(FaqTypes.FAQ_FAILURE, { error });

export const requestCall = (pagination?: Pagination) =>
  action(FaqTypes.CALL_REQUEST, { pagination });
export const successCall = (pagination: Pagination, data: Call[]) =>
  action(FaqTypes.CALL_SUCCESS, { pagination, data });
export const failureCall = (error: ResponseError) => action(FaqTypes.CALL_FAILURE, { error });

export const requestSendCall = (sendCall: SendCall) =>
  action(FaqTypes.SEND_CALL_REQUEST, { sendCall });
export const successSendCall = () => action(FaqTypes.SEND_CALL_SUCCESS);
export const failureSendCall = (error: ResponseError) =>
  action(FaqTypes.SEND_CALL_FAILURE, { error });
