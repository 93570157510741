import { put } from 'redux-saga/effects';
import { loadSuccess } from './actions';

export function* loadHomeProduct(): any {
  yield put(loadSuccess([], []));
  // try {
  //   const state: ApplicationState = yield select();
  //   const headers = requestHeaders(state.auth.token);
  //   const config = yield call(api.get, 'v2/produtos/config', { headers: headers });
  //   const response = yield call(
  //     api.post,
  //     `produtos`,
  //     {
  //       informativos: state.orderFields.values.length === 0 ? undefined : state.orderFields.values,
  //     },
  //     { headers: headers }
  //   );

  //   const tableData = yield call(dataToTableData, response.data.content, [...config.data.campos]);
  //   const data = tableData.map((item: any) => {
  //     return {
  //       ...item,
  //       SELECTED_UNITY: item?.UNIDADE[0].key,
  //     };
  //   });
  //   yield put(loadSuccess(data, [...config.data.campos]));
  // } catch (err: any) {
  //   yield put(err?.response?.status !== 401 ? loadFailure(errorHandler(err)) : refreshRequest());
  // }
}
