import { Reducer } from 'redux';

import { ProductsState, ProductsTypes } from './types';

const INITIAL_STATE: ProductsState = {
  pagination: {
    current: 1,
    pageSize: 12,
    total: 12,
  },
  data: [],
  categories: [],
  loading: false,
  loadingCategories: false,
  loadingConfig: false,
  filter: [],
  search: '',
};

const reducer: Reducer<ProductsState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ProductsTypes.RESET_SORTER:
      return {
        ...state,
        sorter: undefined,
      };

    case ProductsTypes.RESET_ADVANCED_FILTER:
      return {
        ...state,
        advancedFilters: undefined,
      };

    case ProductsTypes.SET_MODE:
      return {
        ...state,
        mode: action.payload.mode,
      };

    case ProductsTypes.CLEAR_SEARCH:
      return {
        ...state,
        search: '',
      };

    case ProductsTypes.LOAD_REQUEST:
      return {
        ...state,
        loading: true,
        loadingConfig: true,
        filter: action.payload.filter || state.filter,
        advancedFilters: action.payload.advancedFilters || state.advancedFilters,
        sorter: action.payload.sorter || state.sorter,
        search: action.payload.search || state.search,
        pagination: action.payload.pagination || state.pagination,
      };
    case ProductsTypes.FAVORITE_REQUEST:
    case ProductsTypes.SET_UNITY:
      return {
        ...state,
        loading: true,
        loadingConfig: true,
      };

    case ProductsTypes.SET_FILTER:
      return {
        ...state,
        filter: action.payload.filter,
      };

    case ProductsTypes.CONFIG_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loadingConfig: false,
        errorProducts: undefined,
      };
    case ProductsTypes.LOAD_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
        errorProducts: undefined,
      };
    case ProductsTypes.LOAD_FAILURE:
    case ProductsTypes.FAVORITE_FAILURE:
      return {
        ...state,
        data: [],
        loading: false,
        loadingConfig: false,
        errorProducts: action.payload.error,
      };

    case ProductsTypes.FAVORITE_SUCCESS:
    case ProductsTypes.UNITY_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        loadingConfig: false,
        data: action.payload.data,
      };

    case ProductsTypes.LOAD_REQUEST_CATEGORY:
      return {
        ...state,
        loadingCategories: true,
      };
    case ProductsTypes.LOAD_SUCCESS_CATEGORY:
      return {
        ...state,
        loadingCategories: false,
        errorCategory: undefined,
        categories: action.payload.data,
      };
    case ProductsTypes.LOAD_FAILURE_CATEGORY:
      return {
        ...state,
        categories: [],
        loadingCategories: false,
        error: action.payload.error,
      };

    case ProductsTypes.ADVANCED_FIELDS_REQUEST:
      return {
        ...state,
        loadingAdvancedFields: true,
      };

    case ProductsTypes.ADVANCED_FIELDS_SUCCESS:
      return {
        ...state,
        loadingAdvancedFields: false,
        advancedFields: action.payload.data,
      };

    case ProductsTypes.ADVANCED_FIELDS_FAILURE:
      return {
        ...state,
        loadingAdvancedFields: false,
        advancedFields: [],
        error: action.payload.error,
      };

    default:
      return state;
  }
};

export default reducer;
