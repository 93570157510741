import React from 'react';
import AreaChart from '../../../components/Charts/AreaChart';
import AreaRrangeChart from '../../../components/Charts/AreaRrangeChart';
import BarChart from '../../../components/Charts/BarChart';
import BubbleChart from '../../../components/Charts/BubbleChart';
import ColumnChart from '../../../components/Charts/ColumnChart';
import DonutChart from '../../../components/Charts/DonutChart';
import EmptyChart from '../../../components/Charts/EmptyChart';
import FunnelChart from '../../../components/Charts/FunnelChart';
import GaugeChart from '../../../components/Charts/GaugeChart';
import LineChart from '../../../components/Charts/LineChart';
import MixChart from '../../../components/Charts/MixChart';
import PieChart from '../../../components/Charts/PieChart';
import RadarChart from '../../../components/Charts/RadarChart';
import ScatterChart from '../../../components/Charts/ScatterChart';
import { HorizontalAxis, Metadata, Serie, SeriesGroup } from '../../../store/ducks/dashboard/types';
import DashContainer from '../DashContainer';

interface Props {
  series?: Serie[];
  seriesGroup?: SeriesGroup[];
  alertColors?: { value: number; color: string }[];
  minValue?: number;
  maxValue?: number;
  metaData?: Metadata[];
  value?: number;
  type?: string;
  field?: string;
  nameField?: string;
  valueField?: string;
  angleField?: string;
  colorField?: string;
  xField?: string;
  yField?: string;
  horizontalAxis?: HorizontalAxis;
  title?: string;
  data?: any[];
  width?: number;
  slider?: {
    start: number;
    end: number;
  };
  fieldType?: string;
  onClick(values: any): void;
}
const Charts: React.FC<Props> = ({
  value,
  maxValue,
  minValue,
  valueField,
  alertColors,
  type = '',
  angleField = '',
  colorField = '',
  xField = '',
  yField = '',
  title = '',
  data = [],
  width = 100,
  slider,
  fieldType,
  nameField = '',
  field = '',
  series = [],
  seriesGroup,
  metaData = [],
  horizontalAxis,
  onClick
}) => {
  switch (type) {
    case 'pizza':
      return (
        <DashContainer width={width}>
          <PieChart title={title} data={data} angleField={field} colorField={nameField} onClick={onClick} />
        </DashContainer>
      );
    case 'donut':
      return (
        <DashContainer width={width}>
          <DonutChart title={title} data={data} angleField={angleField} colorField={colorField} onClick={onClick} />
        </DashContainer>
      );
    case 'column':
      return (
        <DashContainer width={width}>
          <ColumnChart
            title={title}
            series={series}
            data={data}
            xField={xField}
            yField={yField}
            slider={slider}
            seriesGroup={seriesGroup}
            horizontalAxis={horizontalAxis}
            onClick={onClick}
          />
        </DashContainer>
      );
    case 'line':
      return (
        <DashContainer width={width}>
          <LineChart
            series={series}
            title={title}
            data={data}
            xField={xField}
            yField={yField}
            slider={slider}
            onClick={onClick}
          />
        </DashContainer>
      );
    case 'bubble':
      return (
        <DashContainer width={width}>
          <BubbleChart
            title={title}
            data={data}
            xField={xField}
            yField={yField}
            metaData={metaData}
            onClick={onClick}
          />
        </DashContainer>
      );
    case 'bar':
      return (
        <DashContainer width={width}>
          <BarChart
            title={title}
            data={data}
            series={series}
            xField={xField}
            yField={yField}
            slider={slider}
            seriesGroup={seriesGroup}
            onClick={onClick}
          />
        </DashContainer>
      );
    case 'radar':
      return (
        <DashContainer width={width}>
          <RadarChart title={title} data={data} xField={xField} series={series} />
        </DashContainer>
      );
    case 'scatter':
      return (
        <DashContainer width={width}>
          <ScatterChart title={title} data={data} xField={xField} yField={yField} />
        </DashContainer>
      );
    case 'area-range':
      return (
        <DashContainer width={width}>
          <AreaRrangeChart title={title} data={data} xField={xField} series={series} onClick={onClick} />
        </DashContainer>
      );
    case 'gauge':
      return (
        <DashContainer width={width}>
          <GaugeChart
            title={title}
            value={value}
            valueField={valueField}
            maxValue={maxValue}
            minValue={minValue}
            alertColors={alertColors}
            onClick={onClick}
          />
        </DashContainer>
      );
    case 'mix':
      return (
        <DashContainer width={width}>
          <MixChart
            xField={xField}
            title={title}
            data={data}
            series={series}
            seriesGroup={seriesGroup}
            onClick={onClick}
          />
        </DashContainer>
      );
    case 'funnel':
      return (
        <DashContainer width={width}>
          <FunnelChart
            title={title}
            data={data}
            nameField={nameField}
            field={field}
            fieldType={fieldType}
            onClick={onClick}
          />
        </DashContainer>
      );
    case 'area':
      return (
        <DashContainer width={width}>
          <AreaChart
            series={series}
            title={title}
            data={data}
            xField={xField}
            slider={slider}
            onClick={onClick}
          />
        </DashContainer>
      );
    default:
      return (
        <DashContainer width={width}>
          <EmptyChart type={type} />
        </DashContainer>
      );
  }
};

export default Charts;
