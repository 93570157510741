import { Image } from 'antd';
import styled from 'styled-components';
import { colors } from '../../utils/constants';
export const ImageContainer = styled.div`
  width: 320px;
  height: 320px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  @media only screen and (max-width: 1024px) {
    margin-bottom: 20px;
  }
`;

export const ThumbContent = styled(Image)<{ current?: boolean }>`
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  opacity: ${({ current }) => (current ? 1 : 0.5)};
  cursor: pointer;
  :hover {
    opacity: 1;
  }
`;

export const ThumbContainer = styled.div`
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${colors.grayBackgroud};
  :hover {
    border: 2px solid ${colors.grayBackgroud};
  }
`;

export const ImageContent = styled(Image)`
  max-width: 100%;
  max-height: 100%;
`;
