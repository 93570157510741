import { Button } from 'antd';
import styled from 'styled-components';
import { colors } from '../../utils/constants';

export const FullWidthDiv = styled.div`
  width: 100%;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const FormContent = styled.div`
  background-color: white;
  border-radius: 5px;
  box-shadow: 1px 2px 2px 2px ${colors.grey};
  padding: 20px;
  margin-bottom: 20px;
`;

export const WhiteContainer = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 1px 2px 2px 2px ${colors.grey};
`;

export const BackButton = styled(Button)`
  margin-right: 10px;
`;
