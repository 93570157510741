import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../store';
import { Pagination } from '../../store/ducks/products/types';
import { StyledTable } from './styles';
import { Table } from 'antd';
import { TableRowSelection } from 'antd/es/table/interface';
import { Size, useWindowSize } from '../../customHooks';
import CardList from './CardList';
import { CSSProperties } from 'styled-components';
import DataSet from '@antv/data-set';
import mask from '../../utils/masks';
interface Props {
  columns: any;
  data?: any[];
  loading: boolean;
  onChange: any;
  pagination?: Pagination;
  onRowClick?: any;
  onRowDoubleClick?: any;
  rowSelection?: TableRowSelection<any>;
  noResposiveMode?: boolean;
  style?: CSSProperties;
  noShadow?: boolean;
  rowClassName?: any;
}

const CustomTable: React.FC<Props> = ({
  onChange,
  columns,
  data,
  loading,
  pagination,
  onRowClick,
  onRowDoubleClick,
  rowSelection,
  noResposiveMode,
  style,
  noShadow,
  rowClassName,
}) => {
  const layout = useSelector((state: ApplicationState) => state.layout.data);
  const size: Size = useWindowSize();

  return (size?.width ?? 0) >= 1024 || noResposiveMode ? (
    <StyledTable noShadow={noShadow} style={style} layout={layout}>
      <Table
        rowClassName={rowClassName}
        size="small"
        rowSelection={rowSelection}
        style={{ cursor: onRowClick ? 'pointer' : '' }}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              if (onRowClick) {
                onRowClick(record, rowIndex, event);
              }
            },
            onDoubleClick: (event) => {
              if (onRowDoubleClick) {
                onRowDoubleClick(record, rowIndex, event);
              }
            },
          };
        }}
        tableLayout="auto"
        scroll={{ x: 400 }}
        loading={{
          style: { color: layout?.color.default },
          spinning: loading,
          size: 'large',
          delay: 500,
          indicator: <LoadingOutlined style={{ fontSize: 24 }} spin />,
        }}
        onChange={onChange}
        pagination={
          !pagination ? false : { ...pagination, pageSizeOptions: ['12', '24', '48', '96'] }
        }
        showSorterTooltip={false}
        columns={columns}
        dataSource={data}
        summary={
          columns.some((item: any) => item.showSum)
            ? (pageData: any) => {
              const { DataView } = DataSet;

              const dv = new DataView().source(pageData);

              let fields: any[] = [];

              pageData.forEach((item: any) => {
                fields = Object.keys(item).map((index: any) => {
                  return columns.find((column: any) => column?.dataIndex === index);
                });
              });
              dv.transform({
                type: 'fold',
                fields: fields.filter((item) => !!item).map((item) => item.dataIndex),
                key: 'name',
                value: 'value',
              });
              dv.transform({
                type: 'map',
                callback(row) {
                  row.value = row.value ? row.value : 0;
                  return row;
                },
              });

              dv.transform({
                type: 'aggregate',
                fields: ['value'],
                operations: ['sum'],
                as: ['total'],
                groupBy: ['name'],
              });

              const ordered = dv.rows
                .map((row) => {
                  const field = fields.find((field) => field?.dataIndex === row.name);
                  return {
                    name: row.name,
                    total: row.total,
                    ...field,
                  };
                })
                .sort((a: any, b: any) => {
                  if (a.position < b.position) {
                    return -1;
                  }
                  if (a.position > b.position) {
                    return 1;
                  }
                  return 0;
                });

              return (
                <Table.Summary>
                  <Table.Summary.Row>
                    {ordered.map((row, index) => {
                      return row?.showSum ? (
                        <Table.Summary.Cell key={index} index={index}>
                          <strong>
                            {row?.render
                              ? row.render(row.total)
                              : mask(
                                (row?.mask === 'currency' ? '' : row?.mask) || row.type,
                                row.total
                              )}
                          </strong>
                        </Table.Summary.Cell>
                      ) : (
                        <Table.Summary.Cell key={index} index={index} />
                      );
                    })}
                    {columns.length !== dv.rows.length && <Table.Summary.Cell index={35} />}
                  </Table.Summary.Row>
                </Table.Summary>
              );
            }
            : undefined
        }
      />
    </StyledTable>
  ) : (
    <CardList
      data={data}
      columns={columns}
      loading={loading}
      onChange={onChange}
      pagination={pagination}
    />
  );
};

export default CustomTable;
