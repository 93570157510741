import { ResponseError } from "../../../services/api";
import { Type } from "../../../utils/newTableHelper";

export enum BudgetFieldsTypes {
  'LOAD_REQUEST' = '@budgetFields/LOAD_REQUEST',
  'SET_VALUES' = '@budgetFields/SET_VALUES',
  'LOAD_SUCCESS' = '@budgetFields/LOAD_SUCCESS',
  'LOAD_FAILURE' = '@budgetFields/LOAD_FAILURE',
}
export interface FormFields {
  configId: number;
  idField: number;
  fieldName: string;
  description: string;
  placeholder?: string;
  position: number;
  enable: boolean;
  tableName: string;
  foreignEntity?: string | null;
  fieldType: Type;
  defaultValue?: any;
  visible: boolean;
  required: boolean;
}
export interface BudgetFieldsState {
  readonly data: FormFields[];
  readonly values: any[];
  readonly loading: boolean;
  readonly error?: ResponseError;
}
