import { Reducer } from 'redux';
import { BudgetsDetailTypes, BudgetsDetailState } from './types';

const INITIAL_STATE: BudgetsDetailState = {
  pagination: undefined,
  data: [],
  loading: false,
  loadingTable: false,
  dataDetail: [],
};

const reducer: Reducer<BudgetsDetailState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case BudgetsDetailTypes.LOAD_REQUEST:
      return {
        ...state,
        loadingTable: true,
      };
    case BudgetsDetailTypes.LOAD_SUCCESS:
      return {
        ...state,
        loadingTable: false,
        errorData: undefined,
        ...action.payload,
      };
    case BudgetsDetailTypes.LOAD_FAILURE:
      return {
        ...state,
        data: [],
        loadingTable: false,
        errorData: action.payload.error,
      };
    case BudgetsDetailTypes.DATA_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case BudgetsDetailTypes.DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        errorDetails: undefined,
        dataDetail: action.payload.data,
        configDetail: action.payload.config,
      };
    case BudgetsDetailTypes.DATA_FAILURE:
      return {
        ...state,
        dataDetail: [],
        loading: false,
        errorDetails: action.payload.error,
      };
    default:
      return state;
  }
};

export default reducer;
