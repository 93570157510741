import styled from 'styled-components';
import { colors } from '../../utils/constants';

export const PageContent = styled.div`
  margin: 20px auto;
  max-width: 1366px;
  padding: 20px;
  border-radius: 5px;
  background-color: white;
  box-shadow: 1px 2px 2px 2px ${colors.grey};
  @media only screen and (max-width: 1366px) {
    padding: 10px;
    margin: 10px;
  }
`;
