import { action } from 'typesafe-actions';
import { ResponseError } from '../../../services/api';
import { Config, Data } from '../../../utils/newTableHelper';
import { HomeFinancialsTypes } from './types';

export const loadFinancialsRequest = () => action(HomeFinancialsTypes.LOAD_REQUEST);
export const loadSuccess = (data: Data[], config: Config) =>
  action(HomeFinancialsTypes.LOAD_SUCCESS, { data, config });
export const loadFailure = (error: ResponseError) =>
  action(HomeFinancialsTypes.LOAD_FAILURE, { error });
