export enum UserTypes {
  'LOAD_REQUEST' = '@user/LOAD_REQUEST',
  'LOAD_SUCCESS' = '@user/LOAD_SUCCESS',
  'LOAD_FAILURE' = '@user/LOAD_FAILURE',

  'UPDATE_REQUEST' = '@user/UPDATE_REQUEST',
  'UPDATE_SUCCESS' = '@user/UPDATE_SUCCESS',
  'UPDATE_FAILURE' = '@user/UPDATE_FAILURE',

  'PASSWORD_REQUEST' = '@user/PASSWORD_REQUEST',
  'PASSWORD_SUCCESS' = '@user/PASSWORD_SUCCESS',
  'PASSWORD_FAILURE' = '@user/PASSWORD_FAILURE',

  'LOAD_REQUEST_PHOTO' = '@user/LOAD_REQUEST_PHOTO',
  'LOAD_SUCCESS_PHOTO' = '@user/LOAD_SUCCESS_PHOTO',
  'LOAD_FAILURE_PHOTO' = '@user/LOAD_FAILURE_PHOTO',

  'UPDATE_REQUEST_PHOTO' = '@user/UPDATE_REQUEST_PHOTO',
  'UPDATE_SUCCESS_PHOTO' = '@user/UPDATE_SUCCESS_PHOTO',
  'UPDATE_FAILURE_PHOTO' = '@user/UPDATE_FAILURE_PHOTO',

  'DELETE_REQUEST_PHOTO' = '@user/DELETE_REQUEST_PHOTO',
  'DELETE_SUCCESS_PHOTO' = '@user/DELETE_SUCCESS_PHOTO',
  'DELETE_FAILURE_PHOTO' = '@user/DELETE_FAILURE_PHOTO',

  'LOAD_REQUEST_PARTNERS' = '@user/LOAD_REQUEST_PARTNERS',
  'LOAD_SUCCESS_PARTNERS' = '@user/LOAD_SUCCESS_PARTNERS',
  'LOAD_FAILURE_PARTNERS' = '@user/LOAD_FAILURE_PARTNERS',

  'CLEAN_STATE' = '@user/CLEAN_STATE',
}
export interface Contact {
  idContact: number;
  name: string;
  email?: string;
  phone?: string;
}

export interface Budget {
  show: boolean;
  contributionMargin: number;
}

export interface UserPartner {
  idPartner: number;
  name: string;
  corporateName: string;
  cnpj: string;
  email?: string;
  phone?: string;
  address: string;
}

export interface Partner {
  cnpj?: string;
  idContact: number;
  idPartner: number;
  contactName: string;
  partnerName: string;
  corporateName?: string;
}

export interface Photo {
  image: string;
  file: File;
}

export interface User {
  idUser: number;
  name: string;
  email: string;
  contactsAmount: number;
  active: string;
  roles: string[];
  contact: Contact;
  partner: UserPartner;
  budget: Budget;
}

export interface UserState {
  readonly data?: User;
  readonly partners: Partner[];
  readonly photo?: File | string;
  readonly loading: boolean;
  readonly loadingPhoto: boolean;
  readonly error: boolean;
}
