import { Col, Row } from 'antd';
import { ColumnsType, ColumnType } from 'antd/lib/table';
import TableFilterDate from '../components/CustomTable/TableFilterDate';
import TableFilterDefault from '../components/CustomTable/TableFilterDefault';
import TableFilterRangeCurrency from '../components/CustomTable/TableFilterRangeCurrency';
import TableFilterList from '../components/CustomTable/TableFilterList';
import mask from './masks';
import CustomTag from '../components/CustomTag';

export type Type =
  | 'OPTIONS'
  | 'BLOB'
  | 'CLOB'
  | 'STRING'
  | 'INTEGER'
  | 'FLOAT'
  | 'DATE'
  | 'DATE_TIME'
  | 'TIME'
  | 'TEXT_AREA';
export interface DataValues {
  fixedName?: string | null;
  id: number;
  origin: string;
  value?: any;
}
export interface Data {
  fields: DataValues[];
}
export interface ConfigFields {
  description: string;
  fixedName: string;
  id: number;
  name: string;
  options: KeyValue[];
  origin: string;
  pk: boolean;
  mask?: string | null;
  position: number;
  rangeFilter: boolean;
  show: boolean;
  showSorting: boolean;
  showFilter: boolean;
  type: Type;
  showSum?: boolean;
  columnSize?: number;
}

interface PkFields {
  fieldName: string;
  index: number;
}
export interface Config {
  details?: any;
  fields: ConfigFields[];
  filters?: KeyValue[];
  origin: string;
  placeholder?: string;
  pkFields?: PkFields[]
}
export interface KeyValue {
  key: number;
  value: string;
  cor?: string;
}

const renderCurrency = (value: number) => {
  return (
    <Row justify="space-between">
      <Col>R$</Col>
      <Col>
        {value?.toLocaleString('pt-BR', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}
      </Col>
    </Row>
  );
};

export const configToColumnsType = (
  config: ConfigFields[],
  customRender?: any[],
  customColumns?: ColumnsType<any>,
  customSort?: number[],
  noFilters?: boolean,
  noSorter?: boolean
): ColumnsType<any> => {
  let ordered: ConfigFields[] = [];
  if (customSort) {
    const dynamicFields = config.filter((field) => field.position);
    dynamicFields.sort((a: ConfigFields, b: ConfigFields) => {
      return a.position > b.position ? 1 : b.position > a.position ? -1 : 0;
    });

    customSort.forEach((item) => {
      if (item === 0) {
        ordered.push(...dynamicFields);
        return;
      }
      const index = config.findIndex(({ id }) => id === item);
      if (index === -1) return;
      ordered.push(config[index]);
    });
  } else {
    ordered = config.sort((a: ConfigFields, b: ConfigFields) => {
      if (a.position < b.position) {
        return -1;
      }
      if (a.position > b.position) {
        return 1;
      }
      return 0;
    });
  }
  const columns: ColumnsType<any> = ordered.map((item: ConfigFields, index: number) => {
    const custom = customRender?.find(({ id }: any) => id === item.id);
    return {
      showSum: item.showSum,
      mask: item.mask || getColumnType(item?.type, false),
      ellipsis: true,
      type: item.type,
      position: item.position || 999,
      className: 'table-column',
      key: index,
      dataIndex: item.fixedName || item.name,
      fieldName: item.name,
      title: item.description,
      filters: item.showFilter && !noFilters ? (item.options as any) : undefined,
      sorter: !noSorter ? item.showSorting : undefined,
      filterDropdown:
        item.showFilter && !noFilters
          ? getFilterDropdown(item.name,
            item.mask || getColumnType(item.type, item.options?.length > 0)
          )
          : undefined,
      render: custom
        ? custom.render
        : item.mask === 'currency'
          ? (value) => renderCurrency(value)
          : undefined,
    };
  });
  if (customColumns) {
    customColumns.forEach((column: ColumnType<any>) => {
      columns.push(column);
    });
  }
  return columns;
};

export const getColumnType = (type?: Type, hasOption?: boolean) => {
  if (hasOption) return 'options';
  switch (type) {
    case 'INTEGER':
    case 'FLOAT':
      return 'number';

    case 'DATE':
      return 'date';

    case 'DATE_TIME':
      return 'dateTime';

    default:
      return 'string';
  }
};

function getFilterDropdown(dataIndex: string, type: string): any {
  switch (type) {
    case 'date':
    case 'dateTime':
      return TableFilterDate(dataIndex);

    case 'currency':
      return TableFilterRangeCurrency(dataIndex);

    case 'options':
      return TableFilterList(dataIndex);

    default:
      return TableFilterDefault(dataIndex, type);
  }
}

export const filterParamsHandle = (filters: any): any[] => {
  if (!filters) return [];
  const campos: any[] = [];
  Object.keys(filters).forEach((index: any) => {
    if (!filters[index]) return;
    if (filters[index].slice(1)[0] === null || filters[index].slice(1)[0] === undefined) return;
    campos.push({
      name: filters[index][0],
      value: filters[index].slice(1),
    });
  });
  return campos;
};

export const sorterParamsHandle = (sorter: any) => {
  if (!sorter?.order) return;
  return {
    name: sorter.column.fieldName,
    direction: sorter.order === 'ascend' ? 'ASC' : 'DESC',
  };
};

export const dataToDisplay = (data?: DataValues[], config?: Config) => {
  if (!data) return undefined;
  const row = data.map((item) => {
    const dataConfig = config?.fields.find((c) => c?.id === item?.id);
    return {
      key: dataConfig?.fixedName || dataConfig?.name,
      value: mask(dataConfig?.mask || getColumnType(dataConfig?.type, false), item?.value),
      title: dataConfig?.description,
      display: dataConfig?.show && dataConfig?.id > 0,
      show: dataConfig?.show,
    };
  });
  return row;
};

const getOption = (value: any, options: KeyValue[]) => {
  const option = options.find(option => option.key === value);

  return option;
}

const formatValue = (dataConfig?: ConfigFields, value?: any) => {
  if (!dataConfig) return value;

  if (dataConfig.mask) {
    if (dataConfig.options && dataConfig.options.length > 0) {
      const option = getOption(value, dataConfig.options);

      if (option) {
        if (option.cor)
          return <CustomTag title={mask(
            (dataConfig.mask === 'currency' ? '' : dataConfig.mask) ||
            getColumnType(dataConfig.type, false),
            option.value
          )} color={'#' + option.cor} />;

        return mask(
          (dataConfig.mask === 'currency' ? '' : dataConfig.mask) ||
          getColumnType(dataConfig.type, false),
          option.value
        );
      }
    }

    if (dataConfig.mask === 'link' && value)
      return <a href={value} target="__blank" style={{
        display: 'block',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}>{value}</a>

    return mask(
      (dataConfig?.mask === 'currency' ? '' : dataConfig?.mask) ||
      getColumnType(dataConfig?.type, false),
      value
    );
  }

  if (dataConfig?.options && dataConfig?.options.length > 0) {
    const option = getOption(value, dataConfig?.options);

    if (option) {
      if (option.cor)
        return <CustomTag title={option.value} color={'#' + option.cor} />;

      return option.value;
    }
  }


  return value;
}

export const dataToTableData = (data: Data[], config?: Config) => {
  return data.map((dataItem, index) => {
    if (!dataItem) return undefined;

    const row = dataItem.fields.map((item) => {
      const fields = config?.fields || [];
      const dataConfig = fields.find(({ id }) => id === item.id);
      return {
        id: item.id,
        key: dataConfig?.fixedName || dataConfig?.name,
        fieldName: dataConfig?.name,
        value: item.id > 0 ? formatValue(dataConfig, item.value) : item.value,
        originalValue: dataConfig?.options && dataConfig.options.length > 0 ? item.value : undefined,
        show: dataConfig?.show,
        isPK: dataConfig?.pk,
        position: dataConfig?.position || 99999
      };
    });

    const pkFields = config?.pkFields || [];
    let pkValue = '';

    if (pkFields.length > 0) {
      const orderedPks = pkFields.sort((a, b) => a.index - b.index).map(item => item.fieldName);
      pkValue = orderedPks.map(pk => row.find(item => item.fieldName === pk)).map(item => {
        if (item?.originalValue) return item.originalValue;

        return item?.value ?? '';
      }).join('-');
    } else {
      pkValue = row
        .filter((item) => item.isPK)
        .sort((a, b) => {
          return a.position - b.position;
        })
        .map((item) => item.value)
        .join('-');
    }

    const tableData: any = {};
    row.forEach((element: any) => {
      Object.assign(tableData, {
        key: `${dataItem.fields.map((a) => a.value)}${index}`,
        [element.key]: element.value || '',
        [`${element.key}_SHOW`]: element.show,
        [`${element.key}_ID`]: element.id,
        pkValue: pkValue,
      });
    });
    return tableData;
  });
};

export const getFixedFieldDescription = (fixedName: string, defaultDescription: string, config: ConfigFields[] = []) => {
  return config.find(config => config.fixedName === fixedName)?.description || defaultDescription;
}


export const fieldIsSwitch = (fieldOptions: KeyValue[]) =>
  fieldOptions.length === 2 && fieldOptions.some(field => field.value === 'Sim') && fieldOptions.some(field => field.value === 'Não');

