import { message } from 'antd';


export const downloadFile = (pdf: string, name: string, extension: string, messagePosition = '0') => {
  const linkSource = `data:application/octet-stream;base64,${pdf}`;
  const downloadLink = document.createElement('a');
  const fileName = `${name}.${extension}`;
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();

  message.success({
    content: `${fileName} baixado com sucesso!`,
    style: { marginTop: messagePosition },
  });
};

