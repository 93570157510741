import styled from "styled-components";

import { colors } from "../../../utils/constants";

export const Description = styled.div`
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  margin-left: 8px;
`;


export const TotalQuantity = styled.small`
  display:block;
  text-align: left;
  color: ${colors.writeGrey};
  font-size: 11px;
  line-height: 22px;
`;
