import React from 'react';
import { Funnel, FUNNEL_CONVERSATION_FIELD } from '@ant-design/plots';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../store';
import EmptyChart from '../EmptyChart';
interface Props {
  title: string;
  data: any[];
  nameField: string;
  field: string;
  fieldType?: string;
  onClick(values: any): void;
}
const FunnelChart: React.FC<Props> = ({ title, data, nameField, field, fieldType, onClick }) => {
  const layout = useSelector((state: ApplicationState) => state.layout.data);

  const config = {
    data: data,
    legend: {},
    xField: nameField,
    yField: field,
    dynamicHeight: false,
    label: {
      formatter: (datum: any) => {
        return fieldType === 'percentage'
          ? `${(datum['$$percentage$$'] * 100).toFixed(2)}%`
          : `${datum[nameField]}: ${datum[field]}`;
      },
    },
    conversionTag: fieldType === 'percentage' && {
      formatter: (datum: any) => {
        return `${(
          (datum[FUNNEL_CONVERSATION_FIELD][1] / datum[FUNNEL_CONVERSATION_FIELD][0]) *
          100
        ).toFixed(2)}%`;
      },
    },
    onReady: (plot: any) => {
      plot.on('element:click', ({ data: { data } }: any) => {
        onClick(data);
      });
    }
  };
  return data.length === 0 ? (
    <EmptyChart type={'funnel'} />
  ) : (
    <div style={{ padding: '20px' }}>
      <h2 style={{ color: layout?.color.dark }}>{title}</h2>
      <Funnel {...config} />
    </div>
  );
};

export default FunnelChart;
