import { Button, Col, Dropdown, Menu, Row } from 'antd';
import React, { Dispatch, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Action } from 'typesafe-actions';
import Swal from 'sweetalert2';
import { EllipsisOutlined, CheckOutlined, CloseOutlined, EyeOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import CustomTable from '../../../components/CustomTable';
import { ApplicationState } from '../../../store';
import {
  cleanStatus,
  requestApprove,
  requestDeny,
  requestReleases,
} from '../../../store/ducks/orderRelease/actions';
import { maskDate } from '../../../utils/masks';
import CustomTag from '../../../components/CustomTag';
import ServerError from '../../../components/ServerError';
interface Props {
  search: string;
}
const ReleasesTable: React.FC<Props> = ({ search }) => {
  const layout = useSelector((state: ApplicationState) => state.layout.data);
  const data = useSelector((state: ApplicationState) => state.orderRelease.data);
  const error = useSelector((state: ApplicationState) => state.orderRelease.error);
  const loading = useSelector((state: ApplicationState) => state.orderRelease.loading);
  const loadingAction = useSelector((state: ApplicationState) => state.orderRelease.statusLoading);
  const statusError = useSelector((state: ApplicationState) => state.orderRelease.statusError);
  const status = useSelector((state: ApplicationState) => state.orderRelease.status);
  const pagination = useSelector((state: ApplicationState) => state.orderRelease.pagination);
  const dispatch = useDispatch<Dispatch<Action>>();
  const history = useHistory();

  const approveOrDenyOrder = (invoiceId: number, action: 'approved' | 'denied') => {
    if (invoiceId === undefined) return;

    const titleSwal =
      action === 'approved'
        ? `Você confirma a Aprovação do pedido ${invoiceId}? `
        : `Você confirma a Negação do pedido ${invoiceId}? `;

    Swal.fire({
      title: titleSwal,
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: layout?.color.default,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(
          action === 'approved' ? requestApprove(invoiceId, search) : requestDeny(invoiceId, search)
        );
      }
    });
  };

  useEffect(() => {
    if (!status) return;
    Swal.fire({
      title: `Pedido ${status === 'approved' ? 'aprovado' : 'negado'} com Sucesso!`,
      icon: 'success',
      confirmButtonColor: layout?.color.default,
    }).then(() => {
      dispatch(cleanStatus());
    });
  }, [dispatch, layout, status]);

  useEffect(() => {
    if (!statusError) return;
    Swal.fire({
      title: 'Erro!',
      text: statusError?.message,
      icon: 'error',
      confirmButtonColor: layout?.color.default,
    }).then(() => {
      dispatch(cleanStatus());
    });
  }, [dispatch, layout, statusError]);

  const columns = [
    {
      title: 'Solicitante',
      dataIndex: 'userApplicantName',
      key: 'userApplicantName',
    },
    {
      title: 'Usuário liberador',
      dataIndex: 'userReleaserName',
      key: 'userReleaserName',
    },
    {
      title: 'Nro pedido',
      dataIndex: 'invoiceId',
      key: 'invoiceId',
    },
    {
      title: 'Data',
      dataIndex: 'negDt',
      key: 'negDt',
      render: (negDt: Date) => <>{maskDate(negDt)}</>,
      hasTitle: true,
    },
    {
      title: 'Total pedido',
      dataIndex: 'invoiceValue',
      key: 'invoiceValue',
      render: (invoiceValue: number) => (
        <Row justify="space-between">
          <Col>R$</Col>
          <Col>
            {invoiceValue.toLocaleString('pt-BR', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Col>
        </Row>
      ),
      hasTitle: true,
    },
    {
      title: 'Status',
      dataIndex: 'invoiceApprovalStatus',
      key: 'invoiceApprovalStatus',
      render: (invoiceApprovalStatus: string) => (
        <CustomTag
          title={invoiceApprovalStatus}
          color={
            invoiceApprovalStatus === 'PENDENTE'
              ? 'orange'
              : invoiceApprovalStatus === 'NEGADO'
              ? 'red'
              : 'green'
          }
        />
      ),
    },
    {
      title: 'Ações',
      key: 'action',
      render: (record: any) => {
        return (
          <Dropdown
            trigger={['click']}
            overlay={
              <Menu>
                <Menu.Item>
                  <Button
                    data-cy="btn-orderDetail"
                    icon={<EyeOutlined />}
                    type="text"
                    onClick={() => history.push(`liberacao-pedidos/${record.invoiceId}/detalhes`)}
                  >
                    Ver
                  </Button>
                </Menu.Item>
                <Menu.Item>
                  <Button
                    data-cy="btn-approve"
                    type="text"
                    loading={loadingAction}
                    icon={<CheckOutlined />}
                    disabled={record.invoiceApprovalStatus !== 'PENDENTE'}
                    onClick={() => {
                      approveOrDenyOrder(record.invoiceId, 'approved');
                    }}
                  >
                    Aprovar
                  </Button>
                </Menu.Item>
                <Menu.Item>
                  <Button
                    data-cy="btn-deny"
                    type="text"
                    loading={loadingAction}
                    icon={<CloseOutlined />}
                    disabled={record.invoiceApprovalStatus !== 'PENDENTE'}
                    onClick={() => {
                      approveOrDenyOrder(record.invoiceId, 'denied');
                    }}
                  >
                    Negar
                  </Button>
                </Menu.Item>
              </Menu>
            }
          >
            <Button
              data-cy="btn-more"
              type="link"
              style={{ padding: 0 }}
              onClick={(e) => e.preventDefault()}
            >
              <EllipsisOutlined style={{ fontSize: '24px' }} rotate={90} />
            </Button>
          </Dropdown>
        );
      },
    },
  ];

  return (
    <>
      {error ? (
        <ServerError {...error} />
      ) : (
        <CustomTable
          style={{ padding: '20px' }}
          onChange={(pagination: any) => {
            dispatch(requestReleases(pagination, search));
          }}
          pagination={pagination}
          loading={loading}
          columns={columns}
          data={data}
        />
      )}
    </>
  );
};

export default ReleasesTable;
