import React from 'react';
import { List } from 'antd';
import { useSelector } from 'react-redux';
import { LoadingOutlined } from '@ant-design/icons';

import { ApplicationState } from '../../../store';
import ReportItem from '../ReportItem';
import ServerError from '../../../components/ServerError';
import { colors } from '../../../utils/constants';

const ReportsList: React.FC = () => {
  const data = useSelector((state: ApplicationState) => state.reports.dataReports);
  const error = useSelector((state: ApplicationState) => state.reports.error);
  const loading = useSelector((state: ApplicationState) => state.reports.loadingReports);
  const layout = useSelector((state: ApplicationState) => state.layout.data);

  return (
    <>
      {error ? (
        <ServerError {...error} />
      ) : (
        <List
          itemLayout="horizontal"
          style={{
            backgroundColor: 'white',
            padding: '20px',
            borderRadius: '5px',
            boxShadow: `1px 2px 2px 2px ${colors.grey}`,
          }}
          size="small"
          loading={{
            style: { color: layout?.color.default },
            spinning: loading,
            size: 'large',
            delay: 500,
            indicator: <LoadingOutlined style={{ fontSize: 24 }} spin />,
          }}
          dataSource={data}
          renderItem={(item: any) => <ReportItem item={item} />}
        />
      )}
    </>
  );
};

export default ReportsList;
