import { List } from 'antd';
import React, { Dispatch } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Action } from 'redux';
import { LoadingOutlined } from '@ant-design/icons';
import { ApplicationState } from '../../../store';
import ProductListItem from '../ProductListItem';
import { loadRequestShowCase } from '../../../store/ducks/showCase/actions';
interface Props {
  mode?: 'GRID' | 'IMAGEM' | 'CARD';
}
const ProductList: React.FC<Props> = ({ mode }) => {
  const layout = useSelector((state: ApplicationState) => state.layout.data);
  const data = useSelector((state: ApplicationState) => state.productsShowCase.data);
  const pagination = useSelector((state: ApplicationState) => state.productsShowCase.pagination);
  const searchValue = useSelector((state: ApplicationState) => state.productsShowCase.searchValue);
  const loading = useSelector((state: ApplicationState) => state.productsShowCase.loading);
  const dispatch = useDispatch<Dispatch<Action>>();

  return (
    <List
      loading={{
        style: { color: layout?.color.default },
        spinning: loading,
        size: 'large',
        delay: 500,
        indicator: <LoadingOutlined style={{ fontSize: 24 }} spin />,
      }}
      dataSource={data}
      pagination={{
        size: 'small',
        ...pagination,
        onChange: (page, pageSize) => {
          dispatch(
            loadRequestShowCase(
              { current: page, pageSize: pageSize || 0, total: pagination?.total || 0 },
              searchValue
            )
          );
        },
      }}
      renderItem={(item) => {
        return <ProductListItem item={item} mode={mode} loading={loading} />;
      }}
    />
  );
};

export default ProductList;
