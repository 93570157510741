import React, { Dispatch } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Col, Row, Select } from 'antd';

import { CardContainer, CartTitle, Description, Stock, Value } from './styles';
import { ApplicationState } from '../../../store';
import { Action } from 'typesafe-actions';
import { baseURL } from '../../../services/api';
import { useHistory } from 'react-router-dom';
import ImageCarousel from './ProductCarousel';
import PromoTag from '../../../components/PromoTag';
import { maskCurrency } from '../../../utils/masks';
import { setUnity } from '../../../store/ducks/showCase/actions';

export interface Props {
  product: any;
  margin?: string;
  loading?: boolean;
}

const { Option } = Select;

const ProductCard: React.FC<Props> = ({ product, margin }) => {
  const layout = useSelector((state: ApplicationState) => state.layout.data);
  const dispatch = useDispatch<Dispatch<Action>>();

  const images: string[] =
    product.DETAILS.alternativeImages.length > 0
      ? [
        `${baseURL}/produtos/${product?.pkValue}/imagem?w=260&h=260`,
        ...product.DETAILS.alternativeImages.map(
          (item: any) =>
            `${baseURL}/produtos/${product?.pkValue}/imagens/alternativas/${item.key}?w=260&h=260`
        ),
      ]
      : [`${baseURL}/produtos/${product?.pkValue}/imagem?w=260&h=260`];

  const history = useHistory();

  const handleClick = (id: number) => {
    history.push(`/showcase/products/${id}/detalhes`);
  };

  const getStock = () =>
    Math.floor(
      product?.ESTOQUE?.find(({ key }: any) => key === product?.SELECTED_UNITY).value || 0
    );

  const getValue = () =>
    product?.VALOR?.find(({ key }: any) => key === product?.SELECTED_UNITY).value || 0;

  return (
    <>
      <Card
        data-cy="productCard"
        style={{
          margin: margin,
          borderRadius: '5px',
          borderTop: product?.PROMOCAO?.isPromo ? `5px solid ${layout?.color.default}` : 0,
        }}
        hoverable
        onClick={() => handleClick(product?.pkValue)}
      >
        <CardContainer hasValue={!!product?.VALOR}>
          <ImageCarousel images={images} />
          <div style={{ marginTop: 8 }}>
            <CartTitle>
              <Stock>
                {product?.CODPROD_SHOW && `${product?.CODPROD} - `}
                {product?.ESTOQUE_SHOW && (
                  <>
                    Disponível:
                    <strong style={{ marginLeft: '5px', marginRight: '5px' }}>{getStock()}</strong>
                  </>
                )}
              </Stock>
            </CartTitle>
            <Description title={product?.DESCRPROD}>{product?.DESCRPROD}</Description>
            {product?.VALOR && (
              <Row align="middle" gutter={[5, 5]} justify="space-between">
                {product?.PROMOCAO?.isPromo && (
                  <Col>
                    <PromoTag />
                  </Col>
                )}
                <Col>
                  <Value>
                    {product?.DETAILS.stockControlType === 'GRADE' && getValue() === 0
                      ? 'Indisponível'
                      : maskCurrency(getValue())}
                  </Value>
                </Col>
                <Col>
                  <div onClick={(e) => e.stopPropagation()}>
                    <Select
                      data-cy="input-selectUnity"
                      defaultValue={product?.SELECTED_UNITY}
                      value={product?.SELECTED_UNITY}
                      onChange={(value) => dispatch(setUnity(product?.pkValue, value))}
                    >
                      {product?.UNIDADE?.map((element: any) => {
                        return (
                          <Option key={element.key} value={element.key}>
                            {element.key}
                          </Option>
                        );
                      })}
                    </Select>
                  </div>
                </Col>
              </Row>
            )}
          </div>
        </CardContainer>
      </Card>
    </>
  );
};

export default ProductCard;
