import { action } from 'typesafe-actions';
import { ResponseError } from '../../../services/api';
import { SignUpForm, SignUpFieldsTypes, SendObject, Success } from './types';

export const loadFieldsRequest = () => action(SignUpFieldsTypes.LOAD_REQUEST);
export const loadFieldsSuccess = (data: SignUpForm[]) =>
  action(SignUpFieldsTypes.LOAD_SUCCESS, { data });
export const loadFieldsFailure = (error: ResponseError) =>
  action(SignUpFieldsTypes.LOAD_FAILURE, { error });

export const sendFieldsRequest = (values?: SendObject[]) =>
  action(SignUpFieldsTypes.SEND_REQUEST, { values });
export const sendFieldsSuccess = (success: Success) =>
  action(SignUpFieldsTypes.SEND_SUCCESS, { success });
export const sendFieldsFailure = (sendError: ResponseError) =>
  action(SignUpFieldsTypes.SEND_FAILURE, { sendError });

export const cleanFields = () => action(SignUpFieldsTypes.CLEAN_STATE);
