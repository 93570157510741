import { ResponseError } from '../../../services/api';

export enum DashboardMenuTypes {
  'LOAD_REQUEST' = '@dashboardMenu/LOAD_REQUEST',
  'LOAD_SUCCESS' = '@dashboardMenu/LOAD_SUCCESS',
  'LOAD_FAILURE' = '@dashboardMenu/LOAD_FAILURE',
}

export interface DashboardMenu {
  codDsb: number;
  title: string;
}
export interface DashboardMenuState {
  readonly data: DashboardMenu[];
  readonly loading: boolean;
  readonly error?: ResponseError;
}
