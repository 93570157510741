import { Carousel, Col, Spin } from 'antd';
import React from 'react';
import { LoadingOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../store';
import { CarouselStyled } from '../styles';
import ServerError from '../../../components/ServerError';
import StatisticCard from '../../../components/StatisticCard';

const StatisticCards: React.FC = () => {
  const cards = useSelector((state: ApplicationState) => state.homeCards.data);
  const error = useSelector((state: ApplicationState) => state.homeCards.error);
  const layout = useSelector((state: ApplicationState) => state.layout.data);
  const loading = useSelector((state: ApplicationState) => state.homeCards.loading);

  const SampleNextArrow = ({ className, style, onClick }: any) => {
    return (
      <div
        className={className}
        style={{
          ...style,
          color: layout?.color.default,
          fontSize: '20px',
          lineHeight: '1.5715',
        }}
        onClick={onClick}
      >
        <RightOutlined />
      </div>
    );
  };

  const SamplePrevArrow = ({ className, style, onClick }: any) => {
    return (
      <div
        className={className}
        style={{
          ...style,
          color: layout?.color.default,
          fontSize: '20px',
          lineHeight: '1.5715',
        }}
        onClick={onClick}
      >
        <LeftOutlined />
      </div>
    );
  };
  return (
    <Spin
      style={{ color: layout?.color.default }}
      spinning={loading}
      size="large"
      delay={500}
      indicator={<LoadingOutlined />}
    >
      {error ? (
        <ServerError {...error} />
      ) : (
        <CarouselStyled lenght={cards.length}>
          <Carousel
            nextArrow={<SampleNextArrow />}
            prevArrow={<SamplePrevArrow />}
            slidesToShow={4}
            infinite={cards.length > 4}
            arrows={cards.length > 4}
            autoplay
            slidesToScroll={1}
            responsive={[
              {
                breakpoint: 1200,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 2,
                  infinite: cards.length > 3,
                  arrows: cards.length > 3,
                },
              },
              {
                breakpoint: 900,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2,
                  infinite: cards.length > 3,
                  arrows: cards.length > 2,
                },
              },
              {
                breakpoint: 570,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  infinite: cards.length > 1,
                  arrows: cards.length > 1,
                },
              },
            ]}
          >
            {cards.map((item) => (
              <Col span={24}>
                <StatisticCard {...item} />
              </Col>
            ))}
          </Carousel>
        </CarouselStyled>
      )}
    </Spin>
  );
};

export default StatisticCards;
