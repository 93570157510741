import { ResponseError } from '../../../services/api';

export enum NewUserTypes {
  'USER_REQUEST' = '@newUser/USER_REQUEST',
  'USER_SUCCESS' = '@newUser/USER_SUCCESS',
  'USER_FAILURE' = '@newUser/USER_FAILURE',

  'SEND_REQUEST' = '@newUser/SEND_REQUEST',
  'SEND_SUCCESS' = '@newUser/SEND_SUCCESS',
  'SEND_FAILURE' = '@newUser/SEND_FAILURE',

  'CLEAR_SEND' = '@newUser/CLEAR_SEND',

  'SET_ERROR' = '@newUser/SET_ERROR',
}
export interface Image {
  attachment?: string;
  description?: string;
}
export interface Contact {
  contactName?: string;
  accessControl?: boolean;
  active?: boolean;
  bill?: boolean;
  campaigns?: boolean;
  editOrders?: boolean;
  faq?: boolean;
  financial?: boolean;
  myData?: boolean;
  newOrder?: boolean;
  orderRelease?: boolean;
  orders?: boolean;
  reOrder?: boolean;
  reports?: boolean;
  serviceOrder?: boolean;
  technicalFile?: boolean;
}
export interface User {
  active?: boolean;
  email?: string;
  image?: Image;
  name?: string;
  password?: string;
  contact?: Contact;
  userId?: number;
}

export interface NewUserState {
  readonly dataUser: any[];
  readonly loadingUser: boolean;

  readonly dataSend: string;
  readonly loadingSend: boolean;
  readonly saved: boolean;
  readonly errorData?: ResponseError;
  readonly errorSend?: ResponseError;
}
