import { Reducer } from 'redux';
import { DashboardState, DashboardTypes } from './types';

const INITIAL_STATE: DashboardState = {
  loading: false,
};

const reducer: Reducer<DashboardState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DashboardTypes.LOAD_CONFIG_REQUEST:
      return {
        ...state,
        loading: true,
        data: undefined,
      };
    case DashboardTypes.LOAD_CONFIG_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        ...action.payload,
      };
    case DashboardTypes.LOAD_CONFIG_FAILURE:
      return {
        ...state,
        loading: false,
        ...action.payload,
      };

    case DashboardTypes.LOAD_REQUEST:
      return {
        ...state,
        loading: true,
        elementsToBeUpdate: action.payload.elementsToBeUpdate
      };
    case DashboardTypes.LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        ...action.payload,
      };
    case DashboardTypes.LOAD_FAILURE:
      return {
        ...state,
        loading: false,
        ...action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
