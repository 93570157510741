import styled from 'styled-components';
import { Layout } from '../../store/ducks/layout/types';

export const BannersCarousel = styled.div<{ layout?: Layout }>`
  .ant-carousel .slick-dots li button {
    background: ${({ layout }) => layout?.color.dark};
    height: 15px;
    opacity: 0.3;
  }

  .ant-carousel .slick-dots li.slick-active button {
    background: ${({ layout }) => layout?.color.default};
    opacity: 1;
    height: 15px;
  }
`;

export const BannerContainer = styled.div<{ hasDots?: boolean }>`
  padding-bottom: ${({ hasDots }) => (hasDots ? '35px' : '20px')};

`;

export const BannerTitleContent = styled.div<{ hasTitle: boolean }>`
  position: absolute;
  bottom: 50px;
  left: 50%;
  transform: translate(-50%, 0);
  background: ${({ hasTitle }) => (hasTitle ? 'rgba(0, 0, 0, 0.2)' : '')};
`;

export const BannerTitle = styled.h1`
  color: white;
  margin-bottom: 0px;
  padding: 5px 10px;
  text-shadow: 1px 1px 4px #000000;
`;

export const BannerImage = styled.img<{ hasLink: boolean }>`
  cursor: ${({ hasLink }) => (hasLink ? 'pointer' : 'default')};
  width: 100%;
`;
