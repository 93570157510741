import { ResponseError } from "../../../services/api";
import { Config, Data } from "../../../utils/newTableHelper";

export enum BudgetsTypes {
  'LOAD_REQUEST' = '@budgets/LOAD_REQUEST',
  'LOAD_SUCCESS' = '@budgets/LOAD_SUCCESS',
  'LOAD_FAILURE' = '@budgets/LOAD_FAILURE',
}

export interface Pagination {
  current: number;
  total: number;
  pageSize: number;
}

export interface BudgetsState {
  readonly data: Data[];
  readonly config?: Config;
  readonly pagination: Pagination;
  readonly loading: boolean;
  readonly error?: ResponseError;
}
