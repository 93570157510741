import { call, put, select } from 'redux-saga/effects';
import { ApplicationState } from '../..';
import api, { errorHandler } from '../../../services/api';
import { requestHeaders } from '../../../utils/apiHelper';
import { refreshRequest } from '../auth/actions';
import { controlFailure, controlRequest, controlSuccess } from './actions';

export function* loadControl({ payload }: ReturnType<typeof controlRequest>): any {
  try {
    const state: ApplicationState = yield select();
    const headers = requestHeaders(state.auth.token);
    const response = yield call(
      api.post,
      '/access-control/users/all',
      {
        search: payload.search,
        page: (payload.pagination?.current || 1) - 1,
        size: payload.pagination?.pageSize || 12,
      },
      { headers: headers }
    );
    yield put(
      controlSuccess(
        {
          total: response.data.numberOfElements,
          pageSize: response.data.size,
          current: response.data.number + 1,
        },
        response.data.content
      )
    );
  } catch (err: any) {
    yield put(err.response.status !== 401 ? controlFailure(errorHandler(err)) : refreshRequest());
  }
}
