import { message, Select, Tooltip } from 'antd';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import api from '../../../services/api';
import { ApplicationState } from '../../../store';
import { requestHeaders } from '../../../utils/apiHelper';
import { ContainerFiles, ContentFiles, CustomButton } from '../styles';

interface Option {
  value: number | string;
}
interface Props {
  options: Option[];
  id: number;
}
const FIlesSelect: React.FC<Props> = ({ options, id }) => {
  const token = useSelector((state: ApplicationState) => state.auth.token);
  const layout = useSelector((state: ApplicationState) => state.layout.data);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedFiles, setSelectedFiles] = useState<any>([]);

  const downloadFile = () => {
    setLoading(true);
    api
      .get(`order/${id}/attachments?files=${selectedFiles}`, {
        headers: requestHeaders(token),
        responseType: 'blob',
      })
      .then((res) => {
        const blob = new Blob([res.data], { type: res.headers['content-type'] });
        const objectUrl = URL.createObjectURL(blob);

        var element = document.createElement('a');
        element.setAttribute('href', objectUrl);
        element.setAttribute('download', 'anexos.zip');

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
        setLoading(false);
        setSelectedFiles([]);
        message.success({
          content: `anexo(s) baixado(s) com sucesso!`,
          style: { marginTop: '85vh' },
        });
      })
      .catch((err) => {
        Swal.fire({
          confirmButtonColor: layout?.color.default,
          title: 'Erro!',
          text: err.response.data.titulo ?? 'Ocorreu um erro inesperado',
          icon: 'error',
        });
        setLoading(false);
      });
  };

  return (
    <ContainerFiles>
      <h3>Anexos</h3>
      <ContentFiles>
        <Select
          data-cy="input-attach"
          showSearch={false}
          mode="multiple"
          style={{ width: '100%', marginBottom: '20px' }}
          placeholder="Selecione o(s) arquivo(s)"
          value={selectedFiles}
          onChange={(files) => {
            setSelectedFiles(files);
          }}
          options={options}
          showArrow={true}
          notFoundContent={<span>Nenhum arquivo disponível.</span>}
        />
        {options.length === 0 ? (
          <Tooltip placement="top" title="Esse pedido não possui anexos.">
            <CustomButton
              data-cy="btn-download"
              type="default"
              block
              onClick={downloadFile}
              loading={loading}
              disabled
            >
              Baixar
            </CustomButton>
          </Tooltip>
        ) : (
          <CustomButton
            data-cy="btn-download"
            type="default"
            block
            onClick={downloadFile}
            loading={loading}
            disabled={selectedFiles.length === 0}
          >
            Baixar
          </CustomButton>
        )}
      </ContentFiles>
    </ContainerFiles>
  );
};

export default FIlesSelect;
