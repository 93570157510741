import { action } from 'typesafe-actions';
import { ResponseError } from '../../../services/api';
import { DataSheetTypes, Pagination } from './types';

export const categoriesRequest = () => action(DataSheetTypes.LOAD_REQUEST_CATEGORY);
export const CategoriesSuccess = (data: any[]) =>
  action(DataSheetTypes.LOAD_SUCCESS_CATEGORY, { data });
export const categoriesFailure = (error: ResponseError) =>
  action(DataSheetTypes.LOAD_FAILURE_CATEGORY, { error });
export const loadRequest = (
  pagination?: Pagination,
  filter?: any,
  sorter?: any,
  searchValue?: string,
  categoryFilter?: any
) =>
  action(DataSheetTypes.LOAD_REQUEST, { pagination, filter, sorter, searchValue, categoryFilter });
export const loadSuccess = (
  pagination: Pagination,
  data: any[],
  config: any[],
  placeholder: string
) => action(DataSheetTypes.LOAD_SUCCESS, { pagination, data, config, placeholder });
export const loadFailure = (error: ResponseError) => action(DataSheetTypes.LOAD_FAILURE, { error });
