import { Reducer } from 'redux';
import { ShoppingCartState, ShoppingCartTypes } from './types';

const INITIAL_STATE: ShoppingCartState = {
  payments: [],
  paymentLoading: false,
  error: false,
  addresses: [],
  addressLoading: false,
  totalQuantity: 0,
  subTotal: 0,
  cart: [],
  loading: false,
  fields: [],
  cleaningCart: false
};

const reducer: Reducer<ShoppingCartState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ShoppingCartTypes.GET_CURRENT_CART_REQUEST:
      return {
        ...state,
        currentAction: 'INIT',
        ...action.payload
      };
    case ShoppingCartTypes.GET_CURRENT_CART_SUCCESS:
      return {
        ...state,
        error: false,
        currentAction: undefined,
        currentActionItemId: undefined,
        id: action.payload.cartId,
        version: action.payload.versionCart,
        cart: action.payload.items,
        subTotal: action.payload.subTotal,
        totalQuantity: action.payload.totalQuantity,
        synced: false
      };
    case ShoppingCartTypes.GET_CURRENT_CART_FAILURE:
      return {
        ...state,
        currentAction: undefined,
        currentActionItemId: undefined,
        id: undefined,
        version: undefined,
        cart: [],
        subTotal: undefined,
        totalQuantity: undefined,
        synced: false
      };
    case ShoppingCartTypes.CART_INITIALIZED:
      return {
        ...state,
        currentAction: undefined,
      };

    case ShoppingCartTypes.ADD_ITEM_REQUEST:
      return {
        ...state,
        error: false,
        currentAction: 'ADD',
        currentActionItemId: action.payload.item.codProd,
      };
    case ShoppingCartTypes.ADD_ITEM_SUCCESS:
      return {
        ...state,
        subTotal: action.payload.subTotal,
        totalQuantity: action.payload.totalQuantity,
        cart: action.payload.data,
        id: action.payload.cartId,
        version: action.payload.versionCart,
        error: false,
        currentAction: undefined,
        currentActionItemId: undefined,
      };
    case ShoppingCartTypes.ADD_ITEM_FAILURE:
      return {
        ...state,
        error: true,
        currentAction: undefined,
        currentActionItemId: undefined,
      };

    case ShoppingCartTypes.DELETE_ITEM_REQUEST:
      return {
        ...state,
        currentAction: 'REMOVE',
        currentActionItemId: action.payload.item.codProd,
      };
    case ShoppingCartTypes.DELETE_ITEM_SUCCESS:
      return {
        ...state,
        subTotal: action.payload.subTotal,
        cart: action.payload.data,
        totalQuantity: action.payload.totalQuantity,
        currentAction: undefined,
        currentActionItemId: undefined,
        id: action.payload.cartId,
        version: action.payload.versionCart,
      };
    case ShoppingCartTypes.DELETE_ITEM_FAILURE:
      return {
        ...state,
        currentAction: undefined,
        currentActionItemId: undefined,
      };

    case ShoppingCartTypes.PAYMENT_REQUEST:
      return {
        ...state,
        paymentLoading: true,
        error: false,
      };
    case ShoppingCartTypes.PAYMENT_SUCCESS:
      return {
        ...state,
        paymentLoading: false,
        error: false,
        payments: action.payload.data,
      };
    case ShoppingCartTypes.PAYMENT_FAILURE:
      return {
        ...state,
        paymentLoading: false,
        error: true,
      };
    case ShoppingCartTypes.CONFIGS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        ...action.payload,
      };
    case ShoppingCartTypes.CONFIGS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        observation: undefined,
        budget: false,
      };
    case ShoppingCartTypes.ADDRESS_REQUEST:
      return {
        ...state,
        addressLoading: true,
        error: false,
      };
    case ShoppingCartTypes.ADDRESS_SUCCESS:
      return {
        ...state,
        addressLoading: false,
        error: false,
        addresses: action.payload.data,
        selectedAddress: action.payload.selectedAddress,
      };
    case ShoppingCartTypes.ADDRESS_FAILURE:
      return {
        ...state,
        addressLoading: false,
        error: true,
      };
    case ShoppingCartTypes.ADDRESS_SELECT:
      return {
        ...state,
        selectedAddress: action.payload.selectedAddress,
      };
    case ShoppingCartTypes.TOTAL_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        ...action.payload,
      };
    case ShoppingCartTypes.TOTAL_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload.errorMessage,
      };
    case ShoppingCartTypes.REDO_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case ShoppingCartTypes.EDIT_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
        editId: action.payload.nuNota,
      };

    case ShoppingCartTypes.EDIT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        editId: action.payload.nuNota,
      };

    case ShoppingCartTypes.REDO_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      };

    case ShoppingCartTypes.CONFIGS_REQUEST:
    case ShoppingCartTypes.TOTAL_REQUEST:
    case ShoppingCartTypes.SAVE_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case ShoppingCartTypes.SAVE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        ...action.payload,
      };
    case ShoppingCartTypes.SAVE_FAILURE:
    case ShoppingCartTypes.REDO_FAILURE:
    case ShoppingCartTypes.EDIT_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload.error,
      };
    case ShoppingCartTypes.CLOSE_CART:
      return {
        ...state,
        cartVisibility: false,
      };
    case ShoppingCartTypes.OPEN_CART:
      return {
        ...state,
        cartVisibility: true,
      };
    case ShoppingCartTypes.CLEAR_CART:
      return {
        ...state,
        cart: [],
        totalQuantity: 0,
        total: 0,
        subTotal: 0,
        success: undefined,
        id: undefined,
        version: undefined,
        cleaningCart: false,
        budgetId: undefined,
        selectedAddress: undefined,
        editId: undefined,
        fields: [],
      };
    case ShoppingCartTypes.CLEAR_CART_FINISH:
      return {
        ...state,
        cleaningCart: false,
        error: false,
      };
    case ShoppingCartTypes.SET_BUDGET:
      return {
        ...state,
        budgetId: action.payload.budgetId,
      };
    case ShoppingCartTypes.SET_BUDGET_VISIBLE:
      return {
        ...state,
        budgetVisible: action.payload.isBudget,
      };
    case ShoppingCartTypes.SET_FIELDS:
      return {
        ...state,
        ...action.payload,
      };
    case ShoppingCartTypes.SET_ERROR:
      return {
        ...state,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export default reducer;
