import { Divider, Form, Select, Spin } from 'antd';
import React, { Dispatch, useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Action } from 'typesafe-actions';
import { LoadingOutlined } from '@ant-design/icons';
import moment from 'moment';
import DynamicFields from '../../../components/DynamicFields';
import { ApplicationState } from '../../../store';
import { loadRequest, resetAdvancedFilter } from '../../../store/ducks/products/actions';
import { Container, DefaultButton, SubmitButton } from '../../../styles';
import { KeyValue } from '../../../store/ducks/shoppingCart/types';
const { Option } = Select;

type Type = 'S' | 'I' | 'O' | 'L' | 'D' | 'F' | 'R' | 'C' | 'B';
interface Props {
  filtersVisible: boolean;
  setFiltersVisible: any;
}

const Filters: React.FC<Props> = ({ filtersVisible, setFiltersVisible }) => {
  const config = useSelector((state: ApplicationState) => state.products.config);
  const filter = useSelector((state: ApplicationState) => state.products?.filter);
  const advancedFields = useSelector((state: ApplicationState) => state.products.advancedFields);
  const advancedFilters = useSelector((state: ApplicationState) => state.products.advancedFilters);
  const pagination = useSelector((state: ApplicationState) => state.products.pagination);
  const layout = useSelector((state: ApplicationState) => state.layout.data);
  const loading = useSelector((state: ApplicationState) => state.products.loading);
  const [filterFields, setFilterFields] = useState<any[]>([]);

  const [form] = Form.useForm();
  const dispatch = useDispatch<Dispatch<Action>>();

  const getType = (type: Type, mask?: string): Type => {
    if (mask === 'currency') return 'C';
    if (mask === 'date' || mask === 'dateTime') return 'R';
    return type;
  };

  const getAdvancedType = (type: string, apresType: Type): Type => {
    switch (type) {
      case 'OPTIONS':
        return 'O';
      case 'CHECKBOX':
        return 'B';
      case 'RANGE':
        return apresType === 'D' ? 'R' : 'C';
      default:
        return apresType;
    }
  };

  const clearFiltros = () => {
    if (filter.length === 0 && !advancedFilters) return;
    dispatch(resetAdvancedFilter());
    dispatch(
      loadRequest([...filter?.filter((item) => item.name === 'categoryId')], undefined, {
        ...pagination,
        current: 1,
      })
    );
    form.resetFields();
  };

  useEffect(() => {
    if (filter.length > 0) return;
    form.resetFields();
  }, [form, filter]);

  useEffect(() => {
    if (!filtersVisible) return;
    form.resetFields();
  }, [advancedFilters, filtersVisible, form]);

  useEffect(() => {
    setFilterFields(config?.fields?.filter((item) => item.showFilter) || []);
  }, [config]);

  const onFinish = (formValues: any) => {
    const filterArray: any[] = [];
    const AdvancedArray: any[] = [];
    Object.keys(formValues).forEach((item) => {
      const value = formValues[item];
      const arrayValue: any[] = [];
      if (value instanceof Array) {
        if (value[0] instanceof moment) {
          value.forEach((item) => arrayValue.push(moment(item).format('DD/MM/YYYY')));
        } else {
          value.forEach((item) => arrayValue.push(item));
        }
      }
      if (filterFields.find(({ name }) => name === item || item === 'campaignId')) {
        if (!value) return;
        filterArray.push({
          name: item,
          value: arrayValue.length > 0 ? arrayValue : [value],
        });
      } else {
        if (!value) return;
        AdvancedArray.push({
          key: parseInt(item),
          value: arrayValue.length > 0 ? arrayValue : value,
        });
      }
    });

    if (AdvancedArray.length === 0) dispatch(resetAdvancedFilter());

    dispatch(
      loadRequest(
        [...filter?.filter((item) => item?.name === 'categoryId'), ...filterArray],
        undefined,
        { ...pagination, current: 1 },
        undefined,
        AdvancedArray
      )
    );
    setFiltersVisible(false);
  };

  return (
    <Container layout={layout}>
      <Spin
        style={{ color: layout?.color.default }}
        spinning={loading}
        delay={500}
        indicator={<LoadingOutlined />}
      >
        <Form
          preserve={false}
          form={form}
          onFinish={onFinish}
          layout="vertical"
        >
          {filterFields.map((item) => {
            const value = filter.find(({ name }) => name === item.name)?.value;
            return (
              <DynamicFields
                type={getType(item.type, item.mask)}
                label={item.description}
                url={'new-order'}
                name={item.name}
                value={value && [...value]}
              />
            );
          })}
          {config?.details?.filters && config?.details?.filters.length > 0 && (
            <Form.Item
              initialValue={filter.find(({ name }) => name === 'campaignId')?.value}
              key={'campaignId'}
              label={'Campanha'}
              name={'campaignId'}
              style={{ marginBottom: '10px' }}
            >
              <Select
                data-cy={`input-campaign`}
                style={{ width: '100%' }}
                value={filter.find(({ name }) => name === 'campaignId')?.value}
                allowClear
                placeholder={'Selecione uma campanha'}
                key={'campaignId'}
                showSearch
                filterOption={(input: any, option: any) =>
                  option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {config?.details?.filters.map((item: KeyValue) => {
                  return (
                    <Option key={item.value} value={item.key}>
                      {item.value}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          )}
          <Divider style={{ margin: '10px 0' }} />
          {advancedFields && advancedFields?.length > 0 && (
            <>
              <h4>Filtros avançados</h4>
              {advancedFields.map((item) => {
                return (
                  <DynamicFields
                    key={item.idFilter.toString()}
                    type={getAdvancedType(item.type, item.presentationType)}
                    label={item.description}
                    url={'pedidos'}
                    foreign={item.foreignEntity}
                    name={item.idFilter.toString()}
                    placeholder={item.placeholder}
                    list={item.options}
                    value={advancedFilters?.find(v => v.key === item.idFilter)?.value}
                  />
                );
              })}
            </>
          )}
          <Form.Item>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <DefaultButton
                data-cy="btn-clearFilter"
                layout={layout}
                block
                style={{ margin: 0 }}
                onClick={clearFiltros}
              >
                Limpar
              </DefaultButton>
              <SubmitButton
                data-cy="sendFilter"
                style={{ marginLeft: '10px' }}
                layout={layout}
                type="primary"
                block
                htmlType="submit"
              >
                Filtrar
              </SubmitButton>
            </div>
          </Form.Item>
        </Form>
      </Spin>
    </Container>
  );
};

export default Filters;
