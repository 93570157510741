import React, { Dispatch } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Action } from 'typesafe-actions';
import { useHistory, useParams } from 'react-router-dom';
import { EllipsisOutlined, CheckCircleTwoTone, EditOutlined } from '@ant-design/icons';
import { ApplicationState } from '../../../store';
import { colors } from '../../../utils/constants';
import CustomTable from '../../../components/CustomTable';
import { pointsRequest } from '../../../store/ducks/deliveryPoint/actions';
import { Button, Dropdown, Menu } from 'antd';
import ServerError from '../../../components/ServerError';
import { DeliveryPoint } from '../../../store/ducks/deliveryPoint/types';

const PointsTable: React.FC = () => {
  const data = useSelector((state: ApplicationState) => state.deliveryPoint.data);
  const error = useSelector((state: ApplicationState) => state.deliveryPoint.error);
  const loading = useSelector((state: ApplicationState) => state.deliveryPoint.loading);
  const pagination = useSelector((state: ApplicationState) => state.deliveryPoint.pagination);
  const history = useHistory();
  const { userId, contactId }: any = useParams();
  const dispatch = useDispatch<Dispatch<Action>>();

  const columns = [
    {
      title: 'Código',
      dataIndex: 'delivPointId',
      key: 'delivPointId',
    },
    {
      title: 'Contato Ponto de Entrega',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Dia base limite de crédito',
      dataIndex: 'deadline',
      key: 'deadline',
    },
    {
      title: 'Limite de crédito',
      dataIndex: 'creditLimit',
      key: 'creditLimit',
    },
    {
      title: 'Usa limite',
      dataIndex: 'useLimit',
      key: 'useLimit',
      render: (value: boolean) => (
        <>
          {value ? (
            <CheckCircleTwoTone style={{ fontSize: '20px' }} twoToneColor={colors.success} />
          ) : (
            <CheckCircleTwoTone style={{ fontSize: '20px' }} twoToneColor={colors.grey} />
          )}
        </>
      ),
    },
    {
      title: 'Ações',
      key: 'action',
      render: (value: DeliveryPoint) => {
        return (
          <Dropdown
            trigger={['click']}
            overlay={
              <Menu>
                <Menu.Item>
                  <Button
                    data-dy="btn-edit"
                    icon={<EditOutlined />}
                    type="text"
                    className="ant-dropdown-link no-pd"
                    onClick={() => {
                      history.push(
                        `/home/controle-acessos/usuario/${value.userId}/contato/${value.contactId}/ponto-entrega/${value.delivPointId}`
                      );
                    }}
                  >
                    Editar
                  </Button>
                </Menu.Item>
              </Menu>
            }
          >
            <Button
              data-cy="btn-more"
              type="link"
              style={{ padding: 0 }}
              onClick={(e) => e.preventDefault()}
            >
              <EllipsisOutlined style={{ fontSize: '24px' }} rotate={90} />
            </Button>
          </Dropdown>
        );
      },
    },
  ];
  return (
    <>
      {error ? (
        <ServerError {...error} />
      ) : (
        <CustomTable
        style={{padding: '20px'}}
          onChange={(pagination: any) => {
            dispatch(pointsRequest(userId, contactId, pagination));
          }}
          pagination={pagination}
          loading={loading}
          columns={columns}
          data={data}
        />
      )}
    </>
  );
};

export default PointsTable;
