import { Badge, Col, Row } from 'antd';
import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { ApplicationState } from '../../../store';

// import { Container } from './styles';

const MessagesRender: React.FC = () => {
  const observationInput = useSelector(
    (state: ApplicationState) =>
      state.shoppingCart.fields.find((item) => item.key === 'observation'),
    shallowEqual
  );
  const payment = useSelector(
    (state: ApplicationState) => state.shoppingCart.fields.find((item) => item.key === 'payment'),
    shallowEqual
  );
  const budgetValues = useSelector(
    (state: ApplicationState) =>
      state.shoppingCart.fields.filter(
        (item) => item.key !== 'observation' && item.key !== 'payment'
      ),
    shallowEqual
  );
  const budgetFields = useSelector((state: ApplicationState) => state.budgetFields.data);
  const address = useSelector((state: ApplicationState) => state.shoppingCart.selectedAddress);
  const config = useSelector((state: ApplicationState) => state.shoppingCart.config);
  const isBudget = useSelector((state: ApplicationState) => state.shoppingCart.budgetVisible);
  const result = useSelector((state: ApplicationState) => state.shoppingCart.result);
  const cart = useSelector((state: ApplicationState) => state.shoppingCart.cart);

  return (
    <Row>
      {!address && (
        <Col span={24}>
          <Badge status="error" text="Selecione um endereço." />
        </Col>
      )}
      {!payment && (
        <Col span={24}>
          <Badge status="error" text="Selecione uma forma de pagamento." />
        </Col>
      )}
      {result?.validations && payment && address && (
        <>
          {result.validations
            .filter((item) => item.validation !== 'WARNING')
            .map((item) => {
              return (
                <Col span={24}>
                  <Badge
                    status={item.validation === 'WARNING' ? 'warning' : 'error'}
                    text={item.message}
                  />
                </Col>
              );
            })}
        </>
      )}
      {isBudget && budgetFields.length !== budgetValues.length && (
        <Col span={24}>
          <Badge status="error" text="Preencha os campos de orçamento." />
        </Col>
      )}
      {config?.details.note.required && !observationInput && (
        <Col span={24}>
          <Badge status="error" text="Preencha o campo de observação." />
        </Col>
      )}
      {cart.length === 0 && (
        <Col span={24}>
          <Badge status="error" text="Carrinho está vazio." />
        </Col>
      )}
    </Row>
  );
};

export default MessagesRender;
