import React, { useState } from 'react';
import { Button, Modal, notification, Upload } from 'antd';
import { useSelector } from 'react-redux';
import { UploadOutlined, FileDoneOutlined } from '@ant-design/icons';
import { ApplicationState } from '../../store';
import { Container } from '../../styles';
import { DefaultButton } from '../../styles';
import { MESSAGES } from '../../utils/constants';
import { CSSProperties } from 'styled-components';
interface Props {
  accept?: string;
  url: string;
  type?: 'link' | 'text' | 'default' | 'ghost' | 'primary' | 'dashed' | undefined;
  style?: CSSProperties;
}

const { Dragger } = Upload;

const AttachmentFile: React.FC<Props> = ({ accept, url, type, style }) => {
  const layout = useSelector((state: ApplicationState) => state.layout.data);
  const [visible, setVisible] = useState<boolean>(false);

  const handleCancel = () => {
    setVisible(false);
  };

  const messageRender = (file: any) => {
    switch (file.status) {
      case 'error':
        notification.error({
          message: `${file.name} - ${file.response.message}`,
          placement: 'bottomRight',
        });
        break;
      case 'done':
        notification.success({
          message: `${file.name} - ${MESSAGES.UPLOAD_SUCCESS}`,
          placement: 'bottomRight',
        });
        break;
      default:
        break;
    }
  };

  return (
    <>
      <Button
        data-cy="btn-attach"
        icon={<FileDoneOutlined />}
        type={type}
        style={style}
        onClick={() => setVisible(true)}
      >
        Anexar documentos
      </Button>
      <Modal
        destroyOnClose
        data-cy="modal-sendReceipt"
        title="Anexar documentos"
        visible={visible}
        centered
        width={600}
        onCancel={handleCancel}
        footer={[
          <DefaultButton data-cy="btn-close" key="back" onClick={handleCancel} layout={layout}>
            Fechar
          </DefaultButton>,
        ]}
      >
        <Container layout={layout}>
          <Dragger
            multiple
            action={url}
            accept={accept}
            onChange={({ file }) => messageRender(file)}
            name="file"
          >
            <p className="ant-upload-drag-icon">
              <UploadOutlined style={{ color: layout?.color.default }} />
            </p>
            <p className="ant-upload-text">
              Clique ou arraste o(s) arquivo(s) para esta área para fazer o upload
            </p>
          </Dragger>
        </Container>
      </Modal>
    </>
  );
};

export default AttachmentFile;
