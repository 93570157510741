import styled from 'styled-components';
import { colors } from '../../utils/constants';

export const CampaignDescription = styled.div`
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,
    'Open Sans', 'Helvetica Neue', sans-serif;
  white-space: pre-wrap;
`;

export const ContainerInfo = styled.div`
  background-color: white;
  border-radius: 5px;
  box-shadow: 1px 2px 2px 2px ${colors.grey};
  padding: 20px;
`;
