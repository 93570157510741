import React, { Dispatch } from 'react';
import { Input } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Action } from 'typesafe-actions';
import { loadOrdersRequest } from '../../../store/ducks/myOrders/actions';
import { ApplicationState } from '../../../store';
import { loadBudgetsRequest } from '../../../store/ducks/budgets/actions';
const { Search } = Input;
interface Props {
  setSearch: any;
}

const MyOrdersSearch: React.FC<Props> = ({ setSearch }) => {
  const placeholder = useSelector((state: ApplicationState) => state.myOrders.config?.placeholder);
  const dispatch = useDispatch<Dispatch<Action>>();
  const onSearch = (value: any) => {
    setSearch(value);
    dispatch(loadOrdersRequest(undefined, undefined, undefined, value));
    dispatch(loadBudgetsRequest(undefined, undefined, undefined, value));
  };

  return (
    <Search
      data-cy="input-search"
      placeholder={`${placeholder ?? 'Buscar'}`}
      allowClear
      onSearch={onSearch}
      style={{ width: 400 }}
    />
  );
};

export default MyOrdersSearch;
