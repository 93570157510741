import styled from 'styled-components';
import { StarFilled, StarOutlined } from '@ant-design/icons';
import { colors } from '../../../utils/constants';

export const CardContainer = styled.div<{ hasValue?: boolean }>`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  min-height: ${({ hasValue }) => (hasValue ? '350px' : '300px')};
`;

export const ActiveFavoriteIcon = styled(StarFilled)`
  cursor: pointer;
  color: #e9c300;
  font-size: 16px;
`;

export const FavoriteIcon = styled(StarOutlined)`
  cursor: pointer;
  font-size: 16px;
  :hover {
    color: #e9c300;
  }
`;

export const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Value = styled.h2`
  color: green;
  margin: 0;
  font-size: 18px;
`;

export const Description = styled.h3`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin: 0;
`;

export const Stock = styled.h4`
  color: ${colors.writeGrey};
  margin: 0;
`;

export const CartTitle = styled.div`
  display: flex;
  align-items: center;
`;

export const CartFavorite = styled.div`
  margin-right: 10px;
`;

export const ImgContainer = styled.div`
  height: 260px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
