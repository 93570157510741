import { Tabs } from 'antd';
import React from 'react';
import { InfoCircleOutlined, SettingOutlined, LockOutlined } from '@ant-design/icons';
import BasicConfigs from './BasicConfigs';
import General from './General';
import Security from './Security';
import { Size, useWindowSize } from '../../customHooks';
import { colors } from '../../utils/constants';
const { TabPane } = Tabs;

const MyData: React.FC = () => {
  const size: Size = useWindowSize();

  return (
    <Tabs
      style={{
        borderRadius: '5px',
        boxShadow: `1px 2px 2px 2px ${colors.grey}`,
        backgroundColor: 'white',
        padding: '20px',
      }}
      defaultActiveKey="1"
      tabPosition="left"
    >
      <TabPane
        style={{ padding: '10px' }}
        tab={
          <div data-cy="tab-generalInfo">
            <InfoCircleOutlined style={{ margin: (size?.width ?? 0) > 1024 ? '10px' : 0 }} />
            {(size?.width ?? 0) > 1024 && 'Informações gerais'}
          </div>
        }
        key="1"
      >
        <General />
      </TabPane>
      <TabPane
        style={{ padding: '10px' }}
        tab={
          <div data-cy="tab-basicConfigs">
            <SettingOutlined style={{ margin: (size?.width ?? 0) > 1024 ? '10px' : 0 }} />
            {(size?.width ?? 0) > 1024 && 'Configurações básicas'}
          </div>
        }
        key="2"
      >
        <BasicConfigs />
      </TabPane>
      <TabPane
        style={{ padding: '10px' }}
        tab={
          <div data-cy="tab-security">
            <LockOutlined style={{ margin: (size?.width ?? 0) > 1024 ? '10px' : 0 }} />
            {(size?.width ?? 0) > 1024 && 'Segurança'}
          </div>
        }
        key="3"
      >
        <Security />
      </TabPane>
    </Tabs>
  );
};

export default MyData;
