import { Col, Row } from 'antd';
import React, { Dispatch } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Action } from 'typesafe-actions';
import { ApplicationState } from '../../../../store';
import { setFilter } from '../../../../store/ducks/showCase/actions';
import { Category } from '../../../../store/ducks/showCase/types';
import { maskCamelCase } from '../../../../utils/masks';

interface Props {
  closePopover: any;
}

const PopoverMenu: React.FC<Props> = ({ closePopover }) => {
  const categories = useSelector((state: ApplicationState) => state.productsShowCase.categories);
  const history = useHistory();

  const dispatch = useDispatch<Dispatch<Action>>();

  const handleMenuItemClick = (category: Category) => {
    dispatch(
      setFilter(category.list.length > 0 ? category.list.map((item) => item.key) : [category.key])
    );
    history.push('/showcase/products');
    closePopover();
  };

  return (
    <div style={{ maxWidth: '200px' }}>
      <Row gutter={[5, 5]}>
        {categories.map((category) => {
          return (
            <Col span={24}>
              <h3 style={{ cursor: 'pointer' }} onClick={() => handleMenuItemClick(category)}>
                {maskCamelCase(category.value)}
              </h3>
              <div style={{ paddingLeft: '20px' }}>
                {category.list.map((item) => {
                  return (
                    <p style={{ cursor: 'pointer' }} onClick={() => handleMenuItemClick(item)}>
                      {maskCamelCase(item.value)}
                    </p>
                  );
                })}
              </div>
            </Col>
          );
        })}
      </Row>
    </div>
  );
};

export default PopoverMenu;
