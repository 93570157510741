import React, { Dispatch, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Action } from 'typesafe-actions';
import FinancialTable from './FinancialTable';
import { Container, FinancialHeader } from './styles';
import FinancialSearch from './FinancialSearch';
import { ApplicationState } from '../../store';
import { loadFinancialRequest } from './../../store/ducks/financial/actions';

const Financial: React.FC = () => {
  const saved = useSelector((state: ApplicationState) => state.shoppingCart.success);
  const token = useSelector((state: ApplicationState) => state.auth.token);
  const [searchValue, setSearchValue] = useState<string>('');

  const dispatch = useDispatch<Dispatch<Action>>();
  useEffect(() => {
    if (saved) return;
    dispatch(loadFinancialRequest());
  }, [dispatch, token, saved]);

  return (
    <Container>
      <FinancialHeader>
        <FinancialSearch setSearch={setSearchValue} />
      </FinancialHeader>
      <FinancialTable search={searchValue} />
    </Container>
  );
};

export default Financial;
