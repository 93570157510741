import React from 'react';
import { Pie, measureTextWidth } from '@ant-design/plots';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../store';
import { CSSProperties } from 'styled-components';
import EmptyChart from '../EmptyChart';
interface Props {
  title: string;
  data: any[];
  angleField: string;
  colorField: string;
  onClick(values: any): void;
}
const DonutChart: React.FC<Props> = ({ title, data, angleField, colorField, onClick }) => {
  const layout = useSelector((state: ApplicationState) => state.layout.data);

  function renderStatistic(containerWidth: number, text: string, style: CSSProperties) {
    const { width: textWidth, height: textHeight } = measureTextWidth(text, style);
    const R = containerWidth / 2;
    let scale = 1;

    if (containerWidth < textWidth) {
      scale = Math.min(
        Math.sqrt(
          Math.abs(Math.pow(R, 2) / (Math.pow(textWidth / 2, 2) + Math.pow(textHeight, 2)))
        ),
        1
      );
    }

    const textStyleStr = `width:${containerWidth}px;`;
    return `<div style="${textStyleStr};font-size:${scale}em;line-height:${
      scale < 1 ? 1 : 'inherit'
    };">${text}</div>`;
  }

  const config = {
    appendPadding: 10,
    data,
    angleField: angleField,
    colorField: colorField,
    radius: 1,
    innerRadius: 0.64,
    meta: {
      value: {
        formatter: (value: number) => value,
      },
    },
    label: {
      type: 'inner',
      offset: '-50%',
      style: {
        textAlign: 'center',
      },
      autoRotate: false,
      content: '{value}',
    },
    statistic: {
      title: {
        offsetY: -4,
        customHtml: (container: HTMLElement, _view: any, datum: any) => {
          const { width, height } = container.getBoundingClientRect();
          const d = Math.sqrt(Math.pow(width / 2, 2) + Math.pow(height / 2, 2));
          const text = datum ? datum[colorField] : 'Total';
          return renderStatistic(d, text, {
            fontSize: 18,
          });
        },
      },
      content: {
        offsetY: 4,
        style: {
          fontSize: '20px',
        },
        customHtml: (container: HTMLElement, _view: any, datum: any, data: any) => {
          const { width } = container.getBoundingClientRect();
          const text = datum
            ? datum[angleField]
            : data.reduce((r: any, d: any) => r + d[angleField], 0);
          return renderStatistic(width, text, {
            fontSize: 32,
          });
        },
      },
      onReady: (plot: any) => {
        plot.on('element:click', ({ data: { data } }: any) => {
          onClick(data);
        });
      }
    },
    interactions: [
      {
        type: 'element-selected',
      },
      {
        type: 'element-active',
      },
      {
        type: 'pie-statistic-active',
      },
    ],
  };
  return data.length === 0 ? (
    <EmptyChart type={'donut'} />
  ) : (
    <div style={{ padding: '20px' }}>
      <h2 style={{ color: layout?.color.dark }}>{title}</h2>
      <Pie {...config} />
    </div>
  );
};

export default DonutChart;
