import React from 'react';
import { Area } from '@ant-design/plots';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../store';
import { maskCamelCase } from '../../../utils/masks';
import DataSet from '@antv/data-set';
import { Serie } from '../../../store/ducks/dashboard/types';
import EmptyChart from '../EmptyChart';
interface Props {
  series: Serie[];
  title: string;
  data: any[];
  xField: string;
  slider?: {
    start: number;
    end: number;
  };
  onClick(values: any): void;
}
const AreaChart: React.FC<Props> = ({ title, data, xField, slider, series, onClick }) => {
  const layout = useSelector((state: ApplicationState) => state.layout.data);
  const { DataView } = DataSet;
  const getColor = () => {
    const colors = [
      '#5B8FF9',
      '#61DDAA',
      '#65789B',
      '#F6BD16',
      '#7262fd',
      '#78D3F8',
      '#9661BC',
      '#F6903D',
      '#008685',
    ];
    return series.length > 1 ? colors : layout?.color.default;
  };
  const dv = new DataView().source(data);
  const arr: string[] = [];
  series.forEach((item) => {
    arr.push(item.yField?.fieldName || '');
  });
  dv.transform({
    type: 'fold',
    fields: arr,
    key: 'category',
    value: 'value',
  });
  const config = {
    label: series[0]?.circleIntersection
      ? {
          style: {
            fill: getColor(),
            fontWeight: 400,
            stroke: '#fff',
            lineWidth: 1,
          },
        }
      : undefined,
    data: dv.rows,
    color: getColor(),
    xField: xField,
    yField: 'value',
    seriesField: 'category',
    xAxis: {
      tickCount: 5,
    },
    point: series[0]?.circleIntersection ? {} : undefined,
    slider: slider,
    tooltip: {
      formatter: (datum: any) => ({
        name: maskCamelCase(datum.category),
        value: datum.value,
      }),
    },
    areaStyle:
      series.length === 1
        ? () => {
            return {
              fill: `l(270) 0:${layout?.color.lighter} 0.5:${layout?.color.light} 1:${layout?.color.default}`,
            };
          }
        : undefined,
    onReady: (plot: any) => {
        plot.on('element:click', ({ data: { data } }: any) => {
          onClick(data);
        });
    }
  };

  return data.length === 0 || series.length === 0 ? (
    <EmptyChart type={'area'} />
  ) : (
    <div style={{ padding: '20px' }}>
      <h2 style={{ color: layout?.color.dark }}>{title}</h2>
      <Area {...config} animation={false} />
    </div>
  );
};

export default AreaChart;
