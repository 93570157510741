import { ResponseError } from '../../../services/api';
import { Data } from '../../../utils/newTableHelper';

export enum OrderReleaseTypes {
  'RELEASES_REQUEST' = '@orderRelease/RELEASES_REQUEST',
  'RELEASES_SUCCESS' = '@orderRelease/RELEASES_SUCCESS',
  'RELEASES_FAILURE' = '@orderRelease/RELEASES_FAILURE',

  'APPROVE_REQUEST' = '@orderRelease/APPROVE_REQUEST',
  'APPROVE_SUCCESS' = '@orderRelease/APPROVE_SUCCESS',
  'APPROVE_FAILURE' = '@orderRelease/APPROVE_FAILURE',

  'DENY_REQUEST' = '@orderRelease/DENY_REQUEST',
  'DENY_SUCCESS' = '@orderRelease/DENY_SUCCESS',
  'DENY_FAILURE' = '@orderRelease/DENY_FAILURE',

  'CLEAN_STATUS' = '@orderRelease/CLEAN_STATUS',
}

export interface Pagination {
  current: number;
  total: number;
  pageSize: number;
}

export interface OrderReleaseState {
  readonly data: Data[];
  readonly status?: 'approved' | 'denied';
  readonly pagination?: Pagination;
  readonly loading: boolean;
  readonly statusLoading: boolean;
  readonly statusError?: ResponseError;
  readonly error?: ResponseError;
}
