import { ResponseError } from '../../../services/api';

export enum NewDeliveryPointTypes {
  'DELIVERIES_REQUEST' = '@newDeliveryPoint/DELIVERIES_REQUEST',
  'DELIVERIES_SUCCESS' = '@newDeliveryPoint/DELIVERIES_SUCCESS',
  'DELIVERIES_FAILURE' = '@newDeliveryPoint/DELIVERIES_FAILURE',

  'POINT_REQUEST' = '@newDeliveryPoint/POINT_REQUEST',
  'POINT_SUCCESS' = '@newDeliveryPoint/POINT_SUCCESS',
  'POINT_FAILURE' = '@newDeliveryPoint/POINT_FAILURE',

  'SEND_REQUEST' = '@newDeliveryPoint/SEND_REQUEST',
  'SEND_SUCCESS' = '@newDeliveryPoint/SEND_SUCCESS',
  'SEND_FAILURE' = '@newDeliveryPoint/SEND_FAILURE',

  'CLEAR_SEND' = '@newDeliveryPoint/CLEAR_SEND',

  'SET_ERROR' = '@newDeliveryPoint/SET_ERROR',
}

export interface deliveryOption {
  key: number;
  value: string;
}

export interface DeliveryPoint {
  contactId: number;
  creditLimit: number;
  deadline: number;
  delivPointContactId: number;
  delivPointId: number;
  name: string;
  partnerId: number;
  useLimit: boolean;
  userId: number;
}

export interface NewDeliveryPointState {
  readonly dataDeliveries: deliveryOption[];
  readonly dataPoint?: DeliveryPoint;
  readonly dataSend: string;
  readonly loadingDeliveries: boolean;
  readonly loadingSend: boolean;
  readonly saved: boolean;
  readonly errorDeliveries?: ResponseError;
  readonly errorPoint?: ResponseError;
  readonly errorSend?: ResponseError;
}
