import { ResponseError } from "../../../services/api";
import { Type } from "../../../utils/newTableHelper";

export enum OrderFieldsTypes {
  'LOAD_REQUEST' = '@newOrderFields/LOAD_REQUEST',
  'SET_VALUES' = '@newOrderFields/SET_VALUES',
  'CLEAR_VALUES' = '@newOrderFields/CLEAR_VALUES',
  'LOAD_SUCCESS' = '@newOrderFields/LOAD_SUCCESS',
  'LOAD_FAILURE' = '@newOrderFields/LOAD_FAILURE',
}

export interface FormFields {
  id: number;
  idField: number;
  fieldName: string;
  description: string;
  placeholder?: string;
  position: number;
  enable: boolean;
  tableName: string;
  foreignEntity?: string | null;
  fieldType: Type;
  defaultValue?: any;
  visible: boolean;
  required: boolean;
}

export interface OrderFieldsState {
  readonly data: FormFields[];
  readonly values: any[];
  readonly loading: boolean;
  readonly error?: ResponseError;
  readonly loaded: boolean;
}
