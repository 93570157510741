import React, { Dispatch, useEffect } from 'react';
import { Button, Col, Form, InputNumber, Row, Select, Switch } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Action } from 'typesafe-actions';
import { SaveOutlined } from '@ant-design/icons';
import { ApplicationState } from '../../../store';
import { ContainerForm } from '../styles';
import {
  clearSend,
  sendPointRequest,
  setError,
} from '../../../store/ducks/newDeliveryPoint/actions';
import { useWindowSize } from '../../../customHooks';

const { Option } = Select;

const PointForm: React.FC = () => {
  const layout = useSelector((state: ApplicationState) => state.layout.data);
  const deliveriesList = useSelector(
    (state: ApplicationState) => state.newDeliveryPoint.dataDeliveries
  );
  const dataPoint = useSelector((state: ApplicationState) => state.newDeliveryPoint.dataPoint);
  const loadingDeliveries = useSelector(
    (state: ApplicationState) => state.newDeliveryPoint.loadingDeliveries
  );
  const saved = useSelector((state: ApplicationState) => state.newDeliveryPoint.saved);
  const dataSend = useSelector((state: ApplicationState) => state.newDeliveryPoint.dataSend);
  const loadingSend = useSelector((state: ApplicationState) => state.newDeliveryPoint.loadingSend);
  const error = useSelector((state: ApplicationState) => state.newDeliveryPoint.errorSend);
  const [form] = Form.useForm();
  const size = useWindowSize();
  const history = useHistory();
  const { userId, contactId, id }: any = useParams();
  const dispatch = useDispatch<Dispatch<Action>>();

  useEffect(() => {
    if (!dataPoint || !id) {
      return;
    }
    form.setFieldsValue(dataPoint);
  }, [dataPoint, form, id]);

  useEffect(() => {
    if (!saved) {
      return;
    }
    const textFire = `Ponto de entrega (${dataSend}) ${id ? 'editado' : 'salvo'} com sucesso!`;
    Swal.fire({
      title: 'Sucesso!',
      text: `${textFire}`,
      icon: 'success',
      confirmButtonText: 'Ok',
      confirmButtonColor: layout?.color.default,
    }).then(() => {
      history.goBack();
      dispatch(clearSend());
    });
  }, [saved, dispatch, id, dataSend, userId, contactId, history, layout?.color.default]);

  useEffect(() => {
    if (!error) {
      return;
    }
    Swal.fire({
      title: 'Erro!',
      confirmButtonColor: layout?.color.default,
      text: error.message,
      icon: 'error',
      onClose: () => dispatch(setError(undefined)),
    }).then(() => {
      dispatch(setError(undefined));
    });
  }, [error, dispatch, layout?.color.default]);

  const onFinish = (values: any) => {
    dispatch(sendPointRequest(values, userId, contactId, id));
  };
  return (
    <Form form={form} onFinish={onFinish} layout="vertical">
      <ContainerForm>
        <Row gutter={[20, 20]}>
          <Col xs={24} sm={24} md={12} lg={6} xl={6}>
            <Form.Item
              label="Contato ponto de entrega"
              rules={[
                { required: true, message: `Por favor selecione um Contato ponto de entrega!` },
              ]}
              name="delivPointContactId"
            >
              <Select
                data-cy="input-selectAddress"
                loading={loadingDeliveries}
                placeholder="Selecione uma opção"
              >
                {deliveriesList.map((item) => {
                  return (
                    <Option key={item.value} value={item.key}>
                      {item.value}
                    </Option>
                  );
                })}
                {dataPoint && (
                  <Option key={dataPoint.delivPointContactId} value={dataPoint.delivPointContactId}>
                    {dataPoint?.name}
                  </Option>
                )}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={6} xl={6}>
            <Form.Item label="Dia base limite de crédito" name="deadline">
              <InputNumber data-cy="input-limitDay" style={{ width: '100%' }} min={1} max={30} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={6} xl={6}>
            <Form.Item label="Limite de crédito" name="creditLimit">
              <InputNumber data-cy="input-creditLimit" style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12} lg={6} xl={6}>
            <Form.Item
              label="Usa limite por ponto de entrega"
              name="useLimit"
              valuePropName="checked"
            >
              <Switch data-cy="input-useCreditLimit" />
            </Form.Item>
          </Col>
        </Row>
      </ContainerForm>
      <Row justify="end">
        <Button
          data-cy="btn-save"
          icon={<SaveOutlined />}
          block={(size.width ?? 0) <= 1024}
          type="primary"
          htmlType="submit"
          loading={loadingSend}
        >
          Salvar
        </Button>
      </Row>
    </Form>
  );
};

export default PointForm;
