import React, { Dispatch, useEffect, useState } from 'react';
import { TreeSelect } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Action } from 'typesafe-actions';
import { TreeNode } from 'antd/lib/tree-select';
import { ApplicationState } from '../../../store';
import { categoriesRequest } from '../../../store/ducks/dataSheet/actions';
import { Size, useWindowSize } from '../../../customHooks';

interface Props {
  setCategoryFilter: any;
  setPagination: any;
}
const { SHOW_CHILD } = TreeSelect;
const DataSheetCategory: React.FC<Props> = ({ setCategoryFilter, setPagination }) => {
  const categories = useSelector((state: ApplicationState) => state.dataSheet.categories);
  const loading = useSelector((state: ApplicationState) => state.dataSheet.loading);
  const dispatch = useDispatch<Dispatch<Action>>();
  const [value, setValue] = useState<any>();
  const renderNode = (node: any) => {
    return (
      <TreeNode key={node.key} value={node.key} title={node.value}>
        {node.list && node.list.map((child: any) => renderNode(child))}
      </TreeNode>
    );
  };
  const size: Size = useWindowSize();
  const onChange = (item: any) => {
    setValue(item);
    setCategoryFilter(item.map((e: string) => parseInt(e)));
    setPagination(undefined);
  };
  useEffect(() => {
    dispatch(categoriesRequest());
  }, [dispatch]);

  const tProps = {
    showSearch: false,
    categories,
    maxTagCount: 2,
    value: value,
    loading,
    allowClear: true,
    onChange: onChange,
    treeCheckable: true,
    showCheckedStrategy: SHOW_CHILD,
    placeholder: 'Selecione uma opção',
    style: {
      marginBottom: (size.width ?? 0) > 1024 ? '0px' : '10px',
      marginRight: '20px',
      minWidth: (size.width ?? 0) > 1024 ? '400px' : '100%',
    },
  };
  return (
    <div>
      <TreeSelect {...tProps}>{categories.map(renderNode)}</TreeSelect>
    </div>
  );
};

export default DataSheetCategory;
