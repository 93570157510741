export const colors = {
  grayBackgroud: '#F8F8F8',
  lightGrey: '#fbfbfb',
  darkGrey: '#838383',
  grey: '#e5e5e5',
  writeGrey: '#8C8C8C',
  success: '#52c41a',
  danger: '#ff4d4f',
  progress: '#fbb63b',
  approve: '#1890FF',
  whatsGreen: '#25d366',
  borderGrey: '#D9D9D9',
};

export const ROLE = {
  ADMIN: 'ROLE_ADMINISTRADOR_PORTAL',
  FINANCIAL: 'ROLE_MOVIMENTACAO_FINANCEIRA',
  ORDERS: 'ROLE_PEDIDOS',
  ORDER_DETAIL: 'ROLE_DETALHEMENTO_PEDIDOS',
  BOLETO: 'ROLE_DOWNLOAD_BOLETO',
  INVOICE: 'ROLE_DOWNLOAD_NOTA',
  OS: 'ROLE_OS',
  CAMPAIGN: 'ROLE_CAMPANHAS',
  FAQ: 'ROLE_FAQ',
  MY_DATA: 'ROLE_MEUS_DADOS',
  DATASHEET: 'ROLE_FICHA_TECNICA',
  NEW_ORDER: 'ROLE_NOVO_PEDIDO',
  ORDER_RELEASE: 'ROLE_LIBERACAO_PEDIDOS',
  ORDER_SERVICE: 'ROLE_ORDEM_SERVICO',
  ACCESS_CONTROL: 'ROLE_CONTROLE_ACESSO',
  REPORTS: 'ROLE_RELATORIOS',
  EDIT_ORDER: 'ROLE_EDITAR_PEDIDOS',
  REDO_ORDER: 'ROLE_REFAZER_PEDIDOS',
};

export const MESSAGES = {
  NOT_AUTHRIZED:
    'Você não tem permissão para acessar este recurso. Contacte o administrador do portal.',
  NOT_FOUND: 'Página não encontrada!',
  EDIT_ORDER_CONFIRMED: 'O Pedido só pode ser editado antes da confirmação.',
  EDIT_ORDER_ORIGIN: 'O pedido só pode ser editado se gerado pelo PEC.',
  UPLOAD_SUCCESS: 'enviado com sucesso!',
  UPLOAD_FAILED: 'Erro ao enviar documento(s)!',
  ERROR: 'Ocorreu um erro no servidor. Contate o administrador.',
  PASSWORD_ERROR:
    'A senha deve ter no mínimo 8 caracteres, sendo pelo menos um número, uma letra maiúscula e uma minúscula!',
};
