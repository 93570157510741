import React, { Dispatch, useEffect, useMemo, useState } from 'react';
import { Button, Form, Input } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom';
import { Action } from 'typesafe-actions';
import Swal from 'sweetalert2';
import { LockOutlined } from '@ant-design/icons';
import { cleanState, newPasswordRequest } from '../../store/ducks/auth/actions';
import { ApplicationState } from '../../store';
import { colors, MESSAGES } from '../../utils/constants';
import { useForm } from 'antd/lib/form/Form';
import { loadRequest } from '../../store/ducks/user/actions';

export interface Rules {
  required: boolean;
  message: string;
}

const NewPassword: React.FC = () => {
  function useQuery() {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
  }
  const query = useQuery();
  const token = useSelector((state: ApplicationState) => state.auth.token);
  const layout = useSelector((state: ApplicationState) => state.layout.data);
  const error = useSelector((state: ApplicationState) => state.auth.newPasswordError);
  const errorMessage = useSelector((state: ApplicationState) => state.auth.errorMessage);
  const message = useSelector((state: ApplicationState) => state.auth.successMessage);
  const loading = useSelector((state: ApplicationState) => state.auth.newPasswordLoading);
  const [redirect, setRedirect] = useState<string>('');
  const dispatch = useDispatch<Dispatch<Action>>();
  const dispatchClean = useDispatch<Dispatch<Action>>();
  const [form] = useForm();
  const onFinish = (e: any) => {
    dispatch(newPasswordRequest(query.get('key')?.replace('==', '') || '', e.new));
  };

  useEffect(() => {
    if (!token) return;
    dispatch(loadRequest(token));
    setRedirect('/home/');
  }, [dispatch, token]);

  useEffect(() => {
    if (loading || !message) return;
    Swal.fire({
      title: 'Sucesso!',
      confirmButtonColor: layout?.color.default,
      html: message,
      icon: 'success',
    }).then(() => {
      setRedirect('/login');
    });
  }, [dispatch, form, layout?.color.default, loading, message]);

  useEffect(() => {
    if (!error || loading) return;
    Swal.fire({
      title: 'Erro!',
      confirmButtonColor: layout?.color.default,
      html: errorMessage,
      icon: 'error',
    });
  }, [error, errorMessage, layout?.color.default, loading]);
  return (
    <div style={{ width: '100%' }}>
      <Form form={form} name="basic" onFinish={onFinish}>
        <Form.Item
          hasFeedback
          dependencies={['password']}
          rules={[
            {
              required: true,
              message: 'Por favor digite a nova senha!',
            },
            () => ({
              validator(_, value: string) {
                if (!value || value.match(/^(?=.{8,}$)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).*$/)) {
                  return Promise.resolve();
                }
                return Promise.reject(MESSAGES.PASSWORD_ERROR);
              },
            }),
          ]}
          name="new"
        >
          <Input.Password
            data-cy="input-newPassword"
            prefix={<LockOutlined style={{ color: colors.writeGrey }} />}
            placeholder="Nova senha"
          />
        </Form.Item>
        <Form.Item
          hasFeedback
          rules={[
            {
              required: true,
              message: 'Por favor confirme a nova senha!',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('new') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('As duas senhas devem ser iguais!'));
              },
            }),
          ]}
          name="confirm"
          dependencies={['new']}
        >
          <Input.Password
            data-cy="input-confirmPassword"
            prefix={<LockOutlined style={{ color: colors.writeGrey }} />}
            placeholder="Confirme a nova senha"
          />
        </Form.Item>
        <Form.Item>
          <Button
            className="dark-button"
            data-cy="btn-chagePassword"
            htmlType="submit"
            block
            type="primary"
            loading={loading}
          >
            Redefinir senha
          </Button>
        </Form.Item>
        <Form.Item style={{ marginTop: '-15px' }}>
          <Button
            data-cy="btn-backToLogin"
            type="link"
            block
            onClick={() => {
              dispatchClean(cleanState());
              setRedirect('/login');
            }}
          >
            Voltar para o login
          </Button>
        </Form.Item>
      </Form>
      {redirect && <Redirect to={redirect} />}
    </div>
  );
};

export default NewPassword;
