import { ResponseError } from '../../../services/api';

export enum AccessControlTypes {
  'LOAD_REQUEST' = '@accessControl/LOAD_REQUEST',
  'LOAD_SUCCESS' = '@accessControl/LOAD_SUCCESS',
  'LOAD_FAILURE' = '@accessControl/LOAD_FAILURE',
}
export interface Pagination {
  current: number;
  total: number;
  pageSize: number;
}

export interface Users {
  active: boolean;
  email: string;
  id: number;
  name: string;
}
export interface AccessControlState {
  readonly data: Users[];
  readonly pagination?: Pagination;
  readonly loading: boolean;
  readonly error?: ResponseError;
}
