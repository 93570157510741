import { Reducer } from 'redux';
import { ScreenFormState, ScreenFormTypes } from './types';

const INITIAL_STATE: ScreenFormState = {
  data: [],
  loading: false,
  sendLoading: false,
};

const reducer: Reducer<ScreenFormState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ScreenFormTypes.LOAD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ScreenFormTypes.LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        ...action.payload,
      };
    case ScreenFormTypes.LOAD_FAILURE:
      return {
        ...state,
        data: [],
        loading: false,
        ...action.payload,
      };

    case ScreenFormTypes.SEND_REQUEST:
      return {
        ...state,
        sendLoading: true,
      };
    case ScreenFormTypes.SEND_SUCCESS:
      return {
        ...state,
        sendLoading: false,
        error: undefined,
        ...action.payload,
      };
    case ScreenFormTypes.SEND_FAILURE:
      return {
        ...state,
        sendLoading: false,
        ...action.payload,
      };
    case ScreenFormTypes.CLEAN_STATE:
      return {
        ...state,
        sendError: undefined,
        success: undefined,
      };
    default:
      return state;
  }
};

export default reducer;
