import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import auth from './auth';
import layout from './layout';
import user from './user';
import homeOrders from './homeOrders';
import homeFinancials from './homeFinancials';
import banners from './banners';
import orderFields from './orderFields';
import products from './products';
import shoppingCart from './shoppingCart';
import storage from 'redux-persist/lib/storage';
import myOrders from './myOrders';
import financial from './financial';
import serviceOrders from './serviceOrders';
import serviceOrdersDetail from './serviceOrdersDetail';
import accessControl from './accessControl';
import faq from './faq';
import dataSheet from './dataSheet';
import myOrdersDetail from './myOrdersDetail';
import budgetFields from './budgetFields';
import orderRelease from './orderRelease';
import campaigns from './campaigns';
import campaign from './campaigns';
import reports from './reports';
import newUser from './newUser';
import newContact from './newContact';
import deliveryPoint from './deliveryPoint';
import newDeliveryPoint from './newDeliveryPoint';
import productDetail from './productDetail';
import homeCampaigns from './homeCampaingns';
import homeServiceOrders from './homeServiceOrders';
import budgets from './budgets';
import budgetDetail from './budgetDetail';
import homeProducts from './homeProducts';
import homeCards from './homeCards';
import newServiceOrder from './newServiceOrder';
import contacts from './contacts';
import screenForm from './screenForm';
import screenConstructor from './screenConstructor';
import dashboard from './dashboard';
import dashboardMenu from './dashboardMenus';
import campaignProducts from './campaignProducts';
import signUpFields from './signUpFields';
import screenEdit from './screenEdit';
import screenDetail from './screenDetail';
import productsShowCase from './showCase';
import productDetailShowCase from './showCaseDetail';
import additionalScreens from './additionalScreens';
export interface PersistConfig {
  key: string;
  storage: typeof storage;
  blacklist: string[];
}

const authPersistConfig: PersistConfig = {
  key: 'auth',
  storage: storage,
  blacklist: [
    'forgotPasswordMessage',
    'loginLoading',
    'loginError',
    'passwordLoading',
    'passwordError',
    'errorMessage',
  ],
};

const shoppingCartPersistConfig: PersistConfig = {
  key: 'shoppingCart',
  storage: storage,
  blacklist: [
    'paymentLoading',
    'paymentError',
    'addressLoading',
    'addressError',
    'loading',
    'error',
    'success',
    'dataSheet',
    'result',
    'budget',
    'orderSchedule',
    'observation',
    'cartVisibility',
    'config'
  ],
};

const ordersFieldsPersistConfig: PersistConfig = {
  key: 'orderFields',
  storage: storage,
  blacklist: ['data', 'loading', 'error', 'loaded'],
};
export default combineReducers({
  auth: persistReducer(authPersistConfig, auth),
  layout,
  user,
  banners,
  homeOrders,
  homeFinancials,
  orderFields: persistReducer(ordersFieldsPersistConfig, orderFields),
  products,
  shoppingCart: persistReducer(shoppingCartPersistConfig, shoppingCart),
  myOrders,
  financial,
  serviceOrders,
  serviceOrdersDetail,
  myOrdersDetail,
  accessControl,
  faq,
  dataSheet,
  budgetFields,
  orderRelease,
  campaigns,
  campaign,
  reports,
  newUser,
  newContact,
  deliveryPoint,
  newDeliveryPoint,
  productDetail,
  homeCampaigns,
  homeServiceOrders,
  budgets,
  budgetDetail,
  homeProducts,
  homeCards,
  newServiceOrder,
  contacts,
  screenForm,
  screenConstructor,
  dashboard,
  dashboardMenu,
  campaignProducts,
  productsShowCase,
  productDetailShowCase,
  signUpFields,
  screenEdit,
  screenDetail,
  additionalScreens,
});
