import { Reducer } from 'redux';
import { ServiceOrdersDetailState, ServiceOrdersDetailTypes } from './types';

const INITIAL_STATE: ServiceOrdersDetailState = {
  pagination: undefined,
  data: [],
  dataDetail: [],
  loading: false,
};

const reducer: Reducer<ServiceOrdersDetailState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ServiceOrdersDetailTypes.LOAD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ServiceOrdersDetailTypes.LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        errorData: undefined,
        ...action.payload,
      };
    case ServiceOrdersDetailTypes.LOAD_FAILURE:
      return {
        ...state,
        data: [],
        loading: false,
        errorData: action.payload.error,
      };
    case ServiceOrdersDetailTypes.DATA_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ServiceOrdersDetailTypes.DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        errorDetail: undefined,
        dataDetail: action.payload.data,
        configDetail: action.payload.config,
      };
    case ServiceOrdersDetailTypes.DATA_FAILURE:
      return {
        ...state,
        loading: false,
        dataDetail: [],
        errorDetail: action.payload.error,
      };
    default:
      return state;
  }
};

export default reducer;
