import { Modal, Input, List, Radio, Button } from 'antd';
import React, { Dispatch, useCallback, useEffect, useState } from 'react';
import { UserSwitchOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { Action } from 'typesafe-actions';

import { Description } from './styles';
import { loadPartnersRequest } from '../../store/ducks/user/actions';
import { Partner } from '../../store/ducks/user/types';
import { ApplicationState } from '../../store';
import { maskCpfCnpj } from '../../utils/masks';
import { updateRequest } from '../../store/ducks/auth/actions';
import { PartnerAuth } from '../../store/ducks/auth/types';
import { DefaultButton, SubmitButton } from '../../styles';
import { clearCart } from '../../store/ducks/shoppingCart/actions';

const { Search } = Input;

const PartnerModal: React.FC = () => {
  const partners = useSelector((state: ApplicationState) => state.user.partners);
  const user = useSelector((state: ApplicationState) => state.user.data);
  const loading = useSelector((state: ApplicationState) => state.auth.loginLoading);
  const layout = useSelector((state: ApplicationState) => state.layout.data);
  const token = useSelector((state: ApplicationState) => state.auth.token);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [partnersList, setPartnersList] = useState<Partner[]>(partners);
  const [selectedPartner, setSelectedPartner] = useState<PartnerAuth | undefined>();

  const dispatch = useDispatch<Dispatch<Action>>();

  useEffect(() => {
    dispatch(loadPartnersRequest());
  }, [dispatch]);

  const onSearch = useCallback(() => {
    const filteredPartners = partners.filter((item) => {
      const partnerName = item.partnerName?.toUpperCase();
      const cnpj = item.cnpj;
      const contactName = item.contactName?.toUpperCase();
      const corporateName = item.corporateName?.toUpperCase();
      const formatedCnpj = searchValue.replace(/\D+/g, '');
      return (
        partnerName.includes(searchValue?.toUpperCase()) ||
        contactName.includes(searchValue?.toUpperCase()) ||
        corporateName?.includes(searchValue?.toUpperCase()) ||
        (formatedCnpj ? cnpj?.includes(formatedCnpj) : false)
      );
    });

    setPartnersList(filteredPartners);
  }, [partners, searchValue]);

  useEffect(() => {
    onSearch();
  }, [onSearch]);

  const onChange = (value: any) => {
    setSelectedPartner(partners.find((item) => item === value));
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  useEffect(() => {
    setIsModalVisible(false);
  }, [token]);

  const handleOk = () => {
    dispatch(clearCart());
    dispatch(updateRequest(selectedPartner));
  };
  useEffect(() => {
    setSelectedPartner(
      partners.find(
        (item) =>
          item.idPartner === user?.partner.idPartner &&
          item.idContact === user?.contact.idContact
      )
    );
  }, [partners, user]);

  const handleCancel = () => {
    setSearchValue('');
    setSelectedPartner(
      partners.find(
        (item) =>
          item.idPartner === user?.partner.idPartner &&
          item.idContact === user?.contact.idContact
      )
    );
    setIsModalVisible(false);
  };
  return (
    <>
      <Button type="text" data-cy="btn-changePartner" onClick={showModal}>
        <UserSwitchOutlined style={{ fontSize: '18px' }} />
        Trocar de parceiro
      </Button>
      <Modal
        data-cy="modal-partners"
        title="Selecione um parceiro"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <DefaultButton data-cy="btn-cancel" layout={layout} key="back" onClick={handleCancel}>
            Cancelar
          </DefaultButton>,
          <SubmitButton
            data-cy="btn-select"
            layout={layout}
            key="submit"
            loading={loading}
            onClick={handleOk}
          >
            Selecionar
          </SubmitButton>,
        ]}
      >
        <Search
          data-cy="input-search"
          value={searchValue}
          onChange={(text) => setSearchValue(text.target.value)}
          onSearch={onSearch}
        />
        <List
          size="small"
          dataSource={partnersList}
          renderItem={(item: Partner) => (
            <List.Item>
              <List.Item.Meta
                title={
                  <Radio
                    value={item}
                    onChange={(e) => onChange(e.target.value)}
                    checked={selectedPartner === item}
                  >
                    <strong>{item.partnerName}</strong>
                  </Radio>
                }
                description={
                  <Description>
                    <small>Contato: {item.contactName?.toUpperCase()}</small>
                    {item?.corporateName && <small>Razão social: {item?.corporateName}</small>}
                    {item?.cnpj && <small>CNPJ/CPF: {maskCpfCnpj(item?.cnpj)}</small>}
                  </Description>
                }
              />
            </List.Item>
          )}
        />
      </Modal>
    </>
  );
};

export default PartnerModal;
