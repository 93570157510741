import styled from 'styled-components';

import { StarFilled, StarOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { Layout } from '../../store/ducks/layout/types';
import { colors } from '../../utils/constants';

export const Container = styled.div``;
export const Title = styled.h3``;

export const ActiveFavoriteIcon = styled(StarFilled)`
  cursor: pointer;
  color: #e9c300;
  font-size: 16px;
`;

export const FavoriteIcon = styled(StarOutlined)`
  cursor: pointer;
  font-size: 16px;
  :hover {
    color: #e9c300;
  }
`;

export const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px;
  margin-bottom: 20px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 1px 2px 2px 2px ${colors.grey};
  @media only screen and (max-width: 1160px) {
    flex-direction: column;
    justify-content: space-between;
  }
`;

export const FavoriteContent = styled.div`
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SortButton = styled(Button)<{ layout?: Layout; active?: boolean }>`
  background-color: white;
  color: ${({ layout, active }) => (active ? layout?.color.default : 'black')};

  :hover {
    background-color: white;
    color: ${({ layout, active }) => (active ? layout?.color.default : 'black')};
  }
  :active {
    background-color: white;
    color: ${({ layout, active }) => (active ? layout?.color.default : 'black')};
  }
  :focus {
    background-color: white;
    color: ${({ layout, active }) => (active ? layout?.color.default : 'black')};
  }
`;

export const Arrows = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0px 2px;
`;

export const ExtraButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 5px;
`;

export const SorterContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SorterContent = styled.div`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
`;

export const Content = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 1px 2px 2px 2px ${colors.grey};
`;
