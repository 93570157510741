import { AxiosResponse } from 'axios';
import { call, put, select } from 'redux-saga/effects';
import { ApplicationState } from '../..';
import api, { errorHandler } from '../../../services/api';
import { Config, Data } from '../../../utils/newTableHelper';
import { dataToTableData } from '../../../utils/newTableHelper';
import { refreshRequest } from '../auth/actions';
import {
  categoriesFailureShowCase,
  CategoriesSuccessShowCase,
  loadFailureShowCase,
  loadRequestShowCase,
  loadSuccessShowCase,
  setUnity,
  unitySuccess,
} from './actions';
import { Category } from './types';

export function* loadProductsShowCase({ payload }: ReturnType<typeof loadRequestShowCase>) {
  try {
    const state: ApplicationState = yield select();
    const config: AxiosResponse<Config> = yield call(api.get, '/new-order/products/template');
    const response: AxiosResponse<{
      content: Data[];
      numberOfElements: number;
      size: number;
      number: number;
    }> = yield call(api.post, `/product-showcase/products/all`, {
      page: (payload.pagination?.current || 1) - 1,
      size: payload.pagination?.pageSize || 12,
      filters:
        state.productsShowCase.filters.length > 0
          ? [
              {
                name: 'categoryId',
                value: state.productsShowCase.filters.map((item) => parseInt(item as string)),
              },
            ]
          : [],
      search: payload.search,
    });
    const tableData: any[] = yield call(dataToTableData, response.data.content, config.data);
    const filteredData = tableData.filter((item: any) => item?.UNIDADE.length > 0);
    const data = filteredData.map((item: any) => {
      return {
        ...item,
        SELECTED_UNITY: item?.UNIDADE[0].key,
      };
    });
    yield put(
      loadSuccessShowCase(
        data,
        config.data,
        {
          total: response.data.numberOfElements,
          pageSize: response.data.size,
          current: payload.pagination ? payload.pagination.current : response.data.number + 1,
        },
        payload.search
      )
    );
  } catch (err: any) {
    yield put(
      err?.response?.status !== 401 ? loadFailureShowCase(errorHandler(err)) : refreshRequest()
    );
  }
}

export function* loadCategoriesShowCase(): any {
  try {
    const response: AxiosResponse<Category[]> = yield call(api.get, '/product-showcase/categories');
    yield put(CategoriesSuccessShowCase(response.data));
  } catch (err: any) {
    yield put(
      err.response.status !== 401 ? categoriesFailureShowCase(errorHandler(err)) : refreshRequest()
    );
  }
}

export function* showCasechangeUnity({ payload }: ReturnType<typeof setUnity>) {
  const state: ApplicationState = yield select();
  const newData = state.productsShowCase.data;
  const index = newData.findIndex((item) => item?.CODPROD === payload.codProd);
  newData[index].SELECTED_UNITY = payload.unity;
  yield put(unitySuccess([...newData]));
}
