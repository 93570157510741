import React, { Dispatch, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Action } from 'typesafe-actions';
import { Tabs } from 'antd';

import MyOrdersTable from './MyOrdersTable';
import MyOrdersSearch from './MyOrdersSearch';
import { ApplicationState } from '../../store';
import { setTab } from './../../store/ducks/myOrders/actions';
import { Container, Content, MyOrdersHeader } from './styles';
import MyBudgetsTable from './MyBudgetsTable';

const TabPane = Tabs.TabPane;

const MyOrders: React.FC = () => {
  const tabKey = useSelector((state: ApplicationState) => state.myOrders.tab);
  const config = useSelector((state: ApplicationState) => state.myOrders.config);
  const [searchValue, setSearchValue] = useState<string>('');

  const dispatch = useDispatch<Dispatch<Action>>();

  return (
    <Container>
      <MyOrdersHeader>
        <MyOrdersSearch setSearch={setSearchValue} />
      </MyOrdersHeader>
      {config?.details.showBudget ? (
        <Content>
          <Tabs defaultActiveKey={tabKey} onChange={(key) => dispatch(setTab(key))}>
            <TabPane data-cy="tab-orders" tab="Pedidos" key="1">
              <MyOrdersTable search={searchValue} />
            </TabPane>
            <TabPane data-cy="tab-budgets" tab="Orçamentos" key="2">
              <MyBudgetsTable search={searchValue} />
            </TabPane>
          </Tabs>
        </Content>
      ) : (
        <MyOrdersTable search={searchValue} />
      )}
    </Container>
  );
};

export default MyOrders;
