import { Input } from 'antd';
import React, { Dispatch } from 'react';
import { useDispatch } from 'react-redux';
import { Action } from 'typesafe-actions';
import { useWindowSize } from '../../../customHooks';
import { controlRequest } from '../../../store/ducks/accessControl/actions';
const { Search } = Input;

interface Props {
  setSearch: any;
}
const SearchInput: React.FC<Props> = ({ setSearch }) => {
  const dispatch = useDispatch<Dispatch<Action>>();
  const size = useWindowSize();
  const onSearch = (value: any) => {
    setSearch(value);
    dispatch(controlRequest(undefined, value));
  };

  return (
    <Search
      data-cy="input-search"
      placeholder="Buscar"
      allowClear
      onSearch={onSearch}
      style={{
        width: (size.width ?? 0) > 1024 ? '400px' : '100%',
        marginTop: (size.width ?? 0) > 1024 ? '0px' : '10px',
      }}
    />
  );
};

export default SearchInput;
