import styled, { css } from 'styled-components';
import { colors } from '../../utils/constants';

interface HeaderProps {
  appearance: 'home' | 'child';
  hasForm?: boolean
}

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div<HeaderProps>`
  margin-bottom: 20px;

  ${({ appearance, hasForm }) => appearance === 'home' ?
    css`
    padding: 20px;
    display: flex;
    flex-direction: row;
    border-radius: 5px;
    box-shadow: 1px 2px 2px 2px ${colors.grey};
    justify-content: ${hasForm ? 'space-between' : 'flex-end'};
    background: white;
  `: css`
      background-color: #f0f0f0;
      padding: 10px;
    `}

  @media only screen and (max-width: 1024px) {
    flex-direction: column;
    justify-content: ${({ hasForm }) => hasForm ? 'space-between' : 'flex-end'};
  }
`;
