import {
  DatePicker,
  Form,
  Input,
  InputNumber,
  notification,
  Select,
  Slider,
  Spin,
  Switch,
  Upload,
} from 'antd';
import moment from 'moment';
import { UploadOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import React, { ReactNode, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import api from '../../services/api';
import { requestHeaders } from '../../utils/apiHelper';
import { ApplicationState } from '../../store';
import { cepMask, inputMaskCofCnpj, maskCurrency, phoneMask, unmask } from '../../utils/masks';
import locale from 'antd/es/date-picker/locale/pt_BR';
import 'moment/locale/pt-br';
import MaskedInput from 'react-text-mask';
import { testCNPJ, testCPF } from '../../utils/validators';
import { MESSAGES } from '../../utils/constants';
import { AxiosResponse } from 'axios';
import { fieldIsSwitch } from '../../utils/newTableHelper';

const { Dragger } = Upload;

const { RangePicker } = DatePicker;

type Type = 'S' | 'I' | 'O' | 'L' | 'D' | 'F' | 'R' | 'C' | 'B';
export type NewTypes =
  | 'OPTIONS'
  | 'BLOB'
  | 'BLOB_IMAGE'
  | 'CLOB'
  | 'STRING'
  | 'INTEGER'
  | 'FLOAT'
  | 'DATE'
  | 'DATE_TIME'
  | 'TIME'
  | 'TEXT_AREA'
  | 'SWITCH'
  | 'currency'
  | 'cpfCnpj'
  | 'phone'
  | 'date'
  | 'dateTime'
  | 'cep'
  | 'email'
  | 'multiSelect'
  | 'PASSWORD'
  | 'SEARCH';
export interface Props {
  type: Type | NewTypes;
  url?: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  name?: string;
  foreign?: string | null;
  key?: string;
  label?: ReactNode;
  value?: any;
  urlUnique?: string;
  list?: Options[];
  urlImage?: string;
}

interface Options {
  key: string | number;
  value: string | number;
}

const { TextArea } = Input;
const { Option } = Select;
const DynamicFields: React.FC<Props> = ({
  type,
  placeholder,
  required,
  disabled,
  name,
  foreign,
  key,
  url,
  label,
  value,
  urlUnique,
  list,
  urlImage,
}) => {
  const [isSwitch, setIsSwitch] = useState(false);
  const token = useSelector((state: ApplicationState) => state.auth.token);
  const layout = useSelector((state: ApplicationState) => state.layout.data);
  const [options, setOptions] = useState<Options[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [imageUrl, setImageUrl] = useState<string>('');

  const formatter = (value?: number): ReactNode => {
    return maskCurrency(value || 0);
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  useEffect(() => {
    if (!urlImage) return;
    const headers = requestHeaders(token);
    setLoading(true);
    api
      .get(urlImage, { headers: headers })
      .then((res: AxiosResponse<{ value?: string }>) => {
        setImageUrl(res.data.value ? `data:image/png;base64,${res.data.value}` : '');
        setLoading(false);
      })
      .catch((_err: any) => {
        setLoading(false);
      });
  }, [token, urlImage]);

  const getBase64 = (img: any, callback: any) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const beforeUpload = (file: any) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      notification.error({
        message: `Só é possível fazer upload de arquivos JPG ou PNG!`,
        placement: 'bottomRight',
      });
    }
    return isJpgOrPng;
  };

  const handleChange = (info: any) => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      getBase64(info.file.originFileObj, (imageUrl: string) => {
        setImageUrl(imageUrl);
        setLoading(false);
      });
    }
  };

  const messageRender = (file: any) => {
    switch (file.status) {
      case 'error':
        notification.error({
          message: `${file.name} - ${file.response.message}`,
          placement: 'bottomRight',
        });
        break;
      case 'done':
        notification.success({
          message: `${file.name} - ${MESSAGES.UPLOAD_SUCCESS}`,
          placement: 'bottomRight',
        });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (type !== 'O' && type !== 'OPTIONS' && type !== 'multiSelect' && type !== 'SEARCH') {
      return;
    }
    if (list) {
      setOptions(
        list.sort((a: Options, b: Options) => {
          return a.value > b.value ? 1 : b.value > a.value ? -1 : 0;
        })
      );
      return;
    }

    setLoading(true);
    const headers = requestHeaders(token);
    api
      .get(
        urlUnique ? urlUnique : `${url}/campos/${foreign}/campo/${name}/opcoes`,
        token
          ? {
            headers: headers,
          }
          : undefined
      )
      .then((response: any) => {
        setOptions(
          response.data.map((o: Options) => ({ key: o.key.toString(), value: o.value.toString() })).sort((a: Options, b: Options) => {
            return a.value > b.value ? 1 : b.value > a.value ? -1 : 0;
          })
        );

        setLoading(false);
        setIsSwitch(fieldIsSwitch(response.data));
      })
      .catch(() => {
        setLoading(false);
      });
  }, [type, url, name, foreign, token, urlUnique, list]);

  switch (type) {
    case 'I':
    case 'INTEGER':
      return (
        <Form.Item
          initialValue={value}
          rules={[{ required: required, message: `Campo ${label || placeholder} é obrigatório.` }]}
          key={name}
          label={label}
          name={name}
          style={{ marginBottom: '10px' }}
        >
          <InputNumber
            type="number"
            data-cy={`input-${name}`}
            parser={(e) => (e ? Math.floor(parseInt(e)) : '')}
            style={{ width: '100%' }}
            disabled={disabled}
            placeholder={placeholder}
          />
        </Form.Item>
      );

    case 'F':
    case 'FLOAT':
      return (
        <Form.Item
          initialValue={value}
          rules={[{ required: required, message: `Campo ${label || placeholder} é obrigatório.` }]}
          key={name}
          label={label}
          name={name}
          style={{ marginBottom: '10px' }}
        >
          <InputNumber
            data-cy={`input-${name}`}
            step={'1.00'}
            style={{ width: '100%' }}
            disabled={disabled}
            placeholder={placeholder}
            type="number"
          />
        </Form.Item>
      );
    case 'L':
    case 'TEXT_AREA':
      return (
        <Form.Item
          initialValue={value}
          rules={[{ required: required, message: `Campo ${label || placeholder} é obrigatório.`, whitespace: true }]}
          key={name}
          label={label}
          name={name}
          style={{ marginBottom: '10px' }}
        >
          <TextArea
            data-cy={`input-${name}`}
            rows={4}
            disabled={disabled}
            placeholder={placeholder}
          />
        </Form.Item>
      );
    case 'D':
    case 'DATE':
      return (
        <Form.Item
          initialValue={value ? moment(new Date(value), 'DD/MM/YYYY') : value}
          rules={[{ required: required, message: `Campo ${label || placeholder} é obrigatório.` }]}
          key={name}
          label={label}
          name={name}
          style={{ marginBottom: '10px' }}
        >
          <DatePicker
            data-cy={`input-${name}`}
            style={{ width: '100%' }}
            locale={locale}
            format="DD/MM/YYYY"
            disabled={disabled}
            placeholder={placeholder || 'Selecione uma data'}
          />
        </Form.Item>
      );
    case 'DATE_TIME':
      return (
        <Form.Item
          initialValue={value ? moment(new Date(value), 'DD/MM/YYYY') : value}
          rules={[{ required: required, message: `Campo ${label || placeholder} é obrigatório.` }]}
          key={name}
          label={label}
          name={name}
          style={{ marginBottom: '10px' }}
        >
          <DatePicker
            data-cy={`input-${name}`}
            style={{ width: '100%' }}
            locale={locale}
            format="DD/MM/YYYY HH:mm"
            disabled={disabled}
            placeholder={placeholder || 'Selecione uma data'}
            showTime
          />
        </Form.Item>
      );
    case 'O':
    case 'OPTIONS': {
      if (loading)
        return (
          <Spin
            style={{ color: layout?.color.default }}
            spinning
            size="large"
            delay={500}
            indicator={<LoadingOutlined />}
          ></Spin>
        )

      return isSwitch ? (
        <Form.Item
          rules={[{ required: required, message: `Campo ${label || placeholder} é obrigatório.` }]}
          key={name}
          label={label}
          name={name}
          style={{ marginBottom: '10px' }}
          normalize={switchValue => switchValue ? 'S' : 'N'}
        >
          <Switch data-cy={`input-${name}`} disabled={disabled} defaultChecked={value === 'S'} />
        </Form.Item>) : (
        <Form.Item
          initialValue={value?.toString()}
          rules={[{ required: required, message: `Campo ${label || placeholder} é obrigatório.` }]}
          key={name}
          label={label}
          name={name}
          style={{ marginBottom: '10px' }}
        >
          <Select
            data-cy={`input-${name}`}
            style={{ width: '100%' }}
            allowClear
            placeholder={placeholder || 'Selecione uma opção'}
            loading={loading}
            key={key}
            disabled={disabled}
            showSearch
            filterOption={(input: any, option: any) =>
              option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            aria-autocomplete='none'
          >
            {options.map((item) => {
              return (
                <Option key={item.key} value={item.key}>
                  {item.value}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
      );

    }
    case 'R':
      return (
        <Form.Item
          initialValue={value?.map((item: any) => moment(item, 'DD/MM/YYYY'))}
          rules={[{ required: required, message: `Campo ${label || placeholder} é obrigatório.` }]}
          key={name}
          label={label}
          name={name}
          style={{ marginBottom: '10px' }}
        >
          <RangePicker
            data-cy={`input-${name}`}
            disabled={disabled}
            locale={locale}
            format="DD/MM/YYYY"
            style={{ width: '100%' }}
          />
        </Form.Item>
      );
    case 'C':
      return (
        <Form.Item
          initialValue={value}
          rules={[{ required: required, message: `Campo ${label || placeholder} é obrigatório.` }]}
          key={name}
          label={label}
          name={name}
          style={{ marginBottom: '10px' }}
          data-cy={`input-${name}`}
        >
          <Slider
            disabled={disabled}
            range
            min={0.0}
            max={10000.0}
            step={0.01}
            tipFormatter={formatter}
            style={{ minWidth: '200px' }}
          />
        </Form.Item>
      );
    case 'B':
    case 'SWITCH':
      return (
        <Form.Item
          initialValue={value}
          valuePropName="checked"
          rules={[{ required: required, message: `Campo ${label || placeholder} é obrigatório.` }]}
          key={name}
          label={label}
          name={name}
          style={{ marginBottom: '10px' }}
        >
          <Switch data-cy={`input-${name}`} disabled={disabled} />
        </Form.Item>
      );
    case 'cpfCnpj':
      return (
        <Form.Item
          initialValue={value}
          rules={[
            { required: required, message: `Campo ${label || placeholder} é obrigatório.` },
            () => ({
              validator(_, value: string) {
                if (!value) return Promise.resolve();
                if (testCPF(unmask(value)) || testCNPJ(unmask(value))) {
                  return Promise.resolve();
                }
                return Promise.reject('CPF/CNPJ inválido.');
              },
            }),
          ]}
          key={name}
          label={label}
          name={name}
          style={{ marginBottom: '10px' }}
        >
          <MaskedInput
            className="ant-input"
            mask={inputMaskCofCnpj}
            data-cy={`input-${name}`}
            disabled={disabled}
            placeholder={placeholder}
          />
        </Form.Item>
      );
    case 'phone':
      return (
        <Form.Item
          initialValue={value}
          rules={[
            { required: required, message: `Campo ${label || placeholder} é obrigatório.` },
            () => ({
              validator(_, value: string) {
                if (!value) return Promise.resolve();
                if (unmask(value)?.length >= 10) {
                  return Promise.resolve();
                }
                return Promise.reject('Número de telefone inválido.');
              },
            }),
          ]}
          key={name}
          label={label}
          name={name}
          style={{ marginBottom: '10px' }}
        >
          <MaskedInput
            className="ant-input"
            mask={phoneMask}
            data-cy={`input-${name}`}
            disabled={disabled}
            placeholder={placeholder}
          />
        </Form.Item>
      );
    case 'cep':
      return (
        <Form.Item
          initialValue={value}
          rules={[
            { required: required, message: `Campo ${label || placeholder} é obrigatório.` },
            () => ({
              validator(_, value: string) {
                if (!value) return Promise.resolve();
                if (unmask(value)?.length === 8) {
                  return Promise.resolve();
                }
                return Promise.reject('CEP inválido.');
              },
            }),
          ]}
          key={name}
          label={label}
          name={name}
          style={{ marginBottom: '10px' }}
        >
          <MaskedInput
            className="ant-input"
            mask={cepMask}
            data-cy={`input-${name}`}
            disabled={disabled}
            placeholder={placeholder}
          />
        </Form.Item>
      );

    case 'email':
      return (
        <Form.Item
          initialValue={value}
          rules={[{ required: required, message: `Campo ${label || placeholder} é obrigatório.` }]}
          key={name}
          label={label}
          name={name}
          style={{ marginBottom: '10px' }}
        >
          <Input
            type="email"
            data-cy={`input-${name}`}
            disabled={disabled}
            placeholder={placeholder}
          />
        </Form.Item>
      );
    case 'currency':
      return (
        <Form.Item
          initialValue={value}
          rules={[{ required: required, message: `Campo ${label || placeholder} é obrigatório.` }]}
          key={name}
          label={label}
          name={name}
          style={{ marginBottom: '10px' }}
        >
          <InputNumber
            style={{ width: '100%' }}
            formatter={(value: any) => `R$ ${value}`}
            parser={(value: any): number => Number(value ? value.replace(/[^0-9.]/g, '') : 0)}
            min={0}
            precision={2}
            disabled={disabled}
            placeholder={placeholder}
          />
        </Form.Item>
      );

    case 'BLOB':
      return (
        <Form.Item
          initialValue={value}
          rules={[{ required: required, message: `Campo ${label || placeholder} é obrigatório.` }]}
          key={name}
          label={label}
          name={name}
          style={{ marginBottom: '10px' }}
        >
          <Dragger
            disabled={disabled}
            action={urlUnique}
            onChange={({ file }) => messageRender(file)}
            name="file"
            multiple={false}
            maxCount={1}
            defaultFileList={[]}
          >
            <p className="ant-upload-drag-icon">
              <UploadOutlined style={{ color: layout?.color.default }} />
            </p>
            <p className="ant-upload-text">
              Clique ou arraste o arquivo para esta área para fazer o upload
            </p>
          </Dragger>
        </Form.Item>
      );

    case 'BLOB_IMAGE':
      return (
        <Form.Item
          initialValue={value}
          rules={[{ required: required, message: `Campo ${label || placeholder} é obrigatório.` }]}
          key={name}
          label={label}
          name={name}
          style={{ marginBottom: '10px' }}
        >
          <Upload
            disabled={disabled}
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            action={urlUnique}
            beforeUpload={beforeUpload}
            onChange={handleChange}
          >
            {imageUrl ? (
              <img src={imageUrl} alt="avatar" style={{ maxWidth: '100%', maxHeight: '100%' }} />
            ) : (
              uploadButton
            )}
          </Upload>
        </Form.Item>
      );

    case 'multiSelect':
      return (
        <Form.Item
          initialValue={value}
          rules={[{ required: required, message: `Campo ${label || placeholder} é obrigatório.` }]}
          key={name}
          label={label}
          name={name}
          style={{ marginBottom: '10px' }}
        >
          <Select
            data-cy={`input-${name}`}
            style={{ width: '100%' }}
            mode="multiple"
            allowClear
            placeholder={placeholder || 'Selecione uma opção'}
            loading={loading}
            key={key}
            disabled={disabled}
            showSearch
            filterOption={(input: any, option: any) =>
              option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            aria-autocomplete='none'
          >
            {options.map((item) => {
              return (
                <Option key={item.key} value={item.key}>
                  {item.value}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
      );

    case 'SEARCH':
      return (
        <Form.Item
          initialValue={value}
          rules={[{ required: required, message: `Campo ${label || placeholder} é obrigatório.` }]}
          key={name}
          label={label}
          name={name}
          style={{ marginBottom: '10px' }}
        >
          <Select
            data-cy={`input-${name}`}
            style={{ width: '100%' }}
            allowClear
            placeholder={placeholder || 'Selecione uma opção'}
            loading={loading}
            key={key}
            disabled={disabled}
            showSearch
            filterOption={(input: any, option: any) =>
              option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            aria-autocomplete='none'
          >
            {options.map((item) => {
              return (
                <Option key={item.key} value={item.key}>
                  {item.value}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
      );

    case 'PASSWORD':
      return (
        <Form.Item
          initialValue={value}
          rules={[{ required: required, message: `Campo ${label || placeholder} é obrigatório.`, whitespace: true }]}
          key={name}
          label={label}
          name={name}
          style={{ marginBottom: '10px' }}
        >
          <Input.Password data-cy={`input-${name}`} disabled={disabled} placeholder={placeholder} />
        </Form.Item>
      );

    default:
      return (
        <Form.Item
          initialValue={value}
          rules={[{ required: required, message: `Campo ${label || placeholder} é obrigatório.`, whitespace: true }]}
          key={name}
          label={label}
          name={name}
          style={{ marginBottom: '10px' }}
        >
          <Input data-cy={`input-${name}`} disabled={disabled} placeholder={placeholder} />
        </Form.Item>
      );
  }
};

export default DynamicFields;
