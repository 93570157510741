import { Reducer } from 'redux';
import { ProductsShowCaseState, ProductsShowCaseTypes } from './types';

const INITIAL_STATE: ProductsShowCaseState = {
  filters: [],
  pagination: {
    current: 1,
    pageSize: 12,
    total: 12,
  },
  data: [],
  categories: [],
  loading: false,
  loadingCategories: false,
};

const reducer: Reducer<ProductsShowCaseState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ProductsShowCaseTypes.LOAD_REQUEST:
      return {
        ...state,
        loading: true,
        loadingConfig: true,
      };

    case ProductsShowCaseTypes.LOAD_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
        errorProducts: undefined,
      };
    case ProductsShowCaseTypes.LOAD_FAILURE:
      return {
        ...state,
        data: [],
        loading: false,
        loadingConfig: false,
        errorProducts: action.payload.error,
      };

    case ProductsShowCaseTypes.LOAD_REQUEST_CATEGORY:
      return {
        ...state,
        loadingCategories: true,
      };
    case ProductsShowCaseTypes.LOAD_SUCCESS_CATEGORY:
      return {
        ...state,
        loadingCategories: false,
        errorCategory: undefined,
        categories: action.payload.data,
      };
    case ProductsShowCaseTypes.LOAD_FAILURE_CATEGORY:
      return {
        ...state,
        categories: [],
        loadingCategories: false,
        error: action.payload.error,
      };

    case ProductsShowCaseTypes.UNITY_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        loadingConfig: false,
        data: action.payload.data,
      };

    case ProductsShowCaseTypes.SET_FILTERS:
      return {
        ...state,
        filters: action.payload.filters,
      };

    case ProductsShowCaseTypes.SET_SEARCH:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
