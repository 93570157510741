import { AxiosResponse } from 'axios';
import { call, put, select } from 'redux-saga/effects';
import { ApplicationState } from '../..';
import api, { errorHandler } from '../../../services/api';
import { requestHeaders } from '../../../utils/apiHelper';
import { refreshRequest } from '../auth/actions';
import { loadFieldsFailure, loadFieldsSuccess, setValues } from './actions';
import { FormFields } from './types';

export function* loadOrderFields() {
  try {
    const state: ApplicationState = yield select();
    const headers = requestHeaders(state.auth.token);
    const response: AxiosResponse<FormFields[]> = yield call(
      api.get,
      '/new-order/filters/required/all',
      { headers: headers }
    );
    yield put(
      loadFieldsSuccess(
        response.data.sort((a, b) => {
          if (a.position < b.position) {
            return -1;
          }
          if (a.position > b.position) {
            return 1;
          }
          return 0;
        })
      )
    );

    if (state.orderFields.values.length === 0) {
      const defaultValuesFields: FormFields[] = response.data.filter((item) => item.defaultValue);
      yield put(
        setValues(
          defaultValuesFields.map((item) => {
            return { key: item.fieldName, value: item.defaultValue };
          })
        )
      );
    }

  } catch (err: any) {
    yield put(
      err.response.status !== 401 ? loadFieldsFailure(errorHandler(err)) : refreshRequest()
    );
  }
}
