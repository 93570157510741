import { List, Image, Spin, Tooltip, Col, Row } from 'antd';
import React, { Dispatch } from 'react';
import QuantityInput from '../QuantityInput';
import defaultImg from '../../assets/img/default.jpg';
import { LoadingOutlined, DeleteFilled } from '@ant-design/icons';

import { ShoppingCartItem } from '../../store/ducks/shoppingCart/types';
import { maskCamelCase, maskCurrency } from '../../utils/masks';
import { baseURL } from '../../services/api';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../store';
import { ProductDescription, ImageContainer, ListItem, TotalQauntity, ValueText } from './styles';
import { Action } from 'typesafe-actions';
import { deleteItemRequest } from '../../store/ducks/shoppingCart/actions';
import Swal from 'sweetalert2';
import PromoTag from '../PromoTag';
import { colors } from '../../utils/constants';
import { formatNumberTwoDecimalPlaces } from '../../utils/formatNumberHelper';

const CartItem: React.FC<ShoppingCartItem> = (item) => {
  const layout = useSelector((state: ApplicationState) => state.layout.data);
  const { currentAction, currentActionItemId, config } = useSelector((state: ApplicationState) => state.shoppingCart);
  const { insertProductOutOfStock } = useSelector((state: ApplicationState) => state.products.config?.details || {});

  const deleteItem = () => {
    Swal.fire({
      title: 'Atenção!',
      text: `Deseja deletar o item do carrinho?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: layout?.color.default,
      confirmButtonText: 'Continuar',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) dispatch(deleteItemRequest(item));
    });
  };
  const dispatch = useDispatch<Dispatch<Action>>();

  const getTitleStyle = () => {
    const quantity = item.quantity || 0;
    const stock = item.stock || 0

    if (insertProductOutOfStock) return undefined;

    return {
      color: item.hasStock && quantity > stock ? colors.danger : undefined
    }
  }

  return (
    <ListItem>
      <List.Item>
        <List.Item.Meta
          title={
            <Row justify='space-between'>
              <Col span={22}>
                <ProductDescription style={getTitleStyle()}>{item.description}</ProductDescription>
              </Col>

              <Col span={2}>
                <Row justify='end' align="middle">
                  <div>
                    <Tooltip placement="left" title="Remover item">
                      {
                        currentActionItemId === item.codProd && currentAction === 'REMOVE' ? <LoadingOutlined /> :
                          <DeleteFilled onClick={deleteItem} style={{ fontSize: '16px', color: 'red' }} />
                      }
                    </Tooltip>
                  </div>
                </Row>
              </Col>
            </Row>
          }
          avatar={
            config?.details.viewModes.some(
              (mode: string) => mode === 'CARD' || mode === 'LIST'
            ) && (
              <ImageContainer>
                <Image
                  preview={false}
                  fallback={defaultImg}
                  loading="lazy"
                  placeholder={
                    <Spin
                      indicator={<LoadingOutlined style={{ color: layout?.color.default }} />}
                    />
                  }
                  style={{ maxHeight: '100%', maxWidth: '100%' }}
                  src={`${baseURL}/produtos/${item?.codProd}/imagem?w=50&h=50`}
                />
              </ImageContainer>
            )
          }
          description={
            <Row gutter={[5, 5]}>
              {item.isPromo && (
                <Col span={24}>
                  <PromoTag />
                </Col>
              )}
              <Col span={24}>
                <div>
                  {maskCurrency(item.value || 0)} {item.value ? '/' : ''}
                  {maskCamelCase(item.unity?.value || '')}
                </div>
                <div>
                  {item.message && <small style={{ color: colors.danger }}>{item.message}</small>}
                </div>
              </Col>

              <Col span={24}>
                <Row align="middle" justify='space-between' gutter={[10, 0]}>
                  <Col span={14}>
                    <QuantityInput {...item} hasValidation width={140} />
                    {
                      item.multiplier && <TotalQauntity>(Total: {formatNumberTwoDecimalPlaces((item.quantity || 0) * item.multiplier)})</TotalQauntity>
                    }
                  </Col>
                  <Col span={10}>
                    <Row justify='end'>
                      <ValueText>
                        {maskCurrency((item.value || 0) * ((item.quantity || 0) * (item.multiplier || 1)))}
                      </ValueText>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          }
        />
      </List.Item>
    </ListItem>
  );
};

export default CartItem;
