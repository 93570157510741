import { Carousel, Spin } from 'antd';
import React, { Dispatch, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Action } from 'typesafe-actions';
import { LoadingOutlined } from '@ant-design/icons';
import { ApplicationState } from '../../store';

import {
  BannerContainer,
  BannerImage,
  BannersCarousel,
  BannerTitle,
  BannerTitleContent,
} from './styles';
import { loadRequest } from '../../store/ducks/banners/actions';
import { Banner } from '../../store/ducks/banners/types';

const Banners: React.FC = () => {
  const banners = useSelector((state: ApplicationState) => state.banners.data);
  const token = useSelector((state: ApplicationState) => state.auth.token);
  const loading = useSelector((state: ApplicationState) => state.banners.loading);
  const layout = useSelector((state: ApplicationState) => state.layout.data);
  const [speed, setSpeed] = useState<number>(2000);
  const dispatch = useDispatch<Dispatch<Action>>();

  useEffect(() => {
    dispatch(loadRequest());
  }, [dispatch, token]);

  const speedHandler = (current: number) => {
    setSpeed(banners[current].tmpExec * 1000 || 2000);
  };

  const renderImages = (banner: Banner) => {
    return (
      <BannerContainer
        data-cy={`banner-${banner.bannerId}`}
        key={banner.bannerId}
        hasDots={banners.length > 1}
      >
        <BannerTitleContent hasTitle={!!banner.title}>
          {banner.title && (
            <BannerTitle data-cy={`bannerTitle-${banner.bannerId}`}>{banner.title}</BannerTitle>
          )}
        </BannerTitleContent>
        <BannerImage
          hasLink={!!banner.link}
          onClick={() => (banner.link ? window.open(banner.link, '_blank')?.focus() : '')}
          src={
            banner.imageType === 'I' ? `data:image/png;base64,${banner?.image}` : banner?.imageUrl
          }
          alt="presentation"
          title={banner.link}
        />
      </BannerContainer>
    );
  };
  return (
    <>
      <Spin
        style={{ color: layout?.color.default }}
        spinning={loading}
        size="large"
        delay={500}
        indicator={<LoadingOutlined />}
      >
        {banners.length > 0 && (
          <BannersCarousel layout={layout}>
            <Carousel afterChange={speedHandler} autoplaySpeed={speed} autoplay effect="fade">
              {banners.map(renderImages)}
            </Carousel>
          </BannersCarousel>
        )}
      </Spin>
    </>
  );
};

export default Banners;
