import ChangePassword from '../views/ChangePassword';
import ForgotPassword from '../views/ForgotPassword';
import Login from '../views/Login';
import NewPassword from '../views/NewPassword';
import ShowCase from '../views/ShowCase';
import ShowCaseDetail from '../views/ShowCaseDetail';
import ShowCaseHome from '../views/ShowCaseHome';
import SignUp from '../views/SignUp';
import { RouteType } from './routeType';

export const loginRoute: RouteType = {
  path: 'login',
  name: 'login',
  component: Login,
  layout: '/',
  isPrivate: false,
  roles: [],
};

export const showCaseHome: RouteType = {
  path: 'showcase',
  name: 'showcaseHome',
  component: ShowCaseHome,
  layout: '/',
  isPrivate: false,
  roles: [],
};

export const showCaseRoute: RouteType = {
  path: 'showcase/products',
  name: 'showcaseProducts',
  component: ShowCase,
  layout: '/',
  isPrivate: false,
  roles: [],
};

export const showCaseDetailRoute: RouteType = {
  path: 'showcase/products/:id/detalhes',
  name: 'showcaseDetails',
  component: ShowCaseDetail,
  layout: '/',
  isPrivate: false,
  roles: [],
};

export const forgotPasswordRoute: RouteType = {
  path: 'redefinir-senha',
  name: 'Redefinir senha',
  component: ForgotPassword,
  layout: '/',
  isPrivate: false,
  roles: [],
};

export const newPasswordRoute: RouteType = {
  path: 'nova-senha',
  name: 'Nova senha',
  component: NewPassword,
  layout: '/',
  isPrivate: false,
  roles: [],
};

export const changePasswordRoute: RouteType = {
  path: 'trocar-senha',
  name: 'Trocar senha',
  component: ChangePassword,
  layout: '/',
  isPrivate: false,
  roles: [],
};

export const signUpRoute: RouteType = {
  path: 'signup',
  name: 'solicitação de acesso',
  component: SignUp,
  layout: '/',
  isPrivate: false,
  roles: [],
};
