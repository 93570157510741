import { Scatter } from '@ant-design/charts';
import React from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../store';
import { Metadata } from '../../../store/ducks/dashboard/types';
import DataSet from '@antv/data-set';
import EmptyChart from '../EmptyChart';

interface Props {
  xField: string;
  yField: string;
  title: string;
  data: any[];
  metaData: Metadata[];
  onClick(values: any): void;
}
const BubbleChart: React.FC<Props> = ({ title, data, yField, xField, metaData, onClick }) => {
  const layout = useSelector((state: ApplicationState) => state.layout.data);
  const { DataView } = DataSet;
  const colors = [
    '#5B8FF9',
    '#61DDAA',
    '#65789B',
    '#F6BD16',
    '#7262fd',
    '#78D3F8',
    '#9661BC',
    '#F6903D',
    '#008685',
  ];
  const arr: string[] = [];
  const dv = new DataView().source(data);
  metaData.forEach((item) => {
    if (item.name === xField || item.name === yField) return;
    arr.push(item.name);
  });
  dv.transform({
    type: 'fold',
    fields: arr,
    key: 'grupo',
    value: 'valor',
  });
  dv.transform({
    type: 'filter',
    callback(row) {
      return row.valor;
    },
  });
  const config: any = {
    appendPadding: 30,
    data: dv.rows,
    xField: xField,
    yField: yField,
    colorField: 'grupo',
    color: colors.map((item) => `r(0.4, 0.3, 0.7) 0:rgba(255,255,255,0.5) 1:${item}`),
    sizeField: 'valor',
    size: [5, 20],
    shape: 'circle',
    yAxis: {
      nice: true,
      line: {
        style: {
          stroke: '#eee',
        },
      },
    },
    xAxis: {
      grid: {
        line: {
          style: {
            stroke: '#eee',
          },
        },
      },
      line: {
        style: {
          stroke: '#eee',
        },
      },
    },
    onReady: (plot: any) => {
      plot.on('element:click', ({ data: { data } }: any) => {
        onClick(data);
      });
    }
  };

  return data.length === 0 || metaData.length === 0 ? (
    <EmptyChart type={'bubble'} />
  ) : (
    <div style={{ padding: '20px' }}>
      <h2 style={{ color: layout?.color.dark }}>{title}</h2>
      <Scatter {...config} />
    </div>
  );
};

export default BubbleChart;
