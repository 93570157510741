import { action } from 'typesafe-actions';
import { ResponseError } from '../../../services/api';
import { DashboardConfig, DashboardData, DashboardTypes } from './types';

export const dashboardConfigRequest = (id: number) =>
  action(DashboardTypes.LOAD_CONFIG_REQUEST, { id });
export const dashboardConfigSuccess = (config: DashboardConfig) =>
  action(DashboardTypes.LOAD_CONFIG_SUCCESS, { config });
export const dashboardConfigFailure = (error: ResponseError) =>
  action(DashboardTypes.LOAD_CONFIG_FAILURE, { error });

export const dashboardRequest = (params: any, levelId: string, id: number, elementsToBeUpdate?: string[]) =>
  action(DashboardTypes.LOAD_REQUEST, { params, levelId, id, elementsToBeUpdate });
export const dashboardSuccess = (data: DashboardData) =>
  action(DashboardTypes.LOAD_SUCCESS, { data });
export const dashboardFailure = (error: ResponseError) =>
  action(DashboardTypes.LOAD_FAILURE, { error });
