import { Form } from 'antd';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: 1024px) {
    flex-direction: column;
    justify-content: space-between;
  }
`;

export const FormItem = styled(Form.Item)`
  &.ant-form-item-explain {
    color: #fff;
  }
`;

export const Label = styled.p`
  margin: 0px;
`;

export const Content = styled.div`
  margin: 10px 0px;
`;
