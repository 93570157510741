import React, { Dispatch, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../store';
import { Action } from 'typesafe-actions';
import DynamicFields, { NewTypes } from '../../components/DynamicFields';
import { Button, Col, Form, Input, Row, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import moment from 'moment';
import { unmask } from '../../utils/masks';
import ServerError from '../../components/ServerError';
import { MESSAGES } from '../../utils/constants';
import { FormFields } from '../../store/ducks/signUpFields/types';
import {
  cleanFields,
  loadFieldsRequest,
  sendFieldsRequest,
} from '../../store/ducks/signUpFields/actions';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
const SignUp: React.FC = () => {
  const layout = useSelector((state: ApplicationState) => state.layout.data);
  const groups = useSelector((state: ApplicationState) => state.signUpFields.data);
  const error = useSelector((state: ApplicationState) => state.signUpFields.error);
  const loading = useSelector((state: ApplicationState) => state.signUpFields.loading);
  const sendLoading = useSelector((state: ApplicationState) => state.signUpFields.sendLoading);
  const success = useSelector((state: ApplicationState) => state.signUpFields.success);
  const sendError = useSelector((state: ApplicationState) => state.signUpFields.sendError);
  const dispatch = useDispatch<Dispatch<Action>>();

  const history = useHistory();

  useEffect(() => {
    dispatch(loadFieldsRequest());
  }, [dispatch]);

  const sorter = (fields: FormFields[]) => {
    return fields.sort((a, b) => {
      if (a.order < b.order) {
        return -1;
      }
      if (a.order > b.order) {
        return 1;
      }
      return 0;
    });
  };

  useEffect(() => {
    if (!success) return;

    Swal.fire({
      title: success.allowLogin ? 'Sucesso!' : 'Cadastro realizado',
      confirmButtonColor: layout?.color.default,
      text: `${success.message}`,
      icon: success.allowLogin ? 'success' : 'warning',
      confirmButtonText: 'Ok',
    }).then(() => {
      history.push('/login');
      dispatch(cleanFields());
    });
  }, [dispatch, history, layout?.color.default, success]);

  useEffect(() => {
    if (!sendError) return;

    Swal.fire({
      title: 'Erro!',
      confirmButtonColor: layout?.color.default,
      text: `${sendError.message}`,
      icon: 'error',
      confirmButtonText: 'Ok',
    }).then(() => {
      dispatch(cleanFields());
    });
  }, [dispatch, history, layout?.color.default, sendError]);

  const onFinish = (formValues: any) => {
    const values: any[] = [];
    const fields: FormFields[] = [];
    groups.forEach((group) => fields.push(...group.fields));

    Object.keys(formValues).forEach((item) => {
      const field = fields.find((field) => field.fieldName === item);
      let value = formValues[item];
      if (value?.file) {
        value = value.file.response.attachment;
      }
      if (value instanceof moment) {
        value = moment(value).format('DD/MM/YYYY HH:mm:ss');
      }
      if (field?.mask && field?.mask !== 'currency' && field?.mask !== 'email') {
        value = unmask(value);
      }
      if (!value) return;
      values.push({
        id: field?.idField,
        name: item,
        value: value,
      });
    });
    dispatch(sendFieldsRequest(values));
  };

  return (
    <Spin
      style={{ color: layout?.color.default }}
      spinning={loading}
      size="large"
      delay={500}
      indicator={<LoadingOutlined />}
    >
      {error ? (
        <ServerError {...error} />
      ) : (
        <div style={{ height: '60vh' }}>
          <Form onFinish={onFinish} preserve={false} layout="vertical">
            {groups.map((group) => {
              if (group.fields.filter((field) => field.visible).length > 0) {
                return (
                  <Col span={24}>
                    <Row justify="start">
                      <Col span={24}>
                        <h3 style={{ color: layout?.color.dark, margin: 0 }}>{group.title}</h3>
                      </Col>
                      {sorter(group.fields).map((field) => {
                        if (field.visible) {
                          return (
                            <Col span={24}>
                              <DynamicFields
                                type={(field.mask || field.presentationType) as NewTypes}
                                placeholder={field.description}
                                required={field.required}
                                disabled={field.enable}
                                name={field.fieldName}
                                key={field.idField.toString()}
                                value={field.defaultValue}
                                urlUnique={`access-request/fields/${field.idField}/options`}
                              />
                            </Col>
                          );
                        }
                        return undefined;
                      })}
                    </Row>
                  </Col>
                );
              }
              return undefined;
            })}
            <Row justify="start">
              <Col span={24}>
                <h3 style={{ color: layout?.color.dark, margin: 0 }}>Senha</h3>
              </Col>
              <Col span={24}>
                <Form.Item
                  style={{ marginBottom: '10px' }}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: 'Por favor digite a senha!',
                    },
                    () => ({
                      validator(_, value: string) {
                        if (
                          !value ||
                          value.match(/^(?=.{8,}$)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).*$/)
                        ) {
                          return Promise.resolve();
                        }
                        return Promise.reject(MESSAGES.PASSWORD_ERROR);
                      },
                    }),
                  ]}
                  name="password"
                >
                  <Input.Password placeholder="Senha" data-cy="input-password" />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  style={{ marginBottom: '10px' }}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: 'Por favor confirme a senha!',
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('password') === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error('As duas senhas devem ser iguais!'));
                      },
                    }),
                  ]}
                  name="confirmationPassword"
                  dependencies={['newPassword']}
                >
                  <Input.Password placeholder="Confirmar senha" data-cy="input-confirmPassword" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item>
                  <Button loading={sendLoading} block htmlType="submit" type="primary">
                    Cadastrar
                  </Button>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Button block type="link" onClick={() => history.push('/login')} size="small">
                  Voltar para o login
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      )}
    </Spin>
  );
};

export default SignUp;
