import { action } from 'typesafe-actions';
import { ResponseError } from '../../../services/api';
import { Config, Data } from '../../../utils/newTableHelper';
import { Pagination, ServiceOrdersTypes } from './types';

export const loadServiceOrdersRequest = (
  pagination?: Pagination,
  filter?: any,
  sorter?: any,
  searchValue?: string
) => action(ServiceOrdersTypes.LOAD_REQUEST, { pagination, filter, sorter, searchValue });
export const loadSuccess = (pagination: Pagination, data: Data[], config: Config) =>
  action(ServiceOrdersTypes.LOAD_SUCCESS, { pagination, data, config });
export const loadFailure = (error: ResponseError) =>
  action(ServiceOrdersTypes.LOAD_FAILURE, { error });
