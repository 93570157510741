import { ResponseError } from '../../../services/api';

export enum BannerTypes {
  'LOAD_REQUEST' = '@banners/LOAD_REQUEST',
  'LOAD_SUCCESS' = '@banners/LOAD_SUCCESS',
  'LOAD_FAILURE' = '@banners/LOAD_FAILURE',
}

export interface Banner {
  bannerId: number;
  layoutId: number;
  order: number;
  title: string;
  imageType: 'I' | 'U';
  image: string;
  active?: 'S' | 'N';
  imageUrl: string;
  link: string;
  tmpExec: number;
}
export interface BannerState {
  readonly data: Banner[];
  readonly loading: boolean;
  readonly loaded: boolean;
  readonly error?: ResponseError;
}
