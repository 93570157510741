import Icon from '@ant-design/icons';
import React from 'react';
import { CSSProperties } from 'react';
import { ReactComponent as DollarClockSvg } from '../../assets/icons/dollarClock.svg';
import { ReactComponent as FullBagSvg } from '../../assets/icons/fullBag.svg';
export interface Props {
  style?: CSSProperties;
  spin?: boolean;
  rotate?: number;
  className?: string;
}
export const DollarClockOulined: React.FC<Props> = (props) => (
  <Icon data-cy={'DollarClockOulined'} component={DollarClockSvg} {...props} />
);
export const FullBagOutlined: React.FC<Props> = (props) => (
  <Icon data-cy={'FullBagOutlined'} component={FullBagSvg} {...props} />
);
