import React from 'react'
import { Button, Result } from 'antd';

import { Link } from './styles'
import { ErrorPros } from './props'

const Error: React.FC<ErrorPros> = ({ resetError }) => {
  return (
    <Result
      status="500"
      title="Ocorreu um erro inesperado"
      extra={
        <>
          <Button type="primary" onClick={resetError}>
            Tentar novamente
          </Button>

          <Link href="/home">
            Voltar para Tela Inicial
          </Link>
        </>
      }
    />
  )
}

export default Error
