import { Metadata } from '../store/ducks/dashboard/types';

const convertType = (type: string, value: string) => {
  switch (type) {
    case 'I':
      return value ? parseInt(value) : value;
    case 'F':
      return value ? parseFloat(value) : value;
    default:
      return value;
  }
};

export const dashboardData = (data?: any[], metaDatas?: Metadata[]) => {
  if (!data && !metaDatas) return [];
  return data?.map((item) => {
    const tableData: any = {};
    metaDatas?.forEach((metadata) => {
      Object.assign(tableData, {
        [metadata.name]: convertType(metadata.type, item[metadata.name]),
      });
    });
    return tableData;
  });
};
