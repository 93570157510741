import {
  HomeOutlined,
  FilePdfOutlined,
  LineChartOutlined,
  DollarOutlined,
  FileDoneOutlined,
  QuestionCircleOutlined,
  UsergroupAddOutlined,
  SettingOutlined,
  FileTextOutlined,
  CheckCircleOutlined,
  ShoppingCartOutlined,
  DashboardOutlined,
} from '@ant-design/icons';

import { RouteType } from './routeType';
import Home from '../views/Home';
import MyOrders from '../views/MyOrders';
import Campaigns from '../views/Campaigns';
import OrderRelease from '../views/OrderRelease';
import Financial from '../views/Financial';
import ServiceOrders from '../views/ServiceOrders';
import NewServiceOrder from '../views/NewServiceOrder';
import FAQ from '../views/FAQ';
import AccessControl from '../views/AccessControl';
import EditUser from '../views/EditUser';
import NewContact from '../views/NewContact';
import DeliveryPoint from '../views/DeliveryPoint';
import NewDeliveryPoint from '../views/NewDeliveryPoint';
import MyData from '../views/MyData';
import NewOrder from '../views/NewOrder';
import CampaignDetails from '../views/CampaignDetails';
import Reports from '../views/Reports';
import Datasheet from '../views/Datasheet';
import { MESSAGES, ROLE } from '../utils/constants';
import OrderDetails from '../views/OrderDetails';
import ServiceOrderDetail from '../views/ServiceOrderDetail';
import ProductDetail from '../views/ProductDetail';
import BudgetsDetail from '../views/BudgetDetail';
import { FullBagOutlined } from '../components/CustomIcons';
import NewUser from '../views/NewUser';
import ScreenConstructor from '../views/ScreenConstructor';
import ScreenForm from '../views/ScreenForm';
import ServerError from '../components/ServerError';
import ShoppingCart from '../views/ShoppingCart';
import Dashboard from '../views/Dashboard';
import ScreenEdit from '../views/ScreenEdit';
import ScreenDetail from '../views/ScreenDetail';

export const homeRoute: RouteType = {
  path: '',
  name: 'Início',
  component: Home,
  layout: '/home/',
  isPrivate: true,
  icon: HomeOutlined,
  roles: [],
};
export const MyOrdersRoute: RouteType = {
  path: '',
  name: 'Meus pedidos',
  component: MyOrders,
  layout: '/home/meus-pedidos',
  isPrivate: true,
  icon: FullBagOutlined,
  roles: [ROLE.ORDERS],
};
export const MyOrdersDetailsRoute: RouteType = {
  path: '/:id/detalhes',
  name: 'Detalhes do pedido',
  component: OrderDetails,
  layout: '/home/meus-pedidos',
  isPrivate: true,
  roles: [ROLE.ORDERS],
};

export const BudgetsDetailsRoute: RouteType = {
  path: '/meus-orcamentos/:id/detalhes',
  name: 'Detalhes do orçamento',
  component: BudgetsDetail,
  layout: '/home/meus-pedidos',
  isPrivate: true,
  roles: [ROLE.ORDERS],
};

export const OSDetailsRoute: RouteType = {
  path: '/:id/detalhes',
  name: 'Detalhes da OS',
  component: ServiceOrderDetail,
  layout: '/home/ordens-servico',
  isPrivate: true,
  roles: [ROLE.ORDER_SERVICE],
};

export const OrderReleaseRoute: RouteType = {
  path: '',
  name: 'Liberação de pedidos',
  component: OrderRelease,
  layout: '/home/liberacao-pedidos',
  isPrivate: true,
  icon: CheckCircleOutlined,
  roles: [ROLE.ORDER_RELEASE],
};

export const MyOrdersReleaseDetailsRoute: RouteType = {
  path: '/:id/detalhes',
  name: 'Detalhes do pedido',
  component: OrderDetails,
  layout: '/home/liberacao-pedidos',
  isPrivate: true,
  roles: [ROLE.ORDERS],
};
export const CampaignsRoute: RouteType = {
  path: '',
  name: 'Campanhas',
  component: Campaigns,
  layout: '/home/campanhas',
  isPrivate: true,
  icon: LineChartOutlined,
  roles: [ROLE.CAMPAIGN],
};
export const FinancialRoute: RouteType = {
  path: '',
  name: 'Financeiro',
  component: Financial,
  layout: '/home/financeiro',
  isPrivate: true,
  icon: DollarOutlined,
  roles: [ROLE.FINANCIAL],
};
export const ServiceOrdersRoute: RouteType = {
  path: '',
  name: 'Ordens de serviço',
  component: ServiceOrders,
  layout: '/home/ordens-servico',
  isPrivate: true,
  icon: FileDoneOutlined,
  roles: [ROLE.ORDER_SERVICE],
};
export const NewServiceOrderRoute: RouteType = {
  path: '/nova-ordem-servico',
  name: 'Abrir Ordem de Serviço',
  component: NewServiceOrder,
  layout: '/home/ordens-servico',
  isPrivate: true,
  roles: [ROLE.ORDER_SERVICE],
};
export const FAQRoute: RouteType = {
  path: '',
  name: 'Perguntas frequentes',
  component: FAQ,
  layout: '/home/perguntas-frequentes',
  isPrivate: true,
  icon: QuestionCircleOutlined,
  roles: [ROLE.FAQ],
};
export const AccessControlRoute: RouteType = {
  path: '',
  name: 'Controle de acessos',
  component: AccessControl,
  layout: '/home/controle-acessos',
  isPrivate: true,
  icon: UsergroupAddOutlined,
  roles: [ROLE.ACCESS_CONTROL],
};
export const NewUserRoute: RouteType = {
  path: '/usuario',
  name: 'Novo usuário',
  component: NewUser,
  layout: '/home/controle-acessos',
  isPrivate: true,
  roles: [ROLE.ACCESS_CONTROL],
};
export const EditUserRoute: RouteType = {
  path: '/usuario/:id',
  name: 'Dados de acessos',
  component: EditUser,
  layout: '/home/controle-acessos',
  isPrivate: true,
  roles: [ROLE.ACCESS_CONTROL],
};
export const NewContactRoute: RouteType = {
  path: '/usuario/:userId/contato',
  name: 'Novo contato',
  component: NewContact,
  layout: '/home/controle-acessos',
  isPrivate: true,
  roles: [ROLE.ACCESS_CONTROL],
};
export const EditContactRoute: RouteType = {
  path: '/usuario/:userId/contato/:id',
  name: 'Editar contato',
  component: NewContact,
  layout: '/home/controle-acessos',
  isPrivate: true,
  roles: [ROLE.ACCESS_CONTROL],
};
export const DeliveryPointRoute: RouteType = {
  path: '/usuario/:userId/contato/:contactId/ponto-entrega',
  name: 'Pontos de entrega',
  component: DeliveryPoint,
  layout: '/home/controle-acessos',
  isPrivate: true,
  roles: [ROLE.ACCESS_CONTROL],
};
export const NewDeliveryPointRoute: RouteType = {
  path: '/usuario/:userId/contato/:contactId/ponto-entrega/novo',
  name: 'Novo ponto de entrega',
  component: NewDeliveryPoint,
  layout: '/home/controle-acessos',
  isPrivate: true,
  roles: [ROLE.ACCESS_CONTROL],
};
export const EditDeliveryPointRoute: RouteType = {
  path: '/usuario/:userId/contato/:contactId/ponto-entrega/:id',
  name: 'Editar ponto de entrega',
  component: NewDeliveryPoint,
  layout: '/home/controle-acessos',
  isPrivate: true,
  roles: [ROLE.ACCESS_CONTROL],
};
export const MyDataRoute: RouteType = {
  path: '',
  name: 'Meus dados',
  component: MyData,
  layout: '/home/meus-dados',
  isPrivate: true,
  icon: SettingOutlined,
  roles: [ROLE.MY_DATA],
};
export const NewOrderRoute: RouteType = {
  path: '',
  name: 'Novo pedido',
  component: NewOrder,
  layout: '/home/novo-pedido',
  isPrivate: true,
  icon: ShoppingCartOutlined,
  roles: [ROLE.NEW_ORDER],
};

export const ProductDetailRoute: RouteType = {
  path: '/produto/:id/detalhes',
  name: 'Detalhes do produto',
  component: ProductDetail,
  layout: '/home/novo-pedido',
  isPrivate: true,
  roles: [ROLE.NEW_ORDER],
  additionalRouteData: { from: '/produto/:id/detalhes', to: NewOrderRoute.layout }
};

export const CampaignDetailsRoute: RouteType = {
  path: '/:id',
  name: 'Detalhes campanha',
  component: CampaignDetails,
  layout: '/home/campanhas',
  isPrivate: true,
  roles: [ROLE.CAMPAIGN],
};
export const ReportRoute: RouteType = {
  path: '',
  name: 'Relatórios',
  component: Reports,
  layout: '/home/relatorios',
  isPrivate: true,
  icon: FileTextOutlined,
  roles: [ROLE.REPORTS],
};
export const DatasheetRoute: RouteType = {
  path: '',
  name: 'Ficha técnica',
  component: Datasheet,
  layout: '/home/ficha-tecnica',
  isPrivate: true,
  icon: FilePdfOutlined,
  roles: [ROLE.DATASHEET],
};

export const ConstructorRoute: RouteType = {
  path: '/:id/list',
  name: '',
  component: ScreenConstructor,
  layout: '/home/screen',
  isPrivate: true,
  roles: [],
};

export const ConstructorEditRoute: RouteType = {
  path: '/:screenId/edit/:id',
  name: 'Editar',
  component: ScreenEdit,
  layout: '/home/screen',
  isPrivate: true,
  roles: [],
};

export const ConstructorDetailRoute: RouteType = {
  path: '/:screenId/detail/:id',
  name: 'Detalhes',
  component: ScreenDetail,
  layout: '/home/screen',
  isPrivate: true,
  roles: [],
};

export const ScreenFormRoute: RouteType = {
  path: '/:id/form',
  name: 'Novo registro',
  component: ScreenForm,
  layout: '/home/screen',
  isPrivate: true,
  roles: [],
};

export const DashboardViewRoute: RouteType = {
  path: '/:id/view',
  name: 'Dashboard',
  component: Dashboard,
  layout: '/home/dashboard',
  isPrivate: true,
  icon: DashboardOutlined,
  roles: [],
};

export const ShoppingCartRoute: RouteType = {
  path: '/resumo-da-compra',
  name: 'Carrinho de compras',
  component: ShoppingCart,
  layout: '/home',
  isPrivate: true,
  roles: [],
};

export const NotFoundRoute: RouteType = {
  path: '404',
  name: MESSAGES.NOT_FOUND,
  component: () => <ServerError status={404} />,
  layout: '/home/',
  isPrivate: true,
  roles: [],
};
