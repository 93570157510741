import { action } from 'typesafe-actions';
import { ResponseError } from '../../../services/api';
import { Config, Data, DataValues } from '../../../utils/newTableHelper';
import { Pagination, MyOrdersDetailTypes, Step } from './types';

export const loadRequest = (pagination?: Pagination, filter?: any, sorter?: any, id?: number) =>
  action(MyOrdersDetailTypes.LOAD_REQUEST, { pagination, filter, sorter, id });
export const loadSuccess = (pagination: Pagination, data: Data[], config: Config) =>
  action(MyOrdersDetailTypes.LOAD_SUCCESS, { pagination, data, config });
export const loadFailure = (error: ResponseError) =>
  action(MyOrdersDetailTypes.LOAD_FAILURE, { error });

export const loadDetail = (id?: number) => action(MyOrdersDetailTypes.DATA_REQUEST, { id });
export const successDetail = (data: DataValues[], config: Config) =>
  action(MyOrdersDetailTypes.DATA_SUCCESS, { data, config });
export const failureDetail = (error: ResponseError) =>
  action(MyOrdersDetailTypes.DATA_FAILURE, { error });

export const loadSteps = (id?: number) => action(MyOrdersDetailTypes.STEPS_REQUEST, { id });
export const successSteps = (data: Step[]) => action(MyOrdersDetailTypes.STEPS_SUCCESS, { data });
export const failureSteps = (error: ResponseError) =>
  action(MyOrdersDetailTypes.STEPS_FAILURE, { error });
