import React from 'react';
import { Mix } from '@ant-design/plots';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../store';
import { maskCamelCase } from '../../../utils/masks';
import { Serie } from '../../../store/ducks/dashboard/types';
import { G2 } from '@ant-design/charts';
import EmptyChart from '../EmptyChart';
interface Props {
  series: Serie[];
  title: string;
  data: any[];
  xField: string;
  onClick(values: any): void;
}
const AreaRrangeChart: React.FC<Props> = ({ title, data, xField, series, onClick }) => {
  const layout = useSelector((state: ApplicationState) => state.layout.data);

  G2.registerShape('point', 'breath-point', {
    draw(cfg, container) {
      const point: any = {
        x: cfg.x,
        y: cfg.y,
      };
      const group: any = container.addGroup();

      const decorator1 = group.addShape('circle', {
        attrs: {
          x: point.x,
          y: point.y,
          r: 3,
          fill: cfg.color,
          opacity: 0.5,
        },
      });
      const decorator2 = group.addShape('circle', {
        attrs: {
          x: point.x,
          y: point.y,
          r: 3,
          fill: cfg.color,
          opacity: 0.5,
        },
      });
      const decorator3 = group.addShape('circle', {
        attrs: {
          x: point.x,
          y: point.y,
          r: 3,
          fill: cfg.color,
          opacity: 0.5,
        },
      });
      decorator1.animate(
        {
          r: 6,
          opacity: 0,
        },
        {
          duration: 1800,
          easing: 'easeLinear',
          repeat: true,
        }
      );
      decorator2.animate(
        {
          r: 6,
          opacity: 0,
        },
        {
          duration: 1800,
          easing: 'easeLinear',
          repeat: true,
          delay: 600,
        }
      );
      decorator3.animate(
        {
          r: 6,
          opacity: 0,
        },
        {
          duration: 1800,
          easing: 'easeLinear',
          repeat: true,
          delay: 1200,
        }
      );
      group.addShape('circle', {
        attrs: {
          x: point.x,
          y: point.y,
          r: 2,
          fill: cfg.color,
          opacity: 0.7,
        },
      });
      group.addShape('circle', {
        attrs: {
          x: point.x,
          y: point.y,
          r: 0.5,
          fill: cfg.color,
        },
      });

      return group;
    },
  });

  const areaRangeSerie = series.find((item) => item.type === 'area-range');
  const lineRangeSerie = series.find((item) => item.type === 'line');

  const config = {
    appendPadding: 8,
    syncViewPadding: true,
    tooltip: { shared: true, showMarkers: false, showCrosshairs: true, offsetY: -50 },
    views: [
      {
        data: data.map((item) => {
          return {
            [xField]: item[xField],
            yField: areaRangeSerie
              ? [
                item[areaRangeSerie.yMinField?.fieldName || ''],
                item[areaRangeSerie.yMaxField?.fieldName || ''],
              ]
              : '',
          };
        }),
        axes: {},
        meta: {
          [xField]: {
            nice: true,
            tickCount: 5,
          },
          yField: {
            nice: true,
            sync: true,
            alias: `${maskCamelCase(areaRangeSerie?.yMinField?.fieldName || '')}- ${maskCamelCase(
              areaRangeSerie?.yMaxField?.fieldName || ''
            )}`,
          },
        },
        geometries: [
          {
            type: 'area',
            xField: xField,
            yField: 'yField',
            mapping: {
              color: layout?.color.default,
            },
          },
        ],
      },
      {
        data: lineRangeSerie ? data : [],
        meta: {
          [xField]: {
            nice: true,
            tickCount: 5,
          },
          [lineRangeSerie?.yField?.fieldName || '']: {
            nice: true,
            sync: true,
            alias: maskCamelCase(lineRangeSerie?.yField?.fieldName || ''),
          },
        },
        geometries: [
          {
            type: 'line',
            xField: xField,
            yField: lineRangeSerie?.yField?.fieldName || '',
            mapping: {
              color: layout?.color.dark,
            },
          },
          {
            type: 'point',
            xField: xField,
            yField: lineRangeSerie?.yField?.fieldName || '',
            mapping: {
              shape: lineRangeSerie?.circleIntersection ? 'breath-point' : undefined,
              color: layout?.color.dark,
              style: {
                fillOpacity: 1,
              },
            },
          },
        ],
      },
    ],
    onReady: (plot: any) => {
      plot.on('element:click', ({ data: { data } }: any) => {
        onClick(data);
      });
    }
  };

  return data.length === 0 || series.length === 0 ? (
    <EmptyChart type={'area'} />
  ) : (
    <div style={{ padding: '20px' }}>
      <h2 style={{ color: layout?.color.dark }}>{title}</h2>
      <Mix {...config} />
    </div>
  );
};

export default AreaRrangeChart;
