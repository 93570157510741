import { Config } from '../../../utils/newTableHelper';

export enum ShoppingCartTypes {
  'GET_CURRENT_CART_REQUEST' = '@shoppingCart/GET_CURRENT_CART_REQUEST',
  'GET_CURRENT_CART_SUCCESS' = '@shoppingCart/GET_CURRENT_CART_SUCCESS',
  'GET_CURRENT_CART_FAILURE' = '@shoppingCart/GET_CURRENT_CART_FAILURE',
  'CART_INITIALIZED' = '@shoppingCart/CART_INITIALIZED',

  'ADD_ITEM_REQUEST' = '@shoppingCart/ADD_ITEM_REQUEST',
  'ADD_ITEMS_REQUEST' = '@shoppingCart/ADD_ITEMS_REQUEST',
  'ADD_ITEM_SUCCESS' = '@shoppingCart/ADD_ITEM_SUCCESS',
  'ADD_ITEM_FAILURE' = '@shoppingCart/ADD_ITEM_FAILURE',

  'TOTAL_REQUEST' = '@shoppingCart/TOTAL_REQUEST',
  'TOTAL_SUCCESS' = '@shoppingCart/TOTAL_SUCCESS',
  'TOTAL_FAILURE' = '@shoppingCart/TOTAL_FAiLURE',

  'SAVE_REQUEST' = '@shoppingCart/SAVE_REQUEST',
  'SAVE_SUCCESS' = '@shoppingCart/SAVE_SUCCESS',
  'SAVE_FAILURE' = '@shoppingCart/SAVE_FAiLURE',

  'REDO_REQUEST' = '@shoppingCart/REDO_REQUEST',
  'REDO_SUCCESS' = '@shoppingCart/REDO_SUCCESS',
  'REDO_FAILURE' = '@shoppingCart/REDO_FAiLURE',

  'EDIT_REQUEST' = '@shoppingCart/EDIT_REQUEST',
  'EDIT_SUCCESS' = '@shoppingCart/EDIT_SUCCESS',
  'EDIT_FAILURE' = '@shoppingCart/EDIT_FAiLURE',

  'CONFIGS_REQUEST' = '@shoppingCart/CONFIGS_REQUEST',
  'CONFIGS_SUCCESS' = '@shoppingCart/CONFIGS_SUCCESS',
  'CONFIGS_FAILURE' = '@shoppingCart/CONFIGS_FAiLURE',

  'PAYMENT_REQUEST' = '@shoppingCart/PAYMENT_REQUEST',
  'PAYMENT_SUCCESS' = '@shoppingCart/PAYMENT_SUCCESS',
  'PAYMENT_FAILURE' = '@shoppingCart/PAYMENT_FAiLURE',

  'ADDRESS_REQUEST' = '@shoppingCart/ADDRESS_REQUEST',
  'ADDRESS_SUCCESS' = '@shoppingCart/ADDRESS_SUCCESS',
  'ADDRESS_FAILURE' = '@shoppingCart/ADDRESS_FAiLURE',
  'ADDRESS_SELECT' = '@shoppingCart/ADDRESS_SELECT',

  'DELETE_ITEM_REQUEST' = '@shoppingCart/DELETE_ITEM_REQUEST',
  'DELETE_ITEM_SUCCESS' = '@shoppingCart/DELETE_ITEM_SUCCESS',
  'DELETE_ITEM_FAILURE' = '@shoppingCart/DELETE_ITEM_FAILURE',

  'CLEAR_CART' = '@shoppingCart/CLEAR_CART',
  'CLEAR_CART_FINISH' = '@shoppingCart/CLEAR_CART_FINISH',

  'OPEN_CART' = '@shoppingCart/OPEN_CART',

  'CLOSE_CART' = '@shoppingCart/CLOSE_CART',

  'SET_ERROR' = '@shoppingCart/SET_ERROR',

  'SET_BUDGET' = '@shoppingCart/SET_BUDGET',

  'SET_BUDGET_VISIBLE' = '@shoppingCart/SET_BUDGET_VISIBLE',

  'SET_FIELDS' = '@shoppingCart/SET_FIELDS',
}
export interface Metreage {
  quantity: number;
  size: number;
}

export type Format = 'redondo' | 'retangular';
export interface Dimension {
  format: Format;
  size?: number;
  quantity?: number;
  height?: number;
  width?: number;
  volume: number;
}

export interface KeyValue {
  key: string;
  value: string | number | undefined;
}

export interface Validations {
  validation: 'VAILD' | 'WARNING' | 'ERROR' | 'INVALID_TIME' | 'BLOCKED';
  message: string;
}

export interface Summary {
  note?: string;
  description?: string;
  value?: number;
  type?: 'UNIT' | 'MONETARY';
}

export interface CalculateResult {
  amount: number;
  blockOrder?: boolean;
  freight?: number;
  deliveryForecast?: Date;
  validations: Validations[];
  calculationSummary?: Summary[];
}

export interface Payment {
  key: string;
  value: string;
  minOrderValue?: number;
  maxOrderValue?: number
  paymentMethod?: {
    publicKey: string,
    installments: number,
    type: 'PAGARME' | 'OUTROS'
  }
}

export interface OrderSchedule {
  startTime: string;
  endTime: string;
  isValid: boolean;
}

export interface Grid {
  index?: string;
  row?: string;
  column?: string;
  total: number;
  value?: number;
  quantity: number;
  columnLabel?: string;
  rowLabel?: string;
}

export interface Unity {
  key: string;
  value: string;
  defaultValue?: boolean;
  multiple?: number;
  decimalPlaces?: number;
  message?: string;
}

export enum StockControlTypeEnum {
  GRADE,
  LOTE,
  VALIDADE,
  PARCEIRO,
  LIVRE,
  LISTA,
  SERIE,
  SEM_CONTROLE
};

export interface ShoppingCartItem {
  codProd?: string;
  quantity?: number;
  unity?: Unity;
  value?: number;
  stock?: number;
  metreages?: Metreage[];
  mattresses?: Dimension[];
  grid?: Grid[];
  description?: string;
  hasStock?: boolean;
  multiplier?: number;
  isDecimal?: boolean;
  decimalQtd?: number;
  message?: string;
  isPromo?: boolean;
  controlType?: StockControlTypeEnum
}

export interface Address {
  addressId: number;
  addressName: string;
  cityId: number;
  cityName: string;
  complement: string;
  contactName: string;
  creditLimit: number;
  deliveryAddressId: number;
  descriptionDeliveryAddress: string;
  districtId: number;
  districtName: string;
  entId: number;
  number: string;
  uf: string;
  ufId: number;
  zipCode: string;
}

export interface Success {
  message: string;
  orderId: number;
  validations: Validations[];
}
export interface Obsevation {
  required?: boolean;
  placeholder?: string;
  content?: string;
  observationInput?: string;
}

export interface CreditLimit {
  value?: number;
  validates: boolean;
}

export interface ShoppingCartState {
  readonly result?: CalculateResult;
  readonly cart: ShoppingCartItem[];
  readonly config?: Config;
  readonly creditLimit?: CreditLimit;
  readonly payments: Payment[];
  readonly paymentLoading: boolean;
  readonly addresses: Address[];
  readonly selectedAddress?: Address;
  readonly addressLoading: boolean;
  readonly subTotal: number;
  readonly totalQuantity: number;
  readonly loading: boolean;
  readonly error: boolean;
  readonly errorMessage?: string;
  readonly pdf?: string;
  readonly message?: string;
  readonly cartVisibility?: boolean;
  readonly editId?: number;
  readonly budgetId?: number;
  readonly budgetVisible?: boolean;
  readonly fields: KeyValue[];
  readonly success?: Success;

  readonly currentAction?: "INIT" | "ADD" | "REMOVE" | "CLEAR";
  readonly currentActionItemId?: string;
  readonly synced?: boolean;
  readonly id?: number;
  readonly version?: number;
  readonly cleaningCart: boolean;
}
