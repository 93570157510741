import { AxiosResponse } from 'axios';
import { call, put, select } from 'redux-saga/effects';
import { ApplicationState } from '../..';
import api, { errorHandler } from '../../../services/api';
import { requestHeaders } from '../../../utils/apiHelper';
import { refreshRequest } from '../auth/actions';
import {
  contactFailure,
  contactRequest,
  contactSuccess,
  sendContactRequest,
  sendContactSuccess,
  sendContactFailure,
  rolesSuccess,
  rolesFailure,
} from './actions';
import { Contact, Role } from './types';

export function* loadContact({ payload }: ReturnType<typeof contactRequest>) {
  try {
    const state: ApplicationState = yield select();
    const headers = requestHeaders(state.auth.token);
    const response: AxiosResponse<Contact> = yield call(
      api.post,
      `access-control/user/${payload.userId}/contact/${payload.contactId}`,
      undefined,
      {
        headers: headers,
      }
    );
    yield put(contactSuccess(response.data));
  } catch (err: any) {
    yield put(err.response.status !== 401 ? contactFailure(errorHandler(err)) : refreshRequest());
  }
}

export function* loadRoles() {
  try {
    const state: ApplicationState = yield select();
    const headers = requestHeaders(state.auth.token);
    const response: AxiosResponse<Role[]> = yield call(api.get, `/mydata/permissions`, {
      headers: headers,
    });
    yield put(rolesSuccess(response.data));
  } catch (err: any) {
    yield put(err.response.status !== 401 ? rolesFailure(errorHandler(err)) : refreshRequest());
  }
}

export function* loadSendContact({ payload }: ReturnType<typeof sendContactRequest>) {
  try {
    const state: ApplicationState = yield select();
    const headers = requestHeaders(state.auth.token);
    const response: AxiosResponse<Contact> = payload.id
      ? yield call(
          api.put,
          `access-control/user/${payload.userId}/contact/${payload.id}`,
          payload.body,
          {
            headers: headers,
          }
        )
      : yield call(api.post, `access-control/${payload.userId}/contact`, payload.body, {
          headers: headers,
        });
    yield put(sendContactSuccess(response.data));
  } catch (err: any) {
    yield put(
      err.response.status !== 401 ? sendContactFailure(errorHandler(err)) : refreshRequest()
    );
  }
}
