import { Reducer } from 'redux';
import { ContactsState, ContactsTypes } from './types';

const INITIAL_STATE: ContactsState = {
  pagination: undefined,
  data: [],
  loading: false,
};

const reducer: Reducer<ContactsState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ContactsTypes.LOAD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ContactsTypes.LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        ...action.payload,
      };
    case ContactsTypes.LOAD_FAILURE:
      return {
        ...state,
        data: [],
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export default reducer;
