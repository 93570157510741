import styled from 'styled-components';
import { colors } from '../../utils/constants';

export const StepContainer = styled.div`
  padding: 20px;
  width: 100%;
  margin-top: 20px;
  background: white;
  border-radius: 5px;
  box-shadow: 1px 2px 2px 2px ${colors.grey};
  .ant-form-item-label > label {
    width: 170px;
  }
`;
export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 20px 0 20px 20px;
`;
