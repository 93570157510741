import React, { useEffect, useState, Dispatch } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Action } from 'typesafe-actions';
import { ColumnsType } from 'antd/es/table';
import { ApplicationState } from '../../../store';
import { loadServiceOrdersRequest } from '../../../store/ducks/serviceOrders/actions';
import CustomTable from '../../../components/CustomTable';
import { EllipsisOutlined, EyeOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu } from 'antd';
import { useHistory } from 'react-router-dom';
import ServerError from '../../../components/ServerError';
import Status from '../../../components/Status';
import { configToColumnsType, dataToTableData } from '../../../utils/newTableHelper';

interface Props {
  search: string;
}

const ServiceOrdersTable: React.FC<Props> = ({ search }) => {
  const layout = useSelector((state: ApplicationState) => state.layout.data);
  const config = useSelector((state: ApplicationState) => state.serviceOrders.config);
  const error = useSelector((state: ApplicationState) => state.serviceOrders.error);
  const data = useSelector((state: ApplicationState) => state.serviceOrders.data);
  const loading = useSelector((state: ApplicationState) => state.serviceOrders.loading);
  const pagination = useSelector((state: ApplicationState) => state.serviceOrders.pagination);
  const [columns, setColumns] = useState<ColumnsType<any>>([]);
  const history = useHistory();

  const dispatch = useDispatch<Dispatch<Action>>();

  useEffect(() => {
    setColumns(
      configToColumnsType(
        config?.fields.filter((item) => item.show) || [],
        [
          {
            id: -1,
            render: (value: number) => <Status value={value} config={config?.fields || []} />,
          },
        ],
        [
          {
            title: 'Ações',
            key: 'action',
            render: (value) => (
              <Dropdown
                trigger={['click']}
                overlay={
                  <Menu>
                    <Menu.Item
                      onClick={() => history.push(`ordens-servico/${value.pkValue}/detalhes`)}
                    >
                      <Button data-cy="btn-osDetail" icon={<EyeOutlined />} type="text">
                        Ver
                      </Button>
                    </Menu.Item>
                  </Menu>
                }
              >
                <Button
                  data-cy="more"
                  type="link"
                  style={{ padding: 0 }}
                  onClick={(e) => e.preventDefault()}
                >
                  <EllipsisOutlined style={{ fontSize: '24px' }} rotate={90} />
                </Button>
              </Dropdown>
            ),
          },
        ],
        [0, -1]
      )
    );
  }, [pagination, layout, config, history]);

  return (
    <>
      {error ? (
        <ServerError {...error} />
      ) : (
        <CustomTable
          style={{ padding: '20px' }}
          onChange={(pagination: any, filters: any, sorter: any) => {
            dispatch(loadServiceOrdersRequest(pagination, filters, sorter, search));
          }}
          pagination={pagination}
          loading={loading}
          columns={columns}
          data={dataToTableData(data, config)}
        />
      )}
    </>
  );
};

export default ServiceOrdersTable;
