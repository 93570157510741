import { Carousel, Spin } from 'antd';
import React from 'react';
import { LoadingOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { CarouselStyled } from './styles';
import { ApplicationState } from '../../store';
import ProductCard from '../ProductCard';

interface Props {
  products: any[];
  loading?: boolean;
}
const ProductsCarousel: React.FC<Props> = ({ products, loading }) => {
  const layout = useSelector((state: ApplicationState) => state.layout.data);
  const SampleNextArrow = ({ className, style, onClick }: any) => {
    return (
      <div
        className={className}
        style={{
          ...style,
          color: layout?.color.default,
          fontSize: '20px',
          lineHeight: '1.5715',
        }}
        onClick={onClick}
      >
        <RightOutlined />
      </div>
    );
  };

  const SamplePrevArrow = ({ className, style, onClick }: any) => {
    return (
      <div
        className={className}
        style={{
          ...style,
          color: layout?.color.default,
          fontSize: '20px',
          lineHeight: '1.5715',
        }}
        onClick={onClick}
      >
        <LeftOutlined />
      </div>
    );
  };
  return (
    <Spin
      style={{ color: layout?.color.default }}
      spinning={loading}
      size="large"
      delay={500}
      indicator={<LoadingOutlined />}
    >
      <CarouselStyled>
        <Carousel
          nextArrow={<SampleNextArrow />}
          prevArrow={<SamplePrevArrow />}
          slidesToShow={4}
          infinite={products.length > 4}
          arrows={products.length > 4}
          autoplay
          slidesToScroll={2}
          responsive={[
            {
              breakpoint: 1200,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 2,
                infinite: products.length > 3,
                arrows: products.length > 3,
              },
            },
            {
              breakpoint: 900,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                infinite: products.length > 3,
                arrows: products.length > 2,
              },
            },
            {
              breakpoint: 570,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: products.length > 1,
                arrows: products.length > 1,
              },
            },
          ]}
        >
          {products.map((item) => (
            <ProductCard product={item} margin="20px 10px" loading={loading} />
          ))}
        </Carousel>
      </CarouselStyled>
    </Spin>
  );
};

export default ProductsCarousel;
