import { AxiosResponse } from 'axios';
import { call, put, select } from 'redux-saga/effects';
import { ApplicationState } from '../..';
import api, { errorHandler } from '../../../services/api';
import { requestHeaders } from '../../../utils/apiHelper';
import { refreshRequest } from '../auth/actions';
import { Config } from '../../../utils/newTableHelper';
import { DetailRequest, loadFailure, loadSuccess } from './actions';
import { ScreenDetail } from './types';

export function* loadScreenDetail({ payload }: ReturnType<typeof DetailRequest>) {
  try {
    const state: ApplicationState = yield select();
    const headers = requestHeaders(state.auth.token);
    const config: AxiosResponse<Config> = yield call(
      api.get,
      `/screen/${payload.screenId}/detail/config`,
      {
        headers: headers,
      }
    );
    const response: AxiosResponse<ScreenDetail[]> = yield call(
      api.get,
      `/screen/${payload.screenId}/detail/${payload.id}`,
      {
        headers: headers,
      }
    );
    yield put(loadSuccess(response.data, config.data));
  } catch (err: any) {
    yield put(err.response.status !== 401 ? loadFailure(errorHandler(err)) : refreshRequest());
  }
}
