import { put } from 'redux-saga/effects';
import { errorHandler } from '../../../services/api';
import { refreshRequest } from '../auth/actions';
import { loadFailure, loadSuccess } from './actions';
import { DataCard } from './types';

export function* loadHomeCards(): any {
  try {
    const response: DataCard[] = [
      {
        title: 'Total de pedidos',
        value: '145',
        icon: 'ShoppingCartOutlined',
      },
      {
        title: 'Financeiro - Maio 2021',
        value: 'R$ 23.050,22',
        icon: 'DollarCircleOutlined',
      },
      {
        title: 'Títulos em atraso',
        value: '32',
        icon: 'ExclamationCircleOutlined',
      },
      {
        title: 'Quantidade de OS',
        value: '22',
        icon: 'FileDoneOutlined',
      },
    ];
    yield put(loadSuccess(response));
  } catch (err: any) {
    yield put(err.response.status !== 401 ? loadFailure(errorHandler(err)) : refreshRequest());
  }
}
