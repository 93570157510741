import styled from 'styled-components';
import { colors } from '../../utils/constants';

export const HeaderContent = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
  padding: 20px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 1px 2px 2px 2px ${colors.grey};
  @media only screen and (max-width: 1024px) {
    flex-direction: column;
    justify-content: space-between;
  }
`;
