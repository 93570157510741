import styled from 'styled-components';

import { Button, Form } from 'antd';
import { Layout } from '../../store/ducks/layout/types';

export const FormItem = styled(Form.Item)``;

export const FormLastItem = styled(Form.Item)`
  margin-bottom: 0;
`;
export const Container = styled.div`
  width: 100%;
`;

export const Submit = styled(Button)<{ layout?: Layout }>`
  background-color: ${({ layout }) => layout?.color.dark} !important;
  color: white !important;
  border: 1px solid ${({ layout }) => layout?.color.dark} !important;
  :hover {
    background-color: ${({ layout }) => layout?.color.dark} !important;
    color: white !important;
    border: 1px solid ${({ layout }) => layout?.color.dark} !important;
  }
  :active {
    background-color: ${({ layout }) => layout?.color.dark} !important;
    color: white !important;
    border: 1px solid ${({ layout }) => layout?.color.dark} !important;
  }
  :focus {
    background-color: ${({ layout }) => layout?.color.dark} !important;
    color: white !important;
    border: 1px solid ${({ layout }) => layout?.color.dark} !important;
  }
`;
