import { List, Timeline, Tooltip } from 'antd';
import React, { Dispatch } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Action } from 'typesafe-actions';
import { LoadingOutlined, ClockCircleOutlined, CheckCircleOutlined } from '@ant-design/icons';

import { ApplicationState } from '../../../store';
import { requestCall } from '../../../store/ducks/faq/actions';
import { colors } from '../../../utils/constants';
import { maskDate } from '../../../utils/masks';
import { QuestionsSolution, SituationCalled, TitleList } from '../styles';
import ServerError from '../../../components/ServerError';

const FAQHistoricQuestion: React.FC = () => {
  const dataCall = useSelector((state: ApplicationState) => state.faq.dataCall);
  const error = useSelector((state: ApplicationState) => state.faq.errorCall);
  const pagination = useSelector((state: ApplicationState) => state.faq.paginationCall);
  const loading = useSelector((state: ApplicationState) => state.faq.loadingCall);
  const layout = useSelector((state: ApplicationState) => state.layout.data);
  const dispatch = useDispatch<Dispatch<Action>>();
  return (
    <>
      {error ? (
        <ServerError {...error} />
      ) : (
        <Timeline>
          <List
            itemLayout="vertical"
            size="small"
            loading={{
              style: { color: layout?.color.default },
              spinning: loading,
              size: 'large',
              delay: 500,
              indicator: <LoadingOutlined style={{ fontSize: 24 }} spin />,
            }}
            pagination={{
              size: 'small',
              ...pagination,
              pageSizeOptions: ['5', '10', '50', '100'],

              onChange: (page, pageSize) => {
                dispatch(
                  requestCall({
                    current: page,
                    pageSize: pageSize || 0,
                    total: pagination?.total || 0,
                  })
                );
              },
            }}
            dataSource={dataCall}
            renderItem={(item) => (
              <Timeline.Item
                dot={
                  <Tooltip title={item.status === 'OPEN' ? 'Em aberto' : 'Concluído'}>
                    {item.status === 'OPEN' ? (
                      <ClockCircleOutlined style={{ color: colors.progress }} />
                    ) : (
                      <CheckCircleOutlined style={{ color: colors.success }} />
                    )}
                  </Tooltip>
                }
                color={item.status === 'OPEN' ? colors.progress : colors.success}
              >
                <TitleList>
                  <div>
                    <span style={{ fontWeight: 400, fontSize: 14 }}>
                      {`${maskDate(item.callDate)} - `}
                    </span>
                    <SituationCalled>{item.situation}</SituationCalled>
                  </div>
                </TitleList>
                <QuestionsSolution dangerouslySetInnerHTML={{ __html: item.solution || '' }} />
              </Timeline.Item>
            )}
          />
        </Timeline>
      )}
    </>
  );
};
export default FAQHistoricQuestion;
