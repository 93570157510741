import { Dispatch, FC, useCallback, useEffect, useState } from 'react';
import { Button, InputNumber, Modal, Row, Col, Tooltip } from 'antd';
import { DeleteFilled } from '@ant-design/icons';
import { maskCurrency } from '../../../utils/masks';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../../store';
import { Container } from '../../../styles';
import {
  ClearButton,
  DefaultButton,
  Footer,
  FooterCol,
  FooterTitle,
  SubmitButton,
} from '../styles';
import { addItemRequest, deleteItemRequest } from '../../../store/ducks/shoppingCart/actions';
import { Action } from 'typesafe-actions';
import Swal from 'sweetalert2';
import { Size, useWindowSize } from '../../../customHooks';
import Scrollbars from 'react-custom-scrollbars';
import CustomTable from '../../CustomTable';

interface Props {
  product: any;
}

const MetreageCalculator: FC<Props> = ({ product }) => {
  const dispatch = useDispatch<Dispatch<Action>>();
  const layout = useSelector((state: ApplicationState) => state.layout.data);
  const { cart: shoppingCart } = useSelector((state: ApplicationState) => state.shoppingCart);
  const [visible, setVisible] = useState<boolean>(false);
  const [quantity, setQuantity] = useState<number>();
  const [metreage, setMetreage] = useState<number>();
  const [quantities, setQuantities] = useState<any[] | undefined>([]);
  const [totalQuantity, setTotalQuantity] = useState<number>(0);
  const [totalMetreage, setTotalMetreage] = useState<number>(0);
  const [totalValue, setTotalValue] = useState<number>(0);
  const size: Size = useWindowSize();

  const getValue = useCallback(() => {
    return product.VALOR.find(({ key }: any) => key === product?.SELECTED_UNITY).value ?? 0;
  }, [product]);
  useEffect(() => {
    setTotalQuantity(quantities?.reduce((total, item) => total + item.quantity, 0) ?? 0);
    setTotalMetreage(
      quantities?.reduce((total, item) => total + item.size * item.quantity, 0) ?? 0
    );
  }, [quantities]);

  useEffect(() => {
    if (shoppingCart.find((item) => item.codProd === product.CODPROD)?.metreages) {
      setQuantities(shoppingCart.find((item) => item.codProd === product.CODPROD)?.metreages);
    }
  }, [visible, shoppingCart, product.CODPROD]);

  useEffect(() => {
    setTotalValue(totalMetreage * getValue());
  }, [getValue, totalMetreage]);

  useEffect(() => {
    if (!shoppingCart.find((item) => item.codProd === product.CODPROD)?.metreages) {
      setQuantities([]);
    }
  }, [visible, shoppingCart, product.CODPROD]);

  const columns = [
    {
      title: 'Quantidade',
      dataIndex: 'quantity',
      key: 'quantity',
    },
    {
      title: 'Comprimento',
      dataIndex: 'size',
      key: 'size',
      hasTitle: true,
      render: (item: number) => (
        <>
          {item} {item === 1 ? 'metro' : 'metros'}
        </>
      ),
    },
    {
      title: 'Ação',
      key: 'action',
      render: (record: any) => (
        <Tooltip placement="right" title="Remover item">
          <ClearButton
            data-cy="btn-clear"
            type="text"
            shape="circle"
            danger
            icon={<DeleteFilled />}
            onClick={() => deleteQuatity(record)}
          />
        </Tooltip>
      ),
    },
  ];

  const multipleVerify = () => {
    return `${((metreage ?? 0) % product.MULTIPLOPADRAO).toFixed(3)}` === '0.000';
  };

  const onChangeQuantity = (value: any) => setQuantity(Math.floor(value < 0 ? 0 : value));

  const onChangeMetreage = (value: any) => setMetreage(value < 0 ? 0 : value);

  const handleModalVisibility = () => {
    setVisible(!visible);
  };

  const deleteQuatity = (record: any) => {
    const newQuantities = quantities?.filter((item) => item.size !== record.size);
    setQuantities(newQuantities);
    if (shoppingCart.find((item) => item.codProd === product.CODPROD)?.metreages) {
      if (newQuantities?.length === 0) {
        dispatch(
          deleteItemRequest({
            codProd: product?.CODPROD,
            quantity: 0,
            metreages: newQuantities,
            description: product?.DESCRPROD,
            value: getValue(),
            unity: product?.UNIDADE[0],
            isPromo: product?.PROMOCAO?.isPromo,
          })
        );
      } else {
        dispatch(
          addItemRequest({
            unity: product?.UNIDADE[0],
            codProd: product?.CODPROD,
            quantity:
              quantities?.reduce((total, item) => total + item.quantity, 0) ??
              0 *
                (
                  quantities?.reduce((total, item) => total + item.size * item.quantity, 0) ?? 0
                ).toFixed(2),
            metreages: newQuantities,
            description: product?.DESCRPROD,
            value: getValue(),
            isPromo: product?.PROMOCAO?.isPromo,
            controlType: product?.DETAILS?.stockControlType
          })
        );
      }
    }
  };
  const metreageExists = () => {
    return !!quantities?.find((item) => metreage === item.size);
  };

  const SendToShoppingCart = () => {
    handleModalVisibility();
    dispatch(
      addItemRequest({
        codProd: product?.CODPROD,
        unity: product?.UNIDADE[0],
        quantity: totalMetreage,
        metreages: quantities,
        description: product?.DESCRPROD,
        value: getValue(),
        isPromo: product?.PROMOCAO?.isPromo,
        controlType: product?.DETAILS?.stockControlType
      })
    );
  };

  const addQuantity = () => {
    if (metreageExists()) {
      Swal.fire({
        title: 'Metragem já adicionada, deseja continuar?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: layout?.color.default,
        confirmButtonText: 'Continuar',
        cancelButtonText: 'Cancelar',
      }).then((result) => {
        if (result.isConfirmed) {
          const arr = quantities?.map((item) => {
            return item.size === metreage
              ? { quantity: quantity + item.quantity, size: metreage }
              : item;
          });
          setQuantities(arr);
        }
      });
    } else {
      setQuantities((oldArray) => [...(oldArray ?? []), { quantity: quantity, size: metreage }]);
    }
    setMetreage(undefined);
    setQuantity(undefined);
  };
  return (
    <>
      <Button
        data-cy="btn-calculate"
        type="link"
        onClick={handleModalVisibility}
        style={{ padding: 0 }}
      >
        CALCULAR
      </Button>
      <Modal
        data-cy="modalMetreage"
        destroyOnClose
        centered
        visible={visible}
        title={`Produto: ${product?.DESCRPROD}`}
        onCancel={handleModalVisibility}
        footer={[
          <DefaultButton
            data-cy="btn-cancel"
            layout={layout}
            key="cancel"
            onClick={handleModalVisibility}
          >
            Cancelar
          </DefaultButton>,
          <SubmitButton
            data-cy="btn-addToCart"
            layout={layout}
            key="submit"
            type="primary"
            disabled={totalQuantity <= 0}
            onClick={SendToShoppingCart}
          >
            Adicionar ao carrinho
          </SubmitButton>,
        ]}
      >
        <Container layout={layout}>
          <Row gutter={[8, 8]}>
            <Col xs={24} sm={24} md={9} lg={9} xl={9}>
              <InputNumber
                data-cy="input-quantity"
                min={0}
                style={{ width: '100%' }}
                type="number"
                placeholder="Informe a quantidade"
                value={quantity}
                onChange={onChangeQuantity}
              />
            </Col>
            <Col xs={24} sm={24} md={10} lg={10} xl={10}>
              <InputNumber
                data-cy="input-lenght"
                min={0}
                style={{ width: '100%' }}
                type="number"
                placeholder="Informe o comprimento"
                value={metreage}
                onChange={onChangeMetreage}
              />
            </Col>
            <Col xs={24} sm={24} md={5} lg={5} xl={5}>
              {!product.MULTIPLOPADRAO ? (
                <Button
                  data-cy="btn-add"
                  style={{ width: '100%' }}
                  disabled={!quantity || !metreage}
                  type="default"
                  onClick={addQuantity}
                  block={(size?.width ?? 0) < 1024}
                >
                  Adicionar
                </Button>
              ) : (
                <Tooltip
                  title={`Para este produto a metragem deve ser múltipla de ${product.MULTIPLOPADRAO}`}
                >
                  <Button
                    data-cy="btn-add"
                    style={{ width: '100%' }}
                    type="default"
                    disabled={!quantity || !metreage || !multipleVerify()}
                    onClick={addQuantity}
                    block={(size?.width ?? 0) < 1024}
                  >
                    Adicionar
                  </Button>
                </Tooltip>
              )}
            </Col>
          </Row>
          <div style={{ height: '200px' }}>
            <Scrollbars>
              <CustomTable
                loading={false}
                data={quantities}
                columns={columns}
                onChange={() => {}}
              />
            </Scrollbars>
          </div>
          <Footer>
            <FooterCol data-cy="totalQtd">
              <FooterTitle>QTD TOTAL</FooterTitle>
              <strong>{totalQuantity}</strong>
            </FooterCol>
            <FooterCol data-cy="totalLenght">
              <FooterTitle>COMPRIMENTO TOTAL</FooterTitle>
              <strong>
                {totalMetreage.toFixed(2)} {totalMetreage === 1 ? 'metro' : 'metros'}
              </strong>
            </FooterCol>
            <FooterCol data-cy="unityValue">
              <FooterTitle>VALOR UNITÁRIO</FooterTitle>
              <strong>{maskCurrency(getValue())}</strong>
            </FooterCol>
            <FooterCol data-cy="totalValue">
              <FooterTitle>VALOR TOTAL</FooterTitle>
              <strong>{maskCurrency(totalValue)}</strong>
            </FooterCol>
          </Footer>
        </Container>
      </Modal>
    </>
  );
};

export default MetreageCalculator;
