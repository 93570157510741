import { call, put, select } from 'redux-saga/effects';
import api, { errorHandler } from '../../../services/api';
import { refreshRequest } from '../auth/actions';
import {
  dashboardConfigFailure,
  dashboardConfigRequest,
  dashboardConfigSuccess,
  dashboardSuccess,
  dashboardRequest,
  dashboardFailure,
} from './actions';
import { AxiosResponse } from 'axios';
import { requestHeaders } from '../../../utils/apiHelper';
import { ApplicationState } from '../..';
import { DashboardConfig, DashboardData } from './types';

export function* loadDashboardConfigs({ payload }: ReturnType<typeof dashboardConfigRequest>) {
  try {
    const state: ApplicationState = yield select();
    const headers = requestHeaders(state.auth.token);
    const response: AxiosResponse<DashboardConfig> = yield call(
      api.get,
      `dashboard/${payload.id}/config`,
      {
        headers: headers,
      }
    );
    yield put(dashboardConfigSuccess(response.data));
  } catch (err: any) {
    yield put(
      err.response.status !== 401 ? dashboardConfigFailure(errorHandler(err)) : refreshRequest()
    );
  }
}

export function* loadDashboardData({ payload }: ReturnType<typeof dashboardRequest>) {
  try {
    const state: ApplicationState = yield select();
    const headers = requestHeaders(state.auth.token);
    const response: AxiosResponse<DashboardData> = yield call(
      api.post,
      `dashboard/resolve-level`,
      {
        codDsb: payload.id,
        levelId: payload.levelId,
        levelPath: payload.levelId,
        ...payload.params
      },
      {
        headers: headers,
      }
    );

    yield put(dashboardSuccess(response.data));
  } catch (err: any) {
    yield put(err.response.status !== 401 ? dashboardFailure(errorHandler(err)) : refreshRequest());
  }
}
