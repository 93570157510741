import React, { useCallback, useEffect, useState } from 'react';
import { Menu, Row, Col } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  PicLeftOutlined,
  PlusCircleOutlined,
  FormOutlined,
  WalletOutlined,
  FileTextOutlined,
  DashboardOutlined,
  SolutionOutlined,
} from '@ant-design/icons';
import {
  BoxVersion,
  Container,
  CustomDrawer,
  Logo,
  LogoContent,
  MenuItem,
  SubMenuCustom,
} from './styles';
import { ApplicationState } from '../../store';
import logo from '../../assets/img/logo.png';
import { getImage } from '../../utils/image';
import { MENU_ROUTES } from '../../routes/routes';
import pecFavicon from '../../assets/img/favicon.png';
import { hasPermission } from '../../utils/permissions';
import packageJson from '../../../package.json';
import { colors } from '../../utils/constants';
import { Size, useWindowSize } from '../../customHooks';
import { RouteType } from '../../routes/routeType';
import Scrollbars from 'react-custom-scrollbars';
interface Props {
  collapsed: boolean;
  toggleCollapsed: () => void;
}
const Sidebar: React.FC<Props> = ({ collapsed, toggleCollapsed }) => {
  const layout = useSelector((state: ApplicationState) => state.layout.data);
  const additionalMenus = useSelector(
    (state: ApplicationState) => state.additionalScreens.screens
  );
  const roles = useSelector((state: ApplicationState) => state.user.data?.roles);
  const dashboardMenu = useSelector((state: ApplicationState) => state.dashboardMenu.data);
  const [selectedMenu, setSelectedMenu] = useState<string[]>(['']);
  const history = useHistory();
  const location = useLocation();

  const getMenuIcon = (iconName: string) => {
    switch (iconName) {
      case 'PicLeftOutlined':
        return <PicLeftOutlined />;
      case 'PlusCircleOutlined':
        return <PlusCircleOutlined />;
      case 'FormOutlined':
        return <FormOutlined />;
      case 'WalletOutlined':
        return <WalletOutlined />;
      case 'FileTextOutlined':
        return <FileTextOutlined />;
      default:
        return <SolutionOutlined />;
    }
  };

  const size: Size = useWindowSize();

  const getMenuRouteByName = (name: string): RouteType | undefined => {
    return MENU_ROUTES.find((item) => item.name === name);
  };

  const handleClick = (e: any) => {
    if (!e.key) {
      return;
    }
    if (!getMenuRouteByName(e.key)) {
      if (e.key.includes('/dashboard/')) {
        history.push(`/${e.key}`);
      } else if (additionalMenus?.find((item) => item.id === parseInt(e.key))?.showListing) {
        history.push(`/home/screen/${e.key}/list`);
      } else if (additionalMenus?.find((item) => item.id === parseInt(e.key))?.saveForm) {
        history.push(`/home/screen/${e.key}/form`);
      }
    } else {
      history.push(`${getMenuRouteByName(e.key)?.layout}${getMenuRouteByName(e.key)?.path}`);
    }
    if ((size?.width || 0) <= 1024) toggleCollapsed();
  };

  const getSelectedMenu = useCallback(() => {
    const currentPath = location.pathname;
    const menu = MENU_ROUTES.find(
      (route) => currentPath.includes(route.layout || '') && route.name !== 'Início'
    );

    const additionalMenuPath = additionalMenus?.find((item) =>
      currentPath.includes(`/screen/${item.id}/`)
    );
    const dashMenuPath = dashboardMenu?.find((item) =>
      currentPath.includes(`/dashboard/${item.codDsb}/`)
    );

    if (menu) {
      return [menu.name || ''];
    } else if (currentPath.includes('novo-pedido')) {
      return [''];
    } else if (currentPath.includes('meus-dados')) {
      return [''];
    } else if (currentPath.includes('resumo-da-compra')) {
      return [''];
    } else if (dashMenuPath) {
      return [`home/dashboard/${dashMenuPath.codDsb}/view` || ''];
    } else if (additionalMenuPath) {
      return [additionalMenuPath.id.toString() || ''];
    } else {
      return [MENU_ROUTES[0].name || ''];
    }
  }, [dashboardMenu, additionalMenus, location.pathname]);

  useEffect(() => {
    setSelectedMenu(getSelectedMenu());
  }, [getSelectedMenu]);

  return (
    <>
      {(size?.width || 0) > 1024 ? (
        <Container collapsed={collapsed}>
          <LogoContent collapsed={collapsed}>
            {!collapsed ? (
              <Logo
                alt=""
                src={
                  layout?.logo?.type === 'URL'
                    ? layout?.logo.image
                    : getImage(logo, layout?.logo?.image)
                }
              />
            ) : (
              <Logo
                alt=""
                src={
                  layout?.favicon?.type === 'URL'
                    ? layout?.favicon.image
                    : getImage(pecFavicon, layout?.favicon?.image)
                }
              />
            )}
          </LogoContent>
          <Scrollbars>
            <Menu
              style={{ maxWidth: '225px', marginBottom: '55px' }}
              onClick={handleClick}
              defaultSelectedKeys={['1']}
              mode="inline"
              inlineCollapsed={collapsed}
              selectedKeys={selectedMenu}
            >
              {MENU_ROUTES.map((item) => {
                return (
                  hasPermission(item.roles, roles) && (
                    <MenuItem layout={layout} key={item.name}>
                      <item.icon />
                      <span> {item.name}</span>
                    </MenuItem>
                  )
                );
              })}
              {dashboardMenu && dashboardMenu.length > 0 && (
                <SubMenuCustom
                  layout={layout}
                  key="dashboard"
                  icon={<DashboardOutlined />}
                  title="Dashboards"
                >
                  {dashboardMenu.map((menu) => {
                    return (
                      <MenuItem layout={layout} key={`home/dashboard/${menu.codDsb}/view`}>
                        {menu.title}
                      </MenuItem>
                    );
                  })}
                </SubMenuCustom>
              )}
              {additionalMenus
                ?.filter((item) => (item.saveForm || item.showListing) && !item.idFatherScreen)
                .map((item) => {
                  return (
                    <MenuItem layout={layout} key={`${item.id}`}>
                      {getMenuIcon(item.icon)}
                      <span> {item.description}</span>
                    </MenuItem>
                  );
                })}
            </Menu>
            <BoxVersion opened={!collapsed}>
              <Row align="middle" justify="center" gutter={[5, 5]}>
                <Col
                  span={24}
                  style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                >
                  <div
                    style={{
                      maxWidth: '50px',
                      maxHeight: '50px',
                    }}
                  >
                    <a href="https://zydon.com.br/b2b" target="_blank" rel="noreferrer">
                      <Logo src={logo} style={{ filter: 'grayscale(100%)' }} />
                    </a>
                  </div>
                </Col>
                <Col
                  span={24}
                  style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                >
                  <small style={{ color: `${colors.writeGrey}` }}>{`${collapsed ? 'Ver. ' : 'Versão '
                    }${packageJson.version}`}</small>
                </Col>
              </Row>
            </BoxVersion>
          </Scrollbars>
        </Container>
      ) : (
        <CustomDrawer
          closable={false}
          onClose={toggleCollapsed}
          visible={!collapsed}
          placement="left"
        >
          <LogoContent collapsed={collapsed}>
            <Logo
              alt=""
              src={
                layout?.logo?.type === 'URL'
                  ? layout?.logo.image
                  : getImage(logo, layout?.logo?.image)
              }
            />
          </LogoContent>
          <Scrollbars>
            <Menu
              style={{ width: '100%', marginBottom: '75px' }}
              onClick={handleClick}
              defaultSelectedKeys={['1']}
              selectedKeys={getSelectedMenu()}
            >
              {MENU_ROUTES.map((item) => {
                return (
                  hasPermission(item.roles, roles) && (
                    <MenuItem layout={layout} key={item.name}>
                      <item.icon />
                      <span> {item.name}</span>
                    </MenuItem>
                  )
                );
              })}
              {dashboardMenu && dashboardMenu.length > 0 && (
                <SubMenuCustom
                  layout={layout}
                  key="dashboard"
                  icon={<DashboardOutlined />}
                  title="Dashboards"
                >
                  {dashboardMenu.map((menu) => {
                    return (
                      <MenuItem layout={layout} key={`home/dashboard/${menu.codDsb}/view`}>
                        {menu.title}
                      </MenuItem>
                    );
                  })}
                </SubMenuCustom>
              )}
              {additionalMenus
                ?.filter((item) => (item.saveForm || item.showListing) && !item.idFatherScreen)
                .map((item) => {
                  return (
                    <MenuItem layout={layout} key={`${item.id}`}>
                      {getMenuIcon(item.icon)}
                      <span> {item.description}</span>
                    </MenuItem>
                  );
                })}
            </Menu>
            <BoxVersion opened={!collapsed}>
              <Row align="middle" justify="center" gutter={[5, 5]}>
                <Col
                  span={24}
                  style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                >
                  <div
                    style={{
                      maxWidth: '50px',
                      maxHeight: '50px',
                    }}
                  >
                    <a href="https://zydon.com.br/b2b" target="_blank" rel="noreferrer">
                      <Logo src={logo} style={{ filter: 'grayscale(100%)' }} />
                    </a>
                  </div>
                </Col>
                <Col
                  span={24}
                  style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                >
                  <span style={{ color: `${colors.writeGrey}` }}>
                    {`${collapsed ? 'Ver. ' : 'Versão '}${packageJson.version}`}
                  </span>
                </Col>
              </Row>
            </BoxVersion>
          </Scrollbars>
        </CustomDrawer>
      )}
    </>
  );
};

export default Sidebar;
