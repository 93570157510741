import { action } from 'typesafe-actions';
import { ResponseError } from '../../../services/api';
import { Config } from '../../../utils/newTableHelper';
import { CampaignProductsTypes } from './types';

export const requestCampaignProducts = (id: number) =>
  action(CampaignProductsTypes.CAMPAIGN_PRODUCTS_REQUEST, { id });
export const successCampaignProducts = (data: any[], config: Config) =>
  action(CampaignProductsTypes.CAMPAIGN_PRODUCTS_SUCCESS, { data, config });
export const failureCampaignProducts = (error: ResponseError) =>
  action(CampaignProductsTypes.CAMPAIGN_PRODUCTS_FAILURE, { error });
