import { Button, Col, Form, Input, notification, Radio, Row, Space, Spin, Upload } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import React, { Dispatch, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import {
  UploadOutlined,
  LoadingOutlined,
  LeftOutlined,
  RightOutlined,
  CheckOutlined,
} from '@ant-design/icons';
import { Action } from 'typesafe-actions';
import CustomSteps from '../../components/CustomSteps';
import CustomTable from '../../components/CustomTable';
import DynamicFields from '../../components/DynamicFields';
import ServerError from '../../components/ServerError';
import { baseURL } from '../../services/api';
import { ApplicationState } from '../../store';
import {
  loadFormRequest,
  loadListRequest,
  loadModelsRequest,
  sendFailure,
  sendRequest,
  sendSuccess,
  setAttachments,
  setParams,
  setRequiredParams,
} from '../../store/ducks/newServiceOrder/actions';
import { Attachment, Params } from '../../store/ducks/newServiceOrder/types';
import { configToColumnsType, dataToTableData } from '../../utils/newTableHelper';
import { BackButton, ButtonsContainer, FormContent, FullWidthDiv, WhiteContainer } from './styles';
import { MESSAGES } from '../../utils/constants';

const { Search } = Input;
const { Dragger } = Upload;

const NewServiceOrder: React.FC = () => {
  const models = useSelector((state: ApplicationState) => state.newServiceOrder.data);
  const params = useSelector((state: ApplicationState) => state.newServiceOrder.params);
  const requiredParams = useSelector(
    (state: ApplicationState) => state.newServiceOrder.requiredParams
  );
  const error = useSelector((state: ApplicationState) => state.newServiceOrder.error);
  const newId = useSelector((state: ApplicationState) => state.newServiceOrder.newId);
  const sendError = useSelector((state: ApplicationState) => state.newServiceOrder.sendError);
  const loading = useSelector((state: ApplicationState) => state.newServiceOrder.loading);
  const sendLoading = useSelector((state: ApplicationState) => state.newServiceOrder.sendLoading);
  const stepForm = useSelector((state: ApplicationState) => state.newServiceOrder.stepForm);
  const stepData = useSelector((state: ApplicationState) => state.newServiceOrder.stepData);
  const stepConfig = useSelector((state: ApplicationState) => state.newServiceOrder.stepConfig);
  const pagination = useSelector((state: ApplicationState) => state.newServiceOrder.pagination);
  const layout = useSelector((state: ApplicationState) => state.layout.data);
  const [value, setValue] = useState<number>();
  const [searchInput, setSearchInput] = useState<string>('');
  const [current, setCurrent] = useState<number>(0);
  const [tableSelectKey, setTableSelectKey] = useState<any>();
  const [columns, setColumns] = useState<ColumnsType<any>>([]);
  const [selectedFields, setSelectedFields] = useState<Params[]>([]);
  const [data, setData] = useState<any[]>([]);
  const [form] = Form.useForm();
  const history = useHistory();
  const messageRender = (file: any) => {
    switch (file.status) {
      case 'error':
        notification.error({
          message: `${file.name} - ${file.response.message}`,
          placement: 'bottomRight',
        });
        break;
      case 'done':
        notification.success({
          message: `${file.name} - ${MESSAGES.UPLOAD_SUCCESS}`,
          placement: 'bottomRight',
        });
        break;
      default:
        break;
    }
  };

  const onSearch = () => {
    dispatch(
      loadListRequest(models.find(({ id }) => value === id)?.steps[current], value, searchInput)
    );
  };

  const sendParamsToNextStep = () => {
    const newParams: Params[] = [...params];
    const newRequiredParams: Params[] = [...requiredParams];
    selectedFields.forEach((item) => {
      const index = newParams.findIndex((element) => element.name === item.name);
      const requiredIndex = newRequiredParams.findIndex((element) => element.name === item.name);
      if (index !== -1) {
        newParams[index] = item;
      } else {
        newParams.push(item);
      }
      if (requiredIndex !== -1) {
        newRequiredParams[index] = item;
      } else {
        newRequiredParams.push(item);
      }
    });
    dispatch(setParams(newParams));
    dispatch(setRequiredParams(newRequiredParams));
    setSelectedFields([]);
    setTableSelectKey(undefined);
    setSearchInput('');
  };

  const next = () => {
    if (
      models.find(({ id }) => value === id)?.steps[current].id === 'SELECT_SERIES' ||
      models.find(({ id }) => value === id)?.steps[current].id === 'SELECT_PRODUCT'
    ) {
      sendParamsToNextStep();
    }
    if (current !== (getStepsByModelId()?.length ?? 0) - 1) {
      setCurrent(current + 1);
    } else {
      dispatch(sendRequest(value));
    }
  };

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      const step = models.find(({ id }) => value === id)?.steps[current];
      const fields = selectedRows[0];
      const arr: Params[] =
        step?.requiredFields.map((item) => {
          return {
            step: models.find(({ id }) => value === id)?.steps[current].id,
            id: fields[`${item}_ID`],
            name: item,
            value: fields[item],
          };
        }) || [];
      setSelectedFields(arr);
      setTableSelectKey(selectedRowKeys);
    },
    selectedRowKeys: tableSelectKey,
  };

  const onFinish = (formValues: any) => {
    const newParams: Params[] = [...params];
    const newRequiredParams: Params[] = [...requiredParams];
    const attachmentFiles: Attachment[] = [];
    Object.keys(formValues).forEach((item) => {
      const index = newParams.findIndex((element) => element.name === item);
      const requiredIndex = newRequiredParams.findIndex((element) => element.name === item);
      if (item === 'attachment' && formValues[item]) {
        attachmentFiles.push(
          ...formValues[item].fileList.map((file: any) => {
            return { description: file.name, attachment: file.response.attachment };
          })
        );
      } else {
        const newParam: Params = {
          step: models.find(({ id }) => value === id)?.steps[current].id,
          id: stepForm.find((field) => field.fieldName === item)?.idField,
          name: item,
          value:
            formValues[item] instanceof moment
              ? moment(formValues[item]).format('DD/MM/YYYY')
              : formValues[item],
        };

        if (index !== -1) {
          newParams[index] = newParam;
        } else {
          newParams.push(newParam);
        }
        if (requiredIndex !== -1) {
          newRequiredParams[requiredIndex] = newParam;
        } else if (
          models
            .find(({ id }) => value === id)
            ?.steps[current].requiredFields.find((field) => item === field)
        ) {
          newRequiredParams.push(newParam);
        }
      }
    });
    dispatch(setParams(newParams.filter((item) => item.value)));
    dispatch(setRequiredParams(newRequiredParams.filter((item) => item.value)));
    dispatch(setAttachments(attachmentFiles.filter((attachment) => attachment.attachment)));
    next();
  };

  const getStepsByModelId = () => models.find(({ id }) => value === id)?.steps;

  const previous = () => {
    setSearchInput('');
    setTableSelectKey(undefined);
    setSelectedFields([]);
    dispatch(
      setParams(
        params.filter(
          (item) =>
            models
              .find(({ id }) => value === id)
              ?.steps[current].requiredFields.findIndex((field) => field === item.name) === -1
        )
      )
    );
    dispatch(
      setRequiredParams(
        requiredParams.filter(
          (item) =>
            models
              .find(({ id }) => value === id)
              ?.steps[current].requiredFields.findIndex((field) => field === item.name) === -1
        )
      )
    );
    setCurrent(current - 1);
  };
  const onChange = (e: RadioChangeEvent) => {
    setValue(e.target.value);
  };

  const renderContent = () => {
    return (
      <Row gutter={[20, 20]}>
        {models.find(({ id }) => value === id)?.steps[current].id === 'SELECT_TEMPLATE' && (
          <Col span={24}>
            <FullWidthDiv>
              <FormContent>
                <Radio.Group onChange={onChange} value={value}>
                  <Space direction="vertical">
                    {models.map((model) => {
                      return <Radio value={model.id}>{model.description}</Radio>;
                    })}
                  </Space>
                </Radio.Group>
              </FormContent>
              <ButtonsContainer>
                {current < (getStepsByModelId()?.length ?? 0) - 1 && (
                  <Form.Item>
                    <Button onClick={next} type="primary">
                      Próximo <RightOutlined />
                    </Button>
                  </Form.Item>
                )}
              </ButtonsContainer>
            </FullWidthDiv>
          </Col>
        )}
        {models.find(({ id }) => value === id)?.steps[current].id === 'FORM' && (
          <Col span={24}>
            <Form
              form={form}
              onFinish={onFinish}
              preserve={false}
              style={{ width: '100%' }}
              layout="vertical"
            >
              <FormContent>
                <Spin
                  style={{ color: layout?.color.default }}
                  spinning={loading}
                  delay={500}
                  indicator={<LoadingOutlined />}
                >
                  {stepForm.map((formItem) => {
                    return (
                      <DynamicFields
                        type={formItem.fieldType}
                        label={formItem.description}
                        foreign={formItem.foreignEntity}
                        name={formItem.fieldName}
                        placeholder={formItem.placeholder}
                        value={
                          params.find((item) => item.name === formItem.fieldName)?.value ||
                          formItem.defaultValue
                        }
                        disabled={!formItem.enable}
                        required={formItem.required}
                        urlUnique={
                          formItem.fieldType === 'OPTIONS'
                            ? `service-order/template/${value}/form/fields/${formItem.idField}/options`
                            : undefined
                        }
                      />
                    );
                  })}
                  {models.find(({ id }) => value === id)?.enableAttach && (
                    <Form.Item label="Anexos" key={'attacthment'} name={'attachment'}>
                      <Dragger
                        multiple
                        action={`${baseURL}/service-order/upload?id=${value}`}
                        accept={
                          models.find(({ id }) => value === id)?.fileTypes
                            ? models.find(({ id }) => value === id)?.fileTypes.toString()
                            : ''
                        }
                        onChange={({ file }) => messageRender(file)}
                        name="file"
                      >
                        <p className="ant-upload-drag-icon">
                          <UploadOutlined style={{ color: layout?.color.default }} />
                        </p>
                        <p className="ant-upload-text">
                          Clique ou arraste o(s) arquivo(s) para esta área para fazer o upload
                        </p>
                      </Dragger>
                    </Form.Item>
                  )}
                </Spin>
              </FormContent>
              <ButtonsContainer>
                {current > 0 && (
                  <BackButton onClick={previous} type="default">
                    <LeftOutlined /> Anterior
                  </BackButton>
                )}
                {current < (getStepsByModelId()?.length ?? 0) - 1 && (
                  <Form.Item>
                    <Button htmlType="submit" type="primary">
                      Próximo <RightOutlined />
                    </Button>
                  </Form.Item>
                )}
                {current === (getStepsByModelId()?.length ?? 0) - 1 && (
                  <Button loading={sendLoading} htmlType="submit" type="primary">
                    Finalizar <CheckOutlined />
                  </Button>
                )}
              </ButtonsContainer>
            </Form>
          </Col>
        )}
        {(models.find(({ id }) => value === id)?.steps[current].id === 'SELECT_SERIES' ||
          models.find(({ id }) => value === id)?.steps[current].id === 'SELECT_PRODUCT') && (
          <>
            <Col span={24}>
              <WhiteContainer>
                <Search
                  data-cy="input-search"
                  placeholder={`${stepConfig?.placeholder ?? 'Buscar'}`}
                  allowClear
                  value={searchInput}
                  onSearch={onSearch}
                  onChange={({ target }) => setSearchInput(target.value)}
                />
              </WhiteContainer>
            </Col>
            <Col span={24}>
              <CustomTable
                style={{ padding: '20px' }}
                noResposiveMode
                onChange={(pg: any, filters: any, sorter: any) => {
                  dispatch(
                    loadListRequest(
                      models.find(({ id }) => value === id)?.steps[current],
                      value,
                      searchInput,
                      filters,
                      sorter,
                      pg
                    )
                  );
                }}
                loading={loading}
                columns={columns}
                data={data}
                rowSelection={{ type: 'radio', ...rowSelection }}
                pagination={pagination}
              />
            </Col>
            <Col span={24}>
              <ButtonsContainer>
                {current > 0 && (
                  <BackButton onClick={previous} type="default">
                    <LeftOutlined /> Anterior
                  </BackButton>
                )}
                {current < (getStepsByModelId()?.length ?? 0) - 1 && (
                  <Form.Item>
                    <Button disabled={selectedFields.length === 0} onClick={next} type="primary">
                      Próximo <RightOutlined />
                    </Button>
                  </Form.Item>
                )}
                {current === (getStepsByModelId()?.length ?? 0) - 1 && (
                  <Button
                    loading={sendLoading}
                    disabled={selectedFields.length === 0}
                    onClick={next}
                    type="primary"
                  >
                    Finalizar <CheckOutlined />
                  </Button>
                )}
              </ButtonsContainer>
            </Col>
          </>
        )}
      </Row>
    );
  };

  const dispatch = useDispatch<Dispatch<Action>>();
  useEffect(() => {
    setColumns(configToColumnsType(stepConfig?.fields.filter(({ show }) => show) || []));
  }, [stepConfig]);

  useEffect(() => {
    setData(dataToTableData(stepData || [], stepConfig));
  }, [stepConfig, stepData]);

  useEffect(() => {
    dispatch(loadModelsRequest());
  }, [dispatch]);

  useEffect(() => {
    if (!sendError) return;
    Swal.fire({
      title: `Erro!`,
      confirmButtonColor: layout?.color.default,
      html: sendError?.message,
      icon: 'error',
      onClose: () => {
        dispatch(sendFailure(undefined));
      },
    }).then(() => {
      dispatch(sendFailure(undefined));
    });
  }, [dispatch, layout, sendError]);

  useEffect(() => {
    if (!newId) return;
    Swal.fire({
      title: `Sucesso!`,
      confirmButtonColor: layout?.color.default,
      html: `Ordem de serviço ${newId} lançada com sucesso!`,
      icon: 'success',
      onClose: () => {
        history.goBack();
        dispatch(sendSuccess(undefined));
      },
    }).then(() => {
      dispatch(sendSuccess(undefined));
    });
  }, [dispatch, history, layout, newId]);

  useEffect(() => {
    if (!value) return;
    switch (models.find(({ id }) => value === id)?.steps[current].id) {
      case 'SELECT_TEMPLATE':
        dispatch(setParams([]));
        dispatch(setRequiredParams([]));
        return;
      case 'FORM':
        dispatch(loadFormRequest(value));
        return;
      case 'SELECT_SERIES':
      case 'SELECT_PRODUCT':
        dispatch(loadListRequest(models.find(({ id }) => value === id)?.steps[current], value));
        return;
      default:
        return;
    }
  }, [current, dispatch, models, value]);

  useEffect(() => {
    setValue(models[0]?.id);
  }, [models]);
  return (
    <>
      {error ? (
        <ServerError {...error} />
      ) : (
        <>
          <CustomSteps current={current} steps={getStepsByModelId()}>
            {renderContent()}
          </CustomSteps>
        </>
      )}
    </>
  );
};

export default NewServiceOrder;
