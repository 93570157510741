import styled from 'styled-components';
import { colors } from '../../utils/constants';

export const PageContent = styled.div`
  margin: 20px auto;
  max-width: 1366px;
  padding: 20px;
  border-radius: 5px;
  background-color: white;
  box-shadow: 1px 2px 2px 2px ${colors.grey};
  @media only screen and (max-width: 1366px) {
    padding: 10px;
    margin: 10px;
  }
`;

export const Content = styled.div`
  margin: 20px auto;
  max-width: 1366px;
  padding: 20px;
  border-radius: 5px;
  background-color: white;
  box-shadow: 1px 2px 2px 2px ${colors.grey};
  @media only screen and (max-width: 1366px) {
    padding: 10px;
    margin: 10px;
  }
`;

export const InputContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const Value = styled.h1`
  color: green;
  font-size: 24px;
  margin: 0;
`;

export const TextDisplay = styled.pre`
  white-space: pre-wrap;
  margin-right: 10px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,
    'Open Sans', 'Helvetica Neue', sans-serif;
`;

export const DescriptionContent = styled.div`
  width: 100%;
`;

export const TextContent = styled.div`
  margin: 20px auto;
  max-width: 1366px;
  padding: 20px;
  border-radius: 5px;
  background-color: white;
  box-shadow: 1px 2px 2px 2px ${colors.grey};
  @media only screen and (max-width: 1366px) {
    padding: 10px;
    margin: 10px;
  }
`;
