import { Button } from 'antd';
import styled from 'styled-components';
import { Layout } from '../../store/ducks/layout/types';
import { colors } from '../../utils/constants';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const MyOrdersHeader = styled.div`
  padding: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  background-color: white;
  border-radius: 5px;
  box-shadow: 1px 2px 2px 2px ${colors.grey};
`;

export const CustomButton = styled(Button)<{ layout?: Layout }>`
  margin-right: 5px;
  :hover {
    color: ${({ layout }) => layout?.color.default};
    border: 1px solid ${({ layout }) => layout?.color.default};
  }
  :active {
    color: ${({ layout }) => layout?.color.default};
    border: 1px solid ${({ layout }) => layout?.color.default};
  }
  :focus {
    color: ${({ layout }) => layout?.color.default};
    border: 1px solid ${({ layout }) => layout?.color.default};
  }
`;

export const Content = styled.div`
  padding: 20px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 1px 2px 2px 2px ${colors.grey};
`;
