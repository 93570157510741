import { ResponseError } from '../../../services/api';

export enum HomeProductsTypes {
  'LOAD_REQUEST' = '@homeProduct/LOAD_REQUEST',
  'LOAD_SUCCESS' = '@homeProduct/LOAD_SUCCESS',
  'LOAD_FAILURE' = '@homeProduct/LOAD_FAILURE',
}

export interface HomeProductsState {
  readonly data: any[];
  readonly config: any[];
  readonly loading: boolean;
  readonly error?: ResponseError;
}

