import React, { useState } from 'react';
import { Table } from 'antd';
import ResizeObserver from 'rc-resize-observer';

import { VirtualTableProps } from './props';
import { TableContainer } from './styles';
import Body from './Body';

const VirtualTable: React.FC<VirtualTableProps> = ({ columns, data, scroll, onClick }) => {
  const [tableWidth, setTableWidth] = useState(0);
  const mergedColumns = columns!.map((column: any) => {
    if (column.width) {
      return column;
    }

    return {
      ...column,
      width: 240,
    };
  });

  return (
    <ResizeObserver
      onResize={({ width }) => {
        setTableWidth(width);
      }}
    >
      <TableContainer>
        <Table
          rowKey="id"
          size="small"
          tableLayout='fixed'
          scroll={scroll}
          showSorterTooltip={false}
          className="virtual-table"
          dataSource={data}
          columns={mergedColumns}
          pagination={false}
          components={{
            body: (rawData: any, { scrollbarSize, ref, onScroll }: any) =>
              <Body
                rawData={rawData}
                scrollbarSize={scrollbarSize}
                tRef={ref}
                onScroll={onScroll}
                tableWidth={tableWidth}
                mergedColumns={mergedColumns}
                scroll={scroll}
                onClick={onClick}
              />,
          }}
        />
      </TableContainer>
    </ResizeObserver>
  );
}

export default VirtualTable;
