import { Button, Col, Row, Spin } from 'antd';
import React, { Dispatch, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Action } from 'typesafe-actions';
import { LoadingOutlined, EditOutlined } from '@ant-design/icons';
import ServerError from '../../components/ServerError';
import { ApplicationState } from '../../store';
import { colors } from '../../utils/constants';
import { DetailRequest } from '../../store/ducks/screenDetail/actions';
import mask from '../../utils/masks';
import { getColumnType, KeyValue } from '../../utils/newTableHelper';
import RenderImage from './RenderImage';
import ReportsDropdown from '../../components/ReportsDropdown';
import SubScreens from '../../components/SubScreens';

const ScreenEdit: React.FC = () => {
  const { screenId, id }: { screenId: string; id: string } = useParams();
  const layout = useSelector((state: ApplicationState) => state.layout.data);
  const groups = useSelector((state: ApplicationState) => state.screenDetail.data);
  const config = useSelector((state: ApplicationState) => state.screenDetail.config);
  const [displayData, setDisplayData] = useState<any[]>([]);
  const error = useSelector((state: ApplicationState) => state.screenDetail.error);
  const loading = useSelector((state: ApplicationState) => state.screenDetail.loading);
  const editRole = useSelector(
    (state: ApplicationState) =>
      state.additionalScreens.screens.find((item) => item.id === parseInt(screenId))?.edit
  );
  const menus = useSelector((state: ApplicationState) => state.additionalScreens.screens);
  const dispatch = useDispatch<Dispatch<Action>>();

  const history = useHistory();

  const buildValue = (value: any, options?: KeyValue[]) => {
    if (!options || options.length === 0) return value;

    return options.find(option => option.key === value)?.value;
  }

  useEffect(() => {
    dispatch(DetailRequest(id, screenId));
  }, [dispatch, id, screenId]);

  useEffect(() => {
    const data: any[] = [];
    groups.forEach((group) => {
      data.push(
        ...group.fields
          .filter((field) => field.id > 0)
          .map((field) => {
            const configField = config?.fields.find((item) => item.id === field.id);
            return { ...configField, ...field, value: buildValue(field.value, configField?.options) };
          })
      );
    });

    setDisplayData(data);
  }, [config?.fields, groups]);

  const sorter = () => {
    return displayData.sort((a, b) => {
      if (a.position < b.position) {
        return -1;
      }
      if (a.position > b.position) {
        return 1;
      }
      return 0;
    });
  };

  useEffect(() => {
    if (!menus?.some((item) => item.id.toString() === screenId))
      history.push('/')
  }, [menus, screenId, history]);

  return (
    <Spin
      style={{ color: layout?.color.default }}
      spinning={loading}
      size="large"
      delay={500}
      indicator={<LoadingOutlined />}
    >
      {error ? (
        <ServerError {...error} />
      ) : (
        <>
          <Row>
            {(groups.some((group) => group.title === 'injects') || editRole) && (
              <Col
                style={{
                  backgroundColor: 'white',
                  padding: '20px',
                  marginBottom: '20px',
                  borderRadius: '5px',
                  boxShadow: `1px 2px 2px 2px ${colors.grey}`,
                }}
                span={24}
              >
                <Row justify="end" gutter={[20, 20]}>
                  {groups
                    .find((group) => group.title === 'injects')
                    ?.fields.filter(field => field.id === -1).map((field) => {
                      return (
                        <Col xs={24} sm={24} md={3} lg={3} xl={3} className="alll">
                          <ReportsDropdown
                            value={field.value}
                            type="primary"
                            block
                            pdfUrl={`screen/${screenId}/registry/${id}/report`}
                          />
                        </Col>
                      );
                    })}
                  {editRole && (
                    <Col xs={24} sm={24} md={3} lg={3} xl={3} className="alll">
                      <Button
                        data-cy="btn-Edit"
                        icon={<EditOutlined />}
                        type="primary"
                        block
                        onClick={() => history.push(`/home/screen/${screenId}/edit/${id}`)}
                      >
                        Editar
                      </Button>
                    </Col>
                  )}
                </Row>
              </Col>
            )}
            {groups
              .filter((group) => group.title !== 'injects')
              .map((group) => {
                return (
                  <Col
                    style={{
                      backgroundColor: 'white',
                      padding: '20px',
                      marginBottom: '20px',
                      borderRadius: '5px',
                      boxShadow: `1px 2px 2px 2px ${colors.grey}`,
                    }}
                    span={24}
                  >
                    <h2 style={{ color: layout?.color.dark }}>{group.title}</h2>
                    <Row gutter={[20, 20]} justify="start">
                      {sorter()
                        .filter((item) => (item.grouping || '') === (group.title || ''))
                        .map((field) => {
                          if (field?.mask === "link" && field?.value) {
                            return (
                              <Col
                                xs={24}
                                sm={24}
                                md={field.columnSize * 6}
                                lg={field.columnSize * 6}
                                xl={field.columnSize * 6}
                              >
                                <h3 style={{ color: layout?.color.dark }}>{field.description} </h3>

                                <a href={field?.value} target="__blank" style={{
                                  display: 'block',
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                }}>{field?.value}</a>
                              </Col>
                            );
                          }

                          if (field.type !== 'BLOB') {
                            return (
                              <Col
                                xs={24}
                                sm={24}
                                md={field.columnSize * 6}
                                lg={field.columnSize * 6}
                                xl={field.columnSize * 6}
                              >
                                <h3 style={{ color: layout?.color.dark }}>{field.description} </h3>

                                {mask(
                                  field?.mask || getColumnType(field.type, false),
                                  field?.value
                                ) || '-'}
                              </Col>
                            );
                          }

                          return (
                            <Col
                              xs={24}
                              sm={24}
                              md={field.columnSize * 6}
                              lg={field.columnSize * 6}
                              xl={field.columnSize * 6}
                            >
                              <h3 style={{ color: layout?.color.dark }}>{field.description} </h3>
                              <RenderImage
                                urlImage={`/screen/${screenId}/registry/${id}/field/${field.id}/file`}
                              />
                            </Col>
                          );
                        })}
                    </Row>
                  </Col>
                );
              })}
          </Row>

          {!loading && displayData.length > 0 && <SubScreens config={config} father={displayData} />}
        </>
      )}
    </Spin>
  );
};

export default ScreenEdit;
