import React, { Dispatch, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Action } from 'typesafe-actions';
import { ApplicationState } from '../../store';
import { deliveriesRequest, pointRequest, pointSuccess } from '../../store/ducks/newDeliveryPoint/actions';
import { Container } from '../../styles';
import PointForm from './PointForm';

const NewDeliveryPoint: React.FC = () => {
  const layout = useSelector((state: ApplicationState) => state.layout.data);
  const token = useSelector((state: ApplicationState) => state.auth.token);
  const { userId, contactId, id }: any = useParams();

  const dispatch = useDispatch<Dispatch<Action>>();
  useEffect(() => {
    if (!userId || !contactId) {
      return;
    }
    if (id) {
      dispatch(pointRequest(userId, contactId, id));
    } else {
      dispatch(pointSuccess(undefined));

    }
    dispatch(deliveriesRequest(userId, contactId));
  }, [contactId, dispatch, id, token, userId]);
  return (
    <Container layout={layout}>
      <PointForm />
    </Container>
  );
};

export default NewDeliveryPoint;
