import { AxiosResponse } from 'axios';
import { call, put, select } from 'redux-saga/effects';
import { ApplicationState } from '../..';
import api, { baseURL, errorHandler } from '../../../services/api';
import { requestHeaders } from '../../../utils/apiHelper';
import { Config, Data, dataToTableData } from '../../../utils/newTableHelper';
import { refreshRequest } from '../auth/actions';
import { loadFailure, loadRequest, loadSuccess, setUnityDetail, unitySuccess } from './actions';

export function* loadProduct({ payload }: ReturnType<typeof loadRequest>) {
  try {
    const state: ApplicationState = yield select();
    const headers = requestHeaders(state.auth.token);
    const config: AxiosResponse<Config> = yield call(api.get, '/new-order/products/template', {
      headers: headers,
    });
    const response: AxiosResponse<Data> = yield call(
      api.post,
      `/new-order/products/detail/${payload.id}`,
      { params: state.orderFields.values.length === 0 ? undefined : state.orderFields.values },
      {
        headers: headers,
      }
    );
    const relatedProducts: any[] = [];

    // const relatedProductsResponse = yield call(
    //   api.post,
    //   `produtos/${payload.id}/relacionados`,
    //   {
    //     informativos: state.orderFields.values.length === 0 ? undefined : state.orderFields.values,
    //   },
    //   { headers: headers }
    // );
    // const relatedProductsData = yield call(dataToTableData, relatedProductsResponse.data.content, [
    //   ...config.data.campos,
    // ]);

    // const relatedProducts = relatedProductsData.map((item: any) => {
    //   return {
    //     ...item,
    //     SELECTED_UNITY: item?.UNIDADE[0].key,
    //   };
    // });

    const tableData: any[] = yield call(dataToTableData, [response.data], config.data);
    const data = tableData.map((item: any) => {
      return {
        ...item,
        SELECTED_UNITY: item?.UNIDADE[0].key,
      };
    });

    const images =
      data[0].DETAILS.alternativeImages.length > 0
        ? [
            `${baseURL}/produtos/${payload.id}/imagem?w=320&h=320`,
            ...data[0].DETAILS.alternativeImages.map(
              (item: any) =>
                `${baseURL}/produtos/${payload.id}/imagens/alternativas/${item.key}?w=320&h=320`
            ),
          ]
        : [`${baseURL}/produtos/${payload.id}/imagem?w=320&h=320`];

    const thumbnails =
      data[0].DETAILS.alternativeImages?.length > 0
        ? [
            `${baseURL}/produtos/${payload.id}/imagem?w=50&h=50`,
            ...data[0].DETAILS.alternativeImages.map(
              (item: any) =>
                `${baseURL}/produtos/${payload.id}/imagens/alternativas/${item.key}?w=50&h=50`
            ),
          ]
        : [`${baseURL}/produtos/${payload.id}/imagem?w=50&h=50`];

    yield put(loadSuccess(data, relatedProducts, config.data, images, thumbnails));
  } catch (err: any) {
    yield put(err?.response?.status !== 401 ? loadFailure(errorHandler(err)) : refreshRequest());
  }
}

export function* changeUnityDetail({ payload }: ReturnType<typeof setUnityDetail>) {
  const state: ApplicationState = yield select();
  const newData = state.productDetail.data;
  newData[0].SELECTED_UNITY = payload.unity;
  yield put(unitySuccess([...newData]));
}
