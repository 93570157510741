import { Spin, Form } from 'antd';
import React, { Dispatch, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { LoadingOutlined } from '@ant-design/icons';
import { ReconciliationOutlined } from '@ant-design/icons';
import { Action } from 'typesafe-actions';
import DynamicFields from '../../../components/DynamicFields';
import { ApplicationState } from '../../../store';
import { loadFieldsRequest } from '../../../store/ducks/budgetFields/actions';
import { FormInstance } from 'antd/lib/form';
import CustomCollapse from '../../../components/CustomCollapse';

interface Props {
  form: FormInstance<any>;
  onValuesChange: (_value: any, values: any) => void;
}
const BudgetFields: React.FC<Props> = ({ form, onValuesChange }) => {
  const dispatch = useDispatch<Dispatch<Action>>();
  const budgetFields = useSelector((state: ApplicationState) => state.budgetFields.data);
  const layout = useSelector((state: ApplicationState) => state.layout.data);
  const budgetloading = useSelector((state: ApplicationState) => state.budgetFields.loading);
  const budgetValues = useSelector(
    (state: ApplicationState) =>
      state.shoppingCart.fields.filter(
        (item) => item.key !== 'observation' && item.key !== 'payment'
      ),
    shallowEqual
  );
  useEffect(() => {
    dispatch(loadFieldsRequest());
  }, [dispatch]);

  return (
    <Spin
      style={{ color: layout?.color.default }}
      spinning={budgetloading}
      size="small"
      delay={500}
      indicator={<LoadingOutlined />}
    >
      <CustomCollapse
        icon={<ReconciliationOutlined style={{ color: layout?.color.default }} />}
        title="Orçamento"
        hasNotification
        defaultOpened
        dot={budgetValues.length !== budgetFields.length}
      >
        <Form layout="vertical" onValuesChange={onValuesChange} form={form}>
          {budgetFields.map((item) => (
            <DynamicFields
              type={item.fieldType}
              label={item.description}
              foreign={item.foreignEntity}
              name={item.fieldName}
              placeholder={item.placeholder}
              value={budgetValues.find((budgetValue) => budgetValue.key === item.fieldName)?.value}
              disabled={!item.enable}
              required={item.required}
              urlUnique={
                item.fieldType === 'OPTIONS'
                  ? `/new-budget/form/${item.idField}/options`
                  : undefined
              }
            />
          ))}
        </Form>
      </CustomCollapse>
    </Spin>
  );
};

export default BudgetFields;
