import { Dispatch, FC, ReactElement, useEffect, useState } from 'react';
import { Avatar, Badge, Button, Dropdown, Menu, Modal, Tabs } from 'antd';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { DownOutlined, LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { Action } from 'typesafe-actions';
import { useHistory } from 'react-router-dom';

import { ApplicationState } from '../../store';
import { loadPhotoRequest } from '../../store/ducks/user/actions';
import { cleanState } from '../../store/ducks/auth/actions';
import { maskCamelCase } from '../../utils/masks';
import PartnerModal from '../PartnerModal';
import ShoppingCartDrawer from '../ShoppingCartDrawer';
import {
  ButtonWithOutBorder,
  Container,
  Content,
  MenuIcon,
  NewOrderButton,
  PartnerName,
  ShoppingIcon,
  TextAvatar,
  UserContainer,
  UserContent,
  QuestionIcon,
  VideoContainer
} from './styles';
import { hasPermission } from '../../utils/permissions';
import { ROLE } from '../../utils/constants';
import { Size, useWindowSize } from '../../customHooks';
import { formatNumberTwoDecimalPlaces } from '../../utils/formatNumberHelper';
import { clearOrderValues } from '../../store/ducks/orderFields/actions';
export interface Props {
  toggleCollapsed: Function;
}

const { TabPane } = Tabs;

const Navbar: FC<Props> = ({ toggleCollapsed }) => {
  const user = useSelector((state: ApplicationState) => state.user.data);
  const quantity = useSelector((state: ApplicationState) => state.shoppingCart.totalQuantity);
  const editId = useSelector((state: ApplicationState) => state.shoppingCart.editId);
  const photo = useSelector((state: ApplicationState) => state.user.photo);
  const roles = useSelector((state: ApplicationState) => state.user.data?.roles);
  const layout = useSelector((state: ApplicationState) => state.layout.data, shallowEqual);
  const size: Size = useWindowSize();
  const history = useHistory();
  const [modalHelperOpen, setModalHelperOpen] = useState(false);

  const handleLogout = () => {
    dispatch(cleanState());
    dispatch(clearOrderValues());
  }

  const dispatch = useDispatch<Dispatch<Action>>();
  const handleButton = () => {
    history.push('/home/novo-pedido');
  };

  const UserMenu: ReactElement = (
    <Menu>
      {(size?.width ?? 0) <= 640 && (
        <>
          <Menu.Item>
            <UserContainer>
              {!photo ? (
                <TextAvatar layout={layout}>{user?.name[0]}</TextAvatar>
              ) : (
                <Avatar src={photo} />
              )}
              <UserContent>
                <strong>{maskCamelCase(user?.name || '')}</strong>
                <PartnerName>{maskCamelCase(user?.partner?.name || '')}</PartnerName>
              </UserContent>
            </UserContainer>
          </Menu.Item>
          <Menu.Divider />
        </>
      )}

      <Menu.Item onClick={() => history.push('/home/meus-dados')}>
        {hasPermission([ROLE.MY_DATA], roles) && (
          <Button data-cy="btn-myData" type="text">
            <UserOutlined style={{ fontSize: '18px' }} />
            Meus dados
          </Button>
        )}
      </Menu.Item>
      <Menu.Item>
        <PartnerModal />
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item
        data-cy="btn-logout"
        style={{ display: 'flex', alignItems: 'center', padding: '9px 27px' }}
        onClick={handleLogout}
        danger
      >
        <LogoutOutlined style={{ fontSize: '18px', marginRight: '10px' }} />
        Sair
      </Menu.Item>
    </Menu>
  );

  const buttonLabel = (): string => {
    if (editId) return 'Continuar edição';
    else if (quantity > 0) return 'Continuar pedido';
    else return 'Novo pedido';
  };

  const toggleModalHelper = () => setModalHelperOpen(state => !state);

  useEffect(() => {
    dispatch(loadPhotoRequest());
  }, [dispatch]);
  return (
    <>
      <Container data-cy="navbar">
        <ButtonWithOutBorder
          icon={<MenuIcon layout={layout} />}
          onClick={() => toggleCollapsed()}
          size="large"
          type="link"
        />
        <Content>
          {hasPermission([ROLE.NEW_ORDER], roles) && (
            <>
              <NewOrderButton
                data-cy="btn-newOrder"
                onClick={handleButton}
                icon={<ShoppingIcon />}
                shape={(size?.width ?? 0) >= 640 ? undefined : 'circle'}
                type="primary"
                size="large"
              >
                {(size?.width ?? 0) >= 640 ? buttonLabel() : ''}
              </NewOrderButton>
              <Badge data-cy="btn-shoppingCart" count={formatNumberTwoDecimalPlaces(quantity)}>
                <ShoppingCartDrawer />
              </Badge>
            </>
          )}
          {layout?.showHelp && <ButtonWithOutBorder
            icon={<QuestionIcon layout={layout} />}
            size="large"
            type="link"
            onClick={toggleModalHelper}
          />
          }
          <Dropdown trigger={['click']} overlay={UserMenu}>
            <UserContainer data-cy="userDropdown">
              {!photo ? (
                <TextAvatar layout={layout}>{user?.name[0]}</TextAvatar>
              ) : (
                <Avatar src={photo} />
              )}
              {(size?.width ?? 0) >= 640 && (
                <UserContent>
                  <strong>{maskCamelCase(user?.name || '')}</strong>
                  <PartnerName>{maskCamelCase(user?.partner.name || '')}</PartnerName>
                </UserContent>
              )}
              {(size?.width ?? 0) >= 640 && <DownOutlined size={5} />}
            </UserContainer>
          </Dropdown>
        </Content>
      </Container>

      <Modal title="Ajuda" style={{ maxWidth: 800 }} width="90%" visible={modalHelperOpen} onCancel={toggleModalHelper} footer={[
        <Button key="submit" type="ghost" onClick={toggleModalHelper}>
          Fechar
        </Button>,
      ]} destroyOnClose>
        <Tabs defaultActiveKey="1" tabPosition={(size?.width ?? 0) <= 640 ? "top" : "left"}>
          <TabPane tab="Novo pedido" key="1">
            <VideoContainer>
              <iframe
                src="https://www.youtube.com/embed/MizNiAi4BLQ"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              >
              </iframe>
            </VideoContainer>
          </TabPane>

          <TabPane tab="Financeiro" key="2">
            <VideoContainer>
              <iframe
                src="https://www.youtube.com/embed/7DLmVDOqkKk"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              >
              </iframe>
            </VideoContainer>
          </TabPane>

          <TabPane tab="Ordens de serviços" key="3">
            <VideoContainer>
              <iframe
                src="https://www.youtube.com/embed/QVWsFLFjSkg"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              >
              </iframe>
            </VideoContainer>
          </TabPane>

          <TabPane tab="Campanhas" key="4">
            Em breve
          </TabPane>
        </Tabs>
      </Modal>
    </>
  );
};

export default Navbar;
