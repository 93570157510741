import { Carousel } from 'antd';
import React, {  useState } from 'react';
import { useSelector } from 'react-redux';

import { IBannerImage } from '..';
import { ApplicationState } from '../../../store';
import BannerImage from '../BannerImage';

import { BannersCarousel } from './styles';

interface Props {
  banners: IBannerImage[]
}

const Banners: React.FC<Props> = ({banners}) => {
  const layout = useSelector((state: ApplicationState) => state.layout.data);
  const [speed, setSpeed] = useState<number>(2000);

  const speedHandler = (current: number) => {
    setSpeed(banners[current] ? (banners[current].excTime || 10) * 1000 : 2000);
  };


  return (
    <BannersCarousel layout={layout}>
      <Carousel afterChange={speedHandler} autoplaySpeed={speed} autoplay effect="fade">
        {banners.map(banner => <BannerImage key={banner.bannerId} banner={banner} />)}
      </Carousel>
    </BannersCarousel>
  )
}

export default Banners;
