import { AxiosResponse } from 'axios';
import { all, call, put, select } from 'redux-saga/effects';
import { ApplicationState } from '../..';
import api from '../../../services/api';
import { requestHeaders } from '../../../utils/apiHelper';
import { Config, Data, dataToTableData } from '../../../utils/newTableHelper';
import { refreshRequest } from '../auth/actions';
import { setValues } from '../orderFields/actions';
import { loadRequest } from '../products/actions';
import {
  addItemRequest,
  addItemSuccess,
  addressFailure,
  addressSuccess,
  configsFailure,
  configsSuccess,
  deleteItemRequest,
  deleteItemSuccess,
  editOrderFailure,
  editOrderRequest,
  editOrderSuccess,
  paymentFailure,
  paymentSuccess,
  reDoOrderFailure,
  reDoOrderRequest,
  reDoOrderSuccess,
  saveFailure,
  saveSuccess,
  selectAddress,
  setFields,
  totalFailure,
  totalSuccess,
  addItemFailure,
  getCurrentCartSuccess,
  getCurrentCartFailure,
  deleteItemFailure,
  getCurrentCartRequest,
  cartInitialized,
  clearCartFinish,
  clearCart as clearCartAction,
  addItemsRequest,
  saveOrderAction
} from './actions';
import {
  Address,
  CalculateResult,
  KeyValue,
  Payment,
  ShoppingCartItem,
  Success,
  Unity,
} from './types';

interface Response {
  id: number;
  version: number;
}

const getVariation = (cartItem: ShoppingCartItem) => {
  if (cartItem.metreages) {
    return cartItem.metreages?.map((e) => {
      return {
        quantity: e.quantity,
        length: e.size,
      };
    });
  }
  if (cartItem.grid) {
    return cartItem.grid?.map((e) => {
      return {
        quantity: e.quantity,
        control: e.index,
      };
    });
  }
  if (cartItem.mattresses) {
    return cartItem.mattresses?.map((e) => {
      return {
        quantity: e.quantity,
        width: e.width,
        length: e.size,
        height: e.height,
      };
    });
  } else return undefined;
};

const itensHandle = (cart: ShoppingCartItem[]) => {
  return cart.map((item) => {
    return {
      id: item.codProd,
      unity: item.unity?.key,
      quantity: (item.quantity || 0) * (item.multiplier || 1),
      variations: getVariation(item),
    };
  });
};

const getUnity = (record: any, data?: any): Unity | undefined => {
  return record?.UNIDADE.find((item: any) => item.key === data?.unity);
};

const recordToCartItem = (record: any, isEdit?: boolean): ShoppingCartItem => {
  return {
    description: record?.DESCRPROD,
    codProd: record?.pkValue,
    quantity: record?.EDITAR_REFAZER.quantity,
    stock: Math.floor(
      record?.ESTOQUE.find((item: any) => item.key === record?.EDITAR_REFAZER.unity)?.value || 0
    ),
    unity: getUnity(record, record.EDITAR_REFAZER),
    value: record.VALOR.find((item: any) => item.key === record.EDITAR_REFAZER.unity)?.value || 0,
    hasStock: isEdit ? false : record?.ESTOQUE_SHOW,
    message: getUnity(record, record.EDITAR_REFAZER)?.message,
    multiplier: getUnity(record, record.EDITAR_REFAZER)?.multiple,
    isDecimal: !!getUnity(record, record.EDITAR_REFAZER)?.decimalPlaces,
    decimalQtd: getUnity(record, record.EDITAR_REFAZER)?.decimalPlaces,
    controlType: record?.DETAILS?.stockControlType
  };
};

const convertApiItemForItemCart = (record: any): ShoppingCartItem => {
  return {
    description: record.DESCRPROD,
    codProd: record.pkValue,
    quantity: record.DETALHE_CARRINHO[0]?.quantity || 0,
    stock: Math.floor(
      record?.ESTOQUE.find((item: any) => item.key === record.DETALHE_CARRINHO[0]?.unity)?.value || 0
    ),
    unity: getUnity(record, record.DETALHE_CARRINHO[0]),
    value: record.VALOR.find((item: any) => item.key === record.DETALHE_CARRINHO[0]?.unity)?.value || 0,
    hasStock: record.ESTOQUE_SHOW,
    message: record.DETALHE_CARRINHO[0]?.message,
    multiplier: record.DETALHE_CARRINHO[0]?.multiple,
    isDecimal: !!record.DETALHE_CARRINHO[0]?.decimalPlaces,
    decimalQtd: record.DETALHE_CARRINHO[0]?.decimalPlaces,
    controlType: record.DETAILS?.stockControlType
  };
};

const calculateSubTotal = (items: ShoppingCartItem[]) => {
  return items.reduce((total, item) => {
    if (item.grid) {
      return total + (item.grid && item.grid.reduce((totalGrid, e) => totalGrid + e.total, 0));
    }
    if (item.mattresses) {
      return (
        total +
        item.mattresses.reduce(
          (totalMattress, e) => totalMattress + e.volume * (item.value || 0),
          0
        )
      );
    } else {
      return total + (item.value || 0) * (item.quantity || 0) * (item.multiplier || 1);
    }
  }, 0)
}

const calculateTotalQuantity = (items: ShoppingCartItem[]) => {
  return items.reduce(
    (total, item) => total + (item.quantity || 0) * (item.multiplier || 1),
    0
  );
}

const getCalculatedQuantity = (newCart: ShoppingCartItem[], itemIndex: number, item: ShoppingCartItem) => {
  return itemIndex > -1 ? (item.quantity || 0) - (newCart[itemIndex].quantity || 0) : item.quantity
}

const getVariations = (item: ShoppingCartItem) => {
  if (item.metreages && item.metreages.length > 0) {
    return item.metreages.map(metreage => ({
      control: item.controlType,
      quantity: metreage.quantity,
      length: metreage.size
    }));
  }

  if (item.mattresses && item.mattresses.length > 0) {
    return item.mattresses.map(mattress => ({
      control: item.controlType,
      quantity: mattress.quantity,
      height: mattress.height,
      width: mattress.volume,
      length: mattress.size
    }));
  }

  if (item.grid && item.grid.length > 0) {
    return item.grid.map(gridItem => ({
      control: item.controlType,
      quantity: gridItem.quantity,
    }));
  }

  return [];
}

export function* initCart() {
  try {
    const state: ApplicationState = yield select();
    const headers = requestHeaders(state.auth.token);

    if (!state.shoppingCart.config?.details?.saveCart) {
      yield put(cartInitialized())
    } else {
      const { data }: AxiosResponse = yield call(api.get, '/shopping-cart/find', {
        headers: headers,
      });

      const responseConfig: AxiosResponse<Config> = yield call(
        api.get,
        '/shopping-cart/config',
        {
          headers: headers,
        }
      );

      const items = dataToTableData(data.items, responseConfig.data);
      const cartItems = items.map((record: any) => convertApiItemForItemCart(record));

      yield put(setValues(data.filters));
      yield put(loadRequest());

      yield put(getCurrentCartSuccess(cartItems, calculateSubTotal(cartItems), calculateTotalQuantity(cartItems), data.id, data.version));
    }
  } catch (err: any) {
    yield put(err.response.status !== 401 ? getCurrentCartFailure() : refreshRequest());
  }
}

function* updateCart(items: any[] = []) {
  const state: ApplicationState = yield select();
  const filters = state.orderFields.values;
  const headers = requestHeaders(state.auth.token);

  const response: AxiosResponse<Response> = yield call(api.post, '/shopping-cart/save', {
    filters,
    items
  }, {
    headers: headers,
  });

  return response;
}

function cartItemsForSave(items: ShoppingCartItem[]) {
  const formattedItems = items.map(item => ({
    idProd: item.codProd,
    quantity: item.quantity,
    decimalPlaces: item.decimalQtd,
    price: item.value,
    unity: item.unity?.key,
    controlType: item.controlType,
    multiple: item.multiplier,
  }));

  return formattedItems;
}

export function* addItem({ payload }: ReturnType<typeof addItemRequest>) {
  const state: ApplicationState = yield select();
  const filters = state.orderFields.values;
  const headers = requestHeaders(state.auth.token);

  const itemIndex = state.shoppingCart.cart.findIndex(
    ({ codProd }) => codProd === payload.item.codProd
  );
  const newCart = state.shoppingCart.cart;

  const currentVersion = state.shoppingCart.version || 0;
  const quantity = getCalculatedQuantity(newCart, itemIndex, payload.item);

  try {
    let price = payload.item.value;

    if (state.shoppingCart.config?.details?.priceVariation) {
      const { data }: AxiosResponse<{ price: number; }> = yield call(api.post, '/new-order/products/price-variation', {
        filters,
        id: payload.item.codProd,
        quantity: payload.item.quantity,
        eventType: "QUANTITY_CHANGE",
        unity: payload.item.unity?.key,
        control: payload.item.controlType,
        variations: getVariations(payload.item)
      }, {
        headers: headers,
      });

      price = data.price;
    }

    if (payload.isEdit || state.shoppingCart.editId || !state.shoppingCart.config?.details?.saveCart) {
      if (itemIndex === -1) {
        newCart.push({ ...payload.item, value: price });
      } else {
        newCart[itemIndex] = { ...payload.item, value: price };
      }

      yield put(addItemSuccess([...newCart], calculateSubTotal(newCart), calculateTotalQuantity(newCart), -1, -1));
    } else {
      try {
        const response: AxiosResponse<Response> = yield updateCart([{
          idProd: payload.item.codProd,
          quantity,
          decimalPlaces: payload.item.decimalQtd,
          price,
          unity: payload.item.unity?.key,
          controlType: payload.item.controlType,
          multiple: payload.item.multiplier,
        }]);

        if ((state.shoppingCart.id !== undefined && state.shoppingCart.id !== response.data.id) || currentVersion + 1 !== response.data.version) {
          yield put(getCurrentCartRequest(true));
        } else {
          if (itemIndex === -1) {
            newCart.push({ ...payload.item, value: price });
          } else {
            newCart[itemIndex] = { ...payload.item, value: price };
          }

          yield put(addItemSuccess([...newCart], calculateSubTotal(newCart), calculateTotalQuantity(newCart), response.data.id, response.data.version));
        }
      } catch {
        if (itemIndex === -1) {
          newCart.push({ ...payload.item, value: price });
        } else {
          newCart[itemIndex] = { ...payload.item, value: price };
        }

        yield put(addItemSuccess([...newCart], calculateSubTotal(newCart), calculateTotalQuantity(newCart), state.shoppingCart.id, state.shoppingCart.version));
      }
    }
  } catch (err: any) {
    yield put(err.response.status !== 401 ? addItemFailure(err.response.data.message) : refreshRequest());
  }
}

export function* addItems({ payload }: ReturnType<typeof addItemsRequest>) {
  const state: ApplicationState = yield select();
  const newCart = payload.items;

  try {
    if (state.shoppingCart.config?.details?.saveCart) {
      const response: AxiosResponse<Response> = yield updateCart(cartItemsForSave(newCart));
      yield put(addItemSuccess(newCart, calculateSubTotal(newCart), calculateTotalQuantity(newCart), response.data.id, response.data.version));
    } else {
      yield put(addItemSuccess(newCart, calculateSubTotal(newCart), calculateTotalQuantity(newCart), -1, -1));
    }
  } catch (err: any) {
    yield put(err.response.status !== 401 ? addItemFailure(err.response.data.message) : refreshRequest());
  }
}

export function* deleteItem({ payload }: ReturnType<typeof deleteItemRequest>) {
  try {
    const state: ApplicationState = yield select();
    const itemIndex = state.shoppingCart.cart.findIndex(
      ({ codProd }) => codProd === payload.item.codProd
    );
    const newCart = state.shoppingCart.cart;

    if (state.shoppingCart.editId || !state.shoppingCart.config?.details?.saveCart) {
      newCart.splice(itemIndex, 1);

      yield put(deleteItemSuccess([...newCart], calculateSubTotal(newCart), calculateTotalQuantity(newCart), -1, -1));
    } else {
      const response: AxiosResponse<Response> = yield updateCart([{
        idProd: payload.item.codProd,
        quantity: (newCart[itemIndex].quantity || 0) * -1,
        decimalPlaces: payload.item.decimalQtd,
        price: payload.item.value,
        unity: payload.item.unity?.key,
        controlType: payload.item.controlType,
        multiple: payload.item.multiplier,
      }]);

      newCart.splice(itemIndex, 1);

      yield put(deleteItemSuccess([...newCart], calculateSubTotal(newCart), calculateTotalQuantity(newCart), response.data.id, response.data.version));
    }
  } catch (err: any) {
    yield put(err.response.status !== 401 ? deleteItemFailure() : refreshRequest());
  }
}

export function* clearCart() {
  const state: ApplicationState = yield select();
  const headers = requestHeaders(state.auth.token);

  if (state.shoppingCart.editId || !state.shoppingCart.config?.details?.saveCart) {
    yield put(clearCartFinish());
  } else {
    try {
      yield call(api.post, '/shopping-cart/save', {
        items: []
      }, {
        headers: headers,
      });

      yield put(clearCartFinish())
    } catch (err: any) {
      yield put(err.response.status !== 401 ? clearCartFinish() : refreshRequest());
    }
  }
}

export function* loadPayments() {
  try {
    const state: ApplicationState = yield select();
    const headers = requestHeaders(state.auth.token);
    const response: AxiosResponse<Payment[]> = yield call(
      api.get,
      'new-order/payment/negotiation-types',
      {
        headers: headers,
      }
    );
    yield put(paymentSuccess(response.data));
    const fieldsArray = state.shoppingCart.fields;
    const index = fieldsArray.findIndex(({ key }) => key === 'payment');
    if (
      index === -1 &&
      (response.data.length === 1 || response.data.find((item: any) => item.defaultValue))
    ) {
      fieldsArray.push({
        key: 'payment',
        value:
          response.data.length === 1
            ? response.data[0].key
            : response.data.find((item: any) => item.defaultValue)?.key,
      });
      yield put(setFields(fieldsArray));
    }
  } catch (err: any) {
    yield put(err.response.status !== 401 ? paymentFailure() : refreshRequest());
  }
}

export function* loadAddresses() {
  try {
    const state: ApplicationState = yield select();
    const filters = state.orderFields.values;
    const headers = requestHeaders(state.auth.token);

    const response: AxiosResponse<Address[]> = yield call(api.post, '/new-order/address/all', { filters }, {
      headers: headers,
    });
    yield put(
      addressSuccess(
        response.data,
        response.data.length === 1 ? response.data[0] : state.shoppingCart.selectedAddress
      )
    );
  } catch (err: any) {
    yield put(err.response.status !== 401 ? addressFailure() : refreshRequest());
  }
}

export function* editOrder({ payload }: ReturnType<typeof editOrderRequest>) {
  try {
    const state: ApplicationState = yield select();
    const headers = requestHeaders(state.auth.token);
    const responseConfig: AxiosResponse<Config> = yield call(
      api.get,
      `/edit-order/${payload.nuNota}/template`,
      {
        headers: headers,
      }
    );

    const response: AxiosResponse<{
      items: Data[];
      filters: KeyValue[];
      address: Address;
      paymentMethod: KeyValue;
      note: string;
    }> = yield call(api.get, `edit-order/${payload.nuNota}`, {
      headers: headers,
    });

    const itens = dataToTableData(response.data.items, responseConfig.data);
    yield put(setValues(response.data.filters));
    yield all(itens.map((record: any) => put(addItemRequest(recordToCartItem(record, true), true))));
    yield put(selectAddress(response.data.address));
    const fieldsArray = state.shoppingCart.fields;
    const index = fieldsArray.findIndex(({ key }) => key === 'payment');
    if (index !== -1) {
      fieldsArray[index] = {
        key: 'payment',
        value: response.data.paymentMethod.key,
      };
    } else {
      fieldsArray.push({
        key: 'payment',
        value: response.data.paymentMethod.key,
      });
    }

    fieldsArray.push({
      key: 'observation',
      value: response.data.note,
    });

    yield put(setFields(fieldsArray));
    yield put(editOrderSuccess(payload.nuNota));
  } catch (err: any) {
    yield put(
      err.response.status !== 401 ? editOrderFailure(err.response.data.message) : refreshRequest()
    );
  }
}

export function* reDoOrder({ payload }: ReturnType<typeof reDoOrderRequest>) {
  try {
    const state: ApplicationState = yield select();
    const headers = requestHeaders(state.auth.token);
    const responseConfig: AxiosResponse<Config> = yield call(
      api.get,
      `/redo-order/${payload.nuNota}/template`,
      {
        headers: headers,
      }
    );
    const response: AxiosResponse<{
      items: Data[];
      filters: KeyValue[];
    }> = yield call(api.get, `redo-order/${payload.nuNota}`, {
      headers: headers,
    });

    const itens = dataToTableData(response.data.items, responseConfig.data);
    const cartItems = itens.map((record: any) => recordToCartItem(record));

    yield put(setValues(response.data.filters));
    yield put(addItemsRequest(cartItems));
    yield put(reDoOrderSuccess());
  } catch (err: any) {
    yield put(
      err.response.status !== 401 ? reDoOrderFailure(err.response.data.titulo) : refreshRequest()
    );
  }
}

export function* loadConfigs() {
  try {
    const state: ApplicationState = yield select();
    const headers = requestHeaders(state.auth.token);
    const response: AxiosResponse<Config> = yield call(api.get, 'new-order/template', {
      headers: headers,
    });
    yield put(configsSuccess(response.data));
  } catch (err: any) {
    yield put(err.response.status !== 401 ? configsFailure() : refreshRequest());
  }
}

export function* loadTotal() {
  try {
    const state: ApplicationState = yield select();
    const headers = requestHeaders(state.auth.token);
    const sendObject = {
      idDeliveryAddress: state.shoppingCart.selectedAddress?.deliveryAddressId,
      idPaymentMethod: state.shoppingCart.fields.find((item) => item.key === 'payment')?.value,
      filters: state.orderFields.values.map((item) => {
        return { key: item.key, value: item.value };
      }),
      items: itensHandle(state.shoppingCart.cart),
    };
    const response: AxiosResponse<CalculateResult> = !state.shoppingCart.budgetVisible
      ? yield call(
        api.post,
        state.shoppingCart.editId
          ? `/edit-order/${state.shoppingCart.editId}/calculation`
          : `new-order/calculation`,
        sendObject,
        { headers: headers }
      )
      : yield call(
        api.post,
        'new-budget/calculation',
        {
          ...sendObject,
          budgetFields: state.shoppingCart.fields.filter(
            (item) => item.key !== 'payment' && item.key !== 'observation'
          ),
        },
        { headers: headers }
      );
    yield put(totalSuccess(response.data));
  } catch (err: any) {
    yield put(
      err.response.status !== 401 ? totalFailure(err.response.data.titulo) : refreshRequest()
    );
  }
}

export function* saveOrder({ payload }: ReturnType<typeof saveOrderAction>) {
  try {
    const state: ApplicationState = yield select();
    const headers = requestHeaders(state.auth.token);
    const sendObject = {
      idDeliveryAddress: state.shoppingCart.selectedAddress?.deliveryAddressId,
      idPaymentMethod: state.shoppingCart.fields.find((item) => item.key === 'payment')?.value,
      filters: state.orderFields.values.map((item) => {
        return { key: item.key, value: item.value };
      }),
      note: state.shoppingCart.fields.find((item) => item.key === 'observation')?.value,
      items: itensHandle(state.shoppingCart.cart),
      budgetId: state.shoppingCart.budgetId,
      creditCard:{
        paymentToken: payload.tokenCard,
        installments: state.shoppingCart.fields.find((item) => item.key === 'creditCard.installments')?.value,
      }
    };
    const response: AxiosResponse<Success> = !state.shoppingCart.budgetVisible
      ? yield call(
        api.post,
        state.shoppingCart.editId
          ? `/edit-order/${state.shoppingCart.editId}/send`
          : `new-order/send/`,
        sendObject,
        { headers: headers }
      )
      : yield call(
        api.post,
        'new-budget/send',
        {
          ...sendObject,
          budgetFields: state.shoppingCart.fields.filter(
            (item) => item.key !== 'payment' && item.key !== 'observation'
          ),
        },
        { headers: headers }
      );

    yield put(saveSuccess(response.data));
    yield put(clearCartAction())
  } catch (err: any) {
    yield put(
      err.response.status !== 401 ? saveFailure(err.response.data.message) : refreshRequest()
    );
  }
}
