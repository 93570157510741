import { ResponseError } from '../../../services/api';
import { Config, Data } from '../../../utils/newTableHelper';

export enum HomeServiceOrdersTypes {
  'LOAD_REQUEST' = '@homeServiceOrders/LOAD_REQUEST',
  'LOAD_SUCCESS' = '@homeServiceOrders/LOAD_SUCCESS',
  'LOAD_FAILURE' = '@homeServiceOrders/LOAD_FAILURE',
}

export interface HomeServiceOrdersState {
  readonly data: Data[];
  readonly config?: Config;
  readonly loading: boolean;
  readonly error?: ResponseError;
}
