import React from 'react';
import {
  DollarCircleOutlined,
  ShoppingCartOutlined,
  ShoppingOutlined,
  FileDoneOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import { Container, Content, Title, Value } from './styles';
import { DataCard } from '../../store/ducks/homeCards/types';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../store';

const StatisticCard: React.FC<DataCard> = ({ icon, title, value }) => {
  const layout = useSelector((state: ApplicationState) => state.layout.data);
  const iconStyle = { color: layout?.color.default, fontSize: '32px' };
  const renderIcon = (icon: string) => {
    switch (icon) {
      case 'DollarCircleOutlined':
        return <DollarCircleOutlined style={iconStyle} />;
      case 'ShoppingCartOutlined':
        return <ShoppingCartOutlined style={iconStyle} />;
      case 'ShoppingOutlined':
        return <ShoppingOutlined style={iconStyle} />;
      case 'FileDoneOutlined':
        return <FileDoneOutlined style={iconStyle} />;
      default:
        return (
          <ExclamationCircleOutlined style={{ color: layout?.color.default, fontSize: '32px' }} />
        );
    }
  };
  return (
    <Container data-cy="statisticCard">
      <Content>
        <div>
          <Title>{title}</Title>
          <Value>{value}</Value>
        </div>
        {renderIcon(icon)}
      </Content>
    </Container>
  );
};

export default StatisticCard;
