import React from 'react';
import { Gauge, G2 } from '@ant-design/plots';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../store';
import { maskCamelCase } from '../../../utils/masks';
import EmptyChart from '../EmptyChart';
interface Props {
  title: string;
  alertColors?: { value: number; color: string }[];
  valueField?: string;
  minValue?: number;
  maxValue?: number;
  value?: number;
  onClick(values: any): void;
}
const GaugeChart: React.FC<Props> = ({
  title,
  alertColors,
  valueField,
  minValue,
  maxValue,
  value,
  onClick
}) => {
  const { registerShape, Util }: any = G2;

  const getTicks = () => {
    if (!alertColors) return [0, 100];
    return [0, ...alertColors.map((item) => item.value / 100)];
  };
  const getColor = () => {
    if (alertColors?.length === 1) return [layout?.color.default];
    const colors = ['#269A99', '#FE9D4C', '#E8694D'];
    const displayColors = alertColors?.map((_item, index) => colors[index % colors.length]);

    return displayColors ? displayColors.reverse() : [layout?.color.default];
  };

  const getPercent = (v: number = value || 0) => {
    return (v - (minValue || 0)) / ((maxValue || 1) - (minValue || 0));
  };

  registerShape('point', 'triangle-gauge-indicator', {
    draw(cfg: any, container: any) {
      const { indicator, defaultColor } = cfg.customInfo;
      const { pointer } = indicator;
      const group = container.addGroup();

      const center = this.parsePoint({
        x: 0,
        y: 0,
      });

      if (pointer) {
        const { startAngle, endAngle } = Util.getAngle(cfg, this.coordinate);
        const radius = this.coordinate.getRadius();
        const midAngle = (startAngle + endAngle) / 2;
        const { x: x1, y: y1 } = Util.polarToCartesian(
          center.x,
          center.y,
          radius * 0.52,
          midAngle + Math.PI / 30
        );
        const { x: x2, y: y2 } = Util.polarToCartesian(
          center.x,
          center.y,
          radius * 0.52,
          midAngle - Math.PI / 30
        );
        const { x, y } = Util.polarToCartesian(center.x, center.y, radius * 0.6, midAngle);
        const path = [['M', x1, y1], ['L', x, y], ['L', x2, y2], ['Z']];

        group.addShape('path', {
          name: 'pointer',
          attrs: {
            path,
            fill: defaultColor,
            ...pointer.style,
          },
        });
      }

      return group;
    },
  });
  const layout = useSelector((state: ApplicationState) => state.layout.data);

  const config: any = {
    percent: getPercent(),
    axis: {
      label: {
        formatter(v: any) {
          return `${Number(v) * ((maxValue || 0) - (minValue || 0)) + (minValue || 0)} \n (${(
            Number(v) * 100
          ).toFixed(0)}%)`;
        },
      },
      subTickLine: {
        count: 3,
      },
    },
    range: {
      ticks: getTicks(),
      color: getColor(),
    },
    indicator: {
      shape: 'triangle-gauge-indicator',
      pointer: {
        style: {
          fill: layout?.color.dark,
        },
      },
    },
    statistic: {
      title: {
        offsetY: -36,
        style: ({ percent }: any) => {
          const ticks = getTicks();
          const color = getColor();
          return {
            fontSize: '20px',
            lineHeight: '20px',
            fontWeight: 600,
            color: percent < ticks[1] ? color[0] : percent < ticks[2] ? color[1] : color[2],
          };
        },
        formatter: () => `${maskCamelCase(valueField || '')}: ${value}`,
      },
      content: {
        formatter: ({ percent }: any) => `${(percent * 100).toFixed(0)}%`,
        style: {
          fontSize: '20px',
          lineHeight: '20px',
        },
      },
    },
    onReady: (plot: any) => {
      plot.on('element:click', ({ data: { data } }: any) => {
        onClick(data);
      });
    }
  };
  return !value ? (
    <EmptyChart type={'gauge'} />
  ) : (
    <div style={{ padding: '20px' }}>
      <h2 style={{ color: layout?.color.dark }}>{title}</h2>
      <Gauge {...config} />
    </div>
  );
};

export default GaugeChart;
