import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: space-evenly;
  height: 300px;
  margin-right: 20%;
  @media only screen and (max-width: 1024px) {
    margin-right: 0px;
    align-items: center;
  }
`;
