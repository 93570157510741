import { action } from 'typesafe-actions';
import { UserTypes, User, Partner, Photo } from './types';

export const loadRequest = (token?: string) => action(UserTypes.LOAD_REQUEST, { token });
export const loadSuccess = (user?: User) => action(UserTypes.LOAD_SUCCESS, { user });
export const loadFailure = () => action(UserTypes.LOAD_FAILURE);

export const updateRequest = (
  name: string,
  phone?: string,
  email?: string,
  contributionMargin?: number
) => action(UserTypes.UPDATE_REQUEST, { name, email, phone, contributionMargin });
export const updateSuccess = (user?: User) => action(UserTypes.UPDATE_SUCCESS, { user });
export const updateFailure = () => action(UserTypes.UPDATE_FAILURE);

export const passwordRequest = (
  currentPassword: string,
  newPassword: string,
  confirmationPassword: string
) => action(UserTypes.PASSWORD_REQUEST, { confirmationPassword, newPassword, currentPassword });
export const passwordSuccess = () => action(UserTypes.PASSWORD_SUCCESS);
export const passwordFailure = () => action(UserTypes.PASSWORD_FAILURE);

export const loadPartnersRequest = () => action(UserTypes.LOAD_REQUEST_PARTNERS);
export const loadPartnersSuccess = (partners?: Partner[]) =>
  action(UserTypes.LOAD_SUCCESS_PARTNERS, { partners });
export const loadPartnersFailure = () => action(UserTypes.LOAD_FAILURE_PARTNERS);

export const loadPhotoRequest = () => action(UserTypes.LOAD_REQUEST_PHOTO);
export const loadPhotoSuccess = (image?: string) => action(UserTypes.LOAD_SUCCESS_PHOTO, { image });
export const loadPhotoFailure = () => action(UserTypes.LOAD_FAILURE_PHOTO);

export const updatePhotoRequest = (photo: Photo) =>
  action(UserTypes.UPDATE_REQUEST_PHOTO, { photo });
export const updatePhotoSuccess = (image?: string) =>
  action(UserTypes.UPDATE_SUCCESS_PHOTO, { image });
export const updatePhotoFailure = () => action(UserTypes.UPDATE_FAILURE_PHOTO);

export const deletePhotoRequest = () => action(UserTypes.DELETE_REQUEST_PHOTO);
export const deletePhotoSuccess = () => action(UserTypes.DELETE_SUCCESS_PHOTO);
export const deletePhotoFailure = () => action(UserTypes.DELETE_FAILURE_PHOTO);

export const cleanState = () => action(UserTypes.CLEAN_STATE);
