import { List } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import { useWindowSize } from '../../../customHooks';
import { ApplicationState } from '../../../store';
import { ShoppingCartItem } from '../../../store/ducks/shoppingCart/types';
import CartItem from '../../CartItem';
import GridItem from '../../GridItem';
import MattressItem from '../../MattressItem';
import MetreageItem from '../../MetreageItem';

const ItensList: React.FC = () => {
  const { cart: shoppingCart, currentAction } = useSelector((state: ApplicationState) => state.shoppingCart);
  const size = useWindowSize();

  const renderItem = (item: ShoppingCartItem) => {
    if (item.grid) return <GridItem {...item} />;
    if (item.metreages) return <MetreageItem {...item} />;
    if (item.mattresses) return <MattressItem {...item} />;
    return <CartItem {...item} />;
  };

  return (
    <List
      style={{ padding: '20px' }}
      itemLayout={(size.width || 0) > 550 ? 'horizontal' : 'vertical'}
      dataSource={shoppingCart}
      renderItem={(item) => renderItem(item)}
      loading={currentAction === 'INIT'}
    />
  );
};

export default ItensList;
