import React, { useState } from 'react';
import { Badge, Popover } from 'antd';
import {  useSelector } from 'react-redux';
import { DefaultButton } from '../../../styles';
import { CaretUpOutlined, CaretDownOutlined, FilterOutlined } from '@ant-design/icons';
import { Arrows, ExtraButtonsContainer } from '../styles';
import { ApplicationState } from '../../../store';
import Sorter from '../Sorter/intex';
import Filters from '../Filters';

const ExtraButtons: React.FC = () => {
  const [filtersVisible, setFiltersVisible] = useState<boolean>(false);

  const layout = useSelector((state: ApplicationState) => state.layout.data);
  const config = useSelector((state: ApplicationState) => state.products.config);
  const advancedFields = useSelector((state: ApplicationState) => state.products.advancedFields);
  const sorter = useSelector((state: ApplicationState) => state.products.sorter);
  const filter = useSelector((state: ApplicationState) => state.products?.filter);
  const advancedFilters = useSelector((state: ApplicationState) => state.products.advancedFilters);

  return (
    <ExtraButtonsContainer>
      {(config?.fields?.filter((item) => item.showSorting).length || 0) > 0 && (
        <Popover
          data-cy="popover-sorter"
          title="Ordenar"
          trigger="click"
          placement="bottomLeft"
          content={<Sorter />}
        >
          <Badge dot={!!sorter}>
            <DefaultButton data-cy="btn-sorter" style={{ margin: 0 }} layout={layout}>
              <Arrows>
                <CaretUpOutlined style={{ fontSize: '10px' }} />
                <CaretDownOutlined style={{ fontSize: '10px' }} />
              </Arrows>
            </DefaultButton>
          </Badge>
        </Popover>
      )}
      {((config?.fields?.filter((item) => item.showFilter)?.length || 0) > 0 ||
        (advancedFields && advancedFields?.length > 0) ||
        (config?.filters && config?.filters.length > 0)) && (
        <Popover
          data-cy="popover-filters"
          title="Filtros"
          trigger="click"
          placement="bottomLeft"
          content={
            <Filters filtersVisible={filtersVisible} setFiltersVisible={setFiltersVisible} />
          }
          visible={filtersVisible}
          onVisibleChange={(visible) => setFiltersVisible(visible)}
        >
          <Badge
            dot={
              filter?.filter((item) => item?.name !== 'categoryId')?.length > 0 || (advancedFilters && advancedFilters.length > 0)
            }
          >
            <DefaultButton
              data-cy="btn-filters"
              style={{ marginLeft: '5px', marginTop: 0, marginBottom: 0 }}
              layout={layout}
            >
              <FilterOutlined />
            </DefaultButton>
          </Badge>
        </Popover>
      )}
    </ExtraButtonsContainer>
  );
};

export default ExtraButtons;
