import { AxiosResponse } from 'axios';
import { call, put, select } from 'redux-saga/effects';
import { ApplicationState } from '../..';
import api, { errorHandler } from '../../../services/api';
import { requestHeaders } from '../../../utils/apiHelper';
import { dataToTableData, Data, Config } from '../../../utils/newTableHelper';
import { refreshRequest } from '../auth/actions';
import {
  advancedFieldsFailure,
  advancedFieldsSuccess,
  categoriesFailure,
  CategoriesSuccess,
  configSuccess,
  favoriteFailure,
  favoriteRequest,
  favoriteSuccess,
  loadFailure,
  loadRequest,
  loadSuccess,
  setUnity,
  unitySuccess,
} from './actions';
import { Category } from './types';

export function* loadProducts({ payload }: ReturnType<typeof loadRequest>) {
  const filters = payload.filter || payload.advancedFilters ? [...payload.filter, { name: "advancedFilterId", value: payload.advancedFilters || [] }] : undefined;

  try {
    const state: ApplicationState = yield select();
    const headers = requestHeaders(state.auth.token);
    let config: AxiosResponse<Config> = yield call(api.get, '/new-order/products/template', {
      headers: headers,
    });

    //Regra criada para que ajuste para exibição da quantidade de embalagem no mobile não reflita no web
    const indexQTDEMB = config.data.fields.findIndex(field => field.name === 'QTDEMB');
    if (indexQTDEMB !== -1) {
      config.data.fields[indexQTDEMB].fixedName = 'QTDEMB'
    }

    yield put(configSuccess(config.data));
    const response: AxiosResponse<{
      content: Data[];
      numberOfElements: number;
      size: number;
      number: number;
    }> = yield call(
      api.post,
      `/new-order/products/all`,
      {
        page: (payload.pagination?.current || 1) - 1,
        size: payload.pagination?.pageSize || 12,
        filters,
        sort: payload.sorter || state.products.sorter,
        search: payload.search || state.products.search,
        params:
          state.orderFields.values.length === 0
            ? undefined
            : state.orderFields.values.map((item) => {
              return { name: item.key, value: item.value };
            }),
        idConfiguration: 0,
      },
      { headers: headers }
    );
    const tableData: any[] = yield call(dataToTableData, response.data.content, config.data);
    const filteredData = tableData.filter((item: any) => item?.UNIDADE.length > 0);
    const data = filteredData.map((item: any) => {
      return {
        ...item,
        SELECTED_UNITY: item?.UNIDADE[0].key,
      };
    });
    yield put(
      loadSuccess(
        data,
        config.data,
        {
          total: response.data.numberOfElements,
          pageSize: response.data.size,
          current: payload.pagination ? payload.pagination.current : response.data.number + 1,
        },
        payload.filter || [],
        state.products.sorter,
        state.products.search,
        payload.advancedFilters || []
      )
    );
  } catch (err: any) {
    yield put(err?.response?.status !== 401 ? loadFailure(errorHandler(err)) : refreshRequest());
  }
}

export function* setFavorite({ payload }: ReturnType<typeof favoriteRequest>) {
  try {
    const state: ApplicationState = yield select();
    const headers = requestHeaders(state.auth.token);
    yield call(
      api.get,
      `/new-order/products/${payload.codProd}/favorite/${!payload.isFavorite ? 'remove' : 'add'}`,
      { headers: headers }
    );

    const newData = state.products.data;
    const index = newData.findIndex((item) => item?.CODPROD === Number(payload.codProd));
    newData[index].FAVORITO = !newData[index]?.FAVORITO;
    yield put(favoriteSuccess([...newData]));
  } catch (err: any) {
    yield put(err.response.status !== 401 ? favoriteFailure(errorHandler(err)) : refreshRequest());
  }
}

export function* loadCategories(): any {
  try {
    const state: ApplicationState = yield select();
    const headers = requestHeaders(state.auth.token);
    const response: AxiosResponse<Category[]> = yield call(
      api.get,
      '/new-order/products/categories',
      {
        headers: headers,
      }
    );
    yield put(CategoriesSuccess(response.data));
  } catch (err: any) {
    yield put(
      err.response.status !== 401 ? categoriesFailure(errorHandler(err)) : refreshRequest()
    );
  }
}

export function* changeUnity({ payload }: ReturnType<typeof setUnity>) {
  const state: ApplicationState = yield select();
  const newData = state.products.data;
  const index = newData.findIndex((item) => item?.CODPROD.toString() === payload.codProd);
  newData[index].SELECTED_UNITY = payload.unity;
  yield put(unitySuccess([...newData]));
}


export function* loadAdvancedFields(): any {
  try {
    const state: ApplicationState = yield select();
    const headers = requestHeaders(state.auth.token);
    const response: AxiosResponse<Category[]> = yield call(
      api.get,
      '/new-order/products/filters',
      {
        headers: headers,
      }
    );

    yield put(advancedFieldsSuccess(response.data));
  } catch (err: any) {
    yield put(
      err.response.status !== 401 ? advancedFieldsFailure(errorHandler(err)) : refreshRequest()
    );
  }
}
