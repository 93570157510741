import { ResponseError } from "../../../services/api";

export enum ReportsTypes {
  'REPORTS_REQUEST' = '@reports/REPORTS_REQUEST',
  'REPORTS_SUCCESS' = '@reports/REPORTS_SUCCESS',
  'REPORTS_FAILURE' = '@reports/REPORTS_FAILURE',
}
export interface ReportsState {
  readonly dataReports: any[];
  readonly loadingReports: boolean;
  readonly error?: ResponseError;
}
