import React, { Dispatch, useCallback, useEffect, useState } from 'react';
import { Button, Dropdown, Menu, Modal } from 'antd';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Action } from 'redux';
import { ColumnsType } from 'antd/es/table';

import {
  BarcodeOutlined,
  FilePdfOutlined,
  FileExcelOutlined,
  EllipsisOutlined,
  CopyOutlined,
} from '@ant-design/icons';

import { ApplicationState } from '../../../store';
import { loadFinancialRequest } from '../../../store/ducks/financial/actions';
import CustomTable from '../../../components/CustomTable';
import PdfDisplay from '../../../components/PdfDisplay';
import XmlDisplay from '../../../components/XmlDisplay';
import { baseURL } from '../../../services/api';
import FinancialAttachmentFile from '../FinancialAttachmentFile';
import ServerError from '../../../components/ServerError';
import Status from '../../../components/Status';
import ReportsDropdown from '../../../components/ReportsDropdown';
import { configToColumnsType, dataToTableData } from '../../../utils/newTableHelper';
import CodebillContent from '../CodebillContent';

interface Props {
  search: string;
}

const FinancialTable: React.FC<Props> = ({ search }) => {
  const layout = useSelector((state: ApplicationState) => state.layout.data);
  const config = useSelector((state: ApplicationState) => state.financial.config, shallowEqual);
  const data = useSelector((state: ApplicationState) => state.financial.data, shallowEqual);
  const error = useSelector((state: ApplicationState) => state.financial.error);
  const loading = useSelector((state: ApplicationState) => state.financial.loading);
  const pagination = useSelector((state: ApplicationState) => state.financial.pagination);
  const [columns, setColumns] = useState<ColumnsType<any>>([]);
  const [visible, setVisible] = useState<boolean>(false);
  const [title, setTitle] = useState<string>('');
  const [url, setUrl] = useState<string>('');

  const dispatch = useDispatch<Dispatch<Action>>();

  const showPdfDisplay = (title: string, url: string) => {
    if (url === '' || url === undefined) return;
    setTitle(title);
    setUrl(url);
    setVisible(true);
  };
  const info = useCallback(
    (value: string) => {
      Modal.info({
        title: 'Linha digitável:',
        content: <CodebillContent value={value} layout={layout} />,
        centered: true,
        closable: true,
        maskClosable: true,
        okButtonProps: { style: { display: 'none' } },
        icon: <></>,
      });
    },
    [layout]
  );

  useEffect(() => {
    setColumns(
      configToColumnsType(
        config?.fields.filter((item) => item.show) || [],
        [
          {
            id: -5,
            render: (value: number) => <Status value={value} config={config?.fields || []} />,
          },
          {
            id: -1,
            render: (value: any, record: any) => (
              <ReportsDropdown value={value} pdfUrl={`financial/${record.pkValue}/report`} />
            ),
          },
        ],
        [
          {
            title: 'Ações',
            key: 'action',
            render: (value: any) => {
              return (
                <Dropdown
                  trigger={['click']}
                  overlay={
                    <Menu>
                      <Menu.Item>
                        <Button
                          data-cy="btn-printBillet"
                          type="text"
                          disabled={!value.BOLETO}
                          icon={<BarcodeOutlined />}
                          onClick={() =>
                            showPdfDisplay('Imprimir Boleto', `financial/${value.pkValue}/bill`)
                          }
                        >
                          Imprimir Boleto
                        </Button>
                      </Menu.Item>
                      <Menu.Item>
                        <Button
                          data-cy="btn-copyBillet"
                          type="text"
                          disabled={!value.LINHADIGITAVEL || !value.BOLETO}
                          icon={<CopyOutlined />}
                          onClick={() => info(value.LINHADIGITAVEL)}
                        >
                          Copiar código de barras
                        </Button>
                      </Menu.Item>
                      <Menu.Item>
                        <Button
                          data-cy="btn-downloadNfe"
                          disabled={!value.NOTAPDF}
                          icon={<FilePdfOutlined />}
                          type="text"
                          onClick={() =>
                            showPdfDisplay('Download NF-e', `financial/${value.pkValue}/invoice`)
                          }
                        >
                          Download NF-e
                        </Button>
                      </Menu.Item>
                      <Menu.Item>
                        <XmlDisplay
                          url={`${baseURL}/financial/${value.pkValue}/xml`}
                          content="Download XML"
                          title="Download XML"
                          disabled={!value.NOTAXML}
                          icon={<FileExcelOutlined />}
                        />
                      </Menu.Item>
                      {config?.details?.sendProof && (
                        <Menu.Item>
                          <FinancialAttachmentFile
                            nuFin={value.pkValue}
                            url={`${baseURL}/financial/upload/proof`}
                            sendReceipt={config?.details?.sendProof}
                          />
                        </Menu.Item>
                      )}
                    </Menu>
                  }
                >
                  <Button
                    data-cy="btn-more"
                    type="link"
                    style={{ padding: 0 }}
                    onClick={(e) => e.preventDefault()}
                  >
                    <EllipsisOutlined style={{ fontSize: '24px' }} rotate={90} />
                  </Button>
                </Dropdown>
              );
            },
          },
        ],
        [0, -5, -1]
      )
    );
  }, [layout, config, pagination, info]);
  return (
    <>
      {error ? (
        <ServerError {...error} />
      ) : (
        <CustomTable
          style={{ padding: '20px' }}
          onChange={(pagination: any, filters?: any, sorter?: any) => {
            dispatch(loadFinancialRequest(pagination, filters, sorter, search));
          }}
          pagination={pagination}
          loading={loading}
          columns={columns}
          data={dataToTableData(data, config)}
        />
      )}
      <PdfDisplay title={title} url={url} visible={visible} setVisible={setVisible} />
    </>
  );
};

export default FinancialTable;
