import { ResponseError } from '../../../services/api';
import { Config, Data } from '../../../utils/newTableHelper';

export enum MyOrdersTypes {
  'LOAD_REQUEST' = '@myOrders/LOAD_REQUEST',
  'LOAD_SUCCESS' = '@myOrders/LOAD_SUCCESS',
  'LOAD_FAILURE' = '@myOrders/LOAD_FAILURE',

  'SET_TAB' = '@myOrders/SET_TAB',
}

export interface Pagination {
  current: number;
  total: number;
  pageSize: number;
}

export interface MyOrdersState {
  readonly data: Data[];
  readonly config?: Config;
  readonly pagination?: Pagination;
  readonly loading: boolean;
  readonly error?: ResponseError;
  readonly tab: string;
}
