import React, { useCallback, useEffect, useState } from 'react'
import { Button, Col, Form, Row, Spin } from 'antd';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Dispatch } from 'redux';
import Swal from 'sweetalert2';
import { Action } from 'typesafe-actions';
import { CheckOutlined, LoadingOutlined, CloseOutlined } from '@ant-design/icons';

import api, { baseURL } from '../../services/api';
import { ApplicationState } from '../../store';
import { cleanFormState, sendFormRequest } from '../../store/ducks/screenForm/actions';
import { ScreenForm as IScreenForm } from '../../store/ducks/screenForm/types';
import { Fields, SendObject } from '../../store/ducks/screenForm/types';
import { colors } from '../../utils/constants';
import { unmask } from '../../utils/masks';
import DynamicFields, { NewTypes } from '../DynamicFields';
import ServerError from '../ServerError';
import { ScreenFormProps } from './props';
import { requestHeaders } from '../../utils/apiHelper';
import { refreshRequest } from '../../store/ducks/auth/actions';

const ScreenForm: React.FC<ScreenFormProps> = ({ screenId: id, initialValues, appearance = 'home', onCancel, hiddenFields }) => {
  const layout = useSelector((state: ApplicationState) => state.layout.data);

  const [groups, setGroups] = useState<IScreenForm[]>([]);
  const [loading, setLoading] = useState(true);

  const success = useSelector((state: ApplicationState) => state.screenForm.success);
  const token = useSelector((state: ApplicationState) => state.auth.token);
  const sendError = useSelector((state: ApplicationState) => state.screenForm.sendError);
  const error = useSelector((state: ApplicationState) => state.screenForm.error);
  const sendLoading = useSelector((state: ApplicationState) => state.screenForm.sendLoading);
  const menus = useSelector((state: ApplicationState) => state.additionalScreens.screens);

  const dispatch = useDispatch<Dispatch<Action>>();
  const history = useHistory();

  const getUrl = (type: NewTypes, id: string, idField: number) => {
    switch (type) {
      case 'OPTIONS':
        return `/screen/${id}/form/fields/${idField}/options`;
      case 'BLOB':
      case 'BLOB_IMAGE':
        return `${baseURL}/screen/${id}/form/fields/${idField}/upload`;

      default:
        return undefined;
    }
  };

  const sorter = (fields: Fields[]) => {
    return fields.sort((a, b) => {
      if (a.position < b.position) {
        return -1;
      }
      if (a.position > b.position) {
        return 1;
      }
      return 0;
    });
  };

  const onFinish = (formValues: any) => {
    const allValues = { ...initialValues, ...formValues };

    const values: SendObject[] = [];
    const fields: Fields[] = [];
    groups.forEach((group) => fields.push(...group.fields));

    Object.keys(allValues).forEach((item) => {
      const field = fields.find((field) => field.fieldName === item);
      let value = allValues[item];
      if (value?.file) {
        value = value.file.response.attachment;
      }
      if (value instanceof moment) {
        value = moment(value).format('DD/MM/YYYY HH:mm:ss');
      }
      if (field?.mask !== 'currency' && field?.mask) {
        value = unmask(value);
      }
      if (!value) return;
      values.push({
        id: field?.idField,
        name: item,
        value: value,
      });
    });

    dispatch(sendFormRequest(parseInt(id), [...values]));
  };

  const getData = useCallback(
    async () => {
      setLoading(true);
      const headers = requestHeaders(token);

      try {
        const { data = [] } = await api.get(`/screen/${id}/form/fields`,
          {
            headers: headers,
          });

        setGroups(data)
      } catch (err: any) {
        if (err?.response?.status === 401)
          dispatch(refreshRequest())
      } finally {
        setLoading(false)
      }
    },
    [id, token, dispatch],
  );

  useEffect(() => {
    getData();
  }, [getData])

  useEffect(() => {
    if (!success) return;

    Swal.fire({
      title: 'Sucesso!',
      confirmButtonColor: layout?.color.default,
      text: `${success.message}`,
      icon: 'success',
      confirmButtonText: 'Ok',
    }).then(() => {
      if (appearance === 'home')
        history.goBack();
      else
        onCancel && onCancel();
      dispatch(cleanFormState());
    });
  }, [dispatch, history, layout?.color.default, success, appearance, onCancel]);

  useEffect(() => {
    if (!sendError) return;

    Swal.fire({
      title: 'Erro!',
      confirmButtonColor: layout?.color.default,
      text: `${sendError.message}`,
      icon: 'error',
      confirmButtonText: 'Ok',
    }).then(() => {
      dispatch(cleanFormState());
    });
  }, [dispatch, history, layout?.color.default, sendError]);

  useEffect(() => {
    if (appearance === 'home' && !menus?.some((item) => item.id.toString() === id))
      history.push('/')
  }, [menus, id, history, appearance]);

  return (
    <Spin
      style={{ color: layout?.color.default }}
      spinning={loading}
      size="large"
      delay={500}
      indicator={<LoadingOutlined />}
    >
      {error ? (
        <ServerError {...error} />
      ) : (
        <Form onFinish={onFinish} preserve={false} layout="vertical" initialValues={initialValues}>
          {groups.map((group) => {
            return (
              <Col
                style={appearance === 'home' ? {
                  backgroundColor: 'white',
                  padding: '20px',
                  marginBottom: '20px',
                  borderRadius: '5px',
                  boxShadow: `1px 2px 2px 2px ${colors.grey}`,
                } : {}}
                span={24}
              >
                <h2 style={{ color: layout?.color.dark }}>{group.title}</h2>
                <Row gutter={[20, 20]} justify="start">
                  {sorter(group.fields).filter(field => field.visible && (!hiddenFields || hiddenFields?.includes(field.fieldName) === false)).map((field) => {
                    return (
                      <Col
                        xs={24}
                        sm={24}
                        md={field.columnSize * 6}
                        lg={field.columnSize * 6}
                        xl={field.columnSize * 6}
                      >
                        <DynamicFields
                          type={field.mask || field.fieldType}
                          label={field.description}
                          required={field.required}
                          disabled={!field.enable}
                          name={field.fieldName}
                          key={field.idField.toString()}
                          value={field.defaultValue}
                          urlUnique={getUrl(field.fieldType, id, field.idField)}
                        />
                      </Col>
                    );
                  })}
                </Row>
              </Col>
            );
          })}
          <Row justify="end" gutter={10}>
            {appearance === 'child' && <Col xs={24} sm={24} md={3} lg={3} xl={3}>
              <Form.Item style={{ margin: 0 }}>
                <Button
                  disabled={sendLoading}
                  block
                  icon={<CloseOutlined />}
                  htmlType="button"
                  type="ghost"
                  onClick={onCancel}
                >
                  Cancelar
                </Button>
              </Form.Item>
            </Col>}
            <Col xs={24} sm={24} md={3} lg={3} xl={3}>
              <Form.Item style={{ margin: 0 }}>
                <Button
                  loading={sendLoading}
                  block
                  icon={<CheckOutlined />}
                  htmlType="submit"
                  type="primary"
                >
                  Salvar
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      )}
    </Spin>
  );
}

export default ScreenForm;
