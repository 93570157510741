import React, { Dispatch } from 'react';
import CustomCollapse from '../../../components/CustomCollapse';
import { FormOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../../store';
import DynamicFields from '../../../components/DynamicFields';
import { Button, Col, Form, Row } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import { Action } from 'typesafe-actions';
import { dashboardRequest } from '../../../store/ducks/dashboard/actions';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { DashboardConfig } from '../../../store/ducks/dashboard/types';

interface Props {
  config: DashboardConfig;
}

const Parameters: React.FC<Props> = ({ config }) => {
  const dispatch = useDispatch<Dispatch<Action>>();
  const layout = useSelector((state: ApplicationState) => state.layout.data);
  const fields = config.gadget.promptParameters;
  const { id }: { id: string } = useParams();

  const getFieldType = (type: string) => {
    switch (type) {
      case 'datePeriod':
        return 'R';
      case 'date':
        return 'DATE';
      case 'dateTime':
        return 'DATE_TIME';
      case 'text':
        return 'STRING';
      case 'integer':
        return 'INTEGER';
      case 'decimal':
        return 'FLOAT';
      case 'boolean':
        return 'B';
      case 'multiList':
        return 'multiSelect';
      case 'singleList':
      case 'entity':
        return 'OPTIONS';
      default:
        return 'STRING';
    }
  };

  const onFinish = (formValues: any) => {
    const values: any[] = [];
    Object.keys(formValues).forEach((item) => {
      let value = formValues[item];
      const arrayValue: any[] = [];

      if (value instanceof Array) {
        if (value[0] instanceof moment) {
          values.push({
            key: `${item}.INI`,
            value: moment(value[0]).format('DD/MM/YYYY'),
          });

          values.push({
            key: `${item}.FIN`,
            value: moment(value[1]).format('DD/MM/YYYY'),
          });

          return;
        } else {
          value.forEach((item) => arrayValue.push(item));
        }
      }
      const fieldMultilist = fields?.find((f) => f.metadata === 'multiList' && f.id === item);

      if ((!value || value.length === 0) && fieldMultilist)
        value = fieldMultilist.items.map((item) => item.key);

      if (!value) return;

      values.push({
        key: item,
        value:
          arrayValue.length > 0
            ? arrayValue
            : value instanceof moment
            ? moment(value).format('DD/MM/YYYY')
            : value,
      });
    });

    dispatch(
      dashboardRequest(
        { promptParameters: values },
        config?.gadget.levels[0].id || '',
        parseInt(id)
      )
    );
  };

  return (
    <CustomCollapse
      icon={<FormOutlined style={{ color: layout?.color.default }} />}
      title="Parâmetros"
      defaultOpened
      hasNotification={fields?.some((item) => item.required)}
      dot={fields?.some((item) => item.required)}
    >
      <Form onFinish={onFinish} layout="vertical">
        <Row gutter={[20, 20]}>
          {fields?.map((item) => {
            return (
              <Col xs={24} sm={24} md={8} lg={6} xl={6}>
                <DynamicFields
                  type={getFieldType(item.metadata)}
                  label={item.description}
                  name={item.id}
                  required={item.required}
                  urlUnique={
                    item.metadata === 'entity'
                      ? `dashboard/${id}/entity-param/${item.id}?entity=${item.entity}&field=${item.field}`
                      : undefined
                  }
                  list={
                    item.metadata === 'singleList' || item.metadata === 'multiList'
                      ? item.items
                      : undefined
                  }
                />
              </Col>
            );
          })}
        </Row>
        <Row justify="end">
          <Col xs={24} sm={24} md={3} lg={3} xl={3}>
            <Form.Item style={{ margin: 0 }}>
              <Button
                loading={false}
                block
                icon={<CheckOutlined />}
                htmlType="submit"
                type="primary"
              >
                Aplicar
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </CustomCollapse>
  );
};

export default Parameters;
