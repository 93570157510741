import styled from 'styled-components';
import { ShoppingCartOutlined, ShoppingOutlined } from '@ant-design/icons';
import { Button, Divider, Input, Select } from 'antd';
import { Layout } from '../../store/ducks/layout/types';
import { colors } from '../../utils/constants';

const { TextArea } = Input;
export const ShoppingCartIcon = styled(ShoppingCartOutlined)<{ layout?: Layout }>`
  font-size: 25px;
  justify-self: flex-start;
  color: ${({ layout }) => layout?.color.default};
`;

export const ShoppingIcon = styled(ShoppingOutlined)`
  font-size: 20px;
`;

export const EmptyContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80vh;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 70%;
`;

export const ClearButtonContent = styled.div<{ budget?: boolean }>`
  display: flex;
  justify-content: ${({ budget }) => (budget ? 'space-between' : 'flex-end')};
  margin-right: 10px;
`;

export const CustomDivider = styled(Divider)`
  margin: 5px 0 5px;
`;

export const ButtonWithOutBorder = styled(Button)<{ layout?: Layout }>`
  border: 0px solid transparent !important;
  :hover {
    border: 0px solid transparent !important;
  }
  :active {
    border: 0px solid transparent !important;
  }
  :focus {
    border: 0px solid transparent !important;
  }
`;

export const TotalContent = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const DeliveryContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const CartHeader = styled.div<{ layout?: Layout }>`
  z-index: 999;
  .ant-switch-checked {
    background-color: ${({ layout }) => layout?.color.default};
  }
  background-color: ${colors.grayBackgroud};
  padding: 20px;
  position: fixed;
  width: 550px;
  @media only screen and (max-width: 550px) {
    width: 100vw;
  }
`;

export const CartFooter = styled.div<{ opened?: boolean }>`
  position: fixed;
  width: 550px;
  z-index: 999;
  right: 0;
  bottom: 0;
  display: ${({ opened }) => (opened ? 'block' : 'none')};
  background-color: ${colors.grayBackgroud};
  padding: 20px;
  @media only screen and (max-width: 550px) {
    width: 100vw;
  }
`;
export const CartContent = styled.div`
  margin-right: 10px;
  border: 0.5px solid ${colors.grayBackgroud};
`;

export const Buttons = styled.div`
  margin-right: 10px;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
`;

export const ClearButton = styled(Button)`
  background-color: transparent;
  color: red;
  border: 0 solid transparent;
  :hover {
    background-color: transparent;
    color: red !important;
    border: 0 solid transparent;
  }
  :active {
    background-color: transparent;
    color: red !important;
    border: 0 solid transparent;
  }
  :focus {
    background-color: transparent;
    color: red !important;
    border: 0 solid transparent;
  }
`;

export const CustomButton = styled(Button)`
  margin: 5px 0 5px;
  width: 100%;
`;
export const CancelButton = styled(Button)`
  margin: 5px 10px 5px 0px;
  width: 100%;
`;
export const DefaultButton = styled(Button)`
  margin: 5px 0 5px;
`;

export const WarnigMessage = styled.div<{ danger: boolean }>`
  color: ${({ danger }) => (danger ? colors.danger : colors.success)};
`;

export const TextButton = styled(Button)`
  padding: 0px;
  width: fit-content;
`;

export const InputTextArea = styled(TextArea)`
  margin: 5px 0 5px;
`;

export const DefaultSelect = styled(Select)`
  margin: 5px 0 5px;
`;

export const Label = styled.h4`
  margin: 0;
`;
