import React from 'react';
import CustomTag from '../CustomTag';
export interface Props {
  value: number;
  config: any[];
}
const Status: React.FC<Props> = ({ config, value }) => {
  const dataConfig = config?.find(
    ({ fixedName, nomeCampo }) => fixedName === 'STATUS' || nomeCampo === 'STATUS'
  );
  const tag =
    dataConfig?.options?.find(({ key }: any) => key === value) ||
    dataConfig?.opcoes?.find(({ key }: any) => key === value);
  return (
    <CustomTag
      title={tag?.value}
      color={/^[0-9A-F]{6}$/i.test(tag?.cor || '') ? `#${tag?.cor}` : undefined}
    />
  );
};

export default Status;
