import { Checkbox, Col, Collapse, Row } from 'antd';
import React, { Dispatch, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Action } from 'redux';
import { ApplicationState } from '../../store';
import { setFilter } from '../../store/ducks/showCase/actions';
import { Category } from '../../store/ducks/showCase/types';
import { maskCamelCase } from '../../utils/masks';

// import { Container } from './styles';
const { Panel } = Collapse;
const CheckboxGroup = Checkbox.Group;

interface Props {
  category: Category;
}

const CheckCollapse: React.FC<Props> = ({ category }) => {
  const [indeterminate, setIndeterminate] = useState<boolean>(false);
  const completeList = useSelector((state: ApplicationState) => state.productsShowCase.filters);
  const checkedList = useSelector(
    (state: ApplicationState) => state.productsShowCase.filters
  ).filter((item: any) =>
    category.list.length > 0
      ? category.list.map((listItem) => listItem.key).includes(item)
      : category.key === item
  );

  const [checkAll, setCheckAll] = useState<boolean>(false);

  const dispatch = useDispatch<Dispatch<Action>>();

  useEffect(() => {
    setCheckAll(checkedList.length === category.list.length);
    setIndeterminate(!!checkedList.length && checkedList.length < category.list.length);
  }, [category.list.length, checkedList.length]);

  const onChange = (list: any[]) => {
    const newList = completeList.filter((item) => !checkedList.includes(item));
    dispatch(setFilter([...newList, ...list]));
    setIndeterminate(!!list.length && list.length < category.list.length);
    setCheckAll(list.length === category.list.length);
  };

  const onCheckAllChange = (e: any) => {
    const newList = completeList.filter((item) => !checkedList.includes(item));
    dispatch(
      setFilter(e.target.checked ? [...newList, ...category.list.map((item) => item.key)] : newList)
    );
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };
  return (
    <>
      {category.list.length > 0 ? (
        <Collapse ghost expandIconPosition="right" defaultActiveKey={category.key}>
          <Panel
            header={
              <div onClick={(event) => event.stopPropagation()}>
                <Checkbox
                  indeterminate={indeterminate}
                  onChange={onCheckAllChange}
                  checked={checkAll}
                >
                  {maskCamelCase(category.value)}
                </Checkbox>
              </div>
            }
            key={category.key}
          >
            {
              <>
                <CheckboxGroup value={checkedList} onChange={onChange}>
                  <Row>
                    {category.list.map((item) => (
                      <Col span={24} key={item.key}>
                        <Checkbox value={item.key}>{maskCamelCase(item.value)}</Checkbox>
                      </Col>
                    ))}
                  </Row>
                </CheckboxGroup>
              </>
            }
          </Panel>
        </Collapse>
      ) : (
        <div style={{ padding: '12px 16px' }} onClick={(event) => event.stopPropagation()}>
          <CheckboxGroup value={checkedList} onChange={onChange}>
            <Checkbox value={category.key}>{maskCamelCase(category.value)}</Checkbox>
          </CheckboxGroup>
        </div>
      )}
    </>
  );
};

export default CheckCollapse;
