import React, { Dispatch, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ColumnsType } from 'antd/es/table';
import {
  EllipsisOutlined,
  EyeOutlined,
  ShoppingCartOutlined,
  FilePdfOutlined,
} from '@ant-design/icons';
import { Action } from 'typesafe-actions';
import { Menu, Dropdown, Button } from 'antd';

import { ApplicationState } from '../../../store';
import CustomTable from '../../../components/CustomTable';
import { configToColumnsType, dataToTableData } from '../../../utils/newTableHelper';
import { useHistory } from 'react-router-dom';
import { clearCart, reDoOrderRequest, setBudgetId } from '../../../store/ducks/shoppingCart/actions';
import Swal from 'sweetalert2';
import { useCallback } from 'react';
import ServerError from '../../../components/ServerError';
import { loadBudgetsRequest } from '../../../store/ducks/budgets/actions';
import PdfDisplay from '../../../components/PdfDisplay';
interface Props {
  search: string;
}
const MyBudgetsTable: React.FC<Props> = ({ search }) => {
  const layout = useSelector((state: ApplicationState) => state.layout.data);
  const token = useSelector((state: ApplicationState) => state.auth.token);
  const config = useSelector((state: ApplicationState) => state.budgets.config);
  const data = useSelector((state: ApplicationState) => state.budgets.data);
  const error = useSelector((state: ApplicationState) => state.budgets.error);
  const roles = useSelector((state: ApplicationState) => state.user.data?.roles);
  const loading = useSelector((state: ApplicationState) => state.budgets.loading);
  const pg = useSelector((state: ApplicationState) => state.budgets.pagination);
  const saved = useSelector((state: ApplicationState) => state.shoppingCart.success);
  const cartQuantity = useSelector((state: ApplicationState) => state.shoppingCart.totalQuantity);
  const [columns, setColumns] = useState<ColumnsType<any>>([]);
  const [visible, setVisible] = useState<boolean>(false);
  const [currentId, setCurrentId] = useState<number | undefined>();

  const dispatch = useDispatch<Dispatch<Action>>();
  const history = useHistory();

  const generateReport = useCallback((nuNota: number) => {
    setCurrentId(nuNota);
    setVisible(true);
  }, []);

  useEffect(() => {
    if (saved) return;
    dispatch(loadBudgetsRequest(undefined, undefined, undefined, search));
  }, [dispatch, search, token, saved]);

  const budgetToOrder = useCallback(
    (nuNota: number) => {
      if (cartQuantity > 0) {
        Swal.fire({
          title: 'Aviso!',
          confirmButtonColor: layout?.color.default,
          html: 'Você já tem itens adicionados no seu carrinho. Deseja limpar o carrinho?',
          icon: 'warning',
          confirmButtonText: 'Sim',
          cancelButtonText: 'Não',
          showCancelButton: true,
        }).then((result) => {
          if (result.isConfirmed) {
            dispatch(clearCart());
            dispatch(reDoOrderRequest(nuNota));
            dispatch(setBudgetId(nuNota));
            history.push('/home/resumo-da-compra');
          }
        });
      } else {
        dispatch(clearCart());
        dispatch(reDoOrderRequest(nuNota));
        dispatch(setBudgetId(nuNota));
        history.push('/home/resumo-da-compra');
      }
    },
    [cartQuantity, dispatch, history, layout?.color.default]
  );

  useEffect(() => {
    setColumns(
      configToColumnsType(
        config?.fields.filter((item) => item.show) || [],
        undefined,
        [
          {
            title: 'Ações',
            key: 'action',
            render: (record) => (
              <Dropdown
                trigger={['click']}
                overlay={
                  <Menu>
                    <Menu.Item
                      onClick={() => {
                        history.push(`meus-pedidos/meus-orcamentos/${record.pkValue}/detalhes`);
                      }}
                    >
                      <Button data-cy="btn-budgetDetail" icon={<EyeOutlined />} type="text">
                        Ver
                      </Button>
                    </Menu.Item>

                    <Menu.Item
                      data-cy="btn-budgetToOrder"
                      onClick={() => budgetToOrder(record.pkValue)}
                    >
                      <Button icon={<ShoppingCartOutlined />} type="text">
                        Fazer pedido
                      </Button>
                    </Menu.Item>

                    {config?.details.generateReport && (
                      <Menu.Item onClick={() => generateReport(record.pkValue)}>
                        <Button data-cy="btn-pdfGenerate" icon={<FilePdfOutlined />} type="text">
                          Gerar PDF
                        </Button>
                      </Menu.Item>
                    )}
                  </Menu>
                }
              >
                <Button
                  data-cy="btn-more"
                  type="link"
                  style={{ padding: 0 }}
                  onClick={(e) => e.preventDefault()}
                >
                  <EllipsisOutlined style={{ fontSize: '24px' }} rotate={90} />
                </Button>
              </Dropdown>
            ),
          },
        ],
        undefined
      )
    );
  }, [pg, layout, config, history, roles, budgetToOrder, generateReport]);

  return (
    <>
      {error ? (
        <ServerError {...error} />
      ) : (
        <CustomTable
          onChange={(pagination: any, filters: any, sorter: any) => {
            dispatch(loadBudgetsRequest(pagination, filters, sorter, search));
          }}
          pagination={pg}
          loading={loading}
          columns={columns}
          data={dataToTableData(data, config)}
        />
      )}
      <PdfDisplay
        title="Relatório"
        visible={visible}
        setVisible={setVisible}
        url={`budget/${currentId}/report`}
      />
    </>
  );
};

export default MyBudgetsTable;
