import { Col } from 'antd';
import React from 'react';
import { Container } from './styles';

interface Props {
  width: number;
  children: React.ReactNode;
}
const DashContainer: React.FC<Props> = ({ width, children }) => {
  const getColSize = () => Math.round((24 * width) / 100);

  return (
    <Col
      xs={24}
      sm={24}
      md={getColSize()}
      lg={getColSize()}
      xl={getColSize()}
      xxl={getColSize()}
    >
      <Container>{children}</Container>
    </Col>
  );
};

export default DashContainer;
