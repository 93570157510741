import { Button, Dropdown, Menu } from 'antd';
import React, { Dispatch } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Action } from 'typesafe-actions';
import { EllipsisOutlined, EditOutlined, ShopOutlined } from '@ant-design/icons';
import CustomTable from '../../../components/CustomTable';
import { ApplicationState } from '../../../store';
import { contactsRequest } from '../../../store/ducks/contacts/actions';
import ServerError from '../../../components/ServerError';
import { Contact } from '../../../store/ducks/contacts/types';
interface Props {
  userId: number;
}
const ContactsTable: React.FC<Props> = ({ userId }) => {
  const data = useSelector((state: ApplicationState) => state.contacts.data);
  const loading = useSelector((state: ApplicationState) => state.contacts.loading);
  const pagination = useSelector((state: ApplicationState) => state.contacts.pagination);
  const error = useSelector((state: ApplicationState) => state.contacts.error);
  const history = useHistory();
  const dispatch = useDispatch<Dispatch<Action>>();

  const columns = [
    {
      title: 'Parceiro',
      dataIndex: 'partnerName',
      key: 'partnerName',
    },
    {
      title: 'Contato',
      dataIndex: 'contactName',
      key: 'contactName',
    },
    {
      title: 'Ações',
      key: 'action',
      render: (value: Contact) => {
        return (
          <Dropdown
            trigger={['click']}
            overlay={
              <Menu>
                <Menu.Item
                  onClick={() => {
                    history.push(
                      `/home/controle-acessos/usuario/${value.userId}/contato/${value.idContact}/ponto-entrega`
                    );
                  }}
                >
                  <Button data-cy="btn-deliveryPoint" type="text" icon={<ShopOutlined />}>
                    Ponto de entrega
                  </Button>
                </Menu.Item>
                <Menu.Item
                  onClick={() => {
                    history.push(
                      `/home/controle-acessos/usuario/${value.userId}/contato/${value.idContact}`
                    );
                  }}
                >
                  <Button data-cy="btn-edit" icon={<EditOutlined />} type="text">
                    Editar
                  </Button>
                </Menu.Item>
              </Menu>
            }
          >
            <Button
              data-cy="btn-more"
              type="link"
              style={{ padding: 0 }}
              onClick={(e) => e.preventDefault()}
            >
              <EllipsisOutlined style={{ fontSize: '24px' }} rotate={90} />
            </Button>
          </Dropdown>
        );
      },
    },
  ];

  return (
    <>
      {error ? (
        <ServerError {...error} />
      ) : (
        <CustomTable
          noShadow
          onChange={(pagination: any) => {
            dispatch(contactsRequest(userId, pagination));
          }}
          pagination={pagination}
          loading={loading}
          columns={columns}
          data={data}
        />
      )}
    </>
  );
};

export default ContactsTable;
