import { Image, Divider, List, Select, Spin, Row, Col } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Dispatch } from 'redux';
import { Action } from 'typesafe-actions';
import { Size, useWindowSize } from '../../../customHooks';
import { baseURL } from '../../../services/api';
import { ApplicationState } from '../../../store';
import { colors } from '../../../utils/constants';
import { maskCamelCase, maskCurrency } from '../../../utils/masks';
import defaultImg from '../../../assets/img/default.jpg';
import PromoTag from '../../../components/PromoTag';
import { setUnity } from '../../../store/ducks/showCase/actions';

const { Option } = Select;

const ProductListItem: React.FC<any> = ({ item, mode, loading }) => {
  const layout = useSelector((state: ApplicationState) => state.layout.data);

  const dispatch = useDispatch<Dispatch<Action>>();
  const size: Size = useWindowSize();

  const history = useHistory();

  const handleClick = (id: number) => {
    history.push(`/showcase/products/${id}/detalhes`);
  };

  const getStock = (e: any) =>
    Math.floor(e?.ESTOQUE.find(({ key }: any) => key === item?.SELECTED_UNITY).value || 0);

  const getValue = (e: any) =>
    e.VALOR.find(({ key }: any) => key === item?.SELECTED_UNITY).value || 0;
  return (
    <>
      <List.Item
        style={{
          cursor: 'pointer',
          borderRadius: '5px',
          borderLeft: item?.PROMOCAO?.isPromo ? `5px solid ${layout?.color.default}` : 0,
          padding: '20px',
        }}
        onClick={() => handleClick(item?.pkValue)}
      >
        <List.Item.Meta
          avatar={
            mode !== 'GRID' && (
              <div
                style={{
                  height: '64px',
                  width: '64px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Image
                  preview={false}
                  fallback={defaultImg}
                  loading="lazy"
                  placeholder={
                    <Spin
                      indicator={<LoadingOutlined style={{ color: layout?.color.default }} />}
                    />
                  }
                  style={{ maxHeight: '100%', maxWidth: '100%' }}
                  src={
                    loading ? defaultImg : `${baseURL}/produtos/${item?.pkValue}/imagem?w=64&h=64`
                  }
                />
              </div>
            )
          }
          title={
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                {item?.CODPROD_SHOW && `${item?.CODPROD} -`} {item?.DESCRPROD}
              </div>
            </div>
          }
          description={
            <>
              {item.REFFORN && (
                <span>
                  Referência:
                  <strong style={{ marginLeft: '5px', marginRight: '5px' }}>{item.REFFORN}</strong>
                  {(item.MARCA || item?.ESTOQUE_SHOW) && (
                    <Divider
                      style={{
                        margin: '0 5px 0 0',
                        backgroundColor: colors.writeGrey,
                        width: '1px',
                      }}
                      type="vertical"
                    />
                  )}
                </span>
              )}
              {item.MARCA && (
                <span>
                  Marca:
                  <strong style={{ marginLeft: '5px', marginRight: '5px' }}>{item.MARCA}</strong>
                  {item?.ESTOQUE_SHOW && (
                    <Divider
                      style={{
                        margin: '0 5px 0 0',
                        backgroundColor: colors.writeGrey,
                        width: '1px',
                      }}
                      type="vertical"
                    />
                  )}
                </span>
              )}
              {item?.ESTOQUE_SHOW && (
                <span>
                  Disponível:
                  <strong style={{ marginLeft: '5px', marginRight: '5px' }}>
                    {getStock(item)}
                  </strong>
                </span>
              )}
              {item.VALOR && (
                <>
                  <Row align="middle" gutter={[10, 10]}>
                    {item?.PROMOCAO?.isPromo && (
                      <Col>
                        <PromoTag />
                      </Col>
                    )}
                    <Col>
                      <h2
                        style={{
                          margin: 0,
                          color: 'green',
                          fontSize: '16px',
                        }}
                      >
                        {item?.DETAILS.stockControlType === 'GRADE' && getValue(item) === 0
                          ? 'Indisponível'
                          : maskCurrency(getValue(item))}
                      </h2>
                    </Col>
                    {(size?.width ?? 0) >= 600 && (
                      <Col>
                        <div onClick={(e) => e.stopPropagation()}>
                          <Select
                            data-cy="input-selectUnity"
                            defaultValue={item?.SELECTED_UNITY}
                            value={item?.SELECTED_UNITY}
                            onChange={(value) => dispatch(setUnity(item?.pkValue, value))}
                          >
                            {item?.UNIDADE?.map((element: any) => {
                              return (
                                <Option key={element.key} value={element.key}>
                                  {maskCamelCase(element.value)}
                                </Option>
                              );
                            })}
                          </Select>
                        </div>
                      </Col>
                    )}
                  </Row>
                  {(size?.width ?? 0) < 600 && (
                    <div
                      onClick={(e) => e.stopPropagation()}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Select
                        data-cy="input-selectUnity"
                        defaultValue={item?.SELECTED_UNITY}
                        value={item?.SELECTED_UNITY}
                        onChange={(value) => dispatch(setUnity(item?.pkValue, value))}
                      >
                        {item?.UNIDADE?.map((element: any) => {
                          return (
                            <Option key={element.key} value={element.key}>
                              {maskCamelCase(element.value)}
                            </Option>
                          );
                        })}
                      </Select>
                    </div>
                  )}
                </>
              )}
            </>
          }
        />
      </List.Item>
    </>
  );
};

export default ProductListItem;
