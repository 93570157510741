import React from 'react'
import { Col, DatePicker, Input, Row, Select, Form } from 'antd';
import { UserOutlined, CreditCardOutlined, LockOutlined } from '@ant-design/icons';
import MaskedInput from 'react-text-mask';

import { maskCreditCard } from '../../../utils/masks';
import { colors } from '../../../utils/constants';


import { CreditCardData, CreditCardDataTitle } from './styles'
import { CreditCardProps } from './props';

const { Option } = Select;

const CreditCard: React.FC<CreditCardProps> = ({ form, selectedPayment }) => {
  return (
    <Form layout="vertical" form={form}>
      <CreditCardData>
        <CreditCardDataTitle>
          <LockOutlined />Informe os dados do cartão
        </CreditCardDataTitle>
        <Row gutter={[10, 10]}>
          <Col span={24}>

            <Form.Item name="creditCard.cardNumber" style={{ marginBottom: 0 }} rules={[{ required: true, message: `Por favor informe o número do cartão` }]}>
              <MaskedInput
                className="ant-input"
                mask={maskCreditCard}
                placeholder="Informe o número do cartão"
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item name='creditCard.nameOnCard' style={{ marginBottom: 0 }} rules={[{ required: true, message: `Por favor informe o nome que consta no cartão` }]}>
              <Input
                placeholder="Nome que consta no cartão"

                suffix={<UserOutlined style={{ color: colors.writeGrey }} />}
              />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item name='creditCard.expirationDate' style={{ marginBottom: 0 }} rules={[{ required: true, message: `Por favor informe a data de expiração` }]}>
              <DatePicker
                style={{
                  width: '100%',
                }}
                picker="month"
                format={'MM/yyyy'}
                placeholder="Data de expiração"
              />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item name='creditCard.cvv' style={{ marginBottom: 0 }} rules={[{ required: true, message: `Informe o CVV` }]}>
              <Input
                placeholder="CVV"
                maxLength={3}
                suffix={<CreditCardOutlined style={{ color: colors.writeGrey }} />}
              />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item name='creditCard.installments' style={{ marginBottom: 0 }} rules={[{ required: true, message: `Seleciona o número de parcelas` }]}>
              <Select
                placeholder="Número de parcelas"
                allowClear
                style={{ width: '100%' }}
              >
                {
                  [...Array(selectedPayment.paymentMethod ? selectedPayment.paymentMethod.installments : 1)].map((_, index) => {
                    if (index === 0) return <Option value={1}>1 parcela</Option>

                    return <Option value={index + 1}>{index + 1} parcelas</Option>
                  })
                }
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </CreditCardData>
    </Form>
  )
}

export default CreditCard
