import styled from 'styled-components';
import { colors } from '../../utils/constants';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  border: 1px solid ${colors.borderGrey};
  box-sizing: border-box;
  border-radius: 5px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const Title = styled.h3`
  color: ${colors.writeGrey};
  margin: 4px 0px;
  font-size: 14px;
  line-height: 22px;
  font-style: normal;
  font-weight: normal;
`;

export const Value = styled.h2`
  margin: 4px 0px;
  font-size: 24px;
  line-height: 32px;
  font-style: normal;
  font-weight: normal;
`;
