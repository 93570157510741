import { Image, Divider, List, Select, Spin, Row, Col } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Dispatch } from 'redux';
import { Action } from 'typesafe-actions';
import MetreageCalculator from '../../../components/NewOrderModals/MetreageCalculator';
import ModalGrid from '../../../components/NewOrderModals/ModalGrid';
import QuantityInput from '../../../components/QuantityInput';
import { Size, useWindowSize } from '../../../customHooks';
import { baseURL } from '../../../services/api';
import { ApplicationState } from '../../../store';
import { favoriteRequest, setUnity } from '../../../store/ducks/products/actions';
import { Unity } from '../../../store/ducks/shoppingCart/types';
import { colors } from '../../../utils/constants';
import { maskCamelCase, maskCurrency } from '../../../utils/masks';
import { ActiveFavoriteIcon, FavoriteContent, FavoriteIcon } from '../styles';
import defaultImg from '../../../assets/img/default.jpg';
import MattressModal from '../../../components/NewOrderModals/MattressModal';
import PromoTag from '../../../components/PromoTag';
import { getFixedFieldDescription } from '../../../utils/newTableHelper';

import { Description, TotalQuantity } from "./styles";
import { formatNumberTwoDecimalPlaces } from '../../../utils/formatNumberHelper';

const { Option } = Select;

const ProductListItem: React.FC<any> = ({ item, mode, loading }) => {
  const shoppingCart = useSelector((state: ApplicationState) => state.shoppingCart.cart);
  const layout = useSelector((state: ApplicationState) => state.layout.data);
  const config = useSelector((state: ApplicationState) => state.products.config);

  const dispatch = useDispatch<Dispatch<Action>>();
  const size: Size = useWindowSize();

  const history = useHistory();

  const getUnity = (): Unity => {
    const unity = shoppingCart.find((element) => element.codProd?.toString() === item?.CODPROD?.toString())?.unity;

    if (unity) item?.UNIDADE?.find((e: Unity) => unity?.key === e.key);

    return item?.UNIDADE?.find((e: Unity) => item?.SELECTED_UNITY === e.key);
  };

  const getQuantity = () => {
    return shoppingCart.find((element) => element.codProd?.toString() === item?.CODPROD?.toString())?.quantity;
  };

  const handleClick = (id: number) => {
    history.push(`novo-pedido/produto/${id}/detalhes`);
  };

  const renderInput = () => (
    <div onClick={(e) => e.stopPropagation()} style={{ textAlign: 'left' }}>
      {!item?.USAMETRAGEM &&
        item?.DETAILS.stockControlType !== 'GRADE' &&
        item?.DETAILS.stockControlType !== 'AFC' && (
          <>
            <QuantityInput
              isPromo={item?.PROMOCAO?.isPromo}
              width={(size?.width ?? 0) < 600 ? undefined : 140}
              description={item?.DESCRPROD}
              codProd={item?.pkValue}
              quantity={0}
              stock={getStock(item)}
              unity={getUnity()}
              value={getValue(item)}
              hasStock={item?.ESTOQUE_SHOW}
              message={getUnity().message}
              multiplier={getUnity().multiple}
              isDecimal={!!getUnity().decimalPlaces}
              decimalQtd={getUnity().decimalPlaces}
              controlType={item?.DETAILS?.stockControlType}
            />
            {getUnity()?.multiple && getQuantity() ? <TotalQuantity>(Total: {formatNumberTwoDecimalPlaces((getQuantity() || 0) * (getUnity().multiple || 1))})</TotalQuantity> : <></>}
          </>
        )}
      {item?.USAMETRAGEM && <MetreageCalculator product={item} />}
      {item?.DETAILS.stockControlType === 'GRADE' && (
        <ModalGrid product={item} disabled={getValue(item) === 0} />
      )}
      {item?.DETAILS.stockControlType === 'AFC' && <MattressModal product={item} />}
    </div>
  );

  const getStock = (e: any) =>
    Math.floor(
      e?.ESTOQUE.find(
        ({ key }: any) =>
          key ===
          (shoppingCart.find((cartItem) => cartItem.codProd === item?.pkValue)?.unity?.key ||
            item?.SELECTED_UNITY)
      )?.value || 0
    );

  const getValue = (e: any) => {
    if (shoppingCart.length > 0 && e?.DETAILS.stockControlType !== 'GRADE') {
      const dataInCart = shoppingCart.find(cartItem => String(cartItem.codProd) === e.pkValue);

      if (dataInCart)
        return dataInCart.value
    }

    return e.VALOR.find(
      ({ key }: any) =>
        key ===
        (shoppingCart.find((cartItem) => cartItem.codProd === item?.pkValue)?.unity?.key ||
          item?.SELECTED_UNITY)
    )?.value || 0
  };

  return (
    <>
      <List.Item
        style={{
          cursor: 'pointer',
          borderRadius: '5px',
          borderLeft: item?.PROMOCAO?.isPromo ? `5px solid ${layout?.color.default}` : 0,
          padding: '20px',
        }}
        onClick={() => handleClick(item?.pkValue)}
        actions={(size?.width ?? 0) > 600 ? [renderInput()] : undefined}
      >
        <List.Item.Meta
          avatar={
            mode !== 'GRID' && (
              <div
                style={{
                  height: '64px',
                  width: '64px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Image
                  preview={false}
                  fallback={defaultImg}
                  loading="lazy"
                  placeholder={
                    <Spin
                      indicator={<LoadingOutlined style={{ color: layout?.color.default }} />}
                    />
                  }
                  style={{ maxHeight: '100%', maxWidth: '100%' }}
                  src={
                    loading ? defaultImg : `${baseURL}/produtos/${item?.pkValue}/imagem?w=64&h=64`
                  }
                />
              </div>
            )
          }
          title={
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <div onClick={(e) => e.stopPropagation()}>
                <FavoriteContent
                  onClick={() => dispatch(favoriteRequest(item?.pkValue, !item?.FAVORITO))}
                >
                  {item?.FAVORITO ? (
                    <ActiveFavoriteIcon data-cy="iconFilled" />
                  ) : (
                    <FavoriteIcon data-cy="iconOutlined" />
                  )}
                </FavoriteContent>
              </div>
              <Description>
                {item?.CODPROD_SHOW && `${item?.CODPROD} -`} {item?.DESCRPROD}
              </Description>
            </div>
          }
          description={
            <>
              {item.REFERENCIA && (
                <span>
                  {getFixedFieldDescription('REFERENCIA', 'Referência', config?.fields)}:
                  <strong style={{ marginLeft: '5px', marginRight: '5px' }}>{item.REFERENCIA}</strong>
                  {(item.MARCA || item?.ESTOQUE_SHOW || item.FORNEC) && (
                    <Divider
                      style={{
                        margin: '0 5px 0 0',
                        backgroundColor: colors.writeGrey,
                        width: '1px',
                      }}
                      type="vertical"
                    />
                  )}
                </span>
              )}
              {item.FORNEC && (
                <span>
                  {getFixedFieldDescription('FORNEC', 'Fornecedor', config?.fields)}:
                  <strong style={{ marginLeft: '5px', marginRight: '5px' }}>{item.FORNEC}</strong>
                  {(item.MARCA || item?.ESTOQUE_SHOW) && (
                    <Divider
                      style={{
                        margin: '0 5px 0 0',
                        backgroundColor: colors.writeGrey,
                        width: '1px',
                      }}
                      type="vertical"
                    />
                  )}
                </span>
              )}
              {item.MARCA && (
                <span>
                  {getFixedFieldDescription('MARCA', 'Marca', config?.fields)}:
                  <strong style={{ marginLeft: '5px', marginRight: '5px' }}>{item.MARCA}</strong>
                  {item?.ESTOQUE_SHOW && (
                    <Divider
                      style={{
                        margin: '0 5px 0 0',
                        backgroundColor: colors.writeGrey,
                        width: '1px',
                      }}
                      type="vertical"
                    />
                  )}
                </span>
              )}
              {item?.ESTOQUE_SHOW && (
                <span>
                  Disponível:
                  <strong style={{ marginLeft: '5px', marginRight: '5px' }}>
                    {getStock(item)}
                  </strong>
                </span>
              )}
              <Row align="middle" gutter={[10, 10]}>
                {item?.PROMOCAO?.isPromo && (
                  <Col>
                    <PromoTag />
                  </Col>
                )}
                <Col>
                  <h2
                    style={{
                      margin: 0,
                      color: 'green',
                      fontSize: '16px',
                    }}
                  >
                    {item?.DETAILS.stockControlType === 'GRADE' && getValue(item) === 0
                      ? 'Indisponível'
                      : maskCurrency(getValue(item))}
                  </h2>
                </Col>
                {(size?.width ?? 0) >= 600 && (
                  <Col>
                    <div onClick={(e) => e.stopPropagation()}>
                      <Select
                        data-cy="input-selectUnity"
                        defaultValue={item?.SELECTED_UNITY}
                        value={
                          shoppingCart.find((e) => e.codProd === item?.pkValue)?.unity?.key ||
                          item?.SELECTED_UNITY
                        }
                        onChange={(value) => dispatch(setUnity(item?.pkValue, value))}
                        disabled={
                          !!shoppingCart.find((element) => element.codProd === item?.pkValue)
                        }
                      >
                        {item?.UNIDADE?.map((element: any) => {
                          return (
                            <Option key={element.key} value={element.key}>
                              {maskCamelCase(element.value)}
                            </Option>
                          );
                        })}
                      </Select>
                    </div>
                  </Col>
                )}
              </Row>
              {(size?.width ?? 0) < 600 && (
                <div
                  onClick={(e) => e.stopPropagation()}
                  style={{ display: 'flex', flexDirection: 'column', gap: '8px'}}
                >
                  <Select
                    data-cy="input-selectUnity"
                    defaultValue={item?.SELECTED_UNITY}
                    value={
                      shoppingCart.find((e) => e.codProd === item?.pkValue)?.unity?.key ||
                      item?.SELECTED_UNITY
                    }
                    onChange={(value) => dispatch(setUnity(item?.pkValue, value))}
                    disabled={!!shoppingCart.find((element) => element.codProd === item?.pkValue)}
                  >
                    {item?.UNIDADE?.map((element: any) => {
                      return (
                        <Option key={element.key} value={element.key}>
                          {maskCamelCase(element.value)}
                        </Option>
                      );
                    })}
                  </Select>
                  {renderInput()}
                </div>
              )}
            </>
          }
        />
      </List.Item>
    </>
  );
};

export default ProductListItem;
