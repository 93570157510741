import { ResponseError } from '../../../services/api';
import { Config, Data, DataValues } from '../../../utils/newTableHelper';

export enum BudgetsDetailTypes {
  'LOAD_REQUEST' = 'BudgetsDetail/LOAD_REQUEST',
  'LOAD_SUCCESS' = 'BudgetsDetail/LOAD_SUCCESS',
  'LOAD_FAILURE' = 'BudgetsDetail/LOAD_FAILURE',

  'DATA_REQUEST' = 'BudgetsDetail/DATA_REQUEST',
  'DATA_SUCCESS' = 'BudgetsDetail/DATA_SUCCESS',
  'DATA_FAILURE' = 'BudgetsDetail/DATA_FAILURE',
}

export interface Pagination {
  current: number;
  total: number;
  pageSize: number;
}

export interface BudgetsDetailState {
  readonly data: Data[];
  readonly config?: Config;
  readonly pagination?: Pagination;
  readonly loading: boolean;
  readonly loadingTable: boolean;
  readonly dataDetail: DataValues[];
  readonly configDetail?: Config;
  readonly errorData?: ResponseError;
  readonly errorDetails?: ResponseError;
}
