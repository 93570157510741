import { AxiosResponse } from 'axios';
import { call, put, select } from 'redux-saga/effects';
import { ApplicationState } from '../..';
import api, { errorHandler } from '../../../services/api';
import { requestHeaders } from '../../../utils/apiHelper';
import { refreshRequest } from '../auth/actions';
import { Campaign } from '../campaigns/types';
import { successHomeCampaigns, failureHomeCampaigns } from './actions';

export function* loadHomeCampaigns() {
  try {
    const state: ApplicationState = yield select();
    const headers = requestHeaders(state.auth.token);
    const response: AxiosResponse<{ content: Campaign[] }> = yield call(
      api.post,
      `campaign/all`,
      { page: 0, size: 3 },
      {
        headers: headers,
      }
    );
    yield put(successHomeCampaigns(response.data.content));
  } catch (err: any) {
    yield put(
      err.response.status !== 401 ? failureHomeCampaigns(errorHandler(err)) : refreshRequest()
    );
  }
}
