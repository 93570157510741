import { PageHeader, Button } from 'antd';
import styled from 'styled-components';
import { Layout } from '../../../store/ducks/layout/types';
import { colors } from '../../../utils/constants';
import { WhatsAppOutlined } from '@ant-design/icons';

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  background-color: ${colors.grayBackgroud};
`;

export const Header = styled(PageHeader)<{ layout?: Layout }>`
  padding: 10px 24px;
  background-image: linear-gradient(
    to right,
    ${({ layout }) => layout?.color.default},
    ${({ layout }) => layout?.color.dark}
  );
`;

export const PageContent = styled.div`
  margin: 0 auto;
  max-width: 1366px;
  padding: 20px;
  @media only screen and (max-width: 1024px) {
    padding: 10px;
  }
`;

export const PageContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Title = styled.h3`
  margin: 0;
  color: white;
`;

export const WhatsAppButton = styled(Button)`
  position: absolute !important;
  z-index: 999;
  right: 16px;
  bottom: 8px;
  box-shadow: -1px 4px 2px -1px rgba(0, 0, 0, 0.24);
  margin: 5px 0 5px;
  color: white;
  background-color: ${colors.whatsGreen} !important;
  border-color: ${colors.whatsGreen} !important;

  :hover {
    color: white;
    background-color: rgba(37, 211, 102, 0.8) !important;
    border-color: rgba(37, 211, 102, 0.8) !important;
  }
  :active {
    color: white;
    background-color: rgba(37, 211, 102, 0.8) !important;
    border-color: rgba(37, 211, 102, 0.8) !important;
  }
  :focus {
    color: white;
    background-color: rgba(37, 211, 102, 0.8) !important;
    border-color: rgba(37, 211, 102, 0.8) !important;
  }

  @media only screen and (max-width: 1024px) {
    right: 12px;
    bottom: 43px;
  }
`;

export const WhatsAppIcon = styled(WhatsAppOutlined)`
  font-size: 28px !important;
`;
