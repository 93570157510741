import { ResponseError } from '../../../services/api';
import { Config } from '../../../utils/newTableHelper';

export enum ProductDetailShowCaseTypes {
  'LOAD_REQUEST' = '@showCaseProductDetail/LOAD_REQUEST',
  'LOAD_SUCCESS' = '@showCaseProductDetail/LOAD_SUCCESS',
  'LOAD_FAILURE' = '@showCaseProductDetail/LOAD_FAILURE',

  'SET_UNITY' = '@showCaseProductDetail/SET_UNITY',
  'UNITY_SUCCESS' = '@showCaseProductDetail/UNITY_SUCCESS',
}


export interface ProductDetailShowCaseState {
  readonly data: any[];
  readonly images: string[];
  readonly thumbnails: string[];
  readonly relatedProducts: any[];

  readonly config?: Config;
  readonly loading: boolean;
  readonly error?: ResponseError;
}
