import { Reducer } from 'redux';
import { NewContactTypes, NewContactState } from './types';

const INITIAL_STATE: NewContactState = {
  loadingSend: false,
  saved: false,
  loadingContact: false,
  loadingRoles: false,
  roles: [],
};

const reducer: Reducer<NewContactState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case NewContactTypes.CONTACT_REQUEST:
      return {
        ...state,
        loadingContact: true,
      };
    case NewContactTypes.CONTACT_SUCCESS:
      return {
        ...state,
        loadingContact: false,
        errorContact: undefined,
        dataContact: action.payload.data,
      };
    case NewContactTypes.CONTACT_FAILURE:
      return {
        ...state,
        dataContact: undefined,
        loadingContact: false,
        errorContact: action.payload.error,
      };

    case NewContactTypes.ROLES_REQUEST:
      return {
        ...state,
        loadingRoles: true,
      };
    case NewContactTypes.ROLES_SUCCESS:
      return {
        ...state,
        loadingRoles: false,
        errorRoles: undefined,
        roles: action.payload.roles,
      };
    case NewContactTypes.ROLES_FAILURE:
      return {
        ...state,
        roles: [],
        loadingRoles: false,
        errorRoles: action.payload.error,
      };
    case NewContactTypes.SEND_REQUEST:
      return {
        ...state,
        loadingSend: true,
      };
    case NewContactTypes.SEND_SUCCESS:
      return {
        ...state,
        saved: true,
        loadingSend: false,
        errorSend: undefined,
        dataSend: action.payload.data,
      };
    case NewContactTypes.SEND_FAILURE:
      return {
        ...state,
        dataSend: undefined,
        saved: false,
        loadingSend: false,
        errorSend: action.payload.error,
      };
    case NewContactTypes.CLEAR_SEND:
      return {
        ...state,
        loadingSend: false,
        error: false,
        dataContact: undefined,
        dataSend: undefined,
        errorMessage: '',
        saved: false,
      };
    case NewContactTypes.SET_ERROR:
      return {
        ...state,
        errorSend: action.payload.error,
      };
    default:
      return state;
  }
};

export default reducer;
