import { Reducer } from 'redux';
import { DeliveryPointTypes, DeliveryPointState } from './types';

const INITIAL_STATE: DeliveryPointState = {
  data: [],
  pagination: undefined,
  loading: false,
};

const reducer: Reducer<DeliveryPointState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DeliveryPointTypes.POINTS_REQUEST:
      return {
        ...state,
        loadingPoints: true,
      };
    case DeliveryPointTypes.POINTS_SUCCESS:
      return {
        ...state,
        error: undefined,
        loadingPoints: false,
        ...action.payload,
      };
    case DeliveryPointTypes.POINTS_FAILURE:
      return {
        ...state,
        dataPoints: [],
        loadingPoints: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export default reducer;
