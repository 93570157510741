import styled from 'styled-components';
import { Layout } from '../../store/ducks/layout/types';

export const Container = styled.div`
  
`;

export const PanelTitle = styled.h2<{ layout?: Layout }>`
  color: ${({ layout }) => layout?.color.dark};
  margin: 0;
`;