import React, { useState } from 'react';
import { Button, Modal, Row, Upload } from 'antd';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { UploadOutlined, FileDoneOutlined } from '@ant-design/icons';
import { ApplicationState } from '../../../store';
import TextArea from 'antd/lib/input/TextArea';
import { UploadContent } from '../styles';
import { Container } from '../../../styles';
import { requestHeaders } from '../../../utils/apiHelper';
import api from '../../../services/api';
import { SubmitButton, DefaultButton } from '../../../styles';
interface Props {
  nuFin: number;
  url: string;
  sendReceipt?: boolean;
}

const FinancialAttachmentFile: React.FC<Props> = ({ nuFin, url, sendReceipt }) => {
  const token = useSelector((state: ApplicationState) => state.auth.token);
  const layout = useSelector((state: ApplicationState) => state.layout.data);
  const [visible, setVisible] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedFileList, setSelectedFileList] = useState<any[]>([]);
  const [selectedFile, setSelectedFile] = useState<string>('');
  const [description, setDescription] = useState<string>('');

  const handleCancel = () => {
    setSelectedFile('');
    setSelectedFileList([]);
    setDescription('');
    setVisible(false);
  };

  const handleOk = () => {
    const headers = requestHeaders(token);
    setLoading(true);
    api
      .post(
        `financial/${nuFin}/proof`,
        { attachment: selectedFile, description: description },
        { headers: headers }
      )
      .then((_res) => {
        setLoading(false);
        Swal.fire({
          text: 'Arquivo anexado com sucesso.',
          icon: 'success',
          confirmButtonColor: layout?.color.default,
          confirmButtonText: 'Ok',
        });
        handleCancel();
      })
      .catch((err) => {
        setLoading(false);
        Swal.fire({
          title: 'Erro!',
          confirmButtonColor: layout?.color.default,
          html: err.response.data.titulo,
          icon: 'error',
        });
      });
  };

  const onChangeUpload = (event: any) => {
    switch (event.file.status) {
      case 'uploading':
        setSelectedFileList(event.fileList);
        break;
      case 'done':
        setSelectedFile(event.file.response.arquivo);
        setSelectedFileList(event.fileList);
        break;
      default:
        break;
    }
  };

  const onChangeDescription = (event: any) => {
    setDescription(event.target.value);
  };

  const onRemoveFileList = () => {
    setSelectedFile('');
    setSelectedFileList([]);
  };

  return (
    <>
      <Button
        data-cy="btn-attach"
        icon={<FileDoneOutlined />}
        disabled={!sendReceipt}
        type="text"
        onClick={() => setVisible(true)}
      >
        Anexar comprovante
      </Button>
      <Modal
        data-cy="modal-sendReceipt"
        title="Enviar comprovante"
        visible={visible}
        onCancel={handleCancel}
        footer={[
          <DefaultButton data-cy="btn-close" key="back" onClick={handleCancel} layout={layout}>
            Fechar
          </DefaultButton>,
          <SubmitButton
            data-cy="btn-send"
            layout={layout}
            key="submit"
            loading={loading}
            onClick={handleOk}
            disabled={!selectedFile}
            type="primary"
          >
            Enviar
          </SubmitButton>,
        ]}
      >
        <Container layout={layout}>
          <p>
            Clique no botão abaixo para enviar o comprovante referente à movimentação{' '}
            <strong>{nuFin}</strong>.
          </p>

          <TextArea
            data-cy="input-description"
            maxLength={100}
            placeholder="Descrição"
            onChange={onChangeDescription}
            value={description}
          />
          <Row justify="end" style={{ marginBottom: '16px' }}>
            <small>{`${description.length}/100`}</small>
          </Row>
          <UploadContent>
            <Upload
              style={{ width: '100%' }}
              fileList={selectedFileList}
              onRemove={onRemoveFileList}
              multiple={false}
              action={url}
              accept=".pdf,.png,.jpg,.jpeg"
              onChange={onChangeUpload}
              maxCount={1}
            >
              <DefaultButton
                data-cy="btn-attachFile"
                icon={<UploadOutlined />}
                style={{ width: '100%' }}
                layout={layout}
              >
                Anexar arquivo
              </DefaultButton>
            </Upload>
          </UploadContent>
        </Container>
      </Modal>
    </>
  );
};

export default FinancialAttachmentFile;
