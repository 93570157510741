import React, { Dispatch, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Action } from 'typesafe-actions';
import { useParams } from 'react-router-dom';

import { ApplicationState } from '../../store';
import ContactForm from './ContactForm';
import { contactRequest, rolesRequest } from '../../store/ducks/newContact/actions';
import { Container } from '../../styles';

const NewContact: React.FC = () => {
  const token = useSelector((state: ApplicationState) => state.auth.token);
  const { userId, id }: any = useParams();

  const dispatch = useDispatch<Dispatch<Action>>();
  useEffect(() => {
    if (!id || !userId) {
      return;
    }
    dispatch(contactRequest(id, userId));
  }, [dispatch, id, token, userId]);

  useEffect(() => {
    dispatch(rolesRequest());
  }, [dispatch, token]);

  return (
    <Container>
      <ContactForm />
    </Container>
  );
};

export default NewContact;
