import styled from 'styled-components';
import { colors } from '../../utils/constants';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const FinancialHeader = styled.div`
  margin-bottom: 20px;
  display: flex;
  padding: 20px;
  flex-direction: row;
  justify-content: flex-end;
  background-color: white;
  border-radius: 5px;
  box-shadow: 1px 2px 2px 2px ${colors.grey};
`;

export const UploadContent = styled.div`
  .ant-upload.ant-upload-select {
    display: block;
    width: 100%;
  }
`;
