import styled from 'styled-components';
import { colors } from '../../utils/constants';


export const Header = styled.div`
  padding: 20px;
  border-radius: 5px;
  box-shadow: 1px 2px 2px 2px ${colors.grey};
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  background-color: white;
`;