import { AxiosResponse } from 'axios';
import { call, put, select } from 'redux-saga/effects';
import { ApplicationState } from '../..';
import api, { errorHandler } from '../../../services/api';
import { requestHeaders } from '../../../utils/apiHelper';
import { Config, Data, dataToTableData } from '../../../utils/newTableHelper';
import { refreshRequest } from '../auth/actions';
import {
  requestCampaignProducts,
  successCampaignProducts,
  failureCampaignProducts,
} from './actions';

export function* loadCampaignProducts({ payload }: ReturnType<typeof requestCampaignProducts>) {
  try {
    const state: ApplicationState = yield select();
    const headers = requestHeaders(state.auth.token);
    const config: AxiosResponse<Config> = yield call(api.get, '/new-order/products/template', {
      headers: headers,
    });
    const response: AxiosResponse<{
      content: Data[];
    }> = yield call(
      api.get,
      `/campaign/${payload.id}/products`,
      { headers: headers }
    );
    const tableData: any[] = yield call(dataToTableData, response.data.content, config.data);
    const filteredData = tableData.filter((item: any) => item?.UNIDADE.length > 0);
    const data = filteredData.map((item: any) => {
      return {
        ...item,
        SELECTED_UNITY: item?.UNIDADE[0].key,
      };
    });
    yield put(successCampaignProducts(data, config.data));
  } catch (err: any) {
    yield put(
      err?.response?.status !== 401 ? failureCampaignProducts(errorHandler(err)) : refreshRequest()
    );
  }
}
