import { Reducer } from 'redux';
import { ProductDetailShowCaseState, ProductDetailShowCaseTypes } from './types';

const INITIAL_STATE: ProductDetailShowCaseState = {
  data: [],
  images: [],
  thumbnails: [],
  relatedProducts: [],
  loading: false,
};

const reducer: Reducer<ProductDetailShowCaseState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ProductDetailShowCaseTypes.LOAD_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case ProductDetailShowCaseTypes.LOAD_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: undefined,
      };
    case ProductDetailShowCaseTypes.LOAD_FAILURE:
      return {
        ...state,
        data: [],
        loading: false,
        error: action.payload.error,
      };

    case ProductDetailShowCaseTypes.UNITY_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        data: action.payload.data,
      };

    default:
      return state;
  }
};

export default reducer;
