import React, { useEffect, useState } from 'react';
import { Mix } from '@ant-design/plots';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../store';
import { maskCamelCase } from '../../../utils/masks';
import { Serie, SeriesGroup } from '../../../store/ducks/dashboard/types';
import DataSet from '@antv/data-set';
import EmptyChart from '../EmptyChart';

interface Props {
  series: Serie[];
  xField: string;
  seriesGroup?: SeriesGroup[];
  title: string;
  data: any[];
  onClick(values: any): void;
}
const MixChart: React.FC<Props> = ({ title, data, series, seriesGroup, xField, onClick }) => {
  const layout = useSelector((state: ApplicationState) => state.layout.data);
  const [max, setMax] = useState<number>(0);
  const { DataView } = DataSet;

  const dv = new DataView().source(data);
  if (seriesGroup && seriesGroup?.length > 0) {
    const arr: string[] = [];
    const groups: any = {};
    seriesGroup.forEach((item, index) => {
      item.series.forEach((serie) => arr.push(serie.yField?.fieldName || ''));
      item.series.forEach((serie) =>
        Object.assign(groups, { [serie.yField?.fieldName || '']: `grupo${index}` })
      );
    });
    dv.transform({
      type: 'fold',
      fields: arr,
      key: 'stack',
      value: 'value',
    });
    dv.transform({
      type: 'map',
      callback(row) {
        return {
          ...row,
          group: groups[row.stack],
        };
      },
    });
  }

  useEffect(() => {
    const dvMax = new DataView().source(dv.rows);
    if (seriesGroup && seriesGroup?.length > 0) {
      dvMax.transform({
        type: 'aggregate',
        fields: ['value'],
        operations: ['max'],
        as: ['max'],
        groupBy: ['stack'],
      });
      dvMax.transform({
        type: 'aggregate',
        fields: ['max'],
        operations: ['sum'],
        as: ['max'],
        groupBy: ['group'],
      });
      setMax(Math.max(...dvMax.rows.map((row) => row.max)));
    } else {
      dvMax.transform({
        type: 'fold',
        fields: series.map((serie) => serie.yField?.fieldName || ''),
        key: 'value',
        value: 'value',
      });
      dvMax.transform({
        type: 'aggregate',
        fields: ['value'],
        operations: ['max'],
        as: ['max'],
        groupBy: ['value'],
      });
      setMax(Math.max(...dvMax.rows.map((row) => row.max)));
    }
  }, [DataView, dv.rows, series, seriesGroup]);

  const colorPicker = (index: number) => {
    const colors = [
      '#008685',
      '#F6903D',
      '#9661BC',
      '#78D3F8',
      '#7262fd',
      '#F6BD16',
      '#65789B',
      '#61DDAA',
      '#5B8FF9',
    ];
    return colors[index % colors.length];
  };

  const plots = [
    {
      type: 'column',
      options: {
        data: dv.rows || [],
        xAxis: false,
        yAxis: { max: max },
        label: {
          position: 'middle',
          layout: [
            {
              type: 'interval-adjust-position',
            },
            {
              type: 'interval-hide-overlap',
            },
            {
              type: 'adjust-color',
            },
          ],
        },
        xField: xField,
        yField: 'value',
        isStack: true,
        isGroup: true,
        seriesField: 'stack',
        groupField: 'group',
      },
    },
    ...series.map((item, index): any => {
      const color = colorPicker(index);
      return {
        type: item.type,
        options: {
          xAxis: index === 0,
          yAxis: { max: max },
          label: {
            style: {
              fill: color,
              fontWeight: 700,
              stroke: '#fff',
              lineWidth: item.type === 'line' ? 5 : 0,
            },
          },
          annotations: item.circleIntersection
            ? data.map((d) => {
                return {
                  type: 'dataMarker',
                  position: d,
                  point: {
                    style: {
                      stroke: color,
                    },
                  },
                };
              })
            : undefined,

          color: color,
          data: data || [],
          xField: item.xField?.fieldName || '',
          yField: item.yField?.fieldName || '',
          meta: {
            [item.xField?.fieldName || '']: {
              nice: true,
              tickCount: 5,
            },
            [item.yField?.fieldName || '']: {
              nice: true,
              sync: true,
              alias: maskCamelCase(item.yField?.fieldName || ''),
            },
          },
        },
      };
    }),
  ];

  const config = {
    appendPadding: 8,
    tooltip: {
      shared: true,
    },
    syncViewPadding: true,
    plots: plots,
    onReady: (plot: any) => {
      plot.on('element:click', ({ data: { data } }: any) => {
        onClick(data);
      });
    }
  };

  return data.length === 0 || series.length === 0 ? (
    <EmptyChart type={'mix'} />
  ) : (
    <div style={{ padding: '20px' }}>
      <h2 style={{ color: layout?.color.dark }}>{title}</h2>
      <Mix {...config} />
    </div>
  );
};

export default MixChart;
