import React, { Dispatch, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Action } from 'typesafe-actions';
import { useHistory } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import ServiceOrdersTable from './ServiceOrdersTable';
import ServiceOrdersSearch from './ServiceOrdersSearch';
import { ApplicationState } from '../../store';
import { loadServiceOrdersRequest } from './../../store/ducks/serviceOrders/actions';
import { Container, ServiceOrdersHeader } from './styles';
import { useWindowSize } from '../../customHooks';
import { Button } from 'antd';

const ServiceOrders: React.FC = () => {
  const token = useSelector((state: ApplicationState) => state.auth.token);
  const [searchValue, setSearchValue] = useState<string>('');
  const history = useHistory();
  const size = useWindowSize();
  const dispatch = useDispatch<Dispatch<Action>>();
  useEffect(() => {
    dispatch(loadServiceOrdersRequest());
  }, [dispatch, token]);

  return (
    <Container>
      <ServiceOrdersHeader>
        <Button
          data-cy="btn-newOs"
          icon={<PlusOutlined />}
          block={(size.width ?? 0) <= 1024}
          type="primary"
          size="middle"
          onClick={() => {
            history.push('/home/ordens-servico/nova-ordem-servico');
          }}
        >
          Nova OS
        </Button>
        <ServiceOrdersSearch setSearch={setSearchValue} />
      </ServiceOrdersHeader>
      <ServiceOrdersTable search={searchValue} />
    </Container>
  );
};

export default ServiceOrders;
