import { ResponseError } from '../../../services/api';
import { Config, Data } from '../../../utils/newTableHelper';

export enum ServiceOrdersTypes {
  'LOAD_REQUEST' = '@serviceOrders/LOAD_REQUEST',
  'LOAD_SUCCESS' = '@serviceOrders/LOAD_SUCCESS',
  'LOAD_FAILURE' = '@serviceOrders/LOAD_FAILURE',
}

export interface Pagination {
  current: number;
  total: number;
  pageSize: number;
}

export interface ServiceOrdersState {
  readonly data: Data[];
  readonly config?: Config;
  readonly pagination?: Pagination;
  readonly loading: boolean;
  readonly error?: ResponseError;
} 
