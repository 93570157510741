import React, { Dispatch, useRef } from 'react';
import { Avatar, Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../store';
import { UploadOutlined, DeleteOutlined, UserOutlined } from '@ant-design/icons';
import Swal from 'sweetalert2';
import { Action } from 'typesafe-actions';
import { deletePhotoRequest, updatePhotoRequest } from '../../store/ducks/user/actions';
import { Container } from './styles';
import { Size, useWindowSize } from '../../customHooks';

const UploadUserPhoto: React.FC = () => {
  const photo = useSelector((state: ApplicationState) => state.user.photo);
  const layout = useSelector((state: ApplicationState) => state.layout.data);
  const error = useSelector((state: ApplicationState) => state.user.error);

  const dispatch = useDispatch<Dispatch<Action>>();
  const size: Size = useWindowSize();

  const inputFile: any = useRef(null);

  const onButtonClick = () => {
    inputFile.current.click();
  };

  const deletePhoto = () => {
    dispatch(deletePhotoRequest());
    if (error) {
      Swal.fire({
        title: 'Erro!',
        confirmButtonColor: layout?.color.default,
        html: 'Ocorreu um erro no servidor',
        icon: 'error',
      });
    } else {
      Swal.fire({
        title: 'Sucesso!',
        confirmButtonColor: layout?.color.default,
        html: 'Foto removida com sucesso',
        icon: 'success',
      });
    }
  };

  const onDelete = () => {
    Swal.fire({
      title: 'Atenção!',
      text: 'Deseja realmente remover a foto do usuário?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: layout?.color.default,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
    }).then((result) => {
      result.value && deletePhoto();
    });
  };

  const confirmUpdateUserPhoto = (file: File, image: string) => {
    Swal.fire({
      title: 'Deseja realmente alterar a foto do usuário?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      confirmButtonColor: layout?.color.default,
    }).then((result) => {
      result.value && dispatch(updatePhotoRequest({ file: file, image: image }));
    });
  };

  const onChange = (event: any) => {
    const f = event.target.files[0];
    const reader = new FileReader();
    reader.onload = ((f) => {
      return (e: any) => {
        const binaryData = e.target.result;
        const base64String = window.btoa(binaryData);
        confirmUpdateUserPhoto(f, base64String);
      };
    })(f);
    reader.readAsBinaryString(f);
  };

  return (
    <Container>
      {photo ? <Avatar size={150} src={photo} /> : <Avatar size={150} icon={<UserOutlined />} />}
      <input
        id="file"
        onChange={onChange}
        ref={inputFile}
        type="file"
        style={{ display: 'none' }}
      />
      <Button
        data-cy="btn-sendPhoto"
        type="default"
        block={(size?.width ?? 0) <= 1024}
        icon={<UploadOutlined />}
        onClick={onButtonClick}
      >
        Enviar foto
      </Button>
      <Button
        data-cy="btn-deletePhoto"
        danger
        block={(size?.width ?? 0) <= 1024}
        icon={<DeleteOutlined />}
        onClick={onDelete}
      >
        Excluir foto
      </Button>
    </Container>
  );
};

export default UploadUserPhoto;
