import { Reducer } from 'redux';
import { ProductState, ProductTypes } from './types';

const INITIAL_STATE: ProductState = {
  data: [],
  relatedProducts: [],
  images: [],
  thumbnails: [],
  loading: false,
};

const reducer: Reducer<ProductState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ProductTypes.LOAD_REQUEST:
      return {
        ...state,
        loading: true,
        images: [],
        thumbnails: [],
      };
    case ProductTypes.LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        ...action.payload,
      };
    case ProductTypes.LOAD_FAILURE:
      return {
        ...state,
        data: [],
        loading: false,
        error: action.payload.error,
      };
    case ProductTypes.SET_UNITY:
      return {
        ...state,
        loading: true,
      };
    case ProductTypes.UNITY_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        data: action.payload.data,
      };

    default:
      return state;
  }
};

export default reducer;
