import { Col, Row } from 'antd';
import styled from 'styled-components';
import { colors } from '../../utils/constants';

export const Header = styled.div`
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding: 0;
  }
  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0;
  }
`;

export const ValueText = styled.h3`
  font-weight: bold;
  white-space: nowrap;
  color: green;
  margin: 0;
`;

export const FooterTitle = styled.small`
  color: ${colors.writeGrey};
`;

export const FooterCol = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Footer = styled(Row)`
  display: flex;
  justify-content: space-evenly;
`;
export const ListItem = styled.div`
  width: 100%;
  .ant-list-item-extra {
    margin: 0;
  }
`;

export const MessageAlert = styled.small`
  margin-top: 5px;
  color: ${colors.danger};
`;

export const ImageContainer = styled.div`
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
