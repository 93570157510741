import { Reducer } from 'redux';
import { DataSheetState, DataSheetTypes } from './types';

const INITIAL_STATE: DataSheetState = {
  config: [],
  data: [],
  pagination: undefined,
  loading: false,
  categories: [],
  loadingCategories: false,
};

const reducer: Reducer<DataSheetState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DataSheetTypes.LOAD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DataSheetTypes.LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        data: action.payload.data,
        config: action.payload.config,
        pagination: action.payload.pagination,
        placeholder: action.payload.placeholder,
      };
    case DataSheetTypes.LOAD_FAILURE:
      return {
        ...state,
        data: [],
        loading: false,
        error: action.payload.error,
      };
    case DataSheetTypes.LOAD_REQUEST_CATEGORY:
      return {
        ...state,
        loadingCategories: true,
      };
    case DataSheetTypes.LOAD_SUCCESS_CATEGORY:
      return {
        ...state,
        loadingCategories: false,
        errorCategory: undefined,
        categories: action.payload.data,
      };
    case DataSheetTypes.LOAD_FAILURE_CATEGORY:
      return {
        ...state,
        categories: [],
        loadingCategories: false,
        errorCategory: action.payload.error,
      };
    default:
      return state;
  }
};

export default reducer;
