import React from 'react';
import { Steps } from 'antd';
import { ServiceOrderTemplateStep } from '../../store/ducks/newServiceOrder/types';
import { Size, useWindowSize } from '../../customHooks';
import { Container } from './styles';

const { Step } = Steps;

export interface Props {
  steps?: ServiceOrderTemplateStep[];
  children?: React.ReactNode;
  current: number;
}

const CustomSteps: React.FC<Props> = ({ steps, children, current }) => {
  const size: Size = useWindowSize();

  return (
    <>
      <Container>
        <Steps current={current} labelPlacement="vertical" size="small">
          {steps?.map((step, index) => {
            return <Step key={step.id} title={(size.width || 0) > 720 || index === current ? step.label : ''} />;
          })}
        </Steps>
      </Container>
      {children}
    </>
  );
};

export default CustomSteps;
