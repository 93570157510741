import styled from "styled-components";

export const Container = styled.div`
  .ant-col {
    display: flex;
    align-items: stretch;
    position: relative;
    max-width: 100%;
    min-height: 1px;
    min-height: 100%;
  }

  .ant-col > .ant-list-item {
    display: flex;
    align-items: stretch;
    max-width: 100%;
    min-width: 100%;
    margin-bottom: 16px;
    padding-top: 0;
    padding-bottom: 0;
    border-bottom: none;
  }
`;
