import styled from 'styled-components';
import { Layout } from '../../store/ducks/layout/types';

export const CampaignTitle = styled.a<{ layout?: Layout }>`
  color: ${({ layout }) => layout?.color.default} !important;
  font-weight: 400;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: inherit;
`;
export const CampaignDescription = styled.pre`
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,
    'Open Sans', 'Helvetica Neue', sans-serif;
  white-space: pre-wrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
`;
