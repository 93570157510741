import { Reducer } from 'redux';
import { AuthState, AuthTypes } from './types';

const INITIAL_STATE: AuthState = {
  refreshLoading: false,
  forgotPasswordMessage: '',
  token: '',
  refreshToken: '',
  errorMessage: '',
  loginError: false,
  loginLoading: false,
  passwordLoading: false,
  passwordError: false,
  statusFailure: 0,
  userName: '',
  changePasswordMessage: '',
  changePasswordLoading: false,
  changePasswordError: false,
  successMessage: '',
  newPasswordLoading: false,
  newPasswordError: false,
};

const reducer: Reducer<AuthState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AuthTypes.REFRESH_REQUEST:
      return {
        ...state,
        refreshLoading: true,
      };
    case AuthTypes.LOGIN_REQUEST:
      return {
        ...state,
        loginLoading: true,
      };
    case AuthTypes.LOGIN_SUCCESS:
      return {
        ...state,
        loginLoading: false,
        loginError: false,
        ...action.payload,
      };
    case AuthTypes.REFRESH_SUCCESS:
      return {
        ...state,
        refreshLoading: false,
        ...action.payload,
      };
    case AuthTypes.LOGIN_FAILURE:
      return {
        ...state,
        loginLoading: false,
        loginError: true,
        token: '',
        ...action.payload,
      };

    case AuthTypes.UPDATE_REQUEST:
      return {
        ...state,
        loginLoading: true,
      };
    case AuthTypes.UPDATE_SUCCESS:
      return {
        ...state,
        loginLoading: false,
        loginError: false,
        ...action.payload,
      };
    case AuthTypes.UPDATE_FAILURE:
      return {
        ...state,
        loginLoading: false,
        loginError: true,
        token: '',
        errorMessage: action.payload.errorMessage,
      };

    case AuthTypes.PASSWORD_REQUEST:
      return {
        ...state,
        passwordLoading: true,
        passwordError: false,
      };
    case AuthTypes.PASSWORD_SUCCESS:
      return {
        ...state,
        passwordLoading: false,
        passwordError: false,
        forgotPasswordMessage: action.payload.forgotPasswordMessage,
      };
    case AuthTypes.PASSWORD_FAILURE:
      return {
        ...state,
        passwordLoading: false,
        passwordError: true,
        forgotPasswordMessage: '',
        errorMessage: action.payload.errorMessage,
      };

    case AuthTypes.CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        changePasswordLoading: true,
        changePasswordError: false,
      };
    case AuthTypes.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        changePasswordLoading: false,
        changePasswordError: false,
        changePasswordMessage: action.payload.changePasswordMessage,
      };
    case AuthTypes.CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        changePasswordLoading: false,
        changePasswordError: true,
        changePasswordMessage: '',
        errorMessage: action.payload.errorMessage,
      };

    case AuthTypes.NEW_PASSWORD_REQUEST:
      return {
        ...state,
        newPasswordLoading: true,
        newPasswordError: false,
      };
    case AuthTypes.NEW_PASSWORD_SUCCESS:
      return {
        ...state,
        newPasswordLoading: false,
        newPasswordError: false,
        successMessage: action.payload.successMessage,
      };
    case AuthTypes.NEW_PASSWORD_FAILURE:
      return {
        ...state,
        newPasswordLoading: false,
        newPasswordError: true,
        successMessage: '',
        errorMessage: action.payload.errorMessage,
      };

    case AuthTypes.CLEAN_STATE:
    case AuthTypes.REFRESH_FAILURE:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default reducer;
