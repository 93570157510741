import { ResponseError } from '../../../services/api';

export enum DataSheetTypes {
  'LOAD_REQUEST' = '@datasheet/LOAD_REQUEST',
  'LOAD_SUCCESS' = '@datasheet/LOAD_SUCCESS',
  'LOAD_FAILURE' = '@datasheet/LOAD_FAILURE',

  'LOAD_REQUEST_CATEGORY' = '@datasheet/LOAD_REQUEST_CATEGORY',
  'LOAD_SUCCESS_CATEGORY' = '@datasheet/LOAD_SUCCESS_CATEGORY',
  'LOAD_FAILURE_CATEGORY' = '@datasheet/LOAD_FAILURE_CATEGORY',
}

export interface Pagination {
  current: number;
  total: number;
  pageSize: number;
}

export interface DataSheetState {
  readonly data: any;
  readonly config: any;
  readonly pagination?: Pagination;
  readonly loading: boolean;
  readonly placeholder?: string;
  readonly loadingCategories: boolean;
  readonly categories: any[];
  readonly error?: ResponseError;
  readonly errorCategory?: ResponseError;
}
