import styled from "styled-components";
import { Layout } from "../../../store/ducks/layout/types";

export const BannersCarousel = styled.div<{ layout?: Layout }>`
  .ant-carousel .slick-dots li button {
    background: ${({ layout }) => layout?.color.dark};
    height: 15px;
    opacity: 0.3;
  }

  .ant-carousel .slick-dots li.slick-active button {
    background: ${({ layout }) => layout?.color.default};
    opacity: 1;
    height: 15px;
  }
`;
