import { Badge, Col, Collapse, Row } from 'antd';
import React from 'react';
import { DownOutlined } from '@ant-design/icons';
import { ApplicationState } from '../../store';
import { useSelector } from 'react-redux';
import { PanelTitle } from './styles';

const { Panel } = Collapse;

export interface Props {
  title: string;
  icon: React.ReactNode;
  children: React.ReactNode;
  hasNotification?: boolean;
  dot?: boolean;
  defaultOpened?: boolean;
}

const CustomCollapse: React.FC<Props> = ({
  title,
  icon,
  children,
  dot,
  hasNotification,
  defaultOpened,
}) => {
  const layout = useSelector((state: ApplicationState) => state.layout.data);
  return (
    <Collapse
      expandIconPosition="right"
      defaultActiveKey={defaultOpened ? 1 : undefined}
      ghost
      expandIcon={({ isActive }) => (
        <DownOutlined
          rotate={isActive ? 180 : 0}
          style={{ fontSize: '24px', color: layout?.color.default }}
        />
      )}
    >
      <Panel
        header={
          <Row gutter={10}>
            {hasNotification && (
              <Col>
                <Badge offset={[0, 15]} size="default" dot={dot} />
              </Col>
            )}
            <Col>
              <PanelTitle layout={layout}>
                {icon} {title}
              </PanelTitle>
            </Col>
          </Row>
        }
        key={1}
      >
        {children}
      </Panel>
    </Collapse>
  );
};

export default CustomCollapse;
