import { AxiosResponse } from 'axios';
import { call, put, select } from 'redux-saga/effects';
import { ApplicationState } from '../..';
import api, { errorHandler } from '../../../services/api';
import { requestHeaders } from '../../../utils/apiHelper';
import { refreshRequest } from '../auth/actions';
import { loadFieldsFailure, loadFieldsSuccess } from './actions';
import { FormFields } from './types';

export function* loadBudgetFields() {
  try {
    const state: ApplicationState = yield select();
    const headers = requestHeaders(state.auth.token);
    const response: AxiosResponse<FormFields[]> = yield call(api.get, 'new-budget/form', {
      headers: headers,
    });
    yield put(
      loadFieldsSuccess(
        response.data.sort((a: any, b: any) => {
          if (a.position < b.position) {
            return -1;
          }
          if (a.position > b.position) {
            return 1;
          }
          return 0;
        })
      )
    );
  } catch (err: any) {
    yield put(
      err.response.status !== 401 ? loadFieldsFailure(errorHandler(err)) : refreshRequest()
    );
  }
}
