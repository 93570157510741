import React, { Dispatch, useEffect, useState } from 'react';
import { Button, Col, Form, Input, message, Row, Switch, Upload } from 'antd';
import Swal from 'sweetalert2';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Action } from 'typesafe-actions';

import { PlusOutlined, LoadingOutlined, SaveOutlined } from '@ant-design/icons';
import { ApplicationState } from '../../../store';
import { baseURL, errorHandler } from '../../../services/api';
import { FormContainer } from '../styles';
import { clearSend, sendUserRequest, setError } from '../../../store/ducks/newUser/actions';
import { useWindowSize } from '../../../customHooks';
import { RcFile } from 'antd/lib/upload';
import { getBase64 } from '../../../utils/image';
import { Image, User } from '../../../store/ducks/newUser/types';
import { MESSAGES } from '../../../utils/constants';

const UserForm: React.FC = () => {
  const layout = useSelector((state: ApplicationState) => state.layout.data);
  const data = useSelector((state: ApplicationState) => state.newUser.dataUser);
  const saved = useSelector((state: ApplicationState) => state.newUser.saved);
  const dataSend = useSelector((state: ApplicationState) => state.newUser.dataSend);
  const loadingSend = useSelector((state: ApplicationState) => state.newUser.loadingSend);
  const error = useSelector((state: ApplicationState) => state.newUser.errorSend);
  const [image, setImage] = useState<string>('');
  const [imageResponse, setImageResponse] = useState<Image | undefined>(undefined);
  const [uploading, setUploading] = useState<boolean>(false);
  const { id }: any = useParams();
  const [form] = Form.useForm();
  const history = useHistory();
  const dispatch = useDispatch<Dispatch<Action>>();
  const size = useWindowSize();

  const handleChange = (info: any) => {
    if (info.file.status === 'uploading') {
      setImage('');
      setUploading(true);
      return;
    }
    if (info.file.status === 'done') {
      setImageResponse({ description: info.file.name, attachment: info.file.response.attachment });
      getBase64(info.file.originFileObj, (imageUrl: string) => {
        setImage(imageUrl);
        setUploading(false);
      });
    }
    if (info.file.status === 'error') {
      setUploading(false);
      message.error(errorHandler(info.file.response).message);
    }
  };

  const beforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('Só é permitido arquivos do tipo jpeg ou png!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('A foto deve ter menos 2MB!');
    }
    return isJpgOrPng && isLt2M;
  };
  const uploadButton = (
    <div>
      {uploading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  useEffect(() => {
    if (!data || !id) {
      return;
    }
    form.setFieldsValue(data);
    setImage(`${baseURL}/access-control/user/${id}/image`);
  }, [data, form, id]);

  useEffect(() => {
    if (!error) {
      return;
    }
    Swal.fire({
      title: 'Erro!',
      confirmButtonColor: layout?.color.default,
      text: error.message,
      icon: 'error',
    });
    Swal.fire({
      title: 'Erro!',
      text: error.message,
      confirmButtonColor: layout?.color.default,
      icon: 'error',
    }).then(() => {
      dispatch(setError(undefined));
    });
  }, [error, dispatch, layout?.color.default]);

  useEffect(() => {
    if (!saved) {
      return;
    }
    const textFire = `Usuário (${dataSend}) editado com sucesso!`;
    Swal.fire({
      title: 'Sucesso!',
      confirmButtonColor: layout?.color.default,
      text: `${textFire}`,
      icon: 'success',
      confirmButtonText: 'Ok',
    }).then(() => {
      history.goBack();
      dispatch(clearSend());
    });
  }, [saved, dispatch, id, dataSend, history, layout?.color.default]);

  const onFinish = (values: any) => {
    const user: User = { ...values, image: imageResponse, userId: id };
    dispatch(sendUserRequest(user, id));
  };

  return (
    <Form form={form} onFinish={onFinish} layout="vertical">
      <FormContainer>
        <h2>Usuário</h2>
        <Row gutter={[20, 20]}>
          <Col xs={24} sm={24} md={24} lg={4} xl={4}>
            <Form.Item label="Foto" name="attachment">
              <Upload
                name="image"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                action={`${baseURL}/access-control/image/upload`}
                beforeUpload={beforeUpload}
                onChange={handleChange}
              >
                {image ? (
                  <img
                    src={image}
                    alt="avatar"
                    style={{ maxWidth: '100%', maxHeight: '100%', padding: '5px' }}
                  />
                ) : (
                  uploadButton
                )}
              </Upload>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={10} xl={10}>
            <Form.Item
              rules={[{ required: true, message: `Por favor informe um nome!` }]}
              label="Nome"
              name="name"
            >
              <Input data-cy="input-name" />
            </Form.Item>
            <Form.Item
              label="Senha"
              name="password"
              rules={[
                {
                  required: true,
                  message: 'Por favor informe uma senha!',
                },
                () => ({
                  validator(_, value: string) {
                    if (
                      !value ||
                      value.match(/^(?=.{8,}$)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).*$/)
                    ) {
                      return Promise.resolve();
                    }
                    return Promise.reject(MESSAGES.PASSWORD_ERROR);
                  },
                }),
              ]}
            >
              <Input.Password data-cy="input-password" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={10} xl={10}>
            <Form.Item
              rules={[{ required: true, message: `Por favor informe um e-mail!` }]}
              label="E-mail"
              name="email"
            >
              <Input data-cy="input-email" type="email" disabled={id} />
            </Form.Item>
            <Form.Item label="Ativo" name="active" initialValue={true} valuePropName="checked">
              <Switch data-cy="input-active" />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <Row justify="end">
            <Button
              data-cy="btn-saveUser"
              icon={<SaveOutlined />}
              block={(size.width ?? 0) <= 1024}
              htmlType="submit"
              type="primary"
              loading={loadingSend}
            >
              Salvar
            </Button>
          </Row>
        </Form.Item>
      </FormContainer>
    </Form>
  );
};

export default UserForm;
