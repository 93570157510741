import Color from 'color';
import styled from 'styled-components';
import { Layout } from '../../store/ducks/layout/types';
import { colors } from '../../utils/constants';

export const StyledTable = styled.div<{ noShadow?: boolean; layout?: Layout }>`
  background-color: white;
  border-radius: 5px;
  .active-row {
    background-color: ${({ layout }) =>
      Color(layout?.color.default).fade(0.95).toString()} !important;
    .ant-table-cell-row-hover {
      background-color: ${({ layout }) =>
        Color(layout?.color.default).fade(0.90).toString()} !important;
    }
  }
  box-shadow: ${({ noShadow }) => (!noShadow ? `1px 2px 2px 2px ${colors.grey}` : '')};
  .table-column {
    max-width: 300px !important;
  }
  .ant-table-summary {
    background: ${colors.grayBackgroud};
  }
`;
