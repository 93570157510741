import React, { Dispatch, useEffect } from 'react';
import { Action } from 'typesafe-actions';
import { useDispatch, useSelector } from 'react-redux';

import { ApplicationState } from '../../store';
import { requestReports } from '../../store/ducks/reports/actions';
import { Container } from '../../styles';
import ReportsList from './ReportsList';

const Reports: React.FC = () => {
  const layout = useSelector((state: ApplicationState) => state.layout.data);
  const token = useSelector((state: ApplicationState) => state.auth.token);

  const dispatch = useDispatch<Dispatch<Action>>();
  useEffect(() => {
    dispatch(requestReports());
  }, [dispatch, token]);
  return (
    <Container layout={layout}>
      <ReportsList />
    </Container>
  );
};

export default Reports;
