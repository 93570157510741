import React, { Dispatch, FC, useEffect, useState } from 'react';
import { Button, Modal, Row, Col, Tooltip, Select, Input } from 'antd';
import { DeleteFilled } from '@ant-design/icons';
import Swal from 'sweetalert2';
import { maskCamelCase, maskCurrency } from '../../../utils/masks';
import { useDispatch, useSelector } from 'react-redux';
import { Action } from 'typesafe-actions';
import { ApplicationState } from '../../../store';
import { Grid } from '../../../store/ducks/shoppingCart/types';
import { addItemRequest, deleteItemRequest } from '../../../store/ducks/shoppingCart/actions';
import {
  ClearButton,
  DefaultButton,
  Footer,
  FooterCol,
  FooterTitle,
  SubmitButton,
} from '../styles';
import { Container } from '../../../styles';
import { Size, useWindowSize } from '../../../customHooks';
import Scrollbars from 'react-custom-scrollbars';
import CustomTable from '../../CustomTable';

interface GridDimension {
  abbreviation: string;
  description: string;
  options: GridOptions[];
}

interface GridOptions {
  abbreviation: string;
  description: string;
  containStock: boolean;
  price: number;
  stock?: number;
  control: string;
}
interface RecordGrid {
  idGrid: number;
  columnDescription: string;
  lineDescription: string;
  dimensions: GridDimension[];
}

interface GridRow {
  alias: string;
  description: string;
  hasStock?: boolean;
  value: number;
  control: string;
  stock?: number;
}

export interface GridColumn {
  alias: string;
  description: string;
  options: GridRow[];
}

interface Props {
  product: any;
  disabled?: boolean;
}

const { Option } = Select;

const ModalGrid: FC<Props> = ({ product, disabled }) => {
  const dispatch = useDispatch<Dispatch<Action>>();
  const layout = useSelector((state: ApplicationState) => state.layout.data);
  const { cart: shoppingCart } = useSelector((state: ApplicationState) => state.shoppingCart);
  const hasStock = useSelector((state: ApplicationState) => state.products.config?.details.showStock);

  const [visible, setVisible] = useState<boolean>(false);
  const [quantity, setQuantity] = useState<number>(0);
  const [columnLabel, setColumnLabel] = useState<string>('');
  const [rowLabel, setRowLabel] = useState<string>('');
  const [selectedRow, setSelectedRow] = useState<GridRow>();
  const [selectedCol, setSelectedCol] = useState<GridColumn>();
  const [columns, setColumns] = useState<GridColumn[]>([]);
  const [rows, setRows] = useState<GridRow[]>([]);
  const [grid, setGrid] = useState<Grid[]>([]);
  const [totalQuantity, setTotalQuantity] = useState<number>(0);
  const [totalValue, setTotalValue] = useState<number>(0);
  const size: Size = useWindowSize();

  const tableColumns = [
    {
      title: `${columnLabel}`,
      dataIndex: 'column',
      key: 'column',
    },
    {
      title: `${rowLabel}`,
      dataIndex: 'row',
      key: 'column',
    },
    {
      title: 'Quantidade',
      dataIndex: 'quantity',
      key: 'quantity',
    },
    {
      title: 'Vlr Unitário',
      dataIndex: 'value',
      key: 'value',
      hasTitle: true,
      render: (value: number) => <>{maskCurrency(value)}</>,
    },

    {
      title: 'Vlr Total',
      dataIndex: 'total',
      key: 'total',
      hasTitle: true,
      render: (total: number) => <>{maskCurrency(total)}</>,
    },
    {
      title: 'Ação',
      key: 'action',
      render: (record: any) => (
        <Tooltip placement="right" title="Remover item">
          <ClearButton
            data-cy="btn-clear"
            type="text"
            shape="circle"
            danger
            icon={<DeleteFilled />}
            onClick={() => {
              deleteGrid(record);
            }}
          />
        </Tooltip>
      ),
    },
  ];

  useEffect(() => {
    if (!shoppingCart.find((item) => item.codProd === product?.CODPROD)?.grid) {
      setTotalValue(0);
      setGrid([]);
    }
    setGrid(shoppingCart.find((item) => item.codProd === product?.CODPROD)?.grid || []);
  }, [product, shoppingCart]);

  useEffect(() => {
    setTotalQuantity(grid.reduce((total, item) => total + item.quantity, 0));
    setTotalValue(grid.reduce((total, item) => total + item.total, 0));
  }, [grid]);

  useEffect(() => {
    const recordGrid: RecordGrid = product.GRID;
    setColumnLabel(maskCamelCase(recordGrid.columnDescription));
    setRowLabel(maskCamelCase(recordGrid.lineDescription));
    const columns: GridColumn[] = recordGrid.dimensions.map((col) => {
      return {
        alias: col.abbreviation,
        description: maskCamelCase(col.description),
        options: col.options.map((row) => {
          return {
            alias: row.abbreviation,
            description: maskCamelCase(row.description),
            hasStock: row.containStock,
            value: row.price,
            control: row.control,
            stock: row.stock,
          };
        }),
      };
    });
    if (columns.length === 0) {
      return;
    }
    setColumns(columns);
    setRows(columns[0].options);
    setSelectedRow(columns[0].options[0]);
    setSelectedCol(columns[0]);
  }, [product]);

  useEffect(() => {
    setSelectedRow(selectedCol?.options[0]);
    setRows(selectedCol?.options ?? []);
  }, [selectedCol]);

  const handleModalVisibility = () => {
    setVisible(!visible);
  };

  const handleColChange = (value: string) => {
    setSelectedCol(columns.find((col) => col.alias === value));
  };

  const handleRowChange = (value: string) => {
    setSelectedRow(rows.find((row) => row.alias === value));
  };

  const onChangeQuantity = (e: any) =>
    setQuantity(Math.floor(e.target.value <= 0 ? 0 : e.target.value));

  const deleteGrid = (record: any) => {
    const newGrid = grid?.filter((item) => item.index !== record.index);
    setGrid(newGrid);

    if (shoppingCart.find((item) => item.codProd === product.CODPROD)?.grid) {
      if (newGrid?.length === 0) {
        dispatch(
          deleteItemRequest({
            codProd: product?.CODPROD,
            quantity: 0,
            grid: newGrid,
            description: product?.DESCRPROD,
            value: newGrid.reduce((total, item) => total + item.total, 0),
            unity: product?.UNIDADE[0],
            isPromo: product?.PROMOCAO?.isPromo,
          })
        );
      } else {
        dispatch(
          addItemRequest({
            unity: product?.UNIDADE[0],
            codProd: product?.CODPROD,
            quantity: newGrid.reduce((total, item) => total + item.quantity, 0),
            grid: newGrid,
            description: product?.DESCRPROD,
            value: newGrid.reduce((total, item) => total + item.total, 0),
            isPromo: product?.PROMOCAO?.isPromo,
            controlType: product?.DETAILS?.stockControlType
          })
        );
      }
    }
  };

  const SendToShoppingCart = () => {
    handleModalVisibility();
    dispatch(
      addItemRequest({
        codProd: product?.CODPROD,
        unity: product?.UNIDADE[0],
        quantity: totalQuantity,
        grid: grid,
        description: product?.DESCRPROD,
        value: totalValue,
        isPromo: product?.PROMOCAO?.isPromo,
        controlType: product?.DETAILS?.stockControlType
      })
    );
  };

  const addGrid = () => {
    const exists = grid.find((e) => e.index === selectedRow?.control);
    if (exists) {
      Swal.fire({
        title: `${columnLabel} e ${rowLabel} já adicionados, deseja continuar?`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: layout?.color.default,
        confirmButtonText: 'Continuar',
        cancelButtonText: 'Cancelar',
      }).then((result) => {
        if (result.isConfirmed) {
          const arr = grid?.map((item) => {
            return item.index === selectedRow?.control
              ? {
                ...item,
                quantity: quantity + item.quantity,
                total: (selectedRow?.value ?? 0) * (quantity + item.quantity ?? 0),
              }
              : item;
          });
          setGrid(arr);
        }
      });
    } else {
      setGrid((oldArray) => [
        ...oldArray,
        {
          index: selectedRow?.control,
          row: selectedRow?.description,
          column: selectedCol?.description,
          quantity: quantity,
          value: selectedRow?.value,
          total: (selectedRow?.value ?? 0) * (quantity ?? 0),
          columnLabel: columnLabel,
          rowLabel: rowLabel,
        },
      ]);
    }
    setQuantity(0);
  };
  return (
    <>
      <Button
        data-cy="btn-add"
        disabled={disabled}
        type="link"
        onClick={handleModalVisibility}
        style={{ padding: 0 }}
      >
        ADICIONAR
      </Button>
      <Modal
        data-cy="modal-grid"
        centered
        width={700}
        visible={visible}
        title={`Produto: ${product?.DESCRPROD}`}
        onCancel={handleModalVisibility}
        footer={[
          <DefaultButton
            data-cy="btn-cancel"
            layout={layout}
            key="cancel"
            onClick={handleModalVisibility}
          >
            Cancelar
          </DefaultButton>,
          <SubmitButton
            data-cy="btn-addToCart"
            layout={layout}
            key="submit"
            type="primary"
            disabled={totalQuantity <= 0}
            onClick={SendToShoppingCart}
          >
            Adicionar ao carrinho
          </SubmitButton>,
        ]}
      >
        <Container layout={layout}>
          <Row gutter={[8, 8]} style={{ alignItems: 'flex-end' }}>
            <Col xs={24} sm={24} md={12} lg={6} xl={6}>
              <strong>{columnLabel.slice(0, columnLabel.length - 1)}:</strong>
              <Select
                data-cy="input-col"
                style={{ width: '100%' }}
                onChange={handleColChange}
                value={selectedCol?.alias}
              >
                {columns.map((col) => (
                  <Option data-cy="colOption" value={col?.alias}>
                    {col?.description} ({col?.alias})
                  </Option>
                ))}
              </Select>
            </Col>
            <Col xs={24} sm={24} md={12} lg={7} xl={7}>
              <strong>{rowLabel.slice(0, rowLabel.length - 1)}:</strong>
              <Select
                data-cy="input-row"
                style={{ width: '100%' }}
                value={selectedRow?.alias}
                onChange={handleRowChange}
              >
                {selectedCol?.options.map((row) => (
                  <Option data-cy="rowOption" disabled={row.value === 0} value={row?.alias}>
                    {row.value === 0 ? (
                      <>Indisponível</>
                    ) : (
                      <>
                        {row?.description} ({maskCurrency(row?.value)})
                      </>
                    )}
                    {hasStock && (
                      <>
                        <br />
                        <small>{`Disponível: ${(row?.stock || 0) -
                          (grid.find((item) => item.index === row?.control)?.quantity || 0)
                          }`}</small>
                      </>
                    )}
                  </Option>
                ))}
              </Select>
            </Col>
            <Col xs={24} sm={24} md={12} lg={7} xl={7}>
              <strong>Quantidade:</strong>
              <Row>
                <Input
                  data-cy="input-quantity"
                  addonAfter={
                    hasStock && (
                      <>
                        Disponível:{' '}
                        <strong>
                          {(selectedRow?.stock || 0) -
                            (grid.find((item) => item.index === selectedRow?.control)?.quantity ||
                              0)}
                        </strong>
                      </>
                    )
                  }
                  style={{ width: '100%' }}
                  type="number"
                  placeholder="Informe a quantidade"
                  value={quantity}
                  onChange={onChangeQuantity}
                />
              </Row>
            </Col>
            <Col xs={24} sm={24} md={12} lg={4} xl={4}>
              {!(
                (selectedRow?.stock || 0) -
                (grid.find((item) => item.index === selectedRow?.control)?.quantity || 0) <
                (quantity ?? 0)
              ) || !hasStock ? (
                <Button
                  data-cy="btn-addGrid"
                  style={{ width: '100%' }}
                  type="default"
                  onClick={addGrid}
                  block={(size?.width ?? 0) < 1024}
                  disabled={quantity === 0}
                >
                  Adicionar
                </Button>
              ) : (
                <Tooltip title={'Estoque insuficiente'}>
                  <Button
                    data-cy="btn-addGrid"
                    style={{ width: '100%' }}
                    disabled
                    type="default"
                    block={(size?.width ?? 0) < 1024}
                    onClick={addGrid}
                  >
                    Adicionar
                  </Button>
                </Tooltip>
              )}
            </Col>
          </Row>
          <div style={{ height: '200px' }}>
            <Scrollbars>
              <CustomTable loading={false} onChange={() => { }} data={grid} columns={tableColumns} />
            </Scrollbars>
          </div>
          <Footer>
            <FooterCol data-cy="totalQtd">
              <FooterTitle>QTD TOTAL</FooterTitle>
              <strong>{totalQuantity}</strong>
            </FooterCol>
            <FooterCol data-cy="totalValue">
              <FooterTitle>VALOR TOTAL</FooterTitle>
              <strong>{maskCurrency(totalValue)}</strong>
            </FooterCol>
          </Footer>
        </Container>
      </Modal>
    </>
  );
};

export default ModalGrid;
