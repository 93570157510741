import { ResponseError } from "../../../services/api";
import { Config, Data } from "../../../utils/newTableHelper";

export enum HomeFinancialsTypes {
  'LOAD_REQUEST' = '@homeFinancials/LOAD_REQUEST',
  'LOAD_SUCCESS' = '@homeFinancials/LOAD_SUCCESS',
  'LOAD_FAILURE' = '@homeFinancials/LOAD_FAILURE',
}

export interface HomeFinancialsState {
  readonly data: Data[];
  readonly config?: Config;
  readonly loading: boolean;
  readonly error?: ResponseError;
}
