import React, { Dispatch, useEffect } from 'react';
import { Button, List, Avatar, Card } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Action } from 'typesafe-actions';
import { useHistory } from 'react-router-dom';
import { LoadingOutlined, RightOutlined } from '@ant-design/icons';

import { ApplicationState } from '../../../store';
import ServerError from '../../../components/ServerError';
import { requestHomeCampaigns } from '../../../store/ducks/homeCampaingns/actions';
import { CampaignDescription, CampaignTitle, Container } from '../styles';
import { colors } from '../../../utils/constants';

const CampaignsList: React.FC = () => {
  const data = useSelector((state: ApplicationState) => state.homeCampaigns.data);
  const error = useSelector((state: ApplicationState) => state.homeCampaigns.error);
  const loading = useSelector((state: ApplicationState) => state.homeCampaigns.loading);
  const layout = useSelector((state: ApplicationState) => state.layout.data);
  const token = useSelector((state: ApplicationState) => state.auth.token);
  const history = useHistory();
  const dispatch = useDispatch<Dispatch<Action>>();

  useEffect(() => {
    dispatch(requestHomeCampaigns());
  }, [dispatch, token]);
  return (
    <Card
      style={{ borderRadius: '5px', boxShadow: `1px 2px 2px 2px ${colors.grey}` }}
      actions={[
        <Button
          data-cy="btn-redirectCampaign"
          onClick={() => history.push('/home/campanhas')}
          type="link"
          block
          size="small"
        >
          Ver todas as campanhas <RightOutlined type="right" />
        </Button>,
      ]}
      bodyStyle={{ padding: '0 24px', minHeight: '276px' }}
      title={
        <CampaignTitle layout={layout} style={{ margin: '-4px 0' }}>
          Últimas campanhas
        </CampaignTitle>
      }
    >
      <Container>
        {error ? (
          <ServerError {...error} />
        ) : (
          <List
            itemLayout="horizontal"
            size="small"
            loading={{
              style: { color: layout?.color.default },
              spinning: loading,
              size: 'large',
              delay: 500,
              indicator: <LoadingOutlined style={{ fontSize: 24 }} spin />,
            }}
            dataSource={data}
            renderItem={(item) => (
              <List.Item key={item.campaignId} style={{ padding: '10px 0' }}>
                <List.Item.Meta
                  avatar={
                    <Avatar
                      shape="square"
                      size={70}
                      src={
                        item.type === 'BASE64' ? `data:image/png;base64,${item.image}` : item.link
                      }
                    />
                  }
                  title={
                    <CampaignTitle
                      onClick={() => {
                        history.push(`/home/campanhas/${item.campaignId}`);
                      }}
                      layout={layout}
                    >
                      {item.title}
                    </CampaignTitle>
                  }
                  description={<CampaignDescription>{item.description}</CampaignDescription>}
                />
              </List.Item>
            )}
          />
        )}
      </Container>
    </Card>
  );
};

export default CampaignsList;
