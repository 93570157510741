import { Col, Divider, Drawer, Row } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../../store';
import CheckCollapse from '../../../../components/CheckCollapse';
import { Category } from '../../../../store/ducks/showCase/types';

interface Props {
  onClose: () => void;
  visible: boolean;
}

const DrawerMenu: React.FC<Props> = ({ onClose, visible }) => {
  const layout = useSelector((state: ApplicationState) => state.layout.data);
  const categories = useSelector((state: ApplicationState) => state.productsShowCase.categories);

  return (
    <Drawer
      title={<h3 style={{ color: layout?.color.dark, margin: 0 }}>Categorias</h3>}
      placement="left"
      onClose={onClose}
      width='300px'
      visible={visible}
    >
      <Row gutter={[10, 10]}>
        <Col span={24}>
          {categories.map((category: Category) => {
            return (
              <>
                <CheckCollapse category={category} />
                <Divider style={{ margin: 0 }} />
              </>
            );
          })}
        </Col>
      </Row>
    </Drawer>
  );
};

export default DrawerMenu;
