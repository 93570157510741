import { Reducer } from 'redux';
import { NewDeliveryPointTypes, NewDeliveryPointState } from './types';

const INITIAL_STATE: NewDeliveryPointState = {
  dataDeliveries: [],
  dataSend: '',
  loadingDeliveries: false,
  loadingSend: false,
  saved: false,
};

const reducer: Reducer<NewDeliveryPointState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case NewDeliveryPointTypes.DELIVERIES_REQUEST:
      return {
        ...state,
        loadingDeliveries: true,
      };
    case NewDeliveryPointTypes.DELIVERIES_SUCCESS:
      return {
        ...state,
        loadingDeliveries: false,
        errorDeliveries: undefined,
        dataDeliveries: action.payload.data,
      };
    case NewDeliveryPointTypes.DELIVERIES_FAILURE:
      return {
        ...state,
        dataDeliveries: [],
        loadingDeliveries: false,
        errorDeliveries: action.payload.error,
      };
    case NewDeliveryPointTypes.POINT_REQUEST:
      return {
        ...state,
      };
    case NewDeliveryPointTypes.POINT_SUCCESS:
      return {
        ...state,
        errorPoint: undefined,
        dataPoint: action.payload.data,
      };
    case NewDeliveryPointTypes.POINT_FAILURE:
      return {
        ...state,
        dataPoint: undefined,
        errorPoint: action.payload.error,
      };
    case NewDeliveryPointTypes.SEND_REQUEST:
      return {
        ...state,
        loadingSend: true,
      };
    case NewDeliveryPointTypes.SEND_SUCCESS:
      return {
        ...state,
        saved: true,
        loadingSend: false,
        errorSend: undefined,
        dataSend: action.payload.data,
      };
    case NewDeliveryPointTypes.SEND_FAILURE:
      return {
        ...state,
        dataSend: '',
        saved: false,
        loadingSend: false,
        errorSend: action.payload.error,
      };
    case NewDeliveryPointTypes.CLEAR_SEND:
      return {
        ...state,
        dataSend: '',
        loadingSend: false,
        saved: false,
        errorMessage: '',
        error: false,
      };
    case NewDeliveryPointTypes.SET_ERROR:
      return {
        ...state,
        error: action.payload.error,
        errorSend: action.payload.error,
      };
    default:
      return state;
  }
};

export default reducer;
