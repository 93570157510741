import { ResponseError } from "../../../services/api";
import { Config, Data, DataValues } from "../../../utils/newTableHelper";

export enum ServiceOrdersDetailTypes {
  'LOAD_REQUEST' = '@serviceOrdersDetail/LOAD_REQUEST',
  'LOAD_SUCCESS' = '@serviceOrdersDetail/LOAD_SUCCESS',
  'LOAD_FAILURE' = '@serviceOrdersDetail/LOAD_FAILURE',

  'DATA_REQUEST' = '@serviceOrdersDetail/DATA_REQUEST',
  'DATA_SUCCESS' = '@serviceOrdersDetail/DATA_SUCCESS',
  'DATA_FAILURE' = '@serviceOrdersDetail/DATA_FAILURE',
}

export interface Pagination {
  current: number;
  total: number;
  pageSize: number;
}

export interface ServiceOrdersDetailState {
  readonly data: Data[];
  readonly config?: Config;
  readonly pagination?: Pagination;
  readonly loading: boolean;
  readonly errorData?: ResponseError;
  readonly errorDetail?: ResponseError;
  readonly dataDetail: DataValues[];
  readonly configDetail?: Config;
}
