import Color from 'color';
import styled from 'styled-components';

export const Container = styled.div<{ color: string }>`
  padding: 1px 7px;

  border-radius: 25px;
  border: 2px solid ${({ color }) => Color(color).fade(0.4).string()};
  background-color: ${({ color }) => Color(color).fade(0.95).string()};
  width: fit-content;
`;

export const Title = styled.h2<{ color: string }>`
  color: ${({ color }) => color};
  margin: 0;
  padding: 0;
  font-size: 12px;
`;