import styled from 'styled-components';
import { ShoppingCartOutlined, ShoppingOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { Layout } from '../../store/ducks/layout/types';

export const ShoppingCartIcon = styled(ShoppingCartOutlined)<{ layout?: Layout }>`
  font-size: 25px;
  justify-self: flex-start;
  color: ${({ layout }) => layout?.color.default};
`;

export const ShoppingIcon = styled(ShoppingOutlined)`
  font-size: 20px;
`;

export const EmptyContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80vh;
`;

export const CustomButton = styled(Button)`
  margin: 5px 0 5px;
  width: 100%;
`;
