import React, { Dispatch, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ColumnsType } from 'antd/es/table';
import { EllipsisOutlined, EyeOutlined, RedoOutlined, EditOutlined } from '@ant-design/icons';
import { Action } from 'typesafe-actions';
import { Menu, Dropdown, Button, Tooltip } from 'antd';

import { ApplicationState } from '../../../store';
import { loadOrdersRequest } from '../../../store/ducks/myOrders/actions';
import CustomTable from '../../../components/CustomTable';
import { useHistory } from 'react-router-dom';
import { baseURL } from '../../../services/api';
import {
  clearCart,
  editOrderRequest,
  reDoOrderRequest,
} from '../../../store/ducks/shoppingCart/actions';
import { MESSAGES, ROLE } from '../../../utils/constants';
import { hasPermission } from '../../../utils/permissions';
import Swal from 'sweetalert2';
import { useCallback } from 'react';
import ServerError from '../../../components/ServerError';
import ReportsDropdown from '../../../components/ReportsDropdown';
import Status from '../../../components/Status';
import { configToColumnsType, dataToTableData } from '../../../utils/newTableHelper';
import AttachmentFile from '../../../components/AttachmentFile';

interface Props {
  search: string;
}

const MyOrdersTable: React.FC<Props> = ({ search }) => {
  const token = useSelector((state: ApplicationState) => state.auth.token);
  const layout = useSelector((state: ApplicationState) => state.layout.data);
  const config = useSelector((state: ApplicationState) => state.myOrders.config);
  const data = useSelector((state: ApplicationState) => state.myOrders.data);
  const error = useSelector((state: ApplicationState) => state.myOrders.error);
  const roles = useSelector((state: ApplicationState) => state.user.data?.roles);
  const loading = useSelector((state: ApplicationState) => state.myOrders.loading);
  const pg = useSelector((state: ApplicationState) => state.myOrders.pagination);
  const cartQuantity = useSelector((state: ApplicationState) => state.shoppingCart.totalQuantity);
  const saved = useSelector((state: ApplicationState) => state.shoppingCart.success);

  const [columns, setColumns] = useState<ColumnsType<any>>([]);

  const dispatch = useDispatch<Dispatch<Action>>();
  const history = useHistory();

  useEffect(() => {
    if (saved) return;
    dispatch(loadOrdersRequest());
  }, [dispatch, token, saved]);

  const redo = useCallback(
    (nuNota: number, isEdit?: boolean) => {
      if (cartQuantity > 0) {
        Swal.fire({
          title: 'Aviso!',
          confirmButtonColor: layout?.color.default,
          html: 'Você já tem itens adicionados no seu carrinho. Deseja limpar o carrinho?',
          icon: 'warning',
          confirmButtonText: 'Sim',
          cancelButtonText: 'Não',
          showCancelButton: true,
        }).then((result) => {
          if (result.isConfirmed) {
            dispatch(clearCart());
            history.push('/home/resumo-da-compra');
            dispatch(isEdit ? editOrderRequest(nuNota) : reDoOrderRequest(nuNota));
          }
        });
      } else {
        dispatch(clearCart());
        history.push('/home/resumo-da-compra');
        dispatch(isEdit ? editOrderRequest(nuNota) : reDoOrderRequest(nuNota));
      }
    },
    [cartQuantity, dispatch, history, layout?.color.default]
  );

  useEffect(() => {
    setColumns(
      configToColumnsType(
        config?.fields.filter((item) => item.show) || [],
        [
          {
            id: -1,
            render: (value: number) => <Status value={value} config={config?.fields || []} />,
          },
          {
            id: -2,
            render: (value: any, record: any) => (
              <ReportsDropdown
                disabled={!config?.details.showReport}
                value={value}
                pdfUrl={`order/${record.pkValue}/report`}
              />
            ),
          },
        ],
        [
          {
            title: 'Ações',
            key: 'action',
            render: (record) => (
              <Dropdown
                trigger={['click']}
                overlay={
                  <Menu>
                    <Menu.Item
                      onClick={() => history.push(`meus-pedidos/${record.pkValue}/detalhes`)}
                    >
                      <Button data-cy="btn-orderDetail" icon={<EyeOutlined />} type="text">
                        Ver
                      </Button>
                    </Menu.Item>
                    {hasPermission([ROLE.REDO_ORDER], roles) && (
                      <Menu.Item onClick={() => redo(record.pkValue)}>
                        <Button data-cy="btn-redo" icon={<RedoOutlined />} type="text">
                          Refazer
                        </Button>
                      </Menu.Item>
                    )}
                    {hasPermission([ROLE.EDIT_ORDER], roles) && (
                      <Menu.Item
                        disabled={!record.ORIGEMPEC || !record.PERMITE_EDICAO}
                        onClick={() => redo(record.pkValue, true)}
                      >
                        {!record.ORIGEMPEC || !record.PERMITE_EDICAO ? (
                          <Tooltip
                            title={
                              !record.ORIGEMPEC
                                ? MESSAGES.EDIT_ORDER_ORIGIN
                                : MESSAGES.EDIT_ORDER_CONFIRMED
                            }
                          >
                            <Button
                              data-cy="btn-editOrder"
                              icon={<EditOutlined />}
                              disabled={!record.ORIGEMPEC || !record.PERMITE_EDICAO}
                              type="text"
                            >
                              Editar
                            </Button>
                          </Tooltip>
                        ) : (
                          <Button
                            data-cy="btn-editOrder"
                            icon={<EditOutlined />}
                            disabled={!record.ORIGEMPEC || !record.PERMITE_EDICAO}
                            type="text"
                          >
                            Editar
                          </Button>
                        )}
                      </Menu.Item>
                    )}
                    {config?.details.attachDocuments && (
                      <Menu.Item>
                        <AttachmentFile
                          url={`${baseURL}/order/${record.pkValue}/upload`}
                          accept={config.details.fileType}
                          type="text"
                        />
                      </Menu.Item>
                    )}
                  </Menu>
                }
              >
                <Button
                  data-cy="btn-more"
                  type="link"
                  style={{ padding: 0 }}
                  onClick={(e) => e.preventDefault()}
                >
                  <EllipsisOutlined style={{ fontSize: '24px' }} rotate={90} />
                </Button>
              </Dropdown>
            ),
          },
        ],
        [0, -1, -2]
      )
    );
  }, [config, history, redo, roles]);

  return (
    <>
      {error ? (
        <ServerError {...error} />
      ) : (
        <CustomTable
          style={{ padding: config?.details.showBudget ? '0px' : '20px' }}
          onChange={(pagination: any, filters: any, sorter: any) => {
            dispatch(loadOrdersRequest(pagination, filters, sorter, search));
          }}
          pagination={pg}
          loading={loading}
          columns={columns}
          data={dataToTableData(data, config)}
        />
      )}
    </>
  );
};

export default MyOrdersTable;
