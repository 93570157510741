import { AxiosResponse } from 'axios';
import { call, put, select } from 'redux-saga/effects';
import { ApplicationState } from '../..';
import api, { errorHandler } from '../../../services/api';
import { requestHeaders } from '../../../utils/apiHelper';
import { refreshRequest } from '../auth/actions';
import {
  formRequest,
  loadFailure,
  loadSuccess,
  sendFailure,
  sendFormRequest,
  sendSuccess,
} from './actions';
import { ScreenForm, Success } from './types';

export function* loadScreenForm({ payload }: ReturnType<typeof formRequest>) {
  try {
    const state: ApplicationState = yield select();
    const headers = requestHeaders(state.auth.token);
    const response: AxiosResponse<ScreenForm[]> = yield call(
      api.get,
      `/screen/${payload.id}/form/fields`,
      {
        headers: headers,
      }
    );
    yield put(loadSuccess(response.data));
  } catch (err: any) {
    yield put(err.response.status !== 401 ? loadFailure(errorHandler(err)) : refreshRequest());
  }
}

export function* sendScreenForm({ payload }: ReturnType<typeof sendFormRequest>) {
  try {
    const state: ApplicationState = yield select();
    const headers = requestHeaders(state.auth.token);
    const response: AxiosResponse<Success> = yield call(
      api.post,
      `/screen/${payload.id}/save`,
      payload,
      {
        headers: headers,
      }
    );
    yield put(sendSuccess(response.data));
  } catch (err: any) {
    yield put(err.response.status !== 401 ? sendFailure(errorHandler(err)) : refreshRequest());
  }
}
