import React, { Dispatch, useEffect, useState } from 'react';
import { Action } from 'typesafe-actions';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../store';
import { requestReleases } from '../../store/ducks/orderRelease/actions';
import { Container } from '../../styles';
import ReleasesTable from './ReleasesTable';
import { Header } from './styles';
import SearchInput from './SearchInput';

const OrderRelease: React.FC = () => {
  const token = useSelector((state: ApplicationState) => state.auth.token);
  const [searchValue, setSearchValue] = useState<string>('');

  const dispatch = useDispatch<Dispatch<Action>>();
  useEffect(() => {
    dispatch(requestReleases());
  }, [dispatch, token]);

  return (
    <Container>
       <Header>
        <SearchInput setSearch={setSearchValue} />
      </Header>
      <ReleasesTable search={searchValue}/>
    </Container>
  );
};

export default OrderRelease;
