import { ColumnsType } from 'antd/es/table';
import React, { Dispatch, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Action } from 'redux';

import { ApplicationState } from '../../../store';
import { loadFinancialsRequest } from '../../../store/ducks/homeFinancials/actions';
import CustomTable from '../../../components/CustomTable';
import { configToColumnsType, dataToTableData } from '../../../utils/newTableHelper';
import ServerError from '../../../components/ServerError';
import ReportsDropdown from '../../../components/ReportsDropdown';
import Status from '../../../components/Status';

const FinancialsTable: React.FC = () => {
  const config = useSelector(
    (state: ApplicationState) => state.homeFinancials.config,
    shallowEqual
  );
  const data = useSelector((state: ApplicationState) => state.homeFinancials.data, shallowEqual);
  const error = useSelector((state: ApplicationState) => state.homeFinancials.error);
  const loading = useSelector((state: ApplicationState) => state.homeFinancials.loading);
  const saved = useSelector((state: ApplicationState) => state.shoppingCart.success);
  const token = useSelector((state: ApplicationState) => state.auth.token);
  const [columns, setColumns] = useState<ColumnsType<any>>([]);

  const dispatch = useDispatch<Dispatch<Action>>();
  useEffect(() => {
    if (saved) return;
    dispatch(loadFinancialsRequest());
  }, [dispatch, token, saved]);

  useEffect(() => {
    setColumns(
      configToColumnsType(
        config?.fields.filter((item) => item.show) || [],
        [
          {
            id: -5,
            render: (value: number) => <Status value={value} config={config?.fields || []} />,
          },
          {
            id: -1,
            render: (value: any, record: any) => (
              <ReportsDropdown value={value} pdfUrl={`financial/${record.NUFIN}/report`} />
            ),
          },
        ],
        undefined,
        [0, -5, -1],
        true,
        true
      )
    );
  }, [config]);
  return (
    <>
      {error ? (
        <ServerError {...error} />
      ) : (
        <CustomTable
          onChange={() => {}}
          loading={loading}
          columns={columns}
          data={dataToTableData(data, config)}
        />
      )}
    </>
  );
};

export default FinancialsTable;
