import { Button, Input, InputNumber } from 'antd';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../store';
import { SubmitButton } from '../../../styles';

import { Buttons, Content } from './styles';
const renderTableFilterDefault = (dataIndex: string, type: string) => {
  return ({ setSelectedKeys, confirm }: FilterDropdownProps) => {
    const layout = useSelector((state: ApplicationState) => state.layout.data);

    const [searchTerm, setSearchTerm] = useState<any>();

    const onChange = (value?: any) => {
      setSearchTerm(value);
    };

    const onConfirm = () => {
      if (!searchTerm && searchTerm !== 0) {
        return;
      }
      setSelectedKeys([dataIndex, searchTerm]);
      confirm();
    };

    const onClear = () => {
      setSearchTerm(undefined);
      setSelectedKeys([]);
      confirm();
    };
    return (
      <Content>
        {type === 'number' ? (
          <InputNumber
            style={{ width: '100%' }}
            placeholder="Buscar"
            name="searchTerm"
            value={searchTerm}
            onChange={(target) => onChange(target)}
          />
        ) : (
          <Input
            placeholder="Buscar"
            name="searchTerm"
            value={searchTerm}
            onChange={({ target }) => onChange(target.value)}
          />
        )}

        <Buttons>
          <Button danger onClick={onClear}>
            Limpar
          </Button>
          <SubmitButton layout={layout} type="primary" onClick={onConfirm}>
            OK
          </SubmitButton>
        </Buttons>
      </Content>
    );
  };
};
export default renderTableFilterDefault;
