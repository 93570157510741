import React, { Dispatch } from 'react';
import { Input } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Action } from 'typesafe-actions';
import { loadServiceOrdersRequest } from '../../../store/ducks/serviceOrders/actions';
import { ApplicationState } from '../../../store';
import { useWindowSize } from '../../../customHooks';

const { Search } = Input;
interface Props {
  setSearch: any;
}

const ServiceOrdersSearch: React.FC<Props> = ({ setSearch }) => {
  const placeholder = useSelector(
    (state: ApplicationState) => state.serviceOrders.config?.placeholder
  );
  const dispatch = useDispatch<Dispatch<Action>>();
  const size = useWindowSize();

  const onSearch = (value: any) => {
    setSearch(value);
    dispatch(loadServiceOrdersRequest(undefined, undefined, undefined, value));
  };

  return (
    <Search
      data-cy="input-search"
      placeholder={`${placeholder ?? 'Buscar'}`}
      allowClear
      onSearch={onSearch}
      style={{
        width: (size.width ?? 0) > 1024 ? '400px' : '100%',
        marginTop: (size.width ?? 0) > 1024 ? '0px' : '10px',
      }}
    />
  );
};

export default ServiceOrdersSearch;
