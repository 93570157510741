import React from 'react';
import { Input } from 'antd';

import { useWindowSize } from '../../../customHooks';

const { Search } = Input;
interface Props {
  setSearch: any;
}

const ScreenSearchInput: React.FC<Props> = ({ setSearch }) => {
  const size = useWindowSize();

  return (
    <Search
      placeholder="Buscar"
      allowClear
      onSearch={value => setSearch(value)}
      style={{
        width: (size.width ?? 0) > 1024 ? '400px' : '100%',
        marginTop: (size.width ?? 0) > 1024 ? '0px' : '10px',
      }}
    />
  );
};

export default ScreenSearchInput;
