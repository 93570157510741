import { AxiosResponse } from 'axios';
import { call, put, select } from 'redux-saga/effects';
import Swal from 'sweetalert2';
import { ApplicationState } from '../..';
import api, { errorHandler } from '../../../services/api';
import { requestHeaders } from '../../../utils/apiHelper';
import { refreshRequest } from '../auth/actions';
import {
  successCategory,
  failureCategory,
  requestFaq,
  successFaq,
  failureFaq,
  requestCall,
  successCall,
  failureCall,
  requestSendCall,
  successSendCall,
} from './actions';
import { Call, Faq } from './types';

export function* loadCategory() {
  try {
    const state: ApplicationState = yield select();
    const headers = requestHeaders(state.auth.token);
    const response: AxiosResponse<any> = yield call(api.get, 'faq/categories', {
      headers: headers,
    });
    yield put(successCategory(response.data));
  } catch (err: any) {
    yield put(err.response.status !== 401 ? failureCategory(errorHandler(err)) : refreshRequest());
  }
}

export function* loadFaq({ payload }: ReturnType<typeof requestFaq>) {
  try {
    const state: ApplicationState = yield select();
    const headers = requestHeaders(state.auth.token);
    const response: AxiosResponse<{
      content: Faq[];
      numberOfElements: number;
      size: number;
      number: number;
    }> = yield call(
      api.post,
      `faq/all`,
      {
        page: (payload.pagination?.current || 1) - 1,
        size: payload.pagination?.pageSize || 5,
        filters: payload.fields,
      },
      { headers: headers }
    );
    yield put(
      successFaq(
        {
          total: response.data.numberOfElements,
          pageSize: response.data.size,
          current: response.data.number + 1,
        },
        response.data.content
      )
    );
  } catch (err: any) {
    yield put(err.response.status !== 401 ? failureFaq(errorHandler(err)) : refreshRequest());
  }
}

export function* loadCall({ payload }: ReturnType<typeof requestCall>) {
  try {
    const state: ApplicationState = yield select();
    const headers = requestHeaders(state.auth.token);
    const response: AxiosResponse<{
      content: Call[];
      numberOfElements: number;
      size: number;
      number: number;
    }> = yield call(
      api.post,
      `call/all`,
      {
        page: (payload.pagination?.current || 1) - 1,
        size: payload.pagination?.pageSize || 5,
      },
      { headers: headers }
    );
    yield put(
      successCall(
        {
          total: response.data.numberOfElements,
          pageSize: response.data.size,
          current: response.data.number + 1,
        },
        response.data.content
      )
    );
  } catch (err: any) {
    yield put(err.response.status !== 401 ? failureCall(errorHandler(err)) : refreshRequest());
  }
}

export function* sendCall({ payload }: ReturnType<typeof requestSendCall>) {
  const state: ApplicationState = yield select();
  try {
    const headers = requestHeaders(state.auth.token);
    const response: AxiosResponse<{ message: string }> = yield call(
      api.post,
      'call/save',
      payload.sendCall,
      {
        headers: headers,
      }
    );
    yield put(successSendCall());
    yield put(requestCall());
    Swal.fire({
      title: 'Sucesso!',
      text: response.data.message,
      icon: 'success',
      confirmButtonColor: state.layout.data?.color.default,
      confirmButtonText: 'Ok',
    });
  } catch (err: any) {
    yield put(err.response.status !== 401 ? failureCategory(errorHandler(err)) : refreshRequest());
    Swal.fire({
      title: 'Erro!',
      confirmButtonColor: state.layout.data?.color.default,
      html: err.response.data.message,
      icon: 'error',
    });
  }
}
