import React from 'react';
import { Modal, Skeleton } from 'antd';

import { PdfContent } from './styles';
import { ShowActionReportProps } from './props';

const ShowActionReport: React.FC<ShowActionReportProps> = ({ onCancel, resportResponse, visible }) => {
  return (
    <Modal
      style={{ minWidth: '70%' }}
      title={resportResponse?.title || ''}
      visible={visible}
      onCancel={onCancel}
      cancelText="Fechar"
      footer={null}
    >
      {resportResponse && resportResponse.report ? (
        <PdfContent title="pdf" src={`data:application/pdf;base64,${resportResponse.report}`} />
      ) : (
        <>
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
        </>
      )}
    </Modal>
  )
}

export default ShowActionReport;
