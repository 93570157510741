import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import ScreenTable from './ScreenTable';
import { Container, Header } from './styles';
import { ScreenConstructorProps } from './props';
import { ApplicationState } from '../../store';
import { useWindowSize } from '../../customHooks';
import ScreenSearchInput from './ScreenSearchInput';

const ScreenConstructor: React.FC<ScreenConstructorProps> = ({
  screenId,
  appearance = 'home',
  onClickNewRegister,
  params
}) => {
  const { id: idParam }: any = useParams();
  const id = screenId || idParam;
  const [searchValue, setSearchValue] = useState<string>();
  const menus = useSelector((state: ApplicationState) => state.additionalScreens.screens);
  const size = useWindowSize();
  const history = useHistory();

  const hasForm = () => menus?.find((item) => item.id === parseInt(id))?.saveForm;

  useEffect(() => {
    if (appearance === 'home' && !menus?.some((item) => item.id.toString() === id))
      history.push('/')
  }, [menus, id, history, appearance]);

  return (
    <Container>
      {(hasForm() || appearance === "home") && (
        <Header appearance={appearance} hasForm={hasForm()}>
          {hasForm() && (
            <Button
              icon={<PlusOutlined />}
              block={(size.width ?? 0) <= 1024}
              type="primary"
              size="middle"
              onClick={onClickNewRegister}
            >
              Novo Registro
            </Button>
          )}

          {appearance === "home" && <ScreenSearchInput setSearch={setSearchValue} />}
        </Header>
      )}

      <ScreenTable search={searchValue} screenId={screenId} appearance={appearance} params={params} />
    </Container>
  )
}

export default ScreenConstructor;
