import { action } from 'typesafe-actions';
import { ResponseError } from '../../../services/api';
import { Config } from '../../../utils/newTableHelper';
import { Category, Pagination, ProductsShowCaseTypes } from './types';

export const loadRequestShowCase = (pagination?: Pagination, search?: string) =>
  action(ProductsShowCaseTypes.LOAD_REQUEST, { pagination, search });
export const loadSuccessShowCase = (
  data: any[],
  config: Config,
  pagination: Pagination,
  searchValue?: string
) =>
  action(ProductsShowCaseTypes.LOAD_SUCCESS, {
    data,
    config,
    pagination,
    searchValue,
  });
export const loadFailureShowCase = (error: ResponseError) =>
  action(ProductsShowCaseTypes.LOAD_FAILURE, { error });

export const categoriesRequestShowCase = () => action(ProductsShowCaseTypes.LOAD_REQUEST_CATEGORY);
export const CategoriesSuccessShowCase = (data: Category[]) =>
  action(ProductsShowCaseTypes.LOAD_SUCCESS_CATEGORY, { data });
export const categoriesFailureShowCase = (error: ResponseError) =>
  action(ProductsShowCaseTypes.LOAD_FAILURE_CATEGORY, { error });

export const setUnity = (codProd: number, unity: string) =>
  action(ProductsShowCaseTypes.SET_UNITY, { codProd, unity });

export const unitySuccess = (data: any[]) => action(ProductsShowCaseTypes.UNITY_SUCCESS, { data });

export const setFilter = (filters: (string | number)[]) =>
  action(ProductsShowCaseTypes.SET_FILTERS, { filters });

export const setSearch = (searchValue: string) =>
  action(ProductsShowCaseTypes.SET_SEARCH, { searchValue });
