import { ResponseError } from '../../../services/api';

export enum HomeCardsTypes {
  'LOAD_REQUEST' = '@homeCards/LOAD_REQUEST',
  'LOAD_SUCCESS' = '@homeCards/LOAD_SUCCESS',
  'LOAD_FAILURE' = '@homeCards/LOAD_FAILURE',
}

export interface DataCard {
  title: string;
  value: string;
  icon: string;
}

export interface HomeCardsState {
  readonly data: DataCard[];
  readonly loading: boolean;
  readonly error?: ResponseError;
}
