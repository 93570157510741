import React, { Dispatch, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ColumnsType } from 'antd/es/table';
import { Action } from 'typesafe-actions';
import { ApplicationState } from '../../../store';
import { loadOrdersRequest } from '../../../store/ducks/homeOrders/actions';
import CustomTable from '../../../components/CustomTable';
import { configToColumnsType, dataToTableData } from '../../../utils/newTableHelper';
import ServerError from '../../../components/ServerError';
import ReportsDropdown from '../../../components/ReportsDropdown';
import Status from '../../../components/Status';

const OrdersTable: React.FC = () => {
  const config = useSelector((state: ApplicationState) => state.homeOrders.config);
  const error = useSelector((state: ApplicationState) => state.homeOrders.error);
  const data = useSelector((state: ApplicationState) => state.homeOrders.data);
  const token = useSelector((state: ApplicationState) => state.auth.token);
  const saved = useSelector((state: ApplicationState) => state.shoppingCart.success);
  const loading = useSelector((state: ApplicationState) => state.homeOrders.loading);

  const [columns, setColumns] = useState<ColumnsType<any>>([]);

  const dispatch = useDispatch<Dispatch<Action>>();
  useEffect(() => {
    if (saved) return;
    dispatch(loadOrdersRequest());
  }, [dispatch, token, saved]);

  useEffect(() => {
    setColumns(
      configToColumnsType(
        config?.fields.filter((item) => item.show) || [],
        [
          {
            id: -1,
            render: (value: number) => <Status value={value} config={config?.fields || []} />,
          },
          {
            id: -2,
            render: (value: any, record: any) => (
              <ReportsDropdown
                disabled={!config?.details.showReport}
                value={value}
                pdfUrl={`order/${record.NUNOTA}/report`}
              />
            ),
          },
        ],
        undefined,
        [0, -1, -2],
        true,
        true
      )
    );
  }, [config]);
  return (
    <>
      {error ? (
        <ServerError {...error} />
      ) : (
        <CustomTable
          onChange={() => {}}
          loading={loading}
          columns={columns}
          data={dataToTableData(data, config)}
        />
      )}
    </>
  );
};

export default OrdersTable;
