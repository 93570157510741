import React, { Dispatch, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Action } from 'typesafe-actions';
import CustomTable from '../../components/CustomTable';
import ServerError from '../../components/ServerError';
import { ApplicationState } from '../../store';
import {
  loadDetail,
  loadServiceOrdersDetailRequest,
} from '../../store/ducks/serviceOrdersDetail/actions';
import { Container } from '../../styles';
import { configToColumnsType, dataToDisplay, dataToTableData } from '../../utils/newTableHelper';

import {
  ContainerData,
  ContentData,
  ContentDetail,
  ItemData,
  TitleData,
  ValueData,
} from './styles';

const ServiceOrderDetail: React.FC = () => {
  const token = useSelector((state: ApplicationState) => state.auth.token);
  const layout = useSelector((state: ApplicationState) => state.layout.data);
  const config = useSelector((state: ApplicationState) => state.serviceOrdersDetail.config);
  const error = useSelector((state: ApplicationState) => state.serviceOrdersDetail.errorData);
  const data = useSelector((state: ApplicationState) => state.serviceOrdersDetail.data);
  const configDetail = useSelector(
    (state: ApplicationState) => state.serviceOrdersDetail.configDetail
  );
  const dataDetail = useSelector((state: ApplicationState) => state.serviceOrdersDetail.dataDetail);
  const loading = useSelector((state: ApplicationState) => state.serviceOrdersDetail.loading);
  const pagination = useSelector((state: ApplicationState) => state.serviceOrdersDetail.pagination);

  const dispatch = useDispatch<Dispatch<Action>>();
  const { id }: any = useParams();

  useEffect(() => {
    dispatch(loadServiceOrdersDetailRequest(undefined, undefined, undefined, id));
    dispatch(loadDetail(id));
  }, [dispatch, token, id]);

  return (
    <>
      {error ? (
        <ServerError status={error.status} message={error.message} back />
      ) : (
        <Container layout={layout}>
          <ContentDetail>
            <CustomTable
              style={{ padding: '20px' }}
              onChange={(pg: any, filters: any, sorter: any) => {
                dispatch(loadServiceOrdersDetailRequest(pg, filters, sorter, id));
              }}
              pagination={pagination}
              loading={loading}
              columns={configToColumnsType(config?.fields.filter((item) => item.show) || [])}
              data={dataToTableData(data, config)}
            />
            <ContainerData>
              <h3>Informações da OS</h3>
              <ContentData>
                {dataToDisplay(dataDetail, configDetail)?.map((item) => {
                  if (!item.display) {
                    return <></>;
                  }
                  return (
                    <ItemData>
                      <TitleData>{`${item.title}:`}</TitleData>
                      <ValueData>{item.value}</ValueData>
                    </ItemData>
                  );
                })}
              </ContentData>
            </ContainerData>
          </ContentDetail>
        </Container>
      )}
    </>
  );
};

export default ServiceOrderDetail;
