import React from 'react';
import { Input } from 'antd';
import { useSelector } from 'react-redux';

import { ApplicationState } from '../../../store';
import { Size, useWindowSize } from '../../../customHooks';
const { Search } = Input;

interface Props {
  setSearch: any;
  setPagination: any;
}

const DataSheetSearch: React.FC<Props> = ({ setSearch, setPagination }) => {
  const placeholder = useSelector((state: ApplicationState) => state.dataSheet.placeholder);
  const size: Size = useWindowSize();
  const onSearch = (value: any) => {
    setSearch(value);
    setPagination(undefined);
  };

  return (
    <Search
      data-cy="input-search"
      placeholder={`${placeholder ?? 'Buscar'}`}
      allowClear
      onSearch={onSearch}
      style={{ width: (size.width ?? 0) > 1024 ? '400px' : '100%' }}
    />
  );
};

export default DataSheetSearch;
