import { Reducer } from 'redux';
import { OrderFieldsState, OrderFieldsTypes } from './types';

const INITIAL_STATE: OrderFieldsState = {
  data: [],
  values: [],
  loading: false,
  loaded: false,
};

const reducer: Reducer<OrderFieldsState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case OrderFieldsTypes.LOAD_REQUEST:
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    case OrderFieldsTypes.LOAD_SUCCESS:
      return {
        ...state,
        loaded: true,
        loading: false,
        error: undefined,
        ...action.payload,
      };
    case OrderFieldsTypes.LOAD_FAILURE:
      return {
        ...state,
        data: [],
        loading: false,
        ...action.payload,
      };
    case OrderFieldsTypes.SET_VALUES:
      return {
        ...state,
        ...action.payload,
      };
    case OrderFieldsTypes.CLEAR_VALUES:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
};

export default reducer;
