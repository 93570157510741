import { Row, Col, Button, Select, Divider, Tooltip, Spin } from 'antd';
import React, { Dispatch, useEffect } from 'react';
import { LoadingOutlined, DownloadOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import '@brainhubeu/react-carousel/lib/style.css';
import { Action } from 'typesafe-actions';
import ReactPlayer from 'react-player/lazy';
import { FileTextOutlined } from '@ant-design/icons';
import { ApplicationState } from '../../store';
import { maskCamelCase, maskCurrency } from '../../utils/masks';
import QuantityInput from '../../components/QuantityInput';
import MetreageCalculator from '../../components/NewOrderModals/MetreageCalculator';
import {
  Content,
  DescriptionContent,
  InputContent,
  TextContent,
  TextDisplay,
  Value,
} from './styles';
import { hasPermission } from '../../utils/permissions';
import { colors, ROLE } from '../../utils/constants';
import { Size, useWindowSize } from '../../customHooks';
import { Unity } from '../../store/ducks/shoppingCart/types';
import { useParams } from 'react-router-dom';
import { loadRequest, setUnityDetail } from '../../store/ducks/productDetail/actions';
import ModalGrid from '../../components/NewOrderModals/ModalGrid';
import ServerError from '../../components/ServerError';
import ProductsCarousel from '../../components/ProductCarousel';
import Gallery from '../../components/Gallery';
import MattressModal from '../../components/NewOrderModals/MattressModal';
import CustomCollapse from '../../components/CustomCollapse';
import PromoTag from '../../components/PromoTag';
import { getFixedFieldDescription } from '../../utils/newTableHelper';

const { Option } = Select;

const ProductDetail: React.FC = () => {
  const { id }: { id: string } = useParams();
  const codprod = id;
  const roles = useSelector((state: ApplicationState) => state.user.data?.roles);
  const layout = useSelector((state: ApplicationState) => state.layout.data);
  const error = useSelector((state: ApplicationState) => state.productDetail.error);
  const shoppingCart = useSelector((state: ApplicationState) => state.shoppingCart.cart);
  const product = useSelector((state: ApplicationState) => state.productDetail.data[0]);
  const config = useSelector((state: ApplicationState) => state.productDetail.config);
  const relatedProducts = useSelector(
    (state: ApplicationState) => state.productDetail.relatedProducts
  );
  const loading = useSelector((state: ApplicationState) => state.productDetail.loading);
  const images = useSelector((state: ApplicationState) => state.productDetail.images);
  const thumbnails = useSelector((state: ApplicationState) => state.productDetail.thumbnails);
  const saved = useSelector((state: ApplicationState) => state.shoppingCart.success);
  const token = useSelector((state: ApplicationState) => state.auth.token);
  const dispatch = useDispatch<Dispatch<Action>>();
  const size: Size = useWindowSize();

  const getUnity = (): Unity => {
    return (
      shoppingCart.find((element) => element.codProd === product?.pkValue)?.unity ||
      product?.UNIDADE?.find((item: Unity) => product?.SELECTED_UNITY === item.key)
    );
  };

  useEffect(() => {
    if (saved) return;
    dispatch(loadRequest(codprod));
  }, [dispatch, codprod, token, saved]);

  const hasImageView = (): boolean => {
    return !!config?.details.viewModes.find((item: string) => item === 'LIST' || item === 'CARD');
  };

  const renderInput = () => (
    <div onClick={(e) => e.stopPropagation()}>
      {!product?.USAMETRAGEM &&
        product?.DETAILS.stockControlType !== 'GRADE' &&
        product?.DETAILS.stockControlType !== 'AFC' && (
          <QuantityInput
            width={160}
            description={product?.DESCRPROD}
            codProd={product?.pkValue}
            isPromo={product?.PROMOCAO?.isPromo}
            quantity={0}
            stock={getStock()}
            unity={
              shoppingCart.find((element) => element.codProd === product?.pkValue)?.unity ||
              product?.UNIDADE?.find((e: Unity) => product?.SELECTED_UNITY === e.key)
            }
            value={getValue()}
            hasStock={product?.ESTOQUE_SHOW}
            message={getUnity()?.message}
            multiplier={getUnity()?.multiple}
            isDecimal={!!getUnity()?.decimalPlaces}
            decimalQtd={getUnity()?.decimalPlaces}
            controlType={product?.DETAILS?.stockControlType}
          />
        )}
      {product?.USAMETRAGEM && <MetreageCalculator product={product} />}
      {product?.DETAILS.stockControlType === 'GRADE' && (
        <ModalGrid product={product} disabled={getValue() === 0} />
      )}
      {product?.DETAILS.stockControlType === 'AFC' && <MattressModal product={product} />}
    </div>
  );

  const getStock = () =>
    Math.floor(
      product?.ESTOQUE.find(
        ({ key }: any) =>
          key ===
          (shoppingCart.find((element) => element.codProd === codprod)?.unity?.key ||
            product?.SELECTED_UNITY)
      )?.value || 0
    );

  const getValue = () =>
    product?.VALOR.find(
      ({ key }: any) =>
        key ===
        (shoppingCart.find((element) => element.codProd === codprod)?.unity?.key ||
          product?.SELECTED_UNITY)
    )?.value || 0;

  const DataSheetClick = (e: any) => {
    e.preventDefault();
    const win = window.open(product?.DETAILS.specifications, '_blank');
    win && win.focus();
  };

  return (
    <Spin
      style={{ color: layout?.color.default }}
      spinning={loading}
      size="large"
      delay={500}
      indicator={<LoadingOutlined />}
    >
      {error ? (
        <ServerError {...error} />
      ) : (
        <Content>
          <Row justify="center" align="middle">
            {hasImageView() && (
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Gallery images={images} thumbnails={thumbnails} loading={loading} />
              </Col>
            )}
            <Col
              xs={24}
              sm={24}
              md={hasImageView() ? 12 : 24}
              lg={hasImageView() ? 12 : 24}
              xl={hasImageView() ? 12 : 24}
            >
              <Row>
                <DescriptionContent>
                  <h2>{product?.DESCRPROD}</h2>
                  <div>
                    <span style={{ color: colors.writeGrey }}>
                      Código:
                      <strong style={{ marginLeft: '5px', marginRight: '5px' }}>
                        {product?.CODPROD}{' '}
                      </strong>
                      {product?.ESTOQUE_SHOW && (
                        <>
                          - Disponível:
                          <strong style={{ marginLeft: '5px', marginRight: '5px' }}>
                            {getStock()}
                          </strong>
                        </>
                      )}
                    </span>
                  </div>
                  {product?.REFERENCIA && (
                    <span style={{ color: colors.writeGrey }}>
                      {getFixedFieldDescription('REFERENCIA', 'Referência', config?.fields)}:
                      <strong style={{ marginLeft: '5px', marginRight: '5px' }}>
                        {product?.REFERENCIA}
                      </strong>
                      {product?.MARCA && (
                        <Divider
                          style={{
                            margin: '0 5px 0 0',
                            backgroundColor: colors.writeGrey,
                            width: '1px',
                          }}
                          type="vertical"
                        />
                      )}
                    </span>
                  )}
                  {product?.MARCA && (
                    <span style={{ color: colors.writeGrey }}>
                      {getFixedFieldDescription('MARCA', 'Marca', config?.fields)}:
                      <strong style={{ marginLeft: '5px', marginRight: '5px' }}>
                        {product?.MARCA}
                      </strong>
                    </span>
                  )}
                  {product?.FORNEC && (
                    <Divider
                      style={{
                        margin: '0 5px 0 0',
                        backgroundColor: colors.writeGrey,
                        width: '1px',
                      }}
                      type="vertical"
                    />
                  )}
                  {product?.FORNEC && (
                    <span style={{ color: colors.writeGrey }}>
                      {getFixedFieldDescription('FORNEC', 'Fornecedor', config?.fields)}:
                      <strong style={{ marginLeft: '5px', marginRight: '5px' }}>
                        {product?.FORNEC}
                      </strong>
                    </span>
                  )}
                  <Row align="middle" gutter={[10, 10]}>
                    {product?.PROMOCAO?.isPromo && (
                      <Col>
                        <PromoTag />
                      </Col>
                    )}
                    <Col>
                      <Value>
                        {product?.DETAILS.stockControlType === 'GRADE' && getValue() === 0
                          ? 'Indisponível'
                          : maskCurrency(getValue())}
                      </Value>
                    </Col>
                  </Row>
                </DescriptionContent>
                <Divider />
                <InputContent>
                  <Row>
                    <Select
                      data-cy="input-selectUnity"
                      style={{ marginRight: '20px' }}
                      defaultValue={product?.SELECTED_UNITY}
                      value={
                        shoppingCart.find((element) => element.codProd === codprod)?.unity?.key ||
                        product?.SELECTED_UNITY
                      }
                      onChange={(value) => dispatch(setUnityDetail(codprod, value))}
                      disabled={!!shoppingCart.find((element) => element.codProd === codprod)}
                    >
                      {product?.UNIDADE?.map((element: any) => {
                        return (
                          <Option key={element.key} value={element.key}>
                            {(size?.width ?? 0) > 1024 ? maskCamelCase(element.value) : element.key}
                          </Option>
                        );
                      })}
                    </Select>
                    {renderInput()}
                  </Row>
                  {hasPermission([ROLE.DATASHEET], roles) && (
                    <>
                      {!product?.DETAILS.specifications ? (
                        <Tooltip title="Não possui ficha técnica">
                          <Button
                            data-cy="btn-openDatasheet"
                            icon={<DownloadOutlined />}
                            type="link"
                            size="small"
                            disabled
                          >
                            Ficha técnica
                          </Button>
                        </Tooltip>
                      ) : (
                        <Button
                          data-cy="btn-openDatasheet"
                          icon={<DownloadOutlined />}
                          type="link"
                          onClick={(e) => {
                            DataSheetClick(e);
                          }}
                        >
                          Ficha técnica
                        </Button>
                      )}
                    </>
                  )}
                </InputContent>
              </Row>
            </Col>
          </Row>
        </Content>
      )}
      {(product?.CARACTERIS || product?.LINKVIDEO) && (
        <TextContent>
          <CustomCollapse
            title={getFixedFieldDescription('CARACTERIS', 'Características', config?.fields)}
            defaultOpened
            icon={<FileTextOutlined style={{ color: layout?.color.default }} />}
          >
            {product?.CARACTERIS && <TextDisplay>{product?.CARACTERIS}</TextDisplay>}
            {product?.LINKVIDEO && (
              <Row align="middle" justify="center">
                <Col>
                  <ReactPlayer url={`https://www.youtube.com/watch?v=${product?.LINKVIDEO}`} />
                </Col>
              </Row>
            )}
          </CustomCollapse>
        </TextContent>
      )}
      {relatedProducts.length > 0 && (
        <>
          <h2 style={{ color: layout?.color.dark }}>Produtos Relacionados</h2>
          <ProductsCarousel products={relatedProducts} loading={loading} />
        </>
      )}
    </Spin>
  );
};
export default ProductDetail;
