import { AxiosResponse } from 'axios';
import { call, put, select } from 'redux-saga/effects';
import { ApplicationState } from '../..';
import api, { errorHandler } from '../../../services/api';
import { requestHeaders } from '../../../utils/apiHelper';
import { refreshRequest } from '../auth/actions';
import {
  requestUser,
  successUser,
  failureUser,
  sendUserRequest,
  sendUserSuccess,
  sendUserFailure,
} from './actions';

export function* loadDataUser({ payload }: ReturnType<typeof requestUser>) {
  try {
    const state: ApplicationState = yield select();
    const headers = requestHeaders(state.auth.token);
    const response: AxiosResponse<any> = yield call(
      api.post,
      `/access-control/user/${payload.id}`,
      undefined,
      {
        headers: headers,
      }
    );
    yield put(successUser(response.data));
  } catch (err: any) {
    yield put(err.response.status !== 401 ? failureUser(errorHandler(err)) : refreshRequest());
  }
}

export function* loadSendUser({ payload }: ReturnType<typeof sendUserRequest>) {
  try {
    const state: ApplicationState = yield select();
    const headers = requestHeaders(state.auth.token);
    const response: AxiosResponse<{ userId: string }> = payload.id
      ? yield call(api.put, `/access-control/user/${payload.id}`, payload.user, {
          headers: headers,
        })
      : yield call(api.post, 'access-control/user', payload.user, {
          headers: headers,
        });
    yield put(sendUserSuccess(response.data.userId));
  } catch (err: any) {
    yield put(err.response.status !== 401 ? sendUserFailure(errorHandler(err)) : refreshRequest());
  }
}
