import { action } from 'typesafe-actions';
import { ResponseError } from '../../../services/api';
import { NewUserTypes, User } from './types';

export const requestUser = (id: string) => action(NewUserTypes.USER_REQUEST, { id });
export const successUser = (data: any[]) => action(NewUserTypes.USER_SUCCESS, { data });
export const failureUser = (error: ResponseError) => action(NewUserTypes.USER_FAILURE, { error });

export const sendUserRequest = (user?: User, id?: string) =>
  action(NewUserTypes.SEND_REQUEST, { user, id });

export const sendUserSuccess = (data: string) => action(NewUserTypes.SEND_SUCCESS, { data });

export const sendUserFailure = (error: ResponseError) =>
  action(NewUserTypes.SEND_FAILURE, { error });

export const clearSend = () => action(NewUserTypes.CLEAR_SEND);

export const setError = (error?: ResponseError) => action(NewUserTypes.SET_ERROR, { error });
