import { action } from 'typesafe-actions';
import { ResponseError } from '../../../services/api';
import { Config, Data } from '../../../utils/newTableHelper';
import { HomeServiceOrdersTypes } from './types';

export const HomeServiceOrdersRequest = () => action(HomeServiceOrdersTypes.LOAD_REQUEST);
export const loadSuccess = (data: Data[], config: Config) =>
  action(HomeServiceOrdersTypes.LOAD_SUCCESS, { data, config });
export const loadFailure = (error: ResponseError) =>
  action(HomeServiceOrdersTypes.LOAD_FAILURE, { error });
