import React, { Dispatch, useEffect } from 'react';
import { Button, Form, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import TextArea from 'antd/lib/input/TextArea';
import { Action } from 'typesafe-actions';
import { ApplicationState } from '../../../store';
import { requestSendCall } from '../../../store/ducks/faq/actions';
import { SendCall } from '../../../store/ducks/faq/types';

const { Option } = Select;
const FAQSendQuestion: React.FC = () => {
  const dataCategory = useSelector((state: ApplicationState) => state.faq.dataCategory);
  const loading = useSelector((state: ApplicationState) => state.faq.loadingSend);
  const [form] = Form.useForm();
  const dispatch = useDispatch<Dispatch<Action>>();

  useEffect(() => {
    if (!loading)
      setTimeout(() => {
        form.resetFields();
      }, 2000);
  }, [form, loading]);

  const onfinish = (values: SendCall) => {
    dispatch(requestSendCall(values));
  };
  return (
    <Form form={form} onFinish={onfinish}>
      <Form.Item
        name="category"
        rules={[{ required: true, message: 'Por favor informe o campo acima' }]}
      >
        <Select data-cy="input-categoryToSend" allowClear placeholder="Selecione uma opção">
          {dataCategory.map((data) => <Option key={data.key} value={data.key}>{data.value}</Option>)}
        </Select>
      </Form.Item>
      <Form.Item
        name="situation"
        rules={[{ required: true, message: 'Por favor informe o campo acima' }]}
      >
        <TextArea data-cy="input-situation" placeholder="Detalhe aqui" style={{ height: 160 }} />
      </Form.Item>
      <Form.Item>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button data-cy="btn-send" type="primary" block htmlType="submit" loading={loading}>
            Enviar
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};

export default FAQSendQuestion;
