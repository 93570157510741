import { action } from 'typesafe-actions';
import { ResponseError } from '../../../services/api';
import { LayoutTypes, Layout } from './types';

export const loadRequest = () => action(LayoutTypes.LOAD_REQUEST);
export const loadSuccess = (data: Layout) => action(LayoutTypes.LOAD_SUCCESS, { data });
export const loadFailure = (error: ResponseError) => action(LayoutTypes.LOAD_FAILURE, { error });
export const scroll = () => action(LayoutTypes.SCROLL);
export const resetScroll = () => action(LayoutTypes.RESET_SCROLL);

