import React, { Dispatch, useEffect, useState } from 'react';
import { Button, Form, Input } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Action } from 'typesafe-actions';
import Swal from 'sweetalert2';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { changePasswordRequest, cleanState, loginRequest } from '../../store/ducks/auth/actions';
import { ApplicationState } from '../../store';
import { Container, Submit } from './styles';
import { colors, MESSAGES } from '../../utils/constants';
import { useForm } from 'antd/lib/form/Form';
import { Auth } from '../../store/ducks/auth/types';
import { loadRequest } from '../../store/ducks/user/actions';

export interface Rules {
  required: boolean;
  message: string;
}

const ChangePassword: React.FC = () => {
  const token = useSelector((state: ApplicationState) => state.auth.token);
  const userName = useSelector((state: ApplicationState) => state.auth.userName);
  const layout = useSelector((state: ApplicationState) => state.layout.data);
  const error = useSelector((state: ApplicationState) => state.auth.changePasswordError);
  const errorMessage = useSelector((state: ApplicationState) => state.auth.errorMessage);
  const message = useSelector((state: ApplicationState) => state.auth.changePasswordMessage);
  const loading = useSelector((state: ApplicationState) => state.auth.changePasswordLoading);
  const [redirect, setRedirect] = useState<string>('');
  const dispatch = useDispatch<Dispatch<Action>>();
  const dispatchClean = useDispatch<Dispatch<Action>>();
  const [form] = useForm();
  const onFinish = (e: any) => {
    dispatch(changePasswordRequest(e.email, e.password, e.new));
  };

  useEffect(() => {
    if (userName) return;
    setRedirect('/login');
  }, [userName]);

  useEffect(() => {
    if (!token) return;
    dispatch(loadRequest(token));
    setRedirect('/home/');
  }, [dispatch, token]);

  useEffect(() => {
    if (loading || !message) return;
    Swal.fire({
      title: 'Sucesso!',
      confirmButtonColor: layout?.color.default,
      html: message,
      icon: 'success',
    }).then(() => {
      const user: Auth = {
        userName: form.getFieldValue('email'),
        password: form.getFieldValue('new'),
      };
      dispatch(loginRequest(user));
    });
  }, [dispatch, form, layout?.color.default, loading, message]);

  useEffect(() => {
    if (!error || loading) return;
    Swal.fire({
      title: 'Erro!',
      confirmButtonColor: layout?.color.default,
      html: errorMessage,
      icon: 'error',
    });
  }, [error, errorMessage, layout?.color.default, loading]);
  return (
    <Container>
      <Form
        form={form}
        name="basic"
        initialValues={{ remember: true, email: userName }}
        onFinish={onFinish}
      >
        <Form.Item name="email">
          <Input
            data-cy="input-email"
            prefix={<UserOutlined style={{ color: colors.writeGrey }} />}
            disabled
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: 'Por favor digite a senha atual' }]}
        >
          <Input.Password
            data-cy="input-password"
            prefix={<LockOutlined style={{ color: colors.writeGrey }} />}
            placeholder="Senha atual"
          />
        </Form.Item>
        <Form.Item
          hasFeedback
          dependencies={['password']}
          rules={[
            {
              required: true,
              message: 'Por favor digite a nova senha!',
            },
            () => ({
              validator(_, value: string) {
                if (!value || value.match(/^(?=.{8,}$)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).*$/)) {
                  return Promise.resolve();
                }
                return Promise.reject(MESSAGES.PASSWORD_ERROR);
              },
            }),
          ]}
          name="new"
        >
          <Input.Password
            data-cy="input-newPassword"
            prefix={<LockOutlined style={{ color: colors.writeGrey }} />}
            placeholder="Nova senha"
          />
        </Form.Item>
        <Form.Item
          hasFeedback
          rules={[
            {
              required: true,
              message: 'Por favor confirme a nova senha!',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('new') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('As duas senhas devem ser iguais!'));
              },
            }),
          ]}
          name="confirm"
          dependencies={['new']}
        >
          <Input.Password
            data-cy="input-confirmPassword"
            prefix={<LockOutlined style={{ color: colors.writeGrey }} />}
            placeholder="Confirme a nova senha"
          />
        </Form.Item>
        <Form.Item>
          <Submit
            data-cy="btn-chagePassword"
            htmlType="submit"
            block
            layout={layout}
            loading={loading}
          >
            Redefinir senha
          </Submit>
        </Form.Item>
        <Form.Item style={{ marginTop: '-15px' }}>
          <Button
            data-cy="btn-backToLogin"
            type="link"
            block
            onClick={() => {
              dispatchClean(cleanState());
              setRedirect('/login');
            }}
          >
            Voltar para o login
          </Button>
        </Form.Item>
      </Form>
      {redirect && <Redirect to={redirect} />}
    </Container>
  );
};

export default ChangePassword;
