import styled from 'styled-components';
import { colors } from '../../utils/constants';

export const HeaderContacts = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 20px;
`;

export const Content = styled.div`
  background-color: white;
  border-radius: 5px;
  box-shadow: 1px 2px 2px 2px ${colors.grey};
  padding: 20px;
`;

export const FormContainer = styled.div`
  padding: 20px;
  width: 100%;
  margin-bottom: 20px;
  background: white;
  border-radius: 5px;
  box-shadow: 1px 2px 2px 2px ${colors.grey};
`;
