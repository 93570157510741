import { AxiosResponse } from 'axios';
import { call, put, select } from 'redux-saga/effects';
import { ApplicationState } from '../..';
import api, { errorHandler } from '../../../services/api';
import { requestHeaders } from '../../../utils/apiHelper';
import {
  Config,
  Data,
  filterParamsHandle,
  sorterParamsHandle,
} from '../../../utils/newTableHelper';
import { refreshRequest } from '../auth/actions';
import {
  loadFormFailure,
  loadFormRequest,
  loadFormSuccess,
  loadListFailure,
  loadListRequest,
  loadListSuccess,
  loadModelsFailure,
  loadModelsSuccess,
  sendFailure,
  sendRequest,
  sendSuccess,
} from './actions';
import { FormFields, ServiceOrderTemplate } from './types';

export function* loadModels() {
  try {
    const state: ApplicationState = yield select();
    const headers = requestHeaders(state.auth.token);
    const response: AxiosResponse<ServiceOrderTemplate[]> = yield call(
      api.get,
      `service-order/template/all`,
      { headers: headers }
    );
    yield put(loadModelsSuccess(response.data));
  } catch (err: any) {
    yield put(
      err.response.status !== 401 ? loadModelsFailure(errorHandler(err)) : refreshRequest()
    );
  }
}

export function* loadForm({ payload }: ReturnType<typeof loadFormRequest>) {
  try {
    const state: ApplicationState = yield select();
    const headers = requestHeaders(state.auth.token);
    const response: AxiosResponse<FormFields[]> = yield call(
      api.get,
      `service-order/template/${payload.id}/form/fields`,
      {
        headers: headers,
      }
    );
    yield put(loadFormSuccess(response.data));
  } catch (err: any) {
    yield put(err.response.status !== 401 ? loadFormFailure(errorHandler(err)) : refreshRequest());
  }
}

export function* loadList({ payload }: ReturnType<typeof loadListRequest>) {
  try {
    const state: ApplicationState = yield select();
    const headers = requestHeaders(state.auth.token);
    const filtered = state.newServiceOrder.requiredParams.filter(
      (item) => item.step !== payload.step?.id
    );
    const responseConfig: AxiosResponse<Config> = yield call(
      api.get,
      `/service-order/template/${payload.id}/${payload.step?.id}`,
      {
        headers: headers,
      }
    );

    const response: AxiosResponse<{
      content: Data[];
      numberOfElements: number;
      size: number;
      number: number;
    }> = yield call(
      api.post,
      `/service-order/template/${payload.id}/${payload.step?.id}/all`,
      {
        page: (payload.pagination?.current || 1) - 1,
        size: payload.pagination?.pageSize || 12,
        filters: filterParamsHandle(payload.filters),
        sort: sorterParamsHandle(payload.sorter),
        params: filtered.map((item: any) => {
          return { name: item.name, value: item.value };
        }),
        idConfiguration: payload.id,
        search: payload.searchValue,
      },
      {
        headers: headers,
      }
    );
    yield put(
      loadListSuccess(responseConfig.data, response.data.content, {
        total: response.data.numberOfElements,
        current: response.data.number + 1,
        pageSize: response.data.size,
      })
    );
  } catch (err: any) {
    yield put(err.response.status !== 401 ? loadListFailure(errorHandler(err)) : refreshRequest());
  }
}

export function* sendServiceOrder({ payload }: ReturnType<typeof sendRequest>) {
  try {
    const state: ApplicationState = yield select();
    const headers = requestHeaders(state.auth.token);
    const response: AxiosResponse<{ id: number }> = yield call(
      api.post,
      'service-order',
      {
        idTemplate: payload.templateId,
        values: state.newServiceOrder.params,
        attachments: state.newServiceOrder.attachments,
      },
      {
        headers: headers,
      }
    );
    yield put(sendSuccess(response.data.id));
  } catch (err: any) {
    yield put(err.response.status !== 401 ? sendFailure(errorHandler(err)) : refreshRequest());
  }
}
