import { action } from 'typesafe-actions';
import { ResponseError } from '../../../services/api';
import { Config, Data } from '../../../utils/newTableHelper';
import { Pagination, ScreenConstructorTypes } from './types';

export const screenRequest = (
  id: number,
  pagination?: Pagination,
  filter?: any,
  sorter?: any,
  searchValue?: string
) => action(ScreenConstructorTypes.LOAD_REQUEST, { id, pagination, filter, sorter, searchValue });
export const loadSuccess = (pagination: Pagination, data: Data[], config: Config) =>
  action(ScreenConstructorTypes.LOAD_SUCCESS, { pagination, data, config });
export const loadFailure = (error: ResponseError) =>
  action(ScreenConstructorTypes.LOAD_FAILURE, { error });
