import { FilterDropdownProps } from 'antd/lib/table/interface';
import { DatePicker } from 'antd';
import moment from 'moment';
import locale from 'antd/es/date-picker/locale/pt_BR';
import 'moment/locale/pt-br';
const { RangePicker } = DatePicker;
const renderTableFilterDate = (dataIndex: string) => {
  return ({ setSelectedKeys, confirm }: FilterDropdownProps) => {
    const formatDate = (value: moment.MomentInput) => {
      return moment(value).format('DD/MM/YYYY HH:mm:ss');
    };

    const onChange = (value: any) => {
      const selectedKeys = value ? [formatDate(value[0]), formatDate(value[1])] : [value];
      setSelectedKeys(value ? [dataIndex, ...selectedKeys]: []);
      confirm();
    };
    return <RangePicker locale={locale} onChange={onChange} format="DD/MM/YYYY" />;
  };
};

export default renderTableFilterDate;
