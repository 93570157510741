import { call, put, select } from 'redux-saga/effects';
import { ApplicationState } from '../..';
import api, { errorHandler } from '../../../services/api';
import { requestHeaders } from '../../../utils/apiHelper';
import { refreshRequest } from '../auth/actions';
import { successReports, failureReports } from './actions';

export function* loadReports(): any {
  try {
    const state: ApplicationState = yield select();
    const headers = requestHeaders(state.auth.token);
    const response = yield call(api.get, 'relatorios', { headers: headers });
    yield put(successReports(response.data.content));
  } catch (err: any) {
    yield put(err.response.status !== 401 ? failureReports(errorHandler(err)) : refreshRequest());
  }
}
