export enum AdditionalScreenTypes {
  'LOAD_REQUEST' = '@additionalScreen/LOAD_REQUEST',
  'LOAD_SUCCESS' = '@additionalScreen/LOAD_SUCCESS',
  'LOAD_FAILURE' = '@additionalScreen/LOAD_FAILURE',
}

export interface AdditionalScreen {
  description: string;
  icon: string;
  id: number;
  edit: boolean;
  saveForm: boolean;
  showDetail: boolean;
  showListing: boolean;
  idFatherScreen?: number
}


export interface AdditionalScreenState {
  readonly screens: AdditionalScreen[];
  readonly loading: boolean;
  readonly error: boolean;
}
