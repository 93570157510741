import { Button, Dropdown, Menu, Tooltip } from 'antd';
import React, { useState } from 'react';
import { DownOutlined } from '@ant-design/icons';
import PdfDisplay from '../PdfDisplay';

export interface Props {
  value: any[];
  pdfUrl: string;
  disabled?: boolean;
  type?: 'link' | 'text' | 'ghost' | 'default' | 'primary' | 'dashed' | undefined;
  block?: boolean;
}

const ReportsDropdown: React.FC<Props> = ({ value, pdfUrl, disabled, type, block }) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [title, setTitle] = useState<string>('');
  const [url, setUrl] = useState<string>('');

  const showPdfDisplay = (key: string, title: string) => {
    if (pdfUrl === '' || pdfUrl === undefined) return;
    setTitle(title);
    setUrl(`${pdfUrl}/${key}`);
    setVisible(true);
  };
  const menu = (
    <Menu>
      {value?.map((item: any) => (
        <Menu.Item key={item.key}>
          <Tooltip placement="top" title={item.value}>
            <Button
              disabled={disabled}
              data-cy="btn-dropdown"
              type="link"
              size="small"
              onClick={() => showPdfDisplay(item.key, item.value)}
              style={{ color: 'black' }}
            >
              {item.value}
            </Button>
          </Tooltip>
        </Menu.Item>
      ))}
    </Menu>
  );
  return (
    <>
      <Dropdown disabled={value?.length === 0 || !value} trigger={['click']} overlay={menu}>
        <Button
          data-cy="btn-reports"
          type={type || 'link'}
          size={block ? 'middle' : 'small'}
          style={block ? {} : { padding: 0 }}
          block={block}
          onClick={(e) => e.preventDefault()}
        >
          Relatórios <DownOutlined />
        </Button>
      </Dropdown>
      <PdfDisplay title={title} url={url} visible={visible} setVisible={setVisible} />
    </>
  );
};

export default ReportsDropdown;
