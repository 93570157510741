import { Carousel, Col, Row, Skeleton, Spin } from 'antd';
import React, { useRef, useState } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import defaultImg from '../../assets/img/default.jpg';
import { ImageContainer, ImageContent, ThumbContainer, ThumbContent } from './styles';
import { ApplicationState } from '../../store';

export interface Props {
  thumbnails: string[];
  images: string[];
  loading: boolean;
}
const Gallery: React.FC<Props> = ({ thumbnails, images, loading }) => {
  const layout = useSelector((state: ApplicationState) => state.layout.data);
  const [current, setCurrent] = useState<number>(0);
  const sliderRef: any = useRef(null);

  const handleOnClick = (index: number) => {
    if (sliderRef) sliderRef.current.goTo(index);
  };

  const renderImages = (url: string, index: number) => {
    return (
      <Col span={24} key={index}>
        <ImageContainer>
          {loading ? (
            <Skeleton.Image />
          ) : (
            <ImageContent
              preview={false}
              src={url}
              alt="image"
              loading="lazy"
              fallback={defaultImg}
              placeholder={
                <Spin indicator={<LoadingOutlined style={{ color: layout?.color.default }} />} />
              }
            />
          )}
        </ImageContainer>
      </Col>
    );
  };
  const renderThumbnails = (url: string, index: number) => {
    return (
      <Col key={index} flex="none">
        <ThumbContainer onClick={() => handleOnClick(index)}>
          {loading ? (
            <Skeleton.Image />
          ) : (
            <ThumbContent
              preview={false}
              current={index === current}
              src={url}
              alt="image"
              loading="lazy"
              fallback={defaultImg}
              placeholder={
                <Spin indicator={<LoadingOutlined style={{ color: layout?.color.default }} />} />
              }
            />
          )}
        </ThumbContainer>
      </Col>
    );
  };
  return (
    <Row justify="center" align="middle">
      <Col
        xs={{ span: 24, order: 2 }}
        sm={{ span: 24, order: 2 }}
        md={{ span: 24, order: 2 }}
        lg={{ span: 4, order: 1 }}
        xl={{ span: 4, order: 1 }}
      >
        <Row justify="center" align="middle" gutter={[5, 5]}>
          {thumbnails.map(renderThumbnails)}
        </Row>
      </Col>
      <Col
        xs={{ span: 24, order: 1 }}
        sm={{ span: 24, order: 1 }}
        md={{ span: 24, order: 1 }}
        lg={{ span: 20, order: 2 }}
        xl={{ span: 20, order: 2 }}
      >
        <Carousel
          dots={false}
          ref={sliderRef}
          infinite
          effect="scrollx"
          beforeChange={(_current, next) => setCurrent(next)}
        >
          {images.map(renderImages)}
        </Carousel>
      </Col>
    </Row>
  );
};

export default Gallery;
