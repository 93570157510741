import { Reducer } from 'redux';
import { MyOrdersDetailTypes, MyOrdersDetailState } from './types';

const INITIAL_STATE: MyOrdersDetailState = {
  pagination: undefined,
  data: [],
  loading: false,
  loadingTable: false,
  dataDetail: [],
  steps: [],
};

const reducer: Reducer<MyOrdersDetailState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case MyOrdersDetailTypes.LOAD_REQUEST:
      return {
        ...state,
        loadingTable: true,
      };
    case MyOrdersDetailTypes.LOAD_SUCCESS:
      return {
        ...state,
        loadingTable: false,
        errorData: undefined,
        data: action.payload.data,
        config: action.payload.config,
        pagination: action.payload.pagination,
      };
    case MyOrdersDetailTypes.LOAD_FAILURE:
      return {
        ...state,
        data: [],
        loadingTable: false,
        errorData: action.payload.error,
      };
    case MyOrdersDetailTypes.DATA_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case MyOrdersDetailTypes.DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        errorDetails: undefined,
        upload: action.payload.upload,
        dataDetail: action.payload.data,
        configDetail: action.payload.config,
        uploadTypes: action.payload.uploadTypes,
      };
    case MyOrdersDetailTypes.DATA_FAILURE:
      return {
        ...state,
        dataDetail: [],
        loading: false,
        errorDetails: action.payload.error,
      };
    case MyOrdersDetailTypes.STEPS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case MyOrdersDetailTypes.STEPS_SUCCESS:
      return {
        ...state,
        loading: false,
        errorSteps: undefined,
        steps: action.payload.data,
      };
    case MyOrdersDetailTypes.STEPS_FAILURE:
      return {
        ...state,
        stpes: [],
        loading: false,
        errorSteps: action.payload.error,
      };
    default:
      return state;
  }
};

export default reducer;
