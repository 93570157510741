import { Reducer } from 'redux';
import { CampaignsState, CampaignsTypes } from './types';

const INITIAL_STATE: CampaignsState = {
  dataCampaigns: [],
  paginationCampaigns: undefined,
  loadingCampaigns: false,
  loadingCampaign: false,
};

const reducer: Reducer<CampaignsState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CampaignsTypes.CAMPAIGNS_REQUEST:
      return {
        ...state,
        loadingCampaigns: true,
      };
    case CampaignsTypes.CAMPAIGNS_SUCCESS:
      return {
        ...state,
        loadingCampaigns: false,
        errorCampaigns: undefined,
        dataCampaigns: action.payload.data,
        paginationCampaigns: action.payload.pagination,
      };
    case CampaignsTypes.CAMPAIGNS_FAILURE:
      return {
        ...state,
        dataCampaigns: [],
        loadingCampaigns: false,
        errorCampaigns: action.payload.error,
      };
    case CampaignsTypes.CAMPAIGN_REQUEST:
      return {
        ...state,
        loadingCampaign: true,
      };
    case CampaignsTypes.CAMPAIGN_SUCCESS:
      return {
        ...state,
        loadingCampaign: false,
        errorCampaign: undefined,
        dataCampaign: action.payload.data,
      };
    case CampaignsTypes.CAMPAIGN_FAILURE:
      return {
        ...state,
        loadingCampaign: false,
        dataCampaign: undefined,
        errorCampaign: action.payload.error,
      };
    default:
      return state;
  }
};

export default reducer;
