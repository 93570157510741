import { Reducer } from 'redux';

import { ScreenDetailState, ScreenDetailTypes } from './types';

const INITIAL_STATE: ScreenDetailState = {
  data: [],
  loading: true,
};

const reducer: Reducer<ScreenDetailState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ScreenDetailTypes.LOAD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ScreenDetailTypes.LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        ...action.payload,
      };
    case ScreenDetailTypes.LOAD_FAILURE:
      return {
        ...state,
        data: [],
        loading: false,
        ...action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
