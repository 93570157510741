import { createStore, applyMiddleware, Store } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { composeWithDevTools } from 'redux-devtools-extension';
import * as Sentry from '@sentry/react';
import rootReducer from './ducks/rootReducer';
import rootSaga from './ducks/rootSaga';
import { AuthState } from './ducks/auth/types';
import { LayoutState } from './ducks/layout/types';
import { UserState } from './ducks/user/types';
import { BannerState } from './ducks/banners/types';
import { HomeOrdersState } from './ducks/homeOrders/types';
import { HomeFinancialsState } from './ducks/homeFinancials/types';
import { OrderFieldsState } from './ducks/orderFields/types';
import { ProductsState } from './ducks/products/types';
import { ShoppingCartState } from './ducks/shoppingCart/types';
import { MyOrdersState } from './ducks/myOrders/types';
import { FinancialState } from './ducks/financial/types';
import { ServiceOrdersState } from './ducks/serviceOrders/types';
import { ServiceOrdersDetailState } from './ducks/serviceOrdersDetail/types';
import { AccessControlState } from './ducks/accessControl/types';
import { FaqState } from './ducks/faq/types';
import { MyOrdersDetailState } from './ducks/myOrdersDetail/types';
import { DataSheetState } from './ducks/dataSheet/types';
import { BudgetFieldsState } from './ducks/budgetFields/types';
import { OrderReleaseState } from './ducks/orderRelease/types';
import { CampaignsState } from './ducks/campaigns/types';
import { ReportsState } from './ducks/reports/types';
import { NewUserState } from './ducks/newUser/types';
import { NewContactState } from './ducks/newContact/types';
import { DeliveryPointState } from './ducks/deliveryPoint/types';
import { NewDeliveryPointState } from './ducks/newDeliveryPoint/types';
import { ProductState } from './ducks/productDetail/types';
import { HomeCampaignsState } from './ducks/homeCampaingns/types';
import { HomeServiceOrdersState } from './ducks/homeServiceOrders/types';
import { BudgetsState } from './ducks/budgets/types';
import { BudgetsDetailState } from './ducks/budgetDetail/types';
import { HomeProductsState } from './ducks/homeProducts/types';
import { HomeCardsState } from './ducks/homeCards/types';
import { NewServiceOrderState } from './ducks/newServiceOrder/types';
import { ContactsState } from './ducks/contacts/types';
import { ScreenFormState } from './ducks/screenForm/types';
import { ScreenConstructorState } from './ducks/screenConstructor/types';
import { DashboardState } from './ducks/dashboard/types';
import { DashboardMenuState } from './ducks/dashboardMenus/types';
import { CampaignProductsState } from './ducks/campaignProducts/types';
import { SignUpFieldsState } from './ducks/signUpFields/types';
import { ScreenEditState } from './ducks/screenEdit/types';
import { ProductsShowCaseState } from './ducks/showCase/types';
import { ProductDetailShowCaseState } from './ducks/showCaseDetail/types';
import { ScreenDetailState } from './ducks/screenDetail/types';
import { AdditionalScreenState } from './ducks/additionalScreens/types';

export interface ApplicationState {
  auth: AuthState;
  layout: LayoutState;
  user: UserState;
  banners: BannerState;
  homeOrders: HomeOrdersState;
  homeFinancials: HomeFinancialsState;
  orderFields: OrderFieldsState;
  products: ProductsState;
  shoppingCart: ShoppingCartState;
  myOrders: MyOrdersState;
  financial: FinancialState;
  serviceOrders: ServiceOrdersState;
  serviceOrdersDetail: ServiceOrdersDetailState;
  accessControl: AccessControlState;
  faq: FaqState;
  myOrdersDetail: MyOrdersDetailState;
  dataSheet: DataSheetState;
  budgetFields: BudgetFieldsState;
  orderRelease: OrderReleaseState;
  campaigns: CampaignsState;
  reports: ReportsState;
  newUser: NewUserState;
  newContact: NewContactState;
  deliveryPoint: DeliveryPointState;
  newDeliveryPoint: NewDeliveryPointState;
  productDetail: ProductState;
  homeCampaigns: HomeCampaignsState;
  homeServiceOrders: HomeServiceOrdersState;
  budgets: BudgetsState;
  budgetDetail: BudgetsDetailState;
  homeProducts: HomeProductsState;
  homeCards: HomeCardsState;
  newServiceOrder: NewServiceOrderState;
  contacts: ContactsState;
  screenForm: ScreenFormState;
  screenEdit: ScreenEditState;
  screenDetail: ScreenDetailState;
  screenConstructor: ScreenConstructorState;
  dashboard: DashboardState;
  dashboardMenu: DashboardMenuState;
  campaignProducts: CampaignProductsState;
  productsShowCase: ProductsShowCaseState;
  productDetailShowCase: ProductDetailShowCaseState;
  signUpFields: SignUpFieldsState;
  additionalScreens: AdditionalScreenState;
}

export interface PersistConfig {
  key: string;
  storage: typeof storage;
  blacklist: string[];
}

const persistConfig: PersistConfig = {
  key: 'root',
  storage,
  blacklist: [
    'user',
    'auth',
    'banners',
    'layout',
    'homeOrders',
    'homeFinancials',
    'orderFields',
    'shoppingCart',
    'products',
    'myOrders',
    'financial',
    'serviceOrders',
    'serviceOrdersDetail',
    'accessControl',
    'faq',
    'myOrdersDetail',
    'dataSheet',
    'orderRelease',
    'campaigns',
    'reports',
    'newUser',
    'newContact',
    'deliveryPoint',
    'newDeliveryPoint',
    'homeCampaigns',
    'productDetail',
    'homeServiceOrders',
    'budgets',
    'budgetDetail',
    'homeProducts',
    'homeCards',
    'newServiceOrder',
    'screenForm',
    'screenConstructor',
    'dashboard',
    'dashboardMenu',
    'campaignProducts',
    'productsShowCase',
    'productDetailShowCase',
    'signUpFields',
    'screenEdit',
    'screenDetail',
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const sagaMiddleare = createSagaMiddleware();

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  stateTransformer: (state: ApplicationState) => {
    const transformedState = {
      auth: {
        token: state.auth.token,
      },
    };

    return transformedState;
  },
});

const store: Store<ApplicationState> = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(sagaMiddleare), sentryReduxEnhancer)
);

const persistor = persistStore(store);

sagaMiddleare.run(rootSaga);
export { store, persistor };
