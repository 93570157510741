import React, { useEffect, useState, Dispatch } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Action } from 'typesafe-actions';
import { ColumnsType } from 'antd/es/table';

import { ApplicationState } from '../../../store';
import CustomTable from '../../../components/CustomTable';
import { configToColumnsType, dataToTableData } from '../../../utils/newTableHelper';
import ServerError from '../../../components/ServerError';
import { HomeServiceOrdersRequest } from '../../../store/ducks/homeServiceOrders/actions';
import Status from '../../../components/Status';

const ServiceOrdersTable: React.FC = () => {
  const token = useSelector((state: ApplicationState) => state.auth.token);
  const config = useSelector((state: ApplicationState) => state.homeServiceOrders.config);
  const error = useSelector((state: ApplicationState) => state.homeServiceOrders.error);
  const data = useSelector((state: ApplicationState) => state.homeServiceOrders.data);
  const loading = useSelector((state: ApplicationState) => state.homeServiceOrders.loading);
  const [columns, setColumns] = useState<ColumnsType<any>>([]);
  const dispatch = useDispatch<Dispatch<Action>>();

  useEffect(() => {
    dispatch(HomeServiceOrdersRequest());
  }, [dispatch, token]);

  useEffect(() => {
    setColumns(
      configToColumnsType(
        config?.fields.filter((item) => item.show) || [],
        [
          {
            id: -1,
            render: (value: number) => <Status value={value} config={config?.fields || []} />,
          },
        ],
        undefined,
        [0, -1],
        true,
        true
      )
    );
  }, [config]);

  return (
    <>
      {error ? (
        <ServerError {...error} />
      ) : (
        <CustomTable
          onChange={() => {}}
          loading={loading}
          columns={columns}
          data={dataToTableData(data, config)}
        />
      )}
    </>
  );
};

export default ServiceOrdersTable;
