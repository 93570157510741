import { Button, Input, InputNumber } from 'antd';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../store';
import { SubmitButton } from '../../../styles';

import { Buttons, Container } from './styles';
const renderTableFilterRangeCurrency = (dataIndex: string) => {
  return ({ setSelectedKeys, confirm }: FilterDropdownProps) => {
    const layout = useSelector((state: ApplicationState) => state.layout.data);
    const [to, setTo] = useState<number>(0);
    const [from, setFrom] = useState<number>(0);

    const onChange = (value: any, fieldName: string) => {
      if (fieldName === 'from') {
        setFrom(value);
      } else {
        setTo(value);
      }
    };

    const onConfirm = () => {
      if ((!from && from !== 0) || (!to && to !== 0)) {
        return;
      }
      setSelectedKeys([dataIndex, from, to]);
      confirm();
    };

    const onClear = () => {
      setTo(0);
      setFrom(0);
      setSelectedKeys([]);
      confirm();
    };
    return (
      <Container>
        <Input.Group compact>
          <InputNumber
            name="from"
            value={from}
            formatter={(value) => `R$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value): number => Number(value ? value.replace(/R\$\s?|(,*)/g, '') : 0)}
            onChange={(value) => onChange(value || 0, 'from')}
            min={0}
            required
          />
          <InputNumber
            name="to"
            value={to}
            formatter={(value) => `R$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value): number => Number(value ? value.replace(/R\$\s?|(,*)/g, '') : 0)}
            onChange={(value) => onChange(value || 0, 'to')}
            min={from}
            required
          />
        </Input.Group>

        <Buttons>
          <Button danger onClick={onClear}>
            Limpar
          </Button>
          <SubmitButton layout={layout} type="primary" onClick={onConfirm}>
            OK
          </SubmitButton>
        </Buttons>
      </Container>
    );
  };
};

export default renderTableFilterRangeCurrency;
