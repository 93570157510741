import { action } from 'typesafe-actions';
import { AuthTypes, Auth, PartnerAuth } from './types';

export const loginRequest = (auth: Auth) => action(AuthTypes.LOGIN_REQUEST, { auth });
export const loginSuccess = (token: string, refreshToken: string) =>
  action(AuthTypes.LOGIN_SUCCESS, { token, refreshToken });
export const loginFailure = (errorMessage: string, statusFailure: number, userName: string) =>
  action(AuthTypes.LOGIN_FAILURE, { errorMessage, statusFailure, userName });

export const updateRequest = (partnerAuth?: PartnerAuth) =>
  action(AuthTypes.UPDATE_REQUEST, { partnerAuth });
export const updateSuccess = (token: string, refreshToken: string) =>
  action(AuthTypes.UPDATE_SUCCESS, { token, refreshToken });
export const updateFailure = (errorMessage: string) =>
  action(AuthTypes.UPDATE_FAILURE, { errorMessage });

export const passwordRequest = (auth: Auth) => action(AuthTypes.PASSWORD_REQUEST, { auth });
export const passwordSuccess = (forgotPasswordMessage: string) =>
  action(AuthTypes.PASSWORD_SUCCESS, { forgotPasswordMessage });
export const passwordFailure = (errorMessage: string) =>
  action(AuthTypes.PASSWORD_FAILURE, { errorMessage });

export const changePasswordRequest = (email: string, currentPassword: string, newPassword: string) =>
  action(AuthTypes.CHANGE_PASSWORD_REQUEST, { email, currentPassword, newPassword });
export const changePasswordSuccess = (changePasswordMessage: string) =>
  action(AuthTypes.CHANGE_PASSWORD_SUCCESS, { changePasswordMessage });
export const changePasswordFailure = (errorMessage: string) =>
  action(AuthTypes.CHANGE_PASSWORD_FAILURE, { errorMessage });

export const newPasswordRequest = (key: string, password: string) =>
  action(AuthTypes.NEW_PASSWORD_REQUEST, { key, password });
export const newPasswordSuccess = (successMessage: string) =>
  action(AuthTypes.NEW_PASSWORD_SUCCESS, { successMessage });
export const newPasswordFailure = (errorMessage: string) =>
  action(AuthTypes.NEW_PASSWORD_FAILURE, { errorMessage });

export const refreshRequest = () => action(AuthTypes.REFRESH_REQUEST);
export const refreshSuccess = (token: string, refreshToken: string) =>
  action(AuthTypes.REFRESH_SUCCESS, { token, refreshToken });
export const refreshhFailure = () => action(AuthTypes.REFRESH_FAILURE);

export const cleanState = () => action(AuthTypes.CLEAN_STATE);
