import { ResponseError } from '../../../services/api';
import { Campaign } from '../campaigns/types';

export enum HomeCampaignsTypes {
  'HOME_CAMPAIGNS_REQUEST' = '@campaigns/HOME_CAMPAIGNS_REQUEST',
  'HOME_CAMPAIGNS_SUCCESS' = '@campaigns/HOME_CAMPAIGNS_SUCCESS',
  'HOME_CAMPAIGNS_FAILURE' = '@campaigns/HOME_CAMPAIGNS_FAILURE',
}


export interface HomeCampaignsState {
  readonly data: Campaign[];
  readonly loading: boolean;
  readonly error?: ResponseError;
}
