import moment from 'moment';

export const maskDate = (value: Date): string => {
  return value ? moment(value)?.format('DD/MM/YYYY') : '';
};

export const maskDateTime = (value: Date): string => {
  return value ? moment(value)?.format('DD/MM/YYYY HH:mm') : '';
};

const allReplace = (originalString: string, obj: any) => {
  for (var x in obj) {
    originalString = originalString?.replace(new RegExp(x, 'g'), obj[x]);
  }
  return originalString;
};

export const unmask = (value: string) => {
  if (!value) {
    return value;
  }
  return allReplace(value, { ' ': '', '-': '', _: '', '\\(': '', '\\)': '', '\\/': '', '\\.': '' });
};

export const inputMaskCofCnpj = (value: string) => {
  if (unmask(value)?.length <= 11)
    return [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/];
  else
    return [
      /\d/,
      /\d/,
      '.',
      /\d/,
      /\d/,
      /\d/,
      '.',
      /\d/,
      /\d/,
      /\d/,
      '/',
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      '-',
      /\d/,
      /\d/,
    ];
};

export const phoneMask = (rawValue: string) => {
  const unmaskedValueLength = unmask(rawValue)?.length;
  if (unmaskedValueLength <= 10) {
    return ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  }
  return ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
};

export const cepMask = () => [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/];

export const maskCurrency = (value: number | number[]): string => {
  if (!value && value !== 0) {
    return `${value}`;
  }

  if (Array.isArray(value)) {
    const values = value.map((item) => {
      return `R$
        ${item.toLocaleString('pt-BR', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`;
    });
    return `${values[0]} - ${values[1]}`;
  } else
    return `R$
      ${value.toLocaleString('pt-BR', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })}`;
};

export const maskFloating = (value: number | number[], FractionDigits: number): string => {
  if (!value && value !== 0) {
    return `${value}`;
  }
  return value.toLocaleString('pt-BR', {
    minimumFractionDigits: FractionDigits,
    maximumFractionDigits: FractionDigits,
  });
};

export const maskPhone = (value: string): string => {
  return value;
};

export const maskCpfCnpj = (value: string): string => {
  if (!value) {
    return value;
  }

  if (value.length === 11) {
    return `${value.slice(0, 3)}.${value.slice(3, 6)}.${value.slice(6, 9)}-${value.slice(9, 12)}`;
  } else if (value.length === 14) {
    return `${value.slice(0, 2)}.${value.slice(2, 5)}.${value.slice(5, 8)}/${value.slice(
      8,
      12
    )}-${value.slice(12, 15)}`;
  } else return value;
};

export const maskCreditCard = (value: string) => {
  if (unmask(value)?.length === 14)
    return [/\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/];

  if (unmask(value)?.length === 15)
    return [/\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/];


  return [/\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/];
  // https://stackoverflow.com/a/25102559/8564614
};

export const maskCamelCase = (value: string): string => {
  if (!value || !value.split) {
    return value;
  }

  let values = value.split(' ');
  let result = '';

  values.forEach((v) => {
    let substring;
    if (
      v.toLowerCase() === 'a' ||
      v.toLowerCase() === 'e' ||
      v.toLowerCase() === 'o' ||
      v.toLowerCase() === 'ao' ||
      v.toLowerCase() === 'da' ||
      v.toLowerCase() === 'de' ||
      v.toLowerCase() === 'do'
    ) {
      substring = v.toLowerCase();
    } else {
      substring = v.substr(0, 1).toUpperCase() + v.substr(1).toLowerCase();
    }

    result += substring + ' ';
  });

  return result;
};

export const mask = (maskType: string, value: any): string => {
  switch (maskType) {
    case 'date':
      return maskDate(value);

    case 'dateTime':
      return maskDateTime(value);

    case 'currency':
      return maskCurrency(value);

    case 'phone':
      return maskPhone(value);

    case 'cpfCnpj':
      return maskCpfCnpj(value);

    case 'camelCase':
      return maskCamelCase(value);
  }

  return value;
};

export default mask;
