import { AxiosResponse } from 'axios';
import { call, put, select } from 'redux-saga/effects';
import { ApplicationState } from '../..';
import api, { errorHandler } from '../../../services/api';
import { requestHeaders } from '../../../utils/apiHelper';
import { Data } from '../../../utils/newTableHelper';
import { refreshRequest } from '../auth/actions';
import {
  requestReleases,
  loadSuccess,
  loadFailure,
  requestApprove,
  approveFailure,
  approveSuccess,
  requestDeny,
  denySuccess,
  denyFailure,
} from './actions';

export function* loadReleases({ payload }: ReturnType<typeof requestReleases>) {
  try {
    const state: ApplicationState = yield select();
    const headers = requestHeaders(state.auth.token);
    const response: AxiosResponse<{
      content: Data[];
      numberOfElements: number;
      size: number;
      number: number;
    }> = yield call(
      api.post,
      '/order-release/all',
      {
        page: (payload.pagination?.current || 1) - 1,
        size: payload.pagination?.pageSize || 12,
        search: payload.search,
      },
      {
        headers: headers,
      }
    );
    yield put(
      loadSuccess(
        {
          total: response.data.numberOfElements,
          current: response.data.number + 1,
          pageSize: response.data.size,
        },
        response.data.content
      )
    );
  } catch (err: any) {
    yield put(err.response.status !== 401 ? loadFailure(errorHandler(err)) : refreshRequest());
  }
}

export function* loadApprove({ payload }: ReturnType<typeof requestApprove>) {
  try {
    const state: ApplicationState = yield select();
    const headers = requestHeaders(state.auth.token);
    yield call(api.get, `/order-release/${payload.id}/approved`, {
      headers: headers,
    });
    yield put(requestReleases(state.orderRelease.pagination, payload.search));
    yield put(approveSuccess('approved'));
  } catch (err: any) {
    yield put(err.response.status !== 401 ? approveFailure(errorHandler(err)) : refreshRequest());
  }
}

export function* loadDeny({ payload }: ReturnType<typeof requestDeny>) {
  try {
    const state: ApplicationState = yield select();
    const headers = requestHeaders(state.auth.token);
    yield call(api.get, `/order-release/${payload.id}/denied`, {
      headers: headers,
    });
    yield put(requestReleases(state.orderRelease.pagination, payload.search));
    yield put(denySuccess('denied'));
  } catch (err: any) {
    yield put(err.response.status !== 401 ? denyFailure(errorHandler(err)) : refreshRequest());
  }
}
