import { action } from 'typesafe-actions';
import { ResponseError } from '../../../services/api';
import { Data } from '../../../utils/newTableHelper';
import { Pagination, OrderReleaseTypes } from './types';

export const requestReleases = (pagination?: Pagination, search?: string) =>
  action(OrderReleaseTypes.RELEASES_REQUEST, { pagination, search });
export const loadSuccess = (pagination: Pagination, data: Data[]) =>
  action(OrderReleaseTypes.RELEASES_SUCCESS, { pagination, data });
export const loadFailure = (error: ResponseError) =>
  action(OrderReleaseTypes.RELEASES_FAILURE, { error });

export const requestApprove = (id: number, search?: string) =>
  action(OrderReleaseTypes.APPROVE_REQUEST, { id, search });
export const approveSuccess = (status?: 'approved') =>
  action(OrderReleaseTypes.APPROVE_SUCCESS, { status });
export const approveFailure = (statusError: ResponseError) =>
  action(OrderReleaseTypes.APPROVE_FAILURE, { statusError });

export const requestDeny = (id: number, search?: string) =>
  action(OrderReleaseTypes.DENY_REQUEST, { id, search });
export const denySuccess = (status?: 'denied') =>
  action(OrderReleaseTypes.DENY_SUCCESS, { status });
export const denyFailure = (statusError: ResponseError) =>
  action(OrderReleaseTypes.DENY_FAILURE, { statusError });

export const cleanStatus = () => action(OrderReleaseTypes.CLEAN_STATUS);
