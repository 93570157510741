import { ResponseError } from '../../../services/api';

export enum ScreenEditTypes {
  'LOAD_REQUEST' = '@screenEdit/LOAD_REQUEST',
  'LOAD_SUCCESS' = '@screenEdit/LOAD_SUCCESS',
  'LOAD_FAILURE' = '@screenEdit/LOAD_FAILURE',

  'SEND_REQUEST' = '@screenEdit/SEND_REQUEST',
  'SEND_SUCCESS' = '@screenEdit/SEND_SUCCESS',
  'SEND_FAILURE' = '@screenEdit/SEND_FAILURE',

  'CLEAN_STATE' = '@screenEdit/CLEAN_STATE',
}

export interface Fields {
  columnSize: number;
  defaultValue?: any;
  description: string;
  enable?: boolean;
  fieldName: string;
  fieldType:
    | 'BLOB'
    | 'OPTIONS'
    | 'CLOB'
    | 'STRING'
    | 'INTEGER'
    | 'FLOAT'
    | 'DATE'
    | 'DATE_TIME'
    | 'TIME'
    | 'BLOB_IMAGE'
    | 'TEXT_AREA';
  foreignEntity: string;
  grouping: string;
  id: number;
  idField: number;
  mask?: 'currency' | 'cpfCnpj' | 'phone' | 'date' | 'dateTime' | 'cep' | 'email';
  position: number;
  required?: boolean;
  tableName: string;
  visible?: boolean;
  allowEdit?: boolean;
  value?: any;
}

export interface ScreenEdit {
  title: string;
  fields: Fields[];
}

export interface Success {
  id: number;
  message: string;
}
export interface SendObject {
  id?: number;
  name: string;
  value: any;
}

export interface ScreenEditState {
  readonly data: ScreenEdit[];
  readonly sendLoading: boolean;
  readonly success?: Success;
  readonly sendError?: ResponseError;
  readonly loading: boolean;
  readonly error?: ResponseError;
}
