import React, { Dispatch, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Action } from 'typesafe-actions';
import {
  FileTextOutlined,
  LoadingOutlined,
  DownloadOutlined,
  LeftOutlined,
} from '@ant-design/icons';

import { Size, useWindowSize } from '../../customHooks';
import { ApplicationState } from '../../store';
import { Button, Col, Divider, Row, Select, Spin, Tooltip } from 'antd';
import ServerError from '../../components/ServerError';
import {
  Content,
  DescriptionContent,
  InputContent,
  TextContent,
  TextDisplay,
  Value,
} from './styles';
import Gallery from '../../components/Gallery';
import { colors } from '../../utils/constants';
import PromoTag from '../../components/PromoTag';
import { maskCamelCase, maskCurrency } from '../../utils/masks';
import CustomCollapse from '../../components/CustomCollapse';
import ProductsCarousel from '../../components/ProductCarousel';
import {
  loadRequestShowCaseDetail,
  setUnityDetail,
} from '../../store/ducks/showCaseDetail/actions';
import { KeyValue } from '../ShoppingCart';

const { Option } = Select;

const ShowCaseDetail: React.FC = () => {
  const { id }: any = useParams();
  const codprod = parseInt(id);
  const product = useSelector((state: ApplicationState) => state.productDetailShowCase.data[0]);
  const layout = useSelector((state: ApplicationState) => state.layout.data);
  const error = useSelector((state: ApplicationState) => state.productDetailShowCase.error);
  const images = useSelector((state: ApplicationState) => state.productDetailShowCase.images);
  const thumbnails = useSelector(
    (state: ApplicationState) => state.productDetailShowCase.thumbnails
  );
  const loading = useSelector((state: ApplicationState) => state.productDetailShowCase.loading);
  const relatedProducts = useSelector(
    (state: ApplicationState) => state.productDetail.relatedProducts
  );

  const history = useHistory();
  const dispatch = useDispatch<Dispatch<Action>>();

  const size: Size = useWindowSize();

  useEffect(() => {
    dispatch(loadRequestShowCaseDetail(codprod));
  }, [dispatch, codprod]);

  const getStock = () =>
    Math.floor(
      product?.ESTOQUE.find(({ key }: any) => key === product?.SELECTED_UNITY.key || 0)?.value
    );

  const getValue = () =>
    product?.VALOR.find(({ key }: any) => key === product?.SELECTED_UNITY.key || 0)?.value;

  const DataSheetClick = (e: any) => {
    e.preventDefault();
    const win = window.open(product?.DETAILS.specifications, '_blank');
    win && win.focus();
  };
  return (
    <Spin
      style={{ color: layout?.color.default }}
      spinning={loading}
      size="large"
      delay={500}
      indicator={<LoadingOutlined />}
    >
      {error ? (
        <ServerError {...error} />
      ) : (
        <Content>
          <Button icon={<LeftOutlined />} onClick={() => history.goBack()} type="link">
            Voltar
          </Button>
          <Row justify="center" align="middle">
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Gallery images={images} thumbnails={thumbnails} loading={loading} />
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Row>
                <DescriptionContent>
                  <h2>{product?.DESCRPROD}</h2>
                  <div>
                    <span style={{ color: colors.writeGrey }}>
                      Código:
                      <strong style={{ marginLeft: '5px', marginRight: '5px' }}>
                        {product?.CODPROD}{' '}
                      </strong>
                      {product?.ESTOQUE_SHOW && (
                        <>
                          - Disponível:
                          <strong style={{ marginLeft: '5px', marginRight: '5px' }}>
                            {getStock()}
                          </strong>
                        </>
                      )}
                    </span>
                  </div>
                  {product?.REFFORN && (
                    <span style={{ color: colors.writeGrey }}>
                      Referência:
                      <strong style={{ marginLeft: '5px', marginRight: '5px' }}>
                        {product?.REFFORN}
                      </strong>
                      {product?.MARCA && (
                        <Divider
                          style={{
                            margin: '0 5px 0 0',
                            backgroundColor: colors.writeGrey,
                            width: '1px',
                          }}
                          type="vertical"
                        />
                      )}
                    </span>
                  )}
                  {product?.MARCA && (
                    <span style={{ color: colors.writeGrey }}>
                      Marca:
                      <strong style={{ marginLeft: '5px', marginRight: '5px' }}>
                        {product?.MARCA}
                      </strong>
                    </span>
                  )}
                  {product?.VALOR && (
                    <Row align="middle" gutter={[10, 10]}>
                      {product?.PROMOCAO?.isPromo && (
                        <Col>
                          <PromoTag />
                        </Col>
                      )}
                      <Col>
                        <Value>
                          {product?.DETAILS.stockControlType === 'GRADE' && getValue() === 0
                            ? 'Indisponível'
                            : maskCurrency(getValue())}
                        </Value>
                      </Col>
                    </Row>
                  )}
                </DescriptionContent>
                <Divider />
                <InputContent>
                  {product?.VALOR && (
                    <Row>
                      <Select
                        data-cy="input-selectUnity"
                        style={{ marginRight: '20px' }}
                        defaultValue={product?.SELECTED_UNITY.key}
                        value={product?.SELECTED_UNITY.key}
                        onChange={(value) => dispatch(setUnityDetail(codprod, value))}
                      >
                        {product?.UNIDADE?.map((element: KeyValue) => {
                          return (
                            <Option key={element.key} value={element.key}>
                              {(size?.width ?? 0) > 1024
                                ? maskCamelCase(element.value as string)
                                : element.key}
                            </Option>
                          );
                        })}
                      </Select>
                    </Row>
                  )}
                  <>
                    {!product?.DETAILS.specifications ? (
                      <Tooltip title="Não possui ficha técnica">
                        <Button
                          data-cy="btn-openDatasheet"
                          icon={<DownloadOutlined />}
                          type="link"
                          size="small"
                          disabled
                        >
                          Ficha técnica
                        </Button>
                      </Tooltip>
                    ) : (
                      <Button
                        data-cy="btn-openDatasheet"
                        icon={<DownloadOutlined />}
                        type="link"
                        onClick={(e) => {
                          DataSheetClick(e);
                        }}
                      >
                        Ficha técnica
                      </Button>
                    )}
                  </>
                </InputContent>
              </Row>
            </Col>
          </Row>
        </Content>
      )}
      {product?.CARACTERIS && (
        <TextContent>
          <CustomCollapse
            title="Características"
            defaultOpened
            icon={<FileTextOutlined style={{ color: layout?.color.default }} />}
          >
            <TextDisplay>{product?.CARACTERIS}</TextDisplay>
          </CustomCollapse>
        </TextContent>
      )}
      {relatedProducts.length > 0 && (
        <>
          <h2 style={{ color: layout?.color.dark }}>Produtos Relacionados</h2>
          <ProductsCarousel products={relatedProducts} loading={loading} />
        </>
      )}
    </Spin>
  );
};

export default ShowCaseDetail;
