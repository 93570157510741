import React, { Dispatch } from 'react';
import { Input } from 'antd';
import { useDispatch } from 'react-redux';
import { Action } from 'typesafe-actions';
import { requestReleases } from '../../../store/ducks/orderRelease/actions';
const { Search } = Input;
interface Props {
  setSearch: any;
}

const SearchInput: React.FC<Props> = ({ setSearch }) => {
  const dispatch = useDispatch<Dispatch<Action>>();
  const onSearch = (value: any) => {
    setSearch(value);
    dispatch(requestReleases(undefined, value));
  };

  return (
    <Search
      data-cy="input-search"
      placeholder="Buscar"
      allowClear
      onSearch={onSearch}
      style={{ width: 400 }}
    />
  );
};

export default SearchInput;
