import React, { Dispatch } from 'react';
import { Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Action } from 'redux';
import { requestFaq } from '../../../store/ducks/faq/actions';
import { ApplicationState } from '../../../store';
import { useWindowSize } from '../../../customHooks';
import { Fields } from '../../../store/ducks/faq/types';


interface props {
  search: any;
  setCategory: any;
}

const FAQCategory: React.FC<props> = ({ search, setCategory }) => {
  const dataCategory = useSelector((state: ApplicationState) => state.faq.dataCategory);
  const loading = useSelector((state: ApplicationState) => state.faq.loading);
  const dispatch = useDispatch<Dispatch<Action>>();
  const size = useWindowSize();

  return (
    <Select
      data-cy="input-category"
      loading={loading}
      allowClear
      placeholder="Selecione uma opção"
      style={{
        width: (size.width ?? 0) > 1024 ? '400px' : '100%',
        marginRight: (size.width ?? 0) > 1024 ? '10px' : '0px',
      }}
      onChange={(value) => {
        const fieldsArr: Fields[] = [];
        setCategory(value);
        if (search) fieldsArr.push({ name: 'titulo', value: search });
        if (value) fieldsArr.push({ name: 'codFaqPai', value: value.toString() });
        dispatch(requestFaq(undefined, fieldsArr ? fieldsArr : undefined));
      }}
    >
      {dataCategory?.map((data) => {
        return <Select.Option key={data.key} value={data.key}>{data.value}</Select.Option>;
      })}
    </Select>
  );
};

export default FAQCategory;
