import { ResponseError } from '../../../services/api';

export enum FaqTypes {
  'CATEGORY_REQUEST' = '@faq/CATEGORY_REQUEST',
  'CATEGORY_SUCCESS' = '@faq/CATEGORY_SUCCESS',
  'CATEGORY_FAILURE' = '@faq/CATEGORY_FAILURE',

  'FAQ_REQUEST' = '@faq/FAQ_REQUEST',
  'FAQ_SUCCESS' = '@faq/FAQ_SUCCESS',
  'FAQ_FAILURE' = '@faq/FAQ_FAILURE',

  'CALL_REQUEST' = '@faq/CALL_REQUEST',
  'CALL_SUCCESS' = '@faq/CALL_SUCCESS',
  'CALL_FAILURE' = '@faq/CALL_FAILURE',

  'SEND_CALL_REQUEST' = '@faq/SEND_CALL_REQUEST',
  'SEND_CALL_SUCCESS' = '@faq/SEND_CALL_SUCCESS',
  'SEND_CALL_FAILURE' = '@faq/SEND_CALL_FAILURE',
}

export interface Pagination {
  current: number;
  total: number;
  pageSize: number;
}

export interface Faq {
  categoryId: number;
  faqId: number;
  fatherTitle: string;
  isAnalytical: boolean;
  solution: string;
  title: string;
}

export interface Fields {
  name: string;
  value: string;
}

export interface Call {
  callDate: Date;
  callId: number;
  category: number;
  contactId: number;
  partnerId: number;
  situation: string;
  status: 'OPEN' | 'COMPLETED';
  updateDate: Date;
  userId: number;
  solution?: string;
}

export interface Category {
  key: number;
  value: string;
}

export interface SendCall {
  situation: string;
  category: number;
}

export interface FaqState {
  readonly dataCategory: Category[];
  readonly dataFaq: Faq[];
  readonly dataCall: Call[];
  readonly paginationCall?: Pagination;
  readonly paginationFaq?: Pagination;
  readonly loading: boolean;
  readonly loadingCall: boolean;
  readonly loadingFaq: boolean;
  readonly loadingSend: boolean;
  readonly errorCategory?: ResponseError;
  readonly errorCall?: ResponseError;
  readonly errorFaq?: ResponseError;
  readonly errorSend?: ResponseError;
}
