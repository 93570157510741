import React from 'react';
import { Pie } from '@ant-design/plots';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../store';
import EmptyChart from '../EmptyChart';
interface Props {
  title: string;
  data: any[];
  angleField: string;
  colorField: string;
  onClick(values: any): void;
}
const PieChart: React.FC<Props> = ({ title, data, angleField, colorField, onClick }) => {
  const layout = useSelector((state: ApplicationState) => state.layout.data);

  const config = {
    appendPadding: 10,
    data,
    angleField: angleField,
    colorField: colorField,
    radius: 0.8,
    label: {
      type: 'outer',
      content: '{name} {percentage}',
    },
    interactions: [
      {
        type: 'pie-legend-active',
      },
      {
        type: 'element-active',
      },
    ],
    onReady: (plot: any) => {
      plot.on('element:click', ({ data: { data } }: any) => {
        onClick(data);
      });
    }
  };
  return data.length === 0 ? (
    <EmptyChart type={'pizza'} />
  ) : (
    <div style={{ padding: '20px' }}>
      <h2 style={{ color: layout?.color.dark }}>{title}</h2>
      <Pie {...config} />
    </div>
  );
};

export default PieChart;
