import { Button, Form, Input } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { Dispatch, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Action } from 'typesafe-actions';
import { SaveOutlined } from '@ant-design/icons';
import { useWindowSize } from '../../../customHooks';
import { ApplicationState } from '../../../store';
import { passwordRequest } from '../../../store/ducks/user/actions';
import { FormItem } from '../styles';
import { MESSAGES } from '../../../utils/constants';

const Security: React.FC = () => {
  const user = useSelector((state: ApplicationState) => state.user.data);
  const loading = useSelector((state: ApplicationState) => state.user.loading);
  const dispatch = useDispatch<Dispatch<Action>>();
  const size = useWindowSize();
  const [form] = useForm();

  const onFinish = (values: any) => {
    if (!values) return;
    dispatch(
      passwordRequest(values.currentPassword, values.newPassword, values.confirmationPassword)
    );
  };
  useEffect(() => {
    form.resetFields();
  }, [form, user]);
  return (
    <>
      <h4>Alterar senha</h4>
      <Form
        form={form}
        wrapperCol={{ span: (size.width ?? 0) <= 1024 ? 24 : 14 }}
        layout="vertical"
        name="basic"
        onFinish={onFinish}
      >
        <FormItem initialValue={user?.email} name="email" label="E-mail">
          <Input type="email" data-cy="input-email" disabled />
        </FormItem>

        <FormItem
          rules={[{ required: true, message: 'Por favor digite a senha atual' }]}
          name="currentPassword"
          label="Senha atual"
        >
          <Input.Password data-cy="input-password" />
        </FormItem>
        <FormItem
          hasFeedback
          dependencies={['password']}
          rules={[
            {
              required: true,
              message: 'Por favor digite a nova senha!',
            },
            () => ({
              validator(_, value: string) {
                if (!value || value.match(/^(?=.{8,}$)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).*$/)) {
                  return Promise.resolve();
                }
                return Promise.reject(MESSAGES.PASSWORD_ERROR);
              },
            }),
          ]}
          name="newPassword"
          label="Nova senha"
        >
          <Input.Password data-cy="input-newPassword" />
        </FormItem>
        <FormItem
          hasFeedback
          rules={[
            {
              required: true,
              message: 'Por favor confirme a nova senha!',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('newPassword') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('As duas senhas devem ser iguais!'));
              },
            }),
          ]}
          name="confirmationPassword"
          dependencies={['newPassword']}
          label="Confirmar senha"
        >
          <Input.Password data-cy="input-newPassword" />
        </FormItem>

        <FormItem>
          <Button
            data-cy="btn-save"
            icon={<SaveOutlined />}
            block={(size.width ?? 0) <= 1024}
            type="primary"
            htmlType="submit"
            loading={loading}
          >
            Salvar
          </Button>
        </FormItem>
      </Form>
    </>
  );
};

export default Security;
