import React from 'react';

import {
  PieChartOutlined,
  LineChartOutlined,
  RadarChartOutlined,
  AreaChartOutlined,
  BarChartOutlined,
  FunnelPlotOutlined,
  DotChartOutlined,
  DashboardOutlined,
} from '@ant-design/icons';
import { Col, Row } from 'antd';
import { colors } from '../../../utils/constants';
interface Props {
  type: string;
}
const EmptyChart: React.FC<Props> = ({ type }) => {
  const getIconByType = () => {
    switch (type) {
      case 'pizza':
      case 'donut':
        return <PieChartOutlined style={{ fontSize: '150px', color: colors.grey }} />;
      case 'line':
        return <LineChartOutlined style={{ fontSize: '150px', color: colors.grey }} />;
      case 'radar':
        return <RadarChartOutlined style={{ fontSize: '150px', color: colors.grey }} />;
      case 'area':
        return <AreaChartOutlined style={{ fontSize: '150px', color: colors.grey }} />;
      case 'scatter':
      case 'bubble':
        return <DotChartOutlined style={{ fontSize: '150px', color: colors.grey }} />;
      case 'funnel':
        return <FunnelPlotOutlined style={{ fontSize: '150px', color: colors.grey }} />;
      case 'gauge':
        return <DashboardOutlined style={{ fontSize: '150px', color: colors.grey }} />;
      default:
        return <BarChartOutlined style={{ fontSize: '150px', color: colors.grey }} />;
    }
  };
  return (
    <Row
      gutter={[20, 20]}
      align="middle"
      justify="center"
      style={{ padding: '20px', height: '450.49px' }}
    >
      <Col span={24}>
        <Row align="middle" justify="center">
          {getIconByType()}
        </Row>
      </Col>
      <Col span={24}>
        <Row align="middle" justify="center">
          <h2 style={{ color: colors.writeGrey }}>Não foi possível carregar o gráfico!</h2>
        </Row>
      </Col>
    </Row>
  );
};

export default EmptyChart;
