import { Card, Row } from 'antd';
import React from 'react';
import { Container, Content, Title, Value } from './styles';

const CardView: React.FC<any> = (item) => {
  return (
    <Card size="small">
      <Container>
        <Content>
          {Object.keys(item).map((key) => {
            return (
              <div>
                {!item[key]?.render && (
                  <Row>
                    <Title>{item[key]?.title} </Title>
                    <Value>{item[key]?.value}</Value>
                  </Row>
                )}
                {item[key]?.render && key !== 'action' && (
                  <Row>
                    <Title>{item[key]?.title}</Title>
                    <Value>{item[key]?.render(item[key]?.value, item[key]?.record)}</Value>
                  </Row>
                )}
              </div>
            );
          })}
        </Content>
        <Content>
          {Object.keys(item).map((key) => {
            return (
              <div>{key === 'action' && <div>{item[key]?.render(item[key]?.record)}</div>}</div>
            );
          })}
        </Content>
      </Container>
    </Card>
  );
};

export default CardView;
