import styled from 'styled-components';

export const TotalContainer = styled.div<{ validValue?: boolean }>`
  background-color: ${({ validValue }) => (validValue ? '#fcffe6' : '#FFF1F1')};
  border-radius: 5px;
  color: ${({ validValue }) => (validValue ? '#5B8C00' : '#FF4D4F')};
  border: 1px solid ${({ validValue }) => (validValue ? '#e1ecbb' : '#FFCCC7')};
  padding: 10px;
  margin-bottom: 10px;
`;
export const MessagesContainer = styled.div`
  border-radius: 5px;
  border: 1px solid #dcdcdc;
  padding: 10px;
  width: 100%;
  margin-bottom: 10px;
`;
