import styled from "styled-components";
import { colors } from "../../utils/constants";

export const Container = styled.div`
  background-color: #fff;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 5px;
  box-shadow: 1px 2px 2px 2px ${colors.grey};
`;
