import React, { Dispatch, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ColumnsType } from 'antd/es/table';
import { Action } from 'typesafe-actions';
import { Col, Row, Select } from 'antd';
import { useHistory } from 'react-router-dom';

import { ApplicationState } from '../../../store';
import { ActiveFavoriteIcon, FavoriteContent, FavoriteIcon } from '../styles';
import CustomTable from '../../../components/CustomTable';
import { configToColumnsType } from '../../../utils/newTableHelper';
import { favoriteRequest, loadRequest, setUnity } from '../../../store/ducks/products/actions';
import { maskCurrency } from '../../../utils/masks';
import { ShoppingCartItem, Unity } from '../../../store/ducks/shoppingCart/types';
import QuantityInput from '../../../components/QuantityInput';
import MetreageCalculator from '../../../components/NewOrderModals/MetreageCalculator';
import ModalGrid from '../../../components/NewOrderModals/ModalGrid';
import MattressModal from '../../../components/NewOrderModals/MattressModal';
import PromoTag from '../../../components/PromoTag';
import { scroll } from '../../../store/ducks/layout/actions';
interface value {
  key: string;
  value: number;
}
interface unity {
  key: string;
  value: string;
}
const { Option } = Select;

const ProductsTable: React.FC = () => {
  const layout = useSelector((state: ApplicationState) => state.layout.data);
  const shoppingCart = useSelector((state: ApplicationState) => state.shoppingCart.cart);
  const config = useSelector((state: ApplicationState) => state.products.config);
  const data = useSelector((state: ApplicationState) => state.products.data);
  const pagination = useSelector((state: ApplicationState) => state.products.pagination);
  const token = useSelector((state: ApplicationState) => state.auth.token);
  const loading = useSelector((state: ApplicationState) => state.products.loading);
  const cartItens = useSelector((state: ApplicationState) => state.shoppingCart.cart);
  const advancedFilters = useSelector((state: ApplicationState) => state.products.advancedFilters);
  const filter = useSelector((state: ApplicationState) => state.products.filter);
  const [columns, setColumns] = useState<ColumnsType<any>>([]);

  const dispatch = useDispatch<Dispatch<Action>>();
  const history = useHistory();

  const rowClick = (record: any) => {
    history.push(`novo-pedido/produto/${record?.pkValue}/detalhes`);
  };

  useEffect(() => {
    const getUnity = (record: any): Unity => {
      const unity = shoppingCart.find((element) => element.codProd?.toString() === record?.CODPROD?.toString())?.unity;

      if (unity) record?.UNIDADE?.find((e: Unity) => unity?.key === e.key);

      return record?.UNIDADE?.find((e: Unity) => record?.SELECTED_UNITY === e.key);
    };
    const tableDataToItemCart = (record: any): ShoppingCartItem => {
      return {
        isPromo: record?.PROMOCAO?.isPromo,
        description: record?.DESCRPROD,
        codProd: record?.pkValue,
        stock: Math.floor(
          record?.ESTOQUE.find((item: value) => item.key === record?.SELECTED_UNITY)?.value || 0
        ),
        unity:
          shoppingCart.find((element) => element.codProd === record?.pkValue)?.unity ||
          record?.UNIDADE?.find((item: Unity) => record?.SELECTED_UNITY === item.key),
        value: record.VALOR.find((item: value) => item.key === record?.SELECTED_UNITY)?.value || 0,
        hasStock: record?.ESTOQUE_SHOW,
        message: getUnity(record).message,
        multiplier: getUnity(record).multiple,
        isDecimal: !!getUnity(record).decimalPlaces,
        decimalQtd: getUnity(record).decimalPlaces,
        controlType: record.DETAILS.stockControlType
      };
    };
    const valueRender = (values: value[], record: any) => {
      if (cartItens.length > 0 && record?.DETAILS.stockControlType !== 'GRADE') {
        const dataInCart = cartItens.find(cartItem => String(cartItem.codProd) === record.pkValue);

        if (dataInCart)
          return maskCurrency(dataInCart.value || 0)
      }

      return record?.DETAILS.stockControlType !== 'GRADE'
        ? maskCurrency(
          values.find(
            (item) =>
              item.key ===
              (shoppingCart.find((element) => element.codProd === record?.pkValue)?.unity?.key ||
                record?.SELECTED_UNITY)
          )?.value || 0
        )
        : !values.find(
          (item) =>
            item.key ===
            (shoppingCart.find((element) => element.codProd === record?.pkValue)?.unity?.key ||
              record?.SELECTED_UNITY)
        )?.value
          ? 'Indisponível'
          : maskCurrency(
            values.find(
              (item) =>
                item.key ===
                (shoppingCart.find((element) => element.codProd === record?.pkValue)?.unity?.key ||
                  record?.SELECTED_UNITY)
            )?.value || 0
          );
    };

    const descriptionRender = (_value: boolean, record: any) => {
      return (
        <Row gutter={[10, 10]} align="middle">
          {record?.PROMOCAO?.isPromo && (
            <Col span={6} style={{ maxWidth: '65px' }}>
              <PromoTag />
            </Col>
          )}
          <Col span={record?.PROMOCAO?.isPromo ? 18 : 24}>
            <p
              style={{
                margin: 0,
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
              }}
            >
              {record?.DESCRPROD}
            </p>
          </Col>
        </Row>
      );
    };

    const favoriteRrender = (value: boolean, record: any) => {
      return (
        <div onClick={(e) => e.stopPropagation()}>
          <FavoriteContent onClick={() => dispatch(favoriteRequest(record?.pkValue, !value))}>
            {record?.FAVORITO ? (
              <ActiveFavoriteIcon data-cy="iconFilled" />
            ) : (
              <FavoriteIcon data-cy="iconOutlined" />
            )}
          </FavoriteContent>
        </div>
      );
    };

    const stockRender = (values: value[], record: any) => {
      return Math.floor(
        values.find(
          (item) =>
            item.key ===
            (shoppingCart.find((element) => element.codProd === record?.pkValue)?.unity?.key ||
              record?.SELECTED_UNITY)
        )?.value || 0
      );
    };

    const unityRender = (_values: unity[], record: any) => {
      return (
        <div onClick={(e) => e.stopPropagation()}>
          <Select
            data-cy="input-selectUnity"
            defaultValue={record?.SELECTED_UNITY}
            value={
              shoppingCart.find((e) => e.codProd === record?.pkValue)?.unity?.key ||
              record?.SELECTED_UNITY
            }
            onChange={(value) => {
              dispatch(setUnity(record?.pkValue, value));
            }}
            disabled={!!shoppingCart.find((element) => element.codProd === record?.pkValue)}
          >
            {record?.UNIDADE?.map((element: any) => {
              return (
                <Option key={element.key} value={element.key}>
                  {element.key}
                </Option>
              );
            })}
          </Select>
        </div>
      );
    };
    setColumns(
      configToColumnsType(
        config?.fields?.filter((item: any) => item.show) || [],
        [
          { id: -3, render: unityRender },
          { id: -4, render: valueRender },
          { id: -5, render: stockRender },
          { id: -6, render: favoriteRrender },
          {
            id: config?.fields.find((field: any) => field.name === 'DESCRPROD')?.id || 0,
            render: descriptionRender,
          },
        ],
        [
          {
            title: 'Quantidade',
            key: 'action',
            render: (record) => {
              return (
                <>
                  <div onClick={(e) => e.stopPropagation()} style={{ minWidth: 140 }}>
                    {!record?.USAMETRAGEM &&
                      record?.DETAILS.stockControlType !== 'GRADE' &&
                      record?.DETAILS.stockControlType !== 'AFC' && (
                        <QuantityInput {...tableDataToItemCart(record)} width={140} />
                      )}
                    {record?.USAMETRAGEM && <MetreageCalculator product={record} />}
                    {record?.DETAILS.stockControlType === 'GRADE' && (
                      <ModalGrid
                        product={record}
                        disabled={
                          record.VALOR.find((item: any) => item.key === record?.SELECTED_UNITY)
                            ?.value === 0
                        }
                      />
                    )}
                    {record?.DETAILS.stockControlType === 'AFC' && (
                      <MattressModal product={record} />
                    )}
                  </div>
                </>
              );
            },
          },
        ],
        [-6, 0, -5, -3, -4],
        true,
        true
      )
    );
  }, [config, layout, dispatch, shoppingCart, token, data, cartItens]);

  return (
    <CustomTable
      onRowClick={rowClick}
      onChange={(pg: any, _filters: any, _sorter: any) => {
        dispatch(loadRequest(filter, undefined, pg, undefined, advancedFilters));
        dispatch(scroll());
      }}
      pagination={pagination}
      loading={loading}
      columns={columns}
      data={data}
      rowClassName={(record: any) => (record?.PROMOCAO?.isPromo ? 'active-row' : 'data-row')}
    />
  );
};

export default ProductsTable;
