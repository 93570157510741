import { Dispatch, FC, useEffect, useState } from 'react';
import { Button, Col, Form, Input, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Action } from 'typesafe-actions';
import { UserOutlined, LockOutlined, UserAddOutlined } from '@ant-design/icons';
import Swal from 'sweetalert2';

import { ApplicationState } from '../../store';
import { cleanState, loginRequest } from '../../store/ducks/auth/actions';
import { Container, FormItem, FormLastItem, Submit } from './styles';
import { Auth } from '../../store/ducks/auth/types';
import { Redirect } from 'react-router-dom';
import { loadRequest } from '../../store/ducks/user/actions';
import { colors } from '../../utils/constants';
export interface Rules {
  required: boolean;
  message: string;
}

const Login: FC = () => {
  const loading = useSelector((state: ApplicationState) => state.auth.loginLoading);
  const token = useSelector((state: ApplicationState) => state.auth.token);
  const error = useSelector((state: ApplicationState) => state.auth.loginError);
  const errorMessage = useSelector((state: ApplicationState) => state.auth.errorMessage);
  const statusFailure = useSelector((state: ApplicationState) => state.auth.statusFailure);
  const layout = useSelector((state: ApplicationState) => state.layout.data);
  const [redirect, setRedirect] = useState<string>('');
  const dispatch = useDispatch<Dispatch<Action>>();
  const dispatchClean = useDispatch<Dispatch<Action>>();

  const onFinish = (authValues: Auth) => {
    dispatch(loginRequest(authValues));
  };
  const userNameRules: Rules = {
    required: true,
    message: 'Por favor informe um usuário!',
  };

  useEffect(() => {
    if (!token) return;
    dispatch(loadRequest(token));
    setRedirect('/home/');
  }, [dispatch, token]);

  useEffect(() => {
    if (!error || loading) return;
    Swal.fire({
      title: 'Erro!',
      confirmButtonColor: layout?.color.default,
      html: errorMessage,
      icon: 'error',
    });
    statusFailure !== 423 ?? dispatchClean(cleanState());
  }, [error, errorMessage, loading, dispatchClean, layout?.color.default, statusFailure]);

  const passwordRules: Rules = {
    required: true,
    message: 'Por favor informe uma senha!',
  };
  useEffect(() => {
    if (statusFailure !== 423) return;
    setRedirect('/trocar-senha');
  }, [statusFailure]);
  return (
    <Container>
      <Form name="basic" initialValues={{ remember: true }} onFinish={onFinish}>
        <Row>
          <Col span={24}>
            <FormItem name="userName" rules={[userNameRules]}>
              <Input
                data-cy="input-user"
                placeholder="E-mail"
                type="email"
                prefix={<UserOutlined style={{ color: colors.writeGrey }} />}
              />
            </FormItem>
          </Col>
          <Col span={24}>
            <FormItem name="password" rules={[passwordRules]}>
              <Input.Password
                data-cy="input-password"
                placeholder="Senha"
                prefix={<LockOutlined style={{ color: colors.writeGrey }} />}
              />
            </FormItem>
          </Col>
          <Col span={24}>
            <FormItem>
              <Submit data-cy="btn-login" layout={layout} htmlType="submit" block loading={loading}>
                Entrar
              </Submit>
            </FormItem>
          </Col>
        </Row>
        <Row justify={layout?.existsAccessRequest ? 'space-between' : 'center'}>
          <Col>
            <FormLastItem>
              <Button
                data-cy="btn-forgotPassword"
                type="link"
                onClick={() => {
                  setRedirect('/redefinir-senha');
                }}
              >
                Esqueci minha senha
              </Button>
            </FormLastItem>
          </Col>
          {layout?.existsAccessRequest && (
            <Col>
              <Button
                icon={<UserAddOutlined />}
                data-cy="btn-signup"
                type="link"
                onClick={() => {
                  setRedirect('/signup');
                }}
              >
                Registrar
              </Button>
            </Col>
          )}
        </Row>
      </Form>
      {redirect && <Redirect to={redirect} />}
    </Container>
  );
};

export default Login;
