import { call, put, select } from 'redux-saga/effects';
import { ApplicationState } from '../..';
import api, { errorHandler } from '../../../services/api';
import { requestHeaders } from '../../../utils/apiHelper';
import { dataToTableData } from '../../../utils/tableHelper';
import { refreshRequest } from '../auth/actions';
import {
  categoriesFailure,
  CategoriesSuccess,
  loadFailure,
  loadRequest,
  loadSuccess,
} from './actions';

export function* loadDataSheet({ payload }: ReturnType<typeof loadRequest>): any {
  try {
    const state: ApplicationState = yield select();
    const headers = requestHeaders(state.auth.token);
    const config = yield call(api.get, 'v2/ficha-tecnica/config', { headers: headers });
    const fieldFilters: any[] = [...payload.filter];

    if (payload.categoryFilter.length > 0) {
      fieldFilters.push({ nomeCampo: 'categoryId', valor: [...payload.categoryFilter] });
    }

    const response = yield call(
      api.post,
      `ficha-tecnica?page=${payload.pagination ? payload.pagination?.current - 1 : 0}&size=${
        payload.pagination ? payload.pagination?.pageSize : 12
      }`,
      { campos: fieldFilters, ordenacao: payload.sorter, pesquisa: payload.searchValue },
      { headers: headers }
    );

    yield put(
      loadSuccess(
        {
          total: response.data.numberOfElements,
          pageSize: response.data.size,
          current: response.data.number + 1,
        },
        dataToTableData(response.data.content, config.data.campos),
        [...config.data.campos],
        config.data.placeholder
      )
    );
  } catch (err: any) {
    yield put(err.response.status !== 401 ? loadFailure(errorHandler(err)) : refreshRequest());
  }
}
export function* loadDatasheetCategories(): any {
  try {
    const state: ApplicationState = yield select();
    const headers = requestHeaders(state.auth.token);
    const response = yield call(api.get, 'ficha-tecnica/categorias', { headers: headers });
    yield put(CategoriesSuccess(response.data));
  } catch (err: any) {
    yield put(
      err.response.status !== 401 ? categoriesFailure(errorHandler(err)) : refreshRequest()
    );
  }
}
