import React from 'react';
import { colors } from '../../utils/constants';

import { Container, Title } from './styles';
interface Props {
  color?: string;
  title?: string;
}
const CustomTag: React.FC<Props> = ({ color, title }) => {
  return (
    <>
      {title && (
        <Container data-cy='customTag' color={color || colors.writeGrey}>
          <Title color={color || colors.writeGrey}>{title.toLocaleUpperCase()}</Title>
        </Container>
      )}
    </>
  );
};

export default CustomTag;
