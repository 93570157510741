import { Config } from '../../../utils/newTableHelper';
import { ResponseError } from '../../../services/api';

export enum ScreenDetailTypes {
  'LOAD_REQUEST' = '@screenDetail/LOAD_REQUEST',
  'LOAD_SUCCESS' = '@screenDetail/LOAD_SUCCESS',
  'LOAD_FAILURE' = '@screenDetail/LOAD_FAILURE',

}

export interface DisplayFields {
  id: number;
  origin: string;
  value: any;
  fixedName: string;
}

export interface ScreenDetail {
  title?: string;
  fields: DisplayFields[];
}

export interface ScreenDetailState {
  readonly data: ScreenDetail[];
  readonly config?: Config;
  readonly loading: boolean;
  readonly error?: ResponseError;
}
