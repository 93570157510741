import { AxiosResponse } from 'axios';
import { call, put, select } from 'redux-saga/effects';
import { ApplicationState } from '../..';
import api, { errorHandler } from '../../../services/api';
import { requestHeaders } from '../../../utils/apiHelper';
import { refreshRequest } from '../auth/actions';
import {
  requestCampaigns,
  successCampaigns,
  failureCampaigns,
  requestCampaign,
  successCampaign,
  failureCampaign,
} from './actions';
import { Campaign } from './types';

export function* loadCampaigns({ payload }: ReturnType<typeof requestCampaigns>) {
  try {
    const state: ApplicationState = yield select();
    const headers = requestHeaders(state.auth.token);
    const response: AxiosResponse<{
      content: Campaign[];
      numberOfElements: number;
      size: number;
      number: number;
    }> = yield call(
      api.post,
      `campaign/all`,
      {
        page: (payload.pagination?.current || 1) - 1,
        size: payload.pagination?.pageSize || 12,
      },
      { headers: headers }
    );
    yield put(
      successCampaigns(
        {
          total: response.data.numberOfElements,
          pageSize: response.data.size,
          current: response.data.number + 1,
        },
        response.data.content
      )
    );
  } catch (err: any) {
    yield put(err.response.status !== 401 ? failureCampaigns(errorHandler(err)) : refreshRequest());
  }
}

export function* loadCampaign({ payload }: ReturnType<typeof requestCampaign>) {
  try {
    const state: ApplicationState = yield select();
    const headers = requestHeaders(state.auth.token);
    const response: AxiosResponse<Campaign> = yield call(
      api.get,
      `campaign/detail/${payload.codCampaign}`,
      {
        headers: headers,
      }
    );
    yield put(successCampaign(response.data));
  } catch (err: any) {
    yield put(err.response.status !== 401 ? failureCampaign(errorHandler(err)) : refreshRequest());
  }
}
