import { action } from 'typesafe-actions';
import { ResponseError } from '../../../services/api';
import { FormFields, OrderFieldsTypes } from './types';

export const loadFieldsRequest = () => action(OrderFieldsTypes.LOAD_REQUEST);
export const setValues = (values: any[]) => action(OrderFieldsTypes.SET_VALUES, { values });
export const loadFieldsSuccess = (data: FormFields[]) =>
  action(OrderFieldsTypes.LOAD_SUCCESS, { data });
export const loadFieldsFailure = (error: ResponseError) =>
  action(OrderFieldsTypes.LOAD_FAILURE, { error });

export const clearOrderValues = () => action(OrderFieldsTypes.CLEAR_VALUES);
