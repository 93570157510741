import React, { Dispatch } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';
import { Action } from 'typesafe-actions';

import { ApplicationState } from '../../../store';
import { Arrows, SortButton, SorterContainer, SorterContent } from '../styles';
import { loadRequest, resetSorter } from '../../../store/ducks/products/actions';
import { DefaultButton } from '../../../styles';
const Sorter: React.FC = () => {
  const config = useSelector((state: ApplicationState) => state.products.config);
  const pagination = useSelector((state: ApplicationState) => state.products.pagination);
  const sorter = useSelector((state: ApplicationState) => state.products.sorter);
  const layout = useSelector((state: ApplicationState) => state.layout.data);
  const [sortFileds, setSortFields] = useState<any[]>([]);
  const dispatch = useDispatch<Dispatch<Action>>();

  const getSortDirection = (name: string, direction?: 'ASC' | 'DESC') => {
    if (name === sorter?.name && sorter.direction === direction) {
      return layout?.color.default;
    }
    return 'black';
  };
  const clearSorter = () => {
    if (!sorter) return;
    dispatch(resetSorter());
    dispatch(loadRequest(undefined, undefined, { ...pagination, current: 1 }));
  };
  const sort = (name: string) => {
    if (sorter?.direction === 'ASC') {
      dispatch(
        loadRequest(undefined, { name: name, direction: 'DESC' }, { ...pagination, current: 1 })
      );
    } else {
      dispatch(
        loadRequest(undefined, { name: name, direction: 'ASC' }, { ...pagination, current: 1 })
      );
    }
  };

  useEffect(() => {
    setSortFields(config?.fields?.filter((item) => item.showSorting) || []);
  }, [config]);
  return (
    <SorterContainer>
      {sortFileds.map((item) => {
        return (
          <SorterContent onClick={() => sort(item.name)}>
            <SortButton
              data-cy={`btn-${item.name}Sorter`}
              type="text"
              layout={layout}
              active={sorter?.name === item.name}
            >
              {item.description}
            </SortButton>
            <Arrows>
              <CaretUpOutlined
                style={{ fontSize: '10px', color: getSortDirection(item.name, 'ASC') }}
              />
              <CaretDownOutlined
                style={{ fontSize: '10px', color: getSortDirection(item.name, 'DESC') }}
              />
            </Arrows>
          </SorterContent>
        );
      })}
      <DefaultButton
        data-cy="btn-clearSorter"
        layout={layout}
        block
        style={{ margin: 0 }}
        onClick={clearSorter}
      >
        Limpar
      </DefaultButton>
    </SorterContainer>
  );
};

export default Sorter;
