import React, { Dispatch } from 'react';
import { Input } from 'antd';
import { Action } from 'typesafe-actions';
import { useDispatch, useSelector } from 'react-redux';
import { loadFinancialRequest } from '../../../store/ducks/financial/actions';
import { ApplicationState } from '../../../store';

const { Search } = Input;

interface Props {
  setSearch: any;
}

const FinancialSearch: React.FC<Props> = ({ setSearch }) => {
  const placeholder = useSelector(
    (state: ApplicationState) => state.financial?.config?.placeholder
  );
  const onSearch = (value: any) => {
    setSearch(value);
    dispatch(loadFinancialRequest(undefined, undefined, undefined, value));
  };
  const dispatch = useDispatch<Dispatch<Action>>();

  return (
    <Search
      daata-cy="input-search"
      placeholder={`${placeholder ?? 'Buscar'}`}
      allowClear
      onSearch={onSearch}
      style={{ width: 400 }}
    />
  );
};

export default FinancialSearch;
