import { Reducer } from 'redux';
import { NewUserState, NewUserTypes } from './types';

const INITIAL_STATE: NewUserState = {
  dataUser: [],
  loadingUser: false,
  dataSend: '',
  loadingSend: false,
  saved: false,
};

const reducer: Reducer<NewUserState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case NewUserTypes.USER_REQUEST:
      return {
        ...state,
        loadingUser: true,
      };
    case NewUserTypes.USER_SUCCESS:
      return {
        ...state,
        loadingUser: false,
        errorData: undefined,
        dataUser: action.payload.data,
      };
    case NewUserTypes.USER_FAILURE:
      return {
        ...state,
        dataUser: [],
        loadingUser: false,
        errorData: action.payload.error,
      };
    case NewUserTypes.SEND_REQUEST:
      return {
        ...state,
        loadingSend: true,
      };
    case NewUserTypes.SEND_SUCCESS:
      return {
        ...state,
        dataSend: action.payload.data,
        saved: true,
        loadingSend: false,
        errorSend: undefined,
      };
    case NewUserTypes.SEND_FAILURE:
      return {
        ...state,
        dataSend: '',
        saved: false,
        loadingSend: false,
        errorSend: action.payload.error,
      };
    case NewUserTypes.CLEAR_SEND:
      return {
        ...state,
        loadingSend: false,
        error: false,
        dataSend: '',
        errorMessage: '',
        saved: false,
      };
    case NewUserTypes.SET_ERROR:
      return {
        ...state,
        error: action.payload.error,
      };
    default:
      return state;
  }
};

export default reducer;
