import React, { Dispatch } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Image, Row, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { ApplicationState } from '../../../store';
import { CampaignDescription, ContainerInfo } from '../styles';
import ServerError from '../../../components/ServerError';
import ProductsCarousel from '../../../components/ProductCarousel';
import { Action } from 'typesafe-actions';
import { setFilter } from '../../../store/ducks/products/actions';
import { useHistory, useParams } from 'react-router-dom';

const CampaignInfo: React.FC = () => {
  const { id }: any = useParams();

  const data = useSelector((state: ApplicationState) => state.campaigns.dataCampaign);
  const products = useSelector((state: ApplicationState) => state.campaignProducts.data);
  const productsConfig = useSelector((state: ApplicationState) => state.campaignProducts.config);
  const productsLoading = useSelector((state: ApplicationState) => state.campaignProducts.loading);
  const loading = useSelector((state: ApplicationState) => state.campaigns.loadingCampaign);
  const error = useSelector((state: ApplicationState) => state.campaigns.errorCampaign);
  const layout = useSelector((state: ApplicationState) => state.layout.data);
  const history = useHistory();

  const dispatch = useDispatch<Dispatch<Action>>();

  const seeMoreAction = () => {
    dispatch(setFilter([{ name: 'campaignId', value: [parseInt(id)] }]));
    history.push(`/home/novo-pedido`);
  };
  return (
    <Spin
      style={{ color: layout?.color.default }}
      spinning={loading}
      size="large"
      delay={500}
      indicator={<LoadingOutlined />}
    >
      {error ? (
        <ServerError {...error} />
      ) : (
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <ContainerInfo>
              <Row gutter={[20, 20]} justify="center">
                <Col>
                  <Image
                    preview={false}
                    src={
                      data?.type === 'BASE64' ? `data:image/png;base64,${data?.image}` : data?.link
                    }
                  />
                </Col>
                <Col span={24}>
                  <h2>{data?.title}</h2>
                  <CampaignDescription
                    dangerouslySetInnerHTML={{ __html: data?.description || '' }}
                  />
                </Col>
              </Row>
            </ContainerInfo>
          </Col>
          {products.length > 0 &&
            productsConfig?.details?.viewModes?.some(
              (item: string) => item === 'CARD' || item === 'LIST'
            ) && (
              <Col span={24}>
                <ContainerInfo>
                  <Row gutter={[20, 20]} justify="center">
                    <Col span={24}>
                      <ProductsCarousel products={products} loading={productsLoading} />
                    </Col>
                  </Row>
                  <Row gutter={[20, 20]} justify="end">
                    <Col>
                      <Button type="primary" onClick={seeMoreAction}>
                        Ver mais...
                      </Button>
                    </Col>
                  </Row>
                </ContainerInfo>
              </Col>
            )}
        </Row>
      )}
    </Spin>
  );
};

export default CampaignInfo;
