import { AxiosResponse } from 'axios';
import React, { useEffect, useState } from 'react';
import { Image } from 'antd';
import { useSelector } from 'react-redux';

import api from '../../../services/api';
import { ApplicationState } from '../../../store';
import { requestHeaders } from '../../../utils/apiHelper';

interface Props {
  urlImage: string;
}

const RenderImage: React.FC<Props> = ({ urlImage }) => {
  const token = useSelector((state: ApplicationState) => state.auth.token);

  const [imageUrl, setImageUrl] = useState<string>('');

  useEffect(() => {
    if (!urlImage) return;
    const headers = requestHeaders(token);
    api
      .get(urlImage, { headers: headers })
      .then((res: AxiosResponse<{ value?: string }>) => {
        setImageUrl(res.data.value ? `data:image/png;base64,${res.data.value}` : '');
      })
      .catch((err) => {
        setImageUrl('');
      });
  }, [token, urlImage]);
  return <Image width={200} src={imageUrl} />;
};

export default RenderImage;
