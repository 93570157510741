import { action } from 'typesafe-actions';
import { ResponseError } from '../../../services/api';
import { Config } from '../../../utils/newTableHelper';
import { ProductDetailShowCaseTypes } from './types';

export const loadRequestShowCaseDetail = (id: number) =>
  action(ProductDetailShowCaseTypes.LOAD_REQUEST, { id });
export const loadSuccessShowCaseDetail = (
  data: any[],
  config: Config,
  images: string[],
  thumbnails: string[]
) =>
  action(ProductDetailShowCaseTypes.LOAD_SUCCESS, {
    data,
    config,
    images,
    thumbnails,
  });
export const loadFailureShowCaseDetail = (error: ResponseError) =>
  action(ProductDetailShowCaseTypes.LOAD_FAILURE, { error });

export const setUnityDetail = (codProd: number, unity: string) =>
  action(ProductDetailShowCaseTypes.SET_UNITY, { codProd, unity });

export const unitySuccessDetail = (data: any[]) =>
  action(ProductDetailShowCaseTypes.UNITY_SUCCESS, { data });
