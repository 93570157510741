import React, { Dispatch, useEffect, useState } from 'react';
import { Form, Steps, Upload, message, Row, Col, Input, Switch, Button } from 'antd';
import {
  LoadingOutlined,
  PlusOutlined,
  RightOutlined,
  CheckOutlined,
  LeftOutlined,
} from '@ant-design/icons';
import { ButtonsContainer, StepContainer } from './styles';
import { RcFile } from 'antd/lib/upload';
import { getBase64 } from '../../utils/image';
import { baseURL } from '../../services/api';
import { colors, MESSAGES } from '../../utils/constants';
import { clearSend, sendUserRequest, setError } from '../../store/ducks/newUser/actions';
import { useDispatch, useSelector } from 'react-redux';
import { Action } from 'typesafe-actions';
import { ApplicationState } from '../../store';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router';
import { User, Image } from '../../store/ducks/newUser/types';
import { rolesRequest } from '../../store/ducks/newContact/actions';

const { Step } = Steps;
const NewUser: React.FC = () => {
  const [current, setCurrent] = useState<number>(0);
  const [image, setImage] = useState<string>('');
  const [imageResponse, setImageResponse] = useState<Image | undefined>(undefined);
  const [uploading, setUploading] = useState<boolean>(false);
  const [user, setUser] = useState<User | undefined>(undefined);
  const layout = useSelector((state: ApplicationState) => state.layout.data);
  const token = useSelector((state: ApplicationState) => state.auth.token);
  const saved = useSelector((state: ApplicationState) => state.newUser.saved);
  const roles = useSelector((state: ApplicationState) => state.newContact.roles);
  const dataSend = useSelector((state: ApplicationState) => state.newUser.dataSend);
  const loadingSend = useSelector((state: ApplicationState) => state.newUser.loadingSend);
  const error = useSelector((state: ApplicationState) => state.newUser.errorSend);
  const history = useHistory();

  const dispatch = useDispatch<Dispatch<Action>>();

  useEffect(() => {
    dispatch(rolesRequest());
  }, [dispatch, token]);

  useEffect(() => {
    if (!error) {
      return;
    }
    Swal.fire({
      title: 'Erro!',
      confirmButtonColor: layout?.color.default,
      text: error.message,
      icon: 'error',
    });
    Swal.fire({
      title: 'Erro!',
      text: error.message,
      confirmButtonColor: layout?.color.default,
      icon: 'error',
    }).then(() => {
      dispatch(setError(undefined));
    });
  }, [error, dispatch, layout?.color.default]);

  useEffect(() => {
    if (!saved) {
      return;
    }
    const textFire = 'Usuário salvo com sucesso!';
    Swal.fire({
      title: 'Sucesso!',
      confirmButtonColor: layout?.color.default,
      text: `${textFire}`,
      icon: 'success',
      confirmButtonText: 'Ok',
    }).then(() => {
      dispatch(clearSend());
      history.goBack();
    });
  }, [saved, dispatch, dataSend, history, layout?.color.default]);

  const previous = () => {
    setCurrent(current - 1);
  };

  const handleChange = (info: any) => {
    if (info.file.status === 'uploading') {
      setImage('');
      setUploading(true);
      return;
    }
    if (info.file.status === 'done') {
      setImageResponse({ description: info.file.name, attachment: info.file.response.attachment });
      getBase64(info.file.originFileObj, (imageUrl: string) => {
        setImage(imageUrl);
        setUploading(false);
      });
    }
  };

  const beforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('Só é permitido arquivos do tipo jpeg ou png!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('A foto deve ter menos 2MB!');
    }
    return isJpgOrPng && isLt2M;
  };
  const uploadButton = (
    <div>
      {uploading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );
  const secondStep = () => {
    const onFinish = (values: any) => {
      dispatch(sendUserRequest({ ...user, contact: values }));
    };
    return (
      <>
        <Form onFinish={onFinish} autoComplete="no" layout="vertical">
          <StepContainer>
            <h2>Dados contato</h2>
            <Row gutter={[20, 20]}>
              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <Form.Item
                  label="Nome do Contato"
                  name="contactName"
                  rules={[{ required: true, message: `Por favor informe um nome!` }]}
                >
                  <Input data-cy="input-name" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                <Form.Item label="Ativo" name="active" valuePropName="checked" initialValue={true}>
                  <Switch data-cy="input-active" />
                </Form.Item>
              </Col>
            </Row>
          </StepContainer>

          <StepContainer>
            <h2>Permissões</h2>
            <Row gutter={[20, 20]}>
              {roles.map((permission) => {
                return (
                  <Col xs={24} sm={12} md={12} lg={6} xl={6}>
                    <Form.Item
                      label={permission.label}
                      name={[`${permission.name}`]}
                      valuePropName="checked"
                      initialValue={!!permission?.value}
                    >
                      <Switch data-cy={`input-${permission.name}`} />
                    </Form.Item>
                  </Col>
                );
              })}
            </Row>
          </StepContainer>
          <ButtonsContainer>
            <Button style={{ marginRight: '20px' }} onClick={previous} type="default">
              <LeftOutlined /> Voltar
            </Button>
            <Form.Item>
              <Button loading={loadingSend} htmlType="submit" type="primary">
                Finalizar <CheckOutlined />
              </Button>
            </Form.Item>
          </ButtonsContainer>
        </Form>
      </>
    );
  };

  const firstStep = () => {
    const onFinish = (values: any) => {
      setUser({
        ...values,
        image: imageResponse,
      });
      setCurrent(current + 1);
    };
    return (
      <Form onFinish={onFinish} autoComplete="no" layout="vertical">
        <StepContainer>
          <Row gutter={[20, 20]}>
            <Col xs={24} sm={24} md={24} lg={4} xl={4}>
              <Form.Item label="Foto" name="attachment">
                <Upload
                  name="image"
                  listType="picture-card"
                  className="avatar-uploader"
                  showUploadList={false}
                  action={`${baseURL}/access-control/image/upload`}
                  beforeUpload={beforeUpload}
                  onChange={handleChange}
                >
                  {image ? (
                    <img
                      src={image}
                      alt="avatar"
                      style={{ maxWidth: '100%', maxHeight: '100%', padding: '5px' }}
                    />
                  ) : (
                    uploadButton
                  )}
                </Upload>
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={24} lg={20} xl={20}>
              <Row gutter={[20, 20]}>
                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  <Form.Item
                    rules={[{ required: true, message: `Por favor informe um nome!` }]}
                    label="Nome"
                    name="name"
                  >
                    <Input data-cy="input-name" />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  <Form.Item
                    rules={[{ required: true, message: `Por favor informe um e-mail!` }]}
                    label="E-mail"
                    name="email"
                  >
                    <Input data-cy="input-email" type="email" />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  <Form.Item
                    hasFeedback
                    label="Senha"
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: 'Por favor informe uma senha!',
                      },
                      () => ({
                        validator(_, value: string) {
                          if (
                            !value ||
                            value.match(/^(?=.{8,}$)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).*$/)
                          ) {
                            return Promise.resolve();
                          }
                          return Promise.reject(MESSAGES.PASSWORD_ERROR);
                        },
                      }),
                    ]}
                  >
                    <Input.Password data-cy="input-password" />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  <Form.Item
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: 'Por favor confirme a senha!',
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue('password') === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error('As duas senhas devem ser iguais!'));
                        },
                      }),
                    ]}
                    name="confirm"
                    dependencies={['password']}
                    label="Confirmar senha"
                  >
                    <Input.Password data-cy="input-newPassword" />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                  <Form.Item label="Ativo" name="active" initialValue={true} valuePropName="checked">
                    <Switch data-cy="input-active" />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </StepContainer>

        <ButtonsContainer>
          <Form.Item>
            <Button htmlType="submit" type="primary">
              Próximo <RightOutlined />
            </Button>
          </Form.Item>
        </ButtonsContainer>
      </Form>
    );
  };
  return (
    <div>
      <Steps
        style={{
          backgroundColor: 'white',
          padding: '20px',
          borderRadius: '5px',
          boxShadow: `1px 2px 2px 2px ${colors.grey}`,
        }}
        current={current}
        type="navigation"
        className="site-navigation-steps"
      >
        <Step title="Usuário" />
        <Step title="Contato" />
      </Steps>
      {current === 0 ? firstStep() : secondStep()}
    </div>
  );
};

export default NewUser;
