import React, { useEffect, useState } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../store';
import { Pagination } from '../../../store/ducks/products/types';
import { TableRowSelection } from 'antd/es/table/interface';
import { List } from 'antd';
import CardView from '../CardView';
import { colors } from '../../../utils/constants';
interface Props {
  columns: any[];
  data?: any[];
  loading: boolean;
  onChange: any;
  pagination?: Pagination;
  onRowClick?: any;
  onRowDoubleClick?: any;
  rowSelection?: TableRowSelection<any>;
}

const CardList: React.FC<Props> = ({ onChange, columns, data, loading, pagination }) => {
  const layout = useSelector((state: ApplicationState) => state.layout.data);
  const [displayData, setDisplaydata] = useState<any[]>([]);

  useEffect(() => {
    setDisplaydata([]);
    data?.forEach((dataItem) => {
      const tableData: any = {};
      const newData = { ...dataItem, action: true };
      Object.keys(newData).forEach((key) => {
        const obj = columns.find(
          (element) => element?.dataIndex === key || element.key === 'action'
        );
        if (!obj) return;
        let index = columns.findIndex((element) => element?.dataIndex === key);
        if (index === -1) index = obj.key;
        Object.assign(tableData, {
          [`${index}`]: {
            ...obj,
            value: dataItem[key],
            record: dataItem,
          },
        });
      });
      setDisplaydata((old) => {
        return [...old, tableData];
      });
    });
  }, [columns, data, onChange]);
  return (
    <List
      style={{
        backgroundColor: 'white',
        padding: '20px',
        borderRadius: '5px',
        boxShadow: `1px 2px 2px 2px ${colors.grey}`,
      }}
      grid={{ gutter: 16, xs: 1, sm: 1, md: 2, lg: 2, xl: 3, xxl: 4 }}
      loading={{
        style: { color: layout?.color.default },
        spinning: loading,
        size: 'large',
        delay: 500,
        indicator: <LoadingOutlined style={{ fontSize: 24 }} spin />,
      }}
      dataSource={displayData.filter((item) => Object.keys(item).length !== 0)}
      pagination={
        !pagination
          ? false
          : {
              ...pagination,
              size: 'small',
              onChange: (page, pageSize) =>
                onChange({
                  current: page,
                  pageSize: pageSize || 0,
                  total: pagination?.total || 0,
                }),
            }
      }
      renderItem={(item: any) => {
        return (
          <List.Item>
            <CardView {...item} />
          </List.Item>
        );
      }}
    />
  );
};

export default CardList;
