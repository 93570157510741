import styled from "styled-components";
import { Layout } from "../../../store/ducks/layout/types";

export const BannersCarousel = styled.div<{ layout?: Layout }>`
  .ant-carousel .slick-dots li button {
    background: ${({ layout }) => layout?.color.dark};
    height: 15px;
    opacity: 0.3;
  }

  .ant-carousel .slick-dots li.slick-active button {
    background: ${({ layout }) => layout?.color.default};
    opacity: 1;
    height: 15px;
  }
`;

export const Container = styled.div<{ hasDots?: boolean }>`
  display: flex !important;
  align-items: center;
  justify-content: center;
  min-height: 280px;
  max-height: 280px;
  padding-bottom: ${({ hasDots }) => (hasDots ? '35px' : '20px')};
`;

export const TitleContent = styled.div<{ hasTitle: boolean }>`
  position: absolute;
  bottom: 50px;
  left: 50%;
  transform: translate(-50%, 0);
  background: ${({ hasTitle }) => (hasTitle ? 'rgba(0, 0, 0, 0.2)' : '')};
`;

export const Title = styled.h1`
  color: white;
  margin-bottom: 0px;
  padding: 5px 10px;
  text-shadow: 1px 1px 4px #000000;
`;

export const Image = styled.img<{ hasLink: boolean }>`
  cursor: ${({ hasLink }) => (hasLink ? 'pointer' : 'default')};
  width: 100%;
`;


export const LoadingImage = styled.div`
   position: absolute;
   width: 100%;
   height: 100%;
   display: flex;
   align-items: center;
   justify-content: center;
`;

