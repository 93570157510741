import { AxiosResponse } from 'axios';
import { call, put, select } from 'redux-saga/effects';
import { ApplicationState } from '../..';
import api, { errorHandler } from '../../../services/api';
import { requestHeaders } from '../../../utils/apiHelper';
import { refreshRequest } from '../auth/actions';
import {
  deliveriesRequest,
  deliveriesSuccess,
  deliveriesFailure,
  pointRequest,
  pointSuccess,
  pointFailure,
  sendPointRequest,
  sendPointSuccess,
  sendPointFailure,
} from './actions';
import { deliveryOption, DeliveryPoint } from './types';

export function* loadDeliveries({ payload }: ReturnType<typeof deliveriesRequest>) {
  try {
    const state: ApplicationState = yield select();
    const headers = requestHeaders(state.auth.token);
    const response: AxiosResponse<deliveryOption[]> = yield call(
      api.post,
      `access-control/${payload.userId}/contact/${payload.contactId}/deliverypoints/options`,
      undefined,
      {
        headers: headers,
      }
    );
    yield put(deliveriesSuccess(response.data));
  } catch (err: any) {
    yield put(
      err.response.status !== 401 ? deliveriesFailure(errorHandler(err)) : refreshRequest()
    );
  }
}

export function* loadPoint({ payload }: ReturnType<typeof pointRequest>) {
  try {
    const state: ApplicationState = yield select();
    const headers = requestHeaders(state.auth.token);
    const response: AxiosResponse<DeliveryPoint> = yield call(
      api.post,
      `/access-control/user/${payload.userId}/contact/${payload.contactId}/deliverypoint/${payload.id}`,
      undefined,
      {
        headers: headers,
      }
    );
    yield put(pointSuccess(response.data));
  } catch (err: any) {
    yield put(err.response.status !== 401 ? pointFailure(errorHandler(err)) : refreshRequest());
  }
}

export function* loadSendPoint({ payload }: ReturnType<typeof sendPointRequest>) {
  try {
    const state: ApplicationState = yield select();
    const headers = requestHeaders(state.auth.token);
    const response: AxiosResponse<{ delivPointId: string }> = payload.id
      ? yield call(
          api.put,
          `/access-control/user/${payload.userId}/contact/${payload.contactId}/deliverypoint/${payload.id}`,
          payload.body,
          {
            headers: headers,
          }
        )
      : yield call(
          api.post,
          `access-control/${payload.userId}/contact/${payload.contactId}/deliverypoint`,
          payload.body,
          {
            headers: headers,
          }
        );

    yield put(sendPointSuccess(response.data.delivPointId));
  } catch (err: any) {
    yield put(err.response.status !== 401 ? sendPointFailure(errorHandler(err)) : refreshRequest());
  }
}
