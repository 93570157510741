import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import TagManager from 'react-gtm-module';
import { store, persistor } from './store';
import Error from './components/Error';
import App from './App';
import packageInfo from '../package.json';

if (process.env.NODE_ENV === 'production') {
  const { host } = window.location;
  const subdomain = host.split('.')[0];

  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
    ignoreErrors: ['ResizeObserver loop limit exceeded'],
  });

  Sentry.setTag('namespace', subdomain);
  Sentry.setTag('version', packageInfo.version);
}

if (window.location.hostname.includes('navas')) {
  const tagManagerArgs = {
    gtmId: 'GTM-PRDGZHP',
  };
  TagManager.initialize(tagManagerArgs);
}

const fallbackComponent = (props: any) => <Error {...props} />;

ReactDOM.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={fallbackComponent}>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
);
