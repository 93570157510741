import React, { Dispatch, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ptBR from 'antd/lib/locale/pt_BR';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import 'antd/dist/antd.css';
import { Action } from 'typesafe-actions';
import { loadRequest } from './store/ducks/layout/actions';
import { ConfigProvider, Spin } from 'antd';
import ExternalLayout from './components/Layout/ExternalLayout';
import { ApplicationState } from './store';
import BasicLayout from './components/Layout/BasicLayout';
import { Container, LoadingPage } from './styles';
import { colors } from './utils/constants';
import ReactGA from 'react-ga4';

const App: React.FC = () => {
  if (process.env.REACT_APP_GOOGLE_ID) ReactGA.initialize(process.env.REACT_APP_GOOGLE_ID);
  const layout = useSelector((state: ApplicationState) => state.layout.data);
  const loading = useSelector((state: ApplicationState) => state.layout.loading);
  const dispatch = useDispatch<Dispatch<Action>>();
  useEffect(() => {
    dispatch(loadRequest());
  }, [dispatch]);

  return (
    <ConfigProvider locale={ptBR}>
      {loading ? (
        <Spin
          tip="Carregando..."
          style={{ color: colors.writeGrey }}
          spinning={loading}
          size="large"
          delay={500}
          indicator={<LoadingOutlined />}
        >
          <LoadingPage />
        </Spin>
      ) : (
        <Container layout={layout}>
          <Router>
            <Switch>
              <Route path="/home" render={(props) => <BasicLayout {...props} />} />
              <Route path="/" render={(props) => <ExternalLayout {...props} />} />

              <Redirect from="/" to={layout?.existsProductShowcase ? '/showcase' : '/login'} />
            </Switch>
          </Router>
        </Container>
      )}
    </ConfigProvider>
  );
};

export default App;
