import React, { Dispatch, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Action } from 'typesafe-actions';
import { ApplicationState } from '../../store';
import { requestCategory, requestFaq, requestCall } from './../../store/ducks/faq/actions';
import { Container } from '../../styles';
import { CommonQuestions, CommonQuestionsHeader, HistoricQuestions, SendQuestions } from './styles';
import FAQSearch from './FAQSearch';
import FAQCategory from './FAQCategory';
import FAQCommonQuestions from './FAQCommonQuestions';
import FAQSendQuestion from './FAQSendQuestion';
import FAQHistoricQuestion from './FAQHistoricQuestions';
import { Col, Row } from 'antd';

const FAQ: React.FC = () => {
  const token = useSelector((state: ApplicationState) => state.auth.token);
  const [searchValue, setSearchValue] = useState<string>('');
  const [categoryValue, setCategoryValue] = useState<string>('');

  const dispatch = useDispatch<Dispatch<Action>>();
  useEffect(() => {
    dispatch(requestCategory());
    dispatch(requestFaq());
    dispatch(requestCall());
  }, [dispatch, token]);

  return (
    <Container>
      <CommonQuestionsHeader>
        <FAQCategory search={searchValue} setCategory={setCategoryValue} />
        <FAQSearch category={categoryValue} setSearch={setSearchValue} />
      </CommonQuestionsHeader>
      <CommonQuestions>
        <FAQCommonQuestions search={searchValue} category={categoryValue} />
      </CommonQuestions>
      <Row gutter={[20, 20]}>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <SendQuestions>
            <h3>Dúvidas, sugestões e/ou reclamações</h3>
            <FAQSendQuestion />
          </SendQuestions>
        </Col>
        <Col xs={24} sm={24} md={16} lg={16} xl={16}>
          <HistoricQuestions>
            <h3>Histórico de chamados</h3>
            <FAQHistoricQuestion />
          </HistoricQuestions>
        </Col>
      </Row>
    </Container>
  );
};

export default FAQ;
