import React, { Dispatch } from 'react';
import { Input } from 'antd';
import { Action } from 'typesafe-actions';
import { useDispatch } from 'react-redux';
import { requestFaq } from '../../../store/ducks/faq/actions';
import { useWindowSize } from '../../../customHooks';
import { Fields } from '../../../store/ducks/faq/types';

const { Search } = Input;

interface props {
  setSearch: any;
  category: any;
}

const FAQSearch: React.FC<props> = ({ setSearch, category }) => {
  const dispatch = useDispatch<Dispatch<Action>>();
  const size = useWindowSize();
  const onSearch = (value: any) => {
    const fieldsArr: Fields[] = [];
    setSearch(value);
    if (value) fieldsArr.push({ name: 'titulo', value: value });
    if (category) fieldsArr.push({ name: 'codFaqPai', value: category });
    dispatch(requestFaq(undefined, fieldsArr));
  };

  return (
    <Search
      data-cy="input-search"
      placeholder="Buscar"
      allowClear
      onSearch={onSearch}
      style={{
        width: (size.width ?? 0) > 1024 ? '400px' : '100%',
        marginTop: (size.width ?? 0) > 1024 ? '0px' : '10px',
      }}
    />
  );
};

export default FAQSearch;
