import { Button, Checkbox } from 'antd';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import { useState } from 'react';

import { Buttons, CheckboxList, Container } from './styles';
const renderTableFilterList = (dataIndex: string) => {
  return ({ setSelectedKeys, confirm, filters }: FilterDropdownProps) => {
    const [values, setValues] = useState<any[]>([]);

    const onChange = (e: any) => {
      if (e.target.checked) {
        setValues([...values, e.target.name]);
      } else {
        setValues(values.filter((item) => item !== e.target.name));
      }
    };
    const onClear = () => {
      setValues([]);
      setSelectedKeys([]);
      confirm();
    };
    const isChecked = (key: string): boolean => {
      return values.filter((i) => i === key).length > 0;
    };

    const renderCheckboxes = (item: any) => {
      return (
        <li key={item.key}>
          <Checkbox name={item.key} onChange={onChange} checked={isChecked(item.key)}>
            {item.value}
          </Checkbox>
        </li>
      );
    };

    const onConfirm = () => {
      if (values.length > 0)
        setSelectedKeys([dataIndex, ...values]);
      else
        setSelectedKeys([]);

      confirm();
    };
    return (
      <Container>
        <CheckboxList>{filters?.map((item) => renderCheckboxes(item))}</CheckboxList>

        <Buttons>
          <Button danger onClick={onClear}>
            Limpar
          </Button>
          <Button type="primary" onClick={onConfirm}>
            OK
          </Button>
        </Buttons>
      </Container>
    );
  };
};

export default renderTableFilterList;
