import { Tabs, Result, Row, Form, Button, Spin, Tooltip, Badge, Modal } from 'antd';
import React, { Dispatch, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Action } from 'redux';
import {
  UnorderedListOutlined,
  AppstoreOutlined,
  TableOutlined,
  LoadingOutlined,
  FilterOutlined,
} from '@ant-design/icons';
import { ApplicationState } from '../../store';
import { advancedFieldsRequest, loadRequest, setMode } from '../../store/ducks/products/actions';
import ProductCardList from './ProductCardList';
import ProductsTable from './ProductsTable';
import ProductList from './ProductList';
import SearchInput from './SearchInput';
import { Content, HeaderContent } from './styles';
import CategorySelect from './CategorySelect';
import { loadFieldsRequest, setValues } from '../../store/ducks/orderFields/actions';
import DynamicFields from '../../components/DynamicFields';
import { Size, useWindowSize } from '../../customHooks';
import { SubmitButton } from '../../components/NewOrderModals/styles';
import ExtraButtons from './ExtraButtons';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { ProductDetailRoute } from '../../routes/privateRoutes';
import { DefaultButton } from '../../styles';

const { TabPane } = Tabs;
interface KeyValue {
  key: string;
  value: number | string;
}

const NewOrder: React.FC = () => {
  const token = useSelector((state: ApplicationState) => state.auth.token);
  const loading = useSelector((state: ApplicationState) => state.products.loading);
  const { mode, data } = useSelector((state: ApplicationState) => state.products);
  const loadingConfig = useSelector((state: ApplicationState) => state.products.loadingConfig);
  const layout = useSelector((state: ApplicationState) => state.layout.data);
  const { success: saved, cart: shoppingCart } = useSelector(
    (state: ApplicationState) => state.shoppingCart
  );
  const {
    data: requiredFields,
    values: requiredValues,
    loaded: loadedRequiredFields,
    loading: loadingRequiredFields,
  } = useSelector((state: ApplicationState) => state.orderFields);
  const config = useSelector((state: ApplicationState) => state.products.config);
  const shoppingCartConfig = useSelector((state: ApplicationState) => state.shoppingCart.config);
  const [modalVisible, setModalVisible] = useState<boolean>(true);
  const dispatch = useDispatch<Dispatch<Action>>();
  const { location } = useHistory<any>();
  const search = useSelector((state: ApplicationState) => state.products.search);
  const filter = useSelector((state: ApplicationState) => state.products.filter);
  const applyFilterBeforeListing =
    shoppingCartConfig?.details?.applyFilterBeforeListing !== undefined
      ? shoppingCartConfig?.details?.applyFilterBeforeListing
      : true;

  const [form] = Form.useForm();
  const size: Size = useWindowSize();
  const onFinish = (formValues: any) => {
    const arr: KeyValue[] = [];
    Object.keys(formValues).forEach((item) => {
      if (!formValues[item]) return;
      arr.push({
        key: item,
        value:
          formValues[item] instanceof moment
            ? moment(formValues[item]).format('DD/MM/YYYY')
            : formValues[item],
      });
    });
    dispatch(setValues(arr));
    setModalVisible(false);
    dispatch(loadRequest());
  };

  useEffect(() => {
    if (!modalVisible) return;
    form.resetFields();
  }, [form, modalVisible, token]);

  useEffect(() => {
    dispatch(loadFieldsRequest());
  }, [dispatch, form, token]);

  useEffect(() => {
    if (applyFilterBeforeListing) return;

    if (
      saved ||
      !loadedRequiredFields ||
      (requiredFields.length > 0 && requiredValues.length === 0)
    )
      return;

    if (location?.state?.from === ProductDetailRoute.path && data.length > 0) return;
    else dispatch(loadRequest());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    token,
    saved,
    loadedRequiredFields,
    requiredFields,
    requiredValues,
    location,
    applyFilterBeforeListing,
  ]);

  useEffect(() => {
    dispatch(advancedFieldsRequest());
  }, [dispatch]);

  const renderTable = () => {
    return (size?.width ?? 0) >= 1024 ? <ProductsTable /> : <ProductList mode="GRID" />;
  };

  const handleModalVisibility = () => {
    setModalVisible(!modalVisible);
  };

  useEffect(() => {
    if (loadedRequiredFields && requiredFields.length === requiredValues.length) {
      setModalVisible(false);
    }
  }, [loadedRequiredFields, requiredFields, requiredValues]);

  const requiredFiledsContent = (
    <Form
      layout="vertical"
      onFinish={onFinish}
      form={form}
      initialValues={requiredValues.map((item) => {
        return { [`${item.key}`]: item.value };
      })}
    >
      {requiredFields.map((item) => (
        <DynamicFields
          urlUnique={`/new-order/filters/required/${item.idField}/options`}
          type={item.fieldType}
          label={item.description}
          foreign={item.foreignEntity}
          name={item.fieldName}
          placeholder={item.placeholder}
          value={requiredValues.find(({ key }) => key === item.fieldName)?.value}
          required
          disabled={!item.enable}
        />
      ))}
    </Form>
  );

  return (
    <Spin
      style={{ color: layout?.color.default }}
      spinning={loadingConfig || loading || loadingRequiredFields}
      delay={500}
      indicator={<LoadingOutlined />}
    >
      <HeaderContent>
        <Row>
          {requiredFields.length > 0 && (
            <>
              <Badge
                style={{
                  width: '12px',
                  minWidth: '12px',
                  height: '12px',
                  backgroundColor: layout?.color.default || '#ff4d4f',
                }}
                dot={requiredValues.length > 0}
              >
                <Button
                  data-cy="requiredFilter"
                  onClick={handleModalVisibility}
                  type="default"
                  block={(size?.width ?? 0) < 1160}
                  style={{
                    paddingRight: '20px',
                    marginBottom: (size?.width ?? 0) > 1160 ? 0 : '10px',
                  }}
                  icon={<FilterOutlined />}
                >
                  Filtros obrigatórios
                </Button>
              </Badge>
              <Modal
                title="Filtros obrigatórios"
                visible={modalVisible}
                onCancel={handleModalVisibility}
                footer={[
                  <Form layout="vertical" onFinish={onFinish} form={form}>
                    <Form.Item style={{ textAlign: 'right' }}>
                      <DefaultButton
                        data-cy="btn-cancel"
                        layout={layout}
                        key="cancel"
                        onClick={handleModalVisibility}
                      >
                        Cancelar
                      </DefaultButton>
                      ,
                      <Tooltip
                        title={
                          shoppingCart.length > 0 &&
                          'Não é possível alterar o filtro por possuir itens no carrinho.'
                        }
                      >
                        <SubmitButton
                          data-cy="btn-sendRequiredFilters"
                          layout={layout}
                          disabled={shoppingCart.length > 0}
                          loading={loading}
                          type="primary"
                          htmlType="submit"
                          onClick={onFinish}
                        >
                          Filtrar
                        </SubmitButton>
                      </Tooltip>
                    </Form.Item>
                  </Form>,
                ]}
              >
                {requiredFiledsContent}
              </Modal>
            </>
          )}
        </Row>
        <Row>
          <CategorySelect />
          <SearchInput />
        </Row>
      </HeaderContent>

      <Content>
        {(requiredValues.length !== requiredFields.length &&
          requiredFields.length > 0 &&
          !loading &&
          !loadingConfig) ||
        (applyFilterBeforeListing &&
          !search &&
          filter.length === 0 &&
          !loading &&
          !loadingConfig) ? (
          <Result
            title={
              applyFilterBeforeListing ? (
                'Preencha o menu de buscas para listar os produtos'
              ) : (
                <>
                  Preencha os "Filtros obrigatórios" <strong>acima</strong> para listar os produtos
                </>
              )
            }
          />
        ) : (
          <Tabs
            defaultActiveKey={mode || '1'}
            onChange={(activeKey) => dispatch(setMode(activeKey))}
            size="small"
            tabBarExtraContent={<ExtraButtons />}
          >
            {config?.details?.viewModes.find((item: string) => item === 'TABLE') && (
              <TabPane
                tab={
                  <div data-cy="tab-tableView">
                    <TableOutlined style={{ margin: 0 }} /> {(size?.width ?? 0) >= 1024 && 'Tabela'}
                  </div>
                }
                key="1"
              >
                {renderTable()}
              </TabPane>
            )}

            {config?.details?.viewModes.find((item: string) => item === 'LIST') && (
              <TabPane
                tab={
                  <div data-cy="tab-listView">
                    <UnorderedListOutlined style={{ margin: 0 }} />
                    {(size?.width ?? 0) >= 1024 && 'Lista'}
                  </div>
                }
                key="2"
              >
                <ProductList />
              </TabPane>
            )}

            {config?.details?.viewModes.find((item: string) => item === 'CARD') && (
              <TabPane
                tab={
                  <div data-cy="tab-cardView">
                    <AppstoreOutlined style={{ margin: 0 }} />
                    {(size?.width ?? 0) >= 1024 && 'Card'}
                  </div>
                }
                key="3"
              >
                <ProductCardList />
              </TabPane>
            )}
          </Tabs>
        )}
      </Content>
    </Spin>
  );
};

export default NewOrder;
