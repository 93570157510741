import { ResponseError } from '../../../services/api';

export enum NewContactTypes {
  'CONTACT_REQUEST' = '@newContact/CONTACT_REQUEST',
  'CONTACT_SUCCESS' = '@newContact/CONTACT_SUCCESS',
  'CONTACT_FAILURE' = '@newContact/CONTACT_FAILURE',

  'SEND_REQUEST' = '@newContact/SEND_REQUEST',
  'SEND_SUCCESS' = '@newContact/SEND_SUCCESS',
  'SEND_FAILURE' = '@newContact/SEND_FAILURE',

  'ROLES_REQUEST' = '@newContact/ROLES_REQUEST',
  'ROLES_SUCCESS' = '@newContact/ROLES_SUCCESS',
  'ROLES_FAILURE' = '@newContact/ROLES_FAILURE',

  'CLEAR_SEND' = '@newContact/CLEAR_SEND',

  'SET_ERROR' = '@newContact/SET_ERROR',
}
export interface Contact {
  accessControl: boolean;
  active: boolean;
  bill: boolean;
  campaigns: boolean;
  contactName: string;
  editOrders: boolean;
  faq: boolean;
  financial: boolean;
  userId: number;
  idContact: number;
  idPartner: number;
  myData: boolean;
  newOrder: boolean;
  orderRelease: boolean;
  orders: boolean;
  partnerName: string;
  reOrder: boolean;
  reports: boolean;
  serviceOrder: boolean;
  technicalFile: boolean;
}

export interface Role {
  label: string;
  name: string;
  value?: boolean;
}

export interface NewContactState {
  readonly dataContact?: Contact;
  readonly roles: Role[];
  readonly loadingContact: boolean;
  readonly loadingRoles: boolean;
  readonly dataSend?: Contact;
  readonly loadingSend: boolean;
  readonly saved: boolean;
  readonly errorContact?: ResponseError;
  readonly errorRoles?: ResponseError;
  readonly errorSend?: ResponseError;
}
