import React, { Dispatch } from 'react';
import { Input, Tooltip } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Action } from 'typesafe-actions';
import { clearSearch, loadRequest } from '../../../store/ducks/products/actions';
import { ApplicationState } from '../../../store';
import { Size, useWindowSize } from '../../../customHooks';
import { useState } from 'react';
import { useEffect } from 'react';
const { Search } = Input;

const SearchInput: React.FC = () => {
  const config = useSelector((state: ApplicationState) => state.products.config);
  const pagination = useSelector((state: ApplicationState) => state.products.pagination);
  const search = useSelector((state: ApplicationState) => state.products.search);
  const shoppingCartConfig = useSelector((state: ApplicationState) => state.shoppingCart.config);
  const { data: requiredFields, values: requiredValues } = useSelector((state: ApplicationState) => state.orderFields);
  const applyFilterBeforeListing = shoppingCartConfig?.details?.applyFilterBeforeListing;
  const [searchInput, setSearchInput] = useState<string>('');
  const size: Size = useWindowSize();

  useEffect(() => {
    setSearchInput(search);
  }, [search]);

  const onSearch = (value: any) => {
    if (value === '') {
      dispatch(clearSearch());
    }

    if (applyFilterBeforeListing && !value) return;

    dispatch(loadRequest(undefined, undefined, { ...pagination, current: 1 }, value));
  };
  const dispatch = useDispatch<Dispatch<Action>>();

  return (
    <Tooltip placement="topRight" title={requiredValues.length !== requiredFields.length && requiredFields.length > 0 ? "Primeiro preencha os filtros obrigatórios" : ''}>
      <div>
        <Search
          data-cy="input-search"
          placeholder={`${config?.placeholder ?? 'Buscar'}`}
          allowClear
          value={searchInput}
          onSearch={onSearch}
          onChange={({ target }) => setSearchInput(target.value)}
          style={{ width: (size.width ?? 0) > 1160 ? '400px' : '100%' }}
          disabled={requiredValues.length !== requiredFields.length && requiredFields.length > 0}
        />
      </div>
    </Tooltip>
  );
};

export default SearchInput;
