import { action } from 'typesafe-actions';
import { ResponseError } from '../../../services/api';
import { Pagination, AccessControlTypes, Users } from './types';

export const controlRequest = (pagination?: Pagination, search?: string) =>
  action(AccessControlTypes.LOAD_REQUEST, { pagination, search });
export const controlSuccess = (pagination: Pagination, data: Users[]) =>
  action(AccessControlTypes.LOAD_SUCCESS, { pagination, data });
export const controlFailure = (error: ResponseError) =>
  action(AccessControlTypes.LOAD_FAILURE, { error });
