import React, { Dispatch, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Action } from 'typesafe-actions';
import { ApplicationState } from '../../store';
import { loadRequest } from '../../store/ducks/dataSheet/actions';
import DataSheetCategory from './DataSheetCategory';
import DataSheetSearch from './DataSheetSearch';
import DatasheetTable from './DatasheetTable';
import { HeaderContent } from './styles';

const Datasheet: React.FC = () => {
  const token = useSelector((state: ApplicationState) => state.auth.token);
  const [searchValue, setSearchValue] = useState<string>('');
  const [categoryFilter, setCategoryFilter] = useState<any>([]);
  const [filters, setFilters] = useState<any>([]);
  const [sorter, setSorter] = useState<any>();
  const [pagination, setPagination] = useState<any>();

  const dispatch = useDispatch<Dispatch<Action>>();
  useEffect(() => {
    dispatch(loadRequest(pagination, filters, sorter, searchValue, categoryFilter));
  }, [categoryFilter, dispatch, filters, pagination, searchValue, sorter, token]);

  return (
    <>
      <HeaderContent>
        <DataSheetCategory setCategoryFilter={setCategoryFilter} setPagination={setPagination} />
        <DataSheetSearch setSearch={setSearchValue} setPagination={setPagination} />
      </HeaderContent>
      <DatasheetTable setFilters={setFilters} setSorter={setSorter} setPagination={setPagination} />
    </>
  );
};

export default Datasheet;
