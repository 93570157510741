import { Scatter } from '@ant-design/plots';
import React from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../store';
import { colors } from '../../../utils/constants';
import { maskCamelCase } from '../../../utils/masks';
import EmptyChart from '../EmptyChart';

// import { Container } from './styles';
interface Props {
  title: string;
  data: any[];
  xField: string;
  yField: string;
}
const ScatterChart: React.FC<Props> = ({ title, data, xField, yField }) => {
  const layout = useSelector((state: ApplicationState) => state.layout.data);

  const config = {
    data,
    xField: xField,
    yField: yField,
    color: layout?.color.default,
    size: 5,
    pointStyle: {
      stroke: colors.writeGrey,
      lineWidth: 1,
      fill: layout?.color.default,
    },
    meta: {
      [xField]: {
        alias: maskCamelCase(xField),
      },
      [yField]: {
        alias: maskCamelCase(yField),
      },
    },
  };
  return data.length === 0 ? (
    <EmptyChart type={'scatter'} />
  ) : (
    <div style={{ padding: '20px' }}>
      <h2 style={{ color: layout?.color.dark }}>{title}</h2>
      <Scatter {...config} />
    </div>
  );
};

export default ScatterChart;
